import axios from 'axios';
import { saveAs } from 'file-saver';
import * as URLS from '../constants/urls';

const BUCKET_NAME = 'sistema-gestor-activos';
const STORAGE = 'https://storage.cydocs.cl';

/**
 * Método encargado de subir una foto a Cloud Storage.
 * @param {*} file Archivo seleccionado.
 * @param {*} route Ruta de carpeta donde se subirá.
 * @param {*} name Nombre del archivo.
 * @returns URL de la imagen subida.
 */
export async function uploadFileToCloudStorage(file, route, name) {
  const nombredado = name.replace(/ /g, '_');
  const nombreRespaldoSinEspacios = file.name.replace(/ /g, '_');
  const filename = name ? nombredado : nombreRespaldoSinEspacios;
  try {
    const formData = new FormData();
    formData.append('folder', route);
    formData.append('bucket', BUCKET_NAME);
    formData.append('name', filename);
    formData.append('file', file);

    const upload = await axios.post(`${STORAGE}/upload`, formData);
    if (!(upload.status === 201 || upload.status === 200)) {
      throw new Error('Error al intentar subir el archivo.');
    }
    const uploadedFile = {
      mime_type: upload.data.content_type,
      nombre: name,
      nombre_original: file.name,
      repositorio: `${upload.data.bucket}/${route}`,
      url: upload.data.pathname,
    };
    return uploadedFile;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de descargar un archivo desde una URL, como Blob.
 * @param {*} url URL del archivo.
 * @returns Blob del archivo.
 */
export async function downloadFileFromURL(url) {
  try {
    const response = await axios(url, { responseType: 'blob' });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// export async function uploadFileToCloudStorage(file, route, name, progressCallback = (event) => { }) {
//   const filename = name ? name : file.name;
//   var formData = new FormData();
//   formData.append("folder", route);
//   formData.append("bucket", "sistema-gestor-activos");
//   formData.append("name", filename);
//   formData.append("file", file);
//   let archivo = null;
//   return axios.post(`${URLS.BACKEND}/archivos`, { nombre: filename, repositorio: 'Cloud Storage', path: route, size: file.size, mime_type: file.type }).then((response) => {
//     if (response.status === 201 || response.status === 200) archivo = response.data.data;
//     return axios.post("https://storage.cydocs.cl/upload", formData, {
//       onUploadProgress: progressCallback
//     });
//   }).then((response) =>{
//     if (response.status === 201 || response.status === 200){
//       archivo["url"] = response.data.pathname;
//       return archivo;
//     }else{
//       return null;
//     }
//   });
// }

export const downloadUrlAsPromise = (url, fileName, token) => {
  try {
    return new Promise(function (resolve, reject) {
      const url2 = 'https://script.google.com/macros/s/AKfycbwfc_339gl3N-1vAzDLIaTHbyrZRXRtOjEqcBibgQp5AyQslHE/exec';
      fetch(url2, {
        method: 'POST',
      })
        .then((res) => res.json())
        .then((result) => {
          const xhr = new XMLHttpRequest();
          xhr.open('GET', url);
          if (token) xhr.setRequestHeader('Authorization', 'Bearer ' + result.access);
          xhr.responseType = 'blob';
          xhr.onreadystatechange = function (evt) {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                resolve({
                  blob: xhr.response,
                  name: fileName,
                });
              } else {
                reject(new Error('Ajax error for ' + url + ': ' + xhr.status));
              }
            }
          };
          xhr.send();
        })
        .catch((error) => {
          console.log('errorrrr', error);
          reject(error);
        });
    });
  } catch (e) {
    console.log(e);
  }
};

export async function viewFileGCS(url, nombre) {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    return downloadUrlAsPromise(url, nombre, false)
      .then(function (response) {
        saveAs(response.blob, nombre);
        window.close();
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    const windowRef = window.open('', '_blank');
    return downloadUrlAsPromise(url, nombre, false)
      .then(function (response) {
        const blobUrl = URL.createObjectURL(response.blob);
        windowRef.location.href = blobUrl;
        console.log('response archivo: ', response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}

/**
 * Ver archivo almacenado en drive
 * @param {*} url del archivo en drive
 * @param {*} nombre con el que se descarga el archivo
 */
export function viewDriveFile(url, nombre) {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    return downloadUrlAsPromise(url, nombre, true)
      .then(function (response) {
        saveAs(response.blob, nombre);
        window.close();
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    const windowRef = window.open('', '_blank');
    return downloadUrlAsPromise(url, nombre, true)
      .then(function (response) {
        const blobUrl = URL.createObjectURL(response.blob);
        windowRef.location.href = blobUrl;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
/**
 * Descargar archivo almacenado en drive
 * @param {String} url
 * @param {String} nombre
 * @returns
 */

export function downloadDriveFile(url, nombre) {
  return downloadUrlAsPromise(url, nombre, true)
    .then(function (response) {
      saveAs(response.blob, nombre);
      window.close();
    })
    .catch(function (error) {
      console.log(error);
    });
}
/**
 * Carga de archivos a repositorio drive
 * @param {*} file archivo
 * @param {*} path ruta del archivo
 * @param {*} name nombre del archivo
 * @param {*} progressCallback funcion para seguir el proceso de carga del archivo
 */
export async function uploadFileToDrive(
  file,
  path,
  name,
  bucketName = 'sistema-gestor-activos',
  progressCallback = (event) => {},
) {
  let archivo = null;
  const nombredado = name.replace(/ /g, '_');
  const nombreRespaldoSinEspacios = file.name.replace(/ /g, '_');
  const filename = name ? nombredado : nombreRespaldoSinEspacios;

  var formData = new FormData();
  formData.append('folder', path);
  formData.append('bucket', bucketName);
  formData.append('name', filename);
  formData.append('file', file);
  return axios
    .post(`${URLS.BACKEND}/archivos`, {
      nombre: filename,
      repositorio: 'Cloud Storage',
      path,
      size: file.size,
      mime_type: file.type,
    })
    .then((response) => {
      if (response.status === 201 || response.status === 200) archivo = response.data.data;
      return axios.post(`${STORAGE}/upload`, formData, {
        onUploadProgress: progressCallback,
      });
    })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        archivo['url'] = response.data.pathname;
        return archivo;
      } else {
        return null;
      }
    });
}

// function uploadChunks(file, name, location, progressCallback) {
//   return new Promise((resolve, reject) => {
//     try {
//       const chunkSize = file.size > 262144 ? 262144 : file.size;
//       const chunksQuantity = Math.ceil(file.size / chunkSize);
//       recursiveUploadChunks(
//         0,
//         chunksQuantity,
//         file,
//         name,
//         location,
//         null,
//         chunkSize,
//         resolve,
//         reject,
//         progressCallback,
//       );
//     } catch (error) {
//       reject(error);
//     }
//   });
// }

// function recursiveUploadChunks(
//   index,
//   length,
//   file,
//   name,
//   location,
//   finalResponse,
//   size,
//   resolve,
//   reject,
//   progressCallback,
// ) {
//   progressCallback({ loaded: index + 1, total: length, lengthComputable: true });
//   if (index === length) {
//     resolve(finalResponse.body);
//   } else {
//     var headers = finalResponse && finalResponse.headers ? finalResponse.headers : null;
//     var currentRange = 0;
//     if (headers && headers.range) currentRange = headers.range[0];
//     if (headers && headers.Range) currentRange = headers.Range[0];
//     var begining = currentRange !== 0 ? parseInt(currentRange.split('=')[1].split('-')[1]) + 1 : 0;
//     var nextChunk = begining + size - 1;
//     nextChunk = nextChunk > file.size ? file.size - 1 : nextChunk;
//     var final = nextChunk + 1;
//     var chunk = file.slice(begining, final);
//     var chunkRange = begining + '-' + nextChunk + '/' + file.size;
//     var chunkSize = nextChunk - begining + 1;
//     var mimeType = file.type ? file.type : 'application/octet-stream';
//     uploadChunk(chunk, chunkSize, chunkRange, mimeType, location, name)
//       .then((response) => {
//         recursiveUploadChunks(
//           index + 1,
//           length,
//           file,
//           name,
//           location,
//           response,
//           size,
//           resolve,
//           reject,
//           progressCallback,
//         );
//       })
//       .catch(reject);
//   }
// }

// function uploadChunk(chunk, chunkSize, chunkRange, mimeType, location, name) {
//   return new Promise((resolve, reject) => {
//     var form = new FormData();
//     form.set('file', chunk, name);
//     form.append('range', chunkRange);
//     form.append('mime_type', mimeType);
//     form.append('size', chunkSize);
//     form.append('location', location.location);
//     var url = 'https://cydocs-drive-api.herokuapp.com/chunked-resumable-upload';
//     axios
//       .post(url, form)
//       .then((response) => resolve(response.data))
//       .catch((error) => reject(error));
//   });
// }

export const uploadFileAsPromise = (file, folderId) => {
  return new Promise(function (resolve, reject) {
    var fileReader = new FileReader();

    fileReader.onload = function (e) {
      var form = new FormData();
      form.append('file', e.target.result.replace(/^.*,/, ''));
      // form.append('file', e.target.result.split(',')[1]);
      form.append('filename', file.name);
      form.append('folderId', folderId);
      form.append('mimeType', file.type);
      var url = 'https://script.google.com/macros/s/AKfycby1hX1I0b8Hy9rZZofEf4WqcRv7vj6Ze0pnMUyNJbv_HqKUtvM/exec';
      fetch(url, {
        method: 'POST',
        body: form,
      })
        .then((res) => res.json())
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    };
    fileReader.readAsDataURL(file);
  });
};
