import axios from 'axios';
import { BACKEND } from '../../constants/urls'
/**
 * Obtener proveedores
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getProveedores(filter = {}, cancelToken = null) {
  return axios.get(`${BACKEND}/proveedores`,
    {
      params: filter,
      cancelToken: cancelToken
    }).then(res => res.data);
}
/**
 * Crear proveedor
 * @param {*} data Datos del proveedor
 * @param {*} cancelToken token para cancelacion
 */
export function postProveedor(data, cancelToken = null) {
  return axios.post(`${BACKEND}/proveedores`,
    data,
    {
      cancelToken: cancelToken
    }).then(res => res.data);
}