import { AppBar, Tab, Tabs, useMediaQuery, useTheme, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import TablaResultadoLicenciasSoftware from './tablaResultados';
import DialogoCRUDLicenciaSoftware from './dialogoCRUDLicenciaSoftware';
import CargaMasivaSoftware from './cargaMasivaSoftware';
import { MainContext } from '../../App';
import { CARGAR_LICENCIAS_SOFTWARE, VER_LISTADO_LICENCIAS_SOFTWARE } from '../../constants/permissions';
import TablaAsignacionesSoftwareTodas from './tablaAsignacionesSoftwareTodas';
import TablaResumenDisponibilidadLicencias from './tablaResumenDisponibilidad';
//import TabladePrueba from './pruebatabla';

function TabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
	  <div
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...other}
	  >
		{value === index && (
		  <Box >
			{children}
		  </Box>
		)}
	  </div>
	);
  }

  TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
	return {
	  id: `simple-tab-${index}`,
	  'aria-controls': `simple-tabpanel-${index}`,
	};
  }

export default function Page() {

  const [selectedTab, setSelectedTab] = useState(0);

  const { permisos } = useContext(MainContext);

  const theme = useTheme();
  const smBreak = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event, newValue) => {
		setSelectedTab(newValue);
	  };
  
  useEffect(() => {
	if(!permisos[VER_LISTADO_LICENCIAS_SOFTWARE]){
		setSelectedTab(2)
	} 
  }, );

  return (
    <Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <AppBar position="static">
          <Tabs value={selectedTab} 
          onChange={handleChange} 
          aria-label="basic tabs"
          centered={!smBreak}
          variant={smBreak ? "scrollable" : "standard"}
          scrollButtons={smBreak ? "on" : "off"}
          >
          {/* <Tab label="Resumen Disponibilidad Licencias" value = {0} {...a11yProps(0)} /> */}
		  {!permisos[VER_LISTADO_LICENCIAS_SOFTWARE] ? "" : <Tab label="Resumen Disponibilidad Licencias" value = {0} {...a11yProps(0)} />}
          {!permisos[VER_LISTADO_LICENCIAS_SOFTWARE] ? "" : <Tab label="Licencias de Software" value = {1} {...a11yProps(1)} />}
          <Tab label="Asignaciones" value = {2} {...a11yProps(2)} />
          {!permisos[CARGAR_LICENCIAS_SOFTWARE] ? "" : <Tab label="Carga desde Archivo" value = {3} {...a11yProps(3)} disabled={!permisos[CARGAR_LICENCIAS_SOFTWARE]}/>}
          </Tabs>
        </AppBar>
			</Box>
			<TabPanel value={selectedTab} index={0} hidden= {!permisos[VER_LISTADO_LICENCIAS_SOFTWARE]}>
        		<TablaResumenDisponibilidadLicencias />
			</TabPanel>
			<TabPanel value={selectedTab} index={1} hidden= {!permisos[VER_LISTADO_LICENCIAS_SOFTWARE]}>
        		<TablaResultadoLicenciasSoftware />
			</TabPanel>
			<TabPanel value={selectedTab} index={2}>
        		<TablaAsignacionesSoftwareTodas />
			</TabPanel>
			<TabPanel value={selectedTab} index={3} hidden= {!permisos[CARGAR_LICENCIAS_SOFTWARE] }>
        		<CargaMasivaSoftware />
			</TabPanel>
      <DialogoCRUDLicenciaSoftware />
	</Box>    
  );
}