import React, { createContext, Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@material-ui/core";
// import { Autocomplete } from "@material-ui/lab";
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSnackbar } from "notistack";
import useSWR from "swr";
import { ActualizarRol, AgregarRol, EliminarRol, ObtenerRoles } from "../../../services/request/requestRoles";
// import { ObtenerPerfiles } from "./requestPerfiles";
import MaterialTable from "../../../components/materialTable";
import { useHistory } from "react-router";
import { LISTADO_PERFILES_ROL } from '../../../constants/routes';
import { MainContext } from "../../../App";
import { AGREGAR_ROLES, EDITAR_ROLES, ELIMINAR_ROLES } from "../../../constants/permissions";


export const ListadoRolesContext = createContext({
	rolSeleccionado: null,
	setRolSeleccionado: () => { },
	openDialogoPerfilesDeRol: false, setOpenDialogoPerfilesDeRol: () => { },
});

function Index() {
	const history = useHistory();
	const {permisos} = useContext(MainContext);
	const { data: dataRoles, mutate: mutateRoles } = useSWR("get:roles", (key) => ObtenerRoles());
	const [Registro, SetRegistro] = useState();
	const [IsOpen, SetIsOpen] = useState(false);
	const [tipoDialogo, setTipoDialogo] = useState("");
	const notistack = useSnackbar();

	const handleAgregar = useCallback(() => {
		SetIsOpen(true);
		setTipoDialogo("agregar");
	},[]);

	const handleActualizar = useCallback((rol) => {
		SetRegistro(rol);
		SetIsOpen(true);
		setTipoDialogo("actualizar");
	},[]);

	const handleEliminar = useCallback((rol) => {
		SetRegistro(rol);
		SetIsOpen(true);
		setTipoDialogo("eliminar");
	},[]);

	const handleChangeNombre = (event) => {
		let nombre = event.target.value;
		let datos = { ...Registro };
		datos["nombre"] = nombre;
		SetRegistro(datos);
	}

	const handleChangeDescripcion = (event) => {
		let descripcion = event.target.value;
		let datos = { ...Registro };
		datos["descripcion"] = descripcion;
		SetRegistro(datos);
	}

	const handleAceptar = async () => {
		try {
			switch (tipoDialogo) {
				case "agregar":
					await AgregarRol(Registro);
					break;
				case "actualizar":
					await ActualizarRol(Registro);
					break;
				case "eliminar":
					await EliminarRol(Registro);
					break;
				default:
					throw new Error("Acción no especificada.")
			}
			notistack.enqueueSnackbar("Acción realizada exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar realizar la acción.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} finally {
			mutateRoles();
			SetRegistro();
			SetIsOpen(false);
			setTipoDialogo("");
		}
	}

	const handleCancelar = () => {
		SetRegistro();
		SetIsOpen(false);
		setTipoDialogo("");
	}

	let columnsRoles = [
		{
			title: "Nombre",
			field: "nombre",
			defaultSort: 'asc'
		}, {
			title: "Descripción",
			field: "descripcion",
		}
	];

	const [actions, setActions] = useState([]);

	const revisarPermisos = useCallback(() => {
		const accionesPermitidas = [];
		if (permisos[AGREGAR_ROLES]) accionesPermitidas.push({
			tooltip: "Agregar",
			icon: "add",
			onClick: () => handleAgregar(),
			isFreeAction: true,
		});
		if (permisos[ELIMINAR_ROLES]) accionesPermitidas.push({
			tooltip: "Eliminar",
			icon: "delete",
			onClick: (event, row) => handleEliminar(row),
		});
		if (permisos[EDITAR_ROLES]) {
			accionesPermitidas.push({
				tooltip: "Editar",
				icon: "edit",
				onClick: (event, row) => handleActualizar(row),
			});
			accionesPermitidas.push({
				tooltip: "Ver Perfiles",
				icon: "list",
				onClick: (e, rowData) => {
					history.push(LISTADO_PERFILES_ROL.replace(":id", rowData._id));
				}
			});
		}
		setActions(accionesPermitidas);
	}, [permisos, handleAgregar, handleEliminar, handleActualizar, history]);

	useEffect(() => {
		revisarPermisos();
	}, [revisarPermisos]);

	return (
		<Fragment>
			<ListadoRolesContext.Provider>
				<MaterialTable
					title="Roles"
					is_loading={!dataRoles}
					data={dataRoles && dataRoles.data}
					columns={columnsRoles}
					actions={actions}
				/>
				<Dialog open={IsOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						{tipoDialogo === "actualizar" && "Actualizar Rol"}
						{tipoDialogo === "agregar" && "Agregar Rol"}
						{tipoDialogo === "eliminar" && "Eliminar Rol"}
					</DialogTitle>
					<DialogContent dividers>
						<DialogContentText>Formulario con información del Rol.</DialogContentText>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									label="Nombre"
									value={Registro ? Registro.nombre : ""}
									onChange={handleChangeNombre}
									variant="outlined"
									fullWidth
									disabled={tipoDialogo === "eliminar"}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									label="Descripción"
									value={Registro ? Registro.descripcion : ""}
									onChange={handleChangeDescripcion}
									variant="outlined"
									fullWidth
									disabled={tipoDialogo === "eliminar"}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCancelar} variant="outlined" color="primary">
							Cancelar
          </Button>
						<Button onClick={handleAceptar} variant="contained" color="primary">
							Aceptar
          </Button>
					</DialogActions>
				</Dialog>
			</ListadoRolesContext.Provider>
		</Fragment>
	);
}

export default Index;