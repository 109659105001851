import axios from 'axios';
import { BACKEND } from '../../constants/urls'
/**
 * Obtener Todas las sociedades registradaa
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getLogsUpdateValorLibro(filter = {}, selects = [], populates = [], cancelToken = null) {
	let url = `${BACKEND}/log-update-valor-libro`;
	if (selects) selects.forEach(select => {
		url = url + "/" + select;
	});
	if (populates) populates.forEach(populate => {
		url = url + "/" + populate;
	});  
	return axios.get(url,
		{
			params: filter,
			cancelToken: cancelToken
		}).then(res => res.data);
}

/**
 * Obtener Sociedad dado su ID
 * @param {String}  id
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelación
 */
 export function getLogUpdateValorLibro(id, selects, populates, cancelToken = null) {
    let url = `${BACKEND}/log-update-valor-libro/${id}`;
    if (selects) selects.forEach(select => {
      url = url + "/" + select;
    });
    if (populates) populates.forEach(populate => {
      url = url + "/" + populate;
    });
    return axios.get(url,
      {
        cancelToken: cancelToken
      }).then(res => res.data ? res.data : null);
  }

/**
 * Guardar Nueva Sociedad
 * @param {*} data datos de Marca
 * @param {*} cancelToken token para cancelacion
 */
export function postLogUpdateValorLibro(data, cancelToken = null) {
  return axios.post(`${BACKEND}/log-update-valor-libro/`, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data);
}
/**
 * Actualizar Sociedad
 * @param {String}  id
 * @param {Object} data datos del registro
 * @param {*} cancelToken token para cancelacion
 */
export function putLogUpdateValorLibro(id, data, cancelToken = null) {
  return axios.put(`${BACKEND}/log-update-valor-libro/${id}`, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data.data : null);
}

/**
 * Eliminar Sociedad dado el ID
 * @param {String}  id
 * @param {*} cancelToken token para cancelacion
 */
export function deleteLogUpdateValorLibro(id, cancelToken = null) {
  return axios.delete(`${BACKEND}/log-update-valor-libro/${id}`,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data.data : null);
}