import React, { useState, Fragment, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
// import XLSX from 'xlsx';
import MaterialTable from '../../components/materialTable';
import { MTableAction } from '@material-table/core';
import { Button } from '@material-ui/core';
import { getRegistroKilometrajes } from '../../services/request/requestBienes';
import DialogoActualizarKilometraje from './dialogoActualizarKilometraje';
import useSWR from 'swr';
import Moment from 'moment';

function getData() {
  return getRegistroKilometrajes(
    {},
    ['_vehiculo_ref', '_bien_ref', 'foto_tacometro', 'kilometraje', 'fecha_kilometraje'],
    [
      '_vehiculo_ref.patente',
      '_vehiculo_ref.tipo_combustible',
      '_bien_ref.codigo_activo',
      '_bien_ref.marca',
      '_bien_ref.modelo',
      '_vehiculo_ref.transmision',
      '_vehiculo_ref.color',
      '_bien_ref._id',
    ],
  );
}

function TablaRegistrosKM() {
  const [activoSeleccionado, setActivoSeleccionado] = useState(null);
  const [OpenRegistroKilometraje, setOpenRegistroKilometraje] = useState(false);
  const [datosKilometrajes, setDatosKilometrajes] = useState([]);
  const { data: response } = useSWR('get:kilometrajes', (key) => getData());
  const history = useHistory();

  // const handleExport = () => {
  //   const wb = XLSX.utils.book_new();
  //   const wbdata = response.data.map((a) => {
  //     return {
  //       'Código Activo': a.codigo_activo,
  //       Marca: a.marca,
  //       Modelo: a.modelo,
  //       Clase: a._clase_ref ? a._clase_ref.nombre : '',
  //       Patente: a._vehiculo_ref ? a._vehiculo_ref.patente : '',
  //       Kilometraje: a._vehiculo_ref ? a._vehiculo_ref.kilometraje : '',
  //       Color: a._vehiculo_ref ? a._vehiculo_ref.color : '',
  //       Transmisión: a._vehiculo_ref ? a._vehiculo_ref.transmision : '',
  //       'Tipo Combustible': a._vehiculo_ref ? a._vehiculo_ref.tipo_combustible : '',
  //     };
  //   });
  //   let ws = XLSX.utils.json_to_sheet(wbdata);
  //   XLSX.utils.book_append_sheet(wb, ws, 'Activos');
  //   XLSX.writeFile(wb, 'activos.xlsx');
  // };

  const loadData = useCallback(async () => {
    setDatosKilometrajes([]);

    if (response) {
      if (response.status) {
        setDatosKilometrajes(response.data);
      }
    }
  }, [response]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  function handleOpenRegistroKilometraje(seleccionado, open) {
    setActivoSeleccionado(seleccionado);
    setOpenRegistroKilometraje(open);
  }

  const handleVerArchivo = (archivo) => {
    window.open(archivo.url, '_blank');
  };

  const columns = [
    {
      title: 'Patente',
      field: '_vehiculo_ref.patente',
      align: 'center',
    },
    {
      title: 'Código Activo',
      field: '_bien_ref.codigo_activo',
      align: 'center',
    },
    {
      title: 'Marca',
      field: '_bien_ref.marca',
      align: 'center',
    },
    {
      title: 'Modelo',
      field: '_bien_ref.modelo',
      align: 'center',
    },
    {
      title: 'Transmisión',
      field: '_vehiculo_ref.transmision',
      align: 'center',
      hidden: true,
    },
    {
      title: 'Kilometraje',
      field: 'kilometraje',
      align: 'center',
      render: (row) => (row ? `${Intl.NumberFormat('es-ES').format(row.kilometraje)} Kms.` : ''),
    },
    {
      title: 'Fecha Registro',
      field: 'fecha_kilometraje',
      align: 'center',
      render: (row) => (row.fecha_kilometraje ? Moment(row.fecha_kilometraje).format('DD/MM/YYYY') : '--'),
    },
    {
      title: 'Descripción',
      field: 'descripcion',
      align: 'center',
      hidden: true,
    },
  ];

  const actions = [
    {
      icon: 'chevron_right',
      tooltip: 'Ver Detalles',
      onClick: (event, rowData) => history.push(`activos/${rowData._bien_ref._id}`),
    },
    {
      icon: 'visibility',
      tooltip: 'Ver Imagen',
      onClick: (event, rowData) => handleVerArchivo(rowData.foto_tacometro),
    },
    {
      icon: 'edit',
      tooltip: 'Editar registro',
      onClick: (event, rowData) => handleOpenRegistroKilometraje(rowData, true),
    },
    {
      icon: 'registrar_km',
      tooltip: 'Registrar KM',
      position: 'row',
      onClick: (event, row) => handleOpenRegistroKilometraje(null, true),
      isFreeAction: true,
    },
  ];

  return (
    <Fragment>
      <MaterialTable
        title="Kilometrajes Vehiculares Registrados"
        is_loading={!datosKilometrajes}
        fullWidth
        data={datosKilometrajes}
        columns={columns}
        grouping={false}
        actions={actions}
        // export_function={handleExport}
        components={{
          Action: (propData) => {
            const rowData = propData.data;
            if (propData.action.icon === 'visibility') {
              propData.action.hidden = !rowData.foto_tacometro;
              propData.action.disabled = !rowData.foto_tacometro;
            }
            if (propData.action.icon === 'registrar_km') {
              return (
                !propData.action.hidden && (
                  <Button
                    onClick={(event) => propData.action.onClick(event, rowData)}
                    color="primary"
                    style={{
                      color: 'white',
                      backgroundColor: '#731f1f',
                      marginLeft: 5,
                      textTransform: 'none',
                    }}
                    variant="contained"
                    size="small"
                  >
                    {'Registrar KM'}
                  </Button>
                )
              );
            }
            return <MTableAction {...propData} />;
          },
        }}
      />

      {/* POPUP PARA MOSTRAR DIALOGO DE REGISTRO KM */}
      <DialogoActualizarKilometraje
        activo_seleccionado={activoSeleccionado}
        is_open={OpenRegistroKilometraje}
        handle_open={handleOpenRegistroKilometraje}
      />
    </Fragment>
  );
}

export default TablaRegistrosKM;
