import React, { useEffect, useState, useContext } from 'react';
import Page from './page';
import { ObtenerBienesPorContrato } from '../../../../services/request/requestBienes';
import { ObtenerContrato } from '../../../../services/request/requestPortafolio';
import { useParams } from 'react-router';
import { MainContext } from '../../../../App';

function Index(props) {
  const [ContratoAsignado, SetContratoAsignado] = useState();
  const [Bienes, SetBienes] = useState();
  const [Bien, SetBien] = useState();
  const [IsOpen1, SetIsOpen1] = useState(false);
  const [IsOpen2, SetIsOpen2] = useState(false);
  const { id: contratoID } = useParams();
  const { usuarioSesion } = useContext(MainContext);

  useEffect(() => {
    if (contratoID) {
      ObtenerContrato(contratoID)
        .then((contrato) => SetContratoAsignado(contrato))
        .catch((error) => {
          console.error(error);
          SetContratoAsignado(null);
          SetBienes([]);
        });

      ObtenerBienesPorContrato(contratoID)
        .then((response) => SetBienes(response.data))
        .catch((error) => {
          console.error(error);
          SetBienes([]);
        });
    }
  }, [contratoID, IsOpen1, IsOpen2]);

  const handlePaso1 = () => {
    SetIsOpen1(true);
    SetIsOpen2(false);
  };

  const handlePaso2 = (bien) => {
    SetIsOpen1(false);
    SetIsOpen2(true);
    SetBien(bien);
  };

  const handleClose = () => {
    SetIsOpen1(false);
    SetIsOpen2(false);
  };

  return (
    <Page
      usuario_asignante={usuarioSesion}
      contrato_asignado={ContratoAsignado}
      bienes={Bienes}
      bien={Bien}
      is_open_1={IsOpen1}
      is_open_2={IsOpen2}
      handle_paso_1={handlePaso1}
      handle_paso_2={handlePaso2}
      handle_close={handleClose}
    />
  );
}

export default Index;
