import axios from 'axios';
import { PORTAFOLIO } from '../../constants/urls';

/**
 * Método encargado de obtener todas las gerencias.
 */
export async function ObtenerGerencias() {
  try {
    const url = `${PORTAFOLIO}/gerencias`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener una gerencia, por su ID.
 * @param {*} gerenciaID ID de la gerencia.
 */
export async function ObtenerGerencia(gerenciaID) {
  try {
    const url = `${PORTAFOLIO}/gerencias/${gerenciaID}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los contratos, por ID de gerencia.
 * @param {*} gerenciaID ID de la gerencia.
 */
export async function ObtenerContratos(gerenciaID) {
  try {
    let url = `${PORTAFOLIO}/contratos`;
    if (gerenciaID) {
      url = `${url}?gerencia_ref=${gerenciaID}`;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método enargado de obtener un contrato, por su ID.
 * @param {*} contratoID ID del contrato.
 */
export async function ObtenerContrato(contratoID) {
  try {
    const url = `${PORTAFOLIO}/contratos/${contratoID}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método enargado de obtener un contrato, por su código.
 * @param {*} contratoCodigo Código del contrato.
 */
export async function ObtenerContratoPorCodigo(contratoCodigo) {
  try {
    const url = `${PORTAFOLIO}/contratos?codigo=${contratoCodigo}`;
    const response = await axios.get(url);
    return response.data[0];
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método enargado de obtener un contrato, por su correlativo.
 * @param {*} contratoCorrelativo Correlativo del contrato.
 */
export async function ObtenerContratoPorCorrelativo(contratoCorrelativo) {
  try {
    const url = `${PORTAFOLIO}/contratos/correlativo/${contratoCorrelativo}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Obtener gerencias
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getGerencias(filter = {}, cancelToken = null) {
  return axios
    .get(`${PORTAFOLIO}/gerencias/`, {
      params: filter,
      cancelToken,
    })
    .then((res) => res.data);
}

/**
 * Obtener gerencia
 * @param {String} id
 * @param {*} cancelToken token para cancelacion
 */
export function getGerencia(id, cancelToken = null) {
  return axios
    .get(`${PORTAFOLIO}/gerencias/${id}`, {
      cancelToken,
    })
    .then((res) => res.data);
}

/**
 * Obtener contratos
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getContratos(filter = {}, cancelToken = null) {
  return axios.get(`${PORTAFOLIO}/contratos/`, {
    params: filter,
    cancelToken,
  });
}

/**
 * Obtener contrato por id
 * @param {*} id
 * @param {*} cancelToken token para cancelacion
 */
export function getContrato(id, cancelToken = null) {
  return axios
    .get(`${PORTAFOLIO}/contratos/${id}`, {
      cancelToken,
    })
    .then((res) => res.data);
}

/**
 * Método encargado de obtener un contrato, por su código.
 * @param {*} codigo Código del contrato.
 * @param {*} cancelToken Token para cancelar la request.
 * @returns Objeto contrato o null.
 */
export function getContratoPorCodigo(codigo, cancelToken = null) {
  return axios
    .get(`${PORTAFOLIO}/contratos?codigo=${codigo}`, {
      cancelToken,
    })
    .then((res) => (res.data && res.data.length > 0 ? res.data[0] : null));
}

/**
 * Método encargado de obtener listado de contratos asignados a una persona.
 * Si posee solo un contrato es Jefe de Proyecto, si posee mas de un contrato es Gerente de Proyectos.
 * Si centro de Costo es True, es porque es Gerente de Area o Gerente de Proyectos
 * @param {*} idUsuario Identificador del Usuario Responsable de Administración (Jefe de Proyecto - Residente).
 * @param {*} esSubgerente parámetro Booleano para definir si los contratos con centros de costo (True) o NO (False)
 * @param {*} cancelToken Token para cancelar la request.
 * @returns Objeto contrato o null.
 */
export function getContratosACargo(idUsuario, esSubgerente, cancelToken = null) {
  let Consulta = '';

  if (esSubgerente) {
    Consulta = `contratos?is_eliminado=false&responsable_ref=${idUsuario}`;
  } else {
    Consulta = `contratos?is_centro_costo=false&is_eliminado=false&responsable_admin_ref=${idUsuario}`;
  }

  return axios
    .get(`${PORTAFOLIO}/${Consulta}`, {
      cancelToken,
    })
    .then((res) => (res.data && res.data.length > 0 ? res.data : null));
}

/**
 * Obtener sociedades
 * @param {*} filter filtro para resultasdos
 * @param {*} cancelToken token para cancelacion
 */
export function getSociedades(filter = {}, cancelToken = null) {
  return axios
    .get(`${PORTAFOLIO}/sociedades/`, {
      params: filter,
      cancelToken,
    })
    .then((res) => res.data);
}

/**
 * Obtener sociedad por id
 * @param {*} id
 * @param {*} cancelToken token para cancelacion
 */
export function getSociedad(id, cancelToken = null) {
  return axios
    .get(`${PORTAFOLIO}/sociedades/${id}`, {
      cancelToken,
    })
    .then((res) => res.data);
}

/**
 * Obtener Datos del Responsable de contratos por id de Usuario
 * @param {*} idUsuario identificador de usuario Google
 * @param {*} cancelToken token para cancelacion
 */
export function getResponsable(idUsuario, cancelToken = null) {
  return axios
    .get(`${PORTAFOLIO}/responsables?persona.usuario_id=${idUsuario}`, {
      cancelToken,
    })
    .then((res) => res.data);
}

/**
 * Obtener Datos del Responsable de contratos por id de Usuario
 * @param {*} idUsuario identificador de usuario Google
 * @param {*} cancelToken token para cancelacion
 */
export function getResponsableByID(idUsuario, cancelToken = null) {
  return axios
    .get(`${PORTAFOLIO}/responsables/${idUsuario}`, {
      cancelToken,
    })
    .then((res) => res.data);
}
