import axios from 'axios';
import { BACKEND } from '../../constants/urls'
/**
 * Obtener Todas las aseguradoras registradas
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getProveedoresGPS(filter = {}, selects = [], populates = [], cancelToken = null) {
	let url = `${BACKEND}/proveedorGPS`;
	if (selects) selects.forEach(select => {
		url = url + "/" + select;
	});
	if (populates) populates.forEach(populate => {
		url = url + "/" + populate;
	});  
	return axios.get(url,
		{
			params: filter,
			cancelToken: cancelToken
		}).then(res => res.data);
}

/**
 * Obtener empresa Aseguradora dado su ID
 * @param {String}  id
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelación
 */
 export function getProveedorGPS(id, selects, populates, cancelToken = null) {
    let url = `${BACKEND}/proveedorGPS/${id}`;
    if (selects) selects.forEach(select => {
      url = url + "/" + select;
    });
    if (populates) populates.forEach(populate => {
      url = url + "/" + populate;
    });
    return axios.get(url,
      {
        cancelToken: cancelToken
      }).then(res => res.data ? res.data : null);
  }

/**
 * Guardar Nueva Sociedad
 * @param {*} data datos de Marca
 * @param {*} cancelToken token para cancelacion
 */
export function postProveedorGPS(data, cancelToken = null) {
  return axios
    .post(`${BACKEND}/proveedorGPS/`, data, {
      cancelToken: cancelToken,
    })
    .then((res) => res.data);
}
/**
 * Actualizar Sociedad
 * @param {String}  id
 * @param {Object} data datos del registro
 * @param {*} cancelToken token para cancelacion
 */
export function putProveedorGPS(id, data, cancelToken = null) {
  return axios
    .put(`${BACKEND}/proveedorGPS/${id}`, data, {
      cancelToken: cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}

/**
 * Eliminar Sociedad dado el ID
 * @param {String}  id
 * @param {*} cancelToken token para cancelacion
 */
export function deleteProveedorGPS(id, cancelToken = null) {
  return axios
    .delete(`${BACKEND}/proveedorGPS/${id}`, {
      cancelToken: cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}