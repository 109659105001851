import axios from 'axios';
import { BACKEND } from '../../constants/urls'
import { auth } from '../../services/firebase';

/**
 * Obtener Solicitudes de Baja
 * @param {Object} filter filtros
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export function getSolicitudesBaja(filter = {}, selects = [], populates = [], cancelToken = null) {
	let url = `${BACKEND}/solicitudes-baja/`;
	if (selects) selects.forEach(select => {
		url = url + "/" + select;
	});
	if (populates) populates.forEach(populate => {
		url = url + "/" + populate;
	});
	return axios.get(url,
		{
			params: filter,
			cancelToken: cancelToken
		}).then(res => res.data ? res.data.data : []);
}

export function getSolicitudesBajaUsuario(filter = {}, selects = [], populates = [], cancelToken = null) {
	let url = `${BACKEND}/solicitudes-baja/aprobador`;
	if (selects) selects.forEach(select => {
		url = url + "/" + select;
	});
	if (populates) populates.forEach(populate => {
		url = url + "/" + populate;
	});
	return auth.currentUser.getIdToken(true).then((idToken) => {
		return axios.post(url, {},
			{
				params: filter,
				headers: {
					"authorization": `Bearer ${idToken}`
				},
				cancelToken: cancelToken
			});
	}).then(res => res.data ? res.data.data : []);
}

/**
 * Obtener de una Solicitudes de Baja
 * @param {String} id
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export function getSolicitudBaja(id, selects, populates, cancelToken = null) {
	let url = `${BACKEND}/solicitudes-baja/${id}`;
	if (selects) selects.forEach(select => {
		url = url + "/" + select;
	});
	if (populates) populates.forEach(populate => {
		url = url + "/" + populate;
	});
	return axios.get(url,
		{
			cancelToken: cancelToken
		}).then(res => res.data ? res.data.data : null);
}

/**
 * Crear Solicitud de Baja
 * @param {Object} data datos del nuevo registro
 * @param {*} cancelToken token para cancelacion
 */
export function postSolicitudesBaja(data, cancelToken = null) {
	return axios.post(`${BACKEND}/solicitudes-baja/`,
		data,
		{
			cancelToken: cancelToken
		}).then(res => res.data ? res.data : null);
}

/**
 * Aceptar Solicitud de Baja
 * @param {String} id
 * @param {*} cancelToken token para cancelacion
 */
export function postAceptarBaja(id, cancelToken = null) {
	return auth.currentUser.getIdToken(true).then((idToken) => {
		return axios.post(`${BACKEND}/solicitudes-baja/${id}/aceptar`,
			{},
			{
				headers: {
					"authorization": `Bearer ${idToken}`
				},
				cancelToken: cancelToken
			}
		);
	})
		.then(res => res.data ? res.data.data : null);

}


/**
 * Rechazar Solicitud de Baja
 * @param {String} id
 * @param {Object} comentario
 * @param {*} cancelToken token para cancelacion
 */
export function postRechazarBaja(id, comentario, cancelToken = null) {
	return auth.currentUser.getIdToken(true).then((idToken) => {
		return axios.post(`${BACKEND}/solicitudes-baja/${id}/rechazar`,
			{
				comentario: comentario
			},
			{
				headers: {
					"authorization": `Bearer ${idToken}`
				},
				cancelToken: cancelToken
			}
		);
	})
		.then(res => res.data ? res.data.data : null);

}