import React, { createContext, useState } from 'react';
import MaterialTable from '../../../components/materialTable';
import useSWR from 'swr';
import { Fragment } from 'react';
import { useHistory } from 'react-router';
import { AJUSTES_CONFIGURACION } from '../../../constants/routes';
import {
  getGruposSoftware,
  postGrupoSoftware,
  putGrupoSoftware,
  deleteGrupoSoftware,
} from '../../../services/request/requestGrupoSoftware';
import DialogoCRUDAgrupacionSoftware from './dialogoCRUDAgrupacionSoftware';

export const TablaAgrupacionesSoftwareContext = createContext({
  openDialogoCRUD: false,
  setOpenDialogoCRUD: () => {},
  tituloDialogo: '',
  setTituloDialogo: () => {},
  initialValues: '',
  setInitialValues: () => {},
  handleAceptar: () => {},
  disableEditing: false,
  setDisableEditing: () => {},
});

function getData() {
  return getGruposSoftware({}, ['software', '_id', 'descripcion', 'fecha_creacion'], ['_marca_ref.marca']).then(
    (software) => {
      const datos = [];
      console.log('Datos software: ', software);
      software.data.forEach((a) => {
        const data = {
          ...a,
          marca: a._marca_ref.marca,
        };
        datos.push(data);
      });
      console.log('Datos: ', datos);
      return datos;
    },
  );
}

export default function TablaMarcasSoftware() {
  const { data: grupos, mutate } = useSWR('get:grupos-software', (key) => getData(), { refreshInterval: 0 });
  const [openDialogoCRUD, setOpenDialogoCRUD] = useState(false);
  const [tituloDialogo, setTituloDialogo] = useState('');
  const [initialValues, setInitialValues] = useState({
    id: null,
    software: '',
    descripcion: '',
    marca: '',
  });
  const [disableEditing, setDisableEditing] = useState(false);
  const history = useHistory();

  const handleOpenAgregar = (titulo) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      software: '',
      descripcion: '',
      marca: '',
    });
    setDisableEditing(false);
  };

  const handleOpenModificar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      software: row.software,
      descripcion: row.descripcion,
      marca: row._marca_ref ? { value: row._marca_ref._id, label: row._marca_ref.marca } : '',
      id: row._id,
    });
    setDisableEditing(false);
  };

  const handleOpenQuitar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      software: row.software,
      descripcion: row.descripcion,
      marca: row._marca_ref ? { value: row._marca_ref._id, label: row._marca_ref.marca } : '',
      id: row._id,
    });
    setDisableEditing(true);
  };

  const handleAceptar = async (values) => {
    if (tituloDialogo) {
      console.log('Datos enviados: ', values);
      if (tituloDialogo.includes('Nuev')) {
        await postGrupoSoftware({
          ...values,
          _marca_ref: values.marca.value,
        });
      } else if (tituloDialogo.includes('Modificar'))
        await putGrupoSoftware(values.id, {
          software: values.software,
          descripcion: values.descripcion,
          _marca_ref: values.marca.value ? values.marca.value : null,
        });
      else if (tituloDialogo.includes('Eliminar')) await deleteGrupoSoftware(values.id);
    }
    mutate();
    setOpenDialogoCRUD(false);
  };

  const handleVolver = () => {
    history.push(AJUSTES_CONFIGURACION);
  };

  const columnsGruposSoftware = [
    {
      title: 'ID',
      field: '_id',
      align: 'center',
    },
    {
      title: 'Agrupación',
      field: 'software',
      align: 'center',
      defaultSort: 'asc',
    },
    {
      title: 'Marca Asociada',
      field: 'marca',
      align: 'center',
    },
    {
      title: 'Descripción',
      field: 'descripcion',
      align: 'center',
    },
    {
      title: 'Fecha Creación',
      field: 'fecha_creacion',
      align: 'center',
    },
  ];

  return (
    <Fragment>
      <TablaAgrupacionesSoftwareContext.Provider
        value={{
          openDialogoCRUD,
          setOpenDialogoCRUD,
          tituloDialogo,
          setTituloDialogo,
          initialValues,
          setInitialValues,
          handleAceptar,
          disableEditing,
          setDisableEditing,
        }}
      >
        <MaterialTable
          actions={[
            {
              tooltip: 'Agregar Marca',
              icon: 'add',
              onClick: (event, row) => handleOpenAgregar('Nueva Grupo de Software'),
              isFreeAction: true,
            },
            {
              tooltip: 'Editar',
              icon: 'edit',
              onClick: (event, row) => handleOpenModificar('Modificar Grupo de Software', row),
            },
            {
              tooltip: 'Eliminar',
              icon: 'delete',
              onClick: (event, row) => handleOpenQuitar('Eliminar Grupo de Software', row),
            },
            {
              tooltip: 'Volver',
              icon: 'arrow_back',
              onClick: (event, row) => handleVolver(),
              isFreeAction: true,
            },
          ]}
          title="Agrupación de Softwares"
          is_loading={!grupos && !Array.isArray(grupos)}
          data={Array.isArray(grupos) ? grupos : []}
          columns={columnsGruposSoftware}
          filtering={false}
          grouping={false}
          exportButtonPDF={true}
          exportButtonExcel={true}
          empty_rows={true}
          page_size={20}
        />
        <DialogoCRUDAgrupacionSoftware />
      </TablaAgrupacionesSoftwareContext.Provider>
    </Fragment>
  );
}
