import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import MaterialTable from '../../../components/materialTable';
import { ListadoActivosContext } from '.';
import { Button, Checkbox, Typography } from '@material-ui/core';
import { CheckCircle as CheckCircleIcon, List } from '@material-ui/icons';
import Moment from 'moment';
import * as PERMISSIONS from '../../../constants/permissions';
import { MainContext } from '../../../App';
import DialogEliminar from './dialogEliminar';
import DialogActivar from './dialogActivar';
import DialogAnularActivar from './dialogAnularActivar';
import DialogBaja from './dialogBaja';
import DialogAnularBaja from './dialogAnularBaja';
import { MTableAction } from '@material-table/core';
import 'rsuite/dist/rsuite.min.css';
import { DateRangePicker } from 'rsuite';
import SelectSearch from '../../../components/SelectSearch';

export default function TablaResultadoActivos(props) {
  const { permisos } = useContext(MainContext);
  const {
    data,
    handleVerActivo,
    mostrarResultado,
    handleExport,
    isLoading,
    OpenSeleccionados,
    OpenSeleccionadosBaja,
    SetOpenSeleccionados,
    SetOpenSeleccionadosBaja,
  } = useContext(ListadoActivosContext);
  const [columns, setColumns] = useState([]);
  const [actions, setActions] = useState([]);
  const [activoSeleccionado, setActivoSeleccionado] = useState(null);
  const [openEliminarActivo, setOpenEliminarActivo] = useState(false);
  const [openActivar, setOpenActivar] = useState(false);
  const [openAnularActivar, setOpenAnularActivar] = useState(false);
  const [openBaja, setOpenBaja] = useState(false);
  const [openAnularBaja, setOpenAnularBaja] = useState(false);
  const [marcas, setMarcas] = useState([]);
  const [clases, setClases] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [contratos, setContratos] = useState([]);
  const [gerencias, setGerencias] = useState([]);
  const [sociedades, setSociedades] = useState([]);
  const [colores, setColores] = useState([]);
  const [condiciones, setCondiciones] = useState([]);
  const [tipoRecursos, setTipoRecursos] = useState([]);
  const [ubicaciones, setUbicaciones] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const gestionDatos = () => {
    const marcas = [...new Map(data.map((v) => [v.marca, v])).values()];
    const modelos = [...new Map(data.map((v) => [v.modelo, v])).values()];
    const clases = [...new Map(data.map((v) => [v._clase_ref, v])).values()];
    const condiciones = [...new Map(data.map((v) => [v.condicion_actual, v])).values()];
    const tipos_Recursos = [...new Map(data.map((v) => [v.tipo_recurso, v])).values()];
    const ubicaciones = [...new Map(data.map((v) => [v.ubicacion, v])).values()];
    const contratos = [...new Map(data.map((v) => [v._contrato_ref, v])).values()];
    const gerencias = [...new Map(data.map((v) => [v._gerencia_ref, v])).values()];
    const sociedades = [...new Map(data.map((v) => [v.sociedad, v])).values()];
    const colores = [...new Map(data.map((v) => [v.color, v])).values()];
    const proveedores = [...new Map(data.map((v) => [v.proveedor, v])).values()];
    const optMarcas = marcas.map((m) => m.marca);
    const optClases = clases.map((m) => ({ clase: m._clase_ref }));
    const optCondiciones = condiciones.map((m) => ({ condicion: m.condicion_actual }));
    const optTipoRecursos = tipos_Recursos.map((m) => ({ tipo_recurso: m.tipo_recurso }));
    const optUbicaciones = ubicaciones.map((m) => ({ ubicacion: m.ubicacion }));
    const optModelos = modelos.map((m) => ({ modelo: m.modelo }));
    const optContratos = contratos.map((m) => ({ contrato: m._contrato_ref, codigo: m.codigo_contrato }));
    const optGerencias = gerencias.map((m) => ({ gerencia: m._gerencia_ref }));
    const optSociedades = sociedades.map((m) => ({ sociedad: m.sociedad }));
    const optColores = colores.map((m) => ({ color: m.color }));
    const optProveedores = proveedores.map((m) => ({ proveedor: m.proveedor }));

    setMarcas(optMarcas);
    setClases(optClases);
    setCondiciones(optCondiciones);
    setTipoRecursos(optTipoRecursos);
    setUbicaciones(optUbicaciones);
    setModelos(optModelos);
    setContratos(optContratos);
    setGerencias(optGerencias);
    setSociedades(optSociedades);
    setColores(optColores);
    setProveedores(optProveedores);
  };

  const handleRowSelection = (row, isSelected) => {
    if (isSelected) {
      setSelectedRows((prev) => [...prev, row]);
    } else {
      setSelectedRows((prev) => prev.filter((r) => r !== row));
    }
    row.checked = isSelected;
    revisarPermisos();
  };

  /**
   * Método encargado de abrir o cerrar el popup para eliminar.
   * @param {*} seleccionado Datos del activo.
   * @param {*} open Abierto o cerrado.
   */
  function handleOpenEliminar(seleccionado, open) {
    setActivoSeleccionado(seleccionado);
    setOpenEliminarActivo(open);
  }

  /**
   * Método encargado de abrir o cerrar el popup para activar.
   * @param {*} seleccionado Datos del activo.
   * @param {*} open Abierto o cerrado.
   */
  function handleOpenActivar(seleccionado, open) {
    setActivoSeleccionado(seleccionado);
    setOpenActivar(open);
  }

  /**
   * Método encargado de abrir o cerrar el popup para anular activar.
   * @param {*} seleccionado Datos del activo.
   * @param {*} open Abierto o cerrado.
   */
  function handleOpenAnularActivar(seleccionado, open) {
    setActivoSeleccionado(seleccionado);
    setOpenAnularActivar(open);
  }

  /**
   * Método encargado de abrir o cerrar el popup para dar de baja.
   * @param {*} seleccionado Datos del activo.
   * @param {*} open Abierto o cerrado.
   */
  function handleOpenBaja(seleccionado, open) {
    setActivoSeleccionado(seleccionado);
    setOpenBaja(open);
  }

  /**
   * Método encargado de abrir o cerrar el popup para anular baja.
   * @param {*} seleccionado Datos del activo.
   * @param {*} open Abierto o cerrado.
   */
  function handleOpenAnularBaja(seleccionado, open) {
    setActivoSeleccionado(seleccionado);
    setOpenAnularBaja(open);
  }

  function comparaFechaEntre(rangoFiltro, datosFila) {
    let startDate = Moment(rangoFiltro[0]);
    let endDate = Moment(rangoFiltro[1]);
    let fecha_comparar = Moment(datosFila, 'DD/MM/YYYY');
    return fecha_comparar.isBetween(startDate, endDate);
  }

  const filterSearchMarca = (term, rowData) => {
    if (rowData.marca) {
      if (Array.isArray(term)) {
        return term.length === 0 || term.some((x) => rowData.marca == x);
      } else {
        return rowData.marca.toLowerCase().includes(term.toLowerCase());
      }
    }
    return term.length === 0;
  };

  const filterSearchClase = (term, rowData) => {
    if (rowData._clase_ref) {
      if (Array.isArray(term)) {
        return term.length === 0 || term.some((x) => rowData._clase_ref == x);
      } else {
        return rowData._clase_ref.toLowerCase().includes(term.toLowerCase());
      }
    }
    return term.length === 0;
  };

  const filterSearchModelo = (term, rowData) => {
    if (rowData.modelo) {
      if (Array.isArray(term)) {
        return term.length === 0 || term.some((x) => rowData.modelo == x);
      } else {
        return rowData.modelo.toLowerCase().includes(term.toLowerCase());
      }
    }
    return term.length === 0;
  };

  const filterSearchCondiciones = (term, rowData) => {
    if (rowData.condicion_actual) {
      if (Array.isArray(term)) {
        return term.length === 0 || term.some((x) => rowData.condicion_actual == x);
      } else {
        return rowData.condicion_actual.toLowerCase().includes(term.toLowerCase());
      }
    }
    return term.length === 0;
  };

  const filterSearchTipoRecursos = (term, rowData) => {
    if (rowData.tipo_recurso) {
      if (Array.isArray(term)) {
        return term.length === 0 || term.some((x) => rowData.tipo_recurso == x);
      } else {
        return rowData.tipo_recurso.toLowerCase().includes(term.toLowerCase());
      }
    }
    return term.length === 0;
  };

  const filterSearchUbicaciones = (term, rowData) => {
    if (rowData.ubicacion) {
      if (Array.isArray(term)) {
        return term.length === 0 || term.some((x) => rowData.ubicacion == x);
      } else {
        return rowData.ubicacion.toLowerCase().includes(term.toLowerCase());
      }
    }
    return term.length === 0;
  };

  const filterSearchContrato = (term, rowData) => {
    if (rowData._contrato_ref) {
      if (Array.isArray(term)) {
        return term.length === 0 || term.some((x) => rowData._contrato_ref == x);
      } else {
        return rowData._contrato_ref.toLowerCase().includes(term.toLowerCase());
      }
    }
    return term.length === 0;
  };

  const filterSearchCodigoContrato = (term, rowData) => {
    if (rowData.codigo_contrato) {
      if (Array.isArray(term)) {
        return term.length === 0 || term.some((x) => rowData.codigo_contrato == x);
      } else {
        return rowData.codigo_contrato.toLowerCase().includes(term.toLowerCase());
      }
    }
    return term.length === 0;
  };

  const filterSearchGerencia = (term, rowData) => {
    if (rowData._gerencia_ref) {
      if (Array.isArray(term)) {
        return term.length === 0 || term.some((x) => rowData._gerencia_ref == x);
      } else {
        return rowData._gerencia_ref.toLowerCase().includes(term.toLowerCase());
      }
    }
    return term.length === 0;
  };

  const filterSearchSociedad = (term, rowData) => {
    if (rowData.sociedad) {
      if (Array.isArray(term)) {
        return term.length === 0 || term.some((x) => rowData.sociedad == x);
      } else {
        return rowData.sociedad.toLowerCase().includes(term.toLowerCase());
      }
    }
    return term.length === 0;
  };

  const filterSearchColores = (term, rowData) => {
    if (rowData.color) {
      if (Array.isArray(term)) {
        return term.length === 0 || term.some((x) => rowData.color == x);
      } else {
        return rowData.color.toLowerCase().includes(term.toLowerCase());
      }
    }
    return term.length === 0;
  };

  const filterSearchProveedores = (term, rowData) => {
    if (rowData.proveedor) {
      if (Array.isArray(term)) {
        return term.length === 0 || term.some((x) => rowData.proveedor == x);
      } else {
        return rowData.proveedor.toLowerCase().includes(term.toLowerCase());
      }
    }
    return term.length === 0;
  };

  const revisarPermisos = useCallback(() => {
    const actions = [
      {
        icon: 'delete',
        tooltip: 'Eliminar',
        position: 'row',
        disabled: !permisos[PERMISSIONS.ELIMINAR_ACTIVOS],
        hidden: !permisos[PERMISSIONS.ELIMINAR_ACTIVOS],
        onClick: (event, row) => handleOpenEliminar(row, true),
      },
      {
        icon: 'attach_money',
        tooltip: 'Activar',
        position: 'row',
        onClick: (evt, row) => handleOpenActivar(row, true),
      },
      {
        icon: 'money_off',
        tooltip: 'Anular Activación',
        position: 'row',
        onClick: (evt, row) => handleOpenAnularActivar(row, true),
      },
      {
        icon: 'get_app',
        tooltip: 'Dar de Baja',
        position: 'row',
        onClick: (evt, row) => handleOpenBaja(row, true),
      },
      {
        icon: 'publish',
        tooltip: 'Anular Baja',
        position: 'row',
        onClick: (evt, row) => handleOpenAnularBaja(row, true),
      },
      {
        icon: 'chevron_right',
        tooltip: 'Ver Detalles',
        position: 'row',
        onClick: (evt, row) => handleVerActivo(row),
      },
      {
        icon: 'asignar_activos',
        tooltip: 'Asignar',
        position: 'row',
        isFreeAction: true,
        onClick: () => {},
      },
      {
        icon: 'baja_masiva',
        tooltip: 'Baja Masiva',
        position: 'row',
        disabled: !permisos[PERMISSIONS.VER_BAJA],
        hidden: !permisos[PERMISSIONS.VER_BAJA],
        isFreeAction: true,
        onClick: () => {},
      },
    ];
    setActions(actions);

    const columnas = [
      {
        title: 'Selección',
        sorting: false,
        grouping: false,
        render: (row) => (
          <Checkbox checked={row.checked || false} onChange={(e) => handleRowSelection(row, e.target.checked)} />
        ),
      },
      {
        title: 'Código Activo',
        field: 'codigo_activo',
        defaultSort: 'desc',
      },
      {
        title: 'Código Contable',
        field: 'codigo_contable',
        hidden: true,
      },
      {
        title: 'Activado',
        render: (rowData) => (rowData.is_activo_contable ? <CheckCircleIcon /> : ''),
      },
      {
        title: 'No Activable',
        hidden: true,
        render: (rowData) => (rowData.is_no_activable ? <CheckCircleIcon /> : ''),
      },
      {
        title: 'Estado Contable',
        field: 'estado_activacion',
        hidden: true,
      },
      {
        title: 'Tipo de Recurso',
        field: 'tipo_recurso',
        align: 'center',
        customFilterAndSearch: (term, rowData) => filterSearchTipoRecursos(term, rowData),
        filterComponent: (propsFilter) => (
          <SelectSearch
            options={tipoRecursos.map((tipo) => ({ id: tipo.tipo_recurso, nombre: tipo.tipo_recurso }))}
            input={{ name: 'filtroTipoRecurso' }}
            meta={{}}
            multiple
            limitTags={1}
            charCountLimit={12}
            maxTagWidth="105px"
            size="small"
            type="text"
            change={(name, value) => {
              propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
            }}
          />
        ),
      },
      {
        title: 'Clase',
        field: '_clase_ref',
        align: 'center',
        customFilterAndSearch: (term, rowData) => filterSearchClase(term, rowData),
        filterComponent: (propsFilter) => (
          <SelectSearch
            options={clases.map((clase) => ({ id: clase.clase, nombre: clase.clase }))}
            input={{ name: 'filtroClase' }}
            meta={{}}
            multiple
            limitTags={1}
            charCountLimit={12}
            maxTagWidth="105px"
            size="small"
            type="text"
            change={(name, value) => {
              propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
            }}
          />
        ),
      },
      {
        title: 'Grupo Contable',
        field: 'grupo_contable',
        hidden: true,
      },
      {
        title: 'Descripción',
        field: 'descripcion',
        cellStyle: {
          minWidth: '400px',
        },
      },
      {
        title: 'Condición',
        field: 'condicion_actual',
        align: 'center',
        customFilterAndSearch: (term, rowData) => filterSearchCondiciones(term, rowData),
        filterComponent: (propsFilter) => (
          <SelectSearch
            options={condiciones.map((condicion) => ({ id: condicion.condicion, nombre: condicion.condicion }))}
            input={{ name: 'filtroCondicion' }}
            meta={{}}
            multiple
            limitTags={1}
            charCountLimit={12}
            maxTagWidth="105px"
            size="small"
            type="text"
            change={(name, value) => {
              propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
            }}
          />
        ),
      },
      {
        title: 'Ubicación',
        field: 'ubicacion',
        align: 'center',
        customFilterAndSearch: (term, rowData) => filterSearchUbicaciones(term, rowData),
        filterComponent: (propsFilter) => (
          <SelectSearch
            options={ubicaciones.map((ubicacion) => ({ id: ubicacion.ubicacion, nombre: ubicacion.ubicacion }))}
            input={{ name: 'filtroUbicacion' }}
            meta={{}}
            multiple
            limitTags={1}
            charCountLimit={12}
            maxTagWidth="105px"
            size="small"
            type="text"
            change={(name, value) => {
              propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
            }}
          />
        ),
      },
      {
        title: 'Posición',
        field: 'detalle_ubicacion',
      },
      {
        title: 'Número de Serie',
        field: 'serial_number',
      },
      {
        title: 'Marca',
        field: 'marca',
        align: 'center',
        customFilterAndSearch: (term, rowData) => filterSearchMarca(term, rowData),
        filterComponent: (propsFilter) => (
          <SelectSearch
            options={marcas.map((m) => ({ id: m, nombre: m }))}
            input={{ name: 'filtroMarca' }}
            meta={{}}
            multiple
            limitTags={1}
            charCountLimit={12}
            maxTagWidth="105px"
            size="small"
            type="text"
            change={(name, value) => {
              propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
            }}
          />
        ),
      },
      {
        title: 'Modelo',
        field: 'modelo',
        cellStyle: {
          minWidth: '200px',
        },
        align: 'center',
        customFilterAndSearch: (term, rowData) => filterSearchModelo(term, rowData),
        filterComponent: (propsFilter) => (
          <SelectSearch
            options={modelos.map((m) => ({ id: m.modelo, nombre: m.modelo }))}
            input={{ name: 'filtroModelo' }}
            meta={{}}
            multiple
            limitTags={1}
            charCountLimit={12}
            maxTagWidth="105px"
            size="small"
            type="text"
            change={(name, value) => {
              propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
            }}
          />
        ),
      },
      {
        title: 'Asignado a',
        field: '_asignado_ref',
        cellStyle: {
          minWidth: '150px',
        },
      },
      {
        title: 'Fecha Asignación',
        field: 'fecha_asignacion',
        filterComponent: ({ columnDef, onFilterChanged }) => (
          <DateRangePicker
            placeholder="Filtrar Fecha"
            showOneCalendar
            format="dd/MM/yyyy"
            onChange={(e) => {
              onFilterChanged(columnDef.tableData.id, e);
            }}
          />
        ),
        customFilterAndSearch: (filter, rowData) => comparaFechaEntre(filter, rowData.fecha_asignacion),
      },
      {
        title: 'RUT del Asignado',
        field: 'rut_usuario',
        hidden: true,
      },
      {
        title: 'Email del Asignado',
        field: 'mail_usuario',
        hidden: true,
      },
      {
        title: 'Gerencia',
        field: '_gerencia_ref',
        align: 'center',
        customFilterAndSearch: (term, rowData) => filterSearchGerencia(term, rowData),
        filterComponent: (propsFilter) => (
          <SelectSearch
            options={gerencias.map((clase) => ({ id: clase.gerencia, nombre: clase.gerencia }))}
            input={{ name: 'filtroGerencia' }}
            meta={{}}
            multiple
            limitTags={1}
            charCountLimit={12}
            maxTagWidth="105px"
            size="small"
            type="text"
            change={(name, value) => {
              propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
            }}
          />
        ),
      },
      {
        title: 'Contrato',
        field: '_contrato_ref',
        cellStyle: {
          minWidth: '200px',
        },
        align: 'center',
        customFilterAndSearch: (term, rowData) => filterSearchContrato(term, rowData),
        filterComponent: (propsFilter) => (
          <SelectSearch
            options={contratos.map((clase) => ({ id: clase.contrato, nombre: clase.contrato }))}
            input={{ name: 'filtroContrato' }}
            meta={{}}
            multiple
            limitTags={1}
            charCountLimit={12}
            maxTagWidth="105px"
            size="small"
            type="text"
            change={(name, value) => {
              propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
            }}
          />
        ),
      },
      {
        title: 'Cod. Contrato',
        field: 'codigo_contrato',
        align: 'center',
        customFilterAndSearch: (term, rowData) => filterSearchCodigoContrato(term, rowData),
        filterComponent: (propsFilter) => (
          <SelectSearch
            options={contratos.map((clase) => ({ id: clase.codigo, nombre: clase.codigo }))}
            input={{ name: 'filtroCodigoContrato' }}
            meta={{}}
            multiple
            limitTags={1}
            charCountLimit={12}
            maxTagWidth="105px"
            size="small"
            type="text"
            change={(name, value) => {
              propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
            }}
          />
        ),
      },
      {
        title: 'Contrato Anterior',
        field: 'contrato_anterior',
        align: 'center',
      },
      {
        title: 'Cod. Contrato Anterior',
        field: 'contrato_anterior_codigo',
        align: 'center',
      },
      {
        title: 'Fecha Término Contrato',
        field: 'fecha_termino_contrato',
        filterComponent: ({ columnDef, onFilterChanged }) => (
          <DateRangePicker
            placeholder="Filtrar Fecha"
            showOneCalendar
            format="dd/MM/yyyy"
            onChange={(e) => {
              onFilterChanged(columnDef.tableData.id, e);
            }}
          />
        ),
        customFilterAndSearch: (filter, rowData) => comparaFechaEntre(filter, rowData.fecha_termino_contrato),
      },
      {
        title: 'Precio de Compra',
        field: 'valor',
        hidden: !permisos[PERMISSIONS.VER_PRECIO_ACTIVO],
        hiddenByColumnsButton: !permisos[PERMISSIONS.VER_PRECIO_ACTIVO],
        render: (row) => `$${row.valor}`,
        cellStyle: {
          minWidth: '125px',
        },
      },
      {
        title: 'Patente',
        field: 'patente',
      },
      {
        title: 'Color',
        field: 'color',
        align: 'center',
        customFilterAndSearch: (term, rowData) => filterSearchColores(term, rowData),
        filterComponent: (propsFilter) => (
          <SelectSearch
            options={colores.map((clase) => ({ id: clase.color, nombre: clase.color }))}
            input={{ name: 'filtroColor' }}
            meta={{}}
            multiple
            limitTags={1}
            charCountLimit={12}
            maxTagWidth="105px"
            size="small"
            type="text"
            change={(name, value) => {
              propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
            }}
          />
        ),
      },
      {
        title: 'N° Chasis',
        field: 'numero_chasis',
      },
      {
        title: 'N° Motor',
        field: 'numero_motor',
      },
      {
        title: 'Kilometraje',
        field: 'kilometraje',
      },
      {
        title: 'Fecha Kilometraje',
        field: 'fecha_kilometraje',
      },
      {
        title: 'Año',
        field: 'ano',
      },
      {
        title: 'Transmisión',
        field: 'transmision',
      },
      {
        title: 'Fecha Activación',
        field: 'fecha_activacion',
        render: (row) => (row.fecha_activacion ? Moment(row.fecha_activacion).format('DD/MM/YYYY') : '--'),
      },
      {
        title: 'Fecha Baja',
        field: 'fecha_baja',
        render: (row) => (row.fecha_baja ? Moment(row.fecha_baja).format('DD/MM/YYYY') : '--'),
      },
      {
        title: 'Proveedor',
        field: 'proveedor',
        align: 'center',
        customFilterAndSearch: (term, rowData) => filterSearchProveedores(term, rowData),
        filterComponent: (propsFilter) => (
          <SelectSearch
            options={proveedores.map((proveedor) => ({ id: proveedor.proveedor, nombre: proveedor.proveedor }))}
            input={{ name: 'filtroProveedor' }}
            meta={{}}
            multiple
            limitTags={1}
            charCountLimit={12}
            maxTagWidth="105px"
            size="small"
            type="text"
            change={(name, value) => {
              propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
            }}
          />
        ),
      },
      {
        title: 'N° de Factura',
        field: 'numero_factura',
      },
      {
        title: 'Fecha Factura',
        field: 'fecha_factura',
      },
      {
        title: 'Sociedad',
        field: 'sociedad',
        align: 'center',
        customFilterAndSearch: (term, rowData) => filterSearchSociedad(term, rowData),
        filterComponent: (propsFilter) => (
          <SelectSearch
            options={sociedades.map((clase) => ({ id: clase.sociedad, nombre: clase.sociedad }))}
            input={{ name: 'filtroSociedad' }}
            meta={{}}
            multiple
            limitTags={1}
            charCountLimit={12}
            maxTagWidth="105px"
            size="small"
            type="text"
            change={(name, value) => {
              propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
            }}
          />
        ),
      },
      {
        title: 'Contrato Leasing',
        field: 'contrato_leasing',
      },
    ];
    setColumns(columnas);
  }, [permisos, handleVerActivo]);

  useEffect(() => {
    gestionDatos();
    revisarPermisos();
  }, [revisarPermisos, OpenSeleccionados, OpenSeleccionadosBaja]);

  const tableTitle = `Activos ${selectedRows.length > 0 ? ', seleccionados: ' + selectedRows.length : ''}`;

  return (
    <Fragment>
      {mostrarResultado && (
        //Listado de activos
        <MaterialTable
          title={tableTitle}
          is_loading={isLoading}
          columns={columns}
          data={data}
          actions={actions}
          export_function={() => handleExport(columns)}
          exportButtonPDF={false}
          exportButtonExcel={true}
          lockheaders={true}
          components={{
            Action: (propData) => {
              const rowData = propData.data;
              if (propData.action.icon === 'attach_money') {
                propData.action.hidden = rowData.is_activo_contable || !permisos[PERMISSIONS.VER_ACTIVAR];
                propData.action.disabled = rowData.is_activo_contable;
              }
              // Se comprueba si el usuario tiene el permiso de ver el detalle del activo y si este esta eliminado (Dado de Baja)
              if (propData.action.icon === 'chevron_right' && !rowData.is_eliminado) {
                propData.action.hidden = !permisos[PERMISSIONS.VER_DETALLE_ACTIVO];
                propData.action.disabled = !permisos[PERMISSIONS.VER_DETALLE_ACTIVO]; //
              } //Si esta dado de baja, se verifica si posee permiso para ver el detalle
              else if (propData.action.icon === 'chevron_right' && rowData.is_eliminado) {
                propData.action.hidden = !permisos[PERMISSIONS.VER_DETALLE_ACTIVO];
                propData.action.disabled = !permisos[PERMISSIONS.VER_DETALLE_BAJA]; //
              }
              if (propData.action.icon === 'money_off') {
                propData.action.hidden = !rowData.is_activo_contable || !permisos[PERMISSIONS.VER_ANULAR_ACTIVAR];
                propData.action.disabled = !rowData.is_activo_contable;
              }
              if (propData.action.icon === 'get_app') {
                propData.action.hidden = rowData.is_eliminado || !permisos[PERMISSIONS.VER_BAJA];
                propData.action.disabled = rowData.is_eliminado;
              }
              if (propData.action.icon === 'publish') {
                propData.action.hidden = !rowData.is_eliminado || !permisos[PERMISSIONS.VER_ANULAR_BAJA];
                propData.action.disabled = !rowData.is_eliminado;
              }
              if (propData.action.icon === 'asignar_activos') {
                return (
                  <Button
                    color="primary"
                    style={{
                      color: 'white',
                      backgroundColor: '#731f1f',
                    }}
                    variant="contained"
                    onClick={() => {
                      SetOpenSeleccionados(true);
                    }}
                  >
                    <List />
                    {'Asignar Activos'}
                  </Button>
                );
              }
              if (propData.action.icon === 'baja_masiva') {
                return (
                  !propData.action.hidden && (
                    <Button
                      color="primary"
                      style={{
                        color: 'white',
                        backgroundColor: '#731f1f',
                        marginLeft: 10,
                      }}
                      variant="contained"
                      onClick={() => {
                        SetOpenSeleccionadosBaja(true);
                      }}
                    >
                      <List />
                      {'Baja Masiva'}
                    </Button>
                  )
                );
              }
              return <MTableAction {...propData} />;
            },
          }}
        />
      )}
      {/* POPUP PARA ELIMINAR */}
      <DialogEliminar
        activo_seleccionado={activoSeleccionado}
        is_open={openEliminarActivo}
        handle_open={handleOpenEliminar}
      />
      {/* POPUP PARA ACTIVAR */}
      <DialogActivar activo_seleccionado={activoSeleccionado} is_open={openActivar} handle_open={handleOpenActivar} />
      {/* POPUP PARA ANULAR ACTIVAR */}
      <DialogAnularActivar
        activo_seleccionado={activoSeleccionado}
        is_open={openAnularActivar}
        handle_open={handleOpenAnularActivar}
      />
      {/* POPUP PARA BAJA */}
      <DialogBaja activo_seleccionado={activoSeleccionado} is_open={openBaja} handle_open={handleOpenBaja} />
      {/* POPUP PARA ANULAR BAJA */}
      <DialogAnularBaja
        activo_seleccionado={activoSeleccionado}
        is_open={openAnularBaja}
        handle_open={handleOpenAnularBaja}
      />
    </Fragment>
  );
}
