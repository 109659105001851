import { Card, CardContent, CardHeader, CircularProgress, Grid, makeStyles, Paper, Typography, useTheme } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getAsignacionesLicenciasSoftware } from '../../../services/request/requestLicenciasSoftware';
import InformeSoftwareContext from './context';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import variablepie from "highcharts/modules/variable-pie";
import moment from 'moment';
import 'moment/locale/es';
import clsx from 'clsx';
import MaterialTable from "@material-table/core";

const localization = require("../../../data/material-table.location.json");
moment.updateLocale('es', { months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'] });
variablepie(Highcharts);

Highcharts.setOptions({
  lang: {
    months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    shortMonths: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    shortWeekdays: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"]
  }
});



const useStyles = makeStyles(theme => ({
  center: {
    textAlign: 'center'
  },
  circularProgess: {
    margin: theme.spacing(3, 0, 3, 0)
  },
  title: {
    fontSize: 14,
  },
  leftCards: {
    margin: theme.spacing(0, 0, 2, 0)
  },
  leftCardsBodies: {
    minHeight: "440px",
    verticalAlign: "middle",
    padding: theme.spacing(2, 0, 3, 0)
  },
  leftCardtitles: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  miniCards: {
    margin: theme.spacing(0, 1, 2, 1)
  },
  miniCardsTitle: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1, 0, 1, 0),
    minHeight: "52px"
  },
  miniCardsTitleLeft: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1, 0, 1, 0),
    minHeight: "52px"
  },
  gridTabla: {
    margin: theme.spacing(0, 0, 1, 0),
    padding: theme.spacing(0, 1, 0, 1),
  },
  smWidth: {
    // width: "25%"
  }
}));

export default function GraficosInformeSoftware() {

  const { filtros } = useContext(InformeSoftwareContext);

  const classes = useStyles();

  const theme = useTheme();

  const [indicadores, setIndicadores] = useState({
    cantidadTotal: 0,
    softwarePorTipo: [],
    licenciaPorTipo: [],
    licenciasAVencerMes: 0,
    porcentajeLicenciasAVencerMes: 0,
    licenciasAVencerPorMes: [],
    licenciasAVencerPromixosMeses: [],
    licenciasALiberarseMes: 0,
    licenciasContratosNoVigentes: 0,
    licenciasALiberarsePorMes: [],
    licenciasALiberarsePromixosMeses: []
  });

  const [isLoading, setIsLoading] = useState(true);

  // const [mesVencimiento, setMesVencimiento] = useState(moment().format("MMMM YYYY"));

  const mesVencimiento = moment().format("MMMM YYYY");

  const columnasTablas = [
    { field: "codigo_activo", title: "Activo" },
    { field: "codigo_contrato", title: "Contrato" },
    { field: "software", title: "Software" },
    { field: "version", title: "Tipo" }

  ];

  const loadData = useCallback(async () => {
    setIsLoading(true);
    setIndicadores({
      cantidadTotal: 0,
      softwarePorTipo: [],
      licenciaPorTipo: [],
      licenciasAVencerMes: 0,
      porcentajeLicenciasAVencerMes: 0,
      licenciasAVencerPorMes: [],
      licenciasAVencerPromixosMeses: [],
      licenciasALiberarseMes: 0,
      licenciasContratosNoVigentes: 0,
      licenciasALiberarsePorMes: [],
      licenciasALiberarsePromixosMeses: []
    });
    const params = {}
    if (filtros.gerencias) params["_gerencia_ref_in"] = filtros.gerencias;
    if (filtros.activo) params["_bien_ref_in"] = filtros.activo;
    if (filtros.usuarios) params["_usuario_ref_in"] = filtros.usuarios;
    if (filtros.contratos) params["_contrato_ref_in"] = filtros.contratos;
    if (filtros.softwares) params["_licencia_software_ref_in"] = filtros.softwares;
    const response = await getAsignacionesLicenciasSoftware(params,
      [
        "fecha_creacion"
      ], [
      "_licencia_software_ref.nombre",
      "_licencia_software_ref.version",
      "_licencia_software_ref.tipo",
      "_licencia_software_ref.fecha_vencimiento",
      "_bien_ref.codigo_activo",
      "_contrato_ref.codigo",
      "_contrato_ref.fecha_termino_estimada"
    ]);
    if (response.status) {
      const indicadores = {
        cantidadTotal: response.data.length,
        softwarePorTipo: [],
        licenciaPorTipo: [
          {
            name: "Arriendo",
            y: 0,
            z: 50
          },
          {
            name: "Perpetua",
            y: 0,
            z: 50
          },
          // {
          //   name: "",
          //   y: 0,
          //   z: 50
          // }
        ],
        licenciasAVencerMes: 0,
        porcentajeLicenciasAVencerMes: 0,
        licenciasAVencerPorMes: [],
        licenciasAVencerPromixosMeses: [],
        licenciasALiberarseMes: 0,
        licenciasContratosNoVigentes: 0,
        licenciasALiberarsePorMes: [],
        licenciasALiberarsePromixosMeses: []
      };

      for (let i = moment().startOf('month'); i < (moment().add(6, 'months')); i.add(1, 'months')) {
        indicadores.licenciasAVencerPorMes.push([
          i.valueOf(),
          0
        ]);
        indicadores.licenciasALiberarsePorMes.push([
          i.valueOf(),
          0
        ]);
      }

      const mesActual = moment().startOf('month');

      response.data.forEach((value) => {
        const licencia = value._licencia_software_ref;
        const contrato = value._contrato_ref;
        const softwarePorTipo = indicadores.softwarePorTipo.find(a => a.name === licencia.nombre);
        if (contrato) {
          if (contrato.fecha_termino_estimada) {
            const mesContrato = moment(contrato.fecha_termino_estimada).startOf('month');
            if (mesContrato.isSame(mesActual)) {
              indicadores.licenciasALiberarseMes++;
            }
            if (mesContrato.isBefore(mesActual)) {
              indicadores.licenciasContratosNoVigentes++;
            }
            const indexMes = mesActual.clone().diff(mesContrato, "months");
            if (indexMes > -1 && indexMes < indicadores.licenciasALiberarsePorMes.length) {
              indicadores.licenciasALiberarsePorMes[indexMes][1]++;
              indicadores.licenciasALiberarsePromixosMeses.push({
                codigo_activo: value._bien_ref ? value._bien_ref.codigo_activo : "--",
                codigo_contrato: contrato ? contrato.codigo : "--",
                software: licencia ? licencia.nombre : "--",
                version: licencia ? licencia.version : "--"
              });
            }
          }

        }
        if (licencia) {
          if (value.fecha_vencimiento) {
            const mesLicencia = moment(value.fecha_vencimiento).startOf('month');
            if (mesLicencia.isSame(mesActual)) {
              indicadores.licenciasAVencerMes++;
            }
            const indexMes = mesActual.clone().diff(mesLicencia, "months");
            if (indexMes > -1 && indexMes < indicadores.licenciasAVencerPorMes.length) {
              indicadores.licenciasAVencerPorMes[indexMes][1]++;
              indicadores.licenciasAVencerPromixosMeses.push({
                codigo_activo: value._bien_ref ? value._bien_ref.codigo_activo : "--",
                codigo_contrato: contrato ? contrato.codigo : "--",
                software: licencia ? licencia.nombre : "--",
                version: licencia ? licencia.version : "--"
              });
            }
          }
          else if (licencia.fecha_vencimiento) {
            const mesLicencia = moment(licencia.fecha_vencimiento).startOf('month');
            if (mesLicencia.isSame(mesActual)) {
              indicadores.licenciasAVencerMes++;
            }
            const indexMes = mesActual.clone().diff(mesLicencia, "months");
            if (indexMes > -1 && indexMes < indicadores.licenciasAVencerPorMes.length) {
              indicadores.licenciasAVencerPorMes[indexMes][1]++;
              indicadores.licenciasAVencerPromixosMeses.push({
                codigo_activo: value._bien_ref ? value._bien_ref.codigo_activo : "--",
                codigo_contrato: contrato ? contrato.codigo : "--",
                software: licencia ? licencia.nombre : "--",
                version: licencia ? licencia.version : "--"
              });
            }
          }
          if (softwarePorTipo) {
            softwarePorTipo.y = softwarePorTipo.y + 1;
          } else {
            indicadores.softwarePorTipo.push({
              name: licencia.nombre,
              y: 1,
              z: 50
            });
          }
          if (licencia.tipo === "Arriendo") {
            indicadores.licenciaPorTipo[0].y = indicadores.licenciaPorTipo[0].y + 1;
          }
          else if (licencia.tipo === "Perpetua") {
            indicadores.licenciaPorTipo[1].y = indicadores.licenciaPorTipo[1].y + 1;
          }
        }

      });
      if (indicadores.cantidadTotal > 0) indicadores.porcentajeLicenciasAVencerMes = Math.round(indicadores.licenciasAVencerMes / indicadores.cantidadTotal * 10000) / 100;
      console.log(indicadores);
      setIndicadores(indicadores);
    }
    setIsLoading(false);


  }, [filtros]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <React.Fragment>
      {!isLoading &&
        <Grid container spacing={1}>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <Card className={clsx({ [classes.leftCards]: true, [classes.smWidth]: true })}>
              <CardHeader titleTypographyProps={{ variant: "body2", component: "h6", align: "center" }} className={classes.miniCardsTitleLeft} title={`Cantidad Total Software`} />
              <CardContent>
                <Typography variant="h5" component="h2" align="center">
                  {indicadores.cantidadTotal}
                </Typography>

              </CardContent>
            </Card>
            <Card className={classes.leftCards}>
              <CardHeader className={classes.leftCardtitles} title="Cantidad de Software por Tipo" titleTypographyProps={{ variant: "subtitle1", align: "center" }} />
              <CardContent className={classes.leftCardsBodies}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: 'variablepie',
                    },
                    title: {
                      text: null
                    },
                    legend: {
                      layout: 'vertical',
                      align: 'right',
                      verticalAlign: 'top',
                      borderWidth: 1,

                    },
                    tooltip: {
                      pointFormat: '{series.name}: <b>{point.y:.1f}</b>'
                    },
                    series: [{
                      dataLabels: {
                        enabled: true,
                        format: '{point.percentage:.1f}%',
                        distance: -30,
                        filter: {
                          property: 'percentage',
                          operator: '>',
                          value: 4
                        }
                      },
                      showInLegend: true,
                      minPointSize: 10,
                      innerSize: '60%',
                      zMin: 0,
                      name: 'Software por tipo',
                      data: indicadores.softwarePorTipo
                    }]
                  }}
                />

              </CardContent>
            </Card>
            <Card className={classes.leftCards}>
              <CardHeader className={classes.leftCardtitles} title="Distribución de Software por tipo de licencia" titleTypographyProps={{ variant: "subtitle1", align: "center" }} />
              <CardContent className={classes.leftCardsBodies}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: 'variablepie',
                    },
                    title: {
                      text: null
                    },
                    tooltip: {
                      pointFormat: '{series.name}: <b>{point.y:.1f}</b>'
                    },
                    legend: {
                      layout: 'vertical',
                      align: 'right',
                      verticalAlign: 'top',
                      borderWidth: 1
                    },
                    series: [{
                      dataLabels: {
                        enabled: true,
                        format: '{point.percentage:.1f}%',
                        distance: -30,
                        filter: {
                          property: 'percentage',
                          operator: '>',
                          value: 4
                        }
                      },
                      showInLegend: true,
                      minPointSize: 10,
                      innerSize: '60%',
                      zMin: 0,
                      name: 'Licencia por tipo',
                      data: indicadores.licenciaPorTipo
                    }]
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <Grid container spacing={1}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Grid container spacing={1}>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Card className={classes.miniCards}>
                      <CardHeader titleTypographyProps={{ variant: "body2", component: "h6", align: "center" }} className={classes.miniCardsTitle} title={`Licencias a vencer dentro de ${mesVencimiento}`} />
                      <CardContent>

                        <Typography variant="h5" component="h2" align="center" >
                          {indicadores.licenciasAVencerMes}
                        </Typography>

                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Card className={classes.miniCards}>
                      <CardHeader titleTypographyProps={{ variant: "body2", component: "h6", align: "center" }} className={classes.miniCardsTitle} title={`Porcentaje licencias a vencer ${mesVencimiento}`} />
                      <CardContent>

                        <Typography variant="h5" component="h2" align="center">
                          {indicadores.porcentajeLicenciasAVencerMes} %
                        </Typography>

                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Grid container>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Card className={classes.miniCards}>
                      <CardHeader titleTypographyProps={{ variant: "body2", component: "h6", align: "center" }} className={classes.miniCardsTitle} title={`Licencias que se liberan en ${mesVencimiento}`} />
                      <CardContent>

                        <Typography variant="h5" component="h2" align="center">
                          {indicadores.licenciasALiberarseMes}
                        </Typography>

                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Card className={classes.miniCards}>
                      <CardHeader titleTypographyProps={{ variant: "body2", component: "h6", align: "center" }} className={classes.miniCardsTitle} title={`Cantidad licencias contratos no vigentes`} />
                      <CardContent>

                        <Typography variant="h5" component="h2" align="center">
                          {indicadores.licenciasContratosNoVigentes}
                        </Typography>

                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
            <Grid container>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Card className={classes.miniCards}>
                  <CardHeader className={classes.leftCardtitles} title="Licencias a vencer en los próximos 6 meses" titleTypographyProps={{ variant: "subtitle1", align: "center" }} />
                  <CardContent>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={{
                        title: {
                          text: null
                        },
                        xAxis: {
                          type: 'datetime',
                          dateTimeLabelFormats: {
                            day: '%e %b',
                            month: '%b %Y',
                            year: '%b %Y'
                          },
                          title: {
                            text: 'Mes/Año'
                          }
                        },
                        yAxis: {
                          title: {
                            text: "Cantidad"
                          }
                        },
                        tooltip: {
                          pointFormat: '{series.name}: <b>{point.y:.1f}</b>'
                        },
                        series: [{
                          name: 'Cantidad',
                          data: indicadores.licenciasAVencerPorMes,
                          tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.x:%b %Y} -  {point.y:f}'
                          }

                        }]
                      }}
                    />
                  </CardContent>
                </Card>

              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Card className={classes.miniCards}>
                  <CardHeader className={classes.leftCardtitles} title="Licencias a liberarse en los próximos 6 meses" titleTypographyProps={{ variant: "subtitle1", align: "center" }} />
                  <CardContent>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={{
                        title: {
                          text: null
                        },
                        xAxis: {
                          type: 'datetime',
                          dateTimeLabelFormats: {
                            day: '%e %b',
                            month: '%b %Y',
                            year: '%b %Y'
                          },
                          title: {
                            text: 'Mes/Año'
                          }
                        },
                        yAxis: {
                          title: {
                            text: "Cantidad"
                          }
                        },
                        tooltip: {
                          pointFormat: '{series.name}: <b>{point.y:.1f}</b>'
                        },
                        series: [{
                          name: 'Cantidad',
                          data: indicadores.licenciasALiberarsePorMes,
                          tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.x:%b %Y} -  {point.y:f}'
                          }

                        }]
                      }}
                    />

                  </CardContent>
                </Card>

              </Grid>
            </Grid>
            <Grid container>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.gridTabla}>
                <MaterialTable
                  title={""}
                  isLoading={isLoading}
                  columns={columnasTablas}
                  localization={localization}
                  options={{
                    showTitle: false,
                    search: false,
                    toolbar: false,
                    emptyRowsWhenPaging: true,
                    minBodyHeight: "445px",
                    maxBodyHeight: "445px",
                    headerStyle:{
                      color: theme.palette.primary.contrastText,
                      backgroundColor: theme.palette.primary.main
                    }
                  }}
                  data={indicadores.licenciasAVencerPromixosMeses}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.gridTabla}>
                <MaterialTable
                  title={""}
                  isLoading={isLoading}
                  columns={columnasTablas}
                  localization={localization}
                  options={{
                    showTitle: false,
                    search: false,
                    toolbar: false,
                    emptyRowsWhenPaging: true,
                    minBodyHeight: "445px",
                    maxBodyHeight: "445px",

                    headerStyle:{
                      color: theme.palette.primary.contrastText,
                      backgroundColor: theme.palette.primary.main
                    }
                  }}
                  data={indicadores.licenciasALiberarsePromixosMeses}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      {isLoading &&
        <Paper>
          <Grid container direction="row" alignItems="center" justify="center">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.center}>
              <CircularProgress size="200px" className={classes.circularProgess} />
            </Grid>
          </Grid>
        </Paper>
      }
    </React.Fragment>
  );
}