import axios from 'axios';
import { BACKEND } from '../../constants/urls'
/**
 * Obtener tipos de recursos
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getVersionesSoftware(filter = {}, selects = [], populates = [], cancelToken = null) {
	let url = `${BACKEND}/versiones-software`;
	if (selects) selects.forEach(select => {
		url = url + "/" + select;
	});
	if (populates) populates.forEach(populate => {
		url = url + "/" + populate;
	});
  console.log("URL for populate: ", url);
	return axios.get(url,
		{
			params: filter,
			cancelToken: cancelToken
		}).then(res => res.data);
}

/**
 * Método encargado de obtener las las versiones de software de acuerdo al ID de la marca dado
 */
 export async function obtenerVersionesporMarca(idMarca) {
	try {
		let url = `${BACKEND}/versiones-software?_marca_ref_eq=${idMarca}`;
		let response = await axios.get(url);
    console.log("URL Versiones: ", url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener las las versiones de software de acuerdo al ID de la marca dado
 */
 export async function obtenerVersionesporAgrupacion(idsoftware) {
	try {
		let url = `${BACKEND}/versiones-software?_software_ref_eq=${idsoftware}`;
		let response = await axios.get(url);
    console.log("URL Versiones: ", url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Guardar Marca
 * @param {*} data datos de Marca
 * @param {*} cancelToken token para cancelacion
 */
export function postVersionSoftware(data, cancelToken = null) {
  return axios.post(`${BACKEND}/versiones-software/`, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data);
}
/**
 * Actualizar marca
 * @param {String}  id
 * @param {Object} data datos del registro
 * @param {*} cancelToken token para cancelacion
 */
export function putVersionSoftware(id, data, cancelToken = null) {
  return axios.put(`${BACKEND}/versiones-software/${id}`, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data.data : null);
}

/**
 * Eliminar marca
 * @param {String}  id
 * @param {*} cancelToken token para cancelacion
 */
export function deleteVersionSoftware(id, cancelToken = null) {
  return axios.delete(`${BACKEND}/versiones-software/${id}`,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data.data : null);
}