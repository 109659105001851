import { Button, Checkbox, Dialog, DialogActions, DialogContent, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import InformeSoftwareContext from './context';
import MaterialTable from "@material-table/core";
import { getBienes } from '../../../services/request/requestBienes';
import { getClases } from '../../../services/request/requestClase';
const localization = require("../../../data/material-table.location.json");


export default function SelectorActivos() {

  const { openSelectActivo: open, setOpenSelectActivo: setOpen, filtros, setFiltros } = useContext(InformeSoftwareContext);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [totalChecked, setTotalChecked] = useState(0);
  const [allChecked, setAllChecked] = useState(false);

  async function handleEnter() {
    setIsLoading(true);
    setTotalChecked(0);
    setData([]);
    const responseClases = await getClases({ is_computacional_eq: true });
    let clases = [];
    if (responseClases.status) clases = responseClases.data.map(a => a._id);
    const response = await getBienes(
      {
        _clase_ref_in: clases
      },
      ["codigo_activo", "codigo_contable"], []);
    let totalChecked = 0;
    if (response.status) {
      if (filtros.activos && filtros.activos.length > 0) {
        for (let i in response.data) {
          response.data[i]["checked"] = false;
          if (filtros.activos.find(a => a === response.data[i]._id)) {
            response.data[i].checked = true;
            totalChecked++;
          }
        }
      } else {
        for (let i in response.data) {
          response.data[i]["checked"] = false;
        }
      }
      setAllChecked(totalChecked === response.data.length);
      setTotalChecked(totalChecked);
      setData(response.data);
    }
    setIsLoading(false);
  }


  function changeTitleCheck(e, checked) {
    setAllChecked(checked);
    setData(data.map(a => ({ ...a, checked: checked })));
    if (!checked) {
      setTotalChecked(0);
    } else {
      setTotalChecked(data.length);
    }
  }

  function checkCell(row) {
    if (row.checked) {
      row.checked = false;
      let newtotalChecked = totalChecked - 1;
      setAllChecked(false);
      setTotalChecked(newtotalChecked);
    } else {
      row.checked = true;
      let newtotalChecked = totalChecked + 1;
      setAllChecked(false);
      setTotalChecked(newtotalChecked);
    }
  }

  const columns = [
    {
      title: <Checkbox checked={allChecked} indeterminate={(!allChecked && totalChecked > 0)} onChange={changeTitleCheck} />,
      render: (row) => <Checkbox checked={row.checked} onChange={() => checkCell(row)} />
    },
    {
      title: "Código",
      field: "codigo_activo"
    }
  ];

  const theme = useTheme();
  const smBreak = useMediaQuery(theme.breakpoints.up('xs'));

  function handleSubmit() {
    const selected = data.filter(a => a.checked).map(a => a._id);
    setFiltros({
      ...filtros,
      activos: selected
    });
    setOpen(false);
  }

  const [pageSize, setPageSize] = useState(20);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onEntered={handleEnter}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent>
        <MaterialTable
          title={"Seleccionar uno o más activos"}
          isLoading={isLoading}
          columns={columns}
          localization={localization}
          data={data ? data : []}
          style={{
            maxWidth: smBreak ? "92vw" : "72vw"
          }}
          options={{
            pageSize: pageSize,
            padding: "dense",
            headerStyle: {
              fontWeight: 'bold',
              color: "#731f1f",
            },
            emptyRowsWhenPaging: false
          }}
          onChangeRowsPerPage={(size) => setPageSize(size)}
        />
      </DialogContent>
      <DialogActions>
        <Grid container direction='row' alignItems="center" justify="center">
          <Button onClick={() => setOpen(false)} variant="outlined" color="primary" style={{ marginRight: '8px' }}>
            Cerrar
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Aceptar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}