import axios from 'axios';
import { BACKEND } from '../../constants/urls';
/**
 * Obtener municipalidades
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getMunicipalidades(filter = {}, cancelToken = null) {
  return axios
    .get(`${BACKEND}/municipalidades/`, {
      params: filter,
      cancelToken,
    })
    .then((res) => res.data);
}
/**
 * Guardar Municipalidad
 * @param {*} data datos de Municipalidad
 * @param {*} cancelToken token para cancelacion
 */
export function postMunicipalidad(data, cancelToken = null) {
  return axios
    .post(`${BACKEND}/municipalidades/`, data, {
      cancelToken,
    })
    .then((res) => res.data);
}
/**
 * Actualizar municipalidad
 * @param {String}  id
 * @param {Object} data datos del registro
 * @param {*} cancelToken token para cancelacion
 */
export function putMunicipalidad(id, data, cancelToken = null) {
  return axios
    .put(`${BACKEND}/municipalidades/${id}`, data, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}

/**
 * Eliminar municipalidad
 * @param {String}  id
 * @param {*} cancelToken token para cancelacion
 */
export function deleteMunicipalidad(id, cancelToken = null) {
  return axios
    .delete(`${BACKEND}/municipalidades/${id}`, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}
