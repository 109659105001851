import React, { useContext, useEffect, useCallback } from 'react';
import Page from './pageFormularioBienesVehiculos';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { RegistroBienesContext } from '.';
import { getSeleccionables } from '../../../services/request/requestSeleccionables';
import moment from 'moment';
import useSWR from 'swr';

const validationSchema = yup.object({
  kilometraje: yup.string().required('Campo Requerido'),
  numero_motor: yup.string().required('Campo Requerido'),
  numero_chasis: yup.string().required('Campo Requerido'),
  color: yup.string().required('Campo Requerido'),
  ano: yup.date().required('Campo Requerido'),
  cilindrada: yup.string().required('Campo Requerido'),
  traccion: yup.string().required('Campo Requerido'),
  tipo_combustible: yup.string().required('Campo Requerido'),
  transmision: yup.string().required('Campo Requerido'),
});

export default function FomularioBienesVehiculos() {
  const { formularioVehiculos, setFormularioVehiculos, setSelectedTab } = useContext(RegistroBienesContext);

  const { data: colores } = useSWR('get:colores', (key) => getSeleccionables({ context_eq: 'colores' }), {
    refreshInterval: 0,
  });

  const formik = useFormik({
    initialValues: {
      kilometraje: '',
      numero_motor: '',
      numero_chasis: '',
      color: '',
      ano: moment().startOf('year').set('month', 4).toDate(),
      cilindrada: '',
      traccion: '4x2',
      tipo_combustible: 'Bencina',
      transmision: '',
      barra_antivuelco: false,
      gps: false,
      mobileye: false,
      patente: '',
      tag: '',
    },
    onSubmit: (values) => {
      console.log(values);
      setFormularioVehiculos(values);
      setSelectedTab(4);
    },
    validationSchema,
  });

  const { resetForm } = formik;

  const resetFormulario = useCallback(() => {
    if (formularioVehiculos) {
      resetForm({ values: formularioVehiculos });
    } else resetForm();
  }, [resetForm, formularioVehiculos]);

  useEffect(() => {
    resetFormulario();
  }, [resetFormulario]);

  const handleVolver = () => {
    setSelectedTab(0);
  };

  return <Page colores={colores} formik={formik} handleVolver={handleVolver} />;
}
