import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useCallback, useContext, useEffect } from 'react';
import { TablaMarcasSoftwareContext } from './tablaMarcasSoftware';
import * as yup from 'yup';

const validationSchema = yup.object({
  marca: yup.string().required(),
});

export default function DialogoCRUDMarcaSoftware() {
  const { openDialogoCRUD, setOpenDialogoCRUD, tituloDialogo, handleAceptar, disableEditing, initialValues } =
    useContext(TablaMarcasSoftwareContext);

  const handleClose = () => setOpenDialogoCRUD(false);

  const formik = useFormik({
    initialValues,
    onSubmit: handleAceptar,
    validationSchema,
  });

  const { values, errors, touched, handleSubmit, handleChange, resetForm } = formik;

  const resetFormik = useCallback(() => {
    resetForm({ values: initialValues });
  }, [initialValues, resetForm]);

  useEffect(() => {
    resetFormik();
  }, [resetFormik]);

  return (
    <Dialog open={openDialogoCRUD} maxWidth="md" fullWidth>
      <DialogTitle>{tituloDialogo}</DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                name="marca"
                label="Marca Software"
                value={values.marca}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                disabled={disableEditing}
                error={errors.marca && touched.marca ? true : false}
                helperText={errors.marca && touched.marca ? errors.marca : ''}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                name="descripcion"
                label="Descripción"
                value={values.descripcion}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                disabled={disableEditing}
                error={errors.descripcion && touched.descripcion ? true : false}
                helperText={errors.descripcion && touched.descripcion ? errors.descripcion : ''}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
