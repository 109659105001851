import axios from 'axios';
import { BACKEND } from '../../constants/urls'
/**
 * Obtener tipos de recursos
 * @param {*} filter filtro para resultados
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export function getClases(filter = {}, selects = [], populates = [], cancelToken = null) {
	//_sub_clases_ref/is_eliminado/nombre/descripcion/fecha_creacion/is_computacional/is_vehiculo/_grupo_contable_ref/numero_grupo/_tipo_recurso_ref.nombre
	let url = `${BACKEND}/clases`;
	if (selects) selects.forEach(select => {
		url = url + "/" + select;
	});
	if (populates) populates.forEach(populate => {
		url = url + "/" + populate;
	});
	return axios.get(url,
		{
			params: filter,
			cancelToken: cancelToken
		}).then(res => res.data);
}

/**
 * Guardar Clase
 * @param {*} data datos de Clase
 * @param {*} cancelToken token para cancelacion
 */
 export function postClase(data, cancelToken = null) {
  return axios.post(`${BACKEND}/clases/`, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data);
}
/**
 * Actualizar clase
 * @param {String}  id
 * @param {Object} data datos del registro
 * @param {*} cancelToken token para cancelacion
 */
export function putClase(id, data, cancelToken = null) {
  return axios.put(`${BACKEND}/clases/${id}`, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data.data : null);
}

/**
 * Eliminar clase
 * @param {String}  id
 * @param {*} cancelToken token para cancelacion
 */
export function deleteClase(id, cancelToken = null) {
  return axios.delete(`${BACKEND}/clases/${id}`,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data.data : null);
}

/**
 * Obtener clase
 * @param {String} id 
 * @param {*} cancelToken token para cancelacion
 */
 export function getClase(id , cancelToken = null) {
	let url = `${BACKEND}/clases/${id}`;
	return axios.get(url,
		{
			cancelToken: cancelToken
		}).then(res => res.data);
}

/**
 * Método encargado de obtener todas las clases, por tipo de recurso ID.
 */
 export async function ObtenerClases(tipoRecursoID) {
	try {
		let url = `${BACKEND}/clases?_tipo_recurso_ref_eq=${tipoRecursoID}`;
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener las clases de tipo vehículo.
 * @returns Arreglo con las clases de tipo vehículo.
 */
 export async function ObtenerClasesVehiculos() {
  try {
    let url = `${BACKEND}/clases?is_vehiculo_eq=true`;
    let response = await axios.get(url);
    return response.data ? Array.from(response.data.data) : [];
  } catch (error) {
    console.error(error);
    throw error;
  }
}