import { Tooltip, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { MainContext } from '../../../../App';
import MaterialTable from '../../../../components/materialTable';
import { ASIGNAR_ACTIVOS_PERSONAS } from '../../../../constants/permissions';

function Page(props) {
  const { personas } = props;

  const { permisos } = useContext(MainContext);
  const history = useHistory();

  const columns = [
    {
      title: 'RUT',
      field: 'run_formateado',
    },
    {
      title: 'Nombre',
      field: 'nombre_completo',
    },
    {
      title: 'Email',
      field: 'contacto.email',
    },
    {
      title: 'Gerencia',
      field: 'gerencia_ref.sigla',
      width: '100px',
    },
    {
      title: 'Contrato',
      field: 'contrato',
      render: (row) => (
        <Tooltip title={row.contratos_ref && row.contratos_ref.length > 0 ? row.contratos_ref[0].nombre : '---'}>
          <Typography variant="subtitle2">
            {row.contratos_ref && row.contratos_ref.length > 0 ? row.contratos_ref[0].codigo : '---'}
          </Typography>
        </Tooltip>
      ),
    },
    {
      title: 'Activos',
      field: 'cant_bienes',
      width: '100px',
    },
  ];

  const [actions, setActions] = useState([]);

  useEffect(() => {
    if (permisos[ASIGNAR_ACTIVOS_PERSONAS])
      setActions([
        {
          icon: 'chevron_right',
          tooltip: 'Bienes',
          onClick: (event, row) => history.push(`/bienes-persona/${row._id}`),
        },
      ]);
    else setActions([]);
  }, [permisos, history]);

  return (
    <MaterialTable
      title="Personas y Activos"
      is_loading={!personas}
      data={personas}
      columns={columns}
      actions={actions}
    />
  );
}

export default Page;
