import {
  AppBar,
  Container,
  makeStyles,
  Paper,
  Tab,
  Tabs, 
  Typography
} from "@material-ui/core";
import React, { useState } from "react";
import { Fragment } from "react";
import FormularioGeneralCargaActivosNuevos from "./formularioGeneralCargaActivosNuevos";
import FormularioGeneralCargaActivosAntiguos from "./formularioGeneralCargaActivosAntiguos";

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(3),
    maxWidth: "100%"
  },
  padding: {
    padding: theme.spacing(1)
  },
  center: {
    textAlign: "center",
  },
  paddingCenter: {
    padding: theme.spacing(1),
    textAlign: "center",
  },
  fileInput: {
    top: 'unset',
    left: 'unset',
    position: 'inherit',
    marginTop: "1px",
  },
}));


export default function CargaMasivaActivos() {

  const classes = useStyles();

  const [TabIndex, SetTabIndex] = useState(0);

  return (
    <Fragment>
      <Paper>
        <Typography className={classes.paddingCenter} variant={"h4"}>Carga masiva de activos</Typography>
      </Paper>
      <br />
      <AppBar position="static">
        <Tabs value={TabIndex} onChange={(event, tabIndex) => SetTabIndex(tabIndex)}>
          <Tab label="Antiguos"></Tab>
          <Tab label="Nuevos"></Tab>
        </Tabs>
      </AppBar>
      <br />

      <Paper>
        {TabIndex === 0 &&
          <Typography className={classes.paddingCenter} variant={"h5"}>Activos Antiguos - Incluyen código activo</Typography>
        }
        {TabIndex === 1 &&
          <Typography className={classes.paddingCenter} variant={"h5"}>Activos Nuevos - Código activo autogenerado</Typography>
        }
        <Container className={classes.container}>
          {TabIndex === 0 &&
            <FormularioGeneralCargaActivosAntiguos/>
          }
          {TabIndex === 1 &&
            <FormularioGeneralCargaActivosNuevos />
          }
        </Container>
      </Paper>
    </Fragment>
  );
}