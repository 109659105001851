import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField, Typography, Paper, Box, Fab } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Moment from 'moment';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

function TabInfoBase(props) {
  const { bien, propietario, handleSubirFotoActual } = props;

  const classes = useStyles();
  const hiddenFileInput = React.useRef(null);

  const handleChange = (event) => {
    const file = event.target.files[0];
    handleSubirFotoActual(file);
  };

  const [tipo, setTipo] = useState('');

  const [formularioTipo, setFormularioTipo] = useState({});

  const checkTipo = useCallback(() => {
    if (bien._clase_ref && bien._clase_ref.is_computacional) {
      setFormularioTipo({
        disco_duro: bien._equipo_ti_ref && bien._equipo_ti_ref.disco_duro ? bien._equipo_ti_ref.disco_duro : '',
        procesador: bien._equipo_ti_ref && bien._equipo_ti_ref.procesador ? bien._equipo_ti_ref.procesador : '',
        memoria_ram: bien._equipo_ti_ref && bien._equipo_ti_ref.memoria_ram ? bien._equipo_ti_ref.memoria_ram : '',
        pulgadas_pantalla:
          bien._equipo_ti_ref && bien._equipo_ti_ref.pulgadas_pantalla ? bien._equipo_ti_ref.pulgadas_pantalla : '',
      });
      setTipo('Equipo TI');
    } else if (bien._clase_ref && bien._clase_ref.is_vehiculo) {
      setFormularioTipo({
        kilometraje: bien._vehiculo_ref && bien._vehiculo_ref.kilometraje ? bien._vehiculo_ref.kilometraje : '',
        numero_motor: bien._vehiculo_ref && bien._vehiculo_ref.numero_motor ? bien._vehiculo_ref.numero_motor : '',
        numero_chasis: bien._vehiculo_ref && bien._vehiculo_ref.numero_chasis ? bien._vehiculo_ref.numero_chasis : '',
        color: bien._vehiculo_ref && bien._vehiculo_ref.color ? bien._vehiculo_ref.color : '',
        ano: bien._vehiculo_ref && bien._vehiculo_ref.ano ? bien._vehiculo_ref.ano : '',
        tipo_combustible:
          bien._vehiculo_ref && bien._vehiculo_ref.tipo_combustible ? bien._vehiculo_ref.tipo_combustible : '',
        transmision: bien._vehiculo_ref && bien._vehiculo_ref.transmision ? bien._vehiculo_ref.transmision : '',
        cilindrada: bien._vehiculo_ref && bien._vehiculo_ref.cilindrada ? bien._vehiculo_ref.cilindrada : '',
        patente: bien._vehiculo_ref && bien._vehiculo_ref.patente ? bien._vehiculo_ref.patente : '',
        tag: bien._vehiculo_ref && bien._vehiculo_ref.tag ? bien._vehiculo_ref.tag : '',
      });
      setTipo('Vehículo');
    } else if (
      bien._clase_ref &&
      (bien._clase_ref.is_topografico || bien._clase_ref.is_otro_instrumento || bien._clase_ref.is_laboratorio)
    ) {
      setFormularioTipo({
        usos: bien.usos,
        rango_medicion: bien.rango_medicion,
        grado_precision: bien.grado_precision,
        periodo_recalibracion: bien.periodo_recalibracion,
        tolerancias: bien.tolerancias,
        accesorios: bien.accesorios,
      });
      setTipo('Equipo Laboratorio/Topografía');
    } else {
      setFormularioTipo({});
      setTipo('');
    }
  }, [bien]);

  useEffect(() => {
    checkTipo();
  }, [checkTipo]);

  return (
    <Grid container spacing={3}>
      {/* DATOS GENERALES */}
      <Grid item xs={12} container>
        {/* DATOS */}
        <Grid item lg={9} xs={12} container>
          <Grid item lg={4} md={6} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField name="contrato" label="Contrato" fullWidth variant={'outlined'} value={bien.contrato} disabled />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField name="clase" label="Clase" fullWidth variant={'outlined'} value={bien.clase} disabled />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField name="marca" label="Marca" fullWidth variant={'outlined'} value={bien.marca} disabled />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Código S/N"
              fullWidth
              variant={'outlined'}
              value={bien.serial_number}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField label="Modelo" fullWidth variant={'outlined'} value={bien.modelo} disabled />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Condición Actual"
              fullWidth
              variant={'outlined'}
              value={bien.condicion_actual === undefined ? '' : bien.condicion_actual}
              disabled
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Ubicación Física"
              fullWidth
              variant={'outlined'}
              value={bien.ubicacion === undefined ? '' : bien.ubicacion}
              disabled
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Descripción"
              fullWidth
              variant={'outlined'}
              value={bien.descripcion === undefined ? '' : bien.descripcion}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
        </Grid>
        {/* FOTO */}
        <Grid item lg={3} xs={12} className={classes.grid_imagen}>
          <Paper elevation={4} style={{ height: '100%', width: '100%' }}>
            {/* SI EL ACTIVO NO TIENE IMAGEN ACTUAL, SE MUESTRA UN BOTÓN PARA SUBIR IMAGEN */}
            {!bien.foto_actual && (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Fab onClick={() => hiddenFileInput.current.click()} color="secondary" variant="extended">
                  <svg style={{ width: 24, height: 24, marginRight: 10 }} viewBox="0 0 24 24">
                    <path
                      fill="white"
                      d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M13.5,16V19H10.5V16H8L12,12L16,16H13.5M13,9V3.5L18.5,9H13Z"
                    />
                  </svg>
                  {'Subir Foto'}
                </Fab>
              </Box>
            )}
            {/* SI EL ACTIVO TIENE IMAGEN ACTUAL, SE MUESTRA LA IMAGEN */}
            {bien.foto_actual && (
              <Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <img src={bien.foto_actual} alt="Imagen no encontrada" width="250" />
                </Box>
                <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                  <Box position="absolute" className={classes.fab_edit}>
                    <Fab onClick={() => hiddenFileInput.current.click()} size="small" color="secondary">
                      <EditIcon />
                    </Fab>
                  </Box>
                </Box>
              </Box>
            )}
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              accept="image/*"
              style={{ display: 'none' }}
            />
          </Paper>
        </Grid>
      </Grid>

      {/* DATOS VEHICULO */}
      {tipo !== '' && (
        <Grid item xs={12}>
          <Typography>Información de {tipo}</Typography>
        </Grid>
      )}

      {tipo === 'Vehículo' && (
        <Grid item xs={12} container>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
            <CurrencyTextField
              label="Kilometraje"
              value={formularioTipo.kilometraje}
              fullWidth
              variant="outlined"
              currencySymbol="KM"
              outputFormat="string"
              decimalCharacter=","
              digitGroupSeparator="."
              leading="deny"
              decimalPlaces={0}
              decimalPlacesShownOnBlur={0}
              decimalPlacesShownOnFocus={0}
              disabled
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="N° Motor"
              fullWidth
              variant={'outlined'}
              value={formularioTipo.numero_motor}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="N° Chasis"
              fullWidth
              variant={'outlined'}
              value={formularioTipo.numero_chasis}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Color"
              fullWidth
              variant={'outlined'}
              value={formularioTipo.color}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Año"
              fullWidth
              variant={'outlined'}
              value={formularioTipo.ano ? Moment(formularioTipo.ano).utc().format('YYYY') : ''}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Tipo Combustible"
              fullWidth
              variant={'outlined'}
              value={formularioTipo.tipo_combustible}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Transmisión"
              fullWidth
              variant={'outlined'}
              value={formularioTipo.transmision}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Cilindrada"
              fullWidth
              variant={'outlined'}
              value={formularioTipo.cilindrada}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Patente"
              fullWidth
              variant={'outlined'}
              value={formularioTipo.patente}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Tag"
              fullWidth
              variant={'outlined'}
              value={formularioTipo.tag}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
          {propietario && propietario.nombre && (
            <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
              <TextField
                label="Propietario"
                fullWidth
                variant={'outlined'}
                value={propietario.nombre}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </Grid>
          )}
        </Grid>
      )}

      {/* DATOS EQUIPO TI */}
      {tipo === 'Equipo TI' && (
        <Grid item xs={12} container>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Disco Duro"
              fullWidth
              variant={'outlined'}
              value={formularioTipo.disco_duro}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Procesador"
              fullWidth
              variant={'outlined'}
              value={formularioTipo.procesador}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Memoria Ram"
              fullWidth
              variant={'outlined'}
              value={formularioTipo.memoria_ram}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Pulgadas Pantalla"
              fullWidth
              variant={'outlined'}
              value={formularioTipo.pulgadas_pantalla}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
        </Grid>
      )}

      {/* DATOS EQUIPO LABORATORIO/TOPOGRAFICO */}
      {tipo === 'Equipo Laboratorio/Topografía' && (
        <Grid item xs={12} container>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Usos"
              fullWidth
              variant={'outlined'}
              value={formularioTipo.usos}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Rango de medición"
              fullWidth
              variant={'outlined'}
              value={formularioTipo.rango_medicion}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Grado de precision"
              fullWidth
              variant={'outlined'}
              value={formularioTipo.grado_precision}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Período de recalibración"
              fullWidth
              variant={'outlined'}
              value={formularioTipo.periodo_recalibracion}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Tolerancias"
              fullWidth
              variant={'outlined'}
              value={formularioTipo.tolerancias}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid_textbox}>
            <TextField
              label="Accesorios"
              fullWidth
              variant={'outlined'}
              value={formularioTipo.accesorios}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  grid_textbox: {
    padding: theme.spacing(1),
  },
  grid_imagen: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  fab_edit: {
    padding: theme.spacing(2),
  },
}));

export default TabInfoBase;
