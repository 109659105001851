import Axios from "axios";
import { BACKEND } from "../../constants/urls";

/**
 * Método encargado de obtener todas las condiciones actuales para un activo.
 */
export async function ObtenerCondicionesActuales() {
	try {
		let url = `${BACKEND}/seleccionables?context_eq=condiciones_actuales`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}