import React, { useContext } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { MainContext } from "../../../App";
import { ListadoActivosContext } from ".";
import { putBien } from "../../../services/request/requestBienes";
import { GenerarLog } from "../../../services/activoLog";

export default function DialogAnularBaja(props) {
  const {
    activo_seleccionado,
    is_open,
    handle_open,
  } = props;

  const { usuarioSesion } = useContext(MainContext);
  const { data, setData } = useContext(ListadoActivosContext);
  const notistack = useSnackbar();

  /**
   * Método encargado de anular una dada de baja de un activo.
   */
  async function AnularBaja() {
    try {
      notistack.enqueueSnackbar("Anulando la baja del activo...", {
        variant: "info",
        persist: true,
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        }
      });
      let datosActualizacion = {
        is_eliminado: false,
        fecha_baja: null,
        adjunto_baja: null,
        motivo_baja: "",
      }
      let registroLog = {
        _bien_ref: activo_seleccionado._id,
        titulo: "Anulación de Baja",
        cuerpo: `El usuario ${usuarioSesion.nombre} anuló la baja del activo ${activo_seleccionado.codigo_activo}.`,
        tipo: "solicitud_baja",
        icon: "check_circle_outline",
      }
      await Promise.all([
        putBien(activo_seleccionado._id, datosActualizacion),
        GenerarLog(registroLog),
      ]);
      notistack.closeSnackbar();

      let bienes = [...data];
      let bien = bienes.find(bien => bien._id === activo_seleccionado._id);
      bien.is_eliminado = false;
      bien.fecha_baja = null;
      bien.adjunto_baja = null;
      bien.motivo_baja = "";
      setData(bienes);

      notistack.enqueueSnackbar("Operanción exitosa: Baja contable anulada", {
        variant: "success",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><Close /></IconButton>
      });
    }
    catch (e) {
      console.error(e);
      notistack.closeSnackbar();
      notistack.enqueueSnackbar("Error al intentar anular la baja contable. Intente de nuevo o contacte con el equipo de soporte.", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><Close /></IconButton>
      });
    } finally {
      handleClose();
    }
  }

  /**
   * Método encargado de cerrar el popup y limpiar los datos.
   */
  const handleClose = () => {
    handle_open(null, false);
  }

  return (
    <Dialog
      open={is_open}
      onClose={handleClose}
    >
      <DialogTitle>Anular baja del activo: {activo_seleccionado ? activo_seleccionado.codigo_activo : ""}</DialogTitle>
      <DialogContent>
        Se anulará la baja del activo ¿Desea continuar?
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClose}>No</Button>
        <Button variant="contained" color="primary" onClick={() => AnularBaja(activo_seleccionado)}>Sí</Button>
      </DialogActions>
    </Dialog>
  );
}