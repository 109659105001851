import axios from "axios";
import { BACKEND } from "../../constants/urls";

/**
 * Método encargado de obtener todas las solicitudes de asignación.
 */
export async function ObtenerSolicitdesAsignacion() {
  try {
    let url = `${BACKEND}/solicitudes-asignacion/_usuario_asignante_ref.nombre/_usuario_asignante_ref.apellido_paterno/_usuario_asignante_ref.apellido_materno/_asignado_ref.nombre/_asignado_ref.apellido_paterno/_asignado_ref.apellido_materno/_gerencia_ref.sigla/_gerencia_ref.nombre/_contrato_ref.codigo/_contrato_ref.nombre/_bien_ref.codigo_activo/_bien_ref.serial_number/_bien_ref.marca/_bien_ref.modelo/tipo/estado/formulario_estado/fecha_creacion`;
    let response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de anular una solicitud de asignación.
 * @param {*} asignacionID ID de la solicitud de asignación.
 */
export async function AnularAsignacion(asignacionID) {
  try {
    let url = `${BACKEND}/solicitudes-asignacion/${asignacionID}`;
    let response = await axios.put(url, { estado: "anulado" });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener las solicitudes de asignación, en un rango de fechas.
 * @param {*} desde Fecha desde.
 * @param {*} hasta Fecha hasta.
 */
export async function ObtenerSolicitudesAsignacionRango(desde, hasta) {
  try {
    let url = `${BACKEND}/solicitudes-asignacion/_usuario_asignante_ref.nombre/_usuario_asignante_ref.apellido_paterno/_usuario_asignante_ref.apellido_materno/_asignado_ref.nombre/_asignado_ref.apellido_paterno/_asignado_ref.apellido_materno/_bien_ref.codigo_activo/_bien_ref.activo/_bien_ref._sociedad_ref/_contrato_ref.codigo/_contrato_ref.nombre/_origen_contrato_ref.nombre/_origen_contrato_ref.codigo/_gerencia_ref.sigla/_gerencia_ref.nombre/_origen_gerencia_ref.sigla/_origen_gerencia_ref.nombre/tipo/estado/formulario_estado/fecha_creacion?fecha_creacion_lt=${hasta}&fecha_creacion_gt=${desde}`;
    let response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todas las solicitudes de asignación de una persona.
 */
export async function ObtenerSolicitudesAsignacion(usuarioID) {
  try {
    let url = `${BACKEND}/solicitudes-asignacion/_usuario_asignante_ref.nombre/_usuario_asignante_ref.apellido_paterno/_usuario_asignante_ref.apellido_materno/_bien_ref.codigo_activo/_bien_ref.condicion_actual/_bien_ref._clase_ref/_bien_ref.numero_serie/_bien_ref.marca/_bien_ref.modelo/_bien_ref._gerencia_ref/_bien_ref._contrato_ref/_asignado_ref.nombre/_asignado_ref.apellido_paterno/_asignado_ref.apellido_materno/_gerencia_ref.sigla/_gerencia_ref.nombre/_contrato_ref.codigo/_contrato_ref.nombre/fecha_creacion/tipo/estado/formulario_estado?_asignado_ref_eq=${usuarioID}&estado_eq=pendiente&tipo_eq=persona`;
    let response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todas las solicitudes de asignación de una gerencia.
 */
export async function ObtenerSolicitudesAsignacionGerencia(gerenciaID) {
  try {
    let url = `${BACKEND}/solicitudes-asignacion/_usuario_asignante_ref.nombre/_usuario_asignante_ref.apellido_paterno/_usuario_asignante_ref.apellido_materno/_bien_ref.codigo_activo/_bien_ref.condicion_actual/_bien_ref._clase_ref/_bien_ref.numero_serie/_bien_ref.marca/_bien_ref.modelo/_bien_ref._gerencia_ref/_bien_ref._contrato_ref/_gerencia_ref.sigla/_gerencia_ref.nombre/_contrato_ref.codigo/_contrato_ref.nombre/fecha_creacion/tipo/estado/formulario_estado?_gerencia_ref_eq=${gerenciaID}&estado_eq=pendiente&tipo_eq=gerencia`;
    let response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todas las solicitudes de asignación de un contrato.
 */
export async function ObtenerSolicitudesAsignacionContrato(contratoID) {
  try {
    let url = `${BACKEND}/solicitudes-asignacion/_usuario_asignante_ref.nombre/_usuario_asignante_ref.apellido_paterno/_usuario_asignante_ref.apellido_materno/_bien_ref.codigo_activo/_bien_ref.condicion_actual/_bien_ref._clase_ref/_bien_ref.numero_serie/_bien_ref.marca/_bien_ref.modelo/_bien_ref._gerencia_ref/_bien_ref._contrato_ref/_gerencia_ref.sigla/_gerencia_ref.nombre/_contrato_ref.codigo/_contrato_ref.nombre/fecha_creacion/tipo/estado/formulario_estado?_contrato_ref_eq=${contratoID}&estado_eq=pendiente&tipo_eq=contrato`;
    let response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener una sola asignacion especifica
 */
export async function ObtenerAsignacion(asignacionID) {
  try {
    let url = `${BACKEND}/solicitudes-asignacion/_usuario_asignante_ref.nombre/_usuario_asignante_ref.apellido_paterno/_usuario_asignante_ref.apellido_materno/_bien_ref.codigo_activo/_asignado_ref.nombre/_asignado_ref.apellido_paterno/_asignado_ref.apellido_materno/fecha_revision/tipo/estado?_id_eq=${asignacionID}`;
    let response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de aceptar una solicitud de asignación.
 * @param {*} id ID de la solicitud de asignación.
 * @param {*} fotoDespues Archivo de la foto.
 */
export async function AceptarAsignacion(id, fotoDespues) {
  try {
    let url = `${BACKEND}/solicitudes-asignacion/${id}`;
    let response = await axios.put(url, { estado: "aceptado", "formulario_estado.foto_despues": fotoDespues });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de rechazar una solicitud de asignación.
 * @param {*} id 
 */
export async function RechazarAsignacion(id) {
  try {
    let url = `${BACKEND}/solicitudes-asignacion/${id}`;
    let response = await axios.put(url, { estado: "rechazado" });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de generar una solicitud de asignación.
 */
export async function GenerarSolicitudAsignacion(solicitud) {
  try {
    let url = `${BACKEND}/solicitudes-asignacion`;
    let response = await axios.post(url, solicitud);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Obtener Asignaciones
 * @param {Object} filter filtros
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export function getAsignaciones(filter = {}, selects, populates = [], cancelToken = null) {
  let url = `${BACKEND}/solicitudes-asignacion`;
  if (selects) selects.forEach(select => {
    url = url + "/" + select
  });
  if (populates) populates.forEach(populate => {
    url = url + "/" + populate
  });
  return axios.get(url,
    {
      params: filter,
      cancelToken: cancelToken
    }).then(res => {
      return res.data ? res.data.data : null
    });
}