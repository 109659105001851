import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Checkbox,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  FormControlLabel,
  Typography,
  FormHelperText,
  FormLabel,
  Paper,
  IconButton,
  Tooltip,
  InputAdornment,
  FormGroup,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import MaterialCreatable from '../../../components/materialCreatable';
import MaterialReactSelect from '../../../components/materialReactSelect';
import FileInput from '../../../components/fileInput';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(1, 1, 3, 1),
  },
  center: {
    textAlign: 'center',
  },
  main: {
    overflow: 'auto',
    maxWidth: '95vw',
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  tableHeader: {
    textAlign: 'center',
    border: '1px solid #E0E0E0',
    fontWeight: 'bold',
    color: '#731f1f',
    position: 'sticky',
  },
  tableCell: {
    border: '1px solid #E0E0E0',
  },
  btnMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  fileInput: {
    top: 'unset',
    left: 'unset',
    position: 'inherit',
    marginTop: '1px',
  },
  multiplesActivosArea: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: 4,
    paddingBottom: 4,
    overflow: 'auto',
  },
  multiplesActivos: {
    width: 'max-content',
    height: '100%',
  },
  activoElement: {
    width: 300,
  },
  actionActivos: {
    height: '100%',
  },
}));

export default function PageFormularioBasicoBienes(props) {
  const {
    clase,
    clases,
    contratos,
    formik,
    handleChangeClase,
    handleChangeGerencia,
    handleChangeMarca,
    handleChangeTipoRecurso,
    gerencias,
    marcas,
    modelos,
    condiciones_actuales,
    ubicaciones,
    terceros,
    leasings,
    propietarioCyD,
    //propietarios,
    setPropietarioCyD,
    sociedades,
    tiposRecursos,
    modoMultiplesActivos,
    setModoMultiplesActivos,
    handleChangeMarcaMultiple,
    handleAgregarActivo,
    handleCopiarActivo,
    handleQuitarActivo,
    showTabVehiculos,
    showTabComputacionales,
    showTabLabotorioTopografia,
    colores,
  } = props;

  const { errors, handleChange, touched, values, handleSubmit } = formik;
  const classes = useStyles();

  const fileChange = (name, e) => {
    formik.setFieldValue(name, e.target.files[0]);
    formik.setFieldTouched(name, true, false);
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Typography variant="h5">Formulario de Activos</Typography>
      <Grid container spacing={1}>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <FormControl>
            <FormControlLabel
              color={'primary'}
              control={
                <Checkbox
                  checked={modoMultiplesActivos}
                  color="primary"
                  onChange={(e) => {
                    setModoMultiplesActivos(e.target.checked);
                    formik.setFieldValue('modoMultiplesActivos', e.target.checked);
                  }}
                />
              }
              label="Crear Varios Activos"
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <TextField
            name="nombre"
            label="Nombre"
            fullWidth
            variant={'outlined'}
            margin={'normal'}
            value={values.nombre}
            onChange={handleChange}
            error={touched.nombre && errors.nombre && Boolean(errors.nombre)}
            helperText={touched.nombre && errors.nombre ? errors.nombre : ''}
            disabled
          />
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <MaterialReactSelect
            value={values.sociedad}
            label="Sociedad"
            isClearable
            onChange={(newValue, actionMeta) => {
              if (newValue) {
                if (newValue.label === 'Tercero') setPropietarioCyD(false);
                else setPropietarioCyD(true);
                formik.setFieldValue('sociedad', newValue);
              } else {
                setPropietarioCyD(false);
                formik.setFieldValue('sociedad', '');
              }
            }}
            placeholder="Seleccionar Sociedad"
            options={sociedades}
            noOptionsMessage={() => 'No ha entrado la Sociedad'}
            error={touched.sociedad && errors.sociedad && Boolean(errors.sociedad)}
            helperText={touched.sociedad && errors.sociedad ? errors.sociedad : ''}
          />
        </Grid>
        {!propietarioCyD && (
          <Grid item lg={2} md={4} sm={4} xs={12}>
            <MaterialReactSelect
              value={terceros && terceros.data.find((d) => d.label === values._sociedad_tercero_ref)} //{values._sociedad_tercero_ref}
              label="Tercero"
              isClearable
              onChange={(newValue) => formik.setFieldValue('_sociedad_tercero_ref', newValue ? newValue.value : null)}
              placeholder="Seleccionar Tercero"
              options={
                terceros &&
                terceros.data.map((value, index) => ({
                  value: value._id,
                  label: value.sociedad,
                }))
              }
              noOptionsMessage={() => 'No ha entrado el Tercero'}
              error={
                touched._sociedad_tercero_ref && errors._sociedad_tercero_ref && Boolean(errors._sociedad_tercero_ref)
              }
              helperText={
                touched._sociedad_tercero_ref && errors._sociedad_tercero_ref ? errors._sociedad_tercero_ref : ''
              }
            />
          </Grid>
        )}
        {!propietarioCyD && (
          <Grid item lg={2} md={2} sm={2} xs={12}>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <Autocomplete
                freeSolo
                autoSelect
                options={leasings || []}
                //value={values.contrato_leasing}
                loading={!leasings}
                getOptionLabel={(leasing) => leasing || ' '}
                autoComplete
                onChange={(event, newValue) => {
                  newValue
                    ? formik.setFieldValue('contrato_leasing', newValue)
                    : formik.setFieldValue('contrato_leasing', ' ');
                  handleChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField label="Nº Contrato Leasing" variant="outlined" fullWidth {...params} />
                )}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <FormControl
            variant="outlined"
            margin="normal"
            fullWidth
            error={touched._gerencia_ref && errors._gerencia_ref && Boolean(errors._gerencia_ref)}
          >
            <InputLabel>Gerencia</InputLabel>
            <Select
              name="_gerencia_ref"
              value={values._gerencia_ref}
              onChange={(e) => {
                handleChange(e);
                handleChangeGerencia(e);
              }}
            >
              <MenuItem value="">Seleccionar</MenuItem>
              {gerencias &&
                gerencias.map((value, index) => (
                  <MenuItem value={value._id} key={index}>
                    {value.sigla} {value.nombre}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>{touched._gerencia_ref && errors._gerencia_ref ? errors._gerencia_ref : ''}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <MaterialReactSelect
            value={values.contrato}
            label="Contrato"
            isClearable
            // onInputChange={(inputValue, actionMeta)=>formik.setFieldValue("contrato", inputValue.label)}
            onChange={(newValue, actionMeta) =>
              newValue ? formik.setFieldValue('contrato', newValue) : formik.setFieldValue('contrato', '')
            }
            placeholder="Seleccionar Contrato"
            options={contratos.map((value, index) => ({
              value: value._id,
              label: value.codigo + ' ' + value.nombre,
              contrato: value,
            }))}
            noOptionsMessage={() => 'No ha entrado el contrato'}
            error={touched.contrato && errors.contrato && Boolean(errors.contrato)}
            helperText={touched.contrato && errors.contrato ? errors.contrato : ''}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          {/* <MaterialReactSelect
						value={values._tipo_recurso_ref}
						label="Tipo Recurso"
						isClearable
						// onInputChange={(inputValue, actionMeta)=>formik.setFieldValue("contrato", inputValue.label)}
						onChange={(newValue, actionMeta) => newValue ? formik.setFieldValue("contrato", newValue) : formik.setFieldValue("contrato", "")}
						placeholder="Seleccionar Contrato"
						options={tiposRecursos.map((value, index) => ({
							value: value._id,
							label: value.nombre,
							tipoRecurso: value,
						}))}
						error={touched._tipo_recurso_ref && errors._tipo_recurso_ref && Boolean(errors._tipo_recurso_ref)}
						helperText={touched._tipo_recurso_ref && errors._tipo_recurso_ref ? errors._tipo_recurso_ref : ""}
					/> */}
          <FormControl
            variant="outlined"
            margin="normal"
            fullWidth
            error={touched._tipo_recurso_ref && errors._tipo_recurso_ref && Boolean(errors._tipo_recurso_ref)}
          >
            <InputLabel>Tipo Recurso</InputLabel>
            <Select
              native
              name="_tipo_recurso_ref"
              label="Tipo Recurso"
              onChange={(e) => {
                handleChangeTipoRecurso(e);
                handleChange(e);
              }}
              value={values._tipo_recurso_ref}
            >
              <option aria-label="None" value=""></option>
              {tiposRecursos &&
                tiposRecursos.map((value, index) => (
                  <option value={value._id} key={index}>
                    {value.nombre}
                  </option>
                ))}
            </Select>
            <FormHelperText>
              {touched._tipo_recurso_ref && errors._tipo_recurso_ref ? errors._tipo_recurso_ref : ''}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <MaterialReactSelect
            value={values.clase}
            label="Clase"
            isClearable
            onChange={(newValue) => {
              newValue ? formik.setFieldValue('clase', newValue) : formik.setFieldValue('clase', '');
              handleChangeClase(newValue);
            }}
            placeholder="Seleccionar Clase"
            options={clases.map((value, index) => ({
              value,
              label: value.nombre,
            }))}
            noOptionsMessage={() => 'No ha entrado la Clase'}
            error={touched.clase && errors.clase && Boolean(errors.clase)}
            helperText={touched.clase && errors.clase ? errors.clase : ''}
          />
        </Grid>
        {!modoMultiplesActivos && (
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <TextField
              name="serial_number"
              label="N° de Serie (Opcional)"
              fullWidth
              variant={'outlined'}
              margin={'normal'}
              value={values.serial_number}
              onChange={handleChange}
              error={touched.serial_number && errors.serial_number && Boolean(errors.serial_number)}
              helperText={touched.serial_number && errors.serial_number ? errors.serial_number : ''}
            />
          </Grid>
        )}
        {/* <Grid item lg={3} md={6} sm={6} xs={12}>
					<TextField
						disabled
						name="codigo_contable"
						label="Código Contable"
						fullWidth
						variant={"outlined"}
						margin={"normal"}
						value={values.codigo_contable}
						onChange={handleChange}
						error={touched.codigo_contable && errors.codigo_contable && Boolean(errors.codigo_contable)}
						helperText={touched.codigo_contable && errors.codigo_contable ? errors.codigo_contable : ""}
					/>
				</Grid> */}
        {!modoMultiplesActivos && (
          <Grid item lg={3} md={6} sm={6} xs={12}>
            {!(clase && (clase.is_vehiculo || clase.is_computacional)) && (
              <MaterialCreatable
                value={values.marca_obj}
                label="Marca"
                isClearable
                onChange={(newValue, actionMeta) => {
                  newValue ? formik.setFieldValue('marca_obj', newValue) : formik.setFieldValue('marca_obj', '');
                  handleChangeMarca(newValue);
                }}
                placeholder="Seleccionar Marca"
                options={
                  marcas &&
                  marcas.data.map((value, index) => ({
                    value,
                    label: value.nombre,
                  }))
                }
                noOptionsMessage={() => 'No ha entrado la marca'}
                error={touched.marca_obj && errors.marca_obj && Boolean(errors.marca_obj)}
                helperText={touched.marca_obj && errors.marca_obj ? errors.marca_obj : ''}
              />
            )}
            {clase && clase.is_vehiculo && (
              <MaterialCreatable
                value={values.marca_obj}
                label="Marca"
                isClearable
                onChange={(newValue, actionMeta) => {
                  if (newValue) {
                    if (actionMeta.action === 'create-option')
                      formik.setFieldValue('marca_obj', { ...newValue, is_vehiculo: true });
                    else formik.setFieldValue('marca_obj', newValue);
                  } else formik.setFieldValue('marca_obj', '');
                  handleChangeMarca(newValue);
                }}
                placeholder="Seleccionar Marca"
                options={
                  marcas &&
                  marcas.data
                    .filter((a) => a.is_vehiculo)
                    .map((value, index) => ({
                      value,
                      label: value.nombre,
                    }))
                }
                noOptionsMessage={() => 'No ha entrado la marca'}
                error={touched.marca_obj && errors.marca_obj && Boolean(errors.marca_obj)}
                helperText={touched.marca_obj && errors.marca_obj ? errors.marca_obj : ''}
              />
            )}
            {clase && clase.is_computacional && (
              <MaterialCreatable
                value={values.marca_obj}
                label="Marca"
                isClearable
                onChange={(newValue, actionMeta) => {
                  if (newValue) {
                    if (actionMeta.action === 'create-option')
                      formik.setFieldValue('marca_obj', { ...newValue, is_computacional: true });
                    else formik.setFieldValue('marca_obj', newValue);
                  } else formik.setFieldValue('marca_obj', '');
                  handleChangeMarca(newValue);
                }}
                placeholder="Seleccionar Marca"
                options={
                  marcas &&
                  marcas.data
                    .filter((a) => a.is_computacional)
                    .map((value, index) => ({
                      value,
                      label: value.nombre,
                    }))
                }
                noOptionsMessage={() => 'No ha entrado la marca'}
                error={touched.marca_obj && errors.marca_obj && Boolean(errors.marca_obj)}
                helperText={touched.marca_obj && errors.marca_obj ? errors.marca_obj : ''}
              />
            )}
          </Grid>
        )}
      </Grid>
      {!modoMultiplesActivos && (
        <Grid container spacing={1}>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <MaterialCreatable
              value={values.modelo_obj}
              label="Modelo"
              isClearable
              onChange={(newValue, actionMeta) => {
                newValue ? formik.setFieldValue('modelo_obj', newValue) : formik.setFieldValue('modelo_obj', '');
              }}
              placeholder="Seleccionar un Modelo"
              options={
                modelos &&
                modelos.map((value, index) => ({
                  value,
                  label: value.nombre,
                }))
              }
              noOptionsMessage={() => 'No se ha entrado el modelo'}
              error={touched.modelo_obj && errors.modelo_obj && Boolean(errors.modelo_obj)}
              helperText={touched.modelo_obj && errors.modelo_obj ? errors.modelo_obj : ''}
              disabled={!modelos}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <MaterialReactSelect
              value={condiciones_actuales && condiciones_actuales.data.find((d) => d.label === values.condicion_actual)}
              label="Condición Actual"
              isClearable
              onChange={(newValue) => formik.setFieldValue('condicion_actual', newValue ? newValue.label : '')}
              placeholder="Seleccionar Condición Actual"
              options={condiciones_actuales && condiciones_actuales.data}
              noOptionsMessage={() => 'No ha entrado la Condición Actual'}
              error={touched.condicion_actual && errors.condicion_actual && Boolean(errors.condicion_actual)}
              helperText={touched.condicion_actual && errors.condicion_actual ? errors.condicion_actual : ''}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <MaterialReactSelect
              value={ubicaciones && ubicaciones.data.find((d) => d.label === values.ubicacion)}
              label="Ubicación Fisica"
              isClearable
              onChange={(newValue) => formik.setFieldValue('ubicacion', newValue ? newValue.label : '')}
              placeholder="Seleccionar Ubicación"
              options={
                ubicaciones &&
                ubicaciones.data.map((value, index) => ({
                  value: value._id,
                  label: value.ubicacion,
                }))
              }
              noOptionsMessage={() => 'No ha entrado la Ubicación'}
              error={touched.ubicacion && errors.ubicacion && Boolean(errors.ubicacion)}
              helperText={touched.ubicacion && errors.ubicacion ? errors.ubicacion : ''}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <FormControl fullWidth error={touched.file && errors.file && Boolean(errors.file)}>
              <InputLabel shrink className={classes.fileInput}>
                Imagen de Referencia (Opcional)
              </InputLabel>
              <FileInput
                inputName="file"
                meta={{ touched: touched.file, errors: errors.file }}
                buttonColor="primary"
                buttonVariant="outlined"
                label="Imagen de Referencia"
                value={values.file}
                handleOnChange={fileChange.bind(null, 'file')}
                textVariant="outlined"
                accept="image/x-png,image/gif,image/jpeg"
              />
              <FormHelperText>{touched.file && errors.file ? errors.file : ''}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      )}
      {!modoMultiplesActivos && (
        <Grid container spacing={1}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <FormControl>
              <FormLabel>¿Es Antiguo?</FormLabel>
              <FormControlLabel
                color={'primary'}
                control={
                  <Checkbox
                    checked={values.is_antiguo}
                    color="primary"
                    onChange={(e) => {
                      formik.setFieldValue('is_antiguo', e.target.checked);
                      formik.setFieldValue('codigo_activo', '');
                      formik.setFieldValue('codigo_contable', '');
                    }}
                  />
                }
                label="Sí"
              />
              <FormHelperText>Activos ya existentes, que tienen código contable</FormHelperText>
            </FormControl>
          </Grid>
          {values.is_antiguo && (
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <TextField
                name="codigo_contable"
                label="Código Contable"
                fullWidth
                variant={'outlined'}
                margin={'normal'}
                value={values.codigo_contable}
                onChange={(event) => {
                  formik.setFieldValue('codigo_activo', event.target.value);
                  formik.setFieldValue('codigo_contable', event.target.value);
                }}
                error={touched.codigo_contable && errors.codigo_contable && Boolean(errors.codigo_contable)}
                helperText={touched.codigo_contable && errors.codigo_contable ? errors.codigo_contable : ''}
              />
            </Grid>
          )}
        </Grid>
      )}
      {!modoMultiplesActivos && (
        <Grid container spacing={1}>
          {/* {!propietarioCyD &&
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <MaterialCreatable
              value={values.propietario}
              label="Propietario"
              isClearable
              onChange={(newValue, actionMeta) => newValue ? formik.setFieldValue("propietario", newValue) : formik.setFieldValue("propietario", "")}
              placeholder="Seleccionar un Propietario"
              options={propietarios.map((value, index) => ({
                value: value._id,
                label: value.nombre
              }))}
              noOptionsMessage={() => "No se ha encontrado el propietario"}
              error={touched.propietario && errors.propietario && Boolean(errors.propietario)}
              helperText={touched.propietario && errors.propietario ? errors.propietario : ""}
            />
          </Grid>
        } */}
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormControl>
              <FormLabel>¿Es complemento? </FormLabel>
              <FormControlLabel
                color={'primary'}
                control={
                  <Checkbox
                    checked={values.is_complemento}
                    color="primary"
                    onChange={(e) => {
                      formik.setFieldValue('is_complemento', e.target.checked);
                    }}
                  />
                }
                label="Sí"
              />
              <FormHelperText>Los complementos solo se pueden asignar a otros activos</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      )}
      {!modoMultiplesActivos && (
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              name="descripcion"
              label="Descripción"
              fullWidth
              variant={'outlined'}
              margin="normal"
              value={values.descripcion}
              onChange={handleChange}
              error={touched.descripcion && errors.descripcion && Boolean(errors.descripcion)}
              helperText={touched.descripcion && errors.descripcion ? errors.descripcion : ''}
            />
          </Grid>
        </Grid>
      )}

      {modoMultiplesActivos && (
        <Grid container alignContent="center" justify="space-between">
          <Typography variant="h5">Información por Activo</Typography>
          <Grid item>
            <Tooltip title="Agregar Nuevo">
              <IconButton
                color="primary"
                onClick={() => {
                  handleAgregarActivo();
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Copiar Último">
              <IconButton
                color="primary"
                onClick={() => {
                  handleCopiarActivo();
                }}
              >
                <LibraryAddOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
      {modoMultiplesActivos && (
        <Paper elevation={2} className={classes.multiplesActivosArea}>
          {values.multiplesActivos.map((x, i) => (
            <Grid
              container
              alignContent="center"
              justify="flex-start"
              key={i}
              className={classes.multiplesActivos}
              spacing={1}
            >
              {values.multiplesActivos.length > 1 && (
                <Grid item>
                  <Grid container alignContent="center" justify="center" className={classes.actionActivos}>
                    <Tooltip title="Quitar">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          handleQuitarActivo(i);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
              <Grid item className={classes.activoElement}>
                <TextField
                  name="descripcion"
                  label="Descripción"
                  fullWidth
                  variant={'outlined'}
                  margin="normal"
                  value={x.descripcion}
                  onChange={(e) => {
                    let modActivo = [...values.multiplesActivos];
                    modActivo[i].descripcion = e.target.value;
                    formik.setFieldValue('multiplesActivos', modActivo);
                  }}
                  // error={x.errors.descripcion}
                  // helperText={touched.descripcion && errors.descripcion ? errors.descripcion : ""}
                />
              </Grid>
              <Grid item className={classes.activoElement}>
                <TextField
                  name={'serial_number' + i}
                  label="N° de Serie (Opcional)"
                  fullWidth
                  variant={'outlined'}
                  margin={'normal'}
                  value={x.serial_number}
                  onChange={(e) => {
                    let modActivo = [...values.multiplesActivos];
                    modActivo[i].serial_number = e.target.value;
                    formik.setFieldValue('multiplesActivos', modActivo);
                  }}
                  // error={touched.serial_number && errors.serial_number && Boolean(errors.serial_number)}
                  // helperText={touched.serial_number && errors.serial_number ? errors.serial_number : ''}
                />
              </Grid>
              <Grid item className={classes.activoElement}>
                <MaterialCreatable
                  value={x.marca_obj}
                  label="Marca"
                  isClearable
                  onChange={(newValue, actionMeta) => {
                    let nueva_marca_obj = '';
                    if (newValue) {
                      if (actionMeta.action === 'create-option' && clase?.is_computacional)
                        nueva_marca_obj = { ...newValue, is_computacional: true };
                      else if (actionMeta.action === 'create-option' && clase?.is_vehiculo)
                        nueva_marca_obj = { ...newValue, is_vehiculo: true };
                      else nueva_marca_obj = newValue;
                    }
                    handleChangeMarcaMultiple(nueva_marca_obj, i);
                  }}
                  placeholder="Seleccionar Marca"
                  options={
                    marcas &&
                    marcas.data
                      .filter(
                        (a) =>
                          (!clase?.is_vehiculo && !clase?.is_computacional) ||
                          (clase?.is_computacional && a.is_computacional) ||
                          (clase?.is_vehiculo && a.is_vehiculo),
                      )
                      .map((value, index) => ({
                        value,
                        label: value.nombre,
                      }))
                  }
                  noOptionsMessage={() => 'No ha entrado la marca'}
                  error={x.errors.marca_obj && Boolean(x.errors.marca_obj)}
                  helperText={x.errors.marca_obj ? x.errors.marca_obj : ''}
                />
              </Grid>

              <Grid item className={classes.activoElement}>
                <MaterialCreatable
                  value={x.modelo_obj}
                  label="Modelo"
                  isClearable
                  onChange={(newValue, actionMeta) => {
                    let modActivo = [...values.multiplesActivos];
                    modActivo[i].modelo_obj = newValue ? newValue : '';
                    modActivo[i].errors.modelo_obj = false;
                    formik.setFieldValue('multiplesActivos', modActivo);
                  }}
                  placeholder="Seleccionar un Modelo"
                  options={
                    x.modelos &&
                    x.modelos.map((value, index) => ({
                      value,
                      label: value.nombre,
                    }))
                  }
                  noOptionsMessage={() => 'No se ha entrado el modelo'}
                  error={x.errors.modelo_obj && Boolean(x.errors.modelo_obj)}
                  helperText={x.errors.modelo_obj ? x.errors.modelo_obj : ''}
                  disabled={!x.modelos}
                />
              </Grid>
              <Grid item className={classes.activoElement}>
                <MaterialReactSelect
                  value={condiciones_actuales && condiciones_actuales.data.find((d) => d.label === x.condicion_actual)}
                  label="Condición Actual"
                  isClearable
                  onChange={(newValue) => {
                    let modActivo = [...values.multiplesActivos];
                    modActivo[i].condicion_actual = newValue ? newValue.label : '';
                    modActivo[i].errors.condicion_actual = false;
                    formik.setFieldValue('multiplesActivos', modActivo);
                  }}
                  placeholder="Seleccionar Condición Actual"
                  options={condiciones_actuales && condiciones_actuales.data}
                  noOptionsMessage={() => 'No ha entrado la Condición Actual'}
                  error={x.errors.condicion_actual && Boolean(x.errors.condicion_actual)}
                  helperText={x.errors.condicion_actual ? x.errors.condicion_actual : ''}
                />
              </Grid>
              <Grid item className={classes.activoElement}>
                <MaterialReactSelect
                  value={ubicaciones && ubicaciones.data.find((d) => d.label === x.ubicacion)}
                  label="Ubicación Física"
                  isClearable
                  onChange={(newValue) => {
                    let modActivo = [...values.multiplesActivos];
                    modActivo[i].ubicacion = newValue ? newValue.label : '';
                    modActivo[i].errors.ubicacion = false;
                    formik.setFieldValue('multiplesActivos', modActivo);
                  }}
                  placeholder="Seleccionar Ubicación"
                  options={
                    ubicaciones &&
                    ubicaciones.data.map((value, index) => ({
                      value: value._id,
                      label: value.ubicacion,
                    }))
                  }
                  noOptionsMessage={() => 'No ha entrado la Ubicación'}
                  error={x.errors.ubicacion && Boolean(x.errors.ubicacion)}
                  helperText={x.errors.ubicacion ? x.errors.ubicacion : ''}
                />
              </Grid>
              <Grid item className={classes.activoElement}>
                <FormControl fullWidth error={x.errors.file}>
                  <InputLabel shrink className={classes.fileInput}>
                    Imagen de Referencia (Opcional)
                  </InputLabel>
                  <FileInput
                    inputName="file"
                    meta={{ touched: touched.file, errors: x.errors.file }}
                    buttonColor="primary"
                    buttonVariant="outlined"
                    label="Imagen de Referencia"
                    value={x.file}
                    handleOnChange={(newValue) => {
                      let modActivo = [...values.multiplesActivos];
                      modActivo[i].file = newValue.target.files[0] ? newValue.target.files[0] : null;
                      formik.setFieldValue('multiplesActivos', modActivo);
                    }}
                    textVariant="outlined"
                    accept="image/x-png,image/gif,image/jpeg"
                  />
                  {/* <FormHelperText>
                    {touched.file && errors.file ? errors.file : ""}
                  </FormHelperText> */}
                </FormControl>
              </Grid>

              {showTabComputacionales && (
                <Grid item className={classes.activoElement}>
                  <TextField
                    label="Disco duro"
                    fullWidth
                    variant={'outlined'}
                    margin={'normal'}
                    name="disco_duro"
                    value={x.disco_duro}
                    onChange={(e) => {
                      let modActivo = [...values.multiplesActivos];
                      modActivo[i].disco_duro = e.target.value;
                      formik.setFieldValue('multiplesActivos', modActivo);
                    }}
                    error={x.errors.disco_duro}
                    // helperText={touched.disco_duro && errors.disco_duro ? errors.disco_duro : ""}
                  />
                </Grid>
              )}
              {showTabComputacionales && (
                <Grid item className={classes.activoElement}>
                  <TextField
                    label="Procesador"
                    fullWidth
                    variant={'outlined'}
                    margin={'normal'}
                    name="procesador"
                    value={x.procesador}
                    onChange={(e) => {
                      let modActivo = [...values.multiplesActivos];
                      modActivo[i].procesador = e.target.value;
                      formik.setFieldValue('multiplesActivos', modActivo);
                    }}
                    error={x.errors.procesador}
                    // helperText={touched.procesador && errors.procesador ? errors.procesador : ""}
                  />
                </Grid>
              )}
              {showTabComputacionales && (
                <Grid item className={classes.activoElement}>
                  <TextField
                    label="Memoria Ram"
                    fullWidth
                    variant={'outlined'}
                    margin={'normal'}
                    name="memoria_ram"
                    value={x.memoria_ram}
                    onChange={(e) => {
                      let modActivo = [...values.multiplesActivos];
                      modActivo[i].memoria_ram = e.target.value;
                      formik.setFieldValue('multiplesActivos', modActivo);
                    }}
                    error={x.errors.memoria_ram}
                    // helperText={touched.memoria_ram && errors.memoria_ram ? errors.memoria_ram : ""}
                  />
                </Grid>
              )}
              {showTabComputacionales && (
                <Grid item className={classes.activoElement}>
                  <TextField
                    label="Pulgadas Pantalla"
                    fullWidth
                    variant={'outlined'}
                    margin={'normal'}
                    name="pulgadas_pantalla"
                    value={x.pulgadas_pantalla}
                    onChange={(e) => {
                      let modActivo = [...values.multiplesActivos];
                      modActivo[i].pulgadas_pantalla = e.target.value;
                      formik.setFieldValue('multiplesActivos', modActivo);
                    }}
                    error={x.errors.pulgadas_pantalla}
                    // helperText={touched.pulgadas_pantalla && errors.pulgadas_pantalla ? errors.pulgadas_pantalla : ""}
                  />
                </Grid>
              )}
              {showTabVehiculos && (
                <Grid item className={classes.activoElement}>
                  <CurrencyTextField
                    label="Kilometraje"
                    value={x.kilometraje}
                    onChange={(event, value) => {
                      let modActivo = [...values.multiplesActivos];
                      modActivo[i].kilometraje = value;
                      modActivo[i].errors.kilometraje = false;
                      formik.setFieldValue('multiplesActivos', modActivo);
                    }}
                    error={x.errors.kilometraje && Boolean(x.errors.kilometraje)}
                    helperText={x.errors.kilometraje ? x.errors.kilometraje : ''}
                    fullWidth
                    variant="outlined"
                    margin={'normal'}
                    currencySymbol="Km"
                    minimumValue="0"
                    maximumValue="1000000000"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    leading="deny"
                    decimalPlaces={0}
                    decimalPlacesShownOnBlur={0}
                    decimalPlacesShownOnFocus={0}
                    modifyValueOnWheel={false}
                  />
                </Grid>
              )}
              {showTabVehiculos && (
                <Grid item className={classes.activoElement}>
                  <TextField
                    label="N° Motor"
                    fullWidth
                    variant={'outlined'}
                    margin={'normal'}
                    name="numero_motor"
                    value={x.numero_motor}
                    onChange={(e) => {
                      let modActivo = [...values.multiplesActivos];
                      modActivo[i].numero_motor = e.target.value;
                      modActivo[i].errors.numero_motor = false;
                      formik.setFieldValue('multiplesActivos', modActivo);
                    }}
                    error={x.errors.numero_motor && Boolean(x.errors.numero_motor)}
                    helperText={x.errors.numero_motor ? x.errors.numero_motor : ''}
                  />
                </Grid>
              )}
              {showTabVehiculos && (
                <Grid item className={classes.activoElement}>
                  <TextField
                    label="N° Chasis"
                    fullWidth
                    variant={'outlined'}
                    margin={'normal'}
                    name="numero_chasis"
                    value={x.numero_chasis}
                    onChange={(e) => {
                      let modActivo = [...values.multiplesActivos];
                      modActivo[i].numero_chasis = e.target.value;
                      modActivo[i].errors.numero_chasis = false;
                      formik.setFieldValue('multiplesActivos', modActivo);
                    }}
                    error={x.errors.numero_chasis && Boolean(x.errors.numero_chasis)}
                    helperText={x.errors.numero_chasis ? x.errors.numero_chasis : ''}
                  />
                </Grid>
              )}
              {showTabVehiculos && (
                <Grid item className={classes.activoElement}>
                  <FormControl
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={x.errors.color && Boolean(x.errors.color)}
                  >
                    <InputLabel>Color</InputLabel>
                    <Select
                      name="color"
                      value={x.color}
                      onChange={(e) => {
                        let modActivo = [...values.multiplesActivos];
                        modActivo[i].color = e.target.value;
                        modActivo[i].errors.color = false;
                        formik.setFieldValue('multiplesActivos', modActivo);
                      }}
                    >
                      <MenuItem value="">Seleccionar</MenuItem>
                      {colores &&
                        colores.map((value, index) => (
                          <MenuItem value={value.value} key={index}>
                            {value.label}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{x.errors.color ? x.errors.color : ''}</FormHelperText>
                  </FormControl>
                </Grid>
              )}
              {showTabVehiculos && (
                <Grid item className={classes.activoElement}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <DatePicker
                      name="ano"
                      autoOk
                      fullWidth
                      InputLabelProps={{
                        classes: {
                          shrink: 'shrink',
                        },
                      }}
                      views={['year']}
                      margin="normal"
                      inputVariant="outlined"
                      label="Año"
                      format="yyyy"
                      helperText={x.errors.ano ? x.errors.ano : ''}
                      error={x.errors.ano && Boolean(x.errors.ano)}
                      onChange={(e) => {
                        let modActivo = [...values.multiplesActivos];
                        modActivo[i].ano = e;
                        modActivo[i].errors.ano = false;
                        formik.setFieldValue('multiplesActivos', modActivo);
                      }}
                      value={x.ano}
                      maxDate={moment().startOf('year').add(1, 'years').toDate()}
                      minDate={moment().startOf('year').subtract(20, 'years').toDate()}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              )}
              {showTabVehiculos && (
                <Grid item className={classes.activoElement}>
                  <FormControl
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={x.errors.tipo_combustible && Boolean(x.errors.tipo_combustible)}
                  >
                    <InputLabel>Tipo Combustible</InputLabel>
                    <Select
                      name="tipo_combustible"
                      value={x.tipo_combustible}
                      onChange={(e) => {
                        let modActivo = [...values.multiplesActivos];
                        modActivo[i].tipo_combustible = e.target.value;
                        modActivo[i].errors.tipo_combustible = false;
                        formik.setFieldValue('multiplesActivos', modActivo);
                      }}
                    >
                      <MenuItem value="Diesel">Diesel</MenuItem>
                      <MenuItem value="Bencina">Bencina</MenuItem>
                    </Select>
                    <FormHelperText>{x.errors.tipo_combustible ? x.errors.tipo_combustible : ''}</FormHelperText>
                  </FormControl>
                </Grid>
              )}
              {showTabVehiculos && (
                <Grid item className={classes.activoElement}>
                  <FormControl
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={x.errors.transmision && Boolean(x.errors.transmision)}
                  >
                    <InputLabel>Transmisión</InputLabel>
                    <Select
                      name="transmision"
                      value={x.transmision}
                      onChange={(e) => {
                        let modActivo = [...values.multiplesActivos];
                        modActivo[i].transmision = e.target.value;
                        modActivo[i].errors.transmision = false;
                        formik.setFieldValue('multiplesActivos', modActivo);
                      }}
                    >
                      <MenuItem value="4x2">4x2</MenuItem>
                      <MenuItem value="4x4">4x4</MenuItem>
                    </Select>
                    <FormHelperText>{x.errors.transmision ? x.errors.transmision : ''}</FormHelperText>
                  </FormControl>
                </Grid>
              )}
              {showTabVehiculos && (
                <Grid item className={classes.activoElement}>
                  <TextField
                    label="Cilindrada"
                    fullWidth
                    variant={'outlined'}
                    margin={'normal'}
                    name="cilindrada"
                    value={x.cilindrada}
                    onChange={(e) => {
                      let modActivo = [...values.multiplesActivos];
                      modActivo[i].cilindrada = e.target.value;
                      modActivo[i].errors.cilindrada = false;
                      formik.setFieldValue('multiplesActivos', modActivo);
                    }}
                    error={x.errors.cilindrada && Boolean(x.errors.cilindrada)}
                    helperText={x.errors.cilindrada ? x.errors.cilindrada : ''}
                    InputProps={{
                      endAdornment: <InputAdornment position="start">cc</InputAdornment>,
                    }}
                  />
                </Grid>
              )}
              {showTabVehiculos && (
                <Grid item className={classes.activoElement}>
                  <TextField
                    label="Patente"
                    fullWidth
                    variant={'outlined'}
                    margin={'normal'}
                    name="patente"
                    value={x.patente}
                    onChange={(e) => {
                      let modActivo = [...values.multiplesActivos];
                      modActivo[i].patente = e.target.value;
                      modActivo[i].errors.patente = false;
                      formik.setFieldValue('multiplesActivos', modActivo);
                    }}
                    error={x.errors.patente && Boolean(x.errors.patente)}
                    helperText={x.errors.patente ? x.errors.patente : ''}
                  />
                </Grid>
              )}
              {showTabVehiculos && (
                <Grid item className={classes.activoElement}>
                  <TextField
                    label="Tag"
                    fullWidth
                    variant={'outlined'}
                    margin={'normal'}
                    name="tag"
                    value={x.tag}
                    onChange={(e) => {
                      let modActivo = [...values.multiplesActivos];
                      modActivo[i].tag = e.target.value;
                      modActivo[i].errors.tag = false;
                      formik.setFieldValue('multiplesActivos', modActivo);
                    }}
                    error={x.errors.tag && Boolean(x.errors.tag)}
                    helperText={x.errors.tag ? x.errors.tag : ''}
                  />
                </Grid>
              )}
              {showTabVehiculos && (
                <FormGroup row>
                  <FormControlLabel
                    color={'primary'}
                    control={
                      <Checkbox
                        checked={x.gps}
                        color="primary"
                        onChange={(e) => {
                          let modActivo = [...values.multiplesActivos];
                          modActivo[i].gps = e.target.checked;
                          formik.setFieldValue('multiplesActivos', modActivo);
                        }}
                      />
                    }
                    label="GPS"
                  />
                  <FormControlLabel
                    color={'primary'}
                    control={
                      <Checkbox
                        checked={x.mobileye}
                        color="primary"
                        onChange={(e) => {
                          let modActivo = [...values.multiplesActivos];
                          modActivo[i].mobileye = e.target.checked;
                          formik.setFieldValue('multiplesActivos', modActivo);
                        }}
                      />
                    }
                    label="MobilEye"
                  />
                  <FormControlLabel
                    color={'primary'}
                    control={
                      <Checkbox
                        checked={x.barra_antivuelco}
                        color="primary"
                        onChange={(e) => {
                          let modActivo = [...values.multiplesActivos];
                          modActivo[i].barra_antivuelco = e.target.checked;
                          formik.setFieldValue('multiplesActivos', modActivo);
                        }}
                      />
                    }
                    label="Barra Antivuelco"
                  />
                </FormGroup>
              )}
              {showTabLabotorioTopografia && (
                <Grid item className={classes.activoElement}>
                  <TextField
                    label="Usos"
                    fullWidth
                    variant={'outlined'}
                    margin={'normal'}
                    name="usos"
                    value={x.usos}
                    onChange={(e) => {
                      let modActivo = [...values.multiplesActivos];
                      modActivo[i].usos = e.target.value;
                      formik.setFieldValue('multiplesActivos', modActivo);
                    }}
                    error={x.errors.usos}
                    // helperText={touched.usos && errors.usos ? errors.usos : ""}
                  />
                </Grid>
              )}
              {showTabLabotorioTopografia && (
                <Grid item className={classes.activoElement}>
                  <TextField
                    label="Rango de medición"
                    fullWidth
                    variant={'outlined'}
                    margin={'normal'}
                    name="rango_medicion"
                    value={x.rango_medicion}
                    onChange={(e) => {
                      let modActivo = [...values.multiplesActivos];
                      modActivo[i].rango_medicion = e.target.value;
                      formik.setFieldValue('multiplesActivos', modActivo);
                    }}
                    error={x.errors.rango_medicion}
                    // helperText={touched.rango_medicion && errors.rango_medicion ? errors.rango_medicion : ""}
                  />
                </Grid>
              )}
              {showTabLabotorioTopografia && (
                <Grid item className={classes.activoElement}>
                  <TextField
                    label="Grado de precisión"
                    fullWidth
                    variant={'outlined'}
                    margin={'normal'}
                    name="grado_precision"
                    value={x.grado_precision}
                    onChange={(e) => {
                      let modActivo = [...values.multiplesActivos];
                      modActivo[i].grado_precision = e.target.value;
                      formik.setFieldValue('multiplesActivos', modActivo);
                    }}
                    error={x.errors.grado_precision}
                    // helperText={touched.grado_precision && errors.grado_precision ? errors.grado_precision : ""}
                  />
                </Grid>
              )}
              {showTabLabotorioTopografia && (
                <Grid item className={classes.activoElement}>
                  <TextField
                    label="Período recalibración"
                    fullWidth
                    variant={'outlined'}
                    margin={'normal'}
                    name="periodo_recalibracion"
                    value={x.periodo_recalibracion}
                    onChange={(e) => {
                      let modActivo = [...values.multiplesActivos];
                      modActivo[i].periodo_recalibracion = e.target.value;
                      formik.setFieldValue('multiplesActivos', modActivo);
                    }}
                    error={x.errors.periodo_recalibracion}
                    // helperText={touched.periodo_recalibracion && errors.periodo_recalibracion ? errors.periodo_recalibracion : ""}
                  />
                </Grid>
              )}
              {showTabLabotorioTopografia && (
                <Grid item className={classes.activoElement}>
                  <TextField
                    label="Tolerancias"
                    fullWidth
                    variant={'outlined'}
                    margin={'normal'}
                    name="tolerancias"
                    value={x.tolerancias}
                    onChange={(e) => {
                      let modActivo = [...values.multiplesActivos];
                      modActivo[i].tolerancias = e.target.value;
                      formik.setFieldValue('multiplesActivos', modActivo);
                    }}
                    error={x.errors.tolerancias}
                    // helperText={touched.tolerancias && errors.tolerancias ? errors.tolerancias : ""}
                  />
                </Grid>
              )}
              {showTabLabotorioTopografia && (
                <Grid item className={classes.activoElement}>
                  <TextField
                    label="Accesorios"
                    fullWidth
                    variant={'outlined'}
                    margin={'normal'}
                    name="accesorios"
                    value={x.accesorios}
                    onChange={(e) => {
                      let modActivo = [...values.multiplesActivos];
                      modActivo[i].accesorios = e.target.value;
                      formik.setFieldValue('multiplesActivos', modActivo);
                    }}
                    error={x.errors.accesorios}
                    // helperText={touched.accesorios && errors.accesorios ? errors.accesorios : ""}
                  />
                </Grid>
              )}
            </Grid>
          ))}
        </Paper>
      )}

      <Grid container alignContent="center" justify="center">
        <Grid item lg md sm xs className={classes.center}>
          <Button type="submit" color="primary" variant="contained" className={classes.btnMargin}>
            Siguiente
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
