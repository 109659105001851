import { CircularProgress, Container, IconButton, lighten, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Toolbar, Tooltip, Typography, useTheme } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { getBienes } from '../../../services/request/requestBienes';
import { getGerencias } from '../../../services/request/requestPortafolio';
import formatNumber from 'format-number';
import { Fragment } from 'react';
import XLSX from 'xlsx';
import { GetApp } from '@material-ui/icons';
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
function CustomTableRow(props) {

  return (
    <TableRow>
      {
        props.cells.map((a, index) => (
          <TableCell {...a.props} key={index}>{a.value}</TableCell>
        ))
      }
    </TableRow>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

function exportExcel(headers, rows) {
  const wb = XLSX.utils.book_new();
  const wbdata = rows.map((row, index) => {
    return {
      [headers[0].value]: row.columns[0].value,
      [headers[1].value]: row.columns[1].value,
      [headers[2].value]: row.columns[2].value,
      [headers[3].value]: row.columns[3].value,
      [headers[4].value]: row.columns[4].value,
      [headers[5].value]: row.columns[5].value,
      [headers[6].value]: row.columns[6].value,
      [headers[7].value]: row.columns[7].value,
      [headers[8].value]: row.columns[8].value,
      [headers[9].value]: row.columns[9].value,
      [headers[10].value]: row.columns[10].value,
      [headers[11].value]: row.columns[11].value,
      [headers[12].value]: row.columns[12].value,
      [headers[13].value]: row.columns[13].value,
      [headers[14].value]: row.columns[14].value,
    };
  });
  let ws = XLSX.utils.json_to_sheet(wbdata);
  XLSX.utils.book_append_sheet(wb, ws, "Reporte de activos registrados");
  XLSX.writeFile(wb, "Reporte de activos registrados.xlsx");
}

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { exportExcel } = props;
  return (
    <Toolbar
    >
      <Typography className={classes.title} variant="h6">
        Reporte de activos registrados
        <Tooltip title="Exportar a Excel">
          <IconButton onClick={exportExcel}>
            <GetApp />
          </IconButton>
        </Tooltip>
      </Typography>
    </Toolbar>
  );
};

function EnhancedTableHead(props) {
  const {
    classes,
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
    headCells
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            {...headCell.props}
            key={index}
          >
            <TableSortLabel
              active={orderBy === index}
              direction={orderBy === index ? order : 'asc'}
              onClick={createSortHandler(index)}
            >
              {headCell.value}
              {orderBy === index ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'orden descendente' : 'orden ascendente'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


const useStyle = makeStyles(theme => ({
  visuallyHidden: {
    display: 'none'
  },
  indicadores: {
    padding: theme.spacing(3)
  }
}));

export default function ReporteActivosRegistrados() {

  const theme = useTheme();
  const classes = useStyle();
  const headerStyle = {
    backgroundColor: "#ffffff",
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  }

  const headers = [
    {
      value: "Gerencia",
      nombre: "Gerencia",
      props: {
        style: headerStyle
      }
    },
    {
      value: "Vehículos",
      props: {
        style: headerStyle
      }
    },
    {
      value: "Computadores",
      props: {
        style: headerStyle
      }
    },
    {
      value: "Inmuebles",
      props: {
        style: headerStyle
      }
    },
    {
      value: "Otro",
      props: {
        style: headerStyle
      }
    },
    {
      value: "Total gerencia",
      props: {
        style: headerStyle
      }
    },
    {
      value: "% Total",
      props: {
        style: headerStyle
      }
    },
    {
      value: "Asignaciones a personas",
      props: {
        style: headerStyle
      }
    },
    {
      value: "% Asignaciones a personas",
      props: {
        style: headerStyle
      }
    },
    {
      value: "Asignaciones a contratos",
      props: {
        style: headerStyle
      }
    },
    {
      value: "% Asignaciones a contratos",
      props: {
        style: headerStyle
      }
    },
    {
      value: "Asignaciones a gerencias",
      props: {
        style: headerStyle
      }
    },
    {
      value: "% Asignaciones a gerencias",
      props: {
        style: headerStyle
      }
    },
    {
      value: "Contratos no activos",
      props: {
        style: headerStyle
      }
    },
    {
      value: "% Contratos no activos",
      props: {
        style: headerStyle
      }
    }
  ];


  const [orderBy, setOrderBy] = useState(0);
  const [order, setOrder] = useState("asc");

  const [rows, setRows] = useState([]);
  const [indicadores, setIndicadores] = useState({
    totalActivos: 0
  });
  const [isLoading, setIsLoading] = useState(true);

  function onRequestSort(event, index) {
    setOrderBy(index);
    if (orderBy === index && order === 'asc') setOrder("desc");
    else if (orderBy === index && order === 'desc') setOrder("asc");
    else setOrder("asc");
  }

  const loadData = useCallback(async () => {
    setRows([]);
    setIsLoading(true);
    setIndicadores({
      totalActivos: 0
    });
    const response = await getBienes({},
      ["is_activo_contable"],
      [
        "_gerencia_ref.nombre",
        "_contrato_ref.nombre",
        "_contrato_ref.fecha_termino_estimada",
        "_asignado_ref.nombre",
        "_clase_ref.is_vehiculo",
        "_clase_ref.is_computacional",
        "_clase_ref.is_inmueble"
      ]);

    const responseGerencias = await getGerencias();
    if (response.status) {
      const activosPorGerencia = responseGerencias.map(a => ({
        nombre: a.nombre,
        columns: [
          {
            value: a.nombre,
            props: {
              style: {
                minWidth: "200px"
              }
            }
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0,
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          }
        ]
      }));

      activosPorGerencia.push({
        nombre: "Sin Gerencia",
        columns: [
          {
            value: "Sin Gerencia",
            props: {
              style: {
                minWidth: "200px"
              }
            }
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0,
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          }
        ]
      });

      let totalActivos = 0;
      const totales = {
        nombre: "Totales",
        columns: [
          {
            value: "Totales",
            props: {
              style: {
                minWidth: "200px",
                color: "#ffffff",
                backgroundColor: theme.palette.primary.main
              }
            }
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0,
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          },
          {
            value: 0
          }
        ]
      };
      const hoy = new Date();

      response.data.forEach((value, index) => {
        let filaGerencia = activosPorGerencia[activosPorGerencia.length - 1];
        if (value._gerencia_ref && value._gerencia_ref.nombre) {
          filaGerencia = activosPorGerencia.find(a => a.nombre === value._gerencia_ref.nombre);
        }
        filaGerencia.columns[5].value++;
        totales.columns[5].value++;
        if (value._clase_ref && value._clase_ref.is_vehiculo) {
          filaGerencia.columns[1].value++;
          totales.columns[1].value++;
        }
        else if (value._clase_ref && value._clase_ref.is_computacional) {
          filaGerencia.columns[2].value++;
          totales.columns[2].value++;
        }
        else if (value._clase_ref && value._clase_ref.is_inmueble) {
          filaGerencia.columns[3].value++;
          totales.columns[3].value++;
        }
        else {
          filaGerencia.columns[4].value++;
          totales.columns[4].value++;
        }
        if (value._asignado_ref) {
          filaGerencia.columns[7].value++;
          totales.columns[7].value++;
        } else if (value._contrato_ref) {
          filaGerencia.columns[9].value++;
          totales.columns[9].value++;
        } else {
          filaGerencia.columns[11].value++;
          totales.columns[11].value++;
        }
        // if (value._contrato_ref) console.log(value._contrato_ref.fecha_termino_estimada);
        if (value._contrato_ref && value._contrato_ref.fecha_termino_estimada && (hoy.valueOf() >= new Date(value._contrato_ref.fecha_termino_estimada).valueOf())) {
          filaGerencia.columns[13].value++;
          totales.columns[13].value++;
        }
        totalActivos++;
      });

      activosPorGerencia.forEach((row) => {
        const totalGerencia = row.columns[5].value;
        row.columns[6].value = row.columns[5].value > 0 && totalActivos > 0 ? row.columns[5].value / totalActivos * 100 : 0;
        row.columns[8].value = row.columns[7].value > 0 && totalGerencia > 0 ? row.columns[7].value / totalGerencia * 100 : 0;
        row.columns[10].value = row.columns[9].value > 0 && totalGerencia > 0 ? row.columns[9].value / totalGerencia * 100 : 0;
        row.columns[12].value = row.columns[11].value > 0 && totalGerencia > 0 ? row.columns[11].value / totalGerencia * 100 : 0;
        row.columns[14].value = row.columns[13].value > 0 && totalGerencia > 0 ? row.columns[13].value / totalGerencia * 100 : 0;
        row.columns[2].value = numberFormater(Math.round(row.columns[2].value * 100) / 100);
        row.columns[3].value = numberFormater(Math.round(row.columns[3].value * 100) / 100);
        row.columns[4].value = numberFormater(Math.round(row.columns[4].value * 100) / 100);
        row.columns[5].value = numberFormater(Math.round(row.columns[5].value * 100) / 100);
        row.columns[6].value = numberFormater(Math.round(row.columns[6].value * 100) / 100) + "%";
        row.columns[7].value = numberFormater(Math.round(row.columns[7].value * 100) / 100);
        row.columns[8].value = numberFormater(Math.round(row.columns[8].value * 100) / 100) + "%";
        row.columns[9].value = numberFormater(Math.round(row.columns[9].value * 100) / 100);
        row.columns[10].value = numberFormater(Math.round(row.columns[10].value * 100) / 100) + "%";
        row.columns[11].value = numberFormater(Math.round(row.columns[11].value * 100) / 100);
        row.columns[12].value = numberFormater(Math.round(row.columns[12].value * 100) / 100) + "%";
        row.columns[13].value = numberFormater(Math.round(row.columns[13].value * 100) / 100);
        row.columns[14].value = numberFormater(Math.round(row.columns[14].value * 100) / 100) + "%";
      });

      activosPorGerencia.sort(function (a, b) {
        if (order === "desc") return b.columns[orderBy].value - a.columns[orderBy].value;
        else return a.columns[orderBy].value - b.columns[orderBy].value;
      });

      totales.columns[6].value = totales.columns[5].value > 0 && totalActivos > 0 ? totales.columns[5].value / totalActivos * 100 : 0;
      totales.columns[8].value = totales.columns[7].value > 0 && totalActivos > 0 ? totales.columns[7].value / totalActivos * 100 : 0;
      totales.columns[10].value = totales.columns[9].value > 0 && totalActivos > 0 ? totales.columns[9].value / totalActivos * 100 : 0;
      totales.columns[12].value = totales.columns[11].value > 0 && totalActivos > 0 ? totales.columns[11].value / totalActivos * 100 : 0;
      totales.columns[14].value = totales.columns[13].value > 0 && totalActivos > 0 ? totales.columns[13].value / totalActivos * 100 : 0;
      totales.columns[2].value = numberFormater(Math.round(totales.columns[2].value * 100) / 100);
      totales.columns[3].value = numberFormater(Math.round(totales.columns[3].value * 100) / 100);
      totales.columns[4].value = numberFormater(Math.round(totales.columns[4].value * 100) / 100);
      totales.columns[5].value = numberFormater(Math.round(totales.columns[5].value * 100) / 100);
      totales.columns[6].value = numberFormater(Math.round(totales.columns[6].value * 100) / 100) + "%";
      totales.columns[7].value = numberFormater(Math.round(totales.columns[7].value * 100) / 100);
      totales.columns[8].value = numberFormater(Math.round(totales.columns[8].value * 100) / 100) + "%";
      totales.columns[9].value = numberFormater(Math.round(totales.columns[9].value * 100) / 100);
      totales.columns[10].value = numberFormater(Math.round(totales.columns[10].value * 100) / 100) + "%";
      totales.columns[11].value = numberFormater(Math.round(totales.columns[11].value * 100) / 100);
      totales.columns[12].value = numberFormater(Math.round(totales.columns[12].value * 100) / 100) + "%";
      totales.columns[13].value = numberFormater(Math.round(totales.columns[13].value * 100) / 100);
      totales.columns[14].value = numberFormater(Math.round(totales.columns[14].value * 100) / 100) + "%";
      activosPorGerencia.push(totales);

      setIndicadores({
        totalActivos: totalActivos
      });
      setRows(activosPorGerencia);
      setIsLoading(false);
    }
  }, [order, orderBy, theme]);

  useEffect(() => {
    loadData();
  }, [loadData]);


  return (
    <Fragment>
      <Paper>
        <Container>
          <Typography align="center" className={classes.indicadores} variant="h5">
            Total de activos registrados: {isLoading ? (<CircularProgress size="20px" color="primary" />) : indicadores.totalActivos}
          </Typography>
        </Container>
      </Paper>
      <br />
      <TableContainer component={Paper}>
        <EnhancedTableToolbar
          exportExcel={() => exportExcel(headers, rows)}
        />
        <Table size="small">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            headCells={headers}
            onRequestSort={onRequestSort}
          />
          {isLoading &&
            <TableBody>
              <TableRow>
                <TableCell colSpan={14} align="center">
                  <CircularProgress size="40px" color="primary" />
                </TableCell>
              </TableRow>
            </TableBody>
          }
          {!isLoading && <TableBody>
            {rows.map((a, key) => <CustomTableRow cells={a.columns} key={key} />)}
          </TableBody>}
        </Table>
      </TableContainer>
    </Fragment>
  );

}