import Axios from "axios";

  /**
   * Método encargado de enviar un Email en formato Texto.
   * @param {*} to Destinatario.
   * @param {*} subject Asunto.
   * @param {*} text Contenido texto.
   * @returns 
   */
   export async function SendText(to, subject, text) {
    try {
      let url = "https://sendmail.cydocs.cl/email";
      let response = await Axios.post(url, { to, subject, text });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Método encargado de enviar un Email en formato HTML.
   * @param {*} to Destinatario.
   * @param {*} subject Asunto.
   * @param {*} html Contenido HTML.
   * @returns 
   */
  export async function SendHTML (to, subject, html) {
    try {
      let url = "https://sendmail.cydocs.cl/email";
      let response = await Axios.post(url, { to, subject, html });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
