import React, { Fragment, useContext, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Collapse, Divider, Drawer, List, ListItem, ListItemText, Typography } from '@material-ui/core/';
import * as ROUTES from '../../constants/routes';
import * as PERMISOS from '../../constants/permissions';
import { DrawerContext } from '../appBar';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { MainContext } from '../../App';

export default function MainDrawer(props) {
  const [openCollapse, setOpenCollapse] = useState('');
  const { openMainDrawer, setOpenMainDrawer } = useContext(DrawerContext);
  const { permisos } = useContext(MainContext);

  const handleClickCollapse = (index) => {
    if (index === openCollapse) setOpenCollapse('');
    else setOpenCollapse(index);
  };

  const handleClose = () => setOpenMainDrawer(false);
  const classes = useStyles();

  return (
    <Fragment>
      <Drawer
        anchor="left"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: openMainDrawer,
          [classes.drawerClose]: !openMainDrawer,
        })}
        classes={{
          paper: clsx(classes.drawerMargin, {
            [classes.drawerOpen]: openMainDrawer,
            [classes.drawerClose]: !openMainDrawer,
          }),
        }}
        onClose={handleClose}
        open={openMainDrawer}
      >
        <Typography className={classes.title} variant="h6">
          Gestor de Activos
        </Typography>
        <Divider />
        <List>
          {/* HOME / MIS ACTIVOS / MIS SOLICITUDES */}
          <ListItem button component={Link} to={ROUTES.LANDING} onClick={handleClose}>
            <ListItemText primary="Mis Activos" primaryTypographyProps={{ className: classes.drawerItem }} />
          </ListItem>

          {/* AGREGAR ACTIVO */}
          {permisos[PERMISOS.AGREGAR_ACTIVO] && (
            <ListItem button component={Link} to={ROUTES.REGISTRO_AOB} onClick={handleClose}>
              <ListItemText primary="Agregar Activo" primaryTypographyProps={{ className: classes.drawerItem }} />
            </ListItem>
          )}

          {/* REGISTRO Y ASIGNACIÓN */}
          {(permisos[PERMISOS.VER_LISTADO_ACTIVOS] ||
            permisos[PERMISOS.VER_LISTADO_PERSONAS] ||
            permisos[PERMISOS.VER_LISTADO_GERENCIAS] ||
            permisos[PERMISOS.VER_LISTADO_CONTRATOS]) && (
            <ListItem button onClick={() => handleClickCollapse(0)}>
              <ListItemText primary="Consulta de Activos" primaryTypographyProps={{ className: classes.drawerItem }} />
              {openCollapse === 0 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          )}
          <Collapse in={openCollapse === 0} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* LISTADO GENERAL DE ACTIVOS */}
              {permisos[PERMISOS.VER_LISTADO_ACTIVOS] && (
                <ListItem button component={Link} to={ROUTES.LISTADO_BIENES} onClick={handleClose}>
                  <ListItemText
                    primary="Listado de Activos"
                    inset
                    primaryTypographyProps={{ className: classes.drawerItem }}
                  />
                </ListItem>
              )}
              {/* LISTADO DE PERSONAS */}
              {permisos[PERMISOS.VER_LISTADO_PERSONAS] && (
                <ListItem button component={Link} to={ROUTES.LISTADO_PERSONAS} onClick={handleClose}>
                  <ListItemText
                    primary="Listado de Personas"
                    inset
                    primaryTypographyProps={{ className: classes.drawerItem }}
                  />
                </ListItem>
              )}
              {/* LISTADO DE CONTRATOS */}
              {permisos[PERMISOS.VER_LISTADO_CONTRATOS] && (
                <ListItem button component={Link} to={ROUTES.LISTADO_CONTRATOS} onClick={handleClose}>
                  <ListItemText
                    primary="Listado de Contratos"
                    inset
                    primaryTypographyProps={{ className: classes.drawerItem }}
                  />
                </ListItem>
              )}
              {/* LISTADO DE GERENCIAS */}
              {permisos[PERMISOS.VER_LISTADO_GERENCIAS] && (
                <ListItem button component={Link} to={ROUTES.LISTADO_GERENCIAS} onClick={handleClose}>
                  <ListItemText
                    primary="Listado de Gerencias"
                    inset
                    primaryTypographyProps={{ className: classes.drawerItem }}
                  />
                </ListItem>
              )}
              {/* {permisos && permisos[PERMISOS.CARGAR_ACTIVOS] &&
                <ListItem button component={Link} to={ROUTES.CARGA_MASIVA_ACTIVOS} onClick={handleClose} dense>
                  <ListItemText primary="Carga de Activos" inset primaryTypographyProps={{ className: classes.drawerItem }} />
                </ListItem>
              }
              {permisos &&
                <ListItem button component={Link} to={ROUTES.CARGA_MASIVA_ACTIVOS_HISTORICO} onClick={handleClose} dense>
                  <ListItemText primary="Carga Activos" inset primaryTypographyProps={{ className: classes.drawerItem }} />
                </ListItem>
              } */}
            </List>
          </Collapse>
          {permisos[PERMISOS.VER_GESTION_FLOTA] && (
            <ListItem button component={Link} to={ROUTES.GESTION_FLOTA} onClick={handleClose}>
              <ListItemText primary="Gestión de Flota" primaryTypographyProps={{ className: classes.drawerItem }} />
            </ListItem>
          )}
          {permisos[PERMISOS.VER_LICENCIAS_SOFTWARE] && (
            <ListItem button component={Link} to={ROUTES.LICENCIAS_SOFTWARE} onClick={handleClose}>
              <ListItemText
                primary="Licencias de Software"
                primaryTypographyProps={{ className: classes.drawerItem }}
              />
            </ListItem>
          )}
          {permisos[PERMISOS.VER_LISTADO_SOLICITUDES_BAJA] && (
            <ListItem button onClick={() => handleClickCollapse(1)}>
              <ListItemText primary="Solicitudes" primaryTypographyProps={{ className: classes.drawerItem }} />
              {openCollapse === 1 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          )}
          <Collapse in={openCollapse === 1} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* SOLICITUDES DE BAJA */}
              {permisos[PERMISOS.VER_LISTADO_SOLICITUDES_BAJA] && (
                <ListItem button component={Link} to={ROUTES.SOLICITUDES_BAJA} onClick={handleClose}>
                  <ListItemText primary="Baja" inset primaryTypographyProps={{ className: classes.drawerItem }} />
                </ListItem>
              )}
            </List>
          </Collapse>
          {(permisos[PERMISOS.VER_MOVIMIENTO_MENSUAL] ||
            permisos[PERMISOS.VER_COSTOS_ACTIVOS] ||
            permisos[PERMISOS.VER_REPORTE_ACTIVOS_REGISTRADOS] ||
            permisos[PERMISOS.VER_LICENCIAS_SOFTWARE] ||
            permisos[PERMISOS.VER_INFORME_MOVIMIENTOS_ACTIVOS] ||
            permisos[PERMISOS.VER_REPORTE_RESUMEN_LICENCIAS_SOFTWARE_GERENCIAS]) && (
            <ListItem button onClick={() => handleClickCollapse(2)}>
              <ListItemText primary="Reportabilidad" primaryTypographyProps={{ className: classes.drawerItem }} />
              {openCollapse === 2 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          )}
          <Collapse in={openCollapse === 2} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* COSTOS POR ACTIVO*/}
              {permisos[PERMISOS.VER_COSTOS_ACTIVOS] && (
                <ListItem button component={Link} to={ROUTES.COSTOS_ACTIVOS} onClick={handleClose}>
                  <ListItemText
                    primary="Costos por Activo"
                    inset
                    primaryTypographyProps={{ className: classes.drawerItem }}
                  />
                </ListItem>
              )}
              {/* MOVIMEINTO MENSUAL ASIGNACIONES */}
              {permisos[PERMISOS.VER_MOVIMIENTO_MENSUAL] && (
                <ListItem button component={Link} to={ROUTES.MOVIMIENTO_MENSUAL} onClick={handleClose}>
                  <ListItemText
                    primary="Movimiento Mensual"
                    inset
                    primaryTypographyProps={{ className: classes.drawerItem }}
                  />
                </ListItem>
              )}
              {permisos[PERMISOS.VER_LICENCIAS_SOFTWARE] && (
                <ListItem button component={Link} to={ROUTES.INFORME_SOFTWARE_ROUTE} onClick={handleClose}>
                  <ListItemText
                    primary="Informe de Software"
                    inset
                    primaryTypographyProps={{ className: classes.drawerItem }}
                  />
                </ListItem>
              )}
              {permisos[PERMISOS.VER_REPORTE_RESUMEN_LICENCIAS_SOFTWARE_GERENCIAS] && (
                <ListItem
                  button
                  component={Link}
                  to={ROUTES.REPORTE_RESUMEN_LICENCIAS_SOFTWARE_GERENCIAS_ROUTE}
                  onClick={handleClose}
                >
                  <ListItemText
                    primary="Resumen Informe Licencias de Software"
                    inset
                    primaryTypographyProps={{ className: classes.drawerItem }}
                  />
                </ListItem>
              )}
              <ListItem button component={Link} to={ROUTES.DOCUMENTOS_POR_VENCER_ROUTE} onClick={handleClose}>
                <ListItemText
                  primary="Listado de Adjuntos"
                  inset
                  primaryTypographyProps={{ className: classes.drawerItem }}
                />
              </ListItem>
              {permisos[PERMISOS.VER_INFORME_MOVIMIENTOS_ACTIVOS] && (
                <ListItem button component={Link} to={ROUTES.INFORME_MOVIMIENTOS_ACTIVOS} onClick={handleClose}>
                  <ListItemText
                    primary="Informe de Movimiento de Activos"
                    inset
                    primaryTypographyProps={{ className: classes.drawerItem }}
                  />
                </ListItem>
              )}
            </List>
          </Collapse>
          {/* MENU DE AJUSTES */}
          {permisos[PERMISOS.VER_MODULO_AJUSTES] && (
            <ListItem button onClick={() => handleClickCollapse(3)}>
              <ListItemText primary="Ajustes" primaryTypographyProps={{ className: classes.drawerItem }} />
              {openCollapse === 3 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          )}

          {/* SUBMENUS DE AJUSTES */}
          {permisos[PERMISOS.VER_MODULO_AJUSTES] && (
            <Collapse in={openCollapse === 3} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* ADMINISTRACIÓN */}
                {(permisos[PERMISOS.VER_ROLES] ||
                  permisos[PERMISOS.VER_PERFILES] ||
                  permisos[PERMISOS.VER_PERMISOS]) && (
                  <ListItem button component={Link} to={ROUTES.AJUSTES_ADMINISTRACION} onClick={handleClose}>
                    {' '}
                    {/*dense>*/}
                    <ListItemText
                      primary="Administración"
                      inset
                      primaryTypographyProps={{ className: classes.drawerItem }}
                    />
                  </ListItem>
                )}
                {/* CONFIGURACIÓN */}
                {(permisos[PERMISOS.VER_COLORES] ||
                  permisos[PERMISOS.VER_MARCAS] ||
                  permisos[PERMISOS.VER_TIPOS_DOCS]) && (
                  <ListItem button component={Link} to={ROUTES.AJUSTES_CONFIGURACION} onClick={handleClose}>
                    {' '}
                    {/*dense>*/}
                    <ListItemText
                      primary="Configuración"
                      inset
                      primaryTypographyProps={{ className: classes.drawerItem }}
                    />
                  </ListItem>
                )}
              </List>
            </Collapse>
          )}
        </List>
        <div className={classes.version}>v1.47.6</div>
      </Drawer>
    </Fragment>
  );
}

const drawerWidth = 280;
const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: 26,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerMargin: {
    // marginTop: "48px"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: 'hidden',
    width: theme.spacing(0) + 1,
    // width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {},
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  title: {
    padding: theme.spacing(1, 2, 1),
  },
  drawerItem: {
    fontWeight: 'regular',
  },
  version: {
    position: 'fixed',
    bottom: 0,
    margin: '0 0 5px 10px',
  },
}));
