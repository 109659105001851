import React, { createContext, useState } from 'react';
import MaterialTable from '../../../components/materialTable';
import useSWR from 'swr';
import {
  postSeleccionable,
  putSeleccionable,
  deleteSeleccionable,
} from '../../../services/request/requestSeleccionables';
import { Fragment } from 'react';
import DialogoCRUDRegistroSeleccionable from './dialogoCRUDRegistroSeleccionable';

export const TablaSeleccionablesContext = createContext({
  openDialogoCRUD: false,
  setOpenDialogoCRUD: () => {},
  tituloDialogo: '',
  setTituloDialogo: () => {},
  nombre: '',
  setNombre: () => {},
  id: '',
  setId: () => {},
  handleAceptar: () => {},
  disableEditing: false,
  setDisableEditing: () => {},
  handleOpenAgregar: () => {},
  handleOpenModificar: () => {},
  handleOpenQuitar: () => {},
});

export default function TablaSeleccionables(props) {
  const { titulo, getData, puedeAgregar, puedeEditar, puedeEliminar } = props;
  const { data, mutate } = useSWR(`get:${titulo}`, (key) => getData());

  const [openDialogoCRUD, setOpenDialogoCRUD] = useState(false);
  const [tituloDialogo, setTituloDialogo] = useState('');
  const [nombre, setNombre] = useState('');
  const [id, setId] = useState('');
  const [disableEditing, setDisableEditing] = useState(false);

  const handleOpenAgregar = (titulo) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setNombre('');
    setId('');
    setDisableEditing(false);
  };

  const handleOpenModificar = (titulo, nombre, id) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setNombre(nombre);
    setDisableEditing(false);
    setId(id);
  };

  const handleOpenQuitar = (titulo, nombre, id) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setNombre(nombre);
    setDisableEditing(true);
    setId(id);
  };

  const handleAceptar = async (titulo, value, id) => {
    let context = '';
    if (titulo.includes('Colores')) context = 'colores';
    else if (titulo.includes('Condiciones de Activos')) context = 'condiciones_actuales';
    else if (titulo.includes('Tipos de Documentos')) context = 'tipo_documento_adjunto';
    if (context) {
      if (titulo.includes('Agregar')) await postSeleccionable({ value, label: value, context });
      else if (titulo.includes('Modificar')) await putSeleccionable(id, { value, label: value, context });
      else if (titulo.includes('Eliminar')) await deleteSeleccionable(id, context);
    }
    setOpenDialogoCRUD(false);
    mutate();
  };

  const columns = [
    {
      title: 'Nombre',
      field: 'label',
    },
  ];

  return (
    <Fragment>
      <TablaSeleccionablesContext.Provider
        value={{
          openDialogoCRUD,
          setOpenDialogoCRUD,
          tituloDialogo,
          setTituloDialogo,
          nombre,
          setNombre,
          id,
          setId,
          handleAceptar,
          disableEditing,
          setDisableEditing,
          handleOpenAgregar,
          handleOpenModificar,
          handleOpenQuitar,
        }}
      >
        <MaterialTable
          title={titulo}
          is_loading={!data}
          data={data && data.data}
          columns={columns}
          filtering={false}
          grouping={false}
          searching={false}
          empty_rows={true}
          page_size={5}
          actions={[
            {
              tooltip: 'Agregar',
              icon: 'add',
              hidden: !puedeAgregar,
              onClick: (event, row) => handleOpenAgregar(`Agregar ${titulo}`),
              isFreeAction: true,
            },
            {
              tooltip: 'Editar',
              icon: 'edit',
              hidden: !puedeEditar,
              onClick: (event, row) => handleOpenModificar(`Modificar ${titulo}`, row.label, row._id),
            },
            {
              tooltip: 'Eliminar',
              icon: 'delete',
              hidden: !puedeEliminar,
              onClick: (event, row) => handleOpenQuitar(`Eliminar ${titulo}`, row.label, row._id),
            },
          ]}
        />
        <DialogoCRUDRegistroSeleccionable />
      </TablaSeleccionablesContext.Provider>
    </Fragment>
  );
}
