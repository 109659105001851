import { BACKEND } from "../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener un usuarioPlataforma.
 * @param {String} id id del registro
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {Object} cancelToken token para cancelacion
 */
export async function ObtenerUsuarioPlataforma(id, selects, populates, cancelToken = null) {
  let url = `${BACKEND}/usuarios-plataforma/${id}`;
  if (selects) selects.forEach(select => {
    url = url + "/" + select;
  });
  if (populates) populates.forEach(populate => {
    url = url + "/" + populate;
  });
  return Axios.get(url,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}
/**
 * Método encargado de obtener todos los usuariosPlataforma.
 */
export async function ObtenerUsuariosPlataforma() {
  try {
    let url = `${BACKEND}/usuarios-plataforma/rut/nombre/uid/is_propietario/is_ti/is_rff/is_contabilidad/is_gerente/is_responsable_gerencia/_perfiles_ref.nombre`;
    let response = await Axios.get(url);
    let usuariosResponse = response.data;
    for (let i = 0; i < usuariosResponse.data.length; i++) {
      usuariosResponse.data[i]["perfiles"] = Array.from(usuariosResponse.data[i]._perfiles_ref).map(perfil => perfil.nombre).join(", ");
    }
    return usuariosResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar un nuevo usuarioPlataforma.
 * @param {*} data Datos del usuarioPlataforma.
 */
export async function AgregarUsuarioPlataforma(data) {
  try {
    let url = `${BACKEND}/usuarios-plataforma`;
    let response = await Axios.post(url, data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de actualizar un usuarioPlataforma.
 * @param {Object} data Datos actualizados del usuarioPlataforma.
 * @param {String} id
 */
export async function ActualizarUsuarioPlataforma(data, id) {
  try {
    let url = `${BACKEND}/usuarios-plataforma/${id}`;
    let response = await Axios.put(url, data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un usuarioPlataforma.
 * @param {String} id
 */
export async function EliminarUsuarioPlataforma(id) {
  try {
    let url = `${BACKEND}/usuarios-plataforma/${id}`;
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener los usuarios del sistema.
 * @param {Object} filtro
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {Object} cancelToken token para cancelacion
 */
export async function getUsuariosPlataforma(filtro = {}, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/usuarios-plataforma/`;
  if (selects) selects.forEach(select => {
    url = url + "/" + select;
  });
  if (populates) populates.forEach(populate => {
    url = url + "/" + populate;
  });
  return Axios.get(url,
    {
      params: filtro,
      cancelToken: cancelToken
    }).then(res => res.data ? res.data.data : []);
}