import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import {
  getLicenciasSoftware,
  postAsignacionLicenciaSoftware,
  postLogLicenciaSoftware,
} from '../../../services/request/requestLicenciasSoftware';

import { TabLicenciasSoftwareActivoContext } from './tabLicenciasSoftware';
import MaterialReactSelect from '../../../components/materialReactSelect';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useParams } from 'react-router';
import { MainContext } from '../../../App';
import { VerBienContext } from '.';

async function getDataLicencias() {
  const response = await getLicenciasSoftware({});
  if (response.status) {
    return response.data;
  } else return [];
}

export default function DialogoCRUDAsignacionLicenciaSoftware() {
  const { refreshTable, openDialog, closeDialogo } = useContext(TabLicenciasSoftwareActivoContext);
  const { id } = useParams();
  const notistack = useSnackbar();
  const { usuarioSesion } = useContext(MainContext);
  const { bien } = useContext(VerBienContext);

  const [licenciasSoftware, setLicenciasSoftware] = useState([]);

  const [licenciaSeleccionada, setLicenciaSeleccionada] = useState('');

  const handleOpen = async () => {
    setLicenciaSeleccionada('');
    setLicenciasSoftware([]);
    return getDataLicencias().then((response) => setLicenciasSoftware(response));
  };

  async function handleSubmit() {
    const key = notistack.enqueueSnackbar('Asignando licencias...', {
      persist: true,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    });
    try {
      await postAsignacionLicenciaSoftware({
        _bien_ref: id,
        _contrato_ref: bien._contrato_ref,
        _gerencia_ref: bien._gerencia_ref,
        _usuario_ref: bien._asignado_ref,
        _licencia_software_ref: licenciaSeleccionada.value,
      });
      await postLogLicenciaSoftware({
        _usuario_ref: usuarioSesion.ref,
        _licencia_software_ref: licenciaSeleccionada.value,
        descripcion: `Asignación de Licencia a ${bien.codigo_activo}`,
      });
      closeDialogo();
      refreshTable();
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar('Operanción exitosa: Licencias asignadas', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
            <Close />
          </IconButton>
        ),
      });
    } catch (e) {
      console.log(e);
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar(
        'Error al intentar asignar las licencias. Intente de nuevo o contacte con el equipo de soporte.',
        {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
              <Close />
            </IconButton>
          ),
        },
      );
    }
  }

  return (
    <Dialog open={openDialog} onEnter={handleOpen} onClose={closeDialogo} fullWidth maxWidth="xl">
      <DialogTitle>Nueva asignación de licencia de software</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MaterialReactSelect
              value={licenciaSeleccionada}
              label="Licencia de Software"
              isClearable
              onChange={(newValue, actionMeta) => {
                if (newValue) {
                  setLicenciaSeleccionada(newValue);
                } else {
                  setLicenciaSeleccionada('');
                }
              }}
              placeholder="Seleccionar Licencia"
              options={licenciasSoftware.map((a) => ({
                value: a._id,
                label: `${a.nombre} - ${a.version} (${
                  a.cantidad_utilizada ? a.cantidad - a.cantidad_utilizada : a.cantidad
                })`,
              }))}
              noOptionsMessage={() => 'No se ha entrado la licencia'}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" alignItems="center" justify="center">
          <Button onClick={closeDialogo} variant="outlined" color="primary" style={{ marginRight: '8px' }}>
            Cerrar
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Aceptar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
