import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs, useMediaQuery, useTheme, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import ListadoArchivosValoresLibros from "./listadoArchivosValoresLibros";
import SubirArchivoValorLibro from "./subirArchivoValorLibro";

function TabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
	  <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
	  >
		{value === index && (
		  <Box >
			{children}
		  </Box>
		)}
	  </div>
	);
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

function Index(props) {
  const {
    open,
    close,    
  } = props;

  const [selectedTab, setSelectedTab] = useState(0);
	const theme = useTheme();
	const smBreak = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
    };
  
  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>
        
      </DialogTitle>      
        <DialogContent>  
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={selectedTab} 
                onChange={handleChangeTab} 
                aria-label="basic tabs"
                centered={!smBreak}
                variant={smBreak ? "scrollable" : "standard"}
                scrollButtons={smBreak ? "on" : "off"}
              >
                <Tab label="Carga planilla" {...a11yProps(0)} />
                <Tab label="Histórico de carga valores libro" {...a11yProps(1)} />				
              </Tabs>
            </Box>
            <TabPanel value={selectedTab} index={0}>
              <SubirArchivoValorLibro />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <ListadoArchivosValoresLibros />
            </TabPanel>			
          </Box>            
        </DialogContent> 
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={close}>Cerrar</Button>                
        </DialogActions>     
    </Dialog> 
  );
}

export default Index;