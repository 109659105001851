import axios from 'axios';
import { ADMINISTRACION_USUARIOS } from '../../constants/urls';
import { BACKEND } from '../../constants/urls';
import { auth } from '../../services/firebase';

/**
 * Obtener datos de usuario
 * @param {*} uid filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getUsuarioByUid(uid, cancelToken = null) {
  return axios
    .get(`${ADMINISTRACION_USUARIOS}/personas/uid/${uid}`, {
      cancelToken,
    })
    .then((res) => res.data);
}

/**
 * Obtener datos de usuarios. Se incluye filtro para obtener solo usuarios que están en Talana.
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getUsuarios(filter, cancelToken = null) {
  return axios
    .get(`${ADMINISTRACION_USUARIOS}/personas`, {
      params: filter,
      cancelToken,
    })
    .then((res) => res.data);
}

/**
 * Obtener datos de usuario
 * @param {*} id
 * @param {*} cancelToken token para cancelacion
 */
export function getUsuario(id, cancelToken = null) {
  return axios
    .get(`${ADMINISTRACION_USUARIOS}/personas/${id}`, {
      cancelToken,
    })
    .then((res) => res.data);
}

/**
 * Obtener datos de usuario
 * @param {*} uid filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function obtenerDatosSesionUsuario(uid, cancelToken = null) {
  return auth.currentUser
    .getIdToken(true)
    .then((idToken) => {
      return axios.post(
        `${BACKEND}/usuarios-plataforma/sesion`,
        {},
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
          cancelToken,
        },
      );
    })
    .then((res) => (res.data ? res.data.data : null));
}

/**
 * Método encargado de obtener un usuario desde API Usuarios, por su ID.
 * @param {*} usuarioID ID del usuario.
 */
export async function ObtenerUsuario(usuarioID) {
  try {
    const url = `${ADMINISTRACION_USUARIOS}/personas/${usuarioID}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado obtener varios registros de una ruta del SGU
 * @param {String} ruta ruta a consultar del servidor
 * @param {Object} filtro
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {Object} cancelToken token para cancelacion
 */
export async function getManySGU(ruta = '', filtro = {}, cancelToken = null) {
  const url = `${ADMINISTRACION_USUARIOS}${ruta}`;
  return axios
    .get(url, {
      params: filtro,
      cancelToken,
    })
    .then((res) => (res.data ? res.data : { status: false, data: { message: 'SIN RESPUESTA DEL SERVIDOR' } }))
    .catch((e) => {
      if (axios.isCancel(e)) {
        if (e.message) console.log('Request canceled', e.message);
        else console.log('Request canceled');
        return null;
      } else {
        // handle error
        return { status: false, data: { message: 'SIN RESPUESTA DEL SERVIDOR' } };
      }
    });
}
