import { useFormik } from 'formik';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import Page from './page';
import * as yup from 'yup';
import { getProveedores } from '../../../services/request/requestTipoProveedores';
import { getSociedad } from '../../../services/request/requestPortafolio';
import { getPropietarios } from '../../../services/request/requestPropietarios';
import { uploadFileToCloudStorage, viewDriveFile } from '../../../services/fileUpload';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { MainContext } from '../../../App';
import {
  postAceptarSolicitud,
  postRechazarSolicitud,
  getSolicitudActivacion,
  postNoActivarSolicitud,
} from '../../../services/request/requestSolicitudesActivacion';
import { useHistory, useParams } from 'react-router';
import { putBien } from '../../../services/request/requestBienes';
import * as ROUTES from '../../../constants/routes';
import { GenerarLog } from '../../../services/activoLog';
import { APROBAR_TODO } from '../../../constants/permissions';

export const VerSolicitudActivacionContext = createContext({
  esActivable: false,
  isSubmiting: false,
});

export default function VerSolicitudActivacion(props) {
  const { id } = useParams();
  const history = useHistory();
  const notistack = useSnackbar();
  const { usuarioSesion, permisos } = useContext(MainContext);
  const [proveedoresOptions, setProveedoresOptions] = useState([]);
  const [propietariosOptions, setPropietariosOptions] = useState([]);
  const [title, setTitle] = useState('Solicitud Activiación');
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [showCloseDialog, setShowCloseDialog] = useState(true);
  const [esActivable, setEsActivable] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const formik = useFormik({
    initialValues: {
      archivo: null,
      fecha: new Date(),
      valor: 0,
      numero: '',
      proveedor: { value: null },
      comentario: '',
      archivoFactura: {
        nombre: '',
      },
      sociedad: 'Tercero',
      contrato: '',
      solicitante: '',
      clase: '',
      marca: '',
      serial_number: '',
      modelo: '',
      condicion_actual: '',
      propietario: { value: null },
      codigo_contable: '',
    },
    onSubmit: async (values) => {
      try {
        setIsSubmiting(true);
        setDialogTitle('Espere un momento');
        setDialogMessage('Actualizando datos del activo...');
        setShowCloseDialog(false);
        setOpenDialog(true);
        const solictudActivacion = await getSolicitudActivacion(id);
        const bien = solictudActivacion._bien_ref;
        const activo = bien.activo
          ? bien.activo
          : {
              factura: {
                numero: '',
                archivo: null,
                proveedor: null,
                fecha: null,
              },
              valor: '',
              _propietario_ref: null,
              ubicacion: '',
              descripcion: '',
            };
        activo['factura'] = {
          numero: values.numero,
          archivo: values.archivoFactura,
          proveedor: values.proveedor.value,
          fecha: values.fecha,
        };
        if (values.archivo) {
          const uploadedFile = await uploadFileToCloudStorage(
            values.archivo,
            `activos/${bien.codigo_activo}`,
            values.archivo.name,
          );
          activo.factura.archivo = uploadedFile;
        }

        activo._propietario_ref = values.propietario.value ? values.propietario.value._id : null;
        await putBien(bien._id, { activo: activo });
        resetFormulario();
        setDialogTitle('Operanción exitosa');
        setDialogMessage('La información del activo ha sido actualizada');
        setShowCloseDialog(true);
        setOpenDialog(true);
        setIsSubmiting(false);
      } catch (e) {
        setIsSubmiting(false);
        setDialogTitle('Error');
        setDialogMessage(
          'No ha sido posible actualizar el activo. Intente de nuevo o contacte con el equipo de soporte',
        );
        setShowCloseDialog(true);
        setOpenDialog(true);
        console.log(e);
      }
    },
    validationSchema: yup.object({}),
  });

  const { resetForm } = formik;

  const resetFormulario = useCallback(async () => {
    setEsActivable(false);

    const proveedores = await getProveedores();
    const propietarios = await getPropietarios();

    if (proveedores.status && propietarios.length > 0) {
      const solictudActivacion = await getSolicitudActivacion(
        id,
        [],
        ['_bien_ref.contrato_ref', '_usuario_solicitante_ref', '_bien_ref._clase_ref'],
      );

      if (solictudActivacion) {
        const optionsProveedor = proveedores.data.map((value, index) => ({
          value: value,
          label: value.nombre,
        }));

        const proveedor =
          solictudActivacion._bien_ref.activo &&
          solictudActivacion._bien_ref.activo.factura &&
          solictudActivacion._bien_ref.activo.factura.proveedor
            ? optionsProveedor.find((a) => a.value._id === solictudActivacion._bien_ref.activo.factura.proveedor._id)
            : null;

        const optionsPropietarios = propietarios.map((value, index) => ({
          value: value,
          label: value.nombre,
        }));

        const sociedad = solictudActivacion._bien_ref._sociedad_ref
          ? await getSociedad(solictudActivacion._bien_ref._sociedad_ref)
          : null;
        const propietario = solictudActivacion._bien_ref.activo
          ? optionsPropietarios.find((a) => a.value._id === solictudActivacion._bien_ref.activo._propietario_ref)
          : null;
        setTitle(`Solicitud Activación: ${solictudActivacion._bien_ref.codigo_activo}`);
        const archivoFactura =
          solictudActivacion._bien_ref.activo &&
          solictudActivacion._bien_ref.activo.factura.archivo &&
          solictudActivacion._bien_ref.activo.factura.archivo.nombre
            ? solictudActivacion._bien_ref.activo.factura.archivo
            : {
                nombre: '',
              };
        const data = {
          archivo: null,
          fecha: solictudActivacion._bien_ref.activo ? solictudActivacion._bien_ref.activo.factura.fecha : '',
          valor: solictudActivacion._bien_ref.activo ? solictudActivacion._bien_ref.activo.valor : '',
          numero: solictudActivacion._bien_ref.activo ? solictudActivacion._bien_ref.activo.factura.numero : '',
          proveedor: proveedor ? proveedor : { value: null },
          comentario: solictudActivacion.comentario ? solictudActivacion.comentario : '',
          archivoFactura: archivoFactura,
          sociedad: sociedad ? sociedad.nombre : 'Tercero',
          contrato: solictudActivacion._bien_ref._contrato_ref
            ? solictudActivacion._bien_ref._contrato_ref.codigo
            : '--',
          solicitante: solictudActivacion._usuario_solicitante_ref
            ? solictudActivacion._usuario_solicitante_ref.nombre_completo
            : '--',
          clase: solictudActivacion._bien_ref._clase_ref ? solictudActivacion._bien_ref._clase_ref.nombre : '--',
          marca: solictudActivacion._bien_ref.marca,
          serial_number: solictudActivacion._bien_ref.serial_number,
          modelo: solictudActivacion._bien_ref.modelo,
          condicion_actual: solictudActivacion._bien_ref.condicion_actual,
          propietario: propietario ? propietario : { value: null },
          codigo_contable: solictudActivacion._bien_ref.codigo_contable,
        };
        if (solictudActivacion.estado === 'Pendiente') {
          if (permisos[APROBAR_TODO]) {
            setEsActivable(true);
          } else if (
            solictudActivacion.tipo === 'Responsable' &&
            solictudActivacion._usuario_revisor_ref &&
            solictudActivacion._usuario_revisor_ref.usuario_id === usuarioSesion.uid
          ) {
            setEsActivable(true);
          } else if (solictudActivacion.tipo === 'TI' && usuarioSesion.is_ti) {
            setEsActivable(true);
          } else if (solictudActivacion.tipo === 'RFF' && usuarioSesion.is_rff) {
            setEsActivable(true);
          } else if (solictudActivacion.tipo === 'Registro Contable' && usuarioSesion.is_contabilidad) {
            setEsActivable(true);
          }
        }

        setProveedoresOptions(optionsProveedor);
        setPropietariosOptions(optionsPropietarios);
        resetForm({ values: data });
      }
    }
  }, [resetForm, id]);

  async function refreshProveedores(solictudActivacion) {
    const proveedores = await getProveedores();
    const optionsProveedor = proveedores.data.map((value, index) => ({
      value: value,
      label: value.nombre,
    }));
    setProveedoresOptions(optionsProveedor);
  }

  const handleSubmitNoActivar = async (comentario) => {
    try {
      setIsSubmiting(true);
      setEsActivable(false);
      setDialogTitle('Espere un momento');
      setDialogMessage('Actualizando solicitud de activacion...');
      setShowCloseDialog(false);
      setOpenDialog(true);
      await postNoActivarSolicitud(id, usuarioSesion, comentario);
      //Se podrían obtener los datos del BIEN desde FORMIK.VALUES.
      const solictudActivacion = await getSolicitudActivacion(id);
      const bien = solictudActivacion._bien_ref;
      let registroLog = {
        _bien_ref: bien._id,
        titulo: 'Solicitud de Activación',
        cuerpo: `La solicitud de activación del activo ${bien.codigo_activo} fue marcada como No Activable.`,
        tipo: 'solicitud_activacion',
        icon: 'error_outline',
      };
      setIsSubmiting(false);
      await GenerarLog(registroLog);
      history.push(ROUTES.LISTADO_SOLICITUDES_ACTIVACION);
    } catch (e) {
      setIsSubmiting(false);
      setEsActivable(true);
      setDialogTitle('Error');
      setDialogMessage(
        'No ha sido posible actualizar la solicitud de activación. Intente de nuevo o contacte con el equipo de soporte',
      );
      setShowCloseDialog(true);
      setOpenDialog(true);
      console.log(e);
    }
  };

  const handleSubmitActivacion = async () => {
    try {
      setIsSubmiting(true);
      setEsActivable(false);
      setDialogTitle('Espere un momento');
      setDialogMessage('Actualizando solicitud de activacion...');
      setShowCloseDialog(false);
      setOpenDialog(true);
      await postAceptarSolicitud(id, usuarioSesion);
      //Se podrían obtener los datos del BIEN desde FORMIK.VALUES.
      const solictudActivacion = await getSolicitudActivacion(id);
      const bien = solictudActivacion._bien_ref;
      let registroLog = {
        _bien_ref: bien._id,
        titulo: 'Solicitud de Activación',
        cuerpo: `La solicitud de activación del activo ${bien.codigo_activo} fue aceptada por ${usuarioSesion.nombre}.`,
        tipo: 'solicitud_activacion',
        icon: 'check_circle_outline',
      };
      setIsSubmiting(false);
      await GenerarLog(registroLog);
      history.push(ROUTES.LISTADO_SOLICITUDES_ACTIVACION);
    } catch (e) {
      setIsSubmiting(false);
      setEsActivable(true);
      setDialogTitle('Error');
      setDialogMessage(
        'No ha sido posible actualizar la solicitud de activación. Intente de nuevo o contacte con el equipo de soporte',
      );
      setShowCloseDialog(true);
      setOpenDialog(true);
      console.log(e);
    }
  };

  const handleSubmitRechazo = async (comentario) => {
    try {
      setIsSubmiting(true);
      setEsActivable(false);
      setDialogTitle('Espere un momento');
      setDialogMessage('Actualizando solicitud de activacion...');
      setShowCloseDialog(false);
      setOpenDialog(true);
      await postRechazarSolicitud(id, usuarioSesion, comentario);
      const solictudActivacion = await getSolicitudActivacion(id);
      //Se podrían obtener los datos del BIEN desde FORMIK.VALUES.
      const bien = solictudActivacion._bien_ref;
      let registroLog = {
        _bien_ref: id,
        titulo: 'Solicitud de Activación',
        cuerpo: `La solicitud de activación del activo ${bien.codigo_activo} fue rechazada por ${usuarioSesion.nombre} con comentario "${comentario}".`,
        tipo: 'solicitud_activacion',
        icon: 'error_outline',
      };
      setIsSubmiting(false);
      await GenerarLog(registroLog);
      history.push(ROUTES.LISTADO_SOLICITUDES_ACTIVACION);
    } catch (e) {
      setIsSubmiting(false);
      setEsActivable(true);
      setDialogTitle('Error');
      setDialogMessage(
        'No ha sido posible actualizar la solicitud de activación. Intente de nuevo o contacte con el equipo de soporte',
      );
      setShowCloseDialog(true);
      setOpenDialog(true);
      console.log(e);
    }
  };

  useEffect(() => {
    resetFormulario();
  }, [resetFormulario]);

  const handleVerArchivoFactura = async (archivo) => {
    const key = notistack.enqueueSnackbar('Descargando Archivo...', {
      persist: true,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    });
    try {
      const downloadUrl = archivo.url.replace(/ /g, '%20');
      await viewDriveFile(downloadUrl, archivo.nombre);
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar('Descarga completada', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
            <Close />
          </IconButton>
        ),
      });
    } catch (e) {
      console.log(e);
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar(
        'Error en descarga. Intente de nuevo o contacte con el equipo de soporte.',
        {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
              <Close />
            </IconButton>
          ),
        },
      );
    }
  };

  return (
    <VerSolicitudActivacionContext.Provider
      value={{
        esActivable,
        formik,
        title,
        handleSubmitNoActivar,
        handleSubmitActivacion,
        proveedores: proveedoresOptions,
        propietarios: propietariosOptions,
        handleVerArchivoFactura,
        handleSubmitRechazo,
        openDialog,
        handleCloseDialog,
        dialogTitle,
        dialogMessage,
        showCloseDialog,
        refreshProveedores,
        isSubmiting,
      }}
    >
      <Page />
    </VerSolicitudActivacionContext.Provider>
  );
}
