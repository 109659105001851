import { BACKEND_SDA } from '../../constants/urls';
import axios from 'axios';
import { auth } from '../firebase';

/**
 * Obtener datos de proveedores del SDA
 * @param {*} fitro filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getProveedoresSDA(params = {}, cancelToken = null) {
  return auth.currentUser.getIdToken(true).then((idToken) => {
    return axios.get(`${BACKEND_SDA}/proveedor`,
      {
        params: params,
        headers: {
          "authorization": `Bearer ${idToken}`
        },
        cancelToken: cancelToken
      }
    );
  })
    .then(res => res.data ? res.data : null);
}

/**
 * Agregar proveedor al SDA
 * @param {*} data
 * @param {*} cancelToken token para cancelacion
 */
export function postProveedorSDA(data, cancelToken = null) {
  return auth.currentUser.getIdToken(true).then((idToken) => {
    return axios.post(`${BACKEND_SDA}/proveedor`,
      data,
      {
        headers: {
          "authorization": `Bearer ${idToken}`
        },
        cancelToken: cancelToken
      }
    );
  })
    .then(res => res.data ? res.data : null);
}

/**
 * Obtener datos de comunas del SDA
 * @param {*} fitro filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
 export function getComunasSDA(params = {}, cancelToken = null) {
  return auth.currentUser.getIdToken(true).then((idToken) => {
    return axios.get(`${BACKEND_SDA}/comuna`,
      {
        params: params,
        headers: {
          "authorization": `Bearer ${idToken}`
        },
        cancelToken: cancelToken
      }
    );
  })
    .then(res => res.data ? res.data : null);
}

/**
 * Obtener datos de ciudades del SDA
 * @param {*} fitro filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
 export function getCiudadesSDA(params = {}, cancelToken = null) {
  return auth.currentUser.getIdToken(true).then((idToken) => {
    return axios.get(`${BACKEND_SDA}/ciudad`,
      {
        params: params,
        headers: {
          "authorization": `Bearer ${idToken}`
        },
        cancelToken: cancelToken
      }
    );
  })
    .then(res => res.data ? res.data : null);
}