import axios from "axios";

/**
 * Para generar hoja de vida de vehiculo desde appscript
 * @param {*} data
 * @param {*} cancelToken token para cancelacion
 */
export function postGenerarHojaVidaVehiculo(data, cancelToken = null) {
  const url = "https://script.google.com/a/cydingenieria.cl/macros/s/AKfycbxf-vtDZP1NQkpge_n7n-lv_q_WDmTAOnkjuqml/exec";
  return axios.post(url,
    JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'text/plain'
      },
      cancelToken: cancelToken
    }).then(res => res.data);
}
/**
 * Generar hoja de vida de equipo Laboratorio/Topografia desde appscript
 * @param {*} data
 * @param {*} cancelToken token para cancelacion
 */
export function postGenerarHojaVidaEquipoLaboratorioTopografia(data, cancelToken = null) {
  const url = "https://script.google.com/a/cydingenieria.cl/macros/s/AKfycbzihGyB1LRwZM408kvdR6f39-eVrxR2T4ZxoE5k/exec";
  return axios.post(url,
    JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'text/plain'
      },
      responseType: 'text',
      cancelToken: cancelToken
    }).then(res => res.data);
}

/**
 * Eliminar archivo Drive
 * @param {*} id
 * @param {*} cancelToken token para cancelacion
 */
 export function postEliminarArchivoDrive(id, cancelToken = null) {
  const url = "https://script.google.com/a/cydingenieria.cl/macros/s/AKfycbwBdH4WzlcIIveTV33D2bQrWyBnajs6d3a26beh/exec";
  return axios.post(url,
    JSON.stringify({id: id}),
    {
      headers: {
        'Content-Type': 'text/plain'
      },
      responseType: 'text',
      cancelToken: cancelToken
    }).then(res => res.data);
}