import React, { useContext, useCallback, useEffect } from 'react';
import { Button, Grid, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@material-ui/core';
import { useFormik } from 'formik';
import { DetalleUbicacionesContext } from './listadoDetalleUbicaciones';
// import * as yup from 'yup';

export const DetalleUbicaciones = () => {
  const { openDialogoCRUD, setOpenDialogoCRUD, tituloDialogo, handleAceptar, disableEditing, initialValues } =
    useContext(DetalleUbicacionesContext);

  const formik = useFormik({ initialValues, onSubmit: handleAceptar });

  const { values, errors, touched, handleSubmit, handleChange, resetForm } = formik;

  const resetFormik = useCallback(() => {
    resetForm({ values: initialValues });
    console.log('Valores dados en detalle: ', initialValues);
  }, [initialValues, resetForm]);

  useEffect(() => {
    resetFormik();
  }, [resetFormik]);

  const handleClose = () => setOpenDialogoCRUD(false);

  return (
    <Dialog open={openDialogoCRUD} maxWidth="md" fullWidth>
      <DialogTitle>{tituloDialogo}</DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs>
              <Tooltip title="Formato: La Pintana => LP">
                <TextField
                  name="nombre_abreviado"
                  label="Identificación Abreviada"
                  value={values.nombre_abreviado}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled={disableEditing}
                  error={errors.nombre_abreviado && touched.nombre_abreviado ? true : false}
                  helperText={
                    errors.nombre_abreviado && touched.nombre_abreviado
                      ? errors.nombre_abreviado
                      : 'Isidora Goyenechea => abreviado: IG'
                  }
                />
              </Tooltip>
            </Grid>
            <Grid item xs>
              <TextField
                name="numero_bodega"
                label="Nº Bodega"
                value={values.numero_bodega}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                disabled={disableEditing}
                error={errors.numero_bodega && touched.numero_bodega ? true : false}
                helperText={errors.numero_bodega && touched.numero_bodega ? errors.numero_bodega : ''}
              />
            </Grid>
            <Grid item xs>
              <Tooltip title="Formato: RA, RB, ...">
                <TextField
                  name="rack"
                  label="Rack"
                  value={values.rack}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled={disableEditing}
                  error={errors.rack && touched.rack ? true : false}
                  helperText={errors.rack && touched.rack ? errors.rack : ''}
                />
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Tooltip title="Formato: C1, C2, ...">
                <TextField
                  name="columna"
                  label="Columna"
                  value={values.columna}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled={disableEditing}
                  error={errors.columna && touched.columna ? true : false}
                  helperText={errors.columna && touched.columna ? errors.columna : ''}
                />
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Tooltip title="Formato: F1, F2, ...">
                <TextField
                  name="fila"
                  label="Fila"
                  value={values.fila}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled={disableEditing}
                  error={errors.fila && touched.fila ? true : false}
                  helperText={errors.fila && touched.fila ? errors.fila : ''}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
