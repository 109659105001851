import React, { createContext, useState } from 'react';
import MaterialTable from '../../../components/materialTable';
import useSWR from 'swr';
import { Fragment } from 'react';
import DialogoCRUDClase from './dialogoCRUDClase';
import { useHistory } from 'react-router';
import { AJUSTES_CONFIGURACION, LISTADO_DOCUMENTOS_CLASE } from '../../../constants/routes';
import { Check as CheckIcon, Close as CloseIcon } from '@material-ui/icons';
import { getClases, postClase, putClase, deleteClase } from '../../../services/request/requestClase';
import formatNumber from 'format-number';
const numberFormater = formatNumber({ integerSeparator: '.', decimal: ',' });

export const TablaClasesContext = createContext({
  openDialogoCRUD: false,
  setOpenDialogoCRUD: () => {},
  tituloDialogo: '',
  setTituloDialogo: () => {},
  initialValues: {},
  setInitialValues: () => {},
  handleAceptar: () => {},
  disableEditing: false,
  setDisableEditing: () => {},
});

function getData() {
  return getClases(
    {},
    [
      'nombre',
      'is_computacional',
      'is_vehiculo',
      'tarifa',
      'is_inmueble',
      'is_topografico',
      'is_laboratorio',
      'is_otro_instrumento',
      'valor_fijo_tarifa',
    ],
    ['_tipo_recurso_ref.nombre', '_grupo_contable_ref.nombre', '_grupo_contable_ref.sigla'],
  ).then((clases) => {
    const dataClases = [];
    clases.data.forEach((a) => {
      const data = {
        ...a,
        tipo_recurso: a._tipo_recurso_ref.nombre,
        grupo_contable: a._grupo_contable_ref
          ? a._grupo_contable_ref.sigla + ' - ' + a._grupo_contable_ref.nombre
          : '--',
      };
      dataClases.push(data);
    });
    return dataClases;
  });
}

export default function TablaClases(props) {
  const { puedeAgregar, puedeEditar, puedeEliminar } = props;
  const { data, mutate } = useSWR('get:modelos', (key) => getData());
  const [openDialogoCRUD, setOpenDialogoCRUD] = useState(false);
  const [tituloDialogo, setTituloDialogo] = useState('');
  const [initialValues, setInitialValues] = useState({
    id: null,
    nombre: '',
    tipo_recurso: '',
    grupo_contable: '',
    is_computacional: false,
    is_vehiculo: false,
    is_inmueble: false,
    is_topografico: false,
    is_laboratorio: false,
    is_otro_instrumento: false,
    tarifa: '',
  });
  const [disableEditing, setDisableEditing] = useState(false);
  const history = useHistory();
  const handleOpenAgregar = (titulo) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      nombre: '',
      tipo_recurso: '',
      grupo_contable: '',
      is_computacional: false,
      is_vehiculo: false,
      is_inmueble: false,
      is_topografico: false,
      is_laboratorio: false,
      is_otro_instrumento: false,
      tarifa: '',
      valor_fijo_tarifa: '',
    });
    setDisableEditing(false);
  };

  const handleOpenModificar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    console.log(row);
    setInitialValues({
      id: row._id,
      nombre: row.nombre,
      tipo_recurso: row._tipo_recurso_ref
        ? { value: row._tipo_recurso_ref._id, label: row._tipo_recurso_ref.nombre }
        : '',
      grupo_contable: row._grupo_contable_ref
        ? { value: row._grupo_contable_ref._id, label: row._grupo_contable_ref.nombre }
        : '',
      is_computacional: row.is_computaFcional ? row.is_computacional : false,
      is_vehiculo: row.is_vehiculo ? row.is_vehiculo : false,
      is_inmueble: row.is_inmueble ? row.is_inmueble : false,
      is_topografico: row.is_topografico ? row.is_topografico : false,
      is_laboratorio: row.is_laboratorio ? row.is_laboratorio : false,
      is_otro_instrumento: row.is_otro_instrumento ? row.is_otro_instrumento : false,
      tarifa: row.tarifa ? row.tarifa : '',
      valor_fijo_tarifa: row.valor_fijo_tarifa ? row.valor_fijo_tarifa : '',
    });
    setDisableEditing(false);
  };

  const handleOpenQuitar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      id: row._id,
      nombre: row.nombre,
      tipo_recurso: row._tipo_recurso_ref
        ? { value: row._tipo_recurso_ref._id, label: row._tipo_recurso_ref.nombre }
        : '',
      grupo_contable: row._grupo_contable_ref
        ? { value: row._grupo_contable_ref._id, label: row._grupo_contable_ref.nombre }
        : '',
      is_computacional: row.is_computacional ? row.is_computacional : false,
      is_vehiculo: row.is_vehiculo ? row.is_vehiculo : false,
      is_inmueble: row.is_inmueble ? row.is_inmueble : false,
      is_topografico: row.is_topografico ? row.is_topografico : false,
      is_laboratorio: row.is_laboratorio ? row.is_laboratorio : false,
      is_otro_instrumento: row.is_otro_instrumento ? row.is_otro_instrumento : false,
      tarifa: row.tarifa ? row.tarifa : '',
      valor_fijo_tarifa: row.valor_fijo_tarifa ? row.valor_fijo_tarifa : '',
    });
    setDisableEditing(true);
  };

  const handleAceptar = async (values) => {
    if (tituloDialogo) {
      if (tituloDialogo.includes('Nuev')) {
        await postClase({
          ...values,
          _tipo_recurso_ref: values.tipo_recurso.value,
        });
      } else if (tituloDialogo.includes('Modificar'))
        await putClase(values.id, {
          nombre: values.nombre,
          _tipo_recurso_ref: values.tipo_recurso.value ? values.tipo_recurso.value : null,
          _grupo_contable_ref: values.grupo_contable.value ? values.grupo_contable.value : null,
          is_computacional: values.is_computacional,
          is_vehiculo: values.is_vehiculo,
          is_inmueble: values.is_inmueble,
          is_topografico: values.is_topografico,
          is_laboratorio: values.is_laboratorio,
          is_otro_instrumento: values.is_otro_instrumento,
          tarifa: values.tarifa,
          valor_fijo_tarifa: values.valor_fijo_tarifa,
        });
      else if (tituloDialogo.includes('Eliminar')) await deleteClase(values.id);
    }
    mutate(getData());
    setOpenDialogoCRUD(false);
  };

  const handleVolver = () => {
    history.push(AJUSTES_CONFIGURACION);
  };

  const columns = [
    {
      title: 'Nombre',
      field: 'nombre',
      align: 'center',
      defaultSort: 'asc',
    },
    {
      title: 'Grupo Contable',
      field: 'grupo_contable',
      align: 'center',
    },
    {
      title: 'Tipo',
      field: 'tipo_recurso',
      align: 'center',
    },
    {
      title: 'Tarifa',
      field: 'tarifa',
      align: 'center',
    },
    {
      title: 'Valor Fijo (Tarifa de Vehículos)',
      field: 'valor_fijo_tarifa',
      render: (a) => '$' + numberFormater(a.valor_fijo_tarifa ? a.valor_fijo_tarifa : 0),
    },
    {
      title: 'Es vehículo',
      field: 'is_vehiculo',
      render: (rowData) => (rowData.is_vehiculo ? <CheckIcon /> : <CloseIcon />),
      align: 'center',
    },
    {
      title: 'Es equipo computacional',
      field: 'is_computacional',
      render: (rowData) => (rowData.is_computacional ? <CheckIcon /> : <CloseIcon />),
      align: 'center',
    },
    {
      title: 'Es inmueble',
      field: 'is_inmueble',
      render: (rowData) => (rowData.is_inmueble ? <CheckIcon /> : <CloseIcon />),
      align: 'center',
    },
    {
      title: 'Es eq. topográfico',
      field: 'is_topografico',
      render: (rowData) => (rowData.is_topografico ? <CheckIcon /> : <CloseIcon />),
      align: 'center',
    },
    {
      title: 'Es eq. laboratorio',
      field: 'is_laboratorio',
      render: (rowData) => (rowData.is_laboratorio ? <CheckIcon /> : <CloseIcon />),
      align: 'center',
    },
    {
      title: 'Es otro instrumento',
      field: 'is_otro_instrumento',
      render: (rowData) => (rowData.is_otro_instrumento ? <CheckIcon /> : <CloseIcon />),
      align: 'center',
    },
  ];

  return (
    <Fragment>
      <TablaClasesContext.Provider
        value={{
          openDialogoCRUD,
          setOpenDialogoCRUD,
          tituloDialogo,
          setTituloDialogo,
          handleAceptar,
          disableEditing,
          setDisableEditing,
          initialValues,
          setInitialValues,
        }}
      >
        <MaterialTable
          actions={[
            {
              tooltip: 'Agregar',
              icon: 'add',
              hidden: !puedeAgregar,
              onClick: (event, row) => handleOpenAgregar('Nuevo Modelo'),
              isFreeAction: true,
            },
            {
              tooltip: 'Editar',
              icon: 'edit',
              hidden: !puedeEditar,
              onClick: (event, row) => handleOpenModificar('Modificar Modelo', row),
            },
            {
              tooltip: 'Eliminar',
              icon: 'delete',
              hidden: !puedeEliminar,
              onClick: (event, row) => handleOpenQuitar('Eliminar Modelo', row),
            },
            {
              tooltip: 'Volver',
              icon: 'arrow_back',
              onClick: (event, row) => handleVolver(),
              isFreeAction: true,
            },
            {
              tooltip: 'Ver Documentos Requeridos',
              icon: 'list',
              onClick: (event, row) => history.push(LISTADO_DOCUMENTOS_CLASE.replace(':idClase', row._id)),
            },
          ]}
          title="Clases"
          is_loading={!data && !Array.isArray(data)}
          data={Array.isArray(data) ? data : []}
          columns={columns}
          filtering={false}
          grouping={false}
          empty_rows={true}
          page_size={20}
        />
        <DialogoCRUDClase />
      </TablaClasesContext.Provider>
    </Fragment>
  );
}
