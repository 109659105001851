import React, { useContext, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { DatePicker } from '@material-ui/pickers';
import { useFormik } from 'formik';
import { object, string, date, mixed } from 'yup';
import { useSnackbar } from 'notistack';
import useSWR from 'swr';
import Moment from 'moment';
import { VerBienContext } from '.';
import FileInput from '../../../components/fileInput';
import { postDocumentoAdjunto, putBien, putDocumentoAdjunto } from '../../../services/request/requestBienes';
import { GenerarLog } from '../../../services/activoLog';
import { getSeleccionables, postSeleccionable } from '../../../services/request/requestSeleccionables';
import { uploadFileToCloudStorage } from '../../../services/fileUpload';
import { getMunicipalidades, postMunicipalidad } from '../../../services/request/requestMunicipalidades';
import { getAseguradoras, postAseguradora } from '../../../services/request/requestAseguradoras';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import MaterialCreatable from '../../../components/materialCreatable';
import { AGREGAR_TIPO_DOCS } from '../../../constants/permissions';
import { MainContext } from '../../../App';

async function getDataMunicipalidades(filter) {
  const response = await getMunicipalidades();
  if (response && response.status) {
    return response.data.map((a) => ({
      value: a,
      label: a.nombre,
    }));
  } else {
    return [];
  }
}
async function getDataAseguradoras(filter) {
  const response = await getAseguradoras();
  if (response && response.status) {
    return response.data.map((a) => ({
      value: a,
      label: a.aseguradora,
    }));
  } else {
    return [];
  }
}

export default function DialogoAgregarArchivo() {
  const { permisos } = useContext(MainContext);
  const { bien, openAgregarArchivo, setOpenAgregarArchivo, loadData, ArchivoRenovacion, SetArchivoRenovacion } =
    useContext(VerBienContext);
  const {
    data: tiposDocumentos,
    isValidating,
    mutate,
  } = useSWR('tipos_documentos', (key) => getSeleccionables({ context_eq: 'tipo_documento_adjunto' }), {
    revalidateOnFocus: false,
  });
  const [IsOpenTipoArchivo, SetIsOpenTipoArchivo] = useState(false);
  const notistack = useSnackbar();
  const { data: municipalidades } = useSWR('get:municipalidades', (key) => getDataMunicipalidades({}), {
    refreshInterval: 0,
  });
  const { data: aseguradoras } = useSWR('get:aseguradoras', (key) => getDataAseguradoras({}), { refreshInterval: 0 });
  const formikArchivo = useFormik({
    initialValues: {
      archivo: null,
      tipo: null,
      fecha: null,
      fecha_proxima_carga: null,
      _bien_ref: '',
      municipalidad: '',
      laboratorio_proveedor: '',
      valor_permiso_circulacion: 0,
      aseguradora: '',
      valor_prima_Seguro: 0,
      poliza_seguro: '',
    },
    validationSchema: object().shape({
      archivo: mixed().nullable().required('El archivo es requerido.'),
      tipo: object().nullable().required('El tipo de archivo es requerido.'),
      fecha: date().nullable().required('La fecha del archivo es requerida.'),
      fecha_proxima_carga: date().nullable().optional(),
      _bien_ref: string().required('El activo es requerido.'),
    }),
    onSubmit: async (values, helper) => {
      try {
        notistack.enqueueSnackbar('Guardando cambios...', {
          persist: true,
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
        });

        if (!'image/x-png,image/png,image/gif,image/jpeg,application/pdf'.includes(values.archivo.type)) {
          //Si el archivo seleccionado no tiene el formato requerido.
          throw `Formato de archivo ${values.archivo.type} inválido.`;
        }

        let uploaded = null;
        if (values.archivo) {
          uploaded = await uploadFileToCloudStorage(
            values.archivo,
            `activos/${bien.codigo_activo}/adjuntos`,
            `${values.tipo.label} ${Moment().format('DDMMYYYY_HHmmss')}`,
          );
        }
        console.log('valores pasados: ', values);
        let documentoAdjunto = {
          archivo: uploaded,
          tipo: values.tipo.label,
          municipalidad: values.municipalidad && values.municipalidad.label ? values.municipalidad.label : '',
          laboratorio_proveedor: values.laboratorio_proveedor ? values.laboratorio_proveedor : '',
          valor_permiso_circulacion: values.valor_permiso_circulacion ? values.valor_permiso_circulacion : 0,
          fecha: values.fecha,
          fecha_proxima_carga: values.fecha_proxima_carga,
          _bien_ref: values._bien_ref,
          aseguradora: values.aseguradora && values.aseguradora.label ? values.aseguradora.label : '',
          valor_prima_seguro: values.valor_prima_seguro ? values.valor_prima_seguro : 0,
          poliza_seguro: values.poliza_seguro ? values.poliza_seguro : '',
        };
        let registroLog = {
          _bien_ref: bien._id,
          titulo: 'Actualización de Documentos',
          cuerpo: `Se ${ArchivoRenovacion ? 'renovó' : 'adjuntó'} un nuevo documento de tipo ${
            values.tipo.label
          } al activo ${bien.codigo_activo}.`,
          tipo: 'actualizacion',
          icon: 'attach_file',
        };
        console.log('documentos a guardar: ', documentoAdjunto);
        await Promise.all([postDocumentoAdjunto(documentoAdjunto), GenerarLog(registroLog)]);
        if (values.municipalidad && values.municipalidad.__isNew__)
          await postMunicipalidad({
            nombre: values.municipalidad.value,
          });
        if (documentoAdjunto.tipo === 'Permiso Circulación')
          await putBien(bien._id, {
            valor_permiso_circulacion: values.valor_permiso_circulacion,
          });
        if (ArchivoRenovacion) {
          //Si es renovación de archivo, se marca el archivo anterior como renovado.
          await putDocumentoAdjunto(ArchivoRenovacion._id, { is_renovado: true });
        }

        loadData();
        notistack.closeSnackbar();
        notistack.enqueueSnackbar('Operanción exitosa: Documento adjuntado.', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (key) => (
            <IconButton onClick={() => notistack.closeSnackbar(key)}>
              <Close />
            </IconButton>
          ),
        });
      } catch (error) {
        console.error(error);
        notistack.closeSnackbar();
        notistack.enqueueSnackbar(
          'Error: No ha sido posible adjuntar el documento. Intente de nuevo o contacte con Soporte.',
          {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            action: (key) => (
              <IconButton onClick={() => notistack.closeSnackbar(key)}>
                <Close />
              </IconButton>
            ),
          },
        );
      } finally {
        setOpenAgregarArchivo(false);
        helper.resetForm({
          values: {
            archivo: null,
            tipo: null,
            fecha: null,
            fecha_proxima_carga: null,
            _bien_ref: '',
            laboratorio_proveedor: '',
          },
        });
      }
    },
  });

  const formikTipoArchivo = useFormik({
    initialValues: {
      nombre: '',
    },
    validationSchema: object().shape({
      nombre: string()
        .min(3, 'El nombre debe tener al menos ${min} caracteres.')
        .max(50, 'El nombre debe temer a lo más ${max} caracteres.')
        .required('El nombre es requerido.'),
    }),
    onSubmit: async (values, helper) => {
      try {
        notistack.enqueueSnackbar('Guardando cambios...', {
          persist: true,
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
        });

        let data = {
          value: ModificarTexto(values.nombre),
          label: values.nombre,
          context: 'tipo_documento_adjunto',
        };
        await postSeleccionable(data, 'tipo_documento_adjunto');

        notistack.closeSnackbar();
        notistack.enqueueSnackbar('Operanción exitosa: Tipo de archivo agregado.', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (key) => (
            <IconButton onClick={() => notistack.closeSnackbar(key)}>
              <Close />
            </IconButton>
          ),
        });
      } catch (error) {
        console.error(error);
        notistack.closeSnackbar();
        notistack.enqueueSnackbar(
          'Error: No ha sido posible agregar el tipo de archivo. Intente de nuevo o contacte con Soporte.',
          {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            action: (key) => (
              <IconButton onClick={() => notistack.closeSnackbar(key)}>
                <Close />
              </IconButton>
            ),
          },
        );
      } finally {
        SetIsOpenTipoArchivo(false);
        SetArchivoRenovacion(null);
        helper.resetForm();
        mutate();
      }
    },
  });

  const fileChange = (name, e) => {
    formikArchivo.setFieldValue(name, e.target.files[0]);
    formikArchivo.setFieldTouched(name, true, false);
  };

  const dateChange = (name, e) => {
    formikArchivo.setFieldValue(name, e);
    formikArchivo.setFieldTouched(name, true, false);
  };

  const handleExitFormArchivo = () => {
    setOpenAgregarArchivo(false);
    SetArchivoRenovacion(null);
    formikArchivo.resetForm({
      values: {
        archivo: null,
        tipo: null,
        fecha: null,
        fecha_proxima_carga: null,
        _bien_ref: '',
        laboratorio_proveedor: '',
      },
    });
  };

  const handleExitFormTipoArchivo = () => {
    SetIsOpenTipoArchivo(false);
    formikTipoArchivo.resetForm();
  };

  const loadArchivoRenovacion = () => {
    formikArchivo.setFieldValue('_bien_ref', bien._id);

    if (ArchivoRenovacion) {
      //Si es un archivo para renovación.
      formikArchivo.resetForm({
        values: {
          _bien_ref: ArchivoRenovacion._bien_ref,
          archivo: null,
          tipo: Array.from(tiposDocumentos).find((td) => td.label === ArchivoRenovacion.tipo),
          fecha: null,
          fecha_proxima_carga: null,
        },
      });
    }
  };

  return (
    <Dialog open={openAgregarArchivo} maxWidth="lg" onClose={handleExitFormArchivo} onEntered={loadArchivoRenovacion}>
      {/* FORMULARIO PARA AGREGAR NUEVOS ARCHIVOS */}
      <div style={{ display: !IsOpenTipoArchivo ? 'unset' : 'none' }}>
        <DialogTitle>Adjuntar Documento</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6} style={{ display: 'flex' }}>
              {/* TIPO DE ARCHIVO */}
              <Autocomplete
                options={tiposDocumentos ? tiposDocumentos : []}
                getOptionLabel={(tipoDocumento) => tipoDocumento.label}
                value={formikArchivo.values.tipo}
                onChange={(event, value) => formikArchivo.setFieldValue('tipo', value)}
                loading={isValidating}
                disabled={Boolean(ArchivoRenovacion)}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    label="Tipo de Archivo"
                    variant="outlined"
                    error={
                      formikArchivo.touched.tipo && formikArchivo.errors.tipo && Boolean(formikArchivo.errors.tipo)
                    }
                    helperText={
                      formikArchivo.touched.tipo && formikArchivo.errors.tipo ? formikArchivo.errors.tipo : ''
                    }
                    {...params}
                  />
                )}
              />
              {/* ABRE POPUP PARA AGREGAR NUEVOS TIPOS DE ARCHIVOS */}
              {permisos && permisos[AGREGAR_TIPO_DOCS] && (
                <Tooltip title="Agregar Tipo de Archivo">
                  <Button
                    variant="contained"
                    style={{ margin: 5, boxShadow: 'none' }}
                    color="primary"
                    onClick={() => SetIsOpenTipoArchivo(true)}
                  >
                    <Add />
                  </Button>
                </Tooltip>
              )}
            </Grid>
            <Grid item xs={6}>
              {/* ARCHIVO */}
              <FileInput
                inputName="archivo"
                meta={{ touched: formikArchivo.touched.archivo, error: formikArchivo.errors.archivo }}
                buttonColor="primary"
                buttonVariant="outlined"
                label="Archivo"
                value={formikArchivo.values.archivo}
                handleOnChange={fileChange.bind(null, 'archivo')}
                textVariant="outlined"
                accept="image/x-png,image/gif,image/jpeg,application/pdf"
              />
            </Grid>
            <Grid item xs={6}>
              {/* FECHA DEL ARCHIVO */}
              <DatePicker
                name="fecha"
                label="Fecha del Documento"
                value={formikArchivo.values.fecha}
                onChange={dateChange.bind(null, 'fecha')}
                inputVariant="outlined"
                format="dd/MM/yyyy"
                cancelLabel="Cancelar"
                okLabel="Aceptar"
                fullWidth
                error={formikArchivo.touched.fecha && formikArchivo.errors.fecha && Boolean(formikArchivo.errors.fecha)}
                helperText={formikArchivo.touched.fecha && formikArchivo.errors.fecha ? formikArchivo.errors.fecha : ''}
              />
            </Grid>
            <Grid item xs={6}>
              {/* FECHA PARA RENOVAR EL ARCHIVO */}
              <DatePicker
                name="fecha_proxima_carga"
                label="Fecha de Renovación"
                value={formikArchivo.values.fecha_proxima_carga}
                onChange={dateChange.bind(null, 'fecha_proxima_carga')}
                inputVariant="outlined"
                format="dd/MM/yyyy"
                cancelLabel="Cancelar"
                okLabel="Aceptar"
                fullWidth
                error={
                  formikArchivo.touched.fecha_proxima_carga &&
                  formikArchivo.errors.fecha_proxima_carga &&
                  Boolean(formikArchivo.errors.fecha_proxima_carga)
                }
                helperText={
                  formikArchivo.touched.fecha_proxima_carga && formikArchivo.errors.fecha_proxima_carga
                    ? formikArchivo.errors.fecha_proxima_carga
                    : ''
                }
              />
            </Grid>
            {formikArchivo.values.tipo?.label === 'Permiso Circulación' && (
              <Grid item xs={6}>
                <CurrencyTextField
                  name={`valor_permiso_circulacion`}
                  label="Valor (Permiso Circulación)"
                  value={formikArchivo.values.valor_permiso_circulacion}
                  fullWidth
                  margin={'none'}
                  variant="outlined"
                  onChange={(event, value) => formikArchivo.setFieldValue(`valor_permiso_circulacion`, value)}
                  currencySymbol="$"
                  minimumValue="0"
                  maximumValue="1000000000"
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  leading="deny"
                  decimalPlaces={0}
                  decimalPlacesShownOnBlur={0}
                  decimalPlacesShownOnFocus={0}
                  error={
                    formikArchivo.touched.valor_permiso_circulacion &&
                    formikArchivo.errors.valor_permiso_circulacion &&
                    Boolean(formikArchivo.errors.valor_permiso_circulacion)
                  }
                  helperText={
                    formikArchivo.touched.valor_permiso_circulacion && formikArchivo.errors.valor_permiso_circulacion
                      ? formikArchivo.errors.valor_permiso_circulacion
                      : ''
                  }
                />
              </Grid>
            )}
            {formikArchivo.values.tipo?.label.includes('Seguro ') && (
              <Grid item xs={6}>
                <CurrencyTextField
                  name={`valor_prima_seguro`}
                  label="Valor Prima(Seguro)"
                  value={formikArchivo.values.valor_prima_seguro}
                  fullWidth
                  margin={'none'}
                  variant="outlined"
                  onChange={(event, value) => formikArchivo.setFieldValue(`valor_prima_seguro`, value)}
                  currencySymbol="$"
                  minimumValue="0"
                  maximumValue="1000000000"
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  leading="deny"
                  decimalPlaces={0}
                  decimalPlacesShownOnBlur={0}
                  decimalPlacesShownOnFocus={0}
                  error={
                    formikArchivo.touched.valor_prima_seguro &&
                    formikArchivo.errors.valor_prima_seguro &&
                    Boolean(formikArchivo.errors.valor_prima_seguro)
                  }
                  helperText={
                    formikArchivo.touched.valor_prima_seguro && formikArchivo.errors.valor_prima_seguro
                      ? formikArchivo.errors.valor_prima_seguro
                      : ''
                  }
                />
              </Grid>
            )}
            {formikArchivo.values.tipo?.label === 'Permiso Circulación' && (
              <Grid item xs={6}>
                <MaterialCreatable
                  name={`municipalidad`}
                  value={formikArchivo.values.municipalidad}
                  onChange={(newValue, actionMeta) => {
                    console.log(newValue);
                    return newValue
                      ? formikArchivo.setFieldValue(`municipalidad`, newValue)
                      : formikArchivo.setFieldValue(`municipalidad`, '');
                  }}
                  placeholder="Municipalidad"
                  options={municipalidades}
                  noOptionsMessage={() => 'No se encontro la municipalidad'}
                  margin="none"
                  isClearable
                  error={
                    formikArchivo.touched.municipalidad &&
                    formikArchivo.errors.municipalidad &&
                    Boolean(formikArchivo.errors.municipalidad)
                  }
                  helperText={
                    formikArchivo.touched.municipalidad && formikArchivo.errors.municipalidad
                      ? formikArchivo.errors.municipalidad
                      : ''
                  }
                />
              </Grid>
            )}
            {formikArchivo.values.tipo?.label.includes('Seguro ') && (
              <Grid item xs={6}>
                <MaterialCreatable
                  name={`aseguradora`}
                  value={formikArchivo.values.aseguradora}
                  onChange={(newValue, actionMeta) => {
                    console.log(newValue);
                    return newValue
                      ? formikArchivo.setFieldValue(`aseguradora`, newValue)
                      : formikArchivo.setFieldValue(`aseguradora`, '');
                  }}
                  placeholder="Aseguradora"
                  options={aseguradoras}
                  noOptionsMessage={() => 'No se encontro la empresa aseguradora'}
                  margin="none"
                  isClearable
                  error={
                    formikArchivo.touched.aseguradora &&
                    formikArchivo.errors.aseguradora &&
                    Boolean(formikArchivo.errors.aseguradora)
                  }
                  helperText={
                    formikArchivo.touched.aseguradora && formikArchivo.errors.aseguradora
                      ? formikArchivo.errors.aseguradora
                      : ''
                  }
                />
              </Grid>
            )}
            {formikArchivo.values.tipo?.label.includes('Seguro ') && (
              <Grid item xs={6}>
                <TextField
                  label="Numero de Poliza"
                  fullWidth
                  variant={'outlined'}
                  value={formikArchivo.values.poliza_seguro}
                  name="poliza_seguro"
                  onChange={(value) => formikArchivo.setFieldValue(`poliza_seguro`, value.target.value)}
                  error={
                    formikArchivo.touched.poliza_seguro &&
                    formikArchivo.errors.poliza_seguro &&
                    Boolean(formikArchivo.errors.poliza_seguro)
                  }
                  helperText={
                    formikArchivo.touched.poliza_seguro && formikArchivo.errors.poliza_seguro
                      ? formikArchivo.errors.poliza_seguro
                      : ''
                  }
                />
              </Grid>
            )}
            {formikArchivo.values.tipo?.label === 'Calibración' && (
              <Grid item xs={6}>
                <TextField
                  label="Laboratorio Proveedor"
                  fullWidth
                  variant={'outlined'}
                  value={formikArchivo.values.laboratorio_proveedor}
                  name="laboratorio_proveedor"
                  onChange={(value) => formikArchivo.setFieldValue(`laboratorio_proveedor`, value.target.value)}
                  error={
                    formikArchivo.touched.laboratorio_proveedor &&
                    formikArchivo.errors.laboratorio_proveedor &&
                    Boolean(formikArchivo.errors.laboratorio_proveedor)
                  }
                  helperText={
                    formikArchivo.touched.laboratorio_proveedor && formikArchivo.errors.laboratorio_proveedor
                      ? formikArchivo.errors.laboratorio_proveedor
                      : ''
                  }
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExitFormArchivo} variant="outlined" color="primary">
            Cerrar
          </Button>
          <Button onClick={formikArchivo.submitForm} variant="contained" color="primary">
            {ArchivoRenovacion ? 'Renovar' : 'Adjuntar'}
          </Button>
        </DialogActions>
      </div>

      {/* FORMULARIO PARA AGREGAR TIPO DE ARCHIVO */}
      <div style={{ display: IsOpenTipoArchivo ? 'unset' : 'none' }}>
        <DialogTitle>Agregar Tipo de Archivo</DialogTitle>
        <DialogContent>
          {/* NOMBRE DEL TIPO DE ARCHIVO */}
          <TextField
            name="nombre"
            label="Nombre"
            value={formikTipoArchivo.values.nombre}
            onChange={formikTipoArchivo.handleChange}
            error={
              formikTipoArchivo.touched.nombre &&
              formikTipoArchivo.errors.nombre &&
              Boolean(formikTipoArchivo.errors.nombre)
            }
            helperText={
              formikTipoArchivo.touched.nombre && formikTipoArchivo.errors.nombre ? formikTipoArchivo.errors.nombre : ''
            }
            variant="outlined"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExitFormTipoArchivo} variant="outlined" color="primary">
            Cerrar
          </Button>
          <Button onClick={formikTipoArchivo.submitForm} variant="contained" color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

/**
 * Método encargado de modificar un texto. Transforma a Lower Case, quita caracteres especiales y espacio.
 * @param {*} texto Texto original.
 * @returns Texto modificado.
 */
function ModificarTexto(texto) {
  let modificado = String(texto)
    .toLocaleLowerCase()
    .trim()
    .replace(/á/g, 'a')
    .replace(/é/g, 'e')
    .replace(/í/g, 'i')
    .replace(/ó/g, 'o')
    .replace(/ú/g, 'u')
    .replace(/ /g, '_');
  return modificado;
}
