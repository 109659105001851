import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Typography,
  Tooltip,
  FormHelperText,
  InputAdornment,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { MainContext } from '../../../App';
import { getMany, getByIdSDA } from '../../../services/request/requestGestorPagos';
import { getResponsableByID, getSociedades } from '../../../services/request/requestPortafolio';
import { getManySGU } from '../../../services/request/requestUsuarios';
import { IconButton } from '@material-ui/core';
import { Close, Add, Search } from '@material-ui/icons';
import moment from 'moment';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { DatePicker } from '@material-ui/pickers';
import FileInput from '../../../components/fileInput';
import MaterialReactSelect from '../../../components/materialReactSelect';
import useSWR from 'swr';
import DialogoBuscarOrdenCompra from '../dialogos/dialogoBuscarOrderCompra';
import DialogoCRUDCuentasBancosUsuarios from '../dialogos/dialogoCRUDCuentasBancosUsuarios';
import { IngresarFacturaContext } from '../dialogoRegistroMantenimiento';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  contenedorPrincipal: {
    padding: 5,
  },
  items: {
    marginBottom: theme.spacing(0.7),
  },
  itemsMantenimiento: {
    marginRight: theme.spacing(0.3),
    padding: theme.spacing(1),
    border: '1px solid grey',
  },
  formselect: {
    marginTop: -16,
  },
  text_disabled: {
    '& input.Mui-disabled': {
      background: 'rgb(242, 246, 246)',
      color: 'grey',
    },
  },
  input_required: {
    background: 'rgb(247, 249, 216)',
    color: 'black',
  },
  select_color: {
    background: 'rgb(247, 249, 216)',
    color: 'black',
  },
}));

const Tipos_Documentos = [
  { label: 'Boleta', value: 'Boleta' },
  { label: 'Factura', value: 'Factura' },
];
const Tipos_Rendicion = [
  { label: 'Pago directo a proveedor (Orden giro)', value: 'Pago directo a proveedor (Orden giro)' },
  { label: 'Rendición con depósito', value: 'Rendición con depósito' },
];

export const DatosFactura = (props) => {
  const { proveedores } = props;
  const { formik, siguienteAprobador, setSiguienteAprobador } = useContext(IngresarFacturaContext);
  const { usuarioSesion } = useContext(MainContext);
  const [tipoDocumento, setTipoDocumento] = useState('');
  const [gridFactura, setGridFactura] = useState(4);
  const [tituloBoletaFactura, setTituloBoletaFactura] = useState('Boleta');
  const [openDialogoCuentaDeBanco, setOpenDialogoCuentaDeBanco] = useState(false);
  const [openDialogoOrdenCompra, setOpenDialogoOrdenCompra] = useState(false);
  const [mostrarSelectorCuentaBanco, setMostrarSelectorCuentaBanco] = useState(false);
  const [placeholderOrdenCompra, setPlaceholderOrdenCompra] = useState('Seleccionar Orden de Compra');
  const [contratos, setContratos] = useState([]);

  const { data: responseCuentasBanco, mutate: refreshMisCuentasBancos } = useSWR(
    'get:mis-cuentas-bancos',
    () => getMany('/cuentas-bancos-usuarios', { _usuario_ref_eq: usuarioSesion.ref }),
    { initialData: null, revalidateOnFocus: false },
  );
  const { data: responseSociedades, error: errorSociedad } = useSWR('get:sociedad', () => getSociedades({}));
  const { data: responseContratos } = useSWR('get:contratos-usuario', () => getMany('/usuarios/contratos', {}), {
    initialData: null,
  });

  const classes = useStyles();
  const { values, touched, errors, setFieldValue, handleChange } = formik;

  function checkTipoRendicion(tipoRendicion) {
    setFieldValue('_caja_chica_ref', null);
    setFieldValue('_cuenta_banco_usuario_ref', null);
    setFieldValue('_fondo_rendir_ref', null);
    setFieldValue('_proveedor_ref', null);
    setFieldValue('monto_caja_chica', 0);
    setFieldValue('monto_fondo_rendir', 0);
    setFieldValue('caja_chica', '');
    setFieldValue('fondo_rendir', '');
    setFieldValue('cuenta_banco', '');

    setMostrarSelectorCuentaBanco(false);

    if (values.proveedor && tipoRendicion === 'Pago directo a proveedor (Orden giro)')
      setFieldValue('cuenta_banco_usuario', values.proveedor.cuenta_banco);
    let fecha_vencimiento = '';
    const dias_vencimiento = tipoRendicion === 'Pago directo a proveedor (Orden giro)' ? 30 : 0;
    if (values.fecha_documento) {
      fecha_vencimiento = moment(values.fecha_documento).add(dias_vencimiento, 'days').toDate();
    }
    setFieldValue('dias_vencimiento', dias_vencimiento);
    setFieldValue('fecha_vencimiento', fecha_vencimiento);

    switch (tipoRendicion) {
      case 'Rendición con depósito': {
        setMostrarSelectorCuentaBanco(true);
        break;
      }
      default: {
        break;
      }
    }
  }

  useEffect(() => {
    if (errorSociedad) console.log(errorSociedad);
  }, [errorSociedad]);

  async function cargarOrdenCompra(orden) {
    setPlaceholderOrdenCompra('Cargando...');
    if (orden) {
      console.log('Datos OC: ', orden);

      const responseCotizacion = await getByIdSDA('/cotizacion', orden.cotizacion_id);
      if (responseCotizacion) {
        const total = responseCotizacion.monto_total_pesos
          ? parseInt(Math.round(responseCotizacion.monto_total_pesos))
          : 0;
        // setFieldValue('monto_documento', orden.monto);
        setFieldValue('monto_documento', total);
        if (!values.es_exenta) {
          let neto = total ? total / 1.19 : 0;
          neto = parseInt(Math.round(neto));
          const iva = total - neto;
          setFieldValue('monto_neto', neto);
          setFieldValue('iva_documento', iva);
        }
        console.log('response Cotizacion SDA: ', responseCotizacion);

        if (contratos) {
          const solicitud_recurso = responseCotizacion.solicitud_recurso;
          const contrato_buscado = contratos.find((contrato) =>
            contrato.value.codigo.includes(solicitud_recurso.proyecto),
          );

          if (contrato_buscado) {
            onChangeContrato(contrato_buscado);
            //setFieldValue("contrato", contrato_buscado);
          }
        }

        const idAprobadorSDA = responseCotizacion.revisa_gaf_id
          ? responseCotizacion.revisa_gaf_id
          : responseCotizacion.aprobador_id
          ? responseCotizacion.aprobador_id
          : responseCotizacion.revisa_centro_id;
        const responseAprobadorSDA = await getByIdSDA('/usuario', idAprobadorSDA);
        setFieldValue('aprobador_sda', responseAprobadorSDA.nombres + ' ' + responseAprobadorSDA.apellidos);
        setFieldValue('id_usuario_aprobador_sda', idAprobadorSDA);
        const responseSolicitudSDA = await getByIdSDA('/solicitud-recurso', responseCotizacion.solicitud_recurso_id);
        setFieldValue('codigo_solicitud_recurso', responseSolicitudSDA.codigo);

        const responseProveedorSDA = await getByIdSDA('/proveedor', responseCotizacion.proveedor_id);
        if (responseProveedorSDA) {
          const proveedor = proveedores.find((a) => a.rut === responseProveedorSDA.rut);
          if (proveedor) {
            console.log('proveedor encontrado: ', proveedor);

            setFieldValue('proveedor_nombre', proveedor.nombre);
            setFieldValue('rut_proveedor', proveedor.rut);
            setFieldValue('proveedor', proveedor); //{

            if (values.tipo_rendicion === 'Pago directo a proveedor (Orden giro)')
              setFieldValue('cuenta_banco_usuario', proveedor.cuenta_banco);
          }
        }
      } else {
        setFieldValue('aprobador_sda', null);
        setFieldValue('codigo_solicitud_recurso', null);
        setFieldValue('id_usuario_aprobador_sda', null);
      }
      setFieldValue('codigo_orden_compra', orden.codigo);
      setFieldValue('id_orden_compra', orden.id);
    }
    setPlaceholderOrdenCompra('Seleccionar Orden de Compra');
  }

  function onChangeMontoFactura(event, value) {
    switch (event.target.name) {
      case 'es_exenta': {
        if (value) {
          setFieldValue('monto_neto', 0);
          setFieldValue('iva_documento', 0);
        } else {
          let neto = values.monto_documento ? values.monto_documento / 1.19 : 0;
          neto = parseInt(Math.round(neto));
          const iva = parseInt(values.monto_documento) - neto;
          setFieldValue('monto_neto', neto);
          setFieldValue('iva_documento', iva);
        }
        break;
      }
      case 'monto_neto': {
        if (value) {
          let iva = value ? value * 0.19 : 0;
          iva = parseInt(Math.round(iva));
          const total = parseInt(Math.round(iva + parseInt(value)));
          setFieldValue('iva_documento', iva);
          setFieldValue('monto_documento', total);
        } else {
          setFieldValue('monto_documento', '');
          setFieldValue('iva_documento', '');
        }
        break;
      }
      case 'iva_documento': {
        if (value) {
          let neto = value ? value * 0.81 : 0;
          neto = parseInt(Math.round(neto));
          const total = parseInt(Math.round(neto + parseInt(value)));
          setFieldValue('monto_neto', neto);
          setFieldValue('monto_documento', total);
        } else {
          setFieldValue('monto_neto', '');
          setFieldValue('monto_documento', '');
        }
        break;
      }
      case 'monto_documento': {
        if (value) {
          if (!values.es_exenta) {
            let neto = value ? value / 1.19 : 0;
            neto = parseInt(Math.round(neto));
            const iva = parseInt(value) - neto;
            setFieldValue('monto_neto', neto);
            setFieldValue('iva_documento', iva);
          }
          //setMontoPorAsignar(value - montoAsignado);
        } else {
          setFieldValue('monto_neto', '');
          setFieldValue('iva_documento', '');
          //setMontoPorAsignar(0);
        }

        break;
      }
      default:
        break;
    }

    setFieldValue(event.target.name, value);
  }

  // const fileChange = (name, e) => {
  //   setFieldValue(name, e.target.files[0]);
  //   setFieldTouched(name, true, false);
  // };

  function onChangeFechaFactura(e, value) {
    let fecha_vencimiento = '';

    if (e === 'fecha_documento') {
      if (value) {
        fecha_vencimiento = moment(value).add(values.dias_vencimiento, 'days').toDate();
      }
      setFieldValue('fecha_vencimiento', fecha_vencimiento);
    } else if (e.target.name === 'dias_vencimiento') {
      setFieldValue('dias_vencimiento', parseInt(e.target.value));
      if (values.fecha_documento) {
        fecha_vencimiento = moment(values.fecha_documento).add(e.target.value, 'days').toDate();
      }
      setFieldValue('fecha_vencimiento', fecha_vencimiento);
    }
  }

  const dateChange = (name, e) => {
    onChangeFechaFactura(name, e);
    setFieldValue(name, e);
  };

  const checkContratos = useCallback(
    function () {
      if (responseContratos && responseContratos.status) {
        const options = responseContratos.data.map((value, index) => ({
          value,
          label: value.codigo + ' - ' + value.nombre,
        }));
        setContratos(options);
        //Se carga predeterminadamente el contrato que tiene el vehiculo
        if (values?.codigo_contrato) {
          const contrato_buscado = options.find((contrato) => contrato.value.codigo.includes(values?.codigo_contrato));

          if (contrato_buscado) {
            onChangeContrato(contrato_buscado);
          }
        }
      } else {
        setContratos([]);
      }
    },
    [responseContratos, values.codigo_contrato],
  );

  useEffect(
    function () {
      checkContratos();
    },
    [checkContratos, values.codigo_contrato],
  );

  async function onChangeContrato(newValue, actionMeta) {
    setFieldValue('contrato', newValue);
    setSiguienteAprobador('Cargando...');
    if (newValue) {
      if (newValue.value && newValue.value.responsable_ref) {
        if (newValue.value.aprobadores_factura && newValue.value.aprobadores_factura.aprobadores) {
          const aprobador = newValue.value.aprobadores_factura.aprobadores[0];
          if (aprobador.nombre_completo) setSiguienteAprobador(aprobador.nombre_completo);
          else
            setSiguienteAprobador(
              aprobador.nombre +
                ' ' +
                aprobador.apellido_paterno +
                (aprobador.apellido_materno ? ' ' + aprobador.apellido_materno : ''),
            );
        } else {
          const responsableContrato = newValue.value.responsable_ref;
          const responseResponsableContrato = await getResponsableByID('responsables', responsableContrato);
          if (responseResponsableContrato) {
            const personaContrato = await getManySGU('/personas', {
              usuario_id: responseResponsableContrato.persona.usuario_id,
            });
            if (personaContrato && personaContrato.length > 0) {
              if (personaContrato[0].nombre_completo) setSiguienteAprobador(personaContrato[0].nombre_completo);
              else
                setSiguienteAprobador(
                  personaContrato[0].nombre +
                    ' ' +
                    personaContrato[0].apellido_paterno +
                    (personaContrato[0].apellido_materno ? ' ' + personaContrato[0].apellido_materno : ''),
                );
            }
          }
        }
      } else {
        setSiguienteAprobador('Juan José Ramos');
      }
    } else {
      setSiguienteAprobador('');
    }
  }

  function onChangeSociedad(newValue, actionMeta) {
    setFieldValue('sociedad_obj', newValue);
    //let exenta = false;
    if (newValue) {
      if (newValue.value.codigo === 'TAL' || newValue.value.codigo === 'ICOL' || newValue.value.codigo === 'CyD Tec') {
        setFieldValue('es_exenta', false);
        if (values.monto_documento || values.monto_documento === 0) {
          let neto = values.monto_documento ? values.monto_documento / 1.19 : 0;
          neto = parseInt(Math.round(neto));
          const iva = parseInt(values.monto_documento) - neto;
          setFieldValue('monto_neto', neto);
          setFieldValue('iva_documento', iva);
          //setMontoPorAsignar(values.monto_total - montoAsignado);
        }
      } else {
        //exenta = true;
        setFieldValue('es_exenta', true);
        setFieldValue('monto_neto', 0);
        setFieldValue('iva_documento', 0);
      }
    }
    //calcularMontosDivisiones(exenta);
  }

  useEffect(() => {
    // console.log("Valores: ", values);
    if (values) {
      if (values.tipo_documento === 'Boleta') {
        setTipoDocumento('Boleta');
        setTituloBoletaFactura('Boleta');
        setGridFactura(4);
        setFieldValue('tipo_documento', 'Boleta');
      } else {
        setTipoDocumento('Factura');
        setTituloBoletaFactura('Factura');
        setGridFactura(3);
        setFieldValue('tipo_documento', 'Factura');
        setFieldValue('tipo_rendicion', values.tipo_rendicion);
      }
    } else {
      setTipoDocumento('Boleta');
      setTituloBoletaFactura('Boleta');
      setGridFactura(4);
      setFieldValue('tipo_documento', 'Boleta');
    }
  }, []);

  return (
    <>
      <Grid container spacing={1} className={classes.contenedorPrincipal}>
        <Grid item xs={3}>
          <FormControl className={classes.formselect} fullWidth variant="outlined">
            <MaterialReactSelect
              value={Tipos_Documentos.find((d) => d.value === values.tipo_documento)} //values.tipo_documento}
              label="Tipo Documento"
              isClearable
              onChange={(newValue) => {
                setTipoDocumento(newValue.value);
                setFieldValue('tipo_documento', newValue ? newValue.value : '');
                if (newValue) {
                  if (newValue.label === 'Boleta') {
                    setTituloBoletaFactura('Boleta');
                    setGridFactura(3);
                  } else {
                    setTituloBoletaFactura('Factura');
                    setGridFactura(3);
                  }
                } else {
                  setTituloBoletaFactura('Boleta');
                  setGridFactura(3);
                }
              }}
              placeholder="Seleccionar Tipo Documento"
              options={
                Tipos_Documentos &&
                Tipos_Documentos.map((value, index) => ({
                  value: value.value,
                  label: value.label,
                }))
              }
              noOptionsMessage={() => 'No ha seleccionado el tipo de Documento'}
              error={touched.tipo_documento && errors.tipo_documento && Boolean(errors.tipo_documento)}
              helperText={touched.tipo_documento && errors.tipo_documento ? errors.tipo_documento : ''}
            />
          </FormControl>
        </Grid>
        {tipoDocumento === 'Factura' && (
          <Grid item xs={3}>
            <FormControl className={classes.formselect} fullWidth variant="outlined">
              <MaterialReactSelect
                value={Tipos_Rendicion.find((d) => d.value === values.tipo_rendicion)}
                label="Tipo de Rendición"
                isClearable
                onChange={(newValue) => {
                  setFieldValue('tipo_rendicion', newValue ? newValue.value : '');
                  checkTipoRendicion(newValue.value);
                }}
                placeholder="Seleccionar Tipo de Rendición"
                options={
                  Tipos_Rendicion &&
                  Tipos_Rendicion.map((value, index) => ({
                    value: value.value,
                    label: value.label,
                  }))
                }
                noOptionsMessage={() => 'No ha seleccionado el tipo de rendición'}
                error={touched.tipo_rendicion && errors.tipo_rendicion && Boolean(errors.tipo_rendicion)}
                helperText={touched.tipo_rendicion && errors.tipo_rendicion ? errors.tipo_rendicion : ''}
              />
            </FormControl>
          </Grid>
        )}

        {tipoDocumento === 'Factura' && (
          <Grid item xs={4}>
            <div style={{ display: 'flex' }}>
              <TextField
                variant="outlined"
                fullWidth
                label="Orden de Compra"
                InputLabelProps={{
                  shrink: true,
                }}
                value={values.codigo_orden_compra}
                placeholder={placeholderOrdenCompra}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setFieldValue('codigo_orden_compra', '');
                          setFieldValue('id_orden_compra', '');
                          setFieldValue('aprobador_sda', null);
                          setFieldValue('codigo_solicitud_recurso', null);
                          setFieldValue('id_usuario_aprobador_sda', null);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                disabled
              />
              <Tooltip title="Seleccionar Orden de Compra">
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => setOpenDialogoOrdenCompra(true)}
                  className={classes.btnSeleccionarOC}
                >
                  <Search style={{ fontSize: 40 }} />
                </Button>
              </Tooltip>
            </div>
          </Grid>
        )}

        {tipoDocumento === 'Factura' && mostrarSelectorCuentaBanco && (
          <Grid container spacing={1}>
            <Grid item xs={gridFactura}>
              <div style={{ display: 'flex' }}>
                <MaterialReactSelect
                  label="Cuenta de Banco"
                  isClearable
                  margin="none"
                  onChange={(newValue, actionMeta) => {
                    if (newValue) {
                      setFieldValue('cuenta_banco', newValue);
                      setFieldValue('_cuenta_banco_usuario_ref', newValue.value._id);
                      if (values.tipo_rendicion === 'Rendición con depósito')
                        setFieldValue('cuenta_banco_usuario', newValue.value);
                    } else {
                      setFieldValue('cuenta_banco', '');
                      setFieldValue('_cuenta_banco_usuario_ref', null);
                      if (values.tipo_rendicion === 'Rendición con depósito')
                        setFieldValue('cuenta_banco_usuario', null);
                    }
                  }}
                  placeholder="Seleccionar una Cuenta de Banco"
                  options={
                    responseCuentasBanco &&
                    responseCuentasBanco.status &&
                    responseCuentasBanco.data.map((value, index) => ({
                      value: {
                        _id: value._id,
                        descripcion: value.descripcion,
                        correo: value.correo,
                        banco: value.banco,
                        tipo_cuenta: value.tipo_cuenta,
                        numero_cuenta: value.numero_cuenta,
                        titular_cuenta: value.titular_cuenta,
                        rut_cuenta: value.rut_cuenta,
                      },
                      label: value.descripcion,
                    }))
                  }
                  noOptionsMessage={() => 'No ha encontrado la Cuenta de banco'}
                  error={touched.cuenta_banco && errors.cuenta_banco && Boolean(errors.cuenta_banco)}
                  // helperText={touched.cuenta_banco && errors.cuenta_banco ? errors.cuenta_banco : ""}
                />
                {/* {permisos && permisos[VER_MI_CONFIGURACION_PERMISO] &&  */}
                <Tooltip title="Agregar Cuenta de Banco">
                  <Button
                    size="small"
                    className={classes.btnSeleccionarOC}
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenDialogoCuentaDeBanco(true)}
                  >
                    <Add style={{ fontSize: 40 }} />
                  </Button>
                </Tooltip>
                {/* } */}
              </div>
              <FormHelperText error={touched.cuenta_banco && errors.cuenta_banco && Boolean(errors.cuenta_banco)}>
                {touched.cuenta_banco && errors.cuenta_banco ? errors.cuenta_banco : ''}
              </FormHelperText>
            </Grid>
            <Grid item xs={gridFactura}>
              <TextField
                name="banco"
                label="Banco"
                value={values.cuenta_banco ? values.cuenta_banco.value.banco : values.cuenta_banco}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
                disabled
                className={classes.text_disabled}
              />
            </Grid>
            <Grid item xs={gridFactura}>
              <TextField
                name="tipo_cuenta"
                label="Tipo de cuenta"
                value={values.cuenta_banco ? values.cuenta_banco.value.tipo_cuenta : values.cuenta_banco}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
                disabled
                className={classes.text_disabled}
              />
            </Grid>
            <Grid item xs={gridFactura}>
              <TextField
                name="numero_cuenta"
                label="N° de Cuenta"
                value={values.cuenta_banco ? values.cuenta_banco.value.numero_cuenta : values.cuenta_banco}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                disabled
                className={classes.text_disabled}
              />
            </Grid>
          </Grid>
        )}
        {tipoDocumento === 'Factura' && (
          <Grid container spacing={2}>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
              <MaterialReactSelect
                label="Contrato"
                isClearable
                margin="none"
                value={values.contrato}
                onChange={onChangeContrato}
                placeholder="Seleccionar un Contrato"
                error={touched.contrato && errors.contrato ? Boolean(errors.contrato) : false}
                helperText={touched.contrato && errors.contrato ? errors.contrato : ''}
                options={contratos}
                noOptionsMessage={() => 'No ha encontrado el contrato'}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
              <TextField
                label="Revisa/Aprueba"
                margin="none"
                variant="outlined"
                value={siguienteAprobador}
                InputLabelProps={{ shrink: true }}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <MaterialReactSelect
                label="Sociedad"
                isClearable
                margin="none"
                value={values.sociedad_obj}
                onChange={onChangeSociedad}
                placeholder="Seleccionar una Sociedad"
                error={touched.sociedad_obj && errors.sociedad_obj ? Boolean(errors.sociedad_obj) : false}
                helperText={touched.sociedad_obj && errors.sociedad_obj ? errors.sociedad_obj : ''}
                options={
                  responseSociedades
                    ? responseSociedades
                        .filter(
                          (sociedad) =>
                            sociedad.codigo !== 'CyD/PSI' && sociedad.codigo !== 'GPS/CYD' && sociedad.codigo !== 'CZS',
                        )
                        .map((value, index) => ({
                          value: {
                            _id: value._id,
                            codigo: value.codigo,
                            nombre: value.nombre,
                          },
                          label: value.codigo + ' - ' + value.nombre,
                        }))
                    : []
                }
                noOptionsMessage={() => 'No ha encontrado la sociedad'}
              />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={1}>
          <Grid item xs={gridFactura - 1}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                name="numero_documento"
                label={'Número ' + tituloBoletaFactura}
                value={values.numero_documento}
                onChange={handleChange}
                error={touched.numero_documento && errors.numero_documento && Boolean(errors.numero_documento)}
                helperText={touched.numero_documento && errors.numero_documento ? errors.numero_documento : ''}
                variant="outlined"
                fullWidth
                className={classes.input_required}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          {tipoDocumento === 'Factura' && (
            <Grid item xs={gridFactura - 1}>
              {/* FECHA DE LA FACTURA */}
              <DatePicker
                name="fecha_documento"
                label="Fecha del documento"
                value={values.fecha_documento}
                onChange={dateChange.bind(null, 'fecha_documento')}
                inputVariant="outlined"
                format="dd/MM/yyyy"
                maxDate={new Date()}
                cancelLabel="Cancelar"
                okLabel="Aceptar"
                autoOk
                fullWidth
                className={classes.input_required}
                error={touched.fecha_documento && errors.fecha_documento && Boolean(errors.fecha_documento)}
                helperText={touched.fecha_documento && errors.fecha_documento ? errors.fecha_documento : ''}
              />
            </Grid>
          )}

          <Grid item xs={gridFactura - 1}>
            {/* ARCHIVO */}
            <FileInput
              inputName="archivo"
              meta={{ touched: touched.archivo, error: errors.archivo }}
              buttonColor="primary"
              buttonVariant="outlined"
              label={'Seleccionar ' + tituloBoletaFactura}
              value={values.archivo}
              handleOnChange={(e) => setFieldValue('archivo', e.target.files[0])}
              textVariant="outlined"
              accept="image/x-png,image/gif,image/jpeg,application/pdf"
            />
          </Grid>
          <Grid item xs={gridFactura - 1}>
            <CurrencyTextField
              label={'Total ' + tituloBoletaFactura}
              name="monto_documento"
              value={values.monto_documento}
              onChange={onChangeMontoFactura}
              error={touched.monto_documento && errors.monto_documento && Boolean(errors.monto_documento)}
              helperText={touched.monto_documento && errors.monto_documento ? errors.monto_documento : ''}
              fullWidth
              variant="outlined"
              currencySymbol="$"
              outputFormat="string"
              decimalCharacter=","
              digitGroupSeparator="."
              leading="deny"
              decimalPlaces={0}
              decimalPlacesShownOnBlur={0}
              decimalPlacesShownOnFocus={0}
              className={classes.input_required}
            />
          </Grid>
          {tipoDocumento === 'Factura' && (
            <Grid item xs={gridFactura - 2}>
              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={values.es_exenta} onChange={onChangeMontoFactura} name="es_exenta" />}
                    label="Exenta"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          )}

          {tipoDocumento === 'Factura' && (
            <Grid item xs={gridFactura - 2}>
              <CurrencyTextField
                label="Monto Neto"
                name="monto_neto"
                value={values.monto_neto}
                onChange={onChangeMontoFactura}
                error={touched.monto_neto && errors.monto_neto && Boolean(errors.monto_neto)}
                helperText={touched.monto_neto && errors.monto_neto ? errors.monto_neto : ''}
                fullWidth
                variant="outlined"
                currencySymbol="$"
                outputFormat="string"
                decimalCharacter=","
                digitGroupSeparator="."
                leading="deny"
                decimalPlaces={0}
                decimalPlacesShownOnBlur={0}
                decimalPlacesShownOnFocus={0}
                disabled={values.es_exenta}
              />
            </Grid>
          )}
          {tipoDocumento === 'Factura' && (
            <Grid item xs={gridFactura - 2}>
              <CurrencyTextField
                label="IVA Factura"
                name="iva_documento"
                value={values.iva_documento}
                onChange={onChangeMontoFactura}
                error={touched.iva_documento && errors.iva_documento && Boolean(errors.iva_documento)}
                helperText={touched.iva_documento && errors.iva_documento ? errors.iva_documento : ''}
                fullWidth
                variant="outlined"
                currencySymbol="$"
                outputFormat="string"
                decimalCharacter=","
                digitGroupSeparator="."
                leading="deny"
                decimalPlaces={0}
                decimalPlacesShownOnBlur={0}
                decimalPlacesShownOnFocus={0}
                disabled={values.es_exenta}
              />
            </Grid>
          )}
          {tipoDocumento === 'Factura' && values.tipo_rendicion === 'Pago directo a proveedor (Orden giro)' && (
            <Grid item xs={gridFactura}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Vencimiento</FormLabel>
                <RadioGroup
                  row
                  aria-label="position"
                  name="dias_vencimiento"
                  defaultValue="top"
                  onChange={onChangeFechaFactura}
                  value={values.dias_vencimiento}
                >
                  <FormControlLabel value={0} control={<Radio />} label="Al contado" />
                  <FormControlLabel value={15} control={<Radio />} label="15 días" />
                  <FormControlLabel value={30} control={<Radio />} label="30 días" />
                  <FormControlLabel value={45} control={<Radio />} label="45 días" />
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
          {tipoDocumento === 'Factura' && (
            <Grid item xs={gridFactura}>
              <TextField
                name="descripcion_gasto"
                label="Descripción del Gasto"
                value={values.descripcion_gasto}
                onChange={handleChange}
                error={touched.descripcion_gasto && errors.descripcion_gasto && Boolean(errors.descripcion_gasto)}
                helperText={touched.descripcion_gasto && errors.descripcion_gasto ? errors.descripcion_gasto : ''}
                variant="outlined"
                fullWidth
                multiline
              />
            </Grid>
          )}

          <Grid item xs={gridFactura - 1}>
            <TextField
              name="orden_trabajo"
              label="Número Orden de Trabajo"
              value={values.orden_trabajo}
              onChange={handleChange}
              error={touched.orden_trabajo && errors.orden_trabajo && Boolean(errors.orden_trabajo)}
              helperText={touched.orden_trabajo && errors.orden_trabajo ? errors.orden_trabajo : ''}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={gridFactura - 1}>
            {/* ARCHIVO */}
            <FileInput
              inputName="archivo_orden_trabajo"
              meta={{ touched: touched.archivo_orden_trabajo, error: errors.archivo_orden_trabajo }}
              buttonColor="primary"
              buttonVariant="outlined"
              label={'Respaldo OT'}
              value={values.archivo_orden_trabajo}
              handleOnChange={(e) => setFieldValue('archivo_orden_trabajo', e.target.files[0])}
              textVariant="outlined"
              accept="image/x-png,image/gif,image/jpeg,application/pdf"
            />
          </Grid>
          <Grid item xs={gridFactura - 1}>
            <FormControl component="fieldset" margin="none">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.es_flujo_gaf}
                      onChange={async (e) => {
                        handleChange(e);
                        if (e.target.checked) {
                          setSiguienteAprobador('Juan Jóse Ramos');
                          //setFieldValue('es_flujo_gpo', false);
                        } else {
                          if (values.contrato) {
                            setSiguienteAprobador('Cargando...');
                            onChangeContrato(values.contrato);
                            // if (
                            //   values.contrato.value.aprobadores_factura &&
                            //   values.contrato.value.aprobadores_factura.aprobadores &&
                            //   filterAprobadores(values.contrato.value.aprobadores_factura.aprobadores).length > 0
                            // ) {
                            //   const aprobador = filterAprobadores(
                            //     values.contrato.value.aprobadores_factura.aprobadores,
                            //   )[0];
                            //   if (aprobador.nombre_completo) setSiguienteAprobador(aprobador.nombre_completo);
                            //   else
                            //     setSiguienteAprobador(
                            //       aprobador.nombre +
                            //         ' ' +
                            //         aprobador.apellido_paterno +
                            //         (aprobador.apellido_materno ? ' ' + aprobador.apellido_materno : ''),
                            //     );
                            // } else if (values.contrato.value?.responsable_ref) {
                            //   const responsableContrato = values.contrato.value.responsable_ref;
                            //   const responseResponsableContrato = await getByIdPortafolio(
                            //     'responsables',
                            //     responsableContrato,
                            //   );
                            //   if (responseResponsableContrato) {
                            //     const personaContrato = await getManySGU('/personas', {
                            //       usuario_id: responseResponsableContrato.persona.usuario_id,
                            //     });
                            //     if (personaContrato && personaContrato.length > 0) {
                            //       if (personaContrato[0].nombre_completo)
                            //         setSiguienteAprobador(personaContrato[0].nombre_completo);
                            //       else
                            //         setSiguienteAprobador(
                            //           personaContrato[0].nombre +
                            //             ' ' +
                            //             personaContrato[0].apellido_paterno +
                            //             (personaContrato[0].apellido_materno
                            //               ? ' ' + personaContrato[0].apellido_materno
                            //               : ''),
                            //         );
                            //     }
                            //   }
                            // }
                          } else {
                            setSiguienteAprobador('');
                          }
                        }
                      }}
                      name="es_flujo_gaf"
                    />
                  }
                  label="Enviar a Jefatura GAF"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        {tipoDocumento === 'Boleta' && (
          <Grid item xs={gridFactura}>
            <Typography>
              Nota: al seleccionar Boleta, no se registrará el pago automáticamente al Gestor de Pagos para su
              rendición. Deberá gestionar de manera tradicional con una rendición de Gastos.
            </Typography>
          </Grid>
        )}
      </Grid>

      <DialogoCRUDCuentasBancosUsuarios
        titulo="Nueva cuenta de banco"
        open={openDialogoCuentaDeBanco}
        close={() => setOpenDialogoCuentaDeBanco(false)}
        refreshTable={refreshMisCuentasBancos}
        disabledEditing={false}
      />

      <DialogoBuscarOrdenCompra
        open={openDialogoOrdenCompra}
        close={() => {
          setOpenDialogoOrdenCompra(false);
        }}
        handleSubmit={cargarOrdenCompra}
      />
    </>
  );
};
