import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { getTiposRecursos, getGruposContables } from '../../../services/request/requestTipoRecurso';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { TablaClasesContext } from './tablaClases';
import MaterialReactSelect from '../../../components/materialReactSelect';
import { getTarifas } from '../../../services/request/requestTarifas';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

function NumberField(props) {
  const { name, label, error, touched, value, onChange } = props;

  return (
    <CurrencyTextField
      name={name}
      label={label}
      value={value}
      fullWidth
      variant="outlined"
      margin={'normal'}
      onChange={onChange}
      error={touched && error ? true : false}
      helperText={touched && error ? error : ''}
      currencySymbol="$"
      minimumValue="0"
      maximumValue="1000000000"
      outputFormat="string"
      decimalCharacter=","
      digitGroupSeparator="."
      leading="deny"
      decimalPlaces={0}
      decimalPlacesShownOnBlur={0}
      decimalPlacesShownOnFocus={0}
    />
  );
}

const validationSchema = yup.object({
  nombre: yup.string().required(),
});

export default function DialogoCRUDClase() {
  const { openDialogoCRUD, setOpenDialogoCRUD, initialValues, handleAceptar, tituloDialogo, disableEditing } =
    useContext(TablaClasesContext);

  const formik = useFormik({
    initialValues,
    onSubmit: handleAceptar,
    validationSchema,
  });

  const handleClose = () => setOpenDialogoCRUD(false);
  const [tiposRecursos, setTiposRecursos] = useState([]);
  const [gruposContables, setGruposContables] = useState([]);
  const [tarifas, setTarifas] = useState([]);
  const { values, errors, touched, handleSubmit, handleChange, resetForm } = formik;

  function handleOpenDialog(initialValues) {
    resetForm({ values: initialValues });
    getTiposRecursos({}).then((response) => setTiposRecursos(response));
    getGruposContables({}).then((response) => setGruposContables(response.data));
    getTarifas({}).then((response) => setTarifas(response));
  }

  return (
    <Dialog open={openDialogoCRUD} onEntered={() => handleOpenDialog(initialValues)} maxWidth="md" fullWidth>
      <DialogTitle>{tituloDialogo}</DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                label="Nombre"
                name="nombre"
                value={values.nombre}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                disabled={disableEditing}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl component="fieldset">
                <FormLabel component="legend">Es:</FormLabel>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_computacional}
                        onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                        name="is_computacional"
                        color="primary"
                        disabled={disableEditing}
                      />
                    }
                    label="Equipo Computacional"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_vehiculo}
                        onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                        name="is_vehiculo"
                        color="primary"
                        disabled={disableEditing}
                      />
                    }
                    label="Vehículo"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_inmueble}
                        onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                        name="is_inmueble"
                        color="primary"
                        disabled={disableEditing}
                      />
                    }
                    label="Inmueble"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_topografico}
                        onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                        name="is_topografico"
                        color="primary"
                        disabled={disableEditing}
                      />
                    }
                    label="Eq. Topográfico"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_laboratorio}
                        onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                        name="is_laboratorio"
                        color="primary"
                        disabled={disableEditing}
                      />
                    }
                    label="Eq. Laboratorio"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_otro_instrumento}
                        onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                        name="is_otro_instrumento"
                        color="primary"
                        disabled={disableEditing}
                      />
                    }
                    label="Otro Instrumento"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <MaterialReactSelect
                value={values.tipo_recurso}
                label="Tipo Recurso"
                isClearable
                onChange={(newValue, actionMeta) =>
                  newValue ? formik.setFieldValue('tipo_recurso', newValue) : formik.setFieldValue('tipo_recurso', '')
                }
                placeholder="Seleccionar un Tipo de Recurso"
                options={
                  tiposRecursos
                    ? tiposRecursos.map((value, index) => ({
                        value: value._id,
                        label: value.nombre,
                      }))
                    : []
                }
                noOptionsMessage={() => 'No ha entrado el Tipo de Recurso'}
                error={touched.tipo_recurso && errors.tipo_recurso && Boolean(errors.tipo_recurso)}
                helperText={touched.tipo_recurso && errors.tipo_recurso ? errors.tipo_recurso : ''}
              />
            </Grid>
            <Grid item xs>
              <FormControl
                variant="outlined"
                margin="normal"
                fullWidth
                error={touched.tarifa && errors.tarifa && Boolean(errors.tarifa)}
              >
                <InputLabel>Tarifa</InputLabel>
                <Select name="tarifa" value={values.tarifa} onChange={handleChange}>
                  <MenuItem value="">Seleccionar</MenuItem>
                  {tarifas &&
                    tarifas.map((value, index) => (
                      <MenuItem value={value.nombre} key={index}>
                        {value.nombre}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{touched.tarifa && errors.tarifa ? errors.tarifa : ''}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs>
              <NumberField
                label="Valor Fijo (Tarifa de Vehículos)"
                name="valor_fijo_tarifa"
                onChange={(event, value) => formik.setFieldValue('valor_fijo_tarifa', value)}
                error={errors.valor_fijo_tarifa}
                touched={touched.valor_fijo_tarifa}
                value={values.valor_fijo_tarifa}
              />
            </Grid>
            <Grid item xs>
              <MaterialReactSelect
                value={values.grupo_contable}
                label="Grupo Contable"
                isClearable
                onChange={(newValue, actionMeta) =>
                  newValue
                    ? formik.setFieldValue('grupo_contable', newValue)
                    : formik.setFieldValue('grupo_contable', '')
                }
                placeholder="Seleccionar un Grupo Contable"
                options={
                  gruposContables
                    ? gruposContables.map((value, index) => ({
                        value: value._id,
                        label: value.nombre,
                      }))
                    : []
                }
                noOptionsMessage={() => 'No ha entrado el Grupo Contable'}
                error={touched.grupo_contable && errors.grupo_contable && Boolean(errors.grupo_contable)}
                helperText={touched.grupo_contable && errors.grupo_contable ? errors.grupo_contable : ''}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </form>
      F
    </Dialog>
  );
}
