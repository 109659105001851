import React, { useContext, useState } from 'react';
import { Container, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import { MainContext } from '../../../App';
import * as PERMISOS from '../../../constants/permissions';
import TablaMarcas from './tablaMarcas';
import TablaSeleccionables from './tablaSeleccionables';
import TablaClases from './tablaClases';
import {
  ObtenerColores,
  ObtenerTiposDocumentos,
  ObtenerCondicionesActuales,
} from '../../../services/request/requestSeleccionables';
import {
  AGREGAR_COLORES,
  EDITAR_COLORES,
  ELIMINAR_COLORES,
  AGREGAR_MARCAS,
  EDITAR_MARCAS,
  ELIMINAR_MARCAS,
  AGREGAR_TIPO_DOCS,
  EDITAR_TIPO_DOCS,
  ELIMINAR_TIPO_DOCS,
  AGREGAR_CONDICONES_ACTIVOS,
  EDITAR_CONDICONES_ACTIVOS,
  ELIMINAR_CONDICONES_ACTIVOS,
  AGREGAR_CLASES,
  EDITAR_CLASES,
  ELIMINAR_CLASES,
} from '../../../constants/permissions';
import TablaGruposContables from './tablaGruposContables';
import TablaTiposSeguros from './tablaTiposSeguros';
import TablaMunicipalidades from './tablaMunicipalidades';
import TablaListadoLicenciasSoftware from './tablaListadosGruposLicenciasSoftware';
import TablaUbicaciones from './tablaUbicaciones';
import TablaSociedades from './tablaSociedades';
import TablaAseguradoras from './tablaAseguradoras';

function Page(props) {
  const { permisos } = useContext(MainContext);
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <Container>
      <Paper>
        <Grid container>
          <Grid item lg>
            <Tabs
              value={selectedTab}
              onChange={(e, newValue) => setSelectedTab(newValue)}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable force tabs content-tabs"
            >
              <Tab
                label="Colores"
                id="tabColores"
                aria-controls="panelColores"
                hidden={!permisos[PERMISOS.VER_COLORES]}
              />
              <Tab
                label="Marcas/Modelos"
                id="tabMarcas"
                aria-controls="panelMarcas"
                hidden={!permisos[PERMISOS.VER_MARCAS]}
              />
              <Tab
                label="Tipos de Documentos"
                id="tabTiposDocumentos"
                aria-controls="panelTiposDocumentos"
                hidden={!permisos[PERMISOS.VER_TIPOS_DOCS]}
              />
              <Tab
                label="Condiciones de Activos"
                id="tabCondicionesActivos"
                aria-controls="panelCondicionesActivos"
                hidden={!permisos[PERMISOS.VER_CONDICIONES_ACTIVOS]}
              />
              <Tab
                label="Ubicaciones"
                id="tabUbicaciones"
                aria-controls="panelUbicaciones"
                hidden={!permisos[PERMISOS.AGREGAR_UBICACIONES]}
              />
              <Tab label="Clases" id="tabClases" aria-controls="panelClases" />
              <Tab label="Grupos Contables" id="tabGruposContables" aria-controls="panelGruposContables" />
              <Tab label="Sociedades de Terceros" id="tabSociedades" aria-controls="panelSociedades" />
              <Tab label="Tipos de Seguros" id="tabTiposSeguros" aria-controls="panelTipoSeguros" />
              <Tab label="Licencias de Software" id="tabLicenciasSoftware" aria-controls="panelLicenciasSoftware" />
              <Tab label="Municipalidades" id="tabMunicipalidades" aria-controls="panelMunicipalidades" />
              <Tab label="Aseguradoras" id="tabAseguradoras" aria-controls="panelAseguradoras" />
            </Tabs>
          </Grid>
        </Grid>
      </Paper>
      <br />
      <br />
      <Paper>
        {selectedTab === 0 && (
          <TablaSeleccionables
            titulo="Colores"
            getData={ObtenerColores}
            puedeAgregar={permisos && permisos[AGREGAR_COLORES]}
            puedeEditar={permisos && permisos[EDITAR_COLORES]}
            puedeEliminar={permisos && permisos[ELIMINAR_COLORES]}
          />
        )}
        {selectedTab === 1 && (
          <TablaMarcas
            puedeAgregar={permisos && permisos[AGREGAR_MARCAS]}
            puedeEditar={permisos && permisos[EDITAR_MARCAS]}
            puedeEliminar={permisos && permisos[ELIMINAR_MARCAS]}
          />
        )}
        {selectedTab === 2 && (
          <TablaSeleccionables
            titulo="Tipos de Documentos"
            getData={ObtenerTiposDocumentos}
            puedeAgregar={permisos && permisos[AGREGAR_TIPO_DOCS]}
            puedeEditar={permisos && permisos[EDITAR_TIPO_DOCS]}
            puedeEliminar={permisos && permisos[ELIMINAR_TIPO_DOCS]}
          />
        )}
        {selectedTab === 3 && (
          <TablaSeleccionables
            titulo="Condiciones de Activos"
            getData={ObtenerCondicionesActuales}
            puedeAgregar={permisos && permisos[AGREGAR_CONDICONES_ACTIVOS]}
            puedeEditar={permisos && permisos[EDITAR_CONDICONES_ACTIVOS]}
            puedeEliminar={permisos && permisos[ELIMINAR_CONDICONES_ACTIVOS]}
          />
        )}
        {selectedTab === 4 && <TablaUbicaciones />}
        {selectedTab === 5 && (
          <TablaClases
            titulo="Clases"
            puedeAgregar={permisos && permisos[AGREGAR_CLASES]}
            puedeEditar={permisos && permisos[EDITAR_CLASES]}
            puedeEliminar={permisos && permisos[ELIMINAR_CLASES]}
          />
        )}
        {selectedTab === 6 && <TablaGruposContables />}
        {selectedTab === 7 && <TablaSociedades />}
        {selectedTab === 8 && <TablaTiposSeguros />}
        {selectedTab === 9 && <TablaListadoLicenciasSoftware />}
        {selectedTab === 10 && <TablaMunicipalidades />}
        {selectedTab === 11 && <TablaAseguradoras />}
      </Paper>
    </Container>
  );
}

export default Page;
