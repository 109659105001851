import { Grid, TextField } from '@material-ui/core';
import React, { useContext } from 'react';
import { TablaUbicacionesContext } from '../tablaUbicaciones';

export const Ubicaciones = (props) => {
  const { formik } = props;
  const { disableEditing } = useContext(TablaUbicacionesContext);

  const { touched, errors, handleChange, values } = formik;

  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <TextField
          name="ubicacion"
          label="Ubicación"
          value={values.ubicacion}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          disabled={disableEditing}
          error={errors.ubicacion && touched.ubicacion ? true : false}
          helperText={errors.ubicacion && touched.ubicacion ? errors.ubicacion : ''}
        />
      </Grid>

      <Grid item xs>
        <TextField
          name="descripcion"
          label="Descripción"
          value={values.descripcion}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          disabled={disableEditing}
          error={errors.descripcion && touched.descripcion ? true : false}
          helperText={errors.descripcion && touched.descripcion ? errors.descripcion : ''}
        />
      </Grid>
    </Grid>
  );
};
