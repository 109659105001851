import React, { useContext, useState, useCallback, useEffect } from 'react';
import Page from './pageFormularioBasicoBienes';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { RegistroBienesContext } from '.';
import { getPropietarios } from '../../../services/request/requestPropietarios';
import { getGerencias, getContratos, getSociedades } from '../../../services/request/requestPortafolio';
import { ObtenerCondicionesActuales } from '../../../services/request/requestCondicionesActuales';
import useSWR from 'swr';
import { MainContext } from '../../../App';
import { getTiposRecursos } from '../../../services/request/requestTipoRecurso';
import { getClases } from '../../../services/request/requestClase';
import { getMarcas } from '../../../services/request/requestMarcas';
import { getUsuarioByUid } from '../../../services/request/requestUsuarios';
import { getModelos } from '../../../services/request/requestModelos';
import { getSeleccionables } from '../../../services/request/requestSeleccionables';
import { getUbicaciones } from '../../../services/request/requestUbicaciones';
import { getSociedadTerceros } from '../../../services/request/requestSociedades';
import { getBienes } from '../../../services/request/requestBienes';
import moment from 'moment';

const validationSchema = yup.object({
  clase: yup.mixed().required('Campo requerido'),
  condicion_actual: yup
    .string()
    .nullable()
    .when('modoMultiplesActivos', {
      is: false,
      then: yup.string().required('Campo requerido'),
    }),
  contrato: yup.mixed().nullable().required('Campo requerido'),
  _gerencia_ref: yup.string().required('Campo requerido'),
  _tipo_recurso_ref: yup.string().required('Campo requerido'),
  marca_obj: yup
    .mixed()
    .nullable()
    .when('modoMultiplesActivos', {
      is: false,
      then: yup.mixed().nullable().required('Campo requerido'),
    }),
  modelo_obj: yup
    .mixed()
    .nullable()
    .when('modoMultiplesActivos', {
      is: false,
      then: yup.mixed().nullable().required('Campo requerido'),
    }),
  sociedad: yup.mixed().required('Campo requerido'),
});

export default function FomularioBasicoBienes() {
  const {
    setFormularioBasico,
    setSelectedTab,
    setShowTabVehiculos,
    setShowTabComputacionales,
    setShowTabLabotorioTopografia,
    setFormularioComputacionales,
    setFormularioVehiculos,
    formularioBasico,
    clase,
    showTabVehiculos,
    showTabComputacionales,
    showTabLabotorioTopografia,
    modoMultiplesActivos,
    setModoMultiplesActivos,
    setClase,
  } = useContext(RegistroBienesContext);
  const { usuarioSesion } = useContext(MainContext);

  const [clases, setClases] = useState([]);
  const [contratos, setContratos] = useState([]);
  const [propietarios, setPropietarios] = useState([]);
  const [propietarioCyD, setPropietarioCyD] = useState(true);

  const { data: gerencias, error: errorGerencias } = useSWR(
    'get:gerencias',
    (key) => getGerencias({ is_eliminado: false }),
    {
      refreshInterval: 0,
    },
  );
  const { data: tiposRecursos, error: errorTiposRecursos } = useSWR(
    'get:tipos-recursos',
    (key) => getTiposRecursos({}),
    { refreshInterval: 0 },
  );
  const { data: marcas, error: errorMarcas } = useSWR('get:marcas', (key) => getMarcas({}), { refreshInterval: 0 });
  const { data: condicionesActuales, error: errorCondicionesActuales } = useSWR(
    'get:condicionesActuales',
    (key) => ObtenerCondicionesActuales(),
    { refreshInterval: 0 },
  );
  const { data: ubicaciones, error: errorUbicaciones } = useSWR('get:ubicaciones', (key) => getUbicaciones({}), {
    refreshInterval: 0,
  });
  const { data: terceros, error: errorTerceros } = useSWR('get:sociedades', (key) => getSociedadTerceros(), {
    refreshInterval: 0,
  });
  const { data: colores } = useSWR('get:colores', (key) => getSeleccionables({ context_eq: 'colores' }), {
    refreshInterval: 0,
  });
  const [sociedadesOptions, setSociedadesOptions] = useState([]);
  const [leasings, setLeasings] = useState([]);
  const [modelos, setModelos] = useState([]);
  const formik = useFormik({
    initialValues: {
      activar: false,
      clase: '',
      _clase_ref: null,
      _grupo_contable_ref: null,
      _vehiculo_ref: null,
      _equipo_ti_ref: null,
      _tarifa_ref: null,
      // codigo_activo: "",
      // codigo_contable: "",
      serial_number: '',
      condicion_actual: '',
      ubicacion: '',
      contrato: '',
      _contrato_ref: null,
      descripcion: '',
      _gerencia_ref: '',
      is_complemento: false,
      marca: '',
      marca_obj: '',
      modelo: '',
      _modelo_ref: null,
      modelo_obj: '',
      nombre: usuarioSesion.nombre,
      is_antiguo: false,
      propietario: null,
      _usuario_ref: usuarioSesion.ref,
      sociedad: '',
      _tipo_recurso_ref: '',
      _sociedad_ref: null,
      _sociedad_tercero_ref: null,
      contrato_leasing: '',
      _usuario_revisor_ref: null,
      usos: '',
      rango_medicion: '',
      grado_precision: '',
      periodo_recalibracion: '',
      tolerancias: '',
      accesorios: '',
      valor_promedio_venta: 0,
      valor_libro: 0,
      valor_seguro: 0,
      valor_permiso_circulacion: 0,
      valor_inscripcion: 0,
      valor_fijo: 0,
      modoMultiplesActivos: false,
      multiplesActivos: [
        {
          serial_number: '',
          marca_obj: '',
          modelo_obj: '',
          condicion_actual: '',
          ubicacion: '',
          file: null,
          descripcion: '',
          modelos: [],
          disco_duro: '',
          procesador: '',
          memoria_ram: '',
          pulgadas_pantalla: '',
          kilometraje: '',
          numero_motor: '',
          numero_chasis: '',
          color: '',
          ano: moment().startOf('year').set('month', 4).toDate(),
          tipo_combustible: '',
          transmision: '',
          cilindrada: '',
          patente: '',
          tag: '',
          barra_antivuelco: false,
          gps: false,
          mobileye: false,
          usos: '',
          rango_medicion: '',
          grado_precision: '',
          periodo_recalibracion: '',
          tolerancias: '',
          accesorios: '',
          errors: {},
        },
      ],
    },
    onSubmit: async (values) => {
      let faltanDatos = false;
      if (values.modoMultiplesActivos) {
        let modActivos = [...values.multiplesActivos];
        let fieldsCheck = showTabVehiculos
          ? [
              'condicion_actual',
              'kilometraje',
              'numero_motor',
              'numero_chasis',
              'color',
              'ano',
              'tipo_combustible',
              'transmision',
              'cilindrada',
            ]
          : ['condicion_actual'];
        for (let i = 0; i < modActivos.length; i++) {
          if (!modActivos[i].marca_obj?.value) {
            modActivos[i].errors.marca_obj = 'Campo requerido';
            faltanDatos = true;
          }
          if (!modActivos[i].modelo_obj?.value) {
            modActivos[i].errors.modelo_obj = 'Campo requerido';
            faltanDatos = true;
          }

          fieldsCheck.forEach((field) => {
            if (!modActivos[i][field]) {
              modActivos[i].errors[field] = 'Campo requerido';
              faltanDatos = true;
            }
          });
        }
      }
      if (!faltanDatos) {
        let tab = 4;
        if (values.contrato) {
          values._contrato_ref = values.contrato.value;
          if (values.contrato.contrato.responsable_admin_ref) {
            const usuarioResponsable = await getUsuarioByUid(
              values.contrato.contrato.responsable_admin_ref.persona.usuario_id,
            );
            if (usuarioResponsable) values._usuario_revisor_ref = usuarioResponsable._id;
            else values._usuario_revisor_ref = null;
          } else if (values.contrato.contrato.responsable_ref) {
            const usuarioResponsable = await getUsuarioByUid(
              values.contrato.contrato.responsable_ref.persona.usuario_id,
            );
            if (usuarioResponsable) values._usuario_revisor_ref = usuarioResponsable._id;
            else values._usuario_revisor_ref = null;
          } else {
            values._usuario_revisor_ref = null;
          }
        }
        if (values.sociedad) {
          if (values.sociedad && values.sociedad.label === 'Tercero') {
            values.is_propietario_cyd = false;
            values._sociedad_ref = null;
          } else {
            values.is_propietario_cyd = true;
            values._sociedad_ref = values.sociedad.value._id;
          }
        }
        values._clase_ref = values.clase.value._id;
        values._grupo_contable_ref = values.clase.value._grupo_contable_ref;
        if (values.marca_obj.value && values.marca_obj.value.nombre) values.marca = values.marca_obj.value.nombre;
        else values.marca = values.marca_obj.value;
        if (values.modelo_obj.value && values.modelo_obj.value.nombre) values.modelo = values.modelo_obj.value.nombre;
        else values.modelo = values.modelo_obj.value;

        if (!values.modoMultiplesActivos && clase && clase.is_vehiculo) {
          tab = 1;
        } else {
          setFormularioVehiculos(null);
        }
        if (!values.modoMultiplesActivos && clase && clase.is_computacional) {
          tab = 2;
        } else {
          setFormularioComputacionales(null);
        }
        if (
          !values.modoMultiplesActivos &&
          clase &&
          (clase.is_otro_instrumento || clase.is_topografico || clase.is_laboratorio)
        ) {
          tab = 3;
        }
        setSelectedTab(tab);
        setFormularioBasico(values);
      }
    },
    validationSchema,
  });

  const { resetForm, setFieldValue, values } = formik;

  const resetFormulario = useCallback(async () => {
    const propietarios = await getPropietarios();
    const sociedades = (await getSociedades()).map((value, index) => ({
      value,
      label: `${value.rut_formateado} - ${value.nombre}`,
    }));
    //Se obtienen los contratos de leasing registrados en los bienes y se eliminan los repetidos y genera un nuevo array con los datos
    const leasing_registrados = await getBienes({}, ['contrato_leasing'], []);
    const leasing = leasing_registrados.data.map((value) => (value.contrato_leasing ? value.contrato_leasing : ' '));
    const leasingArray = [...new Set(leasing)];
    setLeasings(leasingArray);

    setPropietarios(propietarios);
    sociedades.push({ label: 'Tercero', value: 'Tercero' });
    setSociedadesOptions(sociedades);

    if (formularioBasico) {
      Promise.all([
        getClases({ _tipo_recurso_ref_eq: formularioBasico._tipo_recurso_ref }),
        getContratos({ gerencia_ref: formularioBasico._gerencia_ref }),
      ])
        .then((responses) => {
          if (responses[0].status) {
            setClases(responses[0].data);
          } else {
            setClases([]);
          }
          if (responses[1].status === 200) {
            setContratos(responses[1].data);
          } else if (responses[1].status === 204) {
            setContratos([]);
          } else {
            setContratos([]);
          }
        })
        .catch((e) => {
          console.log(e);
          setClases([]);
          setContratos([]);
        });
      resetForm({ values: formularioBasico });
    } else resetForm();
  }, [resetForm, formularioBasico]);

  useEffect(() => {
    resetFormulario();
  }, [resetFormulario]);

  useEffect(() => {
    if (errorGerencias) console.log(errorGerencias);
  }, [errorGerencias]);

  useEffect(() => {
    if (errorTiposRecursos) console.log(errorTiposRecursos);
  }, [errorTiposRecursos]);

  useEffect(() => {
    if (errorMarcas) console.log(errorMarcas);
  }, [errorMarcas]);

  useEffect(() => {
    if (errorCondicionesActuales) console.log(errorCondicionesActuales);
  }, [errorCondicionesActuales]);

  useEffect(() => {
    if (errorUbicaciones) console.log(errorUbicaciones);
  }, [errorUbicaciones]);

  useEffect(() => {
    if (errorTerceros) console.log(errorTerceros);
  }, [errorTerceros]);

  const handleChangeTipoRecurso = (e) => {
    if (e.target.value) {
      getClases({ _tipo_recurso_ref_eq: e.target.value })
        .then((response) => {
          if (response.status) {
            setClases(response.data);
          } else {
            setClases([]);
          }
        })
        .catch((e) => {
          console.log(e);
          setClases([]);
        });
    } else {
      setClases([]);
    }
    setFieldValue('clase', '', false);
    setFieldValue('marca_obj', '', false);
    setFieldValue('codigo_contable', '', false);
  };

  const handleChangeClase = (e) => {
    if (e && e.value) {
      const clase = e.value;
      setClase(clase);
      setShowTabVehiculos(false);
      setShowTabComputacionales(false);
      setShowTabLabotorioTopografia(false);
      if (clase && clase.is_vehiculo) {
        setShowTabVehiculos(true);
      } else if (clase && clase.is_computacional) {
        setShowTabComputacionales(true);
      } else if (clase && (clase.is_otro_instrumento || clase.is_topografico || clase.is_laboratorio)) {
        setShowTabLabotorioTopografia(true);
      }
    } else {
      setClase(null);
      setFieldValue('codigo_contable', '', false);
    }
    if (modoMultiplesActivos)
      setFieldValue(
        'multiplesActivos',
        values.multiplesActivos.map((x) => ({ ...x, marca_obj: '' })),
      );
    setFieldValue('marca_obj', '', false);
  };

  const handleChangeGerencia = (e) => {
    if (e.target.value) {
      getContratos({ gerencia_ref: e.target.value })
        .then((response) => {
          if (response.status === 200) {
            setContratos(response.data);
          } else if (response.status === 204) {
            setContratos([]);
          } else {
            setContratos([]);
          }
        })
        .catch((e) => {
          console.log(e);
          setContratos([]);
        });
    } else {
      setContratos([]);
    }
    setFieldValue('contrato', '', false);
  };

  const handleChangeMarca = async (e) => {
    if (e && e.value && e.value._id) {
      const marca = e.value;
      const modelosMarca = await getModelos({ _marca_ref_eq: marca._id });
      setModelos(modelosMarca.data);
    } else {
      setModelos([]);
    }
    setFieldValue('modelo_obj', '', false);
  };

  const handleChangeMarcaMultiple = async (e, index) => {
    let modActivo = [...values.multiplesActivos];
    if (e && e.value && e.value._id) {
      const marca = e.value;
      const modelosMarca = await getModelos({ _marca_ref_eq: marca._id });
      modActivo[index].modelos = modelosMarca.data;
    } else {
      modActivo[index].modelos = [];
    }
    modActivo[index].marca_obj = e;
    modActivo[index].modelo_obj = '';
    modActivo[index].errors.marca_obj = false;
    modActivo[index].errors.modelo_obj = false;
    setFieldValue('multiplesActivos', modActivo);
  };

  const handleAgregarActivo = () => {
    let modActivo = [...values.multiplesActivos];
    modActivo.push({
      serial_number: '',
      marca_obj: '',
      modelo_obj: '',
      condicion_actual: '',
      ubicacion: '',
      file: null,
      descripcion: '',
      modelos: [],
      disco_duro: '',
      procesador: '',
      memoria_ram: '',
      pulgadas_pantalla: '',
      kilometraje: '',
      numero_motor: '',
      numero_chasis: '',
      color: '',
      ano: moment().startOf('year').set('month', 4).toDate(),
      tipo_combustible: '',
      transmision: '',
      cilindrada: '',
      patente: '',
      tag: '',
      barra_antivuelco: false,
      gps: false,
      mobileye: false,
      usos: '',
      rango_medicion: '',
      grado_precision: '',
      periodo_recalibracion: '',
      tolerancias: '',
      accesorios: '',
      errors: {},
    });
    setFieldValue('multiplesActivos', modActivo);
  };

  const handleCopiarActivo = () => {
    let modActivo = [...values.multiplesActivos];
    modActivo.push({
      ...modActivo[modActivo.length - 1],
      file: null,
      errors: {},
    });
    setFieldValue('multiplesActivos', modActivo);
  };

  const handleQuitarActivo = (index) => {
    let modActivo = [...values.multiplesActivos];
    modActivo.splice(index, 1);
    setFieldValue('multiplesActivos', modActivo);
  };

  return (
    <Page
      clase={clase}
      clases={clases}
      contratos={contratos}
      formik={formik}
      gerencias={gerencias}
      handleChangeClase={handleChangeClase}
      handleChangeGerencia={handleChangeGerencia}
      handleChangeMarca={handleChangeMarca}
      handleChangeTipoRecurso={handleChangeTipoRecurso}
      marcas={marcas}
      modelos={modelos}
      condiciones_actuales={condicionesActuales}
      ubicaciones={ubicaciones}
      terceros={terceros}
      leasings={leasings}
      propietarios={propietarios}
      propietarioCyD={propietarioCyD}
      setPropietarioCyD={setPropietarioCyD}
      sociedades={sociedadesOptions}
      tiposRecursos={tiposRecursos}
      modoMultiplesActivos={modoMultiplesActivos}
      setModoMultiplesActivos={setModoMultiplesActivos}
      handleChangeMarcaMultiple={handleChangeMarcaMultiple}
      handleAgregarActivo={handleAgregarActivo}
      handleCopiarActivo={handleCopiarActivo}
      handleQuitarActivo={handleQuitarActivo}
      showTabVehiculos={showTabVehiculos}
      showTabComputacionales={showTabComputacionales}
      showTabLabotorioTopografia={showTabLabotorioTopografia}
      colores={colores}
    />
  );
}
