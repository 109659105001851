import React, { createContext, useContext, useState } from 'react';
import MaterialTable from "../../components/materialTable";
import useSWR from 'swr';
import { deleteAsignacionLicenciaSoftware, postLogLicenciaSoftware, getAsignacionesLicenciasSoftware } from '../../services/request/requestLicenciasSoftware';
import { MainContext } from '../../App';
import { Fragment } from 'react';
import DialogoCRUDAsignacionLicenciaSoftware from './dialogoCRUDAsignacionLicenciaSoftware';
import { useHistory, useParams } from 'react-router';
import { DetalleLicenciaSoftwareContext } from '.';
import { VER_ACTIVO } from '../../constants/routes';
import { Button } from '@material-ui/core';

async function getDataAsignaciones(id) {
  const response = await getAsignacionesLicenciasSoftware({ _licencia_software_ref_eq: id },
    ["_licencia_software_ref"], [
    "_bien_ref.codigo_activo",
    "_gerencia_ref.nombre",
    "_contrato_ref.nombre",
    "_contrato_ref.codigo",
    "_contrato_ref.fecha_termino_estimada",
    "_usuario_ref.contacto",
    "_usuario_ref.run",
    "_usuario_ref.nombre",
    "_usuario_ref.apellido_paterno",
    "_usuario_ref.apellido_materno"
  ]);
  if (response.status) {
    return response.data.map(a => ({
      _id: a._id,
      _bien_ref: a._bien_ref ? a._bien_ref._id : null,
      codigo_activo: a._bien_ref ? a._bien_ref.codigo_activo : "--",
      usuario: a._usuario_ref ? a._usuario_ref.nombre + " " + a._usuario_ref.apellido_paterno + " " + a._usuario_ref.apellido_materno : "--",
      codigo_contrato: a._contrato_ref ? a._contrato_ref.codigo : "--",
      contrato: a._contrato_ref ? a._contrato_ref.nombre : "--",
      gerencia: a._gerencia_ref ? a._gerencia_ref.nombre : "--",
      mail_usuario: a._usuario_ref && a._usuario_ref.contacto ? a._usuario_ref.contacto.email : "--",
      rut_usuario: a._usuario_ref ? a._usuario_ref.run : "--",
      fecha_termino_contrato: (a._contrato_ref && a._contrato_ref.fecha_termino_estimada && a._contrato_ref.fecha_termino_estimada.split("T")[0] !== "2100-01-01") ? a._contrato_ref.fecha_termino_estimada : null,
      _licencia_software_ref: a._licencia_software_ref
    }));
  }
  return ([]);
}

export const TablaAsignacioneLicenciasSoftContext = createContext({
  refreshTable: () => { },
  openDialogo: false,
  closeDialogo: () => { },
  data: null,
});

export default function TablaAsignacioneLicenciasSoft() {

  const { id } = useParams();

  const history = useHistory();

  const { usuarioSesion } = useContext(MainContext);

  const { refreshDataLicencia } = useContext(DetalleLicenciaSoftwareContext);

  const { data, mutate } = useSWR('get:asignaciones-licencia', () => getDataAsignaciones(id), { initialData: null });
  const handleEliminar = async (rowData) => {
    if (rowData) await deleteAsignacionLicenciaSoftware(rowData._id);
    await postLogLicenciaSoftware({
      _usuario_ref: usuarioSesion.ref,
      _licencia_software_ref: rowData._licencia_software_ref,
      descripcion: `Desasignación de Licencia de ${rowData.codigo_activo}`
    });
    mutate();
    refreshDataLicencia();
  };

  const [openDialogo, setOpenDialog] = useState(false);

  const handleVerActivo = (rowData) => {
    history.push(VER_ACTIVO.replace(':id', rowData._bien_ref));
  }


  const actions = [
    {
      tooltip: "Agregar",
      icon: "add",
      isFreeAction: true,
      // hidden: permisos && permisos[ELIMINAR_LICENCIA_SOFTWARE] ? false: true,
      onClick: (event, row) => setOpenDialog(true),
    },
    {
      tooltip: "Eliminar",
      icon: "delete",
      // hidden: permisos && permisos[ELIMINAR_LICENCIA_SOFTWARE] ? false: true,
      onClick: (event, row) => handleEliminar(row),
    }
  ];

  const columns = [
    {
      title: "Activo",
      field: "codigo_activo",
      render: rowData => <Button size="small" variant="outlined" onClick={() => handleVerActivo(rowData)}>
        {rowData.codigo_activo}
      </Button >,
      cellStyle: {
        textAlign: 'center'
      }
    },
    {
      title: "Gerencia",
      field: "gerencia",
    },
    {
      title: "Código de Contrato",
      field: "codigo_contrato",
    },
    {
      title: "Contrato",
      field: "contrato",
    },
    {
      title: "Usuario del Activo",
      field: "usuario",
    },
    {
      title: "RUT del Usuario",
      field: "rut_usuario",
    },
    {
      title: "Email del Usuario",
      field: "mail_usuario",
    },
    {
      title: "Fecha Término Contrato",
      field: "fecha_termino_contrato",
      type: 'date'
    }
  ];

  return (
    <Fragment>
      <TablaAsignacioneLicenciasSoftContext.Provider
        value={{
          data,
          refreshTable: () => mutate(),
          openDialogo,
          closeDialogo: () => setOpenDialog(false)
        }}
      >
        <MaterialTable
          title="Licencias de Software"
          is_loading={data === null}
          columns={columns}
          data={data ? data : []}
          actions={actions}
          lockheaders={true}
        />
        <DialogoCRUDAsignacionLicenciaSoftware />
      </TablaAsignacioneLicenciasSoftContext.Provider>
    </Fragment>
  );
}