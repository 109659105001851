import React, { Fragment, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tab, Tabs, Paper, Typography, useMediaQuery, Container, Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ListadoVehiculosContext, MainContext } from '../../App';
import * as PERMISOS from '../../constants/permissions';
import VehiculosCyD from './vehiculosCyD';
import InformeResumen from './informeResumen';
import TablaMantencionesVehiculos from './tablaMantenimientosVehiculares';
import TablaRegistrosKM from './tablaRegistrosKM';
import TablaActasEntrega from './tablaActasEntrega';
import './style.css';
import GestionEquipamiento from './equipamiento/gestionEquipamiento';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Page() {
  const { permisos } = useContext(MainContext);
  const { vehiculos } = useContext(ListadoVehiculosContext);
  const classes = useStyles();
  const theme = useTheme();
  const smBreak = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Fragment>
      <Paper className={classes.paperTitle}>
        <Container>
          <Typography variant="h4" className={classes.title}>
            Gestión de Flota
          </Typography>
        </Container>
      </Paper>
      <Paper className={classes.paperFiltros}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <AppBar position="static">
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              aria-label="basic tabs"
              centered={!smBreak}
              variant={smBreak ? 'scrollable' : 'standard'}
              scrollButtons={smBreak ? 'on' : 'off'}
              classes={{ indicator: classes.indicator }}
            >
              <Tab label="Informe Resumen Vehículos" value={0} {...a11yProps(0)} />
              <Tab label="Listado Completo Vehículos" value={1} {...a11yProps(1)} />
              <Tab label="Registros de KM" value={2} {...a11yProps(2)} />
              {!permisos[PERMISOS.VER_LISTADO_MANTENCION_VEHICULO] ? (
                ''
              ) : (
                <Tab label="Registros de Mantenimiento" value={3} {...a11yProps(3)} />
              )}
              <Tab label="Equipamiento" value={4} {...a11yProps(4)} />
              {!permisos[PERMISOS.VER_LISTADO_ACTA_ENTREGA_VEHICULO] ? (
                ''
              ) : (
                <Tab label="Entrega/Recepción Vehículos" value={5} {...a11yProps(5)} />
              )}
              {!permisos[PERMISOS.VER_LISTADO_SINIESTROS_VEHICULO] ? (
                ''
              ) : (
                <Tab label="Registros de Siniestros" value={6} {...a11yProps(6)} />
              )}
            </Tabs>
          </AppBar>
        </Box>
        <TabPanel value={selectedTab} index={0}>
          <InformeResumen vehiculos={vehiculos} />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <VehiculosCyD vehiculos={vehiculos} />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <TablaRegistrosKM />
        </TabPanel>
        <TabPanel value={selectedTab} index={3} hidden={!permisos[PERMISOS.VER_LISTADO_MANTENCION_VEHICULO]}>
          <TablaMantencionesVehiculos />
        </TabPanel>
        <TabPanel value={selectedTab} index={4}>
          <GestionEquipamiento />
        </TabPanel>
        <TabPanel value={selectedTab} index={5} hidden={!permisos[PERMISOS.VER_LISTADO_ACTA_ENTREGA_VEHICULO]}>
          <TablaActasEntrega />
        </TabPanel>
        <TabPanel value={selectedTab} index={6} hidden={!permisos[PERMISOS.VER_LISTADO_SINIESTROS_VEHICULO]}></TabPanel>
      </Paper>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '95vw',
    padding: theme.spacing(0),
  },
  hidden: {
    display: 'none',
  },
  tab: {
    textTransform: 'none',
  },
  title: {
    padding: theme.spacing(1, 0, 1, 0),
    textAlign: 'center',
  },
  paperTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  paperFiltros: {
    padding: theme.spacing(2, 0, 2, 0),
    margin: theme.spacing(2, 0, 2, 0),
  },
  buttonsFiltros: {
    margin: theme.spacing(0, 1, 0, 1),
  },
  center: {
    textAlign: 'center',
  },
  indicator: {
    backgroundColor: 'blue',
  },
}));

export default Page;
