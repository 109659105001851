import React, { useState, useEffect, useContext } from 'react';
import Page from './page';
import { useParams } from 'react-router';
import { ObtenerContratos } from '../../../../services/request/requestPortafolio';
import { ObtenerBienes } from '../../../../services/request/requestBienes';
import { MainContext } from '../../../../App';
import { VER_SELECCION_GERENCIAS, VER_CONTRATOS_GERENCIA } from '../../../../constants/permissions';
import useGerenciasUser from '../../../../hooks/useGerenciasUser';

function Index(props) {
  const [Contratos, SetContratos] = useState();
  const { permisos, usuarioSesion, listadoContratosACargo } = useContext(MainContext);
  const { gerencias } = useGerenciasUser(false);

  const { id: gerenciaID } = useParams();

  useEffect(() => {
    Promise.all([ObtenerContratos(gerenciaID), ObtenerBienes(false)])
      .then((responses) => {
        const contratos = Array.from(responses[0]);
        const bienes = Array.from(responses[1].data);

        contratos.forEach((contrato, index_1) => {
          contratos[index_1]['cant_bienes'] = bienes.filter((b) => b._contrato_ref === contrato._id).length;
        });

        let contratosFiltrados = [];
        let contratosFiltradosUsuario = [];
        let contratosFiltradosGerenciaUsuario = [];

        if (!permisos[VER_SELECCION_GERENCIAS]) {
          if (!permisos[VER_CONTRATOS_GERENCIA]) {
            if (listadoContratosACargo && listadoContratosACargo.length > 0) {
              contratosFiltradosUsuario = contratos.filter((contrato) => {
                return listadoContratosACargo.some((contratoACargo) => {
                  return contratoACargo.codigo === contrato.codigo;
                });
              });

              if (gerencias && gerencias.length > 0) {
                contratosFiltradosGerenciaUsuario = contratos.filter((contrato) => {
                  return gerencias.some((gerencia) => gerencia._id === contrato.gerencia_ref._id);
                });
              }

              contratosFiltrados = [...contratosFiltradosUsuario, ...contratosFiltradosGerenciaUsuario];
            } else {
              contratosFiltradosUsuario = contratos.filter((contrato) =>
                contrato._id === usuarioSesion.contrato ? true : false,
              );

              if (gerencias && gerencias.length > 0) {
                contratosFiltradosGerenciaUsuario = contratos.filter((contrato) => {
                  return gerencias.some((gerencia) => gerencia._id === contrato.gerencia_ref._id);
                });
              }

              contratosFiltrados = [...contratosFiltradosUsuario, ...contratosFiltradosGerenciaUsuario];
            }
          } else {
            contratosFiltradosUsuario = contratos.filter((contrato) =>
              contrato.gerencia_ref._id === usuarioSesion.gerencia ? true : false,
            );

            if (gerencias && gerencias.length > 0) {
              contratosFiltradosGerenciaUsuario = contratos.filter((contrato) => {
                return gerencias.some((gerencia) => gerencia._id === contrato.gerencia_ref._id);
              });
            }

            contratosFiltrados = [...contratosFiltradosUsuario, ...contratosFiltradosGerenciaUsuario];
          }

          SetContratos(contratosFiltrados);
        } else {
          if (gerencias && gerencias.length > 0) {
            contratosFiltrados = contratos.filter((contrato) => {
              return gerencias.some((gerencia) => gerencia._id === contrato.gerencia_ref._id);
            });
          } else {
            SetContratos(contratos);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        SetContratos([]);
      });
  }, [gerenciaID, gerencias]);

  return <Page contratos={Contratos} />;
}

export default Index;
