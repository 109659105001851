import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState, useCallback, useEffect, useContext } from 'react';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { getRegistroKilometrajes, postRegistroKilometraje } from '../../services/request/requestBienes';
import { uploadFileToDrive, viewFileGCS } from '../../services/fileUpload';
import { GenerarLog } from '../../services/activoLog';
import { MainContext, ListadoVehiculosContext } from '../../App';
import FileInput from '../../components/fileInput';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import MaterialTable from '../../components/materialTable';
import { MTableAction } from '@material-table/core';
import Moment from 'moment';
import { InfoVehiculo } from './components/InfoVehiculo';

export default function DialogoActualizarKilometrajeActivo(props) {
  const { activo_seleccionado, is_open, handle_open } = props;

  const notistack = useSnackbar();
  const formik = useFormik({
    initialValues: {
      _vehiculo_ref: '',
      _bien_ref: null,
      kilometraje: 0,
      fecha_kilometraje: new Date(),
      patente: '',
      foto_tacometro: null,
      codigo_activo: '',
      color: '',
      marca: '',
      modelo: '',
      descripcion: '',
    },
    onSubmit: async (values) => {
      const key = notistack.enqueueSnackbar('Guardando cambios...', {
        persist: true,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
      });
      try {
        if (activo_seleccionado._vehiculo_ref) {
          const registroLog = {
            _bien_ref: activo_seleccionado._id,
            titulo: 'Actualización de Kilometraje',
            cuerpo: `El usuario ${usuarioSesion.nombre} actualizó el kilometraje del activo ${activo_seleccionado.codigo_activo} a ${values.kilometraje} Kms.`,
            tipo: 'actualizacion',
            icon: 'edit',
          };

          let uploadedFile = null;

          if (values.foto_tacometro) {
            uploadedFile = await uploadFileToDrive(
              values.foto_tacometro,
              `activos/${activo_seleccionado.codigo_activo}/${values.foto_tacometro.name}`,
            );
          }

          await Promise.all([
            postRegistroKilometraje({
              _vehiculo_ref: activo_seleccionado._vehiculo_ref._id,
              _bien_ref: values._bien_ref,
              kilometraje: values.kilometraje,
              fecha_kilometraje: values.fecha_kilometraje,
              descripcion: values.descripcion,
              foto_tacometro: uploadedFile,
            }),

            GenerarLog(registroLog),
          ]);

          if (vehiculos && vehiculos.length > 0) {
            console.log('dentro del if de vehiculos');
            const bienes = [...vehiculos];
            const bien = bienes.find((bien) => bien._id === activo_seleccionado._id);
            bien.kilometraje = values.kilometraje;
            setVehiculos(bienes);
          }

          handle_open(null, false);
        }
        notistack.closeSnackbar(key);
        const successkey = notistack.enqueueSnackbar('Operanción exitosa: Kilometraje actualizado', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
              <Close />
            </IconButton>
          ),
        });
      } catch (e) {
        console.log(e);
        notistack.closeSnackbar(key);
        const errorkey = notistack.enqueueSnackbar(
          'Error: No ha sido posible actualizar el kilometraje. Intente de nuevo o contacte con Soporte.',
          {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            action: (
              <IconButton onClick={() => notistack.closeSnackbar(errorkey)}>
                <Close />
              </IconButton>
            ),
          },
        );
      }
    },
  });

  const { usuarioSesion } = useContext(MainContext);
  const { vehiculos, setVehiculos } = useContext(ListadoVehiculosContext);
  const { handleSubmit, values, touched, errors, setFieldValue, resetForm } = formik;
  const [registrosKilometrajes, SetRegistrosKilometrajes] = useState([]);
  const [ultimoKM, setUltimoKM] = useState(0);

  //Permite obtener los kilometrajes registrados del vehiculo
  const getDataKilometrajes = (codigo_bien) => {
    if (codigo_bien && codigo_bien.length > 0) {
      return getRegistroKilometrajes(
        { _vehiculo_ref_eq: codigo_bien },
        ['_vehiculo_ref', '_bien_ref', 'foto_tacometro', 'kilometraje', 'fecha_kilometraje'],
        ['_vehiculo_ref.patente', '_bien_ref.codigo_activo', '_bien_ref.marca', '_bien_ref.modelo'],
      );
    } else return [];
  };

  const fetchData = useCallback(async () => {
    let ultimo_km = 0;

    if (activo_seleccionado && activo_seleccionado._vehiculo_ref?._id.length > 0) {
      const kilometrajes = await getDataKilometrajes(activo_seleccionado._vehiculo_ref._id);

      if (kilometrajes.data && kilometrajes.data.length > 0) {
        SetRegistrosKilometrajes(kilometrajes.data.reverse());
        ultimo_km = kilometrajes.data[0]?.kilometraje ? kilometrajes.data[0]?.kilometraje : 0;
      } else {
        ultimo_km = activo_seleccionado.kilometraje ? activo_seleccionado.kilometraje : 0;

        SetRegistrosKilometrajes([]);
      }
    } else {
      ultimo_km = 0;
      SetRegistrosKilometrajes([]);
    }
    setUltimoKM(ultimo_km);
    setFieldValue('Kilometraje', ultimo_km);
  }, [activo_seleccionado, ultimoKM]);

  useEffect(() => {
    fetchData().catch(console.error);

    if (ultimoKM) setFieldValue('Kilometraje', ultimoKM);
  }, [fetchData]);

  const handleClose = () => {
    handle_open(null, false);
  };

  const handleOnEntered = () => {
    const values = {
      _vehiculo_ref: activo_seleccionado && activo_seleccionado?._vehiculo_ref ? activo_seleccionado._vehiculo_ref : '',
      _bien_ref: activo_seleccionado
        ? activo_seleccionado?._bien_ref
          ? activo_seleccionado?._bien_ref
          : activo_seleccionado?._id
        : '',
      marca: activo_seleccionado
        ? activo_seleccionado?._bien_ref
          ? activo_seleccionado?._bien_ref.marca
          : activo_seleccionado?.marca
        : '',
      modelo: activo_seleccionado
        ? activo_seleccionado?._bien_ref
          ? activo_seleccionado?._bien_ref.modelo
          : activo_seleccionado?.modelo
        : '',
      patente: activo_seleccionado ? activo_seleccionado?._vehiculo_ref?.patente : '',
      codigo_activo: activo_seleccionado
        ? activo_seleccionado?._bien_ref
          ? activo_seleccionado?._bien_ref.codigo_activo
          : activo_seleccionado?.codigo_activo
        : '',
      foto_tacometro: null,
      kilometraje: activo_seleccionado ? (activo_seleccionado?.kilometraje ? activo_seleccionado?.kilometraje : 0) : 0,
      fecha_kilometraje: new Date(),
      descripcion: '',
    };
    resetForm({ values });
  };

  const handleVerArchivo = async (archivo) => {
    console.log('Archivo: ', archivo);
    const key = notistack.enqueueSnackbar('Descargando Archivo...', {
      persist: true,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    });
    try {
      const downloadUrl = archivo.url.replace(/ /g, '%20');
      viewFileGCS(downloadUrl, archivo.nombre);
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar('Descarga completada', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
            <Close />
          </IconButton>
        ),
      });
    } catch (e) {
      console.log(e);
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar(
        'Error en descarga. Intente de nuevo o contacte con el equipo de soporte.',
        {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
              <Close />
            </IconButton>
          ),
        },
      );
    }
  };

  const columns = [
    {
      title: 'Kilometraje',
      field: 'kilometraje',
      align: 'center',
      render: (row) => (row ? `${Intl.NumberFormat('es-ES').format(row.kilometraje)} Kms.` : ''),
    },
    {
      title: 'Fecha Registro',
      field: 'fecha_kilometraje',
      align: 'center',
      render: (row) => (row.fecha_kilometraje ? Moment(row.fecha_kilometraje).format('DD/MM/YYYY') : '--'),
    },
  ];

  const actions = [
    {
      icon: 'visibility',
      tooltip: 'Ver Imagen',
      onClick: (event, rowData) => handleVerArchivo(rowData.foto_tacometro),
    },
  ];

  function CheckButtonDisabled(kilometraje) {
    if (kilometraje <= ultimoKM) {
      return true;
    }
    if (!kilometraje) {
      return true;
    }
    if (kilometraje.length < 1) {
      return true;
    }
    return false;
  }

  return (
    <Dialog
      open={is_open}
      onClose={handleClose}
      TransitionProps={{
        onEntered: handleOnEntered,
      }}
    >
      <DialogTitle>Registrar Kilometraje</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={0}>
            <InfoVehiculo
              values={values}
              formik={formik}
              FullDetalle={false}
              TipoDeshabilitado={true}
              habilitaPatente={true}
            />
            <Grid item xs={6}>
              <FormControl>
                <CurrencyTextField
                  label="Ingrese Kilometraje actual"
                  value={ultimoKM >= 0 ? ultimoKM : values.kilometraje}
                  onChange={(event, value) => {
                    setFieldValue('kilometraje', value);
                    setFieldValue('fecha_kilometraje', new Date());
                  }}
                  error={touched.kilometraje && errors.kilometraje && Boolean(errors.kilometraje)}
                  helperText={touched.kilometraje && errors.kilometraje ? errors.kilometraje : ''}
                  fullWidth
                  variant="outlined"
                  margin={'normal'}
                  currencySymbol="Km"
                  minimumValue="0"
                  maximumValue="1000000000"
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  leading="deny"
                  decimalPlaces={0}
                  decimalPlacesShownOnBlur={0}
                  decimalPlacesShownOnFocus={0}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" margin="normal" fullWidth>
                <FileInput
                  inputName="file"
                  buttonColor="primary"
                  buttonVariant="outlined"
                  label="Foto Tacómetro"
                  value={values.foto_tacometro}
                  handleOnChange={(e) => setFieldValue('foto_tacometro', e.target.files[0])}
                  textVariant="outlined"
                  accept="image/x-png,image/gif,image/jpeg"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid>
            <MaterialTable
              title="Histórico de Kilometrajes"
              is_loading={!registrosKilometrajes}
              data={registrosKilometrajes}
              columns={columns}
              actions={actions}
              searching={false}
              columnsButton={false}
              grouping={false}
              filtering={false}
              exportButton={false}
              export_pdf={false}
              export_excel={false}
              options={{
                padding: 'dense',
                exportButton: false,
                exportAllData: false,
                search: false,
                grouping: false,
                filtering: false,
                actionsColumnIndex: 0,
                headerStyle: {
                  fontWeight: 'bold',
                  color: '#731f1f',
                  textAlign: 'center',
                },
              }}
              components={{
                Action: (propData) => {
                  const rowData = propData.data;
                  if (propData.action.icon === 'visibility') {
                    propData.action.hidden = !rowData.foto_tacometro;
                    propData.action.disabled = !rowData.foto_tacometro;
                  }
                  return <MTableAction {...propData} />;
                },
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={CheckButtonDisabled(values.kilometraje)}>
            Guardar Kilometraje
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
