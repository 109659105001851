import React, { createContext, useState } from 'react';
import MaterialTable from '../../../../components/materialTable';
import {
  ObtenerDetalleUbicacionesPorIDListado,
  putUbicacion,
  postDetalleUbicacion,
  putDetalleUbicacion,
  deleteDetalleUbicacion,
} from '../../../../services/request/requestUbicaciones';
import useSWR from 'swr';
import { DetalleUbicaciones } from './detalleUbicaciones';

export const DetalleUbicacionesContext = createContext({
  openDialogoCRUD: false,
  setOpenDialogoCRUD: () => {},
  tituloDialogo: '',
  setTituloDialogo: () => {},
  initialValues: '',
  setInitialValues: () => {},
  handleAceptar: () => {},
  disableEditing: false,
  setDisableEditing: () => {},
});

async function getData(listado) {
  if (!listado.length) return [];

  const detalles = await ObtenerDetalleUbicacionesPorIDListado('', listado);

  return detalles ? detalles : [];
}

export default function ListadoDetalleUbicaciones(props) {
  const { id_ubicacion, listado_detalles } = props;

  const { data: detalleubicaciones, mutate } = useSWR('get:detalle-ubicaciones', (key) => getData(listado_detalles));
  const [openDialogoCRUD, setOpenDialogoCRUD] = useState(false);
  const [tituloDialogo, setTituloDialogo] = useState('');
  const [initialValues, setInitialValues] = useState({
    resumen_detalle: '',
    nombre_abreviado: '',
    numero_bodega: '',
    rack: '',
    columna: '',
    fila: '',
  });
  const [disableEditing, setDisableEditing] = useState(false);

  const handleOpenAgregar = (titulo) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      resumen_detalle: '',
      nombre_abreviado: '',
      numero_bodega: '',
      rack: '',
      columna: '',
      fila: '',
    });
    setDisableEditing(false);
  };

  const handleOpenModificar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      resumen_detalle: '',
      nombre_abreviado: row.nombre_abreviado,
      numero_bodega: row.numero_bodega,
      rack: row.rack,
      columna: row.columna,
      fila: row.fila,
      id: row._id,
    });
    setDisableEditing(false);
  };

  const handleOpenQuitar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      resumen_detalle: row.resumen_detalle,
      nombre_abreviado: row.nombre_abreviado,
      numero_bodega: row.numero_bodega,
      rack: row.rack,
      columna: row.columna,
      fila: row.fila,
      id: row._id,
    });
    setDisableEditing(true);
  };

  const handleAceptar = async (values) => {
    if (tituloDialogo) {
      if (tituloDialogo.includes('Nuev')) {
        let detalle_asignar = [];

        if (listado_detalles.length > 0) {
          detalle_asignar = listado_detalles.map((detalle) => {
            const dato = { _id: detalle };
            return dato;
          });
        }

        const lista_rack = values.rack.split(/\s*,\s*/);
        const lista_columnas = values.columna.split(/\s*,\s*/);
        const lista_filas = values.fila.split(/\s*,\s*/);

        for (let i = 0; i < lista_rack.length; i++) {
          for (let j = 0; j < lista_columnas.length; j++) {
            for (let k = 0; k < lista_filas.length; k++) {
              const nuevoDetalle = await postDetalleUbicacion({
                nombre_abreviado: values.nombre_abreviado,
                numero_bodega: values.numero_bodega,
                rack: lista_rack[i],
                columna: lista_columnas[j],
                fila: lista_filas[k],
              });

              if (nuevoDetalle) {
                if (listado_detalles.length > 0) {
                  detalle_asignar.push({ _id: nuevoDetalle.data._id });

                  const actualizacion = await putUbicacion(id_ubicacion, {
                    _detalle_ubicacion_ref: detalle_asignar,
                  });
                  console.log('Actualiacion de detalle en ubicacion: ', actualizacion);
                } else {
                  await putUbicacion(id_ubicacion, { _detalle_ubicacion_ref: [{ _id: nuevoDetalle.data._id }] });
                  listado_detalles.push(nuevoDetalle.data._id);
                  detalle_asignar.push({ _id: nuevoDetalle.data._id });
                }
              }
            }
          }
        }
      } else if (tituloDialogo.includes('Modificar'))
        await putDetalleUbicacion(values.id, {
          nombre_abreviado: values.nombre_abreviado,
          numero_bodega: values.numero_bodega,
          rack: values.rack,
          columna: values.columna,
          fila: values.fila,
        });
      else if (tituloDialogo.includes('Eliminar')) await deleteDetalleUbicacion(values.id);
    }
    mutate();
    setOpenDialogoCRUD(false);
  };

  const columns = [
    {
      title: 'ID',
      field: '_id',
      align: 'center',
      hidden: true,
    },
    {
      title: 'Nombre Abreviado',
      field: 'nombre_abreviado',
      align: 'center',
    },
    {
      title: 'Nº Bodega',
      field: 'numero_bodega',
      align: 'center',
    },
    {
      title: 'Rack',
      field: 'rack',
      align: 'center',
    },
    {
      title: 'Columna',
      field: 'columna',
      align: 'center',
    },
    {
      title: 'Fila',
      field: 'fila',
      align: 'center',
    },
    {
      title: 'Resumen Detalle',
      field: 'resumen_detalle',
      align: 'center',
      defaultSort: 'asc',
    },
    {
      title: 'Fecha Creación',
      field: 'fecha_creacion',
      align: 'center',
      type: 'date',
      dateSetting: {
        format: 'dd/MM/yyyy',
      },
      hidden: true,
    },
  ];

  const actions = [
    {
      tooltip: 'Agregar Detalle Ubicación',
      icon: 'add',
      onClick: (event, row) => handleOpenAgregar('Nuevo Detalle Ubicación'),
      isFreeAction: true,
    },
    {
      tooltip: 'Editar',
      icon: 'edit',
      onClick: (event, row) => handleOpenModificar('Modificar Detalle Ubicación', row),
    },
    {
      tooltip: 'Eliminar',
      icon: 'delete',
      onClick: (event, row) => handleOpenQuitar('Eliminar Detalle Ubicación', row),
    },
  ];

  return (
    <>
      <DetalleUbicacionesContext.Provider
        value={{
          openDialogoCRUD,
          setOpenDialogoCRUD,
          tituloDialogo,
          setTituloDialogo,
          initialValues,
          setInitialValues,
          handleAceptar,
          disableEditing,
          setDisableEditing,
        }}
      >
        <MaterialTable
          actions={actions}
          title="Detalles de Ubicaciones Registrados"
          is_loading={!detalleubicaciones}
          data={detalleubicaciones}
          columns={columns}
          filtering={false}
          grouping={false}
          exportButtonPDF={true}
          exportButtonExcel={true}
          empty_rows={true}
          page_size={10}
        />
        <DetalleUbicaciones />
      </DetalleUbicacionesContext.Provider>
    </>
  );
}
