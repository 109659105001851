import React, { useContext } from "react";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormLabel, Grid, TextField } from "@material-ui/core";
import { UsuariosPlataformaContext } from './tabUsuariosPlataforma';
import useSWR from "swr";
import { getUsuarios } from "../../../services/request/requestUsuarios";
import { Autocomplete } from "@material-ui/lab";
import { ObtenerPerfiles } from "../../../services/request/requestPerfiles";

export default function DialogoAgregarUsuario() {
  const { id, usuario, setUsuario, isOpen, setIsOpen, tipoDialogo, setTipoDialogo, handleAceptar } = useContext(UsuariosPlataformaContext);
  const { data: usuarios, isValidating: isValidatingUsuarios } = useSWR("personas", (key) => getUsuarios({ }), { revalidateOnFocus: false });
  const { data: perfiles, isValidating: isValidatingPerfiles } = useSWR("perfiles", (key) => ObtenerPerfiles(), { revalidateOnFocus: false });

  const handleChange = (value) => {
    const datos = { ...usuario };
    datos["nombre"] = value.nombre_completo;
    datos["rut"] = value.run_formateado;
    datos["uid"] = value.usuario_id;
    setUsuario(datos);
  }

  const handleChangePerfiles = (values) => {
    const datos = { ...usuario };
    datos["_perfiles_ref"] = Array.from(values).map(v => ({ _id: v._id, nombre: v.nombre }));
    setUsuario(datos);
  }

  const handleChangeCheck = (event) => {
    const datos = { ...usuario };
    datos[event.target.name] = event.target.checked;
    setUsuario(datos);
  }

  const handleCancelar = () => {
    setUsuario({
      _id: "",
      nombre: "",
      rut: "",
      is_propietario: false,
      uid: "",
      is_ti: false,
      is_rff: false,
      is_contabilidad: false,
      is_gerente: false,
      is_responsable_gerencia: false,
      _perfiles_ref: [],
    });
    setIsOpen(false);
    setTipoDialogo("");
  }


  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth>
      <DialogTitle>
        {tipoDialogo === "actualizar" && "Actualizar Usuario"}
        {tipoDialogo === "agregar" && "Agregar Usuario"}
        {tipoDialogo === "eliminar" && "Eliminar Usuario"}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>Formulario con información del Usuario</DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              options={usuarios || []}
              value={usuario && usuarios ? usuarios.find(u => u.usuario_id === usuario.uid) : undefined}
              loading={isValidatingUsuarios}
              getOptionLabel={usuario => `${usuario.nombre_completo} (${usuario.contacto.email})`}
              onChange={(event, value) => {
                if (value) {
                  console.log(value);
                  handleChange(value);
                }
              }}
              size="medium"
              autoComplete
              loadingText="Cargando..."
              noOptionsText="Sin Datos"
              disabled={tipoDialogo === "actualizar" || tipoDialogo === "eliminar"}
              renderInput={(params) => (
                <TextField
                  label="Usuario (*)"
                  variant="outlined"
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={perfiles ? perfiles.data : []}
              // value={usuario && perfiles && perfiles.data && (tipoDialogo === "actualizar" || tipoDialogo === "eliminar") ? perfiles.data.filter(p => usuario._perfiles_ref.map(pu => pu._id).includes(p._id)) : []}
              value={usuario && usuario._perfiles_ref && usuario._perfiles_ref.length > 0 && perfiles && perfiles.data ? perfiles.data.filter(p => usuario._perfiles_ref.map(pu => pu._id).includes(p._id)) : []}
              loading={isValidatingPerfiles}
              getOptionLabel={perfil => perfil.nombre}
              onChange={(event, value) => {
                if (value) {
                  console.log(value);
                  handleChangePerfiles(value);
                }
              }}
              size="medium"
              autoComplete
              multiple
              loadingText="Cargando..."
              noOptionsText="Sin Datos"
              disabled={tipoDialogo === "eliminar"}
              renderInput={(params) => (
                <TextField
                  label="Perfiles (*)"
                  variant="outlined"
                  {...params}
                />
              )}
            />
          </Grid>
          
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelar} variant="outlined" color="primary">
          Cancelar
        </Button>
        <Button onClick={() => handleAceptar(usuario, id)} disabled={!usuario || !usuario.uid || !usuario._perfiles_ref || usuario._perfiles_ref.length === 0} variant="contained" color="primary">
          Aceptar
				</Button>
      </DialogActions>
    </Dialog>
  );
}