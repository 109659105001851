import React, { useContext, useState } from 'react';
//import useSWR from "swr";
//import { useFormik } from "formik";
//import * as yup from "yup";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
//import { Autocomplete } from "@material-ui/lab";
//import { getUsuarios } from "../../../services/request/requestUsuarios";
import { MainContext } from '../../../App';
import { ListadoActivosContext } from '.';
import { useSnackbar } from 'notistack';
import { Close as CloseIcon } from '@material-ui/icons';
import { GenerarLog } from '../../../services/activoLog';
import { putBien } from '../../../services/request/requestBienes';
import FileInput from '../../../components/fileInput';
import { uploadFileToCloudStorage } from '../../../services/fileUpload';
import { GAF_BODEGA_ID, GERENCIA_GAF_ID } from '../../../constants/gerencias_contratos';

function TablaSeleccionadosBajaMasiva(props) {
  const { is_open, handle_open, handle_close } = props;

  const [File, SetFile] = useState(null);
  const [Motivo, SetMotivo] = useState('');

  //const { data: usuarios, isValidating: isValidatingUsuarios } = useSWR("personas", (key) => getUsuarios({ is_gsuite_talana: true }), { revalidateOnFocus: false });
  // const { data: condiciones, isValidating: isValidatingCondiciones } = useSWR("condiciones_actuales", (key) => ObtenerCondicionesActuales(), { revalidateOnFocus: false });

  const { usuarioSesion } = useContext(MainContext);
  const { data, OpenSeleccionadosBaja, SetOpenSeleccionadosBaja, setData } = useContext(ListadoActivosContext);
  const classes = useStyles();
  const notistack = useSnackbar();

  const [selectedData, setSelectedData] = useState([]);

  /**
   * Método encargado de dar de baja los activos seleccionados
   */
  async function Baja() {
    try {
      for (let i = 0; i < selectedData.length; i++) {
        const seleccionado = selectedData[i];
        console.log(seleccionado);
        notistack.enqueueSnackbar('Dando de baja el activo...' + seleccionado.codigo_activo, {
          variant: 'info',
          persist: true,
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
        });

        let uploadedFile = null;

        if (File) {
          uploadedFile = await uploadFileToCloudStorage(File, `activos/${seleccionado.codigo_activo}`, File.name);
        }

        let datosActualizacion = {
          is_eliminado: true,
          fecha_baja: new Date(),
          motivo_baja: Motivo,
          adjunto_baja: uploadedFile,
          _asignado_ref: null,
          _gerencia_ref: GERENCIA_GAF_ID,
          _contrato_ref: GAF_BODEGA_ID,
          asignado_por: usuarioSesion.nombre,
        };
        let registroLogBaja = {
          _bien_ref: seleccionado._id,
          titulo: 'Baja Contable',
          cuerpo: `El usuario ${usuarioSesion.nombre} realizó la baja del activo ${seleccionado.codigo_activo}.`,
          tipo: 'solicitud_baja',
          icon: 'check_circle_outline',
        };
        let registroLogAsignacion = {
          _bien_ref: seleccionado._id,
          titulo: 'Asignación de Activo',
          cuerpo: `El activo ${seleccionado.codigo_activo} ha sido asignado a GAF-7 Bodega automáticamente por el sistema debido a la Baja Contable.`,
          tipo: 'asignacion',
          icon: 'check',
        };

        await putBien(seleccionado._id, datosActualizacion);
        await GenerarLog(registroLogBaja);
        await GenerarLog(registroLogAsignacion);

        notistack.closeSnackbar();

        let bienes = [...data];
        let bien = bienes.find((bien) => bien._id === seleccionado._id);
        bien.is_eliminado = true;
        bien.fecha_baja = new Date();
        bien.motivo_baja = Motivo;
        bien.adjunto_baja = uploadedFile;
        bien._asignado_ref = '--';
        bien.rut_usuario = '--';
        bien.mail_usuario = '--';
        bien._gerencia_ref = 'Gerencia de Administración y Finanzas';
        bien.gerencia_id = GERENCIA_GAF_ID;
        bien._contrato_ref = 'Bodega GAF';
        bien.codigo_contrato = 'GAF-7';
        bien.contrato_id = GAF_BODEGA_ID;
        setData(bienes);
      }

      SetOpenSeleccionadosBaja(false);

      notistack.enqueueSnackbar('Operación exitosa: Activos dados de baja y asignados a GAF-7 Bodega', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (key) => (
          <IconButton onClick={() => notistack.closeSnackbar(key)}>
            <CloseIcon />
          </IconButton>
        ),
      });
    } catch (e) {
      console.error(e);
      notistack.closeSnackbar();
      notistack.enqueueSnackbar(
        'Error al dar de baja los activos. Intente de nuevo o contacte con el equipo de soporte.',
        {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (key) => (
            <IconButton onClick={() => notistack.closeSnackbar(key)}>
              <CloseIcon />
            </IconButton>
          ),
        },
      );
    }
  }

  async function onEnter() {
    setSelectedData(data.filter((a) => a.checked));
  }

  /**
   * Método encargado de cerrar el popup y limpiar los datos.
   */
  const handleClose = () => {
    SetOpenSeleccionadosBaja(false);
    SetMotivo('');
    SetFile(null);
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={OpenSeleccionadosBaja}
      onClose={handleClose}
      TransitionProps={{
        onEnter: onEnter,
      }}
    >
      <DialogTitle>Formulario para Baja Masiva de Activos</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item lg={11} md={11} sm={11} xs={11}>
            <FormControl fullWidth>
              <FileInput
                inputName="file"
                buttonColor="primary"
                buttonVariant="outlined"
                label="Respaldo de baja"
                value={File}
                handleOnChange={(e) => SetFile(e.target.files[0])}
                textVariant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item lg={11} md={11} sm={11} xs={11}>
            <TextField
              name="motivo"
              label="Razón de baja"
              fullWidth
              variant={'outlined'}
              margin={'normal'}
              value={Motivo}
              rows={2}
              multiline={true}
              required
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => SetMotivo(e.target.value)}
              helperText="Requerido"
            />
          </Grid>
        </Grid>

        <Box m={1}>
          <Typography align="center" style={{ margin: 15 }}>
            Activos Seleccionados Para Baja Masiva
          </Typography>
          <div className={classes.div}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead style={{ backgroundColor: '#731f1f' }}>
                  <TableRow>
                    <TableCell style={{ width: '20%' }} align="center" className={classes.table_header}>
                      Código Activo
                    </TableCell>
                    <TableCell style={{ width: '20%' }} align="center" className={classes.table_header}>
                      Clase
                    </TableCell>
                    <TableCell style={{ width: '20%' }} align="center" className={classes.table_header}>
                      Condición
                    </TableCell>
                    <TableCell style={{ width: '20%' }} align="center" className={classes.table_header}>
                      Marca
                    </TableCell>
                    <TableCell style={{ width: '20%' }} align="center" className={classes.table_header}>
                      Modelo
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* SI NO HAY DATOS */}
                  {selectedData.length === 0 && (
                    <TableRow>
                      <TableCell align="center" colSpan={6}>
                        Sin Datos
                      </TableCell>
                    </TableRow>
                  )}

                  {/* SI HAY DATOS */}
                  {selectedData.map((row, index) => (
                    <TableRow key={`table_row_${index}`}>
                      <TableCell component="th" scope="row" align="center">
                        {row ? row.codigo_activo : '---'}
                      </TableCell>
                      <TableCell align="center">{row ? row._clase_ref : '---'}</TableCell>
                      <TableCell align="center">{row ? row.condicion_actual : '---'}</TableCell>
                      <TableCell align="center">{row ? row.marca : '---'}</TableCell>
                      <TableCell align="center">{row ? row.modelo : '---'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <Box display="flex" justifyContent="center" p={3}>
            <Button onClick={handle_close} className={classes.boton} variant="outlined" color="primary">
              {'Cerrar'}
            </Button>
            <Button
              onClick={() => Baja()}
              disabled={CheckButtonDisabled(Motivo, File)}
              className={classes.boton}
              variant="contained"
              color="primary"
            >
              {'Aceptar'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  popover: {
    maxHeight: '80%',
  },
  div: {
    minWidth: '100%',
  },
  table_header: {
    color: 'white',
  },
  boton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff',
  },
}));

/**
 * Método encargado de verificar si el botón está habilitado o deshabilitado.
 * @param {*} motivo Motivo para activar el bien.
 * @param {*} activo Datos del activo seleccionado.
 * @param {*} file Archivo de respaldo.
 * @returns FLAG que habilita o deshabilita el botón.
 */
function CheckButtonDisabled(motivo, file) {
  if (!file) {
    return true;
  }
  if (!motivo) {
    return true;
  }
  if (motivo.length < 3) {
    return true;
  }
  return false;
}

export default TablaSeleccionadosBajaMasiva;
