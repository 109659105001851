import React, { useState, Fragment, useCallback, useEffect, createContext, useContext } from 'react';
import { useHistory } from 'react-router';
import MaterialTable from '../../components/materialTable';
import { MTableAction } from '@material-table/core';
import { Button, Checkbox, IconButton } from '@material-ui/core';
import { getmantencionesVehiculos, deleteMantencionVehiculo } from '../../services/request/requestMantencionVehiculo';
import DialogoRegistroMantenimiento from './dialogoRegistroMantenimiento';
import DialogoEliminarMantencion from './dialogos/dialogoEliminarMantencion';
import useSWR from 'swr';
import Moment from 'moment';
import { MainContext, ListadoVehiculosContext } from '../../App';
import * as PERMISSIONS from '../../constants/permissions';
import { useProveedores } from '../../hooks/useProveedores';
import { Close } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

export const TablaMantencionesContext = createContext({
  openDialogoEliminar: false,
  setOpenDialogoEliminar: () => {},
  motivoEliminacion: '',
  setMotivoEliminacion: () => {},
  handleEliminar: () => {},
});

function getData() {
  return getmantencionesVehiculos(
    { is_eliminado_eq: false },
    [
      '_vehiculo_ref',
      '_bien_ref',
      'archivo',
      'orden_trabajo_file',
      'reporte',
      'folio',
      'estado',
      'fecha_creacion',
      'kilometraje',
      'fecha_ingreso',
      'fecha_entrega',
      'proveedor',
      'rut_proveedor',
      'otro_proveedor',
      'mantencion_programada',
      'tipo_documento',
      'tipo_rendicion',
      'numero_documento',
      'plazo_pago',
      'fecha_documento',
      'monto_documento',
      'monto_neto',
      'iva_documento',
      'descripcion_gasto',
      'orden_trabajo',
      'descripcion',
      'sistema_luces',
      'espejos',
      'llantas',
      'carroceria',
      'plumillas',
      'parabrisas',
      'neumaticos',
      'tablero',
      'calefaccion',
      'bocinas',
      'espejo_retrovisor',
      'cinturones',
      'radio',
      'air_bags',
      'gata',
      'kit_herramientas',
      'llantas_accesorios',
      'extintor',
      'triangulo_seguridad',
      'chaleco_reflectante',
      'radiador',
      'bateria',
      'filtros',
      'sistema_frenos',
      'correa_accesorios',
      'limpieza_dpf',
      'kit_embriague',
      'aceite_motor',
      'fluido_frenos',
      'refrigerante',
      'aceite_hidraulico',
      'otro',
      'otro_servicio',
      'is_eliminado',
      'motivo_eliminacion',
    ],
    [
      '_vehiculo_ref.patente',
      '_vehiculo_ref.tipo_combustible',
      '_bien_ref.codigo_activo',
      '_bien_ref.marca',
      '_bien_ref.modelo',
      '_vehiculo_ref.transmision',
      '_vehiculo_ref.color',
      '_bien_ref._id',
      '_ingresado_por_ref.nombre',
      '_ingresado_por_ref.apellido_paterno',
      '_ingresado_por_ref.contacto',
      '_ingresado_por_ref.run',
    ],
  );
}

function TablaMantenimientosVehiculares() {
  const { permisos } = useContext(MainContext);
  const { vehiculos } = useContext(ListadoVehiculosContext);
  const [activoSeleccionado, setActivoSeleccionado] = useState(null);
  const [OpenRegistroMantenimiento, setOpenRegistroMantenimiento] = useState(false);
  const [openDialogoEliminar, setOpenDialogoEliminar] = useState(false);
  const [datosMantenciones, setDatosMantenciones] = useState([]);
  const [motivoEliminacion, setMotivoEliminacion] = useState('');
  const { data: response, mutate: mutateMantenciones } = useSWR('mantenciones-vehiculos', (key) => getData());
  const { proveedores } = useProveedores();

  const history = useHistory();
  const notistack = useSnackbar();

  const loadData = useCallback(() => {
    let vehiculos_mantenidos = [];
    setDatosMantenciones([]);

    if (response) {
      if (response.status) {
        const mantenciones = response.data.map((mantencion) => {
          const Exteriores =
            (mantencion.sistema_luces ? 'Sist. Luces, ' : '') +
            (mantencion.espejos ? 'Espejos, ' : '') +
            (mantencion.llantas ? 'Llantas, ' : '') +
            (mantencion.carroceria ? 'Carroceria, ' : '') +
            (mantencion.plumillas ? 'Plumillas, ' : '') +
            (mantencion.parabrisas ? 'Parabrisas, ' : '') +
            (mantencion.neumaticos ? 'Neumáticos ' : '');
          const interiores =
            (mantencion.Tablero ? 'Tablero, ' : '') +
            (mantencion.calefaccion ? 'Calefacción, ' : '') +
            (mantencion.bocinas ? 'Bocinas, ' : '') +
            (mantencion.espejo_retrovisor ? 'Espejo Retrovisor, ' : '') +
            (mantencion.cinturones ? 'Cinturones, ' : '') +
            (mantencion.radio ? 'Radio, ' : '') +
            (mantencion.air_bags ? 'Air Bags ' : '');
          const accesorios =
            (mantencion.gata ? 'Gata, ' : '') +
            (mantencion.kit_herramientas ? 'Kit Herramientas, ' : '') +
            (mantencion.llantas_accesorios ? 'Llantas, ' : '') +
            (mantencion.extintor ? 'Extintor, ' : '') +
            (mantencion.triangulo_seguridad ? 'Triángulo, ' : '') +
            (mantencion.chaleco_reflectante ? 'Chaleco Reflectante ' : '');
          const componentes_mecanicos =
            (mantencion.radiador ? 'Radiador, ' : '') +
            (mantencion.bateria ? 'Batería, ' : '') +
            (mantencion.filtros ? 'Filtros, ' : '') +
            (mantencion.sistema_frenos ? 'Sist. Frenos, ' : '') +
            (mantencion.correa_accesorios ? 'Correa Accesorios, ' : '') +
            (mantencion.limpieza_dpf ? 'Limpieza DPF, ' : '') +
            (mantencion.kit_embriague ? 'Kit Embrague ' : '');
          const fluidos =
            (mantencion.aceite_motor ? 'Aceite Motor, ' : '') +
            (mantencion.fluido_frenos ? 'Frenos, ' : '') +
            (mantencion.refrigerante ? 'Refrigerante, ' : '') +
            (mantencion.aceite_hidraulico ? 'Aceite Hidráulico ' : '');

          return {
            ...mantencion,
            exteriores: Exteriores,
            interiores,
            accesorios,
            componentes_mecanicos,
            fluidos,
            registrado_por:
              mantencion._ingresado_por_ref && mantencion._ingresado_por_ref.nombre
                ? mantencion._ingresado_por_ref.nombre + ' ' + mantencion._ingresado_por_ref.apellido_paterno
                : '--',
          };
        });

        vehiculos_mantenidos = vehiculos.map((vehiculo) => {
          const encontrado_ultimo = mantenciones.filter(
            (mantencion) => mantencion._vehiculo_ref.patente === vehiculo.patente,
          );
          encontrado_ultimo.sort((a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion));

          return {
            ...vehiculo,
            mantenimiento: encontrado_ultimo ? encontrado_ultimo[0] : null,
          };
        });

        setDatosMantenciones(vehiculos_mantenidos);
      }
    }
  }, [response]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  function handleOpenRegistroMantenimiento(seleccionado, open) {
    setActivoSeleccionado(seleccionado);
    setOpenRegistroMantenimiento(open);
  }

  const handleOpenEliminar = (seleccionado) => {
    setActivoSeleccionado(seleccionado);
    setOpenDialogoEliminar(true);
  };

  const handleEliminar = async (values) => {
    try {
      const valorID = values.mantenimiento._id;

      await deleteMantencionVehiculo(valorID, { is_eliminado: true, motivo_eliminacion: motivoEliminacion });

      const successkey = notistack.enqueueSnackbar('Mantenimiento eliminado correctamente.', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
            <Close />
          </IconButton>
        ),
      });

      mutateMantenciones();
      setMotivoEliminacion('');

      setOpenDialogoEliminar(false);
    } catch (error) {
      console.error(error);
      const errorkey = notistack.enqueueSnackbar('Error al eliminar el registro de Mantenimiento.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar(errorkey)}>
            <Close />
          </IconButton>
        ),
      });
    }
  };

  const columns = [
    {
      title: 'Patente',
      field: 'patente',
      align: 'center',
    },
    {
      title: 'Código Activo',
      field: 'codigo_activo',
      align: 'center',
    },
    {
      title: 'Marca',
      field: 'marca',
      align: 'center',
    },
    {
      title: 'Modelo',
      field: 'modelo',
      align: 'center',
    },
    {
      title: 'Transmisión',
      field: 'transmision',
      align: 'center',
      hidden: true,
    },
    {
      title: 'Kilometraje',
      field: 'kilometraje',
      align: 'center',
      render: (row) => (row ? `${Intl.NumberFormat('es-ES').format(row.kilometraje)} Kms.` : ''),
    },
    {
      title: 'Tipo Mantención realizada',
      field: 'mantenimiento.mantencion_programada',
      align: 'center',
    },
    {
      title: 'Kilometraje Ultima Mantención',
      field: 'mantenimiento.kilometraje',
      align: 'center',
      render: (row) =>
        row.mantenimiento?.kilometraje
          ? `${Intl.NumberFormat('es-ES').format(row.mantenimiento?.kilometraje)} Kms.`
          : '',
    },
    {
      title: 'Folio',
      field: 'mantenimiento.folio',
      align: 'center',
      defaultSort: 'desc',
    },
    {
      title: 'Fecha Registro Mantención',
      field: 'mantenimiento.fecha_creacion',
      align: 'center',
      render: (row) =>
        row.mantenimiento?.fecha_creacion ? Moment(row.mantenimiento?.fecha_creacion).format('DD/MM/YYYY') : '--',
      hidden: true,
    },
    {
      title: 'Ingresado por',
      field: 'mantenimiento.registrado_por',
      align: 'center',
    },
    {
      title: 'Estado Pago Documento',
      field: 'mantenimiento.estado',
      align: 'center',
    },
    {
      title: 'Proveedor',
      field: 'mantenimiento.proveedor',
      align: 'center',
    },
    {
      title: 'Rut Proveedor',
      field: 'mantenimiento.rut_proveedor',
      align: 'center',
    },
    {
      title: 'Tipo Documento',
      field: 'mantenimiento.tipo_documento',
      align: 'center',
    },
    {
      title: 'Numero Documento',
      field: 'mantenimiento.numero_documento',
      align: 'center',
    },
    {
      title: 'Plazo Pago',
      field: 'mantenimiento.plazo_pago',
      align: 'center',
    },
    {
      title: 'Fecha Documento',
      field: 'mantenimiento.fecha_documento',
      align: 'center',
      render: (row) =>
        row.mantenimiento?.fecha_documento ? Moment(row.mantenimiento?.fecha_documento).format('DD/MM/YYYY') : '--',
    },
    {
      title: 'Monto Total',
      field: 'mantenimiento.monto_documento',
      align: 'center',
      render: (row) =>
        row.mantenimiento?.monto_documento
          ? `$${Intl.NumberFormat('es-ES').format(row.mantenimiento?.monto_documento)}`
          : '',
    },
    {
      title: 'Descripción',
      field: 'mantenimiento.descripcion',
      align: 'center',
    },
    {
      title: 'Mantención Exterior',
      field: 'mantenimiento.exteriores',
      align: 'center',
    },
    {
      title: 'Mantención Interior',
      field: 'mantenimiento.interiores',
      align: 'center',
    },
    {
      title: 'Mantenimiento Accesorios',
      field: 'mantenimiento.accesorios',
      align: 'center',
    },
    {
      title: 'Componentes Mecánicos',
      field: 'mantenimiento.componentes_mecanicos',
      align: 'center',
    },
    {
      title: 'Mantenimiento Fluidos',
      field: 'mantenimiento.fluidos',
      align: 'center',
    },
    {
      title: 'Otro Mantenimiento',
      field: 'mantenimiento.otro_servicio',
      align: 'center',
    },
    {
      title: 'Sistema de Luces',
      field: 'mantenimiento.sistema_luces',
      align: 'center',
      render: (row) => <Checkbox checked={row.mantenimiento.sistema_luces} />,
      hidden: true,
    },
    {
      title: 'Espejos',
      field: 'mantenimiento.espejos',
      align: 'center',
      render: (row) => <Checkbox checked={row.mantenimiento.espejos} />,
      hidden: true,
    },
    {
      title: 'Llantas',
      field: 'mantenimiento.llantas',
      align: 'center',
      render: (row) => <Checkbox checked={row.mantenimiento.llantas} />,
      hidden: true,
    },
    {
      title: 'Carrocería',
      field: 'mantenimiento.carroceria',
      align: 'center',
      render: (row) => <Checkbox checked={row.mantenimiento.carroceria} />,
      hidden: true,
    },
    {
      title: 'Plumillas',
      field: 'mantenimiento.plumillas',
      align: 'center',
      render: (row) => <Checkbox checked={row.mantenimiento.plumillas} />,
      hidden: true,
    },
    {
      title: 'Parabrisas',
      field: 'mantenimiento.parabrisas',
      align: 'center',
      render: (row) => <Checkbox checked={row.mantenimiento.parabrisas} />,
      hidden: true,
    },
    {
      title: 'Neumáticos',
      field: 'mantenimiento.neumaticos',
      align: 'center',
      render: (row) => <Checkbox checked={row.mantenimiento.neumaticos} />,
      hidden: true,
    },
    {
      title: 'Radiador',
      field: 'mantenimiento.radiador',
      align: 'center',
      render: (row) => <Checkbox checked={row.mantenimiento.radiador} />,
      hidden: true,
    },
    {
      title: 'Batería',
      field: 'mantenimiento.bateria',
      align: 'center',
      render: (row) => <Checkbox checked={row.mantenimiento.bateria} />,
      hidden: true,
    },
    {
      title: 'Filtros',
      field: 'mantenimiento.filtros',
      align: 'center',
      render: (row) => <Checkbox checked={row.mantenimiento.filtros} />,
      hidden: true,
    },
    {
      title: 'Sistema de Frenos',
      field: 'mantenimiento.sistema_frenos',
      align: 'center',
      render: (row) => <Checkbox checked={row.mantenimiento.sistema_frenos} />,
      hidden: true,
    },
    {
      title: 'Correa Accesorios',
      field: 'mantenimiento.correa_accesorios',
      align: 'center',
      render: (row) => <Checkbox checked={row.mantenimiento.correa_accesorios} />,
      hidden: true,
    },
    {
      title: 'Limpieza DPF',
      field: 'mantenimiento.limpieza_dpf',
      align: 'center',
      render: (row) => <Checkbox checked={row.mantenimiento.limpieza_dpf} />,
      hidden: true,
    },
    {
      title: 'Kit de Embrague',
      field: 'mantenimiento.kit_embriague',
      align: 'center',
      render: (row) => <Checkbox checked={row.mantenimiento.kit_embriague} />,
      hidden: true,
    },
  ];

  const actions = [
    {
      icon: 'chevron_right',
      tooltip: 'Ver Detalles',
      onClick: (event, rowData) => history.push(`activos/${rowData._id}`),
    },
    {
      icon: 'delete',
      tooltip: 'Eliminar Mantención',
      position: 'row',
      hidden: !permisos[PERMISSIONS.ELIMINAR_MANTENCION_VEHICULO],
      onClick: (event, rowData) => handleOpenEliminar(rowData),
    },
    {
      icon: 'visibility',
      tooltip: 'Ver Factura/Boleta',
      onClick: (event, rowData) => {
        const file = rowData?.mantenimiento?.archivo;
        window.open(file?.url, '_blank');
      },
    },
    {
      icon: 'preview',
      tooltip: 'Ver Ficha',
      onClick: (event, rowData) => {
        const file = rowData?.mantenimiento?.reporte;
        window.open(file?.url, '_blank');
      },
    },
    {
      icon: 'description',
      tooltip: 'Ver Orden Trabajo',
      onClick: (event, rowData) => {
        window.open(rowData?.mantenimiento?.orden_trabajo_file?.url, '_blank');
      },
    },
    {
      icon: 'mdiCarWrench',
      tooltip: 'Registrar Mantención',
      position: 'row',
      hidden: !permisos[PERMISSIONS.VER_BOTON_MANTENCION_VEHICULO],
      onClick: (event, rowData) => handleOpenRegistroMantenimiento(rowData, true),
    },
  ];

  return (
    <Fragment>
      <TablaMantencionesContext.Provider
        value={{
          openDialogoEliminar,
          setOpenDialogoEliminar,
          motivoEliminacion,
          setMotivoEliminacion,
          handleEliminar,
        }}
      >
        <MaterialTable
          title="Mantenciones Vehiculares Registradas"
          is_loading={!datosMantenciones}
          fullWidth
          data={datosMantenciones}
          columns={columns}
          grouping={false}
          actions={actions}
          components={{
            Action: (propData) => {
              const rowData = propData.data;
              if (propData.action.icon === 'visibility') {
                propData.action.hidden = !rowData?.mantenimiento?.archivo;
                propData.action.disabled = !rowData?.mantenimiento?.archivo;
              }
              if (propData.action.icon === 'delete') {
                propData.action.hidden =
                  !rowData?.mantenimiento?._id || !permisos[PERMISSIONS.ELIMINAR_MANTENCION_VEHICULO];
                propData.action.disabled = !rowData?.mantenimiento?._id;
              }
              if (propData.action.icon === 'preview') {
                propData.action.hidden = !rowData?.mantenimiento?.reporte;
                propData.action.disabled = !rowData.mantenimiento?.reporte;
              }
              if (propData.action.icon === 'description') {
                propData.action.hidden = !rowData.mantenimiento?.orden_trabajo_file;
                propData.action.disabled = !rowData.mantenimiento?.orden_trabajo_file;
              }
              if (propData.action.icon === 'mdiCarWrench') {
                return (
                  !propData.action.hidden && (
                    <Button
                      onClick={(event) => propData.action.onClick(event, rowData)}
                      color="primary"
                      style={{
                        color: 'white',
                        backgroundColor: '#731f1f',
                        marginLeft: 5,
                        textTransform: 'none',
                      }}
                      variant="contained"
                      size="small"
                    >
                      {'Registrar Mantención'}
                    </Button>
                  )
                );
              }
              return <MTableAction {...propData} />;
            },
          }}
        />

        {/* Dialogo para eliminar una mantencion */}
        <DialogoEliminarMantencion activo_seleccionado={activoSeleccionado} />

        {/* Cuadro de Dialogo para Registrar Mantencion */}
        <DialogoRegistroMantenimiento
          activo_seleccionado={activoSeleccionado}
          proveedores={proveedores}
          is_open={OpenRegistroMantenimiento}
          handle_open={handleOpenRegistroMantenimiento}
        />
      </TablaMantencionesContext.Provider>
    </Fragment>
  );
}

export default TablaMantenimientosVehiculares;
