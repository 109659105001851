import React from "react";
import MaterialTable from "../../../../components/materialTable";
import { getLogsUpdateValorLibro } from '../../../../services/request/requestLogUpdateValorLibro';
import useSWR from "swr";

async function getData() {     
	let detalles =  await getLogsUpdateValorLibro()
	console.log("detalles encontrados: ", detalles)
	
    return detalles?.status ? detalles?.data : [];
  }

export default function ListadoArchivosValoresLibros() {    

	const { data: listadoLogsUpdateValorLibro, mutate } = useSWR("get:log-update-valor-libro", (key) => getData(), { initialData: [] });	

	let columns = [
		{
			title: "ID",
			field: "_id",
			align: "center",
            hidden: true,
		},
		{
			title: "Archivo",
			field: "archivo.nombre",
			align: "center",			
		},
		{
			title: "Fecha de carga",
			field: "fecha_creacion",
			align: "center",
			defaultSort: "desc",
		},
		{
			title: "Descripción",
			field: "descripcion",
			align: "center",
		}
	];   

	return (
		<>			
			<MaterialTable
				title="Listado de Archivos subidos con actualizaciones de Valor Libro"
				is_loading={!listadoLogsUpdateValorLibro}
				data={listadoLogsUpdateValorLibro}
				columns={columns}				
				filtering={false}
				grouping={false}
				exportButtonPDF={true}
				exportButtonExcel={true}
				empty_rows={true}
				page_size={10}
			/>
		</>
	);
}
