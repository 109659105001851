import axios from "axios";
import { BACKEND } from "../../constants/urls";

/**
 * Método encargado de obtener las marcas de activos.
 */
export async function ObtenerMarcas() {
	try {
		let url = `${BACKEND}/marcas`;
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener los colores para activos.
 */
export async function ObtenerColores() {
	try {
		let url = `${BACKEND}/seleccionables?context_eq=colores`;
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener los tipos de documentos adjuntables de activos.
 */
export async function ObtenerTiposDocumentos() {
	try {
		let url = `${BACKEND}/seleccionables?context_eq=tipo_documento_adjunto`;
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener las condiciones actuales.
 */
export async function ObtenerCondicionesActuales() {
	try {
		let url = `${BACKEND}/seleccionables?context_eq=condiciones_actuales`;
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Guardar seleccionable
 * @param {*} data datos del registro
 * @param {String} context contexto del registro
 * @param {*} cancelToken token para cancelacion
 */
export function postSeleccionable(data, context = null, cancelToken = null) {
	return axios.post(`${BACKEND}/seleccionables/`, data,
		{
			cancelToken: cancelToken
		}).then(res => res.data ? res.data.data : null);
}


/**
 * Actualizar seleccionable
 * @param {String}  id
 * @param {Object} data datos del registro
 * @param {String} context contexto del registro
 * @param {*} cancelToken token para cancelacion
 */
export function putSeleccionable(id, data, context = null, cancelToken = null) {
	return axios.put(`${BACKEND}/seleccionables/${id}`, data,
		{
			cancelToken: cancelToken
		}).then(res => res.data ? res.data.data : null);
}

/**
 * Eliminar seleccionable
 * @param {String}  id
 * @param {String} context contexto del registro
 * @param {*} cancelToken token para cancelacion
 */
export function deleteSeleccionable(id, context = null, cancelToken = null) {
	return axios.delete(`${BACKEND}/seleccionables/${id}`,
		{
			cancelToken: cancelToken
		}).then(res => res.data ? res.data.data : null);
}

/**
 * Obtener tipos de recursos
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
 export function getSeleccionables(filter, cancelToken = null) {
  return axios.get(`${BACKEND}/seleccionables/`,
    {
      params: filter,
      cancelToken: cancelToken
    }).then(res => res.data ? res.data.data : null);
}