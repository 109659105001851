import React, { createContext, useContext, useState } from 'react';
import MaterialTable from "../../components/materialTable";
import useSWR from 'swr';
import { deleteAsignacionLicenciaSoftware, postLogLicenciaSoftware, getAsignacionesLicenciasSoftware } from '../../services/request/requestLicenciasSoftware';
import { MainContext } from '../../App';
import { Fragment } from 'react';
import DialogoCRUDAsignacionSoftware from './dialogoCRUDAsignacionSoftware';
import { useHistory } from 'react-router';
import { Button } from '@material-ui/core';
import { VER_ACTIVO } from '../../constants/routes';
import { AGREGAR_LICENCIA_SOFTWARE, ELIMINAR_LICENCIA_SOFTWARE } from "../../constants/permissions";

async function getDataAsignaciones() {

  const response = await getAsignacionesLicenciasSoftware({},
    [
      "fecha_creacion"
    ], [
    "_licencia_software_ref.nombre",
    "_licencia_software_ref.version",
    "_licencia_software_ref.fecha_vencimiento",
    "_bien_ref.codigo_activo",
    "_gerencia_ref.nombre",
    "_contrato_ref.nombre",
    "_contrato_ref.codigo",
    "_contrato_ref.fecha_termino_estimada",
    "_usuario_ref.contacto",
    "_usuario_ref.run",
    "_usuario_ref.nombre",
    "_usuario_ref.apellido_paterno",
    "_usuario_ref.apellido_materno"
  ]);
  if (response.status) {
    
    return response.data.map(a => 
      ({
      _id: a._id,
      fecha_creacion: a.fecha_creacion,
      fecha_vencimiento: a._licencia_software_ref?.fecha_vencimiento ? a._licencia_software_ref?.fecha_vencimiento : null,
      licencia: a._licencia_software_ref ? a._licencia_software_ref.nombre : "--",
      version: a._licencia_software_ref ? a._licencia_software_ref.version : "--",
      _bien_ref: a._bien_ref ? a._bien_ref._id : null,
      codigo_activo: a._bien_ref ? a._bien_ref.codigo_activo : "--",
      usuario: a._usuario_ref ? a._usuario_ref.nombre + " " + a._usuario_ref.apellido_paterno + " " + a._usuario_ref.apellido_materno : "--",
      mail_usuario: a._usuario_ref && a._usuario_ref.contacto ? a._usuario_ref.contacto.email : "--",
      rut_usuario: a._usuario_ref ? a._usuario_ref.run : "--",
      codigo_contrato: a._contrato_ref ? a._contrato_ref.codigo : "--",
      contrato: a._contrato_ref ? a._contrato_ref.nombre : "--",
      gerencia: a._gerencia_ref ? a._gerencia_ref.nombre : "--",
      fecha_termino_contrato: (a._contrato_ref && a._contrato_ref.fecha_termino_estimada && a._contrato_ref.fecha_termino_estimada.split("T")[0] !== "2100-01-01") ? a._contrato_ref.fecha_termino_estimada : null,
      _licencia_software_ref: a._licencia_software_ref ? a._licencia_software_ref._id : null
    }));
  }
  return ([]);
}

export const TablaAsignacionesSoftwareTodasContext = createContext({
  refreshTable: () => { },
  openDialogo: false,
  closeDialogo: () => { }
});

export default function TablaAsignacionesSoftwareTodas() {

  const { usuarioSesion, permisos } = useContext(MainContext);

  const history = useHistory();

  const { data, mutate } = useSWR('get:asignaciones-licencias-todas', () => getDataAsignaciones(), { initialData: null });

  const handleEliminar = async (rowData) => {
    if (rowData) await deleteAsignacionLicenciaSoftware(rowData._id);
    await postLogLicenciaSoftware({
      _usuario_ref: usuarioSesion.ref,
      _licencia_software_ref: rowData._licencia_software_ref,
      descripcion: `Desasignación de Licencia de ${rowData.codigo_activo}`
    });
    mutate();
  };

  const handleVerActivo = (rowData) => {
    history.push(VER_ACTIVO.replace(':id', rowData._bien_ref));
  }

  const [openDialogo, setOpenDialog] = useState(false);

  const actions = [
    {
      tooltip: "Agregar",
      icon: "add",
      isFreeAction: true,
      hidden: permisos && permisos[AGREGAR_LICENCIA_SOFTWARE] ? false: true,
      onClick: (event, row) => setOpenDialog(true),
    },
    {
      tooltip: "Eliminar",
      icon: "delete",
      hidden: permisos && permisos[ELIMINAR_LICENCIA_SOFTWARE] ? false: true,
      onClick: (event, row) => handleEliminar(row),
    }
  ];

  const columns = [
    {
      title: "Fecha de Asignación",
      field: "fecha_creacion",
      type: 'datetime',
      align: "center",
      defaultSort: "desc"
    },
    {
      title: "Licencia",
      field: "licencia",
      align: "center",
    },
    {
      title: "Versión",
      field: "version",
      align: "center",
    },
    {
      title: "Fecha de Vencimiento",
      field: "fecha_vencimiento",
      type: 'date',
      align: "center",
    },
    {
      title: "Activo",
      field: "codigo_activo",
      align: "center",
      render: rowData => <Button size="small" variant="outlined" onClick={() => handleVerActivo(rowData)}>
        {rowData.codigo_activo}
      </Button >,
      cellStyle: {
        textAlign: 'center'
      }
    },
    {
      title: "Gerencia",
      field: "gerencia",
      align: "center",
    },
    {
      title: "Código de Contrato",
      field: "codigo_contrato",
      align: "center",
    },
    {
      title: "Contrato",
      field: "contrato",
      align: "center",
    },
    {
      title: "Usuario del Activo",
      field: "usuario",
      align: "center",
    },
    {
      title: "RUT del Usuario",
      field: "rut_usuario",
      align: "center",
    },
    {
      title: "Email del Usuario",
      field: "mail_usuario",
      align: "center",
    },
    {
      title: "Fecha Término Contrato",
      field: "fecha_termino_contrato",
      type: 'date',
      align: "center",
    }
  ];


  return (
    <Fragment>
      <TablaAsignacionesSoftwareTodasContext.Provider
        value={{
          refreshTable: () => mutate(),
          openDialogo,
          closeDialogo: () => setOpenDialog(false)
        }}
      >
        <MaterialTable
          title="Asignaciones de Licencias"
          is_loading={data === null}
          columns={columns}
          data={data ? data : []}
          actions={actions}
          lockheaders={true}
        />
        <DialogoCRUDAsignacionSoftware />
      </TablaAsignacionesSoftwareTodasContext.Provider>
    </Fragment>
  );
}