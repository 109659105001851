import axios from "axios";
import { BACKEND } from "../../constants/urls";
import { auth } from '../firebase';
/**
 * Método encargado de obtener todas las solicitudes de activación.
 * @param {Object} filtro
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {Object} cancelToken token para cancelacion
 */
export async function getSolicitudesActivacion(filtro = {}, selects = [], populates = [], cancelToken = null) {
	let url = `${BACKEND}/solicitudes-activacion`;
	if (selects) selects.forEach(select => {
		url = url + "/" + select;
	});
	if (populates) populates.forEach(populate => {
		url = url + "/" + populate;
	});
	return axios.get(url,
		{
			params: filtro,
			cancelToken: cancelToken
		}).then(res => res.data ? res.data.data : []);
}
/**
 * Método encargado una solicitudes de activación.
 * @param {String} id id del registro
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {Object} cancelToken token para cancelacion
 */
export async function getSolicitudActivacion(id, selects, populates, cancelToken = null) {
	let url = `${BACKEND}/solicitudes-activacion/${id}`;
	if (selects) selects.forEach(select => {
		url = url + "/" + select;
	});
	if (populates) populates.forEach(populate => {
		url = url + "/" + populate;
	});
	return axios.get(url,
		{
			cancelToken: cancelToken
		}).then(res => res.data ? res.data.data : null);
}

/**
 * Método encargado de obtener informacion del activo
 * @param {String} id id del registro
 * @param {Object} cancelToken token para cancelacion
 */
export async function getActivo(id, cancelToken = null) {
	return axios.get(`${BACKEND}/activos/${id}`,
		{
			cancelToken: cancelToken
		}).then(res => res.data ? res.data.data : null);
}

/**
 * Método para aceptar una solictud de activacion
 * @param {String} id id del registro
 * @param {Usuario} usuario usuario que acepta solictud
 * @param {Object} cancelToken token para cancelacion
 */
export async function postAceptarSolicitud(id, usuario, cancelToken = null) {
	return auth.currentUser.getIdToken(true).then((idToken) => {
		return axios.post(`${BACKEND}/solicitudes-activacion/${id}/aceptar`,
			{
				usuario: usuario
			},
			{
				headers: {
					"authorization": `Bearer ${idToken}`
				},
				cancelToken: cancelToken
			}
		);
	})
		.then(res => res.data ? res.data.data : null);
}

/**
 * Método para rechazar una solictud de activacion
 * @param {String} id id del registro
 * @param {Usuario} usuario usuario que rechaza solictud
 * @param {String} comentario
 * @param {Object} cancelToken token para cancelacion
 */
export async function postRechazarSolicitud(id, usuario, comentario = "", cancelToken = null) {
	return auth.currentUser.getIdToken(true).then((idToken) => {
		return axios.post(`${BACKEND}/solicitudes-activacion/${id}/rechazar`,
			{
				usuario: usuario,
				comentario: comentario
			},
			{
				headers: {
					"authorization": `Bearer ${idToken}`
				},
				cancelToken: cancelToken
			}
		);
	})
		.then(res => res.data ? res.data.data : null);
}

/**
 * Método para no activar una solictud de activacion
 * @param {String} id id del registro
 * @param {Usuario} usuario usuario que rechaza solictud
 * @param {String} comentario
 * @param {Object} cancelToken token para cancelacion
 */
export async function postNoActivarSolicitud(id, usuario, comentario = "", cancelToken = null) {
	return auth.currentUser.getIdToken(true).then((idToken) => {
		return axios.post(`${BACKEND}/solicitudes-activacion/${id}/no-activar`,
			{
				usuario: usuario,
				comentario: comentario
			},
			{
				headers: {
					"authorization": `Bearer ${idToken}`
				},
				cancelToken: cancelToken
			}
		);
	})
		.then(res => res.data ? res.data.data : null);
}


export function getSolicitudesActivacionUsuario(filter = {}, selects = [], populates = [], cancelToken = null) {
	let url = `${BACKEND}/solicitudes-activacion/aprobador`;
	if (selects) selects.forEach(select => {
		url = url + "/" + select;
	});
	if (populates) populates.forEach(populate => {
		url = url + "/" + populate;
	});
	return auth.currentUser.getIdToken(true).then((idToken) => {
		return axios.post(url, {},
			{
				params: filter,
				headers: {
					"authorization": `Bearer ${idToken}`
				},
				cancelToken: cancelToken
			});
	}).then(res => res.data ? res.data.data : []);
}


/**
 * método encargado de obtener las solicitudes de activación emitidas, con estado pendiente.
 * @param {*} usuarioID ID del usuario.
 */
 export async function ObtenerActivaciones(usuarioID) {
	try {
		let url = `${BACKEND}/solicitudes-activacion?_usuario_solicitante_ref_eq=${usuarioID}&estado_eq=Pendiente`;
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}