import axios from 'axios';
import { BACKEND } from '../../constants/urls'

/**
 * Obtener documentos requeridos clase
 * @param {*} filtro
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export function getDocumentosRequeridosClase(filter = {}, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/documentos-requeridos-clases`;
  if (selects) selects.forEach(select => {
    url = url + "/" + select;
  });
  if (populates) populates.forEach(populate => {
    url = url + "/" + populate;
  });
  return axios.get(url,
    {
      params: filter,
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Obtener documento requerido de clase por id
 * @param {*} id
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export async function getDocumentoRequeridoClasePorID(id, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/documentos-requeridos-clases/${id}`;
  if (selects) selects.forEach(select => {
    url = url + "/" + select;
  });
  if (populates) populates.forEach(populate => {
    url = url + "/" + populate;
  });
  return axios.get(url,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Agregar documento requerido de clase
 * @param {Object} data
 * @param {*} cancelToken token para cancelacion
 */
export async function postDocumentoRequeridoClase(data, cancelToken = null) {
  let url = `${BACKEND}/documentos-requeridos-clases`;
  return axios.post(url, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Modificar documento requerido de clase
 * @param {*} id
 * @param {Object} data
 * @param {*} cancelToken token para cancelacion
 */
export async function putDocumentoRequeridoClase(id, data, cancelToken = null) {
  let url = `${BACKEND}/documentos-requeridos-clases/${id}`;
  return axios.put(url, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Eliminar documento requerido de clase
 * @param {*} id
 * @param {Object} data
 * @param {*} cancelToken token para cancelacion
 */
export async function deleteDocumentoRequeridoClase(id, data, cancelToken = null) {
  let url = `${BACKEND}/documentos-requeridos-clases/${id}`;
  return axios.delete(url,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}