import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { TablaMarcasContext } from './tablaMarcas';

export default function DialogoCRUDMarca() {
  const {
    openDialogoCRUD,
    setOpenDialogoCRUD,
    tituloDialogo,
    nombre,
    setNombre,
    handleAceptar,
    disableEditing,
    id,
    // descripcion, setDescripcion,
    is_computacional,
    setIsComputacional,
    is_vehiculo,
    setIsVehiculo,
  } = useContext(TablaMarcasContext);

  const handleClose = () => setOpenDialogoCRUD(false);

  return (
    <Dialog open={openDialogoCRUD} maxWidth="md" fullWidth>
      <DialogTitle>{tituloDialogo}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs>
            <TextField
              label="Nombre"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              variant="outlined"
              fullWidth
              disabled={disableEditing}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs>
            <FormControl component="fieldset">
              <FormLabel component="legend">Habilitar para:</FormLabel>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={is_computacional}
                      onChange={(e) => setIsComputacional(e.target.checked)}
                      name="is_computacional"
                      color="primary"
                      disabled={disableEditing}
                    />
                  }
                  label="Equipos Computacionales"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={is_vehiculo}
                      onChange={(e) => setIsVehiculo(e.target.checked)}
                      name="is_vehiculo"
                      color="primary"
                      disabled={disableEditing}
                    />
                  }
                  label="Vehículo"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="primary">
          Cancelar
        </Button>
        <Button
          onClick={() => handleAceptar(tituloDialogo, { nombre, is_computacional, is_vehiculo }, id)}
          variant="contained"
          color="primary"
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
