import {
  Container,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { ArrowBack, Refresh as RefreshIcon } from '@material-ui/icons';
import { LICENCIAS_SOFTWARE } from '../../constants/routes';
import { DetalleLicenciaSoftwareContext } from './index';

const useStyles = makeStyles((theme) => ({
  right: {
    textAlign: "right",
  }
}));

const DarkerDisabledTextField = withStyles({
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "black" // (default alpha is 0.38)
    },
    '& label.Mui-disabled': {
      color: 'black',
    }
  }
})(TextField);

export default function PanelDetalleLicenciaSoftware() {
  const classes = useStyles();
  const history = useHistory();

  const { dataLicencia: licenciaSoftware, refreshDataLicencia } = useContext(DetalleLicenciaSoftwareContext);

  return (
    <Paper>
      <Grid container alignContent="center" justify="center">
        <Grid item lg={4}></Grid>
        <Grid item lg={4} className={classes.center}>
          <Typography variant="h5" style={{ textAlign: 'center', padding: "16px" }}>
            Detalle Licencia de Software
          </Typography>
        </Grid>
        <Grid item lg={4} className={classes.right}>
          <Tooltip title="Volver al Listado de Licencias de Software">
            <IconButton onClick={() => history.push(LICENCIAS_SOFTWARE)} style={{ margin: '8px' }}>
              <ArrowBack></ArrowBack>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <br />
      <Container>
        <Grid container spacing={2}>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <DarkerDisabledTextField
              name="nombre"
              fullWidth
              variant="outlined"

              label="Nombre"
              value={licenciaSoftware && licenciaSoftware.nombre ? licenciaSoftware.nombre : "--"}
              disabled
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12}  xs={12}>
            <DarkerDisabledTextField
              name="version"
              fullWidth
              variant="outlined"

              label="Versión"
              value={licenciaSoftware && licenciaSoftware.version ? licenciaSoftware.version : "--"}
              disabled
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12}  xs={12}>
            <DarkerDisabledTextField
              name="codigo_compra"
              fullWidth
              variant="outlined"

              label="Código de Compra"
              value={licenciaSoftware && licenciaSoftware.codigo_compra ? licenciaSoftware.codigo_compra : "--"}
              disabled
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12}  xs={12}>
            <DarkerDisabledTextField
              name="tipo"
              fullWidth
              variant="outlined"

              label="Tipo de Licencia"
              value={licenciaSoftware && licenciaSoftware.tipo ? licenciaSoftware.tipo : "--"}
              disabled
            />
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={12}  xs={12}>
            <DarkerDisabledTextField
              name="fecha_vencimiento"
              fullWidth
              variant="outlined"

              label="Fecha Vencimiento"
              value={licenciaSoftware && licenciaSoftware.fecha_vencimiento ? moment(licenciaSoftware.fecha_vencimiento).format("DD/MM/YYYY") : "--"}
              disabled
            />
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={12}  xs={12}>
            <DarkerDisabledTextField
              name="cantidad"
              fullWidth
              variant="outlined"

              label="Cantidad Total"
              value={licenciaSoftware && licenciaSoftware.cantidad ? licenciaSoftware.cantidad : 0}
              disabled
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12}  xs={12}>
            <DarkerDisabledTextField
              name="orden_compra"
              fullWidth
              variant="outlined"

              label="Orden de Compra"
              value={licenciaSoftware && licenciaSoftware.orden_compra ? licenciaSoftware.orden_compra : "--"}
              disabled
            />
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={12}  xs={12}>
            <DarkerDisabledTextField
              name="descripcion"
              fullWidth
              variant="outlined"

              label="Descripción"
              value={licenciaSoftware && licenciaSoftware.descripcion ? licenciaSoftware.descripcion : "--"}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} direction="row" alignItems="center" justify="center">
          <Grid item xl={4} lg={4} md={4} sm={12}  xs={12}>
            <DarkerDisabledTextField
              name="cantidad_disponible"
              fullWidth
              variant="outlined"

              label="Cantidad Disponible"
              value={licenciaSoftware && licenciaSoftware.cantidad && (licenciaSoftware.cantidad_utilizada || licenciaSoftware.cantidad_utilizada === 0) ? (licenciaSoftware.cantidad - licenciaSoftware.cantidad_utilizada) : 0}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => refreshDataLicencia()}
                    >
                      <RefreshIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              disabled
            />

          </Grid>

        </Grid>
      </Container>
    </Paper>

  );

}