import React, { useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { ListadoActivosContext } from '.';
import { deleteBien } from '../../../services/request/requestBienes';

export default function DialogEliminar(props) {
  const { activo_seleccionado, is_open, handle_open } = props;

  const { data, setData } = useContext(ListadoActivosContext);
  const notistack = useSnackbar();

  /**
   * Método encargado de eliminar permanentemente un activo.
   * @param {*} activo Datos del activo.
   */
  async function eliminarActivo(activo) {
    try {
      notistack.enqueueSnackbar('Eliminando activo...', {
        persist: true,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
      });
      await deleteBien(activo._id);
      notistack.closeSnackbar();

      const bienes = [...data];
      const index = bienes.findIndex((bien) => bien._id === activo_seleccionado._id);
      bienes.splice(index, 1);
      setData(bienes);

      notistack.enqueueSnackbar('Operación exitosa: Activo eliminado', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (key) => (
          <IconButton onClick={() => notistack.closeSnackbar(key)}>
            <Close />
          </IconButton>
        ),
      });
    } catch (e) {
      console.error(e);
      notistack.closeSnackbar();
      notistack.enqueueSnackbar(
        'Error al intentar eliminar el activo. Intente de nuevo o contacte con el equipo de soporte.',
        {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (key) => (
            <IconButton onClick={() => notistack.closeSnackbar(key)}>
              <Close />
            </IconButton>
          ),
        },
      );
    } finally {
      handle_open(null, false);
    }
  }

  return (
    <Dialog open={is_open} onClose={() => handle_open(null, false)}>
      <DialogTitle>Eliminar activo: {activo_seleccionado ? activo_seleccionado.codigo_activo : ''}</DialogTitle>
      <DialogContent>
        Se eliminará el activo y toda su información relacionada de forma permanente ¿Desea continuar?
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={() => handle_open(null, false)}>
          No
        </Button>
        <Button variant="contained" color="primary" onClick={() => eliminarActivo(activo_seleccionado)}>
          Sí
        </Button>
      </DialogActions>
    </Dialog>
  );
}
