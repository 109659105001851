import React, { useCallback, useContext, useEffect, useState } from "react";
import MaterialTable from '../../../components/materialTable';
import Moment from "moment";
import { VER_DETALLE_SOLICITUD_ACTIVACION } from '../../../constants/permissions';
import { MainContext } from "../../../App";
import { getSolicitudesBajaUsuario } from "../../../services/request/requestSolicitudesBaja";
import useSWR from 'swr';
import { useHistory } from "react-router";
import { getUsuariosPlataforma } from "../../../services/request/requestUsuariosPlataforma";
import { Tooltip } from "@material-ui/core";
import { SupervisorAccount } from "@material-ui/icons";

async function getData() {
  const data = await getSolicitudesBajaUsuario();
  const usuariosContabilidad = await getUsuariosPlataforma({ is_contabilidad_eq: true });
  const usuariosTI = await getUsuariosPlataforma({ is_ti_eq: true });
  const usuariosRFF = await getUsuariosPlataforma({ is_rff_eq: true });
  let stringContabilidad = usuariosContabilidad.map(a => a.nombre);
  stringContabilidad = stringContabilidad.join(' / ');
  let stringTI = usuariosTI.map(a => a.nombre);
  stringContabilidad = stringTI.join(' / ');
  let stringRFF = usuariosRFF.map(a => a.nombre);
  stringRFF = stringRFF.join(' / ');
  return data.map(solicitud => {
    if (!solicitud._usuario_revisor_ref) {
      switch (solicitud.tipo) {
        case "TI": {
          solicitud["revisor"] = <Tooltip
            title={stringTI}>
            <SupervisorAccount />
          </Tooltip>;
          break;
        }
        case "RFF": {
          solicitud["revisor"] = <Tooltip
            title={stringRFF}>
            <SupervisorAccount />
          </Tooltip>;
          break;
        }
        case "Registro Contable": {
          solicitud["revisor"] = <Tooltip
            title={stringContabilidad}>
            <SupervisorAccount />
          </Tooltip>;
          break;
        }
        default:
          solicitud["revisor"] = "--";
          break;
      }
    } else {
      solicitud["revisor"] = solicitud._usuario_revisor_ref.nombre_completo;
    }
    return solicitud;
  });
}

export default function TablaSolicitudesBajaPendientes(props) {

  const { permisos } = useContext(MainContext);
  const history = useHistory();

  const { data: solicitudes, isValidating } = useSWR("get:solicitudes-baja-pendientes", (key) => getData(), { refreshInterval: 0 });

  const handleOpenDialogoBaja = (rowData) => {
    history.push(`/solicitudes-baja/${rowData._id}`);
  }

  const columns = [
    {
      title: "Fecha Solicitud",
      field: "fecha_creacion",
      render: (row) => row.fecha_creacion ? Moment(row.fecha_creacion).format('DD/MM/YYYY') : "--",
      defaultSort: 'desc'
    },
    {
      title: "Fecha Revisión",
      field: "fecha_revision",
      render: (row) => row.fecha_revision ? Moment(row.fecha_revision).format('DD/MM/YYYY') : "--",
    },
    {
      title: "Bien",
      field: "_bien_ref.codigo_activo",
    },
    {
      title: "Clase",
      field: "_bien_ref._clase_ref.nombre",
    },
    {
      title: "Contrato",
      render: (row) => row._bien_ref && row._bien_ref._contrato_ref ? row._bien_ref._contrato_ref.codigo : "--",
    },
    {
      title: "Estado",
      field: "estado",
    },
    {
      title: "Gestiona",
      field: "tipo",
    },
    {
      title: "Solicitante",
      render: (row) => row._usuario_solicitante_ref ? row._usuario_solicitante_ref.nombre_completo : "--",
    },
    {
      title: "Revisor",
      render: (row) => row._usuario_revisor_ref ? row._usuario_revisor_ref.nombre_completo : "--",
    },
    {
      title: "Observaciones",
      field: "comentario",
    },
  ]

  const [actions, setActions] = useState();

  const revisarPermisos = useCallback(() => {
    if (permisos[VER_DETALLE_SOLICITUD_ACTIVACION]) setActions([
      {
        icon: "chevron_right",
        tooltip: "Ver Solicitud",
        onClick: (evt, rowData) => handleOpenDialogoBaja(rowData)
      },
    ]);
    else setActions([]);
  }, [permisos]);

  useEffect(() => {
    revisarPermisos();
  }, [revisarPermisos]);



  return (
    <MaterialTable
      actions={actions}
      title="Solicitudes Pendientes/Aprobadas"
      is_loading={isValidating}
      data={solicitudes ? solicitudes : []}
      columns={columns}
    />
  );
}