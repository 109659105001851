export const ACCESS_ERROR = '/accessError';
export const AJUSTES_ADMINISTRACION = "/ajustes-administracion";
export const AJUSTES_CONFIGURACION = "/ajustes-configuracion";
export const BIENES_CONTRATO = '/bienes-contrato/:id';
export const BIENES_GERENCIA = '/bienes-gerencia/:id';
export const BIENES_PERSONA = '/bienes-persona/:id';
export const CARGA_MASIVA_ACTIVOS = "/carga-masiva/";
export const COSTOS_ACTIVOS = "/costos-por-activo/";
export const DETALLE_BIENES = '/detalle-activo/:id';
export const DETALLE_MI_ACTIVO = '/detalle-mi-activo/:id';
export const DETALLE_LICENCIA_SOFTWARE = '/licencias-software/:id';
export const DOCUMENTOS_POR_VENCER_ROUTE = "/documentos-por-vencer";
export const GESTION_FLOTA = "/gestion-flota";
export const LANDING = '/';
export const INFORME_SOFTWARE_ROUTE = "/informe-de-software";
export const INFORME_MOVIMIENTOS_ACTIVOS = "/informe-movimientos-activos"
export const MOVIMIENTO_MENSUAL = "/movimiento-mensual";
export const LICENCIAS_SOFTWARE = "/licencias-software";
export const LISTADO_ASIGNACIONES_PERSONALES = '/solicitudes-asignaciones-personales';
export const LISTADO_BIENES = '/listado-activos';
export const LISTADO_CONTRATOS = '/listado-contratos';
export const LISTADO_CONTRATOS_ID = '/listado-contratos/:id';
export const LISTADO_DOCUMENTOS_CLASE = '/clases/:idClase/documentos';
export const LISTADO_GERENCIAS = '/listado-gerencias';
export const LISTADO_MODELOS_MARCA = "/marca/:idMarca/modelos";
export const LISTADO_PERFILES_ROL = "/roles/:id";
export const LISTADO_PERMISOS_PERFIL = "/perfiles/:id";
export const LISTADO_PERSONAL = '/mis-activos';
export const LISTADO_PERSONAS = '/listado-personas';
export const LISTADO_SOLICITUDES_ACTIVACION = '/solicitudes-activacion';
export const REGISTRO_AOB = '/registro-activos';
export const REPORTE_ACTIVOS_REGISTRADOS = '/reporte-activos-registrados';
export const SOLICITUDES_ASIGNACION = '/solicitudes-asignacion';
export const SOLICITUDES_BAJA = '/solicitudes-baja/';
export const VER_ACTIVO = '/activos/:id';
export const VER_SOLICITUD_ACTIVACION = '/solicitudes-activacion/:id';
export const VER_SOLICITUDES_ASIGNACION = '/solicitudes-asignacion/:id';
export const VER_SOLICITUD_BAJA = '/solicitudes-baja/:id';
export const REPORTE_RESUMEN_LICENCIAS_SOFTWARE_GERENCIAS_ROUTE = "/resumen-licencias-de-software-gerencias";