import React, { createContext, useContext, useEffect, useState } from "react";
import Page from "./page";
import { MainContext, ListadoVehiculosContext } from "../../../App";
import { ObtenerUsuario } from "../../../services/request/requestUsuarios";
import { obtenerBienesUsuario } from "../../../services/request//requestBienes";
import { ObtenerSolicitudesAsignacion, ObtenerSolicitudesAsignacionGerencia, ObtenerSolicitudesAsignacionContrato } from "../../../services/request/requestSolicitudesAsignacion";
import { ObtenerActivaciones } from "../../../services/request/requestSolicitudesActivacion";
import { ObtenerGerencia, ObtenerGerencias, ObtenerContratos } from "../../../services/request/requestPortafolio";
import { getNotificaciones } from "../../../services/request/requestNotificaciones";
import { VER_MIS_ACTIVACIONES_PENDIENTES } from "../../../constants/permissions";
import { useVehiculos } from '../../../hooks/useVehiculos';

export const ListadoNotificacionesContext = createContext({
	notificaciones: [], setNotificaciones: () => { },	
});

const Index = ()  => {
  const [Usuario, SetUsuario] = useState();
  const [bienes, setBienes] = useState();
  const [asignaciones, setAsignaciones] = useState();
  const [activaciones, setActivaciones] = useState();
  const [notificaciones, setNotificaciones] = useState([]);

  const { usuarioSesion, permisos } = useContext(MainContext);
  const { setVehiculos } = useContext(ListadoVehiculosContext);
  const { vehiculos } = useVehiculos(usuarioSesion.contrato);

  useEffect(() => {
    if (usuarioSesion) {
      //Se busca la información del usuario.
      ObtenerUsuario(usuarioSesion.ref)
        .then(usuario => {
          ObtenerGerencia(usuario.gerencia_ref)
            .then(gerencia => {
              usuario.gerencia = gerencia.nombre;
              SetUsuario(usuario);
            })
            .catch(error => {
              console.error(error);
              throw error;
            });
        })
        .catch(error => {
          console.error(error);
          SetUsuario();
        });
        setVehiculos(vehiculos);
      load_Data();      
    }
  }, [usuarioSesion, vehiculos]);

  /**
   * Función encargada de cargar los datos de activos, asignaciones y activaciones.
   */
  const load_Data = () => {   
    //Se buscan los bienes asignados del usuario.
    BienesUsuario(usuarioSesion.ref)
      .then(bienes => setBienes(bienes))
      .catch(error => setBienes([]));
    
      notificacionesUsuario(usuarioSesion.ref)
      .then(notificaciones => setNotificaciones(notificaciones))
      .catch(error => setNotificaciones([])); 

    //Se busca las asignaciones pendientes del usuario.
    AsignacionesUsuario(usuarioSesion.ref)
      .then(asignaciones => setAsignaciones(asignaciones))
      .catch(error => setAsignaciones([]));

    if (permisos && permisos[VER_MIS_ACTIVACIONES_PENDIENTES]) {
      //Se buscan las activaciones pendientes del usuario.
      ActivacionesUsuario(usuarioSesion.ref)
        .then(activaciones => setActivaciones(activaciones))
        .catch(error => setActivaciones([]));
    }      
  }

  return ( 
    <ListadoNotificacionesContext.Provider value={{
      notificaciones, setNotificaciones,      
    }}>    
    <Page
      usuario={Usuario}
      bienes={bienes}
      asignaciones={asignaciones}
      activaciones={activaciones} 
      //notificaciones={notificaciones}      
      load_data={load_Data}
    />
    </ListadoNotificacionesContext.Provider>
  );
}

  /**
 * Método encargado de obtener los bienes de una persona.
 * @param {*} usuarioID ID de la persona.
 */
   async function BienesUsuario(usuarioID) {
    try {
      let response = await obtenerBienesUsuario(usuarioID);
      let bienes = response.data;
      if (bienes) {
        //Se ordenan los activos por fecha de creación.
        bienes.sort((a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion));
      }
      return bienes ? bienes : [];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
   async function notificacionesUsuario(usuarioID) {
    try {
      let response = await getNotificaciones({_notificados_ref_in: usuarioID}, 
        ["_id", "_notificados_ref", "_bien_ref",  "mensaje", "estado", "fecha_evento", "nivel", "tipo_documento", "fecha_creacion", "descripcion"], 
        ["_bien_ref.codigo_activo",]);
      let notificaciones = response.data;
      if (notificaciones) {
        //Se ordenan los activos por fecha de creación.
        notificaciones.sort((a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion));
      }
      return notificaciones ? notificaciones : [];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  
  /**
   * Método encargado de obtener las asignaciones de un usuario.
   * Si el usuario es responsable de gerencias o contratos, se cargan las asignaciones correspondientes.
   * @param {*} usuarioID ID del usuario.
   */
  async function AsignacionesUsuario(usuarioID) {
    try {
      let asignaciones = [];
  
      let responsePersona = await ObtenerSolicitudesAsignacion(usuarioID);
      if (responsePersona && responsePersona.data) {
        let asignacionesPersona = Array.from(responsePersona.data);
        asignaciones = asignaciones.concat(asignacionesPersona);
      }
  
      let gerencias = await ObtenerGerencias(usuarioID);
      if (gerencias) {
        //Si el usuario tiene gerencias a su cargo.
        for (let i = 0; i < gerencias.length; i++) {
          const gerencia = gerencias[i];
          let responseGerencia = await ObtenerSolicitudesAsignacionGerencia(gerencia._id);
          let asignacionesGerencia = Array.from(responseGerencia.data);
          if (asignacionesGerencia && asignacionesGerencia.length > 0) {
            //Si la gerencia tiene solicitud de asignación pendientes.
            asignaciones = asignaciones.concat(asignacionesGerencia);
          }
        }
      }
  
      let contratos = await ObtenerContratos(usuarioID);
      if (contratos) {
        //Si el usuario tiene contratos a su cargo.
        for (let i = 0; i < contratos.length; i++) {
          const contrato = contratos[i];
          let responseContrato = await ObtenerSolicitudesAsignacionContrato(contrato._id);
          let asignacionesContrato = Array.from(responseContrato.data);
          if (asignacionesContrato && asignacionesContrato.length > 0) {
            //Si el contrato tiene solicitud de asignación pendientes.
            asignaciones = asignaciones.concat(asignacionesContrato);
          }
        }
      }
  
      asignaciones.forEach((solicitud, index_1) => {
        let asignante = solicitud._usuario_asignante_ref;
        asignaciones[index_1]["asignado_por"] = asignante ? `${asignante.nombre} ${asignante.apellido_paterno} ${asignante.apellido_materno}` : "Sin Datos";
        switch (solicitud.tipo) {
          case "persona":
            //Se asigna el nombre de la persona.
            let persona = solicitud._asignado_ref;
            asignaciones[index_1]["asignado_a"] = persona ? `${persona.nombre} ${persona.apellido_paterno} ${persona.apellido_materno}` : "Sin Datos";
            break;
          case "gerencia":
            //Se asigna el nombre de la gerencia.
            let gerencia = solicitud._gerencia_ref;
            asignaciones[index_1]["asignado_a"] = gerencia ? `${gerencia.sigla} ${gerencia.nombre}` : "Sin Datos";
            break;
          case "contrato":
            //Se asigna el nombre del contrato.
            let contrato = solicitud._contrato_ref;
            asignaciones[index_1]["asignado_a"] = contrato ? `${contrato.codigo} ${contrato.nombre}` : "Sin Datos";
            break;
          default:
            throw "Tipo de asignación no válida.";
        }
      });
      if (asignaciones) {
        //Se ordenan las asignaciones de la más actual a la más antigua.
        asignaciones.sort((a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion));
      }
      return asignaciones ? asignaciones : [];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  
  /**
   * Método encargado de obtener las activaciones de un usuario.
   * @param {*} usuarioID ID del usuario.
   */
  async function ActivacionesUsuario(usuarioID) {
    try {
      let response = await ObtenerActivaciones(usuarioID);
      let activaciones = response.data;
      if (activaciones) {
        activaciones.sort((a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion));
      }
      return activaciones ? activaciones : [];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

export default Index;