import React, { useCallback, useContext, useEffect, useState } from "react";
import { InformeMovimientosActivosContext } from ".";
import moment from 'moment';
import { getBienes } from "../../../services/request/requestBienes";
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
Highcharts.setOptions({
  lang: {
    months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    shortMonths: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    shortWeekdays: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"]
  }
});

export default function GraficoLineaBajas() {

  const { desde, hasta } = useContext(InformeMovimientosActivosContext);

  const [data, setData] = useState([]);

  const loadData = useCallback(async () => {
    try {
      setData([]);
      const data = [];
      const responseBienes = await getBienes({
        fecha_baja_lt: hasta,
        fecha_baja_gt: desde
      }, ["fecha_baja"],
        []);
      if (responseBienes.status) {
        for (let i = 0; i < responseBienes.data.length; i++) {
          const value = responseBienes.data[i];
          if (value.fecha_baja) {
            const fechaActivacion = moment(value.fecha_baja).startOf('day').valueOf();
            const fechaData = data.find(a => a[0] === fechaActivacion);
            if (fechaData) fechaData[1]++;
            else data.push([
              fechaActivacion,
              1
            ]);
          }
        }
      }
      setData(data.sort((a, b) => a[0] - b[0]));

    } catch (error) {
      console.error(error);
      throw error;
    }
  }, [desde, hasta]);



  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        title: {
          text: "Bajas Contables"
        },
        yAxis: {
          title: { text: null }
        },
        xAxis: {
          type: 'datetime'
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y:.1f}</b>'
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false,
              symbol: 'circle',
              radius: 2,
              states: {
                hover: {
                  enabled: true
                }
              }
            },
            pointStart: desde.getFullYear()
          }
        },
        series: [{
          data: data
        }]
      }}
    />
  );
}