import React, { createContext, useCallback, useEffect, useState } from "react";
import Moment from "moment";
import { AppBar, Container, Grid, makeStyles, Paper, Tab, Tabs, useMediaQuery, useTheme } from "@material-ui/core";
import { Fragment } from "react";
import { DatePicker } from "@material-ui/pickers";
import IndicadoresMovimientoMensual from "./indicadoresMovimientoMensual";
import TablaAsigMovimientoMensual from "./tablaAsigMovimientoMensual";
import TablaBajasMovimientoMensual from "./tablaBajasMovimientoMensual";
import { getAsignaciones } from "../../../services/request/requestSolicitudesAsignacion";
import { getBienes } from "../../../services/request/requestBienes";
import TablaActivacionesMovimientoMensual from "./tablaActivacionesMovimientoMensual";

export const MovimientoMensualContext = createContext({
  desde: Moment().startOf("month"), hasta: Moment().endOf("day"),
  totalSolicitudes: 0, setTotalSolicitudes: () => { },
  bienes: [],
  isLoading: true, setIsLoading: () => { }
});

const useStyles = makeStyles(theme => ({
  containerPickers: {
    padding: theme.spacing(1, 0, 1, 0)
  },
  paperPickers: {
    margin: theme.spacing(1, 0, 1, 0),
    borderRadius: "15px"
  },
  appBar: {
    borderRadius: "15px",
    margin: theme.spacing(1, 0, 1, 0),
  }
}));

function Index() {
  const [desde, setDesde] = useState(Moment().startOf("month"));
  const [hasta, setHasta] = useState(Moment().endOf("day"));

  const classes = useStyles();

  const theme = useTheme();
  const smBreak = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedTab, setSelectedTab] = useState(1);

  const [isLoading, setIsLoading] = useState(true);
  const [indicadores, setIndicadores] = useState({
    totalAsignaciones: 0,
    totalAsignacionesAceptadas: 0,
    totalAsignacionesPendientes: 0,
    totalBienes: 0
  });

  const getData = useCallback(async () => {
    try {
      setIndicadores({
        totalAsignaciones: 0,
        totalAsignacionesAceptadas: 0,
        totalAsignacionesPendientes: 0,
        totalBienes: 0
      });
      setIsLoading(true);
      const indicadores = {
        totalAsignaciones: 0,
        totalAsignacionesAceptadas: 0,
        totalAsignacionesPendientes: 0,
        totalBienes: 0
      }
      const responseAsignaciones = await getAsignaciones({
        fecha_creacion_lt: hasta.toDate(),
        fecha_creacion_gt: desde.toDate()
      }, ["fecha_creacion", "estado"]);
      const responseBienes = await getBienes({
        fecha_creacion_lt: hasta.toDate(),
        fecha_creacion_gt: desde.toDate()
      }, ["fecha_creacion"]);
      if (responseAsignaciones) {
        indicadores.totalAsignaciones = responseAsignaciones.length;
        indicadores.totalAsignacionesAceptadas = responseAsignaciones.filter(s => s.estado === "aceptado").length;
        indicadores.totalAsignacionesPendientes = responseAsignaciones.filter(s => s.estado === "pendiente").length;
      }
      if (responseBienes.status) {
        indicadores.totalBienes = responseBienes.data.length;
      }
      setIndicadores(indicadores);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, [desde, hasta]);

  useEffect(() => { getData(); },
    [getData]);

  return (
    <Fragment>
      <MovimientoMensualContext.Provider value={{
        desde, hasta,
        indicadores,
        isLoading, setIsLoading
      }}>
        <IndicadoresMovimientoMensual />
        <Paper className={classes.paperPickers}>
          <Container className={classes.containerPickers}>
            <Grid container alignItems="center" justify="center">
              <DatePicker
                label="Desde"
                value={desde}
                minDate={Moment("2010-01-01 00:00:00")}
                maxDate={hasta}
                format="dd/MM/yyyy"
                onChange={setDesde}
                inputVariant="outlined"
                size="small"
                style={{ margin: 5 }}
                okLabel="Aceptar"
                cancelLabel="Cancelar"
              />
              <DatePicker
                label="Hasta"
                value={hasta}
                minDate={desde}
                maxDate={Moment().endOf("day")}
                format="dd/MM/yyyy"
                onChange={setHasta}
                inputVariant="outlined"
                size="small"
                style={{ margin: 5 }}
                okLabel="Aceptar"
                cancelLabel="Cancelar"
              />
            </Grid>

          </Container>
        </Paper>

        <AppBar position="static" className={classes.appBar}>
          <Tabs value={selectedTab} onChange={(event, tabIndex) => setSelectedTab(tabIndex)}
            centered={!smBreak}
            variant={smBreak ? "scrollable" : "standard"}
            scrollButtons={smBreak ? "on" : "off"}
          >
            <Tab label="Activaciones" />
            <Tab label="Asignaciones" />            
            <Tab label="Bajas" />
          </Tabs>
        </AppBar>
        {selectedTab === 0 &&
          <TablaActivacionesMovimientoMensual />
        }
        {selectedTab === 1 &&
          <TablaAsigMovimientoMensual />
        }        
        {selectedTab === 2 &&
          <TablaBajasMovimientoMensual />
        }
      </MovimientoMensualContext.Provider>
    </Fragment>
  );
}



export default Index;