import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { MainContext } from '../../../../App';
import MaterialTable from '../../../../components/materialTable';
import { ASIGNAR_ACTIVOS_PERSONAS } from '../../../../constants/permissions';

function Page(props) {
  const { contratos } = props;

  const { permisos } = useContext(MainContext);
  const history = useHistory();

  const columns = [
    {
      title: 'Código',
      field: 'codigo',
      width: '200px',
    },
    {
      title: 'Nombre',
      field: 'nombre',
    },
    {
      title: 'Responsable',
      field: 'responsable_ref.persona.nombre_completo',
    },
    {
      title: 'Activos',
      field: 'cant_bienes',
      width: '100px',
    },
  ];

  const actions = [
    {
      icon: 'chevron_right',
      tooltip: 'Bienes',
      onClick: (event, row) => history.push(`/bienes-contrato/${row._id}`),
    },
  ];

  return (
    <MaterialTable
      title="Contratos y Activos"
      is_loading={!contratos}
      data={contratos}
      columns={columns}
      actions={permisos[ASIGNAR_ACTIVOS_PERSONAS] && actions}
    />
  );
}

export default Page;
