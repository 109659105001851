import React, { useEffect, useState, useContext } from "react";
import Page from "./page";
import { ObtenerBienesPorGerencia } from "../../../../services/request/requestBienes";
import { ObtenerGerencia } from "../../../../services/request/requestPortafolio";
import { useParams } from "react-router";
import { MainContext } from "../../../../App";

function Index(props) {
	const [GerenciaAsignado, SetGerenciaAsignado] = useState();
	const [Bienes, SetBienes] = useState();
	const [Bien, SetBien] = useState();
	const [IsOpen1, SetIsOpen1] = useState(false);
	const [IsOpen2, SetIsOpen2] = useState(false);
	const { id: gerenciaID } = useParams();
	const { usuarioSesion } = useContext(MainContext);

	useEffect(() => {
		if (gerenciaID) {
			ObtenerGerencia(gerenciaID)
				.then(gerencia => SetGerenciaAsignado(gerencia))
				.catch(error => {
					console.error(error);
					SetGerenciaAsignado(null);
					SetBienes([]);
				});
				
        ObtenerBienesPorGerencia(gerenciaID)
				.then(response => SetBienes(response.data))
				.catch(error => {
					console.error(error);
					SetBienes([]);
				});
		}
	}, [gerenciaID, IsOpen1, IsOpen2]);

	const handlePaso1 = () => {
		SetIsOpen1(true);
		SetIsOpen2(false);
	}

	const handlePaso2 = (bien) => {
		SetIsOpen1(false);
		SetIsOpen2(true);
		SetBien(bien);
	}

	const handleClose = () => {
		SetIsOpen1(false);
		SetIsOpen2(false);
	}

	return (
		<Page
			usuario_asignante={usuarioSesion}
			gerencia_asignado={GerenciaAsignado}
			bienes={Bienes}
			bien={Bien}
			is_open_1={IsOpen1}
			is_open_2={IsOpen2}
			handle_paso_1={handlePaso1}
			handle_paso_2={handlePaso2}
			handle_close={handleClose}
		/>
	);
}

export default Index;