import React, { useCallback, useContext, useEffect, useState } from 'react';
import Page from './page';
import { getGerencia, getSociedad } from '../../../services/request/requestPortafolio';
import { getPropietario } from '../../../services/request/requestPropietarios';
import { viewDriveFile } from '../../../services/fileUpload';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useParams } from 'react-router';
import { getSolicitudBaja, postAceptarBaja, postRechazarBaja } from '../../../services/request/requestSolicitudesBaja';
import { GenerarLog } from '../../../services/activoLog';
import moment from 'moment';
import useSWR from 'swr';
import { MainContext } from '../../../App';
import { APROBAR_TODO } from '../../../constants/permissions';

export default function VerSolicitudBaja() {
  const { id } = useParams();
  const { usuarioSesion, permisos } = useContext(MainContext);
  const { data: solicitudBaja } = useSWR(
    'get:solicitudes-baja',
    (key) => getSolicitudBaja(id, [], ['_bien_ref.contrato_ref', '_bien_ref._usuario_ref']),
    { refreshInterval: 0 },
  );
  const notistack = useSnackbar();
  const [isAutorizada, setIsAutorizada] = useState(false);
  const [title, setTitle] = useState('Solicitud Baja');
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [bien, setBien] = useState({
    sociedad: '',
    contrato: '',
    clase: '',
    marca: '',
    serial_number: '',
    modelo: '',
    condicion_actual: '',
    asignadoA: '',
    _clase_ref: {
      is_vehiculo: false,
      is_computacional: false,
    },
    motivo_baja: '',
    adjunto_baja: {
      nombre: '--',
    },
  });
  const [propietario, setPropietario] = useState({
    nombre: '',
  });
  const [factura, setFactura] = useState({
    archivo: {
      nombre: '--',
    },
    proveedor: {
      nombre: '--',
    },
    numero: '',
    valor: '',
    fecha: '',
  });

  const loadData = useCallback(async () => {
    setIsAutorizada(false);
    if (solicitudBaja) {
      const bien = solicitudBaja._bien_ref;
      const gerencia = await getGerencia(bien._gerencia_ref);
      const gerente =
        gerencia && gerencia.responsable_ref && gerencia.responsable_ref.persona
          ? gerencia.responsable_ref.persona.usuario_id
          : '';
      const sociedad = bien && bien._sociedad_ref ? await getSociedad(bien._sociedad_ref) : null;
      const propietario =
        bien && bien.activo && bien.activo._propietario_ref ? await getPropietario(bien.activo._propietario_ref) : null;
      setTitle(`Solicitud de baja para activo: ${bien ? bien.codigo_activo : '--'}`);
      const dataBien = {
        ...bien,
        sociedad: sociedad ? sociedad.nombre : 'Tercero',
        contrato: bien && bien._contrato_ref ? bien._contrato_ref.codigo : '--',
        asignadoA: bien && bien._usuario_ref ? bien._usuario_ref.nombre_completo : '--',
        clase: bien ? bien._clase_ref.nombre : '--',
      };
      const dataPropietario = propietario;
      if (solicitudBaja.estado === 'Pendiente') {
        if (permisos[APROBAR_TODO]) {
          setIsAutorizada(true);
        } else if (
          solicitudBaja.tipo === 'Responsable' &&
          solicitudBaja._usuario_revisor_ref &&
          solicitudBaja._usuario_revisor_ref.usuario_id === usuarioSesion.uid
        ) {
          setIsAutorizada(true);
        } else if (solicitudBaja.tipo === 'Gerente' && gerente && gerente === usuarioSesion.uid) {
          setIsAutorizada(true);
        } else if (solicitudBaja.tipo === 'TI' && usuarioSesion.is_ti) {
          setIsAutorizada(true);
        } else if (solicitudBaja.tipo === 'RFF' && usuarioSesion.is_rff) {
          setIsAutorizada(true);
        } else if (solicitudBaja.tipo === 'Registro Contable' && usuarioSesion.is_contabilidad) {
          setIsAutorizada(true);
        }
      }

      if (bien && bien.activo) {
        const dataFactura = {
          ...bien.activo.factura,
          fecha: bien.activo.factura ? moment(bien.activo.factura.fecha).format('DD/MM/YYYY') : '--/--/--',
          valor: bien.activo.valor,
        };
        setFactura(dataFactura);
      } else {
        setFactura({
          archivo: {
            nombre: '--',
          },
          proveedor: {
            nombre: '--',
          },
          numero: '',
          valor: '',
          fecha: '',
        });
      }
      setBien(dataBien);
      if (dataPropietario) {
        setPropietario(dataPropietario);
      } else {
        setPropietario({
          nombre: '--',
        });
      }
    }
  }, [solicitudBaja]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleVerArchivoFactura = async (archivo) => {
    const key = notistack.enqueueSnackbar('Descargando Archivo...', {
      persist: true,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    });
    try {
      const downloadUrl = archivo.url.replace(/ /g, '%20');
      await viewDriveFile(downloadUrl, archivo.nombre);
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar('Descarga completada', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
            <Close />
          </IconButton>
        ),
      });
    } catch (e) {
      console.log(e);
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar(
        'Error en descarga. Intente de nuevo o contacte con el equipo de soporte.',
        {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
              <Close />
            </IconButton>
          ),
        },
      );
    }
  };

  const handleDarDeBaja = async () => {
    const key = notistack.enqueueSnackbar('Actualizando Solicitud...', {
      persist: true,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    });
    setIsSubmiting(true);
    try {
      let registroLog = {
        _bien_ref: id,
        titulo: 'Solicitud de Baja',
        cuerpo: `La solicitud de baja del activo ${bien.codigo_activo} fue aceptada.`,
        tipo: 'solicitud_baja',
        icon: 'thumb_up',
      };
      await Promise.all([postAceptarBaja(id), GenerarLog(registroLog)]);
      notistack.closeSnackbar(key);
      setIsSubmiting(false);
      const successkey = notistack.enqueueSnackbar('Solicitud Aceptada', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
            <Close />
          </IconButton>
        ),
      });
    } catch (e) {
      console.log(e);
      notistack.closeSnackbar(key);
      setIsSubmiting(false);
      const successkey = notistack.enqueueSnackbar(
        'Error al actualizar solicitud. Intente de nuevo o contacte con el equipo de soporte.',
        {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
              <Close />
            </IconButton>
          ),
        },
      );
    }
  };

  const handleRechazarBaja = async (comentario) => {
    const key = notistack.enqueueSnackbar('Actualizando Solicitud...', {
      persist: true,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    });
    setIsSubmiting(true);
    try {
      let registroLog = {
        _bien_ref: id,
        titulo: 'Solicitud de Baja',
        cuerpo: `La solicitu de baja del activo ${bien.codigo_activo} fue rechazada.`,
        tipo: 'solicitud_baja',
        icon: 'thumb_down',
      };
      await Promise.all([postRechazarBaja(id, comentario), GenerarLog(registroLog)]);
      setIsSubmiting(false);
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar('Solicitud Rechazada', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
            <Close />
          </IconButton>
        ),
      });
    } catch (e) {
      console.log(e);
      notistack.closeSnackbar(key);
      setIsSubmiting(false);
      const successkey = notistack.enqueueSnackbar(
        'Error al actualizar solicitud. Intente de nuevo o contacte con el equipo de soporte.',
        {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
              <Close />
            </IconButton>
          ),
        },
      );
    }
  };

  return (
    <Page
      title={title}
      isSubmiting={isSubmiting}
      isAutorizada={isAutorizada}
      solicitudBaja={solicitudBaja}
      bien={bien}
      factura={factura}
      propietario={propietario}
      handleVerArchivoFactura={handleVerArchivoFactura}
      handleDarDeBaja={handleDarDeBaja}
      handleRechazarBaja={handleRechazarBaja}
    />
  );
}
