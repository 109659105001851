import React, { useCallback, useContext, useEffect, useState } from "react";
import MaterialTable from "../../../components/materialTable";
import { useHistory } from "react-router";
import { MovimientoMensualContext } from ".";
import XLSX from 'xlsx';
import moment from 'moment';
import { getBienes } from "../../../services/request/requestBienes";

export default function TablaActivacionesMovimientoMensual() {

  const { desde, hasta } = useContext(MovimientoMensualContext);

  const history = useHistory();

  let columns = [
    {
      title: "Fecha de la activación",
      field: "fecha_creacion",
      type: "datetime"
    },
    {
      title: "Código Activo",
      field: "codigo_activo",
    },
    {
      title: "Sociedad",
      field: "sociedad",
    },
    {
      title: "Gerencia",
      field: "gerencia",
    },
    {
      title: "Contrato",
      field: "contrato",
    },
    // {
    //   title: "Activado Por",
    //   field: "usuario",
    // }
  ];

  let actions = [
    {
      icon: "chevron_right",
      tooltip: "Ver Activo",
      onClick: (event, row) => history.push(`/activos/${row._bien_ref}`),
    },
  ];

  const handleExport = () => {
    const wb = XLSX.utils.book_new();
    const wbdata = data.map(s => ({
      "Fecha de la activación": moment(s.fecha_creacion).format("YYYY-MM-DD HH:mm:ss"),
      "Código Activo": s.codigo_activo,
      "Sociedad": s.sociedad,
      "Gerencia": s.gerencia,
      "Contrato": s.contrato,
      "Activado Por": s.usuario
    }));
    let ws = XLSX.utils.json_to_sheet(wbdata);
    XLSX.utils.book_append_sheet(wb, ws, "Movimiento Mensual");
    XLSX.writeFile(wb, "movimiento_mensual.xlsx");
  }

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadData = useCallback(async () => {
    try {
      setIsLoading(true);
      let bienes = [];
      const responseBienes = await getBienes({
        fecha_activacion_lt: hasta.toDate(),
        fecha_activacion_gt: desde.toDate()
      }, ["fecha_creacion", "fecha_baja", "codigo_activo", "fecha_activacion"],
        ["_gerencia_ref.sigla", "_sociedad_ref.nombre", "_contrato_ref.codigo"]);
      if (responseBienes.status) {
        bienes = responseBienes.data.map(b => ({
          fecha_creacion: b.fecha_creacion,
          fecha_baja: b.fecha_baja ? b.fecha_baja : null,
          fecha_activacion: b.fecha_activacion ? b.fecha_activacion : null,
          sociedad: b._sociedad_ref ? b._sociedad_ref.nombre : "--",
          gerencia: b._gerencia_ref ? b._gerencia_ref.sigla : "--",
          contrato: b._contrato_ref ? b._contrato_ref.codigo : "--",
          codigo_activo: b.codigo_activo
        }));
      }
      setData(bienes);
      setIsLoading(false);

    } catch (error) {
      console.error(error);
      setIsLoading(false);
      throw error;
    }
  }, [desde, hasta]);



  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <MaterialTable
      title="Movimientos de tipo activación contable"
      is_loading={isLoading}
      data={data}
      columns={columns}
      actions={actions}
      export_function={handleExport}
      exportButtonPDF={false}
    />
  );
}