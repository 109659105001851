import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import {
  deleteAsignacionLicenciaSoftware,
  getAsignacionesLicenciasSoftware,
  postLogLicenciaSoftware,
} from '../../../services/request/requestLicenciasSoftware';
import MaterialTable from '../../../components/materialTable';
import { MainContext } from '../../../App';
import { AGREGAR_LICENCIA_SOFTWARE, ELIMINAR_LICENCIA_SOFTWARE } from '../../../constants/permissions';
import { Fragment } from 'react';
import DialogoCRUDAsignacionLicenciaSoftware from './dialogoCRUDAsignacionSoftware';

export const TabLicenciasSoftwareActivoContext = createContext({
  refreshTable: () => {},
  openDialogo: false,
  closeDialogo: () => {},
});

export default function TabLicenciasSoftware(props) {
  const { id } = props;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { permisos, usuarioSesion } = useContext(MainContext);
  const [openDialog, setOpenDialog] = useState(false);

  const getData = useCallback(async () => {
    setData([]);
    setIsLoading(true);
    const response = await getAsignacionesLicenciasSoftware(
      { _bien_ref_eq: id },
      [],
      [
        '_licencia_software_ref.nombre',
        '_licencia_software_ref.version',
        '_licencia_software_ref.codigo_compra',
        '_licencia_software_ref.tipo',
        '_licencia_software_ref.fecha_vencimiento',
        '_licencia_software_ref.descripcion',
      ],
    );
    if (response.status) {
      const dataResponse = response.data.map((a) => ({
        _id: a._id,
        nombre: a._licencia_software_ref ? a._licencia_software_ref.nombre : '--',
        version: a._licencia_software_ref ? a._licencia_software_ref.version : '--',
        codigo_compra: a._licencia_software_ref ? a._licencia_software_ref.codigo_compra : '--',
        tipo: a._licencia_software_ref ? a._licencia_software_ref.tipo : '--',
        fecha_vencimiento: a._licencia_software_ref ? a._licencia_software_ref.fecha_vencimiento : null,
        descripcion: a._licencia_software_ref ? a._licencia_software_ref.descripcion : '--',
      }));
      setData(dataResponse);
      setIsLoading(false);
    }
  }, [id]);

  async function handleEliminar(row) {
    if (row) await deleteAsignacionLicenciaSoftware(row._id);
    await postLogLicenciaSoftware({
      _usuario_ref: usuarioSesion.ref,
      _licencia_software_ref: row._licencia_software_ref,
      descripcion: `Desasignación de Licencia de ${row.codigo_activo}`,
    });
    getData();
  }

  const actions = [
    {
      tooltip: 'Agregar',
      icon: 'add',
      isFreeAction: true,
      hidden: permisos && permisos[AGREGAR_LICENCIA_SOFTWARE] ? false : true,
      onClick: (event, row) => setOpenDialog(true),
    },
    {
      tooltip: 'Eliminar',
      icon: 'delete',
      hidden: permisos && permisos[ELIMINAR_LICENCIA_SOFTWARE] ? false : true,
      onClick: (event, row) => handleEliminar(row),
    },
  ];

  const columns = [
    {
      title: 'Nombre',
      field: 'nombre',
      defaultSort: 'desc',
    },
    {
      title: 'Versión',
      field: 'version',
    },
    {
      title: 'Código de Compra',
      field: 'codigo_compra',
    },
    {
      title: 'Tipo de Licencia',
      field: 'tipo',
    },
    {
      title: 'Fecha de Vencimiento',
      field: 'fecha_vencimiento',
      type: 'date',
    },
    {
      title: 'Descripción',
      field: 'descripcion',
    },
  ];

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Fragment>
      <TabLicenciasSoftwareActivoContext.Provider
        value={{
          refreshTable: () => getData(),
          openDialog,
          closeDialogo: () => setOpenDialog(false),
        }}
      >
        <MaterialTable
          title="Licencias de Software Asignadas"
          is_loading={isLoading}
          columns={columns}
          data={data}
          actions={actions}
          lockheaders={true}
        />
        <DialogoCRUDAsignacionLicenciaSoftware />
      </TabLicenciasSoftwareActivoContext.Provider>
    </Fragment>
  );
}
