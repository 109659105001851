import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  Tooltip
} from "@material-ui/core";
import MaterialTable from "@material-table/core";
import React, { useCallback, useEffect, useState } from "react";
import { ActualizarPerfil, ObtenerPerfil } from "../../../services/request/requestPerfiles";
import { ObtenerPermisos } from "../../../services/request/requestPermisos";
import { useHistory, useParams } from "react-router";
import { ArrowBack } from "@material-ui/icons";
import { AJUSTES_ADMINISTRACION } from '../../../constants/routes';
import ReactSelect from 'react-select';
const columns = [
  {
    title: 'Perfil',
    field: 'nombre',
    cellStyle: {
      textAlign: 'center'
    },
    defaultSort: 'asc'
  },
  {
    title: 'Descripción',
    field: 'descripcion'
  }
];

const localization = require('../../../data/material-table.location.json');


export default function ListadoPermisosPerfil() {
  const history = useHistory();
  const { id } = useParams();
  const [perfil, setPerfil] = useState(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [permisos, setPermisos] = useState([]);
  const [nuevoPermiso, setNuevoPermiso] = useState("");
  const cargarData = useCallback(async () => {
    setIsLoading(true);
    if (id) {
      const dataPerfil = await ObtenerPerfil(id, ["_permisos_ref"], ["_permisos_ref"]);
      const dataPermisos = await ObtenerPermisos();
      let listaPermisos = [];
      if (dataPermisos.data) {
        for (let i = 0; i < dataPermisos.data.length; i++) {
          const dataPermiso = dataPermisos.data[i];
          let agregar = true;
          for (let j = 0; j < dataPerfil._permisos_ref.length; j++) {
            if (dataPermiso._id === dataPerfil._permisos_ref[j]._id) {
              agregar = false;
              break;
            }
          }
          if (agregar) listaPermisos.push(dataPermiso);
        }
      }
      listaPermisos = listaPermisos.map(a=>({label: a.nombre, value: a._id}));
      setPermisos(listaPermisos);
      setData(dataPerfil._permisos_ref);
      setPerfil(dataPerfil);
      setIsLoading(false);
    } else {
      setPermisos([]);
      setData([]);
      setIsLoading(false);
      setPerfil(null);
    }
  }, [id]);

  const recargarData = async (id) => {
    setIsLoading(true);
    if (id) {
      const dataPerfil = await ObtenerPerfil(id, ["_permisos_ref"], ["_permisos_ref"]);
      const dataPermisos = await ObtenerPermisos();
      let listaPermisos = [];
      if (dataPermisos.data) {
        for (let i = 0; i < dataPermisos.data.length; i++) {
          const dataPermiso = dataPermisos.data[i];
          let agregar = true;
          for (let j = 0; j < dataPerfil._permisos_ref.length; j++) {
            if (dataPermiso._id === dataPerfil._permisos_ref[j]._id) {
              agregar = false;
              break;
            }
          }
          if (agregar) listaPermisos.push(dataPermiso);
        }
      }
      listaPermisos = listaPermisos.map(a=>({label: a.nombre, value: a._id}));
      setPermisos(listaPermisos);
      setData(dataPerfil._permisos_ref);
      setPerfil(dataPerfil);
      setIsLoading(false);
    } else {
      setPermisos([]);
      setData([]);
      setIsLoading(false);
      setPerfil(null);
    }
  };

  const handleAgregarPermiso = async (nuevos) => {
    // console.log(nuevos);
    const perfil = await ObtenerPerfil(id);
    for(let n =0; n < nuevos.length; n++){
      perfil._permisos_ref.push(nuevos[n].value);
    }
    await ActualizarPerfil({ _id: perfil._id, _permisos_ref: perfil._permisos_ref });
    setNuevoPermiso("");
    recargarData(id);
  }
  const handleQuitarPermiso = async (rowData) => {
    const perfil = await ObtenerPerfil(id);
    const _permisos_ref = perfil._permisos_ref.filter((a) => a !== rowData._id);
    await ActualizarPerfil({ _id: perfil._id, _permisos_ref: _permisos_ref });
    setNuevoPermiso("");
    recargarData(id);
  }
  const handleVolver = () => history.push(AJUSTES_ADMINISTRACION);


  const actions = [{
    tooltip: "Eliminar",
    icon: "delete",
    onClick: (event, row) => handleQuitarPermiso(row),
  }];



  useEffect(() => { cargarData() }, [cargarData]);

  return (
    <Container>
      <Card>
        <CardHeader
          title={`Pérfil de usuario: ${perfil ? perfil.nombre : ""}`}
          action={
            <Tooltip title="Volver">
              <IconButton onClick={handleVolver}>
                <ArrowBack></ArrowBack>
              </IconButton>
            </Tooltip>
          }
        />
        <CardContent>
          <form>

            <Grid container spacing={2}>
              <Grid item lg>
                <ReactSelect
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  searchable={true}
                  value={nuevoPermiso}
                  onChange={e => setNuevoPermiso(e)}
                  name="permiso"
                  placeholder="Elegir a un Permiso"
                  options={permisos}
                  noOptionsMessage={() => "No hay resultados"}
                  closeMenuOnSelect={false}
                  isMulti
                />
                {/* <FormControl fullWidth>
                  <InputLabel>Lista de permisos de usuario</InputLabel>
                  <Select
                    value={nuevoPermiso}
                    onChange={event => setNuevoPermiso(event.target.value)}>
                    <MenuItem value=""></MenuItem>
                    {permisos.map((value, index) =>
                      <MenuItem value={value._id} key={index}>{value.nombre}</MenuItem>
                    )}
                  </Select>
                </FormControl> */}
              </Grid>
              <Grid item lg>
                <Button color="primary" variant="contained" onClick={() => handleAgregarPermiso(nuevoPermiso)}>Agregar Permiso</Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
      <br />
      <MaterialTable
        title={`Permisos del pérfil de usuario`}
        isLoading={isLoading}
        data={data}
        columns={columns}
        actions={actions}
        options={{
          padding: "dense",
          exportButton: false,
          exportAllData: false,
          search: false,
          grouping: false,
          filtering: false,
          actionsColumnIndex: 0,
          headerStyle: {
            fontWeight: 'bold',
            color: "#731f1f",
            textAlign: "center",
          },
        }}
        localization={localization}
      />
    </Container>
  );
}