import React from "react";
import Moment from "moment";
import TitleCase from "titlecase";
import { useHistory } from "react-router";
import XLSX from "xlsx";
import MaterialTable from "../../../components/materialTable";

function MisActivaciones(props) {
	const {
		activaciones,
	} = props;

	const history = useHistory();

	/**
	 * Handler para exportar las activaciones pendientes.
	 */
	const handleExport = () => {
		const wb = XLSX.utils.book_new();
		const wbdata = activaciones.map(a => {
			return {
				"Código Activo": a._bien_ref.codigo_activo,
				"Código Contable": a._bien_ref.codigo_contable,
				"Marca": a._bien_ref.marca,
				"Modelo": a._bien_ref.modelo,
				"Clase": a._bien_ref._clase_ref.nombre,
				"Contrato": a._bien_ref._contrato_ref.nombre,
				"Valor": a._bien_ref.activo.valor,
				"Estado": a.estado,
				"Fecha Solicitud": a.fecha_creacion,
			};
		});
		let ws = XLSX.utils.json_to_sheet(wbdata);
		XLSX.utils.book_append_sheet(wb, ws, "Activos");
		XLSX.writeFile(wb, "activos.xlsx");
	}

	let columns = [
		{
			title: "Código Activo",
			field: "_bien_ref.codigo_activo",
		}, {
			title: "Código Contable",
			field: "_bien_ref.codigo_contable",
		}, {
			title: "Marca",
			field: "_bien_ref.marca",
		}, {
			title: "Modelo",
			field: "_bien_ref.modelo",
		}, {
			title: "Clase",
			field: "_bien_ref._clase_ref.nombre",
		}, {
			title: "Contrato",
			field: "_bien_ref._contrato_ref.nombre",
		}, {
			title: "Valor",
			field: "_bien_ref.activo.valor",
			render: (row) => Number(row._bien_ref.activo.valor).toLocaleString("es-ES", { style: "currency", currency: "CLP" }),
		}, {
			title: "Estado",
			field: "estado",
			render: (row) => TitleCase(row.estado),
		}, {
			title: "Fecha Solicitud",
			field: "fecha_creacion",
			render: (row) => Moment(row.fecha_creacion).format("DD/MM/YYYY HH:mm"),
		},
	];

	let actions = [
		{
			icon: "chevron_right",
			tooltip: "Asignar",
			onClick: (event, row) => history.push(`solicitudes-activacion/${row._id}`),
		}
	];

	return (
		<MaterialTable
			title="Solicitudes de Activación"
			is_loading={!activaciones}
			data={activaciones}
			columns={columns}
			actions={actions}
			export_function={handleExport}
		/>
	);
}

export default MisActivaciones;