import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router';
import MaterialTable from '../../../../components/materialTable';

function Page(props) {
  const { gerencias } = props;

  const [Registro, SetRegistro] = useState(null);
  const history = useHistory();

  const handleClick = (event, row) => {
    SetRegistro({
      anchor: event.currentTarget,
      row,
    });
  };

  /**
   * Handler para ver el detalle de una solicitud de asignación.
   */
  const handleAsignarActivos = () => {
    history.push(`/bienes-gerencia/${Registro.row._id}`);
  };

  /**
   * Handler para ver el detalle de una solicitud de asignación.
   */
  const handleVerContratos = () => {
    history.push(`/listado-contratos/${Registro.row._id}`);
  };

  const columns = [
    {
      title: 'Nombre',
      field: 'nombre',
    },
    {
      title: 'Sigla',
      field: 'sigla',
      width: '150px',
    },
    {
      title: 'Responsable',
      field: 'responsable_ref.persona.nombre_completo',
    },
    {
      title: 'Activos',
      field: 'cant_bienes',
      width: '100px',
    },
  ];

  const actions = [
    {
      icon: 'more_vert',
      tooltip: 'Opciones',
      onClick: handleClick,
    },
  ];

  return (
    <>
      <MaterialTable
        title="Gerencias y Activos"
        is_loading={!gerencias}
        data={gerencias}
        columns={columns}
        actions={actions}
      />
      <Menu
        anchorEl={Registro && Registro.anchor}
        keepMounted
        open={Boolean(Registro && Registro.anchor)}
        onClose={() => SetRegistro()}
      >
        <MenuItem onClick={handleAsignarActivos}>Bienes</MenuItem>
        <MenuItem onClick={handleVerContratos}>Contratos</MenuItem>
      </Menu>
    </>
  );
}

export default Page;
