import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import formatNumber from 'format-number';

const useStyles = makeStyles(theme => ({
  actionButton: {
    marginRight: theme.spacing(1)
  }
}));

const numberFormater = formatNumber({ integerSeparator: ".", decimal: ",", prefix: "$" });

export default function DialogoVistaPreviaFactura(props) {

  const { open, close, values, submit, siguienteAprobador, isSubmiting } = props;

  const classes = useStyles();

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={close}
    >
      <DialogTitle disableTypography>
        <Typography variant="h5" align="center">
          Revisión datos de Facturación
        </Typography>
        <Typography variant="h6" align="center">Verifique que la información de la Factura es correcta para enviarla al Gestor de Pagos</Typography>
      </DialogTitle>
      <DialogContent>

        <Grid container spacing={0}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Typography>
              <b>N°:</b> {values.numero_documento}
            </Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Typography>
              <b>Fecha del documento:</b> {moment(values.fecha_documento).format("DD/MM/YYYY")}
            </Typography>
          </Grid>
          <Grid
            item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Typography>
              <b>Proveedor:</b> {values.proveedor_nombre ? values.proveedor_nombre : ""}
            </Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Typography>
              <b>RUT:</b> {values.rut_proveedor ? values.rut_proveedor : ""}
            </Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Typography>
              <b>Contrato:</b> {values.contrato ? values.contrato.label : ""}
            </Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Typography>
              <b>Sociedad:</b> {values.sociedad_obj ? values.sociedad_obj.label : ""}
            </Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Typography>
              <b>Monto total:</b> {numberFormater(values.monto_documento)}
            </Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Typography>
              <b>Revisa:</b> {siguienteAprobador}
            </Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            {!values.dias_vencimiento &&
              <Typography><b>Condición de pago:</b> Al contado</Typography>}
            {values.dias_vencimiento > 0 &&
              <Typography><b>Condición de pago:</b> A {values.dias_vencimiento} días</Typography>
            }
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Typography>
              <b>Fecha Vencimiento:</b> {moment(values.fecha_vencimiento).format("DD/MM/YYYY")}
            </Typography>
          </Grid>
          <br />
          <br />
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h6">
              Información de pago del Proveedor recuperada desde el Gestor de Pagos
            </Typography>
          </Grid>
          {values.tipo_rendicion === "Pago directo a proveedor (Orden giro)" && values.cuenta_banco_usuario &&
            <Grid container spacing={0}>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography>
                  <b>Titular:</b> {values.cuenta_banco_usuario.titular_cuenta}
                </Typography>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography>
                  <b>RUT:</b> {values.cuenta_banco_usuario.rut_cuenta}
                </Typography>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography>
                  <b>Correo:</b> {values.cuenta_banco_usuario.correo}
                </Typography>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography>
                  <b>Banco:</b> {values.cuenta_banco_usuario.banco}
                </Typography>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography>
                  <b>Banco:</b> {values.cuenta_banco_usuario.tipo_cuenta}
                </Typography>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography>
                  <b>N° Cuenta:</b> {values.cuenta_banco_usuario.numero_cuenta}
                </Typography>
              </Grid>

            </Grid>
          }
          {values.tipo_rendicion === "Rendición con depósito" && values.cuenta_banco_usuario &&
            <Grid container spacing={0}>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography>
                  <b>Titular:</b> {values.cuenta_banco_usuario.titular_cuenta}
                </Typography>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography>
                  <b>RUT:</b> {values.cuenta_banco_usuario.rut_cuenta}
                </Typography>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography>
                  <b>Correo:</b> {values.cuenta_banco_usuario.correo}
                </Typography>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography>
                  <b>Banco:</b> {values.cuenta_banco_usuario.banco}
                </Typography>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography>
                  <b>Banco:</b> {values.cuenta_banco_usuario.tipo_cuenta}
                </Typography>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography>
                  <b>N° Cuenta:</b> {values.cuenta_banco_usuario.numero_cuenta}
                </Typography>
              </Grid>
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction='row' alignItems="center" justify="center">
          <Button onClick={close} variant="outlined" color="primary" className={classes.actionButton}>
            Cerrar
            </Button>
          <Button onClick={() => submit(values)} variant="contained" color="primary" className={classes.actionButton} disabled={isSubmiting}>
            Aceptar
            </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}