import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container, Grid, Tabs, Tab, Card, CardContent, CardHeader, IconButton, Tooltip } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { RegistroBienesContext } from '.';
import FormularioBasicoBienes from './formularioBasicoBienes';
import FormularioBienesVehiculos from './formularioBienesVehiculos';
import FormularioBienesComputacionales from './formularioBienesComputacionales';
import clsx from 'clsx';
import FormularioActivacion from './formularioActivacion';
import FormularioLaboratorioTopografia from './formularioLaboratorioTopografia';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(1, 1, 3, 1),
  },
  center: {
    textAlign: 'center',
  },
  main: {
    overflow: 'auto',
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  tableHeader: {
    textAlign: 'center',
    border: '1px solid #E0E0E0',
    fontWeight: 'bold',
    color: '#731f1f',
    position: 'sticky',
  },
  tableCell: {
    border: '1px solid #E0E0E0',
  },
  container: {
    paddingTop: theme.spacing(3),
    maxWidth: '100%',
  },
  hidden: {
    display: 'none',
  },
}));

export default function Page(props) {
  const { handleVolverActivos } = props;
  const classes = useStyles();
  const {
    selectedTab,
    setSelectedTab,
    showTabVehiculos,
    showTabComputacionales,
    showTabLabotorioTopografia,
    modoMultiplesActivos,
  } = useContext(RegistroBienesContext);
  return (
    <Card className={classes.main}>
      {/* <Grid container alignContent="center" justify="center">
				<Grid item lg md sm xs className={classes.center}>
					<Typography align="center" variant="h5" style={{ padding: "16px" }}>
						Registro de Activos
						</Typography>
				</Grid>
			</Grid> */}
      <CardHeader
        title={'Registro de Activos'}
        titleTypographyProps={{
          variant: 'h4',
          align: 'center',
        }}
        action={
          <Tooltip title="Volver a Lista de Activos">
            <IconButton onClick={handleVolverActivos}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <CardContent>
        <Grid container alignContent="center" justify="center">
          <Grid item lg md sm xs className={classes.center}>
            <Tabs
              indicatorColor="primary"
              value={selectedTab}
              onChange={(e, newValue) => setSelectedTab(newValue)}
              aria-label="content-tabs"
              variant="scrollable"
              scrollButtons="on"
            >
              <Tab label="Información Base" id="tabFormularioBasico" aria-controls="panelFormularioBasico" disabled />
              <Tab
                label="Vehículos"
                id="tabFormularioVehiculos"
                aria-controls="panelFormularioVehiculos"
                className={clsx({ [classes.hidden]: !showTabVehiculos || modoMultiplesActivos })}
                disabled
              />
              <Tab
                label="Eq. Computacionales"
                id="tabFormularioComputacionales"
                aria-controls="panelFormularioComputacionales"
                className={clsx({ [classes.hidden]: !showTabComputacionales || modoMultiplesActivos })}
                disabled
              />
              <Tab
                label="Eq. Laboratorio y Topografía"
                id="tabFormularioLaboratorioTopografia"
                aria-controls="panelFormularioLaboratorioTopografia"
                className={clsx({ [classes.hidden]: !showTabLabotorioTopografia || modoMultiplesActivos })}
                disabled
              />
              <Tab
                label="Facturación y Documentos"
                id="tabDocumentosAdjuntos"
                aria-controls="panelDocumentosAdjuntos"
                disabled
              />
            </Tabs>
          </Grid>
        </Grid>
        <Container className={classes.container}>
          {selectedTab === 0 && (
            <div id="panelFormularioBasico">
              <FormularioBasicoBienes></FormularioBasicoBienes>
            </div>
          )}
          {selectedTab === 1 && (
            <div id="panelFormularioVehiculos">
              <FormularioBienesVehiculos></FormularioBienesVehiculos>
            </div>
          )}
          {selectedTab === 2 && (
            <div id="panelFormularioComputacionales">
              <FormularioBienesComputacionales></FormularioBienesComputacionales>
            </div>
          )}
          {selectedTab === 3 && (
            <div id="panelFormularioLaboratorioTopografia">
              <FormularioLaboratorioTopografia />
            </div>
          )}
          {selectedTab === 4 && (
            <div id="panelDocumentosAdjuntos">
              <FormularioActivacion></FormularioActivacion>
            </div>
          )}
        </Container>
      </CardContent>
    </Card>
  );
}
