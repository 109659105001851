import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
  makeStyles,
  IconButton,
  Paper,
  Container,
  Tab,
  Tabs,
  Button,
  Tooltip
} from "@material-ui/core";
import { ArrowBack, Visibility as VisibilityIcon } from '@material-ui/icons';
import React, { useState } from "react";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import './style.css';
import { useHistory } from "react-router";
const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(1, 1, 3, 1)
  },
  center: {
    textAlign: "center",
  },
  main: {
    overflow: "auto",
    maxWidth: "95vw"
  },
  card: {
    marginBottom: theme.spacing(2)
  },
  tableHeader: {
    textAlign: 'center',
    border: "1px solid #E0E0E0",
    fontWeight: 'bold',
    color: '#731f1f',
    position: 'sticky'
  },
  tableCell: {
    border: "1px solid #E0E0E0"
  },
  btnMargin: {
    margin: theme.spacing(1)
  },
  fileInput: {
    top: 'unset',
    left: 'unset',
    position: 'inherit'

  },
  container: {
    paddingTop: theme.spacing(3),
    maxWidth: "100%"
  },
  right: {
    textAlign: "right"
  }
}));

export default function Page(props) {
  const {
    bien,
    factura,
    propietario,
    handleVerArchivoFactura,
    title,
    handleDarDeBaja,
    handleRechazarBaja,
    solicitudBaja,
    isAutorizada,
    isSubmiting
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(0);
  const [motivoRechazo, setMotivoRechazo] = useState("");
  return (
    <Container className={classes.main}>
      <Paper>


        <Grid container alignContent="center" justify="center">
          <Grid item lg={3}></Grid>
          <Grid item lg={6} className={classes.center}>
            <Typography align="center" variant="h5" style={{ padding: "16px 0px 0px 0px" }}>
              {title}
            </Typography>
            <Typography align="center" variant="h6" style={{ padding: "0px 0px 16px 0px" }}>
              Estado: {solicitudBaja && solicitudBaja.estado ? solicitudBaja.estado : ""}
            </Typography>
          </Grid>
          <Grid item lg={3} className={classes.right}>
            <Tooltip title="Volver">
              <IconButton onClick={() => history.goBack()} style={{ margin: '8px' }}>
                <ArrowBack></ArrowBack>
              </IconButton>
            </Tooltip>
          </Grid>

        </Grid>


        <Grid container alignContent="center" justify="center">
          <Grid item lg md sm xs className={classes.center}>
            <Tabs
              indicatorColor="primary"
              value={selectedTab}
              onChange={(e, newValue) => setSelectedTab(newValue)}
              aria-label="content-tabs"
              variant="scrollable"
              // centered
              scrollButtons="on"
            >
              <Tab label="Detalles" id="tabDetalles" aria-controls="panelDetalles" />
              <Tab label="Contabilidad" id="tabContabilidad" aria-controls="panelContabilidad" />
            </Tabs>
          </Grid>
        </Grid>
        <Container className={classes.container}>
          {selectedTab === 0 &&
            <div id="panelDetalles">
              <Grid container spacing={1}>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <TextField
                    label="Asignado A"
                    fullWidth
                    variant={"outlined"}
                    margin={"normal"}
                    value={bien.asignadoA}
                    disabled
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <TextField
                    name="sociedad"
                    label="Sociedad"
                    fullWidth
                    variant={"outlined"}
                    margin={"normal"}
                    value={bien.sociedad}
                    disabled
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <TextField
                    name="contrato"
                    label="Contrato"
                    fullWidth
                    variant={"outlined"}
                    margin={"normal"}
                    value={bien.contrato}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <TextField
                    name="clase"
                    label="Clase"
                    fullWidth
                    variant={"outlined"}
                    margin={"normal"}
                    value={bien.clase}
                    disabled
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <TextField
                    name="marca"
                    label="Marca"
                    fullWidth
                    variant={"outlined"}
                    margin={"normal"}
                    value={bien.marca}
                    disabled
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <TextField
                    label="Código S/N"
                    fullWidth
                    variant={"outlined"}
                    margin={"normal"}
                    value={bien.serial_number}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <TextField
                    label="Modelo"
                    fullWidth
                    variant={"outlined"}
                    margin={"normal"}
                    value={bien.modelo}
                    disabled
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <TextField
                    label="Condición Actual"
                    fullWidth
                    variant={"outlined"}
                    margin={"normal"}
                    value={bien.condicion_actual}
                    disabled
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <TextField
                    label="Propietario"
                    fullWidth
                    variant={"outlined"}
                    margin={"normal"}
                    value={propietario.nombre}
                    disabled
                  />
                </Grid>
              </Grid>
            </div>
          }
          {selectedTab === 1 &&
            <div id="panelContabilidad">
              <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    label="Código contable"
                    fullWidth
                    variant={"outlined"}
                    margin={"normal"}
                    value={bien.codigo_contable}
                    disabled
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    label="Proveedor"
                    fullWidth
                    variant={"outlined"}
                    margin={"normal"}
                    value={factura.proveedor ? factura.proveedor.nombre : ""}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    label="N° Factura"
                    fullWidth
                    variant={"outlined"}
                    margin={"normal"}
                    value={factura.numero}
                    disabled
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <CurrencyTextField
                    label="Monto Factura"
                    value={factura.valor}
                    fullWidth
                    variant="outlined"
                    margin={"normal"}
                    currencySymbol="$"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    leading="deny"
                    decimalPlaces={0}
                    decimalPlacesShownOnBlur={0}
                    decimalPlacesShownOnFocus={0}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    label="Fecha Factura"
                    fullWidth
                    variant={"outlined"}
                    margin={"normal"}
                    value={factura.fecha}
                    disabled
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="Ver Archivo"
                          onClick={() => handleVerArchivoFactura(factura.archivo)}
                          edge="end"
                          disabled={!factura.archivo || factura.archivo.url ? true : false}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </InputAdornment>,
                    }}
                    label="Factura"
                    fullWidth
                    variant={"outlined"}
                    margin={"normal"}
                    value={factura.archivo ? factura.archivo.nombre : ""}
                    disabled
                  />
                </Grid>
              </Grid>
            </div>
          }
        </Container>

      </Paper>

      <Paper style={{ marginTop: '8px', marginBottom: '8px' }}>
        <Container className={classes.main}>
          <Grid container spacing={1}>
            <Grid item lg={6} md={6} sm={6} xs={12} >
              <TextField
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="Ver Archivo"
                      onClick={() => handleVerArchivoFactura(bien.adjunto_baja)}
                      edge="end"
                      disabled={!bien.adjunto_baja || !bien.adjunto_baja.url ? true : false}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </InputAdornment>,
                }}
                InputLabelProps={{ shrink: true }}
                label="Respaldo de baja"
                fullWidth
                variant={"outlined"}
                margin={"normal"}
                value={bien.adjunto_baja ? bien.adjunto_baja.nombre : ""}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item lg={6} md={6} sm={6} xs={12} >
              <TextField
                label="Razón de baja"
                fullWidth
                variant={"outlined"}
                margin={"normal"}
                value={bien.motivo_baja}
                rows={2}
                multiline={true}
                disabled
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="comentario"
                label="Observaciones"
                fullWidth
                variant={"outlined"}
                margin={"normal"}
                value={motivoRechazo}
                rows={2}
                multiline={true}
                onChange={(e) => setMotivoRechazo(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container alignContent="center" justify="center">
            <Grid item lg md sm xs className={classes.center}>
              <Button disabled={!isAutorizada} className={classes.btnMargin} onClick={() => history.goBack()} color="primary" variant="outlined">
                Volver
            </Button>
              <Button disabled={!isAutorizada || isSubmiting} className={classes.btnMargin} onClick={() => handleRechazarBaja(motivoRechazo)} color="primary" variant="outlined">
                Rechazar
            </Button>
              <Button disabled={!isAutorizada || isSubmiting} className={classes.btnMargin} onClick={() => handleDarDeBaja()} color="primary" variant="contained">
                Autorizar Baja
            </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </Container>
  );
}