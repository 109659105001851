import React, { useContext, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { RegistroBienesContext } from '.';
import { Button, Grid, makeStyles, TextField, Typography } from '@material-ui/core';

const validationSchema = yup.object({});

const useStyles = makeStyles((theme) => ({
  btnMargin: {
    margin: theme.spacing(1),
  },
  center: {
    textAlign: 'center',
  },
}));

export default function FormularioLaboratorioTopografia() {
  const { setFormularioBasico, formularioBasico, setSelectedTab } = useContext(RegistroBienesContext);

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      usos: '',
      rango_medicion: '',
      grado_precision: '',
      periodo_recalibracion: '',
      tolerancias: '',
      accesorios: '',
    },
    onSubmit: async (values) => {
      setFormularioBasico({
        ...formularioBasico,
        usos: values.usos,
        rango_medicion: values.rango_medicion,
        grado_precision: values.grado_precision,
        periodo_recalibracion: values.periodo_recalibracion,
        tolerancias: values.tolerancias,
        accesorios: values.accesorios,
      });
      setSelectedTab(4);
    },
    validationSchema: validationSchema,
  });

  const { resetForm, errors, handleChange, touched, values, handleSubmit } = formik;

  const resetFormulario = useCallback(async () => {
    if (formularioBasico) {
      resetForm({
        values: {
          usos: formularioBasico.usos,
          rango_medicion: formularioBasico.rango_medicion,
          grado_precision: formularioBasico.grado_precision,
          periodo_recalibracion: formularioBasico.periodo_recalibracion,
          tolerancias: formularioBasico.tolerancias,
          accesorios: formularioBasico.accesorios,
        },
      });
    } else resetForm();
  }, [resetForm, formularioBasico]);

  const handleVolver = () => {
    setSelectedTab(0);
  };

  useEffect(() => {
    resetFormulario();
  }, [resetFormulario]);

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Typography variant="h5">Formulario de Equipos de Laboratorio/Topográficos</Typography>
      <Grid container spacing={1}>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <TextField
            label="Usos"
            fullWidth
            variant={'outlined'}
            margin={'normal'}
            name="usos"
            value={values.usos}
            onChange={handleChange}
            error={touched.usos && errors.usos && Boolean(errors.usos)}
            helperText={touched.usos && errors.usos ? errors.usos : ''}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <TextField
            label="Rango de medición"
            fullWidth
            variant={'outlined'}
            margin={'normal'}
            name="rango_medicion"
            value={values.rango_medicion}
            onChange={handleChange}
            error={touched.rango_medicion && errors.rango_medicion && Boolean(errors.rango_medicion)}
            helperText={touched.rango_medicion && errors.rango_medicion ? errors.rango_medicion : ''}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <TextField
            label="Grado de precisión"
            fullWidth
            variant={'outlined'}
            margin={'normal'}
            name="grado_precision"
            value={values.grado_precision}
            onChange={handleChange}
            error={touched.grado_precision && errors.grado_precision && Boolean(errors.grado_precision)}
            helperText={touched.grado_precision && errors.grado_precision ? errors.grado_precision : ''}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <TextField
            label="Período recalibración"
            fullWidth
            variant={'outlined'}
            margin={'normal'}
            name="periodo_recalibracion"
            value={values.periodo_recalibracion}
            onChange={handleChange}
            error={
              touched.periodo_recalibracion && errors.periodo_recalibracion && Boolean(errors.periodo_recalibracion)
            }
            helperText={
              touched.periodo_recalibracion && errors.periodo_recalibracion ? errors.periodo_recalibracion : ''
            }
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <TextField
            label="Tolerancias"
            fullWidth
            variant={'outlined'}
            margin={'normal'}
            name="tolerancias"
            value={values.tolerancias}
            onChange={handleChange}
            error={touched.tolerancias && errors.tolerancias && Boolean(errors.tolerancias)}
            helperText={touched.tolerancias && errors.tolerancias ? errors.tolerancias : ''}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <TextField
            label="Accesorios"
            fullWidth
            variant={'outlined'}
            margin={'normal'}
            name="accesorios"
            value={values.accesorios}
            onChange={handleChange}
            error={touched.accesorios && errors.accesorios && Boolean(errors.accesorios)}
            helperText={touched.accesorios && errors.accesorios ? errors.accesorios : ''}
          />
        </Grid>
        <Grid container alignContent="center" justify="center">
          <Grid item lg md sm xs className={classes.center}>
            <Button variant="outlined" className={classes.btnMargin} onClick={handleVolver}>
              Volver
            </Button>
            <Button type="submit" color="primary" variant="contained" className={classes.btnMargin}>
              Siguiente
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
