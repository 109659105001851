import React, { useState, Fragment, useCallback, useEffect, useContext }  from "react";
import { useHistory } from "react-router";
import MaterialTable from "../../../components/materialTable";
import { MTableAction } from '@material-table/core';
import { Button, Checkbox } from '@material-ui/core';
import { getmantencionesVehiculos } from "../../../services/request/requestMantencionVehiculo";
import DialogoRegistroMantenimiento from "../../gestionFlota/dialogoRegistroMantenimiento";
// import useSWR from 'swr';
import Moment from 'moment';
import { MainContext } from "../../../App";
import * as PERMISSIONS from '../../../constants/permissions';

export default function TablaMantenciones(props) {
  const { proveedores, bien } = props;
  const { permisos } = useContext(MainContext);
  const [activoSeleccionado, setActivoSeleccionado] = useState(null);  
  const [OpenRegistroMantenimiento, setOpenRegistroMantenimiento] = useState(false);
  const [datosMantenciones, setDatosMantenciones] = useState([]);
  //const { data: response } = useSWR("get:mantenciones-vehiculos", (key) => getData());
  const history = useHistory();

  function getData() {   
    return getmantencionesVehiculos({ _bien_ref_eq: bien._id, "is_eliminado_eq": false },
     ["_vehiculo_ref",
     "_bien_ref",
     "archivo",
     "reporte",
     "orden_trabajo_file",
     "folio",
     "estado",
     "fecha_creacion",
     "kilometraje",
     "fecha_ingreso",
     "fecha_entrega",
     "proveedor",
     "rut_proveedor",
     "otro_proveedor",
     "mantencion_programada",
     "tipo_documento",
     "tipo_rendicion",
     "numero_documento",
     "plazo_pago",
     "fecha_documento",
     "monto_documento",
     "monto_neto",
     "iva_documento",
     "descripcion_gasto",
     "orden_trabajo",
     "descripcion",
     "sistema_luces",
     "espejos",
     "llantas",
     "carroceria",
     "plumillas",
     "parabrisas",
     "neumaticos",
     "tablero",
     "calefaccion",
     "bocinas",
     "espejo_retrovisor",
     "cinturones",
     "radio",
     "air_bags",
     "gata",
     "kit_herramientas",
     "llantas_accesorios",
     "extintor",
     "triangulo_seguridad",
     "chaleco_reflectante",
     "radiador",
     "bateria",
     "filtros",
     "sistema_frenos",
     "correa_accesorios",
     "limpieza_dpf",
     "kit_embriague",
     "aceite_motor",
     "fluido_frenos",
     "refrigerante",
     "aceite_hidraulico",
     "otro",
     "otro_servicio"
    ], 
     [
       "_vehiculo_ref.patente",
       "_vehiculo_ref.tipo_combustible",
       "_bien_ref.codigo_activo",
       "_bien_ref.marca",
       "_bien_ref.modelo",       
       "_vehiculo_ref.transmision",
       "_vehiculo_ref.color",
       "_bien_ref._id",
       '_ingresado_por_ref.nombre',
       '_ingresado_por_ref.apellido_paterno',
       '_ingresado_por_ref.contacto',
       '_ingresado_por_ref.run',       
     ]);
   }  

  const loadData = useCallback(async () => {        
    setDatosMantenciones([]);

    const response  = await getData();

    if (response){
      if (response.status) {  
        const mantenciones = response.data.map(mantencion => {          
          const Exteriores = (mantencion.sistema_luces ? 'Sist. Luces, ': '') + (mantencion.espejos ? 'Espejos, ': '') + (mantencion.llantas ? 'Llantas, ': '') + (mantencion.carroceria ? 'Carroceria, ': '') + (mantencion.plumillas ? 'Plumillas, ': '') + (mantencion.parabrisas ? 'Parabrisas, ': '') + (mantencion.neumaticos ? 'Neumáticos ': '');
          const interiores = (mantencion.Tablero ? 'Tablero, ': '') + (mantencion.calefaccion ? 'Calefacción, ': '') + (mantencion.bocinas ? 'Bocinas, ': '') + (mantencion.espejo_retrovisor ? 'Espejo Retrovisor, ': '') + (mantencion.cinturones ? 'Cinturones, ': '') + (mantencion.radio ? 'Radio, ': '') + (mantencion.air_bags ? 'Air Bags ': '');
          const accesorios = (mantencion.gata ? 'Gata, ': '') + (mantencion.kit_herramientas ? 'Kit Herramientas, ': '') + (mantencion.llantas_accesorios ? 'Llantas, ': '') + (mantencion.extintor ? 'Extintor, ': '') + (mantencion.triangulo_seguridad ? 'Triángulo, ': '') + (mantencion.chaleco_reflectante ? 'Chaleco Reflectante ': '');
          const componentes_mecanicos = (mantencion.radiador ? 'Radiador, ': '') + (mantencion.bateria ? 'Batería, ': '') + (mantencion.filtros ? 'Filtros, ': '') + (mantencion.sistema_frenos ? 'Sist. Frenos, ': '') + (mantencion.correa_accesorios ? 'Correa Accesorios, ': '') + (mantencion.limpieza_dpf ? 'Limpieza DPF, ': '') + (mantencion.kit_embriague ? 'Kit Embrague ': '');
          const fluidos = (mantencion.aceite_motor ? 'Aceite Motor, ': '') + (mantencion.fluido_frenos ? 'Frenos, ': '') + (mantencion.refrigerante ? 'Refrigerante, ': '') + (mantencion.aceite_hidraulico ? 'Aceite Hidráulico ': '');
         
          return {
            ...mantencion,
            exteriores: Exteriores,
            interiores: interiores,
            accesorios: accesorios,
            componentes_mecanicos: componentes_mecanicos,
            fluidos: fluidos,
            registrado_por:
                mantencion._ingresado_por_ref && mantencion._ingresado_por_ref.nombre
                  ? mantencion._ingresado_por_ref.nombre + ' ' + mantencion._ingresado_por_ref.apellido_paterno
                  : '--',
          }
        });
       
        setDatosMantenciones(mantenciones);
      } 
    } 
  }, []);

  useEffect(() => {    
    loadData();
  }, [loadData]);

  function handleOpenRegistroMantenimiento(seleccionado, open) {      
    setActivoSeleccionado(seleccionado);  
    setOpenRegistroMantenimiento(open);
  }

  let columns = [
    {
      title: "Folio",
      field: "folio",
      align: "center",
    }, {
      title: "Fecha Creación Registro",
      field: "fecha_creacion",   
      align: "center",
      render: row => (row.fecha_creacion ? Moment(row.fecha_creacion).format('DD/MM/YYYY') : '--'),
      hidden: true,  
    },{
      title: "Ingresado por",
      field: "registrado_por", 
      align: "center",  
      hidden: true,   
    },{
      title: "Estado",
      field: "estado", 
      align: "center",     
    },{
      title: "Patente",
      field: "_vehiculo_ref.patente",
      align: "center",
    }, {
      title: "Código Activo",
      field: "_bien_ref.codigo_activo",
      align: "center",
    }, {
      title: "Marca",
      field: "_bien_ref.marca",
      align: "center",
    }, {
      title: "Modelo",
      field: "_bien_ref.modelo",
      align: "center",
    },  {
      title: "Transmisión",
      field: "_vehiculo_ref.transmision",
      align: "center",
      hidden: true,
    }, {
      title: "Kilometraje",
      field: "kilometraje",
      align: "center",
      render: (row) => row ? `${Intl.NumberFormat("es-ES").format(row.kilometraje)} Kms.` : "",      
    },{
        title: "Proveedor",
        field: "proveedor",   
        align: "center",   
    },{
        title: "Rut Proveedor",
        field: "rut_proveedor", 
        align: "center",     
    },
    // {
    //     title: "Fecha Ingreso",
    //     field: "fecha_ingreso", 
    //     align: "center",
    //     render: row => (row.fecha_ingreso ? Moment(row.fecha_ingreso).format('DD/MM/YYYY') : '--')     
    // },{
    //     title: "Fecha Entrega",
    //     field: "fecha_entrega",   
    //     align: "center",
    //     render: row => (row.fecha_entrega ? Moment(row.fecha_entrega).format('DD/MM/YYYY') : '--')   
    // },
    {
        title: "Tipo Documento",
        field: "tipo_documento",  
        align: "center",    
    },{
        title: "Numero Documento",
        field: "numero_documento",    
        align: "center",  
    },{
        title: "Plazo Pago",
        field: "plazo_pago", 
        align: "center", 
        hidden: true,    
    },{
        title: "Fecha Documento",
        field: "fecha_documento", 
        align: "center",
        render: row => (row.fecha_documento ? Moment(row.fecha_documento).format('DD/MM/YYYY') : '--')      
    },{
        title: "Monto Total",
        field: "monto_documento",  
        align: "center",
        render: row => `$${Intl.NumberFormat("es-ES").format(row.monto_documento)}`,    
    },{
        title: "Descripción",
        field: "descripcion",  
        align: "center",    
    },{
        title: "Mantención Programada",
        field: "mantencion_programada",    
        align: "center",  
    },{
      title: "Mantención Exterior",
      field: "exteriores",     
      align: "center",  
    },{
      title: "Mantención Interior",
      field: "interiores",   
      align: "center",   
  },{
    title: "Mantenimiento Accesorios",
    field: "accesorios",    
    align: "center",  
  },{
    title: "Componentes Mecánicos",
    field: "componentes_mecanicos",  
    align: "center",    
  },{
    title: "Mantenimiento Fluidos",
    field: "fluidos",    
    align: "center",  
  },{
    title: "Otro Mantenimiento",
    field: "otro_servicio",   
    align: "center",   
},{
    title: "Sistema de Luces",
    field: "sistema_luces", 
    align: "center",
    render: row => ( <Checkbox checked={row.sistema_luces} />),        
    hidden: true,     
},{
    title: "Espejos",
    field: "espejos",
    align: "center",
    render: row => ( <Checkbox checked={row.espejos} />),   
    hidden: true,   
  },{
    title: "Llantas",
    field: "llantas",  
    align: "center",
    render: row => ( <Checkbox checked={row.llantas} />),
    hidden: true,    
  },{
    title: "Carrocería",
    field: "carroceria", 
    align: "center",
    render: row => ( <Checkbox checked={row.carroceria} />), 
    hidden: true,    
  },{
    title: "Plumillas",
    field: "plumillas", 
    align: "center", 
    render: row => ( <Checkbox checked={row.plumillas} />),
    hidden: true,    
  },{
    title: "Parabrisas",
    field: "parabrisas",
    align: "center",
    render: row => ( <Checkbox checked={row.parabrisas} />),  
    hidden: true,    
  },{
    title: "Neumáticos",
    field: "neumaticos",  
    align: "center",
    render: row => ( <Checkbox checked={row.neumaticos} />),
    hidden: true,    
  },{
    title: "Radiador",
    field: "radiador", 
    align: "center", 
    render: row => ( <Checkbox checked={row.radiador} />),
    hidden: true,    
  },{
    title: "Batería",
    field: "bateria",  
    align: "center",
    render: row => ( <Checkbox checked={row.bateria} />),
    hidden: true,    
  },{
    title: "Filtros",
    field: "filtros", 
    align: "center", 
    render: row => ( <Checkbox checked={row.filtros} />),
    hidden: true,    
  },{
    title: "Sistema de Frenos",
    field: "sistema_frenos", 
    align: "center",
    render: row => ( <Checkbox checked={row.sistema_frenos} />), 
    hidden: true,    
  },{
    title: "Correa Accesorios",
    field: "correa_accesorios",  
    align: "center",
    render: row => ( <Checkbox checked={row.correa_accesorios} />),
    hidden: true,    
  },{
    title: "Limpieza DPF",
    field: "limpieza_dpf", 
    align: "center",
    render: row => ( <Checkbox checked={row.limpieza_dpf} />), 
    hidden: true,    
  },{
    title: "Kit de Embrague",
    field: "kit_embriague",  
    align: "center",
    render: row => ( <Checkbox checked={row.kit_embriague} />),
    hidden: true,    
  },
  ];

  let actions = [     
    {
      icon: "visibility",
      tooltip: "Ver Factura/Boleta",
      onClick: (event, rowData) => window.open(rowData.archivo.url, '_blank'), //handleVerArchivo(rowData.archivo),      
    }, 
    {
      icon: "preview",
      tooltip: "Ver Ficha",
      onClick: (event, rowData) => window.open(rowData.reporte.url, '_blank'), //handleVerArchivo(rowData.reporte),      
    }, 
    {
      icon: "description",
      tooltip: "Ver Orden Trabajo",
      onClick: (event, rowData) => window.open(rowData.orden_trabajo_file.url, '_blank'), //handleVerArchivo(rowData.reporte),      
    },
    // {
    //   icon: "edit",
    //   hidden: !permisos[PERMISSIONS.VER_BOTON_MANTENCION_VEHICULO], 
    //   tooltip: "Editar Mantenimiento",
    //   onClick: (event, rowData) => handleOpenRegistroMantenimiento(rowData, true),
    // },    
    {
      icon: 'registrar_mantencion',
      tooltip: 'Registrar Mantención',
      position: 'row', 
      hidden: !permisos[PERMISSIONS.VER_BOTON_MANTENCION_VEHICULO],     
      onClick: (event, row) => handleOpenRegistroMantenimiento(bien, true),
      isFreeAction: true,
    }
  ];

  return (
    <Fragment> 
      <MaterialTable
        title="Mantenciones Registradas"
        is_loading={!datosMantenciones}
        options={{
          filtering: false,
          pageSizeOptions: [5, 10, 20, 50],          
          grouping: false,
          columnsButton: true,
          maxBodyHeight: "70vh",
          headerStyle: {
            backgroundColor: "#731f1f",
            color: "#ffffff",
            textAlign: "center",
            position: 'sticky'
          },
          rowStyle: {
            border: "1px solid #E0E0E0"
          },
          padding: 'dense',
          actionsCellStyle: {
            margin: "0 auto",
            display: "flex",
            flexGrow: "1",
            justifyContent: "center",
            border: '1px solid #ffffff',
            padding: '6px 24px 6px 21px'
          },
          cellStyle: {
            border: "1px solid #E0E0E0",
          }
        }}        
        data={datosMantenciones}
        columns={columns}        
        grouping= {false}
        actions={actions}
        components={{
          Action: propData => {
            const rowData = propData.data;   
            if (propData.action.icon === 'visibility') {
              propData.action.hidden = !rowData.archivo;
              propData.action.disabled = !rowData.archivo;
            }                              
            if (propData.action.icon === 'preview') {
              propData.action.hidden = !rowData.reporte;
              propData.action.disabled = !rowData.reporte;
            }  
            if (propData.action.icon === 'description') {
              propData.action.hidden = !rowData.orden_trabajo_file;
              propData.action.disabled = !rowData.orden_trabajo_file;
            }                             
            if (propData.action.icon === 'registrar_mantencion') {
              return (
                !propData.action.hidden && (
                  <Button
                  onClick={(event) => propData.action.onClick(event, rowData)}
                    color="primary"
                    style={{
                      color: 'white',
                      backgroundColor: '#731f1f',
                      marginLeft: 5,
                      textTransform: 'none'
                    }}
                    variant="contained"
                    size="small"
                    >                  
                    {'Registrar Mantención'}
                  </Button>
                )
              );
            }
            return <MTableAction {...propData} />;
          }
        }}
      />
      {/* Cuadro de dialogo para los mantenimientos */}
      <DialogoRegistroMantenimiento
        activo_seleccionado={activoSeleccionado}
        proveedores={proveedores}
        is_open={OpenRegistroMantenimiento}
        handle_open={handleOpenRegistroMantenimiento}
      />  
    </Fragment>
  );
}