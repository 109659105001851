import React, { createContext, useState } from 'react';
import MaterialTable from '../../../components/materialTable';
import useSWR from 'swr';
import { ObtenerMarcas } from '../../../services/request/requestSeleccionables';
import { postMarca, putMarca, deleteMarca } from '../../../services/request/requestMarcas';
import { Fragment } from 'react';
import DialogoCRUDMarca from './dialogoCRUDMarca';
import { Check as CheckIcon, Close as CloseIcon } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { LISTADO_MODELOS_MARCA } from '../../../constants/routes';

export const TablaMarcasContext = createContext({
  openDialogoCRUD: false,
  setOpenDialogoCRUD: () => {},
  tituloDialogo: '',
  setTituloDialogo: () => {},
  nombre: '',
  setNombre: () => {},
  id: '',
  setId: () => {},
  handleAceptar: () => {},
  disableEditing: false,
  setDisableEditing: () => {},
  is_computacional: false,
  setIsComputacional: () => {},
  is_vehiculo: false,
  setIsVehiculo: () => {},
});

export default function TablaMarcas(props) {
  const { puedeAgregar, puedeEditar, puedeEliminar } = props;
  const history = useHistory();
  const { data: marcas, mutate } = useSWR('get:marcas', (key) => ObtenerMarcas());
  const [openDialogoCRUD, setOpenDialogoCRUD] = useState(false);
  const [tituloDialogo, setTituloDialogo] = useState('');
  const [nombre, setNombre] = useState('');
  const [id, setId] = useState('');
  const [disableEditing, setDisableEditing] = useState(false);
  const [is_computacional, setIsComputacional] = useState(false);
  const [is_vehiculo, setIsVehiculo] = useState(false);

  const handleOpenAgregar = (titulo) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setNombre('');
    setId('');
    setDisableEditing(false);
  };

  const handleOpenModificar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setNombre(row.nombre);
    setIsComputacional(row.is_computacional);
    setIsVehiculo(row.is_vehiculo);
    setDisableEditing(false);
    setId(row._id);
  };

  const handleOpenQuitar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setNombre(row.nombre);
    setIsComputacional(row.is_computacional);
    setIsVehiculo(row.is_vehiculo);
    setDisableEditing(true);
    setId(row._id);
  };

  const handleAceptar = async (titulo, values, id) => {
    if (titulo) {
      if (titulo.includes('Nuev')) await postMarca(values);
      else if (titulo.includes('Modificar')) await putMarca(id, values);
      else if (titulo.includes('Eliminar')) await deleteMarca(id);
    }
    mutate(ObtenerMarcas());
    setOpenDialogoCRUD(false);
  };

  const columnsMarcas = [
    {
      field: '_id',
      title: 'ID',
    },
    {
      title: 'Nombre',
      field: 'nombre',
      align: 'center',
      defaultSort: 'asc',
    },
    {
      title: 'Para vehículos',
      field: 'is_vehiculo',
      render: (rowData) => (rowData.is_vehiculo ? <CheckIcon /> : <CloseIcon />),
      align: 'center',
    },
    {
      title: 'Para equipos computacionales',
      field: 'is_computacional',
      render: (rowData) => (rowData.is_computacional ? <CheckIcon /> : <CloseIcon />),
      align: 'center',
    },
  ];

  return (
    <Fragment>
      <TablaMarcasContext.Provider
        value={{
          openDialogoCRUD,
          setOpenDialogoCRUD,
          tituloDialogo,
          setTituloDialogo,
          nombre,
          setNombre,
          id,
          setId,
          handleAceptar,
          disableEditing,
          setDisableEditing,
          is_computacional,
          setIsComputacional,
          is_vehiculo,
          setIsVehiculo,
        }}
      >
        <MaterialTable
          actions={[
            {
              tooltip: 'Agregar',
              icon: 'add',
              hidden: !puedeAgregar,
              onClick: (event, row) => handleOpenAgregar('Nuevo Marca'),
              isFreeAction: true,
            },
            {
              tooltip: 'Editar',
              icon: 'edit',
              hidden: !puedeEditar,
              onClick: (event, row) => handleOpenModificar('Modificar Marca', row),
            },
            {
              tooltip: 'Eliminar',
              icon: 'delete',
              hidden: !puedeEliminar,
              onClick: (event, row) => handleOpenQuitar('Eliminar Marca', row),
            },
            {
              tooltip: 'Ver Modelos',
              icon: 'list',
              onClick: (event, row) => history.push(LISTADO_MODELOS_MARCA.replace(':idMarca', row._id)),
            },
          ]}
          title="Marcas"
          is_loading={!marcas}
          data={marcas && marcas.data}
          columns={columnsMarcas}
          empty_rows={true}
          page_size={5}
        />
        <DialogoCRUDMarca />
      </TablaMarcasContext.Provider>
    </Fragment>
  );
}
