import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, Select, TextField, IconButton } from '@material-ui/core';
import { Close } from "@material-ui/icons";
import { useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LicenciasSoftwareContext } from './index';
import * as yup from 'yup';
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { deleteLicenciaSoftware, postLicenciaSoftware, putLicenciaSoftware, postLogLicenciaSoftware } from '../../services/request/requestLicenciasSoftware';
import { getVersionesSoftware, obtenerVersionesporMarca } from '../../services/request/requestVersionesSoftware';
import { getMarcasSoftware } from '../../services/request/requestMarcasSoftware';
import { MainContext } from '../../App';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import MaterialReactSelect from '../../components/materialReactSelect';
import { useSnackbar } from "notistack";

function NumberField(props) {
  const { name, label, error, touched, value, onChange, style } = props;

  return (
    <CurrencyTextField
      name={name}
      label={label}
      value={value}
      fullWidth
      variant="outlined"
      margin={"normal"}
      onChange={onChange}
      error={touched && error ? true : false}
      helperText={touched && error ? error : ""}
      currencySymbol=""
      minimumValue="0"
      maximumValue="10000000000"
      outputFormat="string"
      decimalCharacter=","
      digitGroupSeparator="."
      leading="deny"
      decimalPlaces={0}
      decimalPlacesShownOnBlur={0}
      decimalPlacesShownOnFocus={0}
      style={style ? style : {}}
    />
  );
}

const validationSchema = yup.object({
  //nombre: yup.string().required("Debe ingresar un nombre"),
  cantidad: yup.number().required("Deber ingresar una cantidad")
});

export default function DialogoCRUDLicenciaSoftware() {

  const {
    disableEditing,
    initialValues,
    openDialogoCRUD,
    refreshTable,
    setOpenDialogoCRUD,
    tituloDialogo,
    oldData
  } = useContext(LicenciasSoftwareContext);

  const { usuarioSesion } = useContext(MainContext);
  const [marcas, setMarcas] = useState([]);
  const [versiones, setVersiones] = useState([]);
  const handleClose = () => setOpenDialogoCRUD(false);
  const notistack = useSnackbar();

  const getVersionesporMarca = async (marca) => {
    obtenerVersionesporMarca(marca).then(response => {
      if (response && response.status === true) {
        setVersiones(response.data);        
      } else if (response.status === 204) {        
        setVersiones([]);
      } else {
        setVersiones([]);        
      }
    });
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      if (tituloDialogo) {
        if (tituloDialogo.includes("Nuev")) {
          const response = await postLicenciaSoftware({ ...values,
            nombre: values.marca.label,
            version: values.version_software.label ? values.version_software.label : null,
            _marca_ref: values.marca.value,
            _version_ref: values.version_software.value,
            _software_ref: values.version_software.agrupado
           });

          if (response.status) {
            await postLogLicenciaSoftware({
            _usuario_ref: usuarioSesion.ref,
            descripcion: "Creación de Licencia",
            _licencia_software_ref: response.data._id,
            });

            notistack.enqueueSnackbar("Nueva Licencia creada exitosamente.", {
              variant: "success",
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
              },
              action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
          }
        }
        else if (tituloDialogo.includes("Modificar")) {
          let descripcion = "Modificación de Licencia: \n";
          const data = {
            cantidad: values.cantidad,
            codigo_compra: values.codigo_compra,
            descripcion: values.descripcion,
            fecha_vencimiento: values.fecha_vencimiento,
            nombre: values.marca.label,
            orden_compra: values.orden_compra,
            tipo: values.tipo,
            version: values.version_software.label ? values.version_software.label : null,
            _version_ref: values.version_software.value ? values.version_software.value : null,
            _marca_ref: values.marca.value ? values.marca.value : null,           
            _software_ref: values.version_software.agrupado ? values.version_software.agrupado : null            
          };
          for (let key in data) {
            if(data[key] !== oldData[key]) descripcion += `- ${key}: ${data[key]} \n`;
          }
          await putLicenciaSoftware(values.id, data);
          await postLogLicenciaSoftware({
            _usuario_ref: usuarioSesion.ref,
            _licencia_software_ref: values.id,
            descripcion: descripcion
          });

          notistack.enqueueSnackbar("Licencia modificada exitosamente.", {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom"
            },
            action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
          });
        }
        else if (tituloDialogo.includes("Eliminar")) {
          await deleteLicenciaSoftware(values.id);
          await postLogLicenciaSoftware({
            _usuario_ref: usuarioSesion.ref,
            _licencia_software_ref: values.id,
            descripcion: "Eliminación de Licencia"
          });
          
          notistack.enqueueSnackbar("Licencia eliminada exitosamente.", {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom"
            },
            action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
          });
        }
      }
      setOpenDialogoCRUD(false);
      refreshTable();
    },
    validationSchema: validationSchema
  });

  const { handleChange, setFieldValue, setFieldTouched, errors, touched, values, handleSubmit, resetForm } = formik;

  const resetFormik = useCallback(() => {
    resetForm({ values: initialValues });
  }, [initialValues, resetForm]);

  const dateChange = (name, e) => {
    setFieldValue(name, e);
    setFieldTouched(name, true, false);
  };

  useEffect(() => {
    resetFormik();
  }, [resetFormik]);

  function handleOpenDialog(initialValues) {
    resetForm({ values: initialValues });
    getMarcasSoftware({}).then(response => setMarcas(response.data));
    getVersionesSoftware({}).then(response => setVersiones(response.data));
  }

  return (
    <Dialog open={openDialogoCRUD} onEntered={() => handleOpenDialog(initialValues)} maxWidth="lg" fullWidth>
      <DialogTitle>
        {tituloDialogo}
      </DialogTitle>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <MaterialReactSelect
                value={values.marca}
                label="Marca Software"
                isClearable
                onChange={(newValue, actionMeta) => {
                  if (newValue) {
                    setFieldValue("marca", newValue);                    
                    getVersionesporMarca(newValue.value);
                  }
                  else setFieldValue("marca", "");
                  setFieldValue("version_software", "");
                }}
                placeholder="Seleccione la Marca"
                options={marcas ? marcas.map((value, index) => ({
                  value: value._id,
                  label: value.marca,
                })) : []}
                noOptionsMessage={() => "No ha entrado la Marca del Software"}
                error={touched.marca && errors.marca && Boolean(errors.marca)}
                helperText={touched.marca && errors.marca ? errors.marca : ""}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <MaterialReactSelect
                value={values.version_software}
                label="Versión Software"
                isClearable
                onChange={(newValue, actionMeta) => newValue ? formik.setFieldValue("version_software", newValue) : formik.setFieldValue("version_software", "")}
                placeholder="Seleccione la Versión del Software"
                options={versiones ? versiones.map((value, index) => ({
                  value: value._id,
                  label: value.version,
                  agrupado: value._software_ref
                })) : []}
                noOptionsMessage={() => "No hay opciones de versiones ha desplegar"}
                error={touched.version_software && errors.version_software && Boolean(errors.version_software)}
                helperText={touched.version_software && errors.version_software ? errors.version_software : ""}
              />              
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <TextField
                label="Código de compra"
                name="codigo_compra"
                value={values.codigo_compra}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
                disabled={disableEditing}
                error={errors.codigo_compra && touched.codigo_compra ? true : false}
                helperText={errors.codigo_compra && touched.codigo_compra ? errors.codigo_compra : ""}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <FormControl
                variant="outlined"
                margin="normal"
                fullWidth
                error={touched.tipo && errors.tipo && Boolean(errors.tipo)}
              >
                <InputLabel>Tipo de licencia</InputLabel>
                <Select
                  native
                  name="tipo"
                  label="Tipo de licencia"
                  onChange={handleChange}
                  value={values.tipo}
                >
                  <option value=""></option>
                  <option value="Perpetua">Perpetua</option>
                  <option value="Arriendo">Arriendo</option>
                </Select>
                <FormHelperText>
                  {touched.tipo && errors.tipo ? errors.tipo : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  name="fecha_vencimiento"
                  autoOk
                  okLabel="Aceptar"
                  cancelLabel="Cancelar"
                  fullWidth
                  InputLabelProps={{
                    classes: {
                      shrink: "shrink"
                    }
                  }}
                  margin="normal"
                  inputVariant="outlined"
                  label="Fecha de Vencimiento"
                  format="dd/MM/yyyy"
                  helperText={touched.fecha_vencimiento ? errors.fecha_vencimiento : ""}
                  error={touched.fecha_vencimiento && Boolean(errors.fecha_vencimiento)}
                  onChange={dateChange.bind(null, "fecha_vencimiento")}
                  value={values.fecha_vencimiento}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <NumberField
                label="Cantidad total"
                name="cantidad"
                onChange={(event, value) => formik.setFieldValue("cantidad", value)}
                error={errors.cantidad}
                touched={touched.cantidad}
                value={values.cantidad}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <TextField
                label="Orden de compra"
                name="orden_compra"
                value={values.orden_compra}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
                disabled={disableEditing}
                error={errors.orden_compra && touched.orden_compra ? true : false}
                helperText={errors.orden_compra && touched.orden_compra ? errors.orden_compra : ""}
              />
            </Grid>
            <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
              <TextField
                label="Descripción"
                name="descripcion"
                margin="normal"
                value={values.descripcion}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                disabled={disableEditing}
                error={errors.descripcion && touched.descripcion ? true : false}
                helperText={errors.descripcion && touched.descripcion ? errors.descripcion : ""}
              />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}