import { useEffect, useState, useContext } from 'react';
import { ObtenerVehiculosGerenciaContratos } from '../services/request/requestVehiculos';
import { ObtenerClasesVehiculos } from '../services/request/requestClase';
import { VER_SELECCION_GERENCIAS, VER_SELECCION_CONTRATOS, VER_CONTRATOS_GERENCIA } from '../constants/permissions';
import { MainContext } from '../App';

export function useVehiculos(idContrato = null) {
  const { usuarioSesion, permisos, listadoContratosACargo } = useContext(MainContext);
  const [vehiculos, setVehiculos] = useState([]);

  async function VehiculosCyD(id_contrato = null) {
    try {
      let filter = '';
      if (!id_contrato) {
        if (usuarioSesion && usuarioSesion.gerencia) {
          //se comprueba si no posee el permiso para acceder a todas las gerencias
          if (!permisos[VER_SELECCION_GERENCIAS]) {
            if (!permisos[VER_CONTRATOS_GERENCIA]) {
              //Si posee 1 o mas contratos a cargo, se setea el primero en el desplegable de los contratos
              if (listadoContratosACargo && listadoContratosACargo.length > 0) {
                const contratosIDs = listadoContratosACargo.map((c) => `_contrato_ref_in=${c._id}`);
                filter = contratosIDs.join('&');
              } else {
                if (!permisos[VER_SELECCION_CONTRATOS]) {
                  if (usuarioSesion.contrato) {
                    filter = `&_contrato_ref_eq=${usuarioSesion.contrato}`;
                  }
                } else {
                  filter = `&_gerencia_ref_eq=${usuarioSesion.gerencia}`;
                }
              }
            } else {
              filter = `&_gerencia_ref_eq=${usuarioSesion.gerencia}`;
            }
          } else {
            filter = '';
          }
        }
      } else {
        filter = `&_contrato_ref_eq=${id_contrato}`;
      }

      const clases = await ObtenerClasesVehiculos();
      const vehiculos = await ObtenerVehiculosGerenciaContratos(filter, clases);

      let v_editados = [];
      if (vehiculos) {
        v_editados = vehiculos.map((vehiculo) => ({
          ...vehiculo,
          _asignado_ref:
            vehiculo._asignado_ref && vehiculo._asignado_ref.nombre
              ? vehiculo._asignado_ref.nombre + ' ' + vehiculo._asignado_ref.apellido_paterno
              : '--',
          mail_usuario:
            vehiculo._asignado_ref && vehiculo._asignado_ref.contacto ? vehiculo._asignado_ref.contacto.email : '--',
          rut_usuario: vehiculo._asignado_ref ? vehiculo._asignado_ref.run : '--',
          clase: vehiculo._clase_ref.nombre ? vehiculo._clase_ref.nombre : '--',
          contrato: vehiculo._contrato_ref?.nombre ? vehiculo._contrato_ref.nombre : '--',
          codigo_contrato: vehiculo._contrato_ref?.codigo ? vehiculo._contrato_ref.codigo : '--',
          fecha_termino_contrato: vehiculo._contrato_ref?.fecha_termino_estimada
            ? vehiculo._contrato_ref.fecha_termino_estimada
            : '--',
          color: vehiculo._vehiculo_ref?.color ? vehiculo._vehiculo_ref?.color : '--',
          patente: vehiculo._vehiculo_ref?.patente ? vehiculo._vehiculo_ref?.patente : '--',
          kilometraje: vehiculo._vehiculo_ref?.kilometraje ? vehiculo._vehiculo_ref?.kilometraje : '0',
          tipo_combustible: vehiculo._vehiculo_ref?.tipo_combustible ? vehiculo._vehiculo_ref?.tipo_combustible : '--',
          transmision: vehiculo._vehiculo_ref?.transmision ? vehiculo._vehiculo_ref?.transmision : '--',
          numero_chasis: vehiculo._vehiculo_ref?.numero_chasis ? vehiculo._vehiculo_ref?.numero_chasis : '--',
          numero_motor: vehiculo._vehiculo_ref?.numero_motor ? vehiculo._vehiculo_ref?.numero_motor : '--',
          traccion: vehiculo._vehiculo_ref?.traccion ? vehiculo._vehiculo_ref?.traccion : '--',
          ano: vehiculo._vehiculo_ref?.ano ? vehiculo._vehiculo_ref?.ano : '--',
          sigla_gerencia: vehiculo._gerencia_ref?.sigla ? vehiculo._gerencia_ref?.sigla : '--',
          gerencia: vehiculo._gerencia_ref?.nombre ? vehiculo._gerencia_ref?.nombre : '--',
          sociedad: vehiculo._sociedad_ref
            ? vehiculo._sociedad_ref.nombre
            : vehiculo._sociedad_tercero_ref
            ? vehiculo._sociedad_tercero_ref.sociedad
            : '--',
        }));
      }
      return v_editados ? v_editados : [];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  useEffect(() => {
    VehiculosCyD(idContrato)
      .then((vehiculos) => setVehiculos(vehiculos))
      .catch((error) => setVehiculos([]));
  }, [idContrato]);

  return { vehiculos };
}
