import React, { createContext, useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';
import Page from './page';
import { getLicenciasSoftware } from '../../services/request/requestLicenciasSoftware';
//import { getVersionesSoftware } from "../../services/request/requestVersionesSoftware";

export const LicenciasSoftwareContext = createContext({
  data: [],
  isLoading: true,
  refreshTable: () => { },
  openDialogoCRUD: false, setOpenDialogoCRUD: () => { },
  tituloDialogo: "",
  initialValues: {}, setInitialValues: () => { },
  disableEditing: false, setDisableEditing: () => { },
  oldData: {}
});

function getData() {
  return getLicenciasSoftware({}, 
    ["_id", "cantidad", "cantidad_utilizada", "codigo_compra", "descripcion", "orden_compra", "nombre", "tipo", "version", "fecha_vencimiento"],
    ["_marca_ref.marca", "_version_ref.version", "_software_ref.software"]);    
  }

export default function LicenciasSoftware() {

  const { data: response, mutate } = useSWR("get:licencias", () => getData(), { initialData: null });
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialogoCRUD, setOpenDialogoCRUD] = useState(false);
  const [tituloDialogo, setTituloDialogo] = useState("");
  const [initialValues, setInitialValues] = useState({
    cantidad: 0,
    codigo_compra: "",
    descripcion: "",
    fecha_vencimiento: null,
    nombre: "",
    tipo: "",
    version_software: "",
    marca: "",
    agrupacion: ""
  });
  const [disableEditing, setDisableEditing] = useState(false);
  const [oldData, setOldData] = useState({});
  const handleOpenAgregar = (rowData) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo("Nueva licencia de software");
    setInitialValues({
      cantidad: 0,
      codigo_compra: "",
      descripcion: "",
      fecha_vencimiento: null,
      nombre: "",
      orden_compra: "",
      tipo: "",
      version_software: "",
      marca: "",
      agrupacion: ""
    });
    setDisableEditing(false);
  }

  const handleOpenModificar = (rowData) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo("Modificar licencia de software");
    setOldData(rowData);
    setInitialValues({
      cantidad: rowData.cantidad,
      codigo_compra: rowData.codigo_compra,
      descripcion: rowData.descripcion,
      fecha_vencimiento: rowData.fecha_vencimiento,
      id: rowData._id,
      nombre: rowData.nombre,
      orden_compra: rowData.orden_compra,
      tipo: rowData.tipo,
      version_software: rowData._version_ref ? { value: rowData._version_ref._id, label: rowData._version_ref.version, agrupado: rowData._version_ref._software_ref } : "",
      marca: rowData._marca_ref ? { value: rowData._marca_ref._id, label: rowData._marca_ref.marca } : "",
      agrupacion: rowData._software_ref ? { value: rowData._software_ref._id, label: rowData._software_ref.software } : ""
    });
    setDisableEditing(false);
  }

  const handleOpenQuitar = (rowData) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo("Eliminar licencia de software");
    setOldData(rowData);
    setInitialValues({
      cantidad: rowData.cantidad,
      codigo_compra: rowData.codigo_compra,
      descripcion: rowData.descripcion,
      fecha_vencimiento: rowData.fecha_vencimiento,
      id: rowData._id,
      nombre: rowData.nombre,
      orden_compra: rowData.orden_compra,
      tipo: rowData.tipo,
      version_software: rowData._version_ref ? { value: rowData._version_ref._id, label: rowData._version_ref.version } : "",
      marca: rowData._marca_ref ? { value: rowData._marca_ref._id, label: rowData._marca_ref.marca } : "",
      agrupacion: rowData._software_ref ? { value: rowData._software_ref._id, label: rowData._software_ref.software } : ""
    });
    setDisableEditing(true);
  }

  const verifyResponse = useCallback(() => {
    setIsLoading(true);
    setData([]);
    if (response) {
      //console.log("Response Licencias: ", response);
      if (response.status) {
        setIsLoading(false);
        const licencias = response.data.map(a => {
          return {
            ...a,
            fecha_vencimiento_view: a.fecha_vencimiento,
            cantidad_disponible: a.cantidad_utilizada ? (a.cantidad - a.cantidad_utilizada) : a.cantidad,
            marca: a._marca_ref ? a._marca_ref.marca : "",
            agrupacion: a._software_ref ? a._software_ref.software : "",
            version_software: a._version_ref ? a._version_ref.version : ""  
          }
        });
        setData(licencias);
      } else {
        setIsLoading(false);
      }
    }
  }, [response]);

  useEffect(() => verifyResponse(), [verifyResponse]);

  return (
    <LicenciasSoftwareContext.Provider value={{
      data,
      isLoading,
      refreshTable: () => mutate(),
      openDialogoCRUD, setOpenDialogoCRUD,
      tituloDialogo,
      initialValues,
      handleOpenAgregar,
      handleOpenModificar,
      handleOpenQuitar,
      disableEditing,
      oldData
    }}>
      <Page />
    </LicenciasSoftwareContext.Provider>
  );

}