import {
  Grid,
  Typography,
  makeStyles,
  Paper,
  Container,
  Tab,
  Tabs,
  Button,
  Backdrop,
  CircularProgress,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
  Box,
} from '@material-ui/core';
import { ArrowBack, CheckCircle as CheckCircleIcon, Close } from '@material-ui/icons';
import { VerBienContext } from '.';
import DialogoActualizarKilometraje from '../../gestionFlota/dialogoActualizarKilometraje';
import DialogoActualizarInformacionActivo from './dialogoActualizarInformacion';
import DialogoActualizarContabilidad from './dialogoActualizarContabilidad';
import DialogoAgregarArchivo from './dialogoAgregarArchivo';
import React, { useContext, useState } from 'react';
import TabInfoBase from './tabInfoBase';
import TabContabilidad from './tabContabilidad';
import TablaDocumentosAdjuntos from './tablaAdjuntos';
import TablaAsignaciones from './tablaAsisgnaciones';
import TabLineaTiempo from './tabLineaTiempo';
import DialogoCondicionActual from './dialogoCondicionActual';
import DialogVerQR from './dialogVerQR';
import DialogoBaja from './dialogoBaja';
import DialogoRegresarActivo from './dialogoRegresarActivo';
import * as PERMISSIONS from '../../../constants/permissions';
import DialogoAsignar from './dialogoAsignar';
import { MainContext } from '../../../App';
import PropTypes from 'prop-types';
import DialogoSolicitarActivacion from './dialogoSolicitarActivacion';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import TabLicenciasSoftware from './tabLicenciasSoftware';
import TablaMantenciones from './tablaMantenciones';
import TablaEquipamiento from '../../gestionFlota/equipamiento/tablaEquipamiento';
import {
  postEliminarArchivoDrive,
  postGenerarHojaVidaEquipoLaboratorioTopografia,
  postGenerarHojaVidaVehiculo,
} from '../../../services/request/otros';
import { ObtenerActivoLogs } from '../../../services/request/requestActivoLog';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { downloadDriveFile } from '../../../services/fileUpload';
import DialogoActualizarClase from './dialogoActualizarClase';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(1, 1, 3, 1),
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  main: {
    overflow: 'auto',
    maxWidth: '95vw',
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  tableHeader: {
    textAlign: 'center',
    border: '1px solid #E0E0E0',
    fontWeight: 'bold',
    color: '#731f1f',
    position: 'sticky',
  },
  tableCell: {
    border: '1px solid #E0E0E0',
  },
  btnMargin: {
    margin: theme.spacing(1),
  },
  fileInput: {
    top: 'unset',
    left: 'unset',
    position: 'inherit',
    textAlign: 'left',
  },
  container: {
    paddingTop: theme.spacing(3),
    maxWidth: '100%',
  },
  fotos: {
    height: 300,
    maxHeight: 300,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  gridBordered: {
    border: '1px solid #dddddd',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff',
  },
  paper: {
    padding: '6px 16px',
  },
  hidden: {
    display: 'none',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Page(props) {
  const { bien, factura, propietario, proveedores, handleVerArchivoFactura, handleSubirFotoActual, title } = props;

  const { permisos } = useContext(MainContext);

  const {
    id,
    // setOpenActualizarKilometraje,
    setOpenActualizarInformacion,
    handleOpenDialogoCondicionActual,
    handleOpenDialogoActualizarClase,
    handleDialogActualizarContabilidad,
    handleDialogAgregarArchivo,
    handleDialogVerQR,
    handleDialogBaja,
    handleDialogRegresarActivo,
    handleDialogAsignar,
    setOpenDialogActivacion,
    is_loading,
    // permirtirBaja,
    // permirtirActivacion,
    tipo,
  } = useContext(VerBienContext);
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [activoSeleccionado, setActivoSeleccionado] = useState(null);
  const [OpenRegistroKilometraje, setOpenRegistroKilometraje] = useState(false);

  const history = useHistory();

  function volverPaginaAnterior() {
    history.goBack();
  }
  const theme = useTheme();

  const smBreak = useMediaQuery(theme.breakpoints.down('sm'));
  // const scrollableTabs = useMediaQuery(theme.breakpoints.down('md'));
  const notistack = useSnackbar();

  function handleOpenRegistroKilometraje(seleccionado, open) {
    console.log('Seleccionado: ', seleccionado);
    setActivoSeleccionado(seleccionado);
    setOpenRegistroKilometraje(open);
  }

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  async function generarHojaVida(bien, tipo) {
    const key = notistack.enqueueSnackbar('Descargando Archivo...', {
      persist: true,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    });
    try {
      if (tipo === 'Vehículo') {
        const data = {
          tipo: bien._clase_ref.nombre,
          empresa: bien.sociedad ? bien.sociedad : '',
          codigo_empresa: bien.codigo_sociedad ? bien.codigo_sociedad : '',
          patente: bien._vehiculo_ref ? bien._vehiculo_ref.patente : '',
          marca: bien.marca ? bien.marca : '',
          modelo: bien.modelo ? bien.modelo : '',
          anio: bien._vehiculo_ref && bien._vehiculo_ref.anio ? moment(bien._vehiculo_ref.anio).format('YYYY') : '',
          numero_motor: bien._vehiculo_ref ? bien._vehiculo_ref.numero_motor : '',
          numero_chasis: bien._vehiculo_ref ? bien._vehiculo_ref.numero_chasis : '',
          observaciones: bien.descripcion ? bien.descripcion.patente : '',
          hoja_vida: [],
        };
        const responseRegistros = await ObtenerActivoLogs(id);
        if (responseRegistros.status) {
          for (let i = 0; i < responseRegistros.data.length; i++) {
            data.hoja_vida.push({
              fecha: moment(responseRegistros.data[i].fecha_creacion).format('DD-MM-YYYY'),
              kilometraje: '',
              evento: `${responseRegistros.data[i].titulo} - ${responseRegistros.data[i].cuerpo}`,
              nombre_responsable: '',
            });
          }
        }
        const response = await postGenerarHojaVidaVehiculo(data);
        if (response && response.id) {
          await downloadDriveFile(
            `https://www.googleapis.com/drive/v3/files/${response.id}?alt=media`,
            `RPG12-02-03${bien.codigo_activo ? ' ' + bien.codigo_activo : ''}.pdf`,
          );
          await postEliminarArchivoDrive(response.id);
          notistack.closeSnackbar(key);
          const successkey = notistack.enqueueSnackbar('Descarga completada', {
            variant: 'success',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            action: (
              <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
                <Close />
              </IconButton>
            ),
          });
        }
      } else if (['Equipo Topográfico', 'Equipo Laboratorio', 'Otros instrumentos'].includes(tipo)) {
        const data = {
          codigo: bien.codigo_activo,
          tipo,
          descripcion: bien.descripcion ? bien.descripcion : '',
          marca: bien.marca ? bien.marca : '',
          modelo: bien.modelo ? bien.modelo : '',
          serial_number: bien.serial_number ? bien.serial_number : '',
          usos: bien.usos ? bien.usos : '',
          contrato: bien._contrato_ref ? bien.contrato.codigo : '',
          rango_medicion: bien.rango_medicion ? bien.rango_medicion : '',
          grado_precision: bien.grado_precision ? bien.grado_precision : '',
          periodo_recalibracion: bien.periodo_recalibracion ? bien.periodo_recalibracion : '',
          tolerancias: bien.tolerancias ? bien.tolerancias : '',
          accesorios: bien.accesorios ? bien.accesorios : '',
          hoja_vida: [],
        };
        const responseRegistros = await ObtenerActivoLogs(id);
        if (responseRegistros.status) {
          for (let i = 0; i < responseRegistros.data.length; i++) {
            data.hoja_vida.push({
              fecha: moment(responseRegistros.data[i].fecha_creacion).format('DD-MM-YYYY'),
              kilometraje: '',
              evento: `${responseRegistros.data[i].titulo} - ${responseRegistros.data[i].cuerpo}`,
              nombre_responsable: '',
            });
          }
        }
        const response = await postGenerarHojaVidaEquipoLaboratorioTopografia(data);
        if (response && response.id) {
          await downloadDriveFile(
            `https://www.googleapis.com/drive/v3/files/${response.id}?alt=media`,
            `RPG12-02-03${bien.codigo_activo ? ' ' + bien.codigo_activo : ''}.pdf`,
          );
          await postEliminarArchivoDrive(response.id);
          notistack.closeSnackbar(key);
          const successkey = notistack.enqueueSnackbar('Descarga completada', {
            variant: 'success',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            action: (
              <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
                <Close />
              </IconButton>
            ),
          });
        }
      } else {
        notistack.closeSnackbar(key);
        const successkey = notistack.enqueueSnackbar(
          'Error con el tipo del activo, contacte con el equipo de soporte.',
          {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            action: (
              <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
                <Close />
              </IconButton>
            ),
          },
        );
      }
    } catch (e) {
      console.log(e);
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar(
        'Error en descarga. Intente de nuevo o contacte con el equipo de soporte.',
        {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
              <Close />
            </IconButton>
          ),
        },
      );
    }
  }

  return (
    <Container className={classes.main}>
      <Paper>
        <Grid container alignContent="center" justify="center">
          <Grid item lg={4}></Grid>
          <Grid item lg={4} className={classes.center}>
            <Typography align="center" variant="h5" style={{ padding: '16px' }}>
              {title} {bien.is_activo_contable && <CheckCircleIcon></CheckCircleIcon>}
            </Typography>
          </Grid>
          <Grid item lg={4} className={classes.right}>
            <Tooltip title="Volver atrás">
              <IconButton onClick={volverPaginaAnterior} style={{ margin: '8px' }}>
                <ArrowBack></ArrowBack>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Tabs
          indicatorColor="primary"
          value={selectedTab}
          onChange={handleChange}
          aria-label="basic tabs"
          centered={!smBreak}
          variant={smBreak ? 'scrollable' : 'standard'}
          scrollButtons={smBreak ? 'on' : 'off'}
        >
          <Tab label="Información Base" id="tabDetalles" aria-controls="panelDetalles" value={0} {...a11yProps(0)} />
          <Tab
            label="Contabilidad"
            id="tabContabilidad"
            aria-controls="panelContabilidad"
            className={clsx({
              [classes.hidden]: !permisos || !permisos[PERMISSIONS.VER_PRECIO_ACTIVO] ? true : false,
            })}
            value={1}
            {...a11yProps(1)}
          />
          <Tab label="Adjuntos" id="tabAdjuntos" aria-controls="panelAdjuntos" value={2} {...a11yProps(2)} />
          {bien._clase_ref && bien._clase_ref.is_vehiculo && (
            <Tab
              label="Mantenciones"
              id="tabMantenciones"
              aria-controls="panelMantenciones"
              value={3}
              {...a11yProps(3)}
            />
          )}
          {bien._clase_ref && bien._clase_ref.is_vehiculo && (
            <Tab
              label="Equipamiento"
              id="tabEquipamiento"
              aria-controls="panelEquipamiento"
              value={4}
              {...a11yProps(4)}
            />
          )}
          <Tab
            label="Asignaciones"
            id="tabAsignaciones"
            aria-controls="panelAsignaciones"
            value={5}
            {...a11yProps(5)}
          />
          <Tab label="Línea de Tiempo" id="panelLineaTiempo" value={6} {...a11yProps(6)} />
          {bien._clase_ref && bien._clase_ref.is_computacional && (
            <Tab label="Listado de Software" id="panelListadoSoftware" value={7} {...a11yProps(7)} />
          )}
        </Tabs>

        <Container className={classes.container}>
          <TabPanel value={selectedTab} index={0}>
            <TabInfoBase bien={bien} propietario={propietario} handleSubirFotoActual={handleSubirFotoActual} />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <TabContabilidad
              bien={bien}
              factura={factura}
              handleVerArchivoFactura={handleVerArchivoFactura}
              handle_dialog_actualizar_contabilidad={handleDialogActualizarContabilidad}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <TablaDocumentosAdjuntos
              id={id}
              handleVerArchivo={handleVerArchivoFactura}
              handleDialogAgregarArchivo={handleDialogAgregarArchivo}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={3} hidden={bien._clase_ref && !bien._clase_ref.is_vehiculo}>
            <TablaMantenciones id={id} bien={bien} proveedores={proveedores} />
          </TabPanel>
          <TabPanel value={selectedTab} index={4} hidden={bien._clase_ref && !bien._clase_ref.is_vehiculo}>
            <TablaEquipamiento bien={bien} />
          </TabPanel>
          <TabPanel value={selectedTab} index={5}>
            <TablaAsignaciones id={id} />
          </TabPanel>
          <TabPanel value={selectedTab} index={6}>
            <TabLineaTiempo id={id} />
          </TabPanel>
          <TabPanel value={selectedTab} index={7} hidden={bien._clase_ref && !bien._clase_ref.is_computacional}>
            <TabLicenciasSoftware id={id} />
          </TabPanel>
        </Container>
      </Paper>
      <Paper
        style={{
          marginTop: '30px',
          marginBottom: '8px',
          padding: '8px 24px 8px 24px',
        }}
      >
        <Grid container alignContent="center" justify="center">
          <Grid item lg md sm xs className={classes.center}>
            {permisos && permisos[PERMISSIONS.VER_ACTUALIZAR_INFORMACION_ACTIVO] && (
              <Button
                className={classes.btnMargin}
                onClick={() => setOpenActualizarInformacion(true)}
                color="primary"
                variant="contained"
              >
                Actualizar Información
              </Button>
            )}
            {permisos && permisos[PERMISSIONS.EDITAR_CONDICONES_ACTIVOS] && (
              <Button
                className={classes.btnMargin}
                onClick={handleOpenDialogoCondicionActual}
                color="primary"
                variant="contained"
              >
                Actualizar Condición
              </Button>
            )}

            {permisos && permisos[PERMISSIONS.EDITAR_CLASE_ACTIVOS] && (
              <Button
                className={classes.btnMargin}
                onClick={handleOpenDialogoActualizarClase}
                color="primary"
                variant="contained"
              >
                Actualizar Clase
              </Button>
            )}

            {tipo === 'Vehículo' && (
              <Button
                className={classes.btnMargin}
                onClick={() => handleOpenRegistroKilometraje(bien, true)}
                color="primary"
                variant="contained"
              >
                Actualizar Kilometraje
              </Button>
            )}
            {['Vehículo', 'Equipo Topográfico', 'Equipo Laboratorio', 'Otros instrumentos'].includes(tipo) && (
              <Button
                className={classes.btnMargin}
                onClick={() => generarHojaVida(bien, tipo)}
                color="primary"
                variant="contained"
              >
                Hoja de vida
              </Button>
            )}
            {permisos && permisos[PERMISSIONS.VER_BAJA] && (
              <Button className={classes.btnMargin} onClick={handleDialogBaja} color="primary" variant="contained">
                Baja Contable
              </Button>
            )}
            {permisos && permisos[PERMISSIONS.VER_ACTIVAR] && (
              <Button
                className={classes.btnMargin}
                onClick={() => setOpenDialogActivacion(true)}
                color="primary"
                variant="contained"
              >
                Activación Contable
              </Button>
            )}
            <Button className={classes.btnMargin} onClick={handleDialogVerQR} color="primary" variant="contained">
              Ver Código QR
            </Button>
            {permisos && permisos[PERMISSIONS.REGRESAR_ACTIVO] && (
              <Button
                className={classes.btnMargin}
                onClick={handleDialogRegresarActivo}
                color="primary"
                variant="contained"
              >
                Regresar Activo
              </Button>
            )}
            {permisos && permisos[PERMISSIONS.ASIGNAR_ACTIVOS_PERSONAS] && (
              <Button
                className={classes.btnMargin}
                onClick={handleDialogAsignar}
                color="primary"
                variant="contained"
                disabled={!permisos || !permisos[PERMISSIONS.ASIGNAR_ACTIVOS_PERSONAS]}
              >
                Asignar Activo
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
      <DialogoActualizarInformacionActivo />
      {/* <DialogoActualizarKilometrajeActivo /> */}
      <DialogoActualizarKilometraje
        activo_seleccionado={activoSeleccionado}
        is_open={OpenRegistroKilometraje}
        handle_open={handleOpenRegistroKilometraje}
      />
      <DialogoActualizarContabilidad />
      <DialogoAgregarArchivo />
      <DialogoCondicionActual />
      <DialogoActualizarClase />
      <DialogVerQR />
      <DialogoSolicitarActivacion />
      <DialogoRegresarActivo />
      <DialogoAsignar />
      <DialogoBaja />
      <Backdrop className={classes.backdrop} open={is_loading}>
        <CircularProgress color="inherit" style={{ marginRight: 10 }} />
        <Typography variant="h6">Actualizando el Activo</Typography>
      </Backdrop>
    </Container>
  );
}
