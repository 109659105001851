import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useCallback, useContext, useEffect } from 'react';
import { TablaModelosContext } from './tablaModelos';
import * as yup from 'yup';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

const validationSchema = yup.object({
  nombre: yup.string().required(),
});

function NumberField(props) {
  const { name, label, error, touched, value, onChange } = props;

  return (
    <CurrencyTextField
      name={name}
      label={label}
      value={value}
      fullWidth
      variant="outlined"
      margin={'normal'}
      onChange={onChange}
      error={touched && error ? true : false}
      helperText={touched && error ? error : ''}
      currencySymbol="$"
      minimumValue="0"
      maximumValue="1000000000"
      outputFormat="string"
      decimalCharacter=","
      digitGroupSeparator="."
      leading="deny"
      decimalPlaces={0}
      decimalPlacesShownOnBlur={0}
      decimalPlacesShownOnFocus={0}
    />
  );
}

export default function DialogoCRUDModelo() {
  const { openDialogoCRUD, setOpenDialogoCRUD, tituloDialogo, handleAceptar, disableEditing, initialValues } =
    useContext(TablaModelosContext);

  const handleClose = () => setOpenDialogoCRUD(false);

  const formik = useFormik({
    initialValues,
    onSubmit: handleAceptar,
    validationSchema,
  });

  const { values, errors, touched, handleSubmit, handleChange, resetForm } = formik;

  const resetFormik = useCallback(() => {
    resetForm({ values: initialValues });
  }, [initialValues, resetForm]);

  useEffect(() => {
    resetFormik();
  }, [resetFormik]);

  return (
    <Dialog open={openDialogoCRUD} maxWidth="md" fullWidth>
      <DialogTitle>{tituloDialogo}</DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                name="nombre"
                label="Nombre"
                value={values.nombre}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                disabled={disableEditing}
                error={errors.nombre && touched.nombre ? true : false}
                helperText={errors.nombre && touched.nombre ? errors.nombre : ''}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <NumberField
                label="Valor Promedio Venta"
                name="valor_promedio_venta"
                onChange={(event, value) => formik.setFieldValue('valor_promedio_venta', value)}
                error={errors.valor_promedio_venta}
                touched={touched.valor_promedio_venta}
                value={values.valor_promedio_venta}
              />
            </Grid>
            <Grid item xs>
              <NumberField
                label="Valor Libro"
                name="valor_libro"
                onChange={(event, value) => formik.setFieldValue('valor_libro', value)}
                error={errors.valor_libro}
                touched={touched.valor_libro}
                value={values.valor_libro}
              />
            </Grid>
            <Grid item xs>
              <NumberField
                label="Precio Seguro"
                name="valor_seguro"
                onChange={(event, value) => formik.setFieldValue('valor_seguro', value)}
                error={errors.valor_seguro}
                touched={touched.valor_seguro}
                value={values.valor_seguro}
              />
            </Grid>
            <Grid item xs>
              <NumberField
                label="Precio Permiso Circulación"
                name="valor_permiso_circulacion"
                onChange={(event, value) => formik.setFieldValue('valor_permiso_circulacion', value)}
                error={errors.valor_permiso_circulacion}
                touched={touched.valor_permiso_circulacion}
                value={values.valor_permiso_circulacion}
              />
            </Grid>
            <Grid item xs>
              <NumberField
                label="Precio Inscripción"
                name="valor_inscripcion"
                onChange={(event, value) => formik.setFieldValue('valor_inscripcion', value)}
                error={errors.valor_inscripcion}
                touched={touched.valor_inscripcion}
                value={values.valor_inscripcion}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
