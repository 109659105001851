import axios from 'axios';
import { BACKEND } from '../../constants/urls'
/**
 * Obtener grupos-contables
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getGruposContables(filter = {}, cancelToken = null) {
  return axios.get(`${BACKEND}/grupos-contables/`,
    {
      params: filter,
      cancelToken: cancelToken
    }).then(res => res.data);
}
/**
 * Guardar GrupoContable
 * @param {*} data datos de GrupoContable
 * @param {*} cancelToken token para cancelacion
 */
export function postGrupoContable(data, cancelToken = null) {
  return axios.post(`${BACKEND}/grupos-contables/`, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data);
}
/**
 * Actualizar grupo contable
 * @param {String}  id
 * @param {Object} data datos del registro
 * @param {*} cancelToken token para cancelacion
 */
export function putGrupoContable(id, data, cancelToken = null) {
  return axios.put(`${BACKEND}/grupos-contables/${id}`, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data.data : null);
}

/**
 * Eliminar grupo contable
 * @param {String}  id
 * @param {*} cancelToken token para cancelacion
 */
export function deleteGrupoContable(id, cancelToken = null) {
  return axios.delete(`${BACKEND}/grupos-contables/${id}`,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data.data : null);
}