import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { MainContext } from "../../../App";
import MaterialTable from "../../../components/materialTable";
import { VER_DETALLE_BIEN_ASIGNADO } from "../../../constants/permissions";

function MisActivos(props) {
  const {
    bienes,
  } = props;

  const history = useHistory();
  const { permisos } = useContext(MainContext);
  const [actions, setActions] = useState([]);

  const revisarPermisos = useCallback(() => {
    if (permisos[VER_DETALLE_BIEN_ASIGNADO]) setActions([
      {
        icon: "chevron_right",
        tooltip: "Ver Detalles",
        onClick: (event, row) => history.push(`activos/${row._id}`)
      },
    ]);
    else setActions([]);
  }, [permisos, history]);

  useEffect(() => {
    revisarPermisos();
  }, [revisarPermisos]);

  let columns = [
    {
      title: "Código Activo",
      field: "codigo_activo",
    }, {
      title: "Condición Actual",
      field: "condicion_actual",
    }, {
      title: "Clase",
      field: "_clase_ref.nombre"
    }, {
      title: "N° Serie",
      field: "serial_number",
      hidden: true,
      // hiddenByColumnsButton: true,
    }, {
      title: "Marca",
      field: "marca",
    }, {
      title: "Modelo",
      field: "modelo",
    }, {
      title: "Ubicación",
      field: "ubicacion",
    }, {
      title: "Asignado Por",
      field: "asignado_por",
    }, {
      title: "Gerencia",
      field: "_gerencia_ref.nombre",
      hidden: true,
      // hiddenByColumnsButton: true,
    }, {
      title: "Contrato",
      field: "_contrato_ref.nombre",
      hidden: true,
      // hiddenByColumnsButton: true,
    },
  ];

  return (
    <MaterialTable
      title="Activos Asignados"
      is_loading={!bienes}
      data={bienes}
      columns={columns}
      actions={actions}      
    />

  );
}

export default MisActivos;