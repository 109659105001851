import React, { createContext, useState } from 'react';
import MaterialTable from '../../../components/materialTable';
import useSWR from 'swr';
import { Fragment } from 'react';
import { useHistory } from 'react-router';
import { AJUSTES_CONFIGURACION } from '../../../constants/routes';
import {
  deleteGrupoContable,
  getGruposContables,
  postGrupoContable,
  putGrupoContable,
} from '../../../services/request/requestGruposContables';
import DialogoCRUDGrupoContable from './dialogoCRUDGrupoContable';

export const TablaGruposContablesContext = createContext({
  openDialogoCRUD: false,
  setOpenDialogoCRUD: () => {},
  tituloDialogo: '',
  setTituloDialogo: () => {},
  initialValues: '',
  setInitialValues: () => {},
  handleAceptar: () => {},
  disableEditing: false,
  setDisableEditing: () => {},
});

export default function TablaGruposContables() {
  const { data: grupos, mutate } = useSWR('get:grupos_contables', (key) => getGruposContables(), {
    refreshInterval: 0,
  });
  const [openDialogoCRUD, setOpenDialogoCRUD] = useState(false);
  const [tituloDialogo, setTituloDialogo] = useState('');
  const [initialValues, setInitialValues] = useState({
    nombre: '',
    grupo: '',
    sigla: '',
  });
  const [disableEditing, setDisableEditing] = useState(false);
  const history = useHistory();

  const handleOpenAgregar = (titulo) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      nombre: '',
      grupo: '',
      sigla: '',
    });
    setDisableEditing(false);
  };

  const handleOpenModificar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      nombre: row.nombre,
      grupo: row.grupo,
      sigla: row.sigla,
      id: row._id,
    });
    setDisableEditing(false);
  };

  const handleOpenQuitar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      nombre: row.nombre,
      grupo: row.grupo,
      sigla: row.sigla,
      id: row._id,
    });
    setDisableEditing(true);
  };

  const handleAceptar = async (values) => {
    if (tituloDialogo) {
      if (tituloDialogo.includes('Nuev')) await postGrupoContable({ ...values });
      else if (tituloDialogo.includes('Modificar'))
        await putGrupoContable(values.id, {
          nombre: values.nombre,
          grupo: values.grupo,
          sigla: values.sigla,
        });
      else if (tituloDialogo.includes('Eliminar')) await deleteGrupoContable(values.id);
    }
    mutate();
    setOpenDialogoCRUD(false);
  };

  const handleVolver = () => {
    history.push(AJUSTES_CONFIGURACION);
  };

  const columnsModelos = [
    {
      title: 'Nombre',
      field: 'nombre',
      align: 'center',
      defaultSort: 'asc',
    },
    {
      title: 'Grupo',
      field: 'grupo',
      align: 'center',
    },
    {
      title: 'Sigla',
      field: 'sigla',
      align: 'center',
    },
  ];

  return (
    <Fragment>
      <TablaGruposContablesContext.Provider
        value={{
          openDialogoCRUD,
          setOpenDialogoCRUD,
          tituloDialogo,
          setTituloDialogo,
          initialValues,
          setInitialValues,
          handleAceptar,
          disableEditing,
          setDisableEditing,
        }}
      >
        <MaterialTable
          actions={[
            {
              tooltip: 'Agregar',
              icon: 'add',
              onClick: (event, row) => handleOpenAgregar('Nuevo Grupo contable'),
              isFreeAction: true,
            },
            {
              tooltip: 'Editar',
              icon: 'edit',
              onClick: (event, row) => handleOpenModificar('Modificar Grupo contable', row),
            },
            {
              tooltip: 'Eliminar',
              icon: 'delete',
              onClick: (event, row) => handleOpenQuitar('Eliminar Grupo contable', row),
            },
            {
              tooltip: 'Volver',
              icon: 'arrow_back',
              onClick: (event, row) => handleVolver(),
              isFreeAction: true,
            },
          ]}
          title="Grupos contables"
          is_loading={!grupos}
          data={grupos && grupos.data}
          columns={columnsModelos}
          filtering={false}
          grouping={false}
          // searching={false}
          empty_rows={true}
          page_size={20}
        />
        <DialogoCRUDGrupoContable />
      </TablaGruposContablesContext.Provider>
    </Fragment>
  );
}
