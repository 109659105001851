import React, { useContext } from 'react';
import { InformeMovimientosActivosContext } from '.';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

export default function GraficoTipoAsignaciones() {

  const { dataGraficoTipoAsignaciones } = useContext(InformeMovimientosActivosContext);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        chart: {
          type: 'pie',
        },
        title: {
          text: "Tipo de asignación de activos"
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'bottom',
          borderWidth: 1,

        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y:.1f}</b>'
        },
        series: [{
          dataLabels: {
            enabled: true,
            format: '{point.percentage:.1f}%',
            distance: -30,
            filter: {
              property: 'percentage',
              operator: '>',
              value: 4
            }
          },
          showInLegend: true,
          minPointSize: 10,
          zMin: 0,
          data: dataGraficoTipoAsignaciones
        }]
      }}
    />);
}