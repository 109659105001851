import React, { useState } from "react";
import { AppBar, Box, Tabs, Tab } from "@material-ui/core";
import TabRoles from "./tabRoles";
import TabPerfiles from "./tabPerfiles";
import TabPermisos from "./tabPermisos";
import TabUsuariosPlataforma from './tabUsuariosPlataforma';

function Page(props) {
	const [TabIndex, SetTabIndex] = useState(0);

	return (
		<div>
			<AppBar position="static">
				<Tabs value={TabIndex} onChange={(event, tabIndex) => SetTabIndex(tabIndex)}>
					<Tab label="Usuarios Plataforma"></Tab>
					<Tab label="Roles" />
					<Tab label="Perfiles" />
					<Tab label="Permisos" />
				</Tabs>
			</AppBar>
			<TabPanel value={TabIndex} index={0}>
				<TabUsuariosPlataforma />
			</TabPanel>
			<TabPanel value={TabIndex} index={1}>
				<TabRoles />
			</TabPanel>
			<TabPanel value={TabIndex} index={2}>
				<TabPerfiles />
			</TabPanel>
			<TabPanel value={TabIndex} index={3}>
				<TabPermisos />
			</TabPanel>
		</div>
	);
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div role="tabpanel" hidden={value !== index} {...other}>
			{value === index && (
				<Box p={1}>
					{children}
				</Box>
			)}
		</div>
	);
}

export default Page;