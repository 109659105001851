import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import MaterialTable from '../../../components/materialTable';
import { getDocumentoAdjunto } from '../../../services/request/requestBienes';
import { red, yellow, green } from '@material-ui/core/colors/';
import { useHistory } from 'react-router';
import { VER_ACTIVO } from '../../../constants/routes';
import {
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core';

const localization = require('../../../data/material-table.location.json');

const useStyles = makeStyles((theme) => ({
  containerFiltros: {
    padding: theme.spacing(2),
  },
  paperFiltros: {
    margin: theme.spacing(0, 0, 2, 0),
  },
}));

export default function DocumentosPorVencer() {
  const [data, setData] = useState([]);
  const [documentosVehiculos, setDocumentosVehiculos] = useState([]);
  const [documentosComputadores, setDocumentosComputadores] = useState([]);
  const [documentosTopografia, setDocumentosTopografia] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const classes = useStyles();

  const handleVerActivo = (rowData) => {
    history.push(VER_ACTIVO.replace(':id', rowData._bien_ref));
  };
  const [filtros, setFiltros] = useState({
    estados: ['VENCIDO', 'POR VENCER', 'FALTANTE', 'VIGENTE'],
  });

  let actions = [
    {
      icon: 'chevron_right',
      tooltip: 'Ver Activo',
      onClick: (evt, rowData) => handleVerActivo(rowData),
    },
  ];

  const columns = [
    {
      field: 'codigo_activo',
      title: 'Código Activo',
    },
    {
      field: 'tipo',
      title: 'Tipo',
    },
    {
      field: 'contrato',
      title: 'Contrato',
    },
    {
      field: 'estado',
      title: 'Estado',
    },
    {
      field: 'fecha',
      title: 'Fecha Documento',
      type: 'date',
    },
    {
      field: 'fecha_proxima_carga',
      title: 'Fecha Vencimiento',
      type: 'date',
    },
    {
      field: 'municipalidad',
      title: 'Municipalidad',
    },
    {
      field: 'valor_permiso_circulacion',
      title: 'Valor Permiso Circulación',
      align: 'center',
      render: (row) => `$${Intl.NumberFormat('es-ES').format(row.valor_permiso_circulacion)}`,
    },
  ];

  const estados = ['VENCIDO', 'POR VENCER', 'FALTANTE', 'VIGENTE'];

  const [pageSizes, setPageSizes] = useState([10, 20, 50]);
  const [pageSize, setPageSize] = useState(pageSizes[1]);

  const loadData = useCallback(async () => {
    setIsLoading(true);
    setData([]);
    setPageSizes([1]);
    setPageSize(pageSizes[0]);
    const response = await getDocumentoAdjunto(
      { is_renovado_eq: false },
      [
        'fecha',
        'fecha_proxima_carga',
        'tipo',
        'is_renovado',
        'archivo',
        'municipalidad',
        'valor_permiso_circulacion',
        'valor_prima_seguro',
        'poliza_seguro',
        'aseguradora',
        'laboratorio_proveedor',
      ],
      [
        '_bien_ref.codigo_activo',
        '_bien_ref.marca',
        '_bien_ref.modelo',
        '_bien_ref.condicion_actual',
        '_bien_ref._contrato_ref.codigo',
        '_bien_ref._tipo_recurso_ref.nombre',
        '_bien_ref._vehiculo_ref.patente',
        '_bien_ref._clase_ref.nombre',
      ],
    );
    const hoy = moment();
    const proximoMes = moment().add(30, 'days');
    if (response.status) {
      let vehiculos_filtrados = response.data.filter((datos) => {
        let tipo_recurso = datos?._bien_ref?._tipo_recurso_ref;
        return tipo_recurso?.nombre === 'Vehículos';
      });

      let data = response.data
        .filter((d) => !d.is_renovado)
        .map((a) => {
          const b = {
            _id: a._id,
            fecha: a.fecha,
            fecha_proxima_carga: a.fecha_proxima_carga,
            tipo: a.tipo,
            estado: '',
            _bien_ref: a._bien_ref._id,
            codigo_activo: a._bien_ref ? a._bien_ref.codigo_activo : '--',
            contrato: a._bien_ref && a._bien_ref._contrato_ref ? a._bien_ref._contrato_ref.codigo : '--',
            municipalidad: a?.municipalidad ? a?.municipalidad : '--',
            valor_permiso_circulacion: a?.valor_permiso_circulacion ? a?.valor_permiso_circulacion : 0,
          };
          if (!a.archivo) {
            b.estado = 'FALTANTE';
          } else {
            if (b.fecha_proxima_carga) {
              if (moment(a.fecha_proxima_carga).isAfter(hoy)) b.estado = 'VIGENTE';
              else if (moment(a.fecha_proxima_carga).isSameOrBefore(hoy)) b.estado = 'VENCIDO';
              else if (moment(a.fecha_proxima_carga).isSameOrBefore(proximoMes)) b.estado = 'POR VENCER';
            } else {
              b.estado = 'VIGENTE';
            }
          }

          return b;
        });
      if (filtros.estados) data = data.filter((a) => filtros.estados.includes(a.estado));
      let pageSizes = [];
      if (data && data.length > 0) {
        let size1 = Math.ceil(data.length / 10) * 1;
        let size2 = Math.ceil(data.length / 10) * 2;
        let size3 = Math.ceil(data.length / 10) * 5;
        let size4 = Math.ceil(data.length / 10) * 10;
        let size5 = data.length;
        if (size1 > 10) size1 = 10;
        if (size2 > 20) size2 = 20;
        if (size3 > 50) size3 = 50;
        if (size4 > 100) size4 = 100;
        if (size5 > 200) size5 = 200;
        pageSizes.push(size1);
        pageSizes.push(size2);
        pageSizes.push(size3);
        pageSizes.push(size4);
        pageSizes.push(size5);
      }

      pageSizes.sort((a, b) => a - b);
      setPageSize(pageSizes[1]);
      setPageSizes(pageSizes);
      setData(data);
    }
    setIsLoading(false);
  }, [filtros]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleChange = (event) => {
    const newValue = { ...filtros, estados: event.target.value };
    setFiltros(newValue);
  };

  return (
    <>
      <Paper className={classes.paperFiltros}>
        <Container className={classes.containerFiltros}>
          <Grid container direction="row" alignItems="center" justify="center">
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <FormControl className={classes.formControl} fullWidth variant="outlined">
                <InputLabel>Estados</InputLabel>
                <Select
                  labelId="demo-mutiple-name-label"
                  id="demo-mutiple-name"
                  multiple
                  value={filtros.estados}
                  onChange={handleChange}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {estados.map((estado, index) => (
                    <MenuItem key={index} value={estado}>
                      <Checkbox checked={filtros.estados.indexOf(estado) > -1} />
                      {/* {estado} */}
                      <ListItemText primary={estado} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <MaterialTable
        title="Listado de adjuntos"
        columns={columns}
        data={data}
        isLoading={isLoading}
        localization={localization}
        onChangeRowsPerPage={(size) => setPageSize(size)}
        actions={actions}
        options={{
          padding: 'dense',
          exportAllData: true,
          columnsButton: true,
          filtering: true,
          pageSize: pageSize,
          pageSizeOptions: pageSizes,
          headerStyle: {
            fontWeight: 'bold',
            color: '#731f1f',
            textAlign: 'center',
          },
          maxBodyHeight: '70vh',
          rowStyle: (rowData) => ({
            backgroundColor:
              rowData.estado === 'VENCIDO' || rowData.estado === 'FALTANTES'
                ? red[500]
                : rowData.estado === 'POR VENCER'
                ? yellow[500]
                : 'white',
          }),
        }}
      />
    </>
  );
}
