import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@material-ui/core";
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSnackbar } from "notistack";
import useSWR from "swr";
import { ActualizarPerfil, AgregarPerfil, EliminarPerfil, ObtenerPerfiles } from "../../../services/request/requestPerfiles";
import MaterialTable from "../../../components/materialTable";
import { useHistory } from "react-router";
import { LISTADO_PERMISOS_PERFIL } from '../../../constants/routes';
import { MainContext } from "../../../App";
import { AGREGAR_PERFILES, EDITAR_PERFILES, ELIMINAR_PERFILES } from "../../../constants/permissions";

function Index() {
	const {permisos} = useContext(MainContext);
	const { data: dataPerfiles, mutate: mutatePerfiles } = useSWR("get:perfiles", (key) => ObtenerPerfiles());
	const [Registro, SetRegistro] = useState();
	const [IsOpen, SetIsOpen] = useState(false);
	const [tipoDialogo, setTipoDialogo] = useState("");
	const notistack = useSnackbar();
	const history = useHistory();
	const handleAgregar = useCallback(() => {
		SetIsOpen(true);
		setTipoDialogo("agregar");
	}, []);

	const handleActualizar = useCallback((perfil) => {
		SetRegistro(perfil);
		SetIsOpen(true);
		setTipoDialogo("actualizar");
	}, []);

	const handleEliminar = useCallback((perfil) => {
		SetRegistro(perfil);
		SetIsOpen(true);
		setTipoDialogo("eliminar");
	}, []);

	const handleChangeNombre = (event) => {
		let nombre = event.target.value;
		let datos = { ...Registro };
		datos["nombre"] = nombre;
		SetRegistro(datos);
	}

	const handleChangeDescripcion = (event) => {
		let descripcion = event.target.value;
		let datos = { ...Registro };
		datos["descripcion"] = descripcion;
		SetRegistro(datos);
	}

	const handleAceptar = async () => {
		try {
			switch (tipoDialogo) {
				case "agregar":
					await AgregarPerfil(Registro);
					break;
				case "actualizar":
					await ActualizarPerfil(Registro);
					break;
				case "eliminar":
					await EliminarPerfil(Registro);
					break;
				default:
					throw new Error("Acción no especificada.")
			}
			notistack.enqueueSnackbar("Acción realizada exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar realizar la acción.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} finally {
			mutatePerfiles();
			SetRegistro();
			SetIsOpen(false);
			setTipoDialogo("");
		}
	}

	const handleCancelar = () => {
		SetRegistro();
		SetIsOpen(false);
		setTipoDialogo("");
	}

	let columnsPerfiles = [
		{
			title: "Nombre",
			field: "nombre",
			defaultSort: 'asc'
		}, {
			title: "Descripción",
			field: "descripcion",
		}
	];

	const [actions, setActions] = useState([]);

	const revisarPermisos = useCallback(() => {
		const accionesPermitidas = [];
		if (permisos[AGREGAR_PERFILES]) accionesPermitidas.push({
			tooltip: "Agregar",
			icon: "add",
			onClick: () => handleAgregar(),
			isFreeAction: true,
		});
		if (permisos[ELIMINAR_PERFILES]) accionesPermitidas.push({
			tooltip: "Eliminar",
			icon: "delete",
			onClick: (event, row) => handleEliminar(row),
		});
		if (permisos[EDITAR_PERFILES]) {
			accionesPermitidas.push({
				tooltip: "Editar",
				icon: "edit",
				onClick: (event, row) => handleActualizar(row),
			});
			accionesPermitidas.push({
				tooltip: "Ver Permisos",
				icon: "list",
				onClick: (e, rowData) => {
					history.push(LISTADO_PERMISOS_PERFIL.replace(":id", rowData._id));
				}
			});
		}
		setActions(accionesPermitidas);
	}, [permisos, handleAgregar, handleEliminar, handleActualizar, history]);

	useEffect(() => {
		revisarPermisos();
	}, [revisarPermisos]);

	return (
		<Fragment>
			<MaterialTable
				title="Perfiles"
				is_loading={!dataPerfiles}
				data={dataPerfiles && dataPerfiles.data}
				columns={columnsPerfiles}
				actions={actions}
			/>

			<Dialog open={Boolean(IsOpen)} maxWidth="md" fullWidth>
				<DialogTitle>
					{tipoDialogo === "actualizar" && "Actualizar Perfil"}
					{tipoDialogo === "agregar" && "Agregar Perfil"}
					{tipoDialogo === "eliminar" && "Eliminar Perfil"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>Formulario con información del Perfil</DialogContentText>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<TextField
								label="Nombre"
								value={Registro ? Registro.nombre : ""}
								onChange={handleChangeNombre}
								variant="outlined"
								fullWidth
								disabled={tipoDialogo === "eliminar"}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								label="Descripción"
								value={Registro ? Registro.descripcion : ""}
								onChange={handleChangeDescripcion}
								variant="outlined"
								fullWidth
								disabled={tipoDialogo === "eliminar"}
							/>
						</Grid>
						{/* <Grid item xs={12}>
							<Autocomplete
								multiple
								options={dataPermisos ? dataPermisos.data : []}
								value={dataPermisos && Registro ? dataPermisos.data.filter(d => Registro._permisos_ref && Registro._permisos_ref.includes(d._id)) : []}
								getOptionLabel={(option) => option.nombre}
								onChange={handleChangePermisos}
								filterSelectedOptions
								disabled={tipoDialogo === "eliminar"}
								renderInput={(params) => (
									<TextField
										variant="outlined"
										label="Permisos"
										{...params}
									/>
								)}
							/>
						</Grid> */}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancelar} variant="outlined" color="primary">
						Cancelar
          </Button>
					<Button onClick={handleAceptar} variant="contained" color="primary">
						Aceptar
          </Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
}

export default Index;