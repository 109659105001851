import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  Paper
} from "@material-ui/core";
import React from "react";
import FileInput from "../../components/fileInput";
import xlsx from 'xlsx';
import { useFormik } from "formik";
import * as yup from 'yup';
import { useSnackbar } from "notistack";
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { downloadDriveFile } from "../../services/fileUpload";
import { deleteLicenciaSoftware, getLicenciasSoftware, postAsignacionLicenciaSoftware, postLicenciaSoftware, putLicenciaSoftware } from "../../services/request/requestLicenciasSoftware";
import { getBienes } from "../../services/request/requestBienes";

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(3),
    maxWidth: "100%"
  },
  padding: {
    padding: theme.spacing(1)
  },
  center: {
    textAlign: "center",
  },
  paddingCenter: {
    padding: theme.spacing(1),
    textAlign: "center",
  },
  fileInput: {
    top: 'unset',
    left: 'unset',
    position: 'inherit',
    marginTop: "1px",
  },
}));

const validationSchema = yup.object({
  // archivo: yup.object().required("Debe seleccionar un Archivo").nullable()
});


export default function CargaMasivaSoftware() {

  const classes = useStyles();
  const notistack = useSnackbar();
  const comenzarCarga = (values) => {
    const key = notistack.enqueueSnackbar("Iniciando carga de activos...", {
      persist: true,
      anchorOrigin: {
        horizontal: "center",
        vertical: "bottom"
      }
    });
    try {
      if (values.archivo) {
        let fileReader = new FileReader();
        fileReader.onload = async (e) => {
          const binary = new Uint8Array(e.target.result);
          const workbook = xlsx.read(binary, { type: 'array' });
          const nombreHoja = "OFFICE";
          let worksheet = workbook.Sheets[nombreHoja];
          if (!worksheet) {
            notistack.closeSnackbar(key);
            const successkey = notistack.enqueueSnackbar(`La hoja ${nombreHoja} no se encuentra en el archivo`, {
              variant: 'warning',
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
              },
              action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
            });
            return;
          }
          let desired_row = 2;
          let desired_cell = worksheet["A" + desired_row];
          let desired_value = (desired_cell ? desired_cell.v : undefined);
          while (desired_value) {
            const filter = {
              nombre_eq: (worksheet["M" + desired_row]) ? worksheet["M" + desired_row].v : "",
              version_eq: (worksheet["N" + desired_row]) ? worksheet["N" + desired_row].v : "",
              codigo_compra_eq: (worksheet["L" + desired_row]) ? worksheet["L" + desired_row].v : ""
            }
            if (filter.nombre_eq && filter.version_eq) {
              const responseGetLicencia = await getLicenciasSoftware(filter);
              let licencia = null;
              if (responseGetLicencia.status && responseGetLicencia.data.length > 0) {
                licencia = responseGetLicencia.data[0];
                await putLicenciaSoftware(licencia._id, {
                  cantidad: licencia.cantidad + 1
                });
              } else {
                let tipoLicencia = (worksheet["T" + desired_row]) ? worksheet["T" + desired_row].v : "";
                if (tipoLicencia === "PERPETUA") tipoLicencia = "Perpetua";
                else if (tipoLicencia === "ARRIENDO") tipoLicencia = "Arriendo";

                let fechaVencimiento = (worksheet["R" + desired_row]) && (worksheet["R" + desired_row]).w !== "-" ? new Date(worksheet["R" + desired_row].w) : null;

                const nuevaLicencia = {
                  cantidad: 1,
                  cantidad_utilizada: 0,
                  codigo_compra: (worksheet["L" + desired_row]) ? worksheet["L" + desired_row].v : "",
                  descripcion: "",
                  fecha_vencimiento: fechaVencimiento,
                  nombre: (worksheet["M" + desired_row]) ? worksheet["M" + desired_row].v : "",
                  orden_compra: "",
                  tipo: tipoLicencia,
                  version: (worksheet["N" + desired_row]) ? worksheet["N" + desired_row].v : ""
                };

                const responsePostLicencia = await postLicenciaSoftware(nuevaLicencia);
                if (responsePostLicencia.status) licencia = responsePostLicencia.data;
              }
              const codigoActivo = desired_value !== "-" ? desired_value : "";
              if (licencia && codigoActivo) {
                const responseGetBien = await getBienes({ codigo_activo_eq: desired_value });
                if (responseGetBien.status && responseGetBien.data.length > 0) {
                  const activo = responseGetBien.data[0];
                  await postAsignacionLicenciaSoftware({
                    _bien_ref: activo._id,
                    _contrato_ref: activo._contrato_ref,
                    _gerencia_ref: activo._gerencia_ref,
                    _usuario_ref: activo._asignado_ref,
                    _licencia_software_ref: licencia._id
                  });
                  await putLicenciaSoftware(licencia._id, {
                    cantidad_utilizada: licencia.cantidad_utilizada + 1
                  });
                }else{
                  console.log("activo no encontrado", codigoActivo);
                }
              }

            }


            desired_row++;
            desired_cell = worksheet["A" + desired_row];
            desired_value = (desired_cell ? desired_cell.v : undefined);
          }
          notistack.closeSnackbar(key);
          const successkey = notistack.enqueueSnackbar("Carga de activos completadada", {
            variant: 'success',
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom"
            },
            action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
          });
        };
        fileReader.readAsArrayBuffer(values.archivo);
      } else {
        notistack.closeSnackbar(key);
        const successkey = notistack.enqueueSnackbar("Debe seleccionar un archivo para la carga.", {
          variant: 'warning',
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
        });
      }
    }
    catch (e) {
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar("Error en carga de activos. Intente de nuevo o contacte con el equipo de soporte.", {
        variant: 'error',
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
      });
    }
  }
  const formik = useFormik({
    initialValues: {
      archivo: null
    },
    onSubmit: comenzarCarga,
    validationSchema: validationSchema
  });

  const { touched, values, handleSubmit, setFieldValue, setFieldTouched } = formik;

  const fileChange = (name, e) => {
    setFieldValue(name, e.target.files[0]);
    setFieldTouched(name, true, false);
  };

  const descargarFormato = async () => {
    const key = notistack.enqueueSnackbar("Descargando Archivo...", {
      persist: true,
      anchorOrigin: {
        horizontal: "center",
        vertical: "bottom"
      }
    });
    try {
      await downloadDriveFile("https://www.googleapis.com/drive/v3/files/1UHH6fSVeutjhhzDabrr_t7BqOvs_KFkk?alt=media", "Carga de activos.xlsx");
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar("Descarga completada", {
        variant: 'success',
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
      });
    }
    catch (e) {
      console.log(e);
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar("Error en descarga. Intente de nuevo o contacte con el equipo de soporte.", {
        variant: 'error',
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
      });
    }
  }

  async function borrarLicencias() {
    const response = await getLicenciasSoftware({}, ["_id"]);

    if (response.status) {
      response.data.forEach(async value => {
        console.log(value._id);
        await deleteLicenciaSoftware(value._id);
      });
    }
  }

  return (
    <Paper>
      <Container>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Grid container>
            <Grid item lg>
              <FormControl
                fullWidth
                error={!values.archivo && touched.archivo ? true : false}
              >
                <InputLabel className={classes.fileInput} shrink >Archivo</InputLabel>
                <FileInput
                  inputName="archivo"
                  buttonColor="primary"
                  buttonVariant="outlined"
                  label="Archivo de Factura"
                  value={values.archivo}
                  handleOnChange={fileChange.bind(null, "archivo")}
                  textVariant="outlined"
                />
                <FormHelperText>
                  {!values.archivo && touched.archivo ? "Debe seleccionar un Archivo" : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container className={classes.padding} alignContent="center" justify="center">
            <Grid item lg className={classes.center}>
              <Button variant="outlined" color="primary" onClick={borrarLicencias} style={{ marginRight: "8px" }}>Borrar Licencias</Button>
              <Button variant="outlined" color="primary" onClick={descargarFormato} style={{ marginRight: "8px" }}>Descargar Formato</Button>
              <Button variant="contained" color="primary" type="submit">Comenzar Carga</Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Paper>
  );
}