import axios from 'axios';
import { BACKEND } from '../../constants/urls';
/**
 * Obtener tipos de recursos
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getMarcasSoftware(filter = {}, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/marcas-software`;
  if (selects)
    selects.forEach((select) => {
      url = url + '/' + select;
    });
  if (populates)
    populates.forEach((populate) => {
      url = url + '/' + populate;
    });
  //console.log("URL for populate: ", url);
  return axios
    .get(url, {
      params: filter,
      cancelToken,
    })
    .then((res) => res.data);
}
/**
 * Guardar Marca
 * @param {*} data datos de Marca
 * @param {*} cancelToken token para cancelacion
 */
export function postMarcaSoftware(data, cancelToken = null) {
  return axios
    .post(`${BACKEND}/marcas-software/`, data, {
      cancelToken,
    })
    .then((res) => res.data);
}
/**
 * Actualizar marca
 * @param {String}  id
 * @param {Object} data datos del registro
 * @param {*} cancelToken token para cancelacion
 */
export function putMarcaSoftware(id, data, cancelToken = null) {
  return axios
    .put(`${BACKEND}/marcas-software/${id}`, data, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}

/**
 * Eliminar marca
 * @param {String}  id
 * @param {*} cancelToken token para cancelacion
 */
export function deleteMarcaSoftware(id, cancelToken = null) {
  return axios
    .delete(`${BACKEND}/marcas-software/${id}`, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}
