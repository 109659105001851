import React, { useContext, useEffect } from 'react';
import Page from './page';
import { ListadoVehiculosContext } from '../../App';
import { useVehiculos } from '../../hooks/useVehiculos';

function Index(props) {
  const { setVehiculos } = useContext(ListadoVehiculosContext);
  const { vehiculos } = useVehiculos();

  useEffect(() => {
    setVehiculos(vehiculos);
  }, [vehiculos]);

  return <Page />;
}

export default Index;
