import React, { useEffect, useState, useContext } from 'react';
import Page from './page';
import { getUsuarios } from '../../../../services/request/requestUsuarios';
import { ObtenerBienes } from '../../../../services/request/requestBienes';
import { MainContext } from '../../../../App';
import { VER_SELECCION_GERENCIAS, VER_CONTRATOS_GERENCIA } from '../../../../constants/permissions';
import useGerenciasUser from '../../../../hooks/useGerenciasUser';

function Index(props) {
  const [Personas, SetPersonas] = useState();
  const { permisos, usuarioSesion, listadoContratosACargo } = useContext(MainContext);
  const { gerencias } = useGerenciasUser(false);

  useEffect(() => {
    Promise.all([getUsuarios({ is_gsuite_talana: true }), ObtenerBienes()])
      .then((responses) => {
        const personas = Array.from(responses[0]);
        const bienes = Array.from(responses[1].data);

        personas.forEach((persona, index_1) => {
          let checkContrato = persona.contratos_ref.length > 0;
          personas[index_1]['contrato'] = checkContrato
            ? `${persona.contratos_ref[0].codigo} ${persona.contratos_ref[0].nombre}`
            : null;
          personas[index_1]['cant_bienes'] = bienes.filter((b) => b._asignado_ref === persona._id).length;
        });

        let personasFiltradas = [];
        let personasFiltradasUsuario = [];
        let personasFiltradasGerenciaUsuario = [];

        if (!permisos[VER_SELECCION_GERENCIAS]) {
          if (!permisos[VER_CONTRATOS_GERENCIA]) {
            if (listadoContratosACargo && listadoContratosACargo.length > 0) {
              personasFiltradasUsuario = personas.filter((persona) => {
                return listadoContratosACargo.some((personacontrato) => {
                  return personacontrato.codigo === persona.contratos_ref[0].codigo;
                });
              });

              if (gerencias && gerencias.length > 0) {
                personasFiltradasGerenciaUsuario = personas.filter((persona) => {
                  return gerencias.some((gerencia) => gerencia._id === persona.gerencia_ref._id);
                });
              }

              personasFiltradas = [...personasFiltradasUsuario, ...personasFiltradasGerenciaUsuario];
            } else {
              personasFiltradasUsuario = personas.filter((persona) =>
                persona.contratos_ref[0].contrato_id === usuarioSesion.contrato ? true : false,
              );

              if (gerencias && gerencias.length > 0) {
                personasFiltradasGerenciaUsuario = personas.filter((persona) => {
                  return gerencias.some((gerencia) => gerencia._id === persona.gerencia_ref._id);
                });
              }

              personasFiltradas = [...personasFiltradasUsuario, ...personasFiltradasGerenciaUsuario];
            }
          } else {
            personasFiltradasUsuario = personas.filter((persona) =>
              persona.gerencia_ref._id === usuarioSesion.gerencia ? true : false,
            );

            if (gerencias && gerencias.length > 0) {
              personasFiltradasGerenciaUsuario = personas.filter((persona) => {
                return gerencias.some((gerencia) => gerencia._id === persona.gerencia_ref._id);
              });
            }

            personasFiltradas = [...personasFiltradasUsuario, ...personasFiltradasGerenciaUsuario];
          }
          console.log(personasFiltradas);
          SetPersonas(personasFiltradas);
        } else {
          SetPersonas(personas);
        }
      })
      .catch((error) => {
        console.error(error);
        SetPersonas([]);
      });
  }, [gerencias]);

  return <Page personas={Personas} />;
}

export default Index;
