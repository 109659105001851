import React, { createContext, useState } from 'react';
import MaterialTable from '../../../components/materialTable';
import useSWR from 'swr';
import { Fragment } from 'react';
import DialogoCRUDDocumentoRequerido from './dialogoCRUDDocumentoRequerido';
import { useHistory, useParams } from 'react-router';
import { AJUSTES_CONFIGURACION } from '../../../constants/routes';
import {
  deleteDocumentoRequeridoClase,
  getDocumentosRequeridosClase,
  postDocumentoRequeridoClase,
  putDocumentoRequeridoClase,
} from '../../../services/request/requestDocumentosRequeridosClase';
import { getClase } from '../../../services/request/requestClase';
import { MTableAction } from '@material-table/core';
import { Button } from '@material-ui/core';
import {
  deleteDocumentoAdjunto,
  getBienes,
  getDocumentoAdjunto,
  postDocumentoAdjunto,
} from '../../../services/request/requestBienes';

export const TablaDocumentosRequeridosClaseContext = createContext({
  openDialogoCRUD: false,
  setOpenDialogoCRUD: () => {},
  tituloDialogo: '',
  setTituloDialogo: () => {},
  initialValues: '',
  setInitialValues: () => {},
  handleAceptar: () => {},
  disableEditing: false,
  setDisableEditing: () => {},
  idClase: '',
  openAgregarTipoArchivo: false,
  setOpenAgregarTipoArchivo: () => {},
});

export default function TablaDocumentosRequeridosClase() {
  const { idClase } = useParams();
  const { data, mutate } = useSWR('get:documentos-requeridos-clase', (key) =>
    idClase ? getDocumentosRequeridosClase({ _clase_ref_eq: idClase }) : { data: [] },
  );
  const { data: clase } = useSWR('get:clase-documentos-requeridos', (key) => getClase(idClase), { initialData: null });
  const [openDialogoCRUD, setOpenDialogoCRUD] = useState(false);
  const [tituloDialogo, setTituloDialogo] = useState('');
  const [initialValues, setInitialValues] = useState({ nombre: '' });
  const [disableEditing, setDisableEditing] = useState(false);
  const [openAgregarTipoArchivo, setOpenAgregarTipoArchivo] = useState(false);
  const history = useHistory();

  const handleOpenAgregar = (titulo) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      nombre: '',
      tipo_archivo_obj: '',
    });
    setDisableEditing(false);
  };

  const handleOpenModificar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      nombre: row.nombre,
      id: row._id,
      tipo_archivo_obj: '',
    });
    setDisableEditing(false);
  };

  const handleOpenQuitar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      nombre: row.nombre,
      id: row._id,
      tipo_archivo_obj: '',
    });
    setDisableEditing(true);
  };

  const handleAceptar = async (values) => {
    if (tituloDialogo) {
      if (tituloDialogo.includes('Nuev'))
        await postDocumentoRequeridoClase({ nombre: values.nombre, _clase_ref: idClase });
      else if (tituloDialogo.includes('Modificar'))
        await putDocumentoRequeridoClase(values.id, {
          nombre: values.nombre,
        });
      else if (tituloDialogo.includes('Eliminar')) await deleteDocumentoRequeridoClase(values.id);
    }
    mutate(getDocumentosRequeridosClase({ _clase_ref: idClase }));
    setOpenDialogoCRUD(false);
  };

  const handleVolver = () => {
    history.push(AJUSTES_CONFIGURACION);
  };

  const columns = [
    {
      title: 'Nombre',
      field: 'nombre',
      align: 'center',
      defaultSort: 'asc',
    },
  ];

  async function actualizarRequerimientosActivos() {
    const responseBienes = await getBienes({ _clase_ref_eq: idClase }, ['_clase_ref']);
    if (data.status && data.data.length > 0 && responseBienes.status && responseBienes.data.length) {
      for (let i = 0; i < responseBienes.data.length; i++) {
        const responseArchivos = await getDocumentoAdjunto({ _bien_ref_eq: responseBienes.data[i]._id });
        if (responseArchivos.status) {
          for (let d = 0; d < data.data.length; d++) {
            const archivoBien = responseArchivos.data.find((a) => a.tipo === data.data[d].nombre);
            if (!archivoBien || (archivoBien && archivoBien.archivo)) {
              await postDocumentoAdjunto({
                tipo: data.data[d].nombre,
                _bien_ref: responseBienes.data[i]._id,
                archivo: null,
              });
            }
          }
        }
      }
    }
  }

  async function eliminarRequerimientosActivos() {
    const responseBienes = await getBienes({ _clase_ref_eq: idClase }, ['_clase_ref']);
    if (data.status && data.data.length > 0 && responseBienes.status && responseBienes.data.length) {
      for (let i = 0; i < responseBienes.data.length; i++) {
        const responseArchivos = await getDocumentoAdjunto({ _bien_ref_eq: responseBienes.data[i]._id });
        if (responseArchivos.status) {
          for (let d = 0; d < responseArchivos.data.length; d++) {
            if (!responseArchivos.data.archivo) {
              await deleteDocumentoAdjunto(responseArchivos.data[d]._id);
            }
          }
        }
      }
    }
  }

  return (
    <Fragment>
      <TablaDocumentosRequeridosClaseContext.Provider
        value={{
          openDialogoCRUD,
          setOpenDialogoCRUD,
          tituloDialogo,
          setTituloDialogo,
          initialValues,
          setInitialValues,
          handleAceptar,
          disableEditing,
          setDisableEditing,
          idClase,
          openAgregarTipoArchivo,
          setOpenAgregarTipoArchivo,
        }}
      >
        <MaterialTable
          actions={[
            {
              tooltip: 'Agregar',
              icon: 'add',
              onClick: (event, row) => handleOpenAgregar('Nuevo Documento'),
              isFreeAction: true,
            },
            {
              tooltip: 'Editar',
              icon: 'edit',
              onClick: (event, row) => handleOpenModificar('Modificar Documento', row),
            },
            {
              tooltip: 'Eliminar',
              icon: 'delete',
              onClick: (event, row) => handleOpenQuitar('Eliminar Documento', row),
            },
            {
              tooltip: 'Volver',
              icon: 'arrow_back',
              onClick: (event, row) => handleVolver(),
              isFreeAction: true,
            },
            {
              icon: 'actualizar_activos',
              onClick: (event, row) => actualizarRequerimientosActivos(),
              isFreeAction: true,
            },
            {
              icon: 'eliminar_requerimientos',
              onClick: (event, row) => eliminarRequerimientosActivos(),
              isFreeAction: true,
            },
          ]}
          components={{
            Action: (props) => {
              if (props.action.icon === 'actualizar_activos') {
                return (
                  <Button onClick={props.action.onClick} variant="outlined">
                    Actualizar Req. de Activos
                  </Button>
                );
              }
              if (props.action.icon === 'eliminar_requerimientos') {
                return (
                  <Button onClick={props.action.onClick} variant="outlined">
                    Eliminar Req. de Activos
                  </Button>
                );
              }
              return <MTableAction {...props} />;
            },
          }}
          title={`Documentos Requeridos para ${clase ? clase.nombre : ''}`}
          is_loading={!data}
          data={data && data.data}
          columns={columns}
          filtering={false}
          grouping={false}
          searching={false}
          empty_rows={true}
          page_size={20}
        />
        <DialogoCRUDDocumentoRequerido />
      </TablaDocumentosRequeridosClaseContext.Provider>
    </Fragment>
  );
}
