import { Grid, makeStyles, TextField } from "@material-ui/core";
import React from "react";
import moment from "moment";
import { DatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
    subtitle: {
      margin: theme.spacing(2, 0, 2, 0)
    },
    contenedorPrincipal:{
      padding: 5
    },
    items:{
      marginBottom: theme.spacing(0.7),
    },
    itemsMantenimiento:{
      marginRight: theme.spacing(0.3),
      padding: theme.spacing(1),
      border: '1px solid grey',
    },
    formselect:{
      marginTop: -16,
    },
    text_disabled: {
      "& input.Mui-disabled": {
        background: "rgb(242, 246, 246)",
        color: "grey"
      }
    },
    input_required: {
      // "& input": {
        background: "rgb(247, 249, 216)",
        color: "black"
      // }
    },
    select_color:{    
      background: "rgb(247, 249, 216)",
      color: "black"
    }
  }));


export const InfoVehiculo = (props) => {   
const {    
    values, 
    formik,    
    FullDetalle,
    TipoDeshabilitado,
    habilitaPatente  
} = props;

const classes = useStyles();
const { touched, errors, handleChange } = formik;

  return (
    <Grid container  spacing={1}>
        <Grid item xs={FullDetalle ? 2 : 6} className={classes.items}>        
        <TextField
            name="patente"
            label="Patente"
            disabled = {habilitaPatente}
            value={values.patente}
            onChange={handleChange}
            error={touched.patente && errors.patente && Boolean(errors.patente)}
            helperText={touched.patente && errors.patente ? errors.patente : ""}                  
            variant="outlined"
            fullWidth
            className={classes.text_disabled}
        />
        </Grid>
        {(FullDetalle) && <Grid item xs={2} className={classes.items}>       
        <TextField
            name="codigo_activo"
            label="Código Activo"
            disabled = {TipoDeshabilitado}
            value={values.codigo_activo}
            onChange={handleChange}
            error={touched.codigo_activo && errors.codigo_activo && Boolean(errors.codigo_activo)}
            helperText={touched.codigo_activo && errors.codigo_activo ? errors.codigo_activo : ""}                  
            variant="outlined"
            fullWidth
            className={classes.text_disabled}
        />
        </Grid>}
        {(FullDetalle) && <Grid item xs={2} className={classes.items}>       
        <TextField
            name="marca"
            label="Marca"
            disabled = {TipoDeshabilitado}
            value={values.marca}
            onChange={handleChange}
            error={touched.marca && errors.marca && Boolean(errors.marca)}
            helperText={touched.marca && errors.marca ? errors.marca : ""}                 
            variant="outlined"
            fullWidth
            className={classes.text_disabled}
        />
        </Grid>}
        <Grid item xs={FullDetalle ? 2 : 6} className={classes.items}>
        <TextField
            name="modelo"
            label="Modelo"
            disabled = {TipoDeshabilitado}
            value={values.modelo}
            onChange={handleChange}
            error={touched.modelo && errors.modelo && Boolean(errors.modelo)}
            helperText={touched.modelo && errors.modelo ? errors.modelo : ""}
            fullWidth
            variant="outlined"
            className={classes.text_disabled}
        />
        </Grid>
        {(FullDetalle) && <Grid item xs={2} className={classes.items}>
        <TextField
            name="color"
            label="Color"
            disabled = {TipoDeshabilitado}
            value={values.color}
            onChange={handleChange}
            error={touched.color && errors.color && Boolean(errors.color)}
            helperText={touched.color && errors.color ? errors.color : ""}
            fullWidth
            variant="outlined"
            className={classes.text_disabled}
        />
        </Grid>}
        {(FullDetalle) && <Grid item xs={2} className={classes.items}> 
        <DatePicker
            name="ano"
            autoOk
            disabled = {TipoDeshabilitado}
            InputLabelProps={{
                classes: {
                shrink: "shrink"
                }
            }}
            views={['year']}
            inputVariant="outlined"
            label="Año"
            format="yyyy"
            helperText={touched.ano ? errors.ano : ""}
            error={touched.ano && Boolean(errors.ano)}
            //onChange={dateChange.bind(null, "ano")}
            value={moment(values.ano).utc().add(moment().utcOffset() * -1, "minutes")}
            fullWidth
            maxDate={moment().startOf('year').add(1, 'years')}
            minDate={moment().startOf('year').subtract(20, 'years')}
            className={classes.text_disabled}
            />                  
        </Grid>}

    </Grid>
  )
}
