import React from 'react';
import Page from './page';

export default function Home(props) {
    return (
        <Page
        />
    );

}
