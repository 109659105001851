import React from 'react';
import ResumenGPSProveedor from './resumenGPSProveedor';
import TablaEquipamiento from './tablaEquipamiento';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: 5,
  },
}));

function GestionEquipamiento() {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={0.5} className={classes.gridContainer} alignContent="center" justifyContent="center">
        <Grid item xs={12}>
          <ResumenGPSProveedor />
        </Grid>
        <Grid item xs={12}>
          <TablaEquipamiento />
        </Grid>
      </Grid>
    </>
  );
}

export default GestionEquipamiento;
