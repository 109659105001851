import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import MaterialReactSelect from '../../../components/materialReactSelect';
import React, { useContext, useState } from 'react';
import { TablaAgrupacionesSoftwareContext } from './tablaAgrupacionSoftware';
import { getMarcasSoftware } from '../../../services/request/requestMarcasSoftware';
import * as yup from 'yup';

const validationSchema = yup.object({
  software: yup.string().required(),
});

export default function DialogoCRUDAgrupacionSoftware() {
  const { openDialogoCRUD, setOpenDialogoCRUD, tituloDialogo, handleAceptar, disableEditing, initialValues } =
    useContext(TablaAgrupacionesSoftwareContext);

  const formik = useFormik({
    initialValues,
    onSubmit: handleAceptar,
    validationSchema,
  });

  const handleClose = () => setOpenDialogoCRUD(false);

  const [marcas, setMarcas] = useState([]);
  const { values, errors, touched, handleSubmit, handleChange, resetForm } = formik;

  function handleOpenDialog(initialValues) {
    resetForm({ values: initialValues });
    getMarcasSoftware({}).then((response) => {
      setMarcas(response.data);
    });
  }

  return (
    <Dialog open={openDialogoCRUD} onEntered={() => handleOpenDialog(initialValues)} maxWidth="md" fullWidth>
      <DialogTitle>{tituloDialogo}</DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs>
              <MaterialReactSelect
                value={values.marca}
                label="Marca Software"
                isClearable
                onChange={(newValue, actionMeta) =>
                  newValue ? formik.setFieldValue('marca', newValue) : formik.setFieldValue('marca', '')
                }
                placeholder="Seleccione la Marca"
                options={
                  marcas
                    ? marcas.map((value, index) => ({
                        value: value._id,
                        label: value.marca,
                      }))
                    : []
                }
                noOptionsMessage={() => 'No ha entrado la Marca del Software'}
                error={touched.marca && errors.marca && Boolean(errors.marca)}
                helperText={touched.marca && errors.marca ? errors.marca : ''}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                name="software"
                label="Agrupación de Software"
                value={values.software}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                disabled={disableEditing}
                error={errors.software && touched.software ? true : false}
                helperText={errors.software && touched.software ? errors.software : ''}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                name="descripcion"
                label="Descripción"
                value={values.descripcion}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                disabled={disableEditing}
                error={errors.descripcion && touched.descripcion ? true : false}
                helperText={errors.descripcion && touched.descripcion ? errors.descripcion : ''}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
