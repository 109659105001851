import React, { useContext } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { VerBienContext } from '.';

export default function DialogoSolicitarActivacion(props) {
  const {
    bien,
    motivoBaja,
    setMotivoBaja,
    openDialogActivacion,
    handleSolicitarActivacion,
    handleAnularActivar,
    setOpenDialogActivacion,
  } = useContext(VerBienContext);

  const classes = useStyles();

  const handleActivar = () => {
    handleSolicitarActivacion(motivoBaja);
  };

  return (
    <Dialog open={openDialogActivacion} onClose={() => setOpenDialogActivacion(false)} fullWidth>
      <DialogTitle>Activación Contable</DialogTitle>
      <DialogContent dividers>
        <Typography>Formulario para realizar la activación.</Typography>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.center}>
            <TextField
              name="motivo"
              label="Comentario"
              fullWidth
              variant={'outlined'}
              margin={'normal'}
              value={bien.motivo_activacion}
              rows={2}
              multiline={true}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setMotivoBaja(e.target.value)}
              helperText="Requerido para realizar la activación"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => setOpenDialogActivacion(false)} color="primary" variant="outlined">
          Cancelar
        </Button>
        <Button
          autoFocus
          onClick={bien.is_activo_contable ? handleAnularActivar : handleActivar}
          disabled={CheckButtonDisabled(bien, motivoBaja)}
          color="primary"
          variant="contained"
        >
          {bien.is_activo_contable ? 'Anular Activación' : 'Activar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * Método encargado de verificar si el botón está habilitado o deshabilitado.
 * @param {*} motivo Motivo para activar el bien.
 * @returns FLAG que habilita o deshabilita el botón.
 */
function CheckButtonDisabled(activo, motivo) {
  if (!motivo && !activo.motivo_activacion) {
    return true;
  }
  if (motivo.length < 3) {
    return true;
  }
  return false;
}

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: 'center',
  },
  fileInput: {
    top: 'unset',
    left: 'unset',
    position: 'inherit',
    textAlign: 'left',
  },
}));
