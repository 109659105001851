import axios from 'axios';
import { BACKEND } from '../../constants/urls'
/**
 * Obtener tipos de recursos
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getTiposRecursos(filter = {}, cancelToken = null) {
  return axios.get(`${BACKEND}/tipos-recurso/`,
    {
      params: filter,
      cancelToken: cancelToken
    }).then(res => res.data.data);
}

/**
 * Obtener grupos contables
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
 export function getGruposContables(filter = {}, cancelToken = null) {
  return axios.get(`${BACKEND}/grupos-contables/`,
    {
      params: filter,
      cancelToken: cancelToken
    }).then(res => res.data);
}

/**
 * Método encargado de obtener todos los tipos de recursos.
 */
 export async function ObtenerTiposRecursos() {
  try {
    let url = `${BACKEND}/tipos-recurso`;
    let response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}