import axios from 'axios';
import { auth } from '../firebase';
import { BACKEND_GP, BACKEND_SDA } from '../../constants/urls';

var token = null;

/**
 * Método encargado obtener varios registros de una ruta
 * @param {String} ruta ruta a consultar del servidor
 * @param {Object} filtro
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {Object} cancelToken token para cancelacion
 */
export async function getMany(
  ruta = '',
  filtro = {},
  selects = [],
  populates = [],
  cancelToken = null,
  newToken = null,
) {
  let url = `${BACKEND_GP}${ruta}`;
  if (selects)
    selects.forEach((select) => {
      url = url + '/' + select;
    });
  if (populates)
    populates.forEach((populate) => {
      url = url + '/' + populate;
    });

  if (!token) token = await auth.currentUser.getIdToken(true);
  if (newToken) token = newToken;
  //console.log("URL consulñta: ", url);
  return axios
    .get(url, {
      params: filtro,
      headers: {
        authorization: `Bearer ${token}`,
      },
      cancelToken,
    })
    .then((res) => (res.data ? res.data : { status: false, data: { message: 'SIN RESPUESTA DEL SERVIDOR' } }))
    .catch(async (e) => {
      if (axios.isCancel(e)) {
        if (e.message) console.log('Request canceled', e.message);
        else console.log('Request canceled');
        return null;
      } else if (e.message === 'Request failed with status code 401') {
        console.log('Reintentar con nuevo token');
        newToken = await auth.currentUser.getIdToken(true);
        return getMany(ruta, filtro, selects, populates, cancelToken, newToken);
      } else {
        // handle error
        return { status: false, data: { message: 'SIN RESPUESTA DEL SERVIDOR' } };
      }
    });
}

/**
 * Método encargado obtener un registro por su id de una ruta
 * @param {String} ruta ruta a consultar del servidor
 * @param {String} id
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {Object} cancelToken token para cancelacion
 * @returns
 */
export async function getById(ruta = '', id, selects = [], populates = [], cancelToken = null, newToken = null) {
  let url = `${BACKEND_GP}${ruta}/${id}`;
  if (selects)
    selects.forEach((select) => {
      url = url + '/' + select;
    });
  if (populates)
    populates.forEach((populate) => {
      url = url + '/' + populate;
    });
  if (!token) token = await auth.currentUser.getIdToken(true);
  if (newToken) token = newToken;

  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${token}`,
      },
      cancelToken,
    })
    .then((res) => (res.data ? res.data : null))
    .catch(async (e) => {
      if (axios.isCancel(e)) {
        if (e.message) console.log('Request canceled', e.message);
        else console.log('Request canceled');
        return null;
      } else if (e.message === 'Request failed with status code 401') {
        console.log('Reintentar con nuevo token');
        newToken = await auth.currentUser.getIdToken(true);
        return getById(ruta, id, selects, populates, cancelToken, newToken);
      } else {
        // handle error
        return null;
      }
    });
}
/**
 * Método encargado de insertar un registro en el servidor
 * @param {*} ruta ruta a consultar del servidor
 * @param {*} data datos del nuevo registro
 * @param {Object} cancelToken token para cancelacion
 * @returns
 */
export async function postOne(ruta = '', data, cancelToken = null, newToken = null) {
  const url = `${BACKEND_GP}${ruta}`;

  if (!token) token = await auth.currentUser.getIdToken(true);
  if (newToken) token = newToken;

  return axios
    .post(url, data, {
      headers: {
        authorization: `Bearer ${token}`,
      },
      cancelToken,
    })
    .then((res) => (res.data ? res.data : { status: false, data: { message: 'SIN RESPUESTA DEL SERVIDOR' } }))
    .catch(async (e) => {
      if (axios.isCancel(e)) {
        if (e.message) console.log('Request canceled', e.message);
        else console.log('Request canceled');
        return null;
      } else if (e.message === 'Request failed with status code 401') {
        console.log('Reintentar con nuevo token');
        newToken = await auth.currentUser.getIdToken(true);
        return postOne(ruta, data, cancelToken, newToken);
      } else {
        // handle error
        return { status: false, data: { message: 'SIN RESPUESTA DEL SERVIDOR' } };
      }
    });
}
/**
 * Método encargado de borrar un registro por id
 * @param {*} ruta ruta a consultar del servidor
 * @param {*} id
 * @param {*} cancelToken token para cancelacion
 * @returns
 */
export async function deleteOne(ruta = '', id, cancelToken = null, newToken = null) {
  const url = `${BACKEND_GP}${ruta}/${id}`;
  if (!token) token = await auth.currentUser.getIdToken(true);
  if (newToken) token = newToken;

  return axios
    .delete(url, {
      headers: {
        authorization: `Bearer ${token}`,
      },
      cancelToken,
    })
    .then((res) => (res.data ? res.data : { status: false, data: { message: 'SIN RESPUESTA DEL SERVIDOR' } }))
    .catch(async (e) => {
      if (axios.isCancel(e)) {
        if (e.message) console.log('Request canceled', e.message);
        else console.log('Request canceled');
        return null;
      } else if (e.message === 'Request failed with status code 401') {
        console.log('Reintentar con nuevo token');
        newToken = await auth.currentUser.getIdToken(true);
        return deleteOne(ruta, id, cancelToken, newToken);
      } else {
        // handle error
        return { status: false, data: { message: 'SIN RESPUESTA DEL SERVIDOR' } };
      }
    });
}

/**
 * Método encargado de actualizar un registro que concuerdo con la id
 * @param {*} ruta ruta a consultar del servidor
 * @param {*} id
 * @param {*} data datos del nuevo registro
 * @param {*} cancelToken token para cancelacion
 * @returns
 */
export async function updateOne(ruta = '', id, data, cancelToken = null, newToken = null) {
  const url = `${BACKEND_GP}${ruta}/${id}`;
  if (!token) token = await auth.currentUser.getIdToken(true);
  if (newToken) token = newToken;
  return axios
    .put(url, data, {
      headers: {
        authorization: `Bearer ${token}`,
      },
      cancelToken,
    })
    .then((res) => (res.data ? res.data : { status: false, data: { message: 'SIN RESPUESTA DEL SERVIDOR' } }))
    .catch(async (e) => {
      if (axios.isCancel(e)) {
        if (e.message) console.log('Request canceled', e.message);
        else console.log('Request canceled');
        return null;
      } else if (e.message === 'Request failed with status code 401') {
        console.log('Reintentar con nuevo token');
        newToken = await auth.currentUser.getIdToken(true);
        return updateOne(ruta, id, data, cancelToken, newToken);
      } else {
        // handle error
        return { status: false, data: { message: 'SIN RESPUESTA DEL SERVIDOR' } };
      }
    });
}

/**
 * Método encargado obtener varios registros de una ruta
 * @param {String} ruta ruta a consultar del servidor
 * @param {Object} cancelToken token para cancelacion
 */
export async function getManySDA(ruta = '', cancelToken = null) {
  const url = `${BACKEND_SDA}${ruta}`;
  return auth.currentUser
    .getIdToken(true)
    .then((idToken) => {
      return axios.get(url, {
        headers: {
          authorization: `Bearer ${idToken}`,
        },
        cancelToken,
      });
    })
    .then((res) => (res.data ? res.data : { status: false, data: { message: 'SIN RESPUESTA DEL SERVIDOR' } }));
}

/**
 * Método encargado obtener un registro por su id de una ruta
 * @param {String} ruta ruta a consultar del servidor
 * @param {String} id
 * @param {Object} cancelToken token para cancelacion
 * @returns
 */
export async function getByIdSDA(ruta = '', id, cancelToken = null) {
  const url = `${BACKEND_SDA}${ruta}/${id}`;
  return auth.currentUser
    .getIdToken(true)
    .then((idToken) => {
      return axios.get(url, {
        headers: {
          authorization: `Bearer ${idToken}`,
        },
        cancelToken,
      });
    })
    .then((res) => (res.data ? res.data : { status: false, data: { message: 'SIN RESPUESTA DEL SERVIDOR' } }));
}
