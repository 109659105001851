import React from "react";
import MaterialTable from "../../../../components/materialTable";
import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

function Page(props) {
  const {
    gerencia,
    set_gerencia,
    set_contrato,
    tipo_recurso,
    set_tipo_recurso,
    clase,
    set_clase,
    gerencias,
    contratos,
    tipos_recursos,
    clases,
    bienes,
    handle_paso_2,
    usuario_sesion,
  } = props;

  let columns = [
    {
      title: "Código",
      field: "codigo_activo",
    }, {
      title: "Marca",
      field: "marca",
    }, {
      title: "Modelo",
      field: "modelo",
    }, {
      title: "Condición Actual",
      field: "condicion_actual",
    }, {
      title: "Clase",
      field: "_clase_ref.nombre",
    }, {
      title: "Gerencia",
      field: "gerencia",
    }, {
      title: "Contrato",
      field: "contrato",
    },
  ];

  let actions = [
    row => ({
      icon: "chevron_right",
      tooltip: "Asignar",
      // hidden: CheckHidden(usuario_sesion, row._gerencia_ref, row._contrato_ref),
      onClick: (event, row) => handle_paso_2(row),
    })
  ];

  return (
    <Grid container spacing={2} style={{ marginTop: 0, marginBottom: 0 }}>
      <Grid item xs={3} xl={3}>
        {/* FILTRO TIPO RECURSO */}
        <Autocomplete
          options={tipos_recursos}
          // value={tipo_recurso}
          loading={!tipos_recursos}
          disabled={!tipos_recursos}
          getOptionLabel={tipo_recurso => tipo_recurso.nombre}
          onChange={(event, value) => set_tipo_recurso(value)}
          size="small"
          autoComplete
          renderInput={(params) => (
            <TextField
              label="Tipo de Recurso"
              variant="outlined"
              {...params}
            />
          )}
        />
      </Grid>
      <Grid item xs={3} xl={3}>
        {/* FILTRO CLASE */}
        <Autocomplete
          options={clases}
          value={clase}
          loading={!clases}
          disabled={!tipo_recurso || !clases}
          getOptionLabel={clase => clase.nombre}
          onChange={(event, value) => set_clase(value)}
          size="small"
          autoComplete
          renderInput={(params) => (
            <TextField
              label="Clase"
              variant="outlined"
              {...params}
            />
          )}
        />
      </Grid>
      <Grid item xs={3} xl={3}>
        {/* FILTRO GERENCIA */}
        <Autocomplete
          options={gerencias}
          // value={gerencia}
          loading={!gerencias}
          disabled={!gerencias}
          getOptionLabel={gerencia => `[${gerencia.sigla}] ${gerencia.nombre}`}
          onChange={(event, value) => set_gerencia(value)}
          size="small"
          autoComplete
          renderInput={(params) => (
            <TextField
              label="Gerencia"
              variant="outlined"
              {...params}
            />
          )}
        />
      </Grid>
      <Grid item xs={3} xl={3}>
        {/* FILTRO CONTRATO */}
        <Autocomplete
          options={contratos}
          // value={contrato}
          loading={!contratos}
          disabled={!gerencia || !contratos}
          getOptionLabel={contrato => `[${contrato.codigo}] ${contrato.nombre}`}
          onChange={(event, value) => set_contrato(value)}
          size="small"
          autoComplete
          renderInput={(params) => (
            <TextField
              label="Contrato"
              variant="outlined"
              {...params}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} xl={12}>
        {/* TABLA DE BIENES */}
        <MaterialTable
          title="Activos"
          is_loading={!bienes}
          data={bienes}
          columns={columns}
          actions={actions}
          filtering={false}
          grouping={false}
          exportButton={false}
          page_size={6}
        />
      </Grid>
    </Grid>
  );
}

/**
 * Método encargado de verificar si el ACTION estará visible.
 * @param {*} usuario Datos del usuario.
 * @param {*} gerencia Datos de la gerencia del activo.
 * @param {*} contrato Datos del contrato del activo.
 * @returns Flag para determinar si se esconde o no el ACTION.
 */
function CheckHidden(usuario, gerencia, contrato) {
  if (usuario) {
    if (usuario.is_responsable_gerencia && gerencia && usuario.gerencia === gerencia._id) {
      return false;
    } else if (contrato && usuario.contrato === contrato._id) {
      return false;
    }
  }
  return true;
}

export default Page;