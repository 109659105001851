import React, { useState, Fragment, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router';
import MaterialTable from '../../components/materialTable';
import SelectSearch from '../../components/SelectSearch';
import { MTableAction } from '@material-table/core';
import { MainContext, ListadoVehiculosContext } from '../../App';
import { Button } from '@material-ui/core';
import DialogoActualizarKilometraje from './dialogoActualizarKilometraje';
import DialogoRegistroMantenimiento from './dialogoRegistroMantenimiento';
import DialogoActaEntrega from './dialogoActaEntrega';
import DialogoGPS from './dialogos/dialogoGPS';
import * as PERMISSIONS from '../../constants/permissions';
import { useProveedores } from '../../hooks/useProveedores';
import { CRUD_ACTIONS } from '../../constants/constantes';

function VehiculosCyD() {
  const { permisos } = useContext(MainContext);
  const { vehiculos } = useContext(ListadoVehiculosContext);
  const [activoSeleccionado, setActivoSeleccionado] = useState(null);
  const [OpenRegistroKilometraje, setOpenRegistroKilometraje] = useState(false);
  const [OpenRegistroMantenimiento, setOpenRegistroMantenimiento] = useState(false);
  const [OpenDialogoActaEntrega, setOpenDialogoActaEntrega] = useState(false);
  const [openRegistroEquipamiento, setOpenRegistroEquipamiento] = useState(false);
  const [marcas, setMarcas] = useState([]);
  const [clases, setClases] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [contratos, setContratos] = useState([]);
  const [gerencias, setGerencias] = useState([]);
  const [sociedades, setSociedades] = useState([]);
  const [colores, setColores] = useState([]);
  const { proveedores } = useProveedores();

  const history = useHistory();

  const gestionDatos = () => {
    console.log(vehiculos);
    const marcas = [...new Map(vehiculos.map((v) => [v.marca, v])).values()];
    const modelos = [...new Map(vehiculos.map((v) => [v.modelo, v])).values()];
    const clases = [...new Map(vehiculos.map((v) => [v.clase, v])).values()];
    const contratos = [...new Map(vehiculos.map((v) => [v.contrato, v])).values()];
    const gerencias = [...new Map(vehiculos.map((v) => [v.gerencia, v])).values()];
    const sociedades = [...new Map(vehiculos.map((v) => [v.sociedad, v])).values()];
    const colores = [...new Map(vehiculos.map((v) => [v.color, v])).values()];
    const optMarcas = marcas.map((m) => m.marca);
    const optClases = clases.map((m) => ({ clase: m.clase }));
    const optModelos = modelos.map((m) => ({ modelo: m.modelo }));
    const optContratos = contratos.map((m) => ({
      contrato: m.contrato,
      codigo: m.codigo_contrato,
    }));
    const optGerencias = gerencias.map((m) => ({ gerencia: m.gerencia }));
    const optSociedades = sociedades.map((m) => ({ sociedad: m.sociedad }));
    const optColores = colores.map((m) => ({ color: m.color }));

    setMarcas(optMarcas);
    setClases(optClases);
    setModelos(optModelos);
    setContratos(optContratos);
    setGerencias(optGerencias);
    setSociedades(optSociedades);
    setColores(optColores);
  };

  useEffect(() => {
    gestionDatos();
  }, [vehiculos]);

  function handleOpenRegistroKilometraje(seleccionado, open) {
    setActivoSeleccionado(seleccionado);
    setOpenRegistroKilometraje(open);
  }

  function handleOpenRegistroMantenimiento(seleccionado, open) {
    setActivoSeleccionado(seleccionado);
    setOpenRegistroMantenimiento(open);
  }

  const handleOpenRegistroEquipamiento = useCallback((seleccionado, open) => {
    setActivoSeleccionado(seleccionado);
    setOpenRegistroEquipamiento(open);
  }, []);

  function handleOpenDialogoActaEntrega(seleccionado, open) {
    setActivoSeleccionado(seleccionado);
    setOpenDialogoActaEntrega(open);
  }

  const filterSearch = (term, field) => (rowData) => {
    if (rowData[field]) {
      if (Array.isArray(term)) {
        return term.length === 0 || term.some((x) => rowData[field] === x);
      } else {
        return rowData[field].toLowerCase().includes(term.toLowerCase());
      }
    }
    return term.length === 0;
  };

  const columns = [
    {
      title: 'Código Activo',
      field: 'codigo_activo',
      align: 'center',
    },
    {
      title: 'Marca',
      field: 'marca',
      align: 'center',
      customFilterAndSearch: (term, rowData) => filterSearch(term, 'marca')(rowData),
      filterComponent: (propsFilter) => (
        <SelectSearch
          options={marcas.map((m) => ({ id: m, nombre: m }))}
          input={{ name: 'filtroMarca' }}
          meta={{}}
          multiple
          limitTags={1}
          charCountLimit={12}
          maxTagWidth="105px"
          size="small"
          type="text"
          change={(name, value) => {
            propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
          }}
        />
      ),
    },
    {
      title: 'Modelo',
      field: 'modelo',
      align: 'center',
      customFilterAndSearch: (term, rowData) => filterSearch(term, 'modelo')(rowData),
      filterComponent: (propsFilter) => (
        <SelectSearch
          options={modelos.map((m) => ({ id: m.modelo, nombre: m.modelo }))}
          input={{ name: 'filtroModelo' }}
          meta={{}}
          multiple
          limitTags={1}
          charCountLimit={12}
          maxTagWidth="105px"
          size="small"
          type="text"
          change={(name, value) => {
            propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
          }}
        />
      ),
    },
    {
      title: 'Clase',
      field: 'clase',
      align: 'center',
      customFilterAndSearch: (term, rowData) => filterSearch(term, 'clase')(rowData),
      filterComponent: (propsFilter) => (
        <SelectSearch
          options={clases.map((clase) => ({ id: clase.clase, nombre: clase.clase }))}
          input={{ name: 'filtroClase' }}
          meta={{}}
          multiple
          limitTags={1}
          charCountLimit={12}
          maxTagWidth="105px"
          size="small"
          type="text"
          change={(name, value) => {
            propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
          }}
        />
      ),
    },
    {
      title: 'Patente',
      field: 'patente',
      align: 'center',
    },
    {
      title: 'Kilometraje',
      field: 'kilometraje',
      align: 'center',
      render: (row) => (row ? `${Intl.NumberFormat('es-ES').format(row.kilometraje)} Kms.` : ''),
    },
    {
      title: 'Asignado a',
      field: '_asignado_ref',
      cellStyle: {
        minWidth: '150px',
      },
    },
    {
      title: 'Gerencia',
      field: 'gerencia',
      align: 'center',
      customFilterAndSearch: (term, rowData) => filterSearch(term, 'gerencia')(rowData),
      filterComponent: (propsFilter) => (
        <SelectSearch
          options={gerencias.map((clase) => ({
            id: clase.gerencia,
            nombre: clase.gerencia,
          }))}
          input={{ name: 'filtroGerencia' }}
          meta={{}}
          multiple
          limitTags={1}
          charCountLimit={12}
          maxTagWidth="105px"
          size="small"
          type="text"
          change={(name, value) => {
            propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
          }}
        />
      ),
    },
    {
      title: 'Contrato',
      field: 'contrato',
      align: 'center',
      customFilterAndSearch: (term, rowData) => filterSearch(term, 'contrato')(rowData),
      filterComponent: (propsFilter) => (
        <SelectSearch
          options={contratos.map((clase) => ({
            id: clase.contrato,
            nombre: clase.contrato,
          }))}
          input={{ name: 'filtroContrato' }}
          meta={{}}
          multiple
          limitTags={1}
          charCountLimit={12}
          maxTagWidth="105px"
          size="small"
          type="text"
          change={(name, value) => {
            propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
          }}
        />
      ),
    },
    {
      title: 'Código Contrato',
      field: 'codigo_contrato',
      align: 'center',
      customFilterAndSearch: (term, rowData) => filterSearch(term, 'codigo_contrato')(rowData),
      filterComponent: (propsFilter) => (
        <SelectSearch
          options={contratos.map((clase) => ({ id: clase.codigo, nombre: clase.codigo }))}
          input={{ name: 'filtroCodigoContrato' }}
          meta={{}}
          multiple
          limitTags={1}
          charCountLimit={12}
          maxTagWidth="105px"
          size="small"
          type="text"
          change={(name, value) => {
            propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
          }}
        />
      ),
    },
    {
      title: 'Sociedad',
      field: 'sociedad',
      align: 'center',
      customFilterAndSearch: (term, rowData) => filterSearch(term, 'sociedad')(rowData),
      filterComponent: (propsFilter) => (
        <SelectSearch
          options={sociedades.map((clase) => ({
            id: clase.sociedad,
            nombre: clase.sociedad,
          }))}
          input={{ name: 'filtroSociedad' }}
          meta={{}}
          multiple
          limitTags={1}
          charCountLimit={12}
          maxTagWidth="105px"
          size="small"
          type="text"
          change={(name, value) => {
            propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
          }}
        />
      ),
    },
    {
      title: 'Color',
      field: 'color',
      align: 'center',
      customFilterAndSearch: (term, rowData) => filterSearch(term, 'colores')(rowData),
      filterComponent: (propsFilter) => (
        <SelectSearch
          options={colores.map((clase) => ({ id: clase.color, nombre: clase.color }))}
          input={{ name: 'filtroColor' }}
          meta={{}}
          multiple
          limitTags={1}
          charCountLimit={12}
          maxTagWidth="105px"
          size="small"
          type="text"
          change={(name, value) => {
            propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
          }}
        />
      ),
      hidden: true,
    },
    {
      title: 'Nº Chasis',
      field: 'numero_chasis',
      align: 'center',
      //hidden: true,
    },
    {
      title: 'Nº Motor',
      field: 'numero_motor',
      align: 'center',
      // hidden: true,
    },
    {
      title: 'Transmisión',
      field: 'transmision',
      align: 'center',
      hidden: true,
    },
    {
      title: 'Tipo Combustible',
      field: 'tipo_combustible',
      align: 'center',
      hidden: true,
    },
  ];

  const actions = [
    {
      icon: 'chevron_right',
      tooltip: 'Ver Detalles',
      onClick: (event, rowData) => history.push(`activos/${rowData._id}`),
    },
    {
      icon: 'update_km',
      tooltip: 'Actualizar KM',
      position: 'row',
      onClick: (event, row) => handleOpenRegistroKilometraje(row, true),
    },
    {
      icon: 'mdiCarWrench',
      tooltip: 'Registrar Mantención',
      position: 'row',
      hidden: !permisos[PERMISSIONS.VER_BOTON_MANTENCION_VEHICULO],
      onClick: (event, row) => handleOpenRegistroMantenimiento(row, true),
    },
    {
      icon: 'acta_entrega',
      tooltip: 'Registro Acta/Entrega',
      position: 'row',
      hidden: !permisos[PERMISSIONS.VER_BOTON_ACTA_ENTREGA_VEHICULO],
      onClick: (event, row) => handleOpenDialogoActaEntrega(row, true),
    },
    {
      icon: 'gps',
      tooltip: 'Registro GPS',
      position: 'row',
      hidden: !permisos[PERMISSIONS.AGREGAR_DISPOSITIVO_GPS],
      onClick: (event, row) => handleOpenRegistroEquipamiento(row, true),
    },
  ];

  return (
    <Fragment>
      <MaterialTable
        title="Gestión Flota de Vehículos CyD"
        fullWidth
        is_loading={!vehiculos}
        data={vehiculos}
        columns={columns}
        grouping={false}
        actions={actions}
        components={{
          Action: (propData) => {
            const rowData = propData.data;
            if (propData.action.icon === 'update_km') {
              return (
                <Button
                  onClick={(event) => propData.action.onClick(event, rowData)}
                  color="primary"
                  style={{
                    color: 'white',
                    backgroundColor: '#731f1f',
                    marginLeft: 5,
                    textTransform: 'none',
                  }}
                  variant="contained"
                  size="small"
                >
                  {'Actualizar KM'}
                </Button>
              );
            }
            if (propData.action.icon === 'mdiCarWrench') {
              return (
                !propData.action.hidden && (
                  <Button
                    onClick={(event) => propData.action.onClick(event, rowData)}
                    color="primary"
                    style={{
                      color: 'white',
                      backgroundColor: '#731f1f',
                      marginLeft: 5,
                      textTransform: 'none',
                    }}
                    variant="contained"
                    size="small"
                  >
                    {'Registrar Mantención'}
                  </Button>
                )
              );
            }
            if (propData.action.icon === 'acta_entrega') {
              return (
                !propData.action.hidden && (
                  <Button
                    onClick={(event) => propData.action.onClick(event, rowData)}
                    color="primary"
                    style={{
                      color: 'white',
                      backgroundColor: '#731f1f',
                      marginLeft: 5,
                      textTransform: 'none',
                    }}
                    variant="contained"
                    size="small"
                  >
                    {'Registro Acta/Entrega'}
                  </Button>
                )
              );
            }
            if (propData.action.icon === 'gps') {
              return (
                !propData.action.hidden && (
                  <Button
                    onClick={(event) => propData.action.onClick(event, rowData)}
                    color="primary"
                    style={{
                      color: 'white',
                      backgroundColor: '#731f1f',
                      marginLeft: 5,
                      textTransform: 'none',
                    }}
                    variant="contained"
                    size="small"
                  >
                    {'Registro GPS'}
                  </Button>
                )
              );
            }
            return <MTableAction {...propData} />;
          },
        }}
      />

      <DialogoActualizarKilometraje
        activo_seleccionado={activoSeleccionado}
        is_open={OpenRegistroKilometraje}
        handle_open={handleOpenRegistroKilometraje}
      />

      <DialogoRegistroMantenimiento
        activo_seleccionado={activoSeleccionado}
        proveedores={proveedores}
        is_open={OpenRegistroMantenimiento}
        handle_open={handleOpenRegistroMantenimiento}
      />
      <DialogoActaEntrega
        activo_seleccionado={activoSeleccionado}
        is_open={OpenDialogoActaEntrega}
        handle_open={handleOpenDialogoActaEntrega}
      />
      <DialogoGPS
        activo_seleccionado={activoSeleccionado}
        is_open={openRegistroEquipamiento}
        handle_open={handleOpenRegistroEquipamiento}
        action={CRUD_ACTIONS.ADD}
      />
    </Fragment>
  );
}

export default VehiculosCyD;
