import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  InputLabel,
  InputAdornment,
  makeStyles,
  IconButton,
  Paper,
  Container,
  Tab,
  Tabs,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Tooltip
} from "@material-ui/core";
import { Add, ArrowBack, Visibility as VisibilityIcon } from '@material-ui/icons';
import React, { useContext, useState } from "react";
import MaterialReactSelect from "../../../components/materialReactSelect";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import FileInput from "../../../components/fileInput";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { VerSolicitudActivacionContext } from ".";
import './style.css';
import { useHistory } from "react-router";
import DialogoAgregarProveedor from "./dialogoAgregarProveedor";

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(1, 1, 3, 1)
  },
  center: {
    textAlign: "center",
  },
  main: {
    overflow: "auto",
    maxWidth: "95vw"
  },
  card: {
    marginBottom: theme.spacing(2)
  },
  tableHeader: {
    textAlign: 'center',
    border: "1px solid #E0E0E0",
    fontWeight: 'bold',
    color: '#731f1f',
    position: 'sticky'
  },
  tableCell: {
    border: "1px solid #E0E0E0"
  },
  btnMargin: {
    margin: theme.spacing(1)
  },
  fileInput: {
    top: 'unset',
    left: 'unset',
    position: 'inherit'

  },
  container: {
    paddingTop: theme.spacing(3),
    maxWidth: "100%"
  },
  right: {
    textAlign: "right",
  }
}));

export default function Page(props) {
  const {
    dialogMessage,
    dialogTitle,
    esActivable,
    formik,
    handleCloseDialog,
    handleVerArchivoFactura,
    handleSubmitActivacion,
    handleSubmitNoActivar,
    handleSubmitRechazo,
    title,
    openDialog,
    proveedores,
    propietarios,
    showCloseDialog,
    refreshProveedores,
    isSubmiting
  } = useContext(VerSolicitudActivacionContext);

  const history = useHistory();

  const { errors, handleChange, touched, values, handleSubmit } = formik;
  const fileChange = (name, e) => {
    formik.setFieldValue(name, e.target.files[0]);
    formik.setFieldTouched(name, true, false);
  };
  const dateChange = (name, e) => {
    formik.setFieldValue(name, e);
    formik.setFieldTouched(name, true, false);
  };
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);

  const [openDialogoProveedor, setOpenDialogoProveedor] = useState(false);


  return (
    <Container className={classes.main}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Paper>
          <Grid container alignContent="center" justify="center">
            <Grid item lg={4}></Grid>
            <Grid item lg={4} className={classes.center}>
              <Typography align="center" variant="h5" style={{ padding: "16px" }}>
                {title}
              </Typography>
            </Grid>
            <Grid item lg={4} className={classes.right}>
              <Tooltip title="Volver">
                <IconButton onClick={() => history.goBack()} style={{ margin: '8px' }}>
                  <ArrowBack></ArrowBack>
                </IconButton>
              </Tooltip>
            </Grid>

          </Grid>

          <Grid container alignContent="center" justify="center">
            <Grid item lg md sm xs className={classes.center}>
              <Tabs
                indicatorColor="primary"
                value={selectedTab}
                onChange={(e, newValue) => setSelectedTab(newValue)}
                aria-label="content-tabs"
                variant="scrollable"
                // centered
                scrollButtons="on"
              >
                <Tab label="Información Básica" id="tabDetalles" aria-controls="panelDetalles" />
                <Tab label="Contabilidad" id="tabContabilidad" aria-controls="panelContabilidad" />
              </Tabs>
            </Grid>
          </Grid>
          <Container className={classes.container}>
            {selectedTab === 0 &&
              <div id="panelDetalles">

                <Grid container spacing={1}>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <TextField
                      name="solicitante"
                      label="Solicitante"
                      fullWidth
                      variant={"outlined"}
                      margin={"normal"}
                      value={values.solicitante}
                      disabled
                    />
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <TextField
                      name="sociedad"
                      label="Sociedad"
                      fullWidth
                      variant={"outlined"}
                      margin={"normal"}
                      value={values.sociedad}
                      disabled
                    />
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <TextField
                      name="contrato"
                      label="Contrato"
                      fullWidth
                      variant={"outlined"}
                      margin={"normal"}
                      value={values.contrato}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <TextField
                      name="clase"
                      label="Clase"
                      fullWidth
                      variant={"outlined"}
                      margin={"normal"}
                      value={values.clase}
                      disabled
                    />
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <TextField
                      name="marca"
                      label="Marca"
                      fullWidth
                      variant={"outlined"}
                      margin={"normal"}
                      value={values.marca}
                      disabled
                    />
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <TextField
                      name="numero_serie"
                      label="Código S/N"
                      fullWidth
                      variant={"outlined"}
                      margin={"normal"}
                      value={values.numero_serie}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <TextField
                      name="modelo"
                      label="Modelo"
                      fullWidth
                      variant={"outlined"}
                      margin={"normal"}
                      value={values.modelo}
                      disabled
                    />
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <TextField
                      name="condicion_actual"
                      label="Condición Actual"
                      fullWidth
                      variant={"outlined"}
                      margin={"normal"}
                      value={values.condicion_actual}
                      disabled
                    />
                  </Grid>
                </Grid>

              </div>
            }
            {selectedTab === 1 &&
              <div id="panelContabilidad">
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField
                      name="codigo_contable"
                      label={`Código Contable ${values.codigo_contable ? "" : "(Generado automáticamente al ser activado)"}`}
                      fullWidth
                      variant={"outlined"}
                      margin={"normal"}
                      value={values.codigo_contable}
                      onChange={handleChange}
                      disabled
                      error={touched.codigo_contable && errors.codigo_contable && Boolean(errors.codigo_contable)}
                      helperText={touched.codigo_contable && errors.codigo_contable ? errors.codigo_contable : ""}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <MaterialReactSelect
                      label="Propietario"
                      isClearable
                      onChange={(newValue, actionMeta) => newValue ? formik.setFieldValue("propietario", newValue) : formik.setFieldValue("propietario", "")}
                      placeholder="Seleccionar un Propietario"
                      options={propietarios}
                      noOptionsMessage={() => "No ha encontrado el propietario"}
                      error={touched.propietario && errors.propietario && Boolean(errors.propietario)}
                      helperText={touched.propietario && errors.propietario ? errors.propietario : ""}
                      value={values.propietario}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12} style={{ display: 'flex' }}>
                    <MaterialReactSelect
                      label="Proveedor"
                      isClearable
                      onChange={(newValue, actionMeta) => newValue ? formik.setFieldValue("proveedor", newValue) : formik.setFieldValue("proveedor", "")}
                      placeholder="Seleccionar un Proveedor"
                      options={proveedores}
                      noOptionsMessage={() => "No ha encontrado el proveedor"}
                      error={touched.proveedor && errors.proveedor && Boolean(errors.proveedor)}
                      helperText={touched.proveedor && errors.proveedor ? errors.proveedor : ""}
                      value={values.proveedor}
                    />
                    <Tooltip title="Agregar Proveedor">
                      <Button variant="contained" style={{ margin: "18px 4px 14px 4px", boxShadow: "none" }} color="primary" onClick={() => setOpenDialogoProveedor(true)} >
                        <Add />
                      </Button>
                    </Tooltip>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField
                      name="numero"
                      label="N° Factura"
                      fullWidth
                      variant={"outlined"}
                      margin={"normal"}
                      value={values.numero}
                      onChange={handleChange}
                      error={touched.numero && errors.numero && Boolean(errors.numero)}
                      helperText={touched.numero && errors.numero ? errors.numero : ""}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <CurrencyTextField
                      label="Monto"
                      value={values.valor}
                      fullWidth
                      variant="outlined"
                      margin={"normal"}
                      onChange={(event, value) => formik.setFieldValue("valor", value)}
                      error={touched.valor && errors.valor && Boolean(errors.valor)}
                      helperText={touched.valor && errors.valor ? errors.valor : ""}
                      currencySymbol="$"
                      minimumValue="0"
                      maximumValue="1000000000"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      leading="deny"
                      decimalPlaces={0}
                      decimalPlacesShownOnBlur={0}
                      decimalPlacesShownOnFocus={0}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                      <KeyboardDatePicker
                        name="fecha"
                        autoOk
                        fullWidth
                        InputLabelProps={{
                          classes: {
                            shrink: "shrink"
                          }
                        }}
                        // views={['year']}
                        margin="normal"
                        inputVariant="outlined"
                        label="Fecha Factura"
                        format="dd/MM/yyyy"
                        helperText={touched.fecha ? errors.fecha : ""}
                        error={touched.fecha && Boolean(errors.fecha)}
                        onChange={dateChange.bind(null, "fecha")}
                        value={values.fecha}
                      />
                    </MuiPickersUtilsProvider>

                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                          <IconButton
                            aria-label="Ver Archivo"
                            onClick={() => handleVerArchivoFactura(values.archivoFactura)}
                            edge="end"
                            disabled={values.archivoFactura.url ? false : true}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </InputAdornment>
                      }}
                      name="factura"
                      label="Factura"
                      fullWidth
                      variant={"outlined"}
                      margin={"normal"}
                      value={values.archivoFactura.nombre}
                      // onChange={handleChange}
                      disabled
                    // error={touched.valor && errors.valor && Boolean(errors.valor)}
                    // helperText={touched.valor && errors.valor ? errors.valor : ""} 
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormControl
                      fullWidth
                      error={touched.archivo && errors.archivo && Boolean(errors.archivo)}
                    >
                      <InputLabel shrink className={classes.fileInput}>Actualizar Factura</InputLabel>
                      <FileInput
                        inputName="archivo"
                        meta={{ touched: touched.archivo, errors: errors.archivo }}
                        buttonColor="primary"
                        buttonVariant="outlined"
                        label="Archivo Factura"
                        value={values.archivo}
                        handleOnChange={fileChange.bind(null, "archivo")}
                        textVariant="outlined"
                      />
                      <FormHelperText>
                        {touched.archivo && errors.archivo ? errors.archivo : ""}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>

              </div>
            }
          </Container>

        </Paper>
        <Paper style={{ marginTop: '8px', marginBottom: '8px', padding: '8px 0px 8px 0px' }}>
          <Grid container alignContent="center" justify="center">
            <Grid item lg={4} md={6} sm={6} xs={12} className={classes.center}>
              <TextField
                name="comentario"
                label="Observaciones"
                fullWidth
                variant={"outlined"}
                margin={"normal"}
                rows={3}
                value={values.comentario}
                onChange={handleChange}
                error={touched.comentario && errors.comentario && Boolean(errors.comentario)}
                helperText={touched.comentario && errors.comentario ? errors.comentario : ""}
                multiline={true}
              />
            </Grid>
          </Grid>
          <Grid container alignContent="center" justify="center">
            <Grid item lg md sm xs className={classes.center}>
              <Button disabled={!esActivable || isSubmiting} className={classes.btnMargin} onClick={() => handleSubmitRechazo(values.comentario)} color="primary" variant="outlined">
                Rechazar
              </Button>
              <Button disabled={!esActivable || isSubmiting} className={classes.btnMargin} onClick={() => handleSubmitNoActivar(values.comentario)} color="primary" variant="outlined">
                No Activar
              </Button>
              <Button disabled={isSubmiting} className={classes.btnMargin} type="submit" color="primary" variant="contained">
                Actualizar
              </Button>
              <Button disabled={!esActivable || isSubmiting} className={classes.btnMargin} onClick={handleSubmitActivacion} color="primary" variant="contained">
                Aceptar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
      <DialogoAgregarProveedor
        open={openDialogoProveedor}
        closeDialog={() => setOpenDialogoProveedor(false)}
        onSubmit={refreshProveedores}
      />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          {dialogMessage}
        </DialogContent>
        <DialogActions>
          {showCloseDialog && <Button onClick={handleCloseDialog} color="primary" variant="outlined">
            Cerrar
          </Button>
          }
        </DialogActions>
      </Dialog>
    </Container>
  );
}