import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, TextField, FormControl } from "@material-ui/core";
import { useFormik } from "formik";
import { object, string } from "yup";
import React, { useContext, useState } from "react";
import { MainContext } from "../../App";
import { postRegistroKilometraje } from "../../services/request/requestBienes";
import { postActaEntregaVehiculo } from "../../services/request/requestActaEntregaVehiculo";
import { getUsuarios } from "../../services/request/requestUsuarios";
import { getContratos } from "../../services/request/requestPortafolio";
import { GenerarLog } from "../../services/activoLog";
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSnackbar } from "notistack";
import moment from "moment";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { DatePicker } from '@material-ui/pickers';
import MaterialReactSelect from "../../components/materialReactSelect";
import { Autocomplete } from "@material-ui/lab";
import useSWR from 'swr';
import { InfoVehiculo } from "./components/InfoVehiculo";
import { VER_SELECCION_GERENCIAS, VER_CONTRATOS_GERENCIA } from "../../constants/permissions";

const useStyles = makeStyles(theme => ({
  subtitle: {
    margin: theme.spacing(2, 0, 2, 0)
  },
  contenedorPrincipal:{
    padding: 5
  },
  items:{
    marginBottom: theme.spacing(0.7),
  },
  itemsMantenimiento:{
    marginRight: theme.spacing(0.3),
    padding: theme.spacing(1),
    border: '1px solid grey',
  },
  formselect:{
    marginTop: -16,
  },
  text_disabled: {
    "& input.Mui-disabled": {
      background: "rgb(242, 246, 246)",
      color: "grey"
    }
  },
  input_required: {
      background: "rgb(247, 249, 216)",
      color: "black"
  },
  select_color:{    
    background: "rgb(247, 249, 216)",
    color: "black"
  }
}));


const Tipos_Motivo = [{label: "Asignación", value: "Asignacion"}, {label: "Arriendo", value: "Arriendo"}, {label: "Préstamo", value: "Prestamo"}, {label: "Dev. a GAF", value: "Dev. a GAF"}];

export default function DialogoRegistroMantenimiento(props) {
  const {
      activo_seleccionado,      
      is_open,
      handle_open,
  } = props;

  const { usuarioSesion, permisos } = useContext(MainContext);  
  const [habilitaPatente, setHabilitaPatente] = useState(false);
  const [editarMantencion, setEditarMantencion] = useState(false);

  //Permite obtener los contratos que puede visualizar el usuario, segun sus permisos
function getDataContratos() {
  if (permisos[VER_SELECCION_GERENCIAS]){			
    return getContratos(); 
  }else{						
    if (permisos[VER_CONTRATOS_GERENCIA]) { 			
      return getContratos({gerencia_ref: usuarioSesion.gerencia});     				
    }else{
      return getContratos({_id: usuarioSesion.contrato});
    }			
  }
}
  
  const { data: usuarios, isValidating: isValidatingUsuarios } = useSWR("personas", (key) => getUsuarios({ is_gsuite_talana: true }), { revalidateOnFocus: false });
  const { data: contratos, isValidating: isValidatingContratos } = useSWR("contratos", (key) => getDataContratos(), { revalidateOnFocus: false });

  const notistack = useSnackbar();

  const formik = useFormik({
    initialValues: {
      _vehiculo_ref: "",
      _bien_ref: null,      
      patente: "",
      codigo_activo: "",
      color: "",      
      marca: "",      
      modelo: "",
      ano: moment().startOf('year').set("month", 4).toDate(),
      kilometraje: 0,
      fecha_creacion: new Date(),
      _entregado_ref: "",
      _recibido_ref: "",
      _recibido: "",
      _usuario_final_ref: "",
      _usuario_final: "",
      _contrato_origen_ref: "",
      _contrato_destino_ref: "",
      motivo: "",      
      descripcion: ""      
    },
    validationSchema: object().shape({  
      kilometraje: string().required("Debe ingresar el Kilometraje"),      
    }), 
    onSubmit: async (values) => {
      console.log("Valores usuario: ", usuarioSesion);
      //console.log("vehiculo: ", activo_seleccionado);
      const key = notistack.enqueueSnackbar("Guardando cambios...", {
        persist: true,
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        }
      });
      try {
        if (activo_seleccionado) {
          let registroLog = {
            _bien_ref: activo_seleccionado._id,
            titulo: "Acta de Entrega Vehicular",
            cuerpo: `El usuario ${usuarioSesion.nombre} registró la entrega del vehículo: ${activo_seleccionado.codigo_activo}. al usuario: ${values._recibido}`,
            tipo: "actualizacion",
            icon: "edit",
          }           
          if (activo_seleccionado._vehiculo_ref) { 
            console.log("Valores a guardar: ", values);
            await postActaEntregaVehiculo({
              _vehiculo_ref: activo_seleccionado._vehiculo_ref._id,
              _bien_ref: values._bien_ref,           
              kilometraje: values.kilometraje, 
              _entregado_ref: usuarioSesion.ref,
              _recibido_ref: values._recibido_ref,
              //_recibido: values._recibido,
              _usuario_final_ref: values._usuario_final_ref,
              //_usuario_final: values._usuario_final,
              _contrato_origen_ref: values._contrato_origen_ref,
              _contrato_destino_ref: values._contrato_destino_ref,
              motivo: values.motivo,             
              descripcion: values.descripcion                            
            }); 

            await postRegistroKilometraje({_vehiculo_ref: activo_seleccionado._vehiculo_ref._id,
                                    _bien_ref: values._bien_ref, // activo_seleccionado._id,                                    
                                    kilometraje: values.kilometraje,
                                    fecha_kilometraje: new Date(),
                                    descripcion: "",
                                    foto_tacometro: null
                                  });             
          }
          let registroLogKM = {
            _bien_ref: activo_seleccionado._id,
            titulo: "Actualización de Kilometraje",
            cuerpo: `Se actualizó automáticamente el kilometraje del activo ${activo_seleccionado.codigo_activo} a ${values.kilometraje} Kms. al registrar el Acta de Entrega`,
            tipo: "actualizacion",
            icon: "edit",
          }                 
          await GenerarLog(registroLog);
          await GenerarLog(registroLogKM);          
          handleClose();
        }
        notistack.closeSnackbar(key);
        const successkey = notistack.enqueueSnackbar("Operanción exitosa: Acta de Entrega registrada correctamente", {
          variant: 'success',
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
        });
      }
      catch (e) {
        console.log(e);
        notistack.closeSnackbar(key);
        const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible ingresar el Acta de Entrega. Intente de nuevo o contacte con Soporte.", {
          variant: 'error',
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
        });
      }
    }
  });

  const classes = useStyles();
  const { handleSubmit, values, touched, errors, setFieldValue, resetForm, handleChange } = formik;

  function handleEntered() {
    console.log("Acta/vehiculo seleccionado: ", activo_seleccionado);
    activo_seleccionado ? setHabilitaPatente(true) : setHabilitaPatente(false);
    activo_seleccionado?._bien_ref ? setEditarMantencion(true) : setEditarMantencion(false);

    let values = {   
      _vehiculo_ref: activo_seleccionado && activo_seleccionado?._vehiculo_ref ? activo_seleccionado._vehiculo_ref : "", 
      _bien_ref: activo_seleccionado ? (activo_seleccionado?._bien_ref ? activo_seleccionado?._bien_ref : activo_seleccionado?._id) : "",    
      marca: activo_seleccionado ? (activo_seleccionado?._bien_ref ? activo_seleccionado?._bien_ref.marca : activo_seleccionado?.marca) : "",      
      modelo: activo_seleccionado ? (activo_seleccionado?._bien_ref ? activo_seleccionado?._bien_ref.modelo : activo_seleccionado?.modelo) : "",
      patente: activo_seleccionado ? activo_seleccionado?._vehiculo_ref?.patente : "",
      codigo_activo: activo_seleccionado ? (activo_seleccionado?._bien_ref ? activo_seleccionado?._bien_ref.codigo_activo : activo_seleccionado?.codigo_activo) : "",
      color: activo_seleccionado ? activo_seleccionado?._vehiculo_ref?.color : "",
      ano: moment().startOf('year').set("month", 4).toDate(),
      archivo: null,
      kilometraje: activo_seleccionado ? (activo_seleccionado?.kilometraje ? activo_seleccionado?.kilometraje : 0) : 0,
      fecha_creacion: new Date(),
      _entregado_ref: activo_seleccionado && activo_seleccionado?._entregado_ref ? activo_seleccionado?._entregado_ref : "",
      _recibido_ref: activo_seleccionado && activo_seleccionado?._recibido_ref ? activo_seleccionado?._recibido_ref : "",
      _usuario_final_ref: activo_seleccionado && activo_seleccionado?._usuario_final_ref ? activo_seleccionado?._usuario_final_ref : "",
      _contrato_origen_ref: activo_seleccionado && activo_seleccionado?._contrato_origen_ref ? activo_seleccionado?._contrato_origen_ref : "",
      _contrato_destino_ref: activo_seleccionado && activo_seleccionado?._contrato_destino_ref ? activo_seleccionado?._contrato_destino_ref : "",
      motivo: "",      
      descripcion: "" 
    };
    
    resetForm({ values: values });
  }

  const dateChange = (name, e) => {
    formik.setFieldValue(name, e);
    formik.setFieldTouched(name, true, false);
  };

  const handleClose = () => {
    resetForm({ values: formik.initialValues });
    handle_open(null, false);   
  }
   
  return (
    <>
    
    <Dialog
      open={is_open}
      onClose={handleClose}      
      TransitionProps={{
        onEntered: handleEntered
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>Registro Acta / Entrega Vehiculo</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>

          {/* DATOS DEL VEHÍCULO */}
          <Grid container spacing={0} className={classes.contenedorPrincipal}>
            <InfoVehiculo 
              values={values} 
              formik={formik}  
              FullDetalle= {false} 
              TipoDeshabilitado= {true}
              habilitaPatente = {habilitaPatente}              
            />
          </Grid>

          <Grid container spacing={0} className={classes.contenedorPrincipal}>                  
            <Grid container spacing={1}>
            <Grid item xs={3} className={classes.items}>                
                <DatePicker
                  name="fecha_creacion"
                  label="Fecha"
                  value={values.fecha_creacion}
                  onChange={dateChange.bind(null, 'fecha_creacion')}
                  inputVariant="outlined"
                  format="dd/MM/yy"
                  cancelLabel="Cancelar"
                  okLabel="Aceptar"
                  fullWidth
                  error={touched.fecha_creacion && errors.fecha_creacion && Boolean(errors.fecha_creacion)}
                  helperText={touched.fecha_creacion && errors.fecha_creacion ? errors.fecha_creacion : ''}
                />
              </Grid> 
            <Grid item xs={3} className={classes.items}>                
                <CurrencyTextField
                  label="Kilometraje Actual"
                  value={values.kilometraje}
                  onChange={(event, value) => {
                    setFieldValue("kilometraje", value);                  
                  }}
                  error={touched.kilometraje && errors.kilometraje && Boolean(errors.kilometraje)}
                  helperText={touched.kilometraje && errors.kilometraje ? errors.kilometraje : ""}
                  fullWidth                  
                  variant="outlined"                
                  currencySymbol="Km"
                  minimumValue="0"
                  maximumValue="1000000000"
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  leading="deny"
                  decimalPlaces={0}
                  decimalPlacesShownOnBlur={0}
                  decimalPlacesShownOnFocus={0}
                  className={classes.input_required}
                />
              </Grid>
              <Grid item xs={6} className={classes.items}>
                <TextField
                    name="entregado_por"
                    label="Entregado por"                
                    value={usuarioSesion.nombre}
                    //onChange={handleChange}
                    // error={touched.orden_trabajo && errors.orden_trabajo && Boolean(errors.orden_trabajo)}
                    // helperText={touched.orden_trabajo && errors.orden_trabajo ? errors.orden_trabajo : ""}                  
                    variant="outlined"
                    text_disabled
                    fullWidth
                  />
              </Grid> 
              <Grid item xs={6} className={classes.items}>                                  
                  <Autocomplete
									options={usuarios || []}
									// value={tipo_recurso}
									loading={isValidatingUsuarios}
									getOptionLabel={usuario => `${usuario.nombre_completo} (${usuario.contacto.email})`}
									onChange={(event, value) => {
										// formik.setFieldValue("asignado", value);
										formik.setFieldValue("_recibido", value.nombre_completo);
										formik.setFieldValue("_recibido_ref", value._id);
									}}
									size="small"
									autoComplete
									loadingText="Cargando..."
									noOptionsText="Sin Datos"
									renderInput={(params) => (
										<TextField
											label="Recibido por"
											variant="outlined"
											{...params}
										/>
									)}
								/>               
              </Grid>  
              <Grid item xs={6} className={classes.items}>                                  
                  <Autocomplete
									options={usuarios || []}
									// value={tipo_recurso}
									loading={isValidatingUsuarios}
									getOptionLabel={usuario => `${usuario.nombre_completo} (${usuario.contacto.email})`}
									onChange={(event, value) => {
										// formik.setFieldValue("asignado", value);
										formik.setFieldValue("_usuario_final", value.nombre_completo);
										formik.setFieldValue("_usuario_final_ref", value._id);
									}}
									size="small"
									autoComplete
									loadingText="Cargando..."
									noOptionsText="Sin Datos"
									renderInput={(params) => (
										<TextField
											label="Para el Usuario"
											variant="outlined"
											{...params}
										/>
									)}
								/>               
              </Grid> 
              <Grid item xs={6} className={classes.items}>                                  
                <Autocomplete
									options={contratos ? contratos.data : []}
									// value={contrato}
									loading={isValidatingContratos}
									getOptionLabel={contrato => `[${contrato.codigo}] ${contrato.nombre}`}
									onChange={(event, value) => {
										// formik.setFieldValue("asignado", value);
										// formik.setFieldValue("asignado_nombre", value.nombre);
										formik.setFieldValue("_contrato_origen_ref", value._id);
									}}
									//size="small"
									autoComplete
									loadingText="Cargando..."
									noOptionsText="Sin Datos"
									renderInput={(params) => (
										<TextField
											label="Contrato Origen"
											variant="outlined"
											{...params}
										/>
									)}
								/>              
              </Grid> 
              <Grid item xs={6} className={classes.items}>                                  
                <Autocomplete
									options={contratos ? contratos.data : []}
									// value={contrato}
									loading={isValidatingContratos}
									getOptionLabel={contrato => `[${contrato.codigo}] ${contrato.nombre}`}
									onChange={(event, value) => {
										// formik.setFieldValue("asignado", value);
										// formik.setFieldValue("asignado_nombre", value.nombre);
										formik.setFieldValue("_contrato_destino_ref", value._id);
									}}
									//size="small"
									autoComplete
									loadingText="Cargando..."
									noOptionsText="Sin Datos"
									renderInput={(params) => (
										<TextField
											label="Contrato Destino"
											variant="outlined"
											{...params}
										/>
									)}
								/>              
              </Grid> 
               
              <Grid item xs={6} className={classes.items} >
                <FormControl className={classes.formselect} fullWidth variant="outlined">           
                  <MaterialReactSelect
                    value={Tipos_Motivo.find(d => d.value === values.motivo)} 
                    label="Motivo"                
                    isClearable
                    onChange={(newValue) => setFieldValue("motivo", newValue ? newValue.value : "") }
                    placeholder="Motivo"
                    options={Tipos_Motivo && Tipos_Motivo.map((value, index) => ({
                      value: value.value,
                      label: value.value
                    }))}
                    noOptionsMessage={() => "No ha seleccionado el Motivo"}
                    error={touched.motivo && errors.motivo && Boolean(errors.motivo)}
                    helperText={touched.motivo && errors.motivo ? errors.motivo : ""}
                  />   
                </FormControl> 
              </Grid>

              <Grid item xs={6} className={classes.items} >
                <TextField
                  name="descripcion"
                  label="Descripción"                
                  value={values.descripcion}
                  onChange={handleChange}
                  error={touched.descripcion && errors.descripcion && Boolean(errors.descripcion)}
                  helperText={touched.descripcion && errors.descripcion ? errors.descripcion : ""}                  
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                />
            </Grid>
                                                                                                
            </Grid>
          </Grid>                                      

        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>Cerrar</Button>
          <Button type="submit" variant="contained" color="primary" >Guardar Registro</Button>
        </DialogActions>
      </form>
    </Dialog>    
</>
  );
}