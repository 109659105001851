//PROGRAMA
export const AGREGAR_ACTIVO = 'agregarActivo';
export const AGREGAR_CLASES = 'agregarClases';
export const AGREGAR_CONDICONES_ACTIVOS = 'agregarCondicionesActivos';
export const AGREGAR_COLORES = 'agregarColores';
export const AGREGAR_MARCAS = 'agregarMarcas';
export const AGREGAR_LICENCIA_SOFTWARE = 'agregarLicenciaSoftware';
export const AGREGAR_PERMISOS = 'agregarPermisos';
export const AGREGAR_PERFILES = 'agregarPerfiles';
export const AGREGAR_ROLES = 'agregarRoles';
export const AGREGAR_TIPO_DOCS = 'agregarTipoDocs';
export const APROBAR_TODO = 'aprobarTodo';
export const ASIGNAR_ACTIVOS_PERSONAS = 'asignarActivosAPersonas';
export const CARGAR_ACTIVOS = 'cargarActivos';
export const CARGAR_LICENCIAS_SOFTWARE = 'cargarLicenciasSoftware';
export const EDITAR_CLASES = 'editarClases';
export const EDITAR_CONDICONES_ACTIVOS = 'editarCondicionesActivos';
export const EDITAR_CLASE_ACTIVOS = 'editarClaseActivos';
export const EDITAR_COLORES = 'editarColores';
export const EDITAR_MARCAS = 'editarMarcas';
export const EDITAR_LICENCIA_SOFTWARE = 'editarLicenciaSoftware';
export const EDITAR_PERMISOS = 'editarPermisos';
export const EDITAR_PERFILES = 'editarPerfiles';
export const EDITAR_ROLES = 'editarRoles';
export const EDITAR_VALORES_TARIFAS = 'editarValoresTarifas';
export const EDITAR_TIPO_DOCS = 'editarTipoDocs';
export const ELIMINAR_ACTIVOS = 'eliminarActivos';
export const ELIMINAR_CLASES = 'eliminarClases';
export const ELIMINAR_CONDICONES_ACTIVOS = 'eliminarCondicionesActivos';
export const ELIMINAR_COLORES = 'eliminarColores';
export const ELIMINAR_ADJUNTOS = 'eliminarAdjuntos';
export const ELIMINAR_MARCAS = 'eliminarMarcas';
export const ELIMINAR_LICENCIA_SOFTWARE = 'eliminarLicenciaSoftware';
export const ELIMINAR_PERMISOS = 'eliminarPermisos';
export const ELIMINAR_PERFILES = 'eliminarPerfiles';
export const ELIMINAR_ROLES = 'eliminarRoles';
export const ELIMINAR_TIPO_DOCS = 'eliminarTipoDocs';
export const REGRESAR_ACTIVO = 'regresarActivo';
export const VER_COSTOS_ACTIVOS = 'verCostosActivos';
export const VER_DETALLE_ACTIVO = 'verDetalleActivo';
export const VER_DETALLE_BIEN_ASIGNADO = 'verDetalleBienAsignado';
export const VER_DETALLE_SOLICITUD_ACTIVACION = 'verDetalleSolicitudActivacion';
export const VER_DETALLE_SOLICITUD_BAJA = 'verDetalleSolicitudBaja';
export const VER_INFORME_MOVIMIENTOS_ACTIVOS = 'verInformeMovimientosActivos';
export const VER_LICENCIAS_SOFTWARE = 'verLicenciasSoftware';
export const VER_LISTADO_ACTIVOS = 'verListadoActivos';
export const VER_LISTADO_ASIGNACIONES = 'verListadoAsignaciones';
export const VER_LISTADO_PERSONAS = 'verListadoDePersonas';
export const VER_LISTADO_GERENCIAS = 'verListadoDeGerencias';
export const VER_LISTADO_CONTRATOS = 'verListadoDeContratos';
export const VER_LISTADO_SOLICITUDES_ACTIVACION = 'verListadoSolicitudesActivacion';
export const VER_LISTADO_SOLICITUDES_BAJA = 'verListadoSolicitudesBaja';
export const VER_MIS_ACTIVACIONES_PENDIENTES = 'verMisActivacionesPendientes';
export const VER_MIS_ASIGNACIONES_PENDIENTES = 'verMisAsignacionesPendientes';
export const VER_PRECIO_MIS_ASIGNACIONES_PENDIENTES = 'verPrecioEnMisActivacionesPendientes';
export const VER_MOVIMIENTO_MENSUAL = 'verMovimientoMensual';
export const VER_MODULO_AJUSTES = 'verModuloAjustes';
export const VER_CONDICIONES_ACTIVOS = 'verCondicionesActivos';
export const VER_COLORES = 'verColores';
export const VER_MARCAS = 'verMarcas';
export const VER_PERFILES = 'verPerfiles';
export const VER_PERMISOS = 'verPermisos';
export const VER_PRECIO_ACTIVO = 'verPrecioActivo';
export const VER_REPORTE_ACTIVOS_REGISTRADOS = 'verReporteActivosRegistrados';
export const VER_ROLES = 'verRoles';
export const VER_TARIFAS = 'verTarifas';
export const VER_TIPOS_DOCS = 'verTiposDocs';
export const VER_TODO = 'verTodo';
export const VER_ACTIVAR = 'verActivar';
export const VER_ANULAR_ACTIVAR = 'verAnularActivar';
export const VER_BAJA = 'verBaja';
export const VER_BAJA_LISTADO = 'opcionVerBajaEnListado';
export const VER_ANULAR_BAJA = 'verAnularBaja';
export const VER_VEHICULOS_CONTRATOS = 'verVehiculosContrato';
export const VER_ACTUALIZAR_INFORMACION_ACTIVO = 'verActualizarInformacionActivo';
export const VER_EDITAR_CONTABILIDAD = 'verEditarContabilidad';
export const VER_OPCION_REGRESAR_A_GERENCIA_ACTIVO = 'verOpcionRegresarAGerenciaActivo';
export const VER_DETALLE_BAJA = 'verDetalleBajaActivo';
export const VER_LISTADO_LICENCIAS_SOFTWARE = 'verListadoLicenciasSoftware';
export const VER_SELECCION_GERENCIAS = 'verSeleccionGerencias';
export const VER_SELECCION_CONTRATOS = 'verSeleccionContratos';
export const VER_CONTRATOS_GERENCIA = 'verContratosGerencia';
export const VER_REPORTE_RESUMEN_LICENCIAS_SOFTWARE_GERENCIAS = 'verReporteResumenLicenciasSoftwareGerencia';
export const AGREGAR_UBICACIONES = 'agregarUbicaciones';
export const REASIGNACION_LICENCIAS_TI = 'reasignacionLicenciasTI';
export const VER_GESTION_FLOTA = 'verGestionFlota';
export const VER_LISTADO_MANTENCION_VEHICULO = 'verListadoMantencionVehiculo';
export const VER_BOTON_MANTENCION_VEHICULO = 'verBotonMantencionVehiculo';
export const ELIMINAR_MANTENCION_VEHICULO = 'eliminarMantencionVehiculo';
export const VER_LISTADO_ACTA_ENTREGA_VEHICULO = 'verListadoActaEntregaVehiculo';
export const VER_BOTON_ACTA_ENTREGA_VEHICULO = 'verBotonActaEntregaVehiculo';
export const VER_LISTADO_SINIESTROS_VEHICULO = 'verListadoSiniestrosVehiculo';
export const EDITAR_SOCIEDAD_ACTIVO = 'editarSociedadActivo';
export const EDITAR_ESTADO_NOTIFICACION = 'editarEstadoNotificacion';
export const VER_CONDICION_VENTA = 'verCondicionVenta';
export const VER_GERENCIA_GAF = 'verGerenciaGAF';
export const VER_GERENCIA_GGN = 'verGerenciaGGN';
export const VER_GERENCIA_GIT = 'verGerenciaGIT';
export const VER_GERENCIA_GEI = 'verGerenciaGEI';
export const VER_GERENCIA_GMI = 'verGerenciaGMI';
export const VER_GERENCIA_GPO = 'verGerenciaGPO';
export const VER_GERENCIA_GMB = 'verGerenciaGMB';
export const VER_GERENCIA_GOP = 'verGerenciaGOP';
export const VER_GERENCIA_GDN = 'verGerenciaGDN';
export const VER_GERENCIA_CDP = 'verGerenciaCDP';
export const VER_GERENCIA_CZS = 'verGerenciaCZS';
export const VER_GERENCIA_PSI = 'verGerenciaPSI';
export const VER_GERENCIA_PPE = 'verGerenciaPPE';
export const VER_GERENCIA_TEC = 'verGerenciaTEC';
export const VER_GERENCIA_GZS = 'verGerenciaGZS';
export const VER_GERENCIA_GDO = 'verGerenciaGDO';
export const VER_GERENCIA_RSE = 'verGerenciaRSE';
export const VER_GERENCIA_GPE = 'verGerenciaGPE';
export const VER_GERENCIA_GCM = 'verGerenciaGCM';
export const VER_GERENCIA_OTC = 'verGerenciaOTC';
export const VER_GERENCIA_CDZ = 'verGerenciaCDZ';
export const AGREGAR_DISPOSITIVO_GPS = 'agregarDispositivoGPS';
export const EDITAR_DISPOSITIVO_GPS = 'editarDispositivoGPS';
export const ELIMINAR_DISPOSITIVO_GPS = 'eliminarDispositivoGPS';
export const VER_CONTRATO_DISPOSITIVO_GPS = 'verContratoDispositivoGPS';
