import { BACKEND } from "../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener un perfil.
 * @param {String} id id del registro
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {Object} cancelToken token para cancelacion
 */
export async function ObtenerPerfil(id, selects, populates, cancelToken = null) {
	let url = `${BACKEND}/perfiles/${id}`;
	if (selects) selects.forEach(select => {
		url = url + "/" + select;
	});
	if (populates) populates.forEach(populate => {
		url = url + "/" + populate;
	});
	return Axios.get(url,
		{
			cancelToken: cancelToken
		}).then(res => res.data ? res.data : null);
}

/**
 * Método encargado de obtener todos los perfiles.
 */
export async function ObtenerPerfiles() {
	try {
		let url = `${BACKEND}/perfiles`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar un nuevo perfil.
 * @param {*} perfil Datos del perfil.
 */
export async function AgregarPerfil(perfil) {
	try {
		let url = `${BACKEND}/perfiles`;
		let response = await Axios.post(url, perfil);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar un perfil.
 * @param {*} perfil Datos actualizados del perfil.
 */
export async function ActualizarPerfil(perfil) {
	try {
		let url = `${BACKEND}/perfiles/${perfil._id}`;
		let response = await Axios.put(url, perfil);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar un perfil.
 * @param {*} perfil Datos del perfil.
 */
export async function EliminarPerfil(perfil) {
	try {
		let url = `${BACKEND}/perfiles/${perfil._id}`;
		let response = await Axios.delete(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}