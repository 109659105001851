import React, { Fragment, useContext, useState } from 'react';
import MisActivos from './misActivos';
import MisAsignaciones from './misAsignaciones';
import MisActivaciones from './misActivaciones';
import MisNotificaciones from './misNotificaciones';
import VehiculosCyD from '../../gestionFlota/vehiculosCyD';

import { AppBar, Avatar, Badge, Box, Grid, Paper, Tab, Tabs, Typography, useMediaQuery } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import './style.css';
import { MainContext, ListadoVehiculosContext } from '../../../App';
import { ListadoNotificacionesContext } from '.';
import {
  VER_MIS_ACTIVACIONES_PENDIENTES,
  VER_MIS_ASIGNACIONES_PENDIENTES,
  VER_VEHICULOS_CONTRATOS,
} from '../../../constants/permissions';
import clsx from 'clsx';

function Page(props) {
  const {
    usuario,
    bienes,
    asignaciones,
    activaciones,
    //notificaciones,
    load_data,
  } = props;

  const { permisos } = useContext(MainContext);
  const { vehiculos } = useContext(ListadoVehiculosContext);
  const { notificaciones } = useContext(ListadoNotificacionesContext);
  const [TabIndex, SetTabIndex] = useState(0);
  const classes = useStyles();
  const theme = useTheme();
  const smBreak = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} xl={12}>
        {/* INFORMACIÓN DE USUARIO */}
        <Paper elevation={4} style={{ borderRadius: 15 }}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={2}
              md={2}
              lg={2}
              xl={2}
              component={Box}
              display="flex"
              justifyContent="center"
              alignSelf="center"
            >
              {/* IMAGEN DE USUARIO */}
              {!usuario && <Skeleton variant="circle" className={classes.avatar_size} />}
              {usuario && <Avatar src={usuario.photo} className={classes.avatar_size} />}
            </Grid>
            <Grid item xs={8} md={8} lg={8} xl={8}>
              {/* DATOS DE USUARIO */}
              <Box display="flex" justifyContent="left" alignContent="center">
                {!usuario && <Skeleton variant="rect" className={classes.datos_usuario} />}
                {usuario && (
                  <Grid container spacing={0} className={classes.datos_usuario}>
                    <Grid item xs={12} xl={12}>
                      <Typography variant="h6">{usuario.nombre_completo}</Typography>
                    </Grid>
                    <Grid item xs={12} xl={12}>
                      <Typography variant="body1">{usuario.run_formateado}</Typography>
                    </Grid>
                    <Grid item xs={12} xl={12}>
                      <Typography variant="body1">{usuario.contacto.email}</Typography>
                    </Grid>
                    <Grid item xs={12} xl={12}>
                      <Typography variant="body1">{usuario.cargo}</Typography>
                    </Grid>
                    <Grid item xs={12} xl={12}>
                      <Typography variant="body1">
                        {' '}
                        {usuario.gerencia} / {usuario.contratos_ref ? usuario.contratos_ref[0].nombre : 'Sin Contrato'}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>

            <Grid item xs={2} md={2} lg={2} xl={2}>
              {/* CANTIDAD DE ACTIVOS */}
              <Box display="flex" justifyContent="center" alignContent="center">
                <Paper elevation={4} className={classes.total_asignaciones}>
                  {!usuario && <Skeleton variant="rect" className={classes.total_asignaciones} />}
                  {usuario && (
                    <Grid container>
                      <Grid item xs={12} xl={12}>
                        <Typography variant="h6" align="center">
                          Total Asignaciones
                        </Typography>
                      </Grid>
                      <Grid item xs={12} xl={12}>
                        <Typography variant="h3" align="center">
                          {bienes ? bienes.length : 0}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} xl={12}>
        <Fragment>
          <AppBar position="static">
            <Tabs
              value={TabIndex}
              onChange={(event, value) => SetTabIndex(value)}
              centered={!smBreak}
              variant={smBreak ? 'scrollable' : 'standard'}
              scrollButtons={smBreak ? 'on' : 'off'}
              TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
            >
              <Tab label="Mis Activos" className={classes.tab} />
              <Tab
                label={TabItem('Mis Asignaciones Pendientes', asignaciones)}
                className={clsx({
                  [classes.tab]: true,
                  [classes.hidden]: !permisos || !permisos[VER_MIS_ASIGNACIONES_PENDIENTES] ? true : false,
                })}
              />
              <Tab
                label={TabItem('Mis Activaciones Pendientes', activaciones)}
                className={clsx({
                  [classes.tab]: true,
                  [classes.hidden]: !permisos || !permisos[VER_MIS_ACTIVACIONES_PENDIENTES] ? true : false,
                })}
              />
              <Tab
                label={TabItem('Gestión de Flota', vehiculos)}
                className={clsx({
                  [classes.tab]: true,
                  [classes.hidden]: !permisos || !permisos[VER_VEHICULOS_CONTRATOS] ? true : false,
                })}
              />
              {/* <Tab label={TabItem("Mis Notificaciones", notificaciones)} className={clsx({ [classes.tab]: true, [classes.hidden]: false })} /> */}
            </Tabs>
          </AppBar>
          <TabPanel value={TabIndex} index={0}>
            <MisActivos bienes={bienes} />
          </TabPanel>
          <TabPanel value={TabIndex} index={1}>
            <MisAsignaciones
              asignaciones={asignaciones}
              usuario_id={usuario ? usuario._id : ''}
              load_data={load_data}
            />
          </TabPanel>
          <TabPanel value={TabIndex} index={2}>
            <MisActivaciones activaciones={activaciones} />
          </TabPanel>
          <TabPanel value={TabIndex} index={3}>
            <VehiculosCyD vehiculos={vehiculos} />
          </TabPanel>
          {/* <TabPanel value={TabIndex} index={4}>
            <MisNotificaciones  />
          </TabPanel> */}
        </Fragment>
      </Grid>
    </Grid>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box py={1}>{children}</Box>}
    </div>
  );
}

function TabItem(texto, datos) {
  return (
    <Badge badgeContent={datos ? datos.length : 0} showZero={true}>
      <label style={{ marginRight: 5 }}>{texto}</label>
    </Badge>
  );
}

const useStyles = makeStyles((theme) => ({
  avatar_size: {
    width: '100%',
    height: '100%',
    maxWidth: theme.spacing(15),
    maxHeight: theme.spacing(15),
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  datos_usuario: {
    width: theme.spacing(150),
    height: theme.spacing(15),
    margin: theme.spacing(1),
  },
  total_asignaciones: {
    width: theme.spacing(20),
    height: theme.spacing(15),
    margin: theme.spacing(1),
    borderRadius: 15,
  },
  hidden: {
    display: 'none',
  },
  tab: {
    textTransform: 'none',
  },
}));

export default Page;
