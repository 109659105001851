import React, { useContext, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { VerBienContext } from '.';
import { VER_OPCION_REGRESAR_A_GERENCIA_ACTIVO } from '../../../constants/permissions';
import { MainContext } from '../../../App';

function DialogoRegresarActivo(props) {
  const { bien, openDialogRegresarActivo, handleRegresarActivo, setOpenDialogRegresarActivo } =
    useContext(VerBienContext);

  const { permisos } = useContext(MainContext);

  const [value, setValue] = useState();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleCerrar = () => {
    setOpenDialogRegresarActivo(false);
    setValue();
  };

  return (
    <Dialog open={openDialogRegresarActivo} onClose={handleCerrar} fullWidth>
      <DialogTitle>Regresar Activo</DialogTitle>
      <DialogContent dividers>
        <Typography>{`Formulario para reasignar el activo ${bien.codigo_activo} ${bien.marca ? bien.marca : ''} ${
          bien.modelo ? bien.modelo : ''
        } a una nueva ubicación.`}</Typography>
        <RadioGroup name="regresar_activo" value={value} onChange={handleChange}>
          {bien && bien._contrato_ref && bien._asignado_ref && (
            <FormControlLabel value="contrato" control={<Radio />} label="Contrato" />
          )}
          {permisos &&
            permisos[VER_OPCION_REGRESAR_A_GERENCIA_ACTIVO] &&
            bien &&
            bien._gerencia_ref &&
            bien._contrato_ref && <FormControlLabel value="gerencia" control={<Radio />} label="Gerencia" />}
          {/* <FormControlLabel value="rrff_ti" control={<Radio />} label="GAF" /> */}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCerrar} color="primary" variant="outlined">
          Cancelar
        </Button>
        <Button
          autoFocus
          onClick={() => handleRegresarActivo(value)}
          disabled={!value}
          color="primary"
          variant="contained"
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogoRegresarActivo;
