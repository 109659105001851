import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { useState } from 'react';
import MaterialTable from '@material-table/core';
import { getManySDA } from '../../../services/request/requestGestorPagos';
// import formatNumber from 'format-number';

const localization = require('../../../data/material-table.location.json');
// const numberFormater = formatNumber({ integerSeparator: '.', decimal: ',' });
export default function DialogoBuscarOrdenCompra(props) {
  const { id, handleSubmit, open, close } = props;
  const [isSearching, setIsSearching] = useState(false);
  const [ordenes, setOrdenes] = useState([]);
  const [codigo, setCodigo] = useState('');

  const columns = [
    {
      title: 'Código',
      field: 'codigo',
    },
    // {
    //   title: 'Monto',
    //   field: 'monto',
    //   render: (row) => '$' + (row.monto ? numberFormater(row.monto) : 0),
    // },
  ];

  function checkOrdenCompra() {
    if (id) {
      console.log('Precargar OC');
    }
  }

  async function buscar(codigo) {
    if (!codigo) {
      setOrdenes([]);
      return;
    }
    setIsSearching(true);
    const responseOrdenes = await getManySDA('/orden-compra?activo=1&codigo=' + codigo);
    setOrdenes(responseOrdenes.filter((a) => a.codigo.includes(codigo)));
    setIsSearching(false);
  }

  return (
    <Dialog
      onClose={close}
      open={open}
      maxWidth="xs"
      fullWidth
      TransitionProps={{
        onEnter: checkOrdenCompra,
      }}
    >
      <DialogTitle>Buscar orden de compra</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} style={{ marginBottom: '8px' }}>
          <Grid item xl={10} lg={10} md={10} sm={8} xs={12}>
            <TextField
              variant="outlined"
              value={codigo}
              placeholder="Código de Orden de compra"
              size="small"
              onChange={(e) => setCodigo(e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={4} xs={12}>
            <div style={{ display: 'flex', height: '100%' }}>
              <Button onClick={() => buscar(codigo)} disabled={isSearching} variant="outlined" color="primary">
                Buscar
              </Button>
            </div>
          </Grid>
        </Grid>
        <MaterialTable
          title="Ordenes de Compra"
          options={{
            search: false,
          }}
          isLoading={isSearching}
          data={ordenes}
          columns={columns}
          actions={[
            {
              icon: 'chevron_right',
              tooltip: 'Seleccionar',
              onClick: (e, rowData) => {
                close();
                handleSubmit(rowData);
              },
            },
          ]}
          onRowClick={(e, rowData) => {
            close();
            handleSubmit(rowData);
          }}
          localization={localization}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          disabled={isSearching}
          variant="outlined"
          color="primary"
          style={{ marginRight: '8px' }}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
