import { Grid, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import MaterialTable from '../../../components/materialTable';
import { getAsignacionesLicenciasSoftware } from '../../../services/request/requestLicenciasSoftware';
import { getMarcasSoftware } from '../../../services/request/requestMarcasSoftware';
import useSWR from 'swr';
import Moment from 'moment';
import CustomMiniCards from '../../../components/customMiniCards';

const objetoIndicadores = {
  cantidadTotalSoftware: 0,
  cantidadTotalPC: 0,
  licenciasMicrosoft: 0,
  licenciasAutoDesk: 0,
  licenciasAdobe: 0,
  licenciasOtro: 0,
};

function getData() {
  return getAsignacionesLicenciasSoftware(
    {},
    ['fecha_creacion'],
    [
      '_licencia_software_ref.nombre',
      '_licencia_software_ref.version',
      '_licencia_software_ref.tipo',
      '_licencia_software_ref.fecha_vencimiento',
      '_licencia_software_ref._marca_ref',
      '_bien_ref.codigo_activo',
      '_contrato_ref.codigo',
      '_contrato_ref.nombre',
      '_contrato_ref.fecha_termino_estimada',
      '_gerencia_ref.nombre',
      '_gerencia_ref.sigla',
    ],
  );
}

export default function InformeResumenLicenciaSoftware() {
  const { data: marcas } = useSWR('get:marcas-software', () => getMarcasSoftware());
  const { data: response } = useSWR('asignacion-licencias-software', () => getData());
  const [indicadores, setIndicadores] = useState(objetoIndicadores);
  const [datosGerencia, setDatosGerencia] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadData = useCallback(async () => {
    setIndicadores({
      cantidadTotalSoftware: 0,
      cantidadTotalPC: 0,
      licenciasAutoDesk: 0,
      licenciasMicrosoft: 0,
      licenciasAdobe: 0,
      licenciasOtro: 0,
    });
    setIsLoading(true);
    setDatosGerencia([]);

    if (response) {
      if (response.status) {
        setIsLoading(false);

        const datosAgregados = response.data.map((dato) => {
          const nombremarca = marcas.data.find((marca) => marca._id === dato._licencia_software_ref._marca_ref);
          return {
            ...dato,
            nombre_marca: nombremarca.marca,
          };
        });

        const agrupacionsoftware = datosAgregados.reduce((acumulador, asignacionActual) => {
          const marcaYaExiste = acumulador.find((elemento) => elemento.nombre === asignacionActual.nombre_marca);
          if (marcaYaExiste) {
            return acumulador.map((elemento) => {
              if (elemento.nombre === asignacionActual.nombre_marca) {
                return {
                  ...elemento,
                  cantidad: elemento.cantidad + 1,
                };
              }
              return elemento;
            });
          }
          return [
            ...acumulador,
            {
              marca: asignacionActual._licencia_software_ref._marca_ref,
              nombre: asignacionActual.nombre_marca,
              cantidad: 1,
            },
          ];
        }, []);

        console.log('datos agrupados software: ', agrupacionsoftware);

        const cantidadAutodesk = agrupacionsoftware.find((element) => element.nombre === 'AutoDesk');
        const cantidadMicrosoft = agrupacionsoftware.find((element) => element.nombre === 'Microsoft');
        const cantidadAdobe = agrupacionsoftware.find((element) => element.nombre === 'Adobe');

        let cantidadOtro = 0;
        agrupacionsoftware.forEach((valor) => {
          if (valor.nombre !== 'AutoDesk' && valor.nombre !== 'Microsoft' && valor.nombre !== 'Adobe') {
            cantidadOtro = cantidadOtro + valor.cantidad;
          }
          return cantidadOtro;
        });

        const arregloSoloEquipos = response.data.reduce((acumulador, asignacionActual) => {
          if (!acumulador.includes(asignacionActual._bien_ref.codigo_activo)) {
            acumulador.push(asignacionActual._bien_ref.codigo_activo);
          }
          return acumulador;
        }, []);

        setIndicadores({
          ...indicadores,
          cantidadTotalSoftware: response.data.length,
          licenciasAutoDesk: cantidadAutodesk.cantidad,
          licenciasMicrosoft: cantidadMicrosoft.cantidad,
          licenciasAdobe: cantidadAdobe.cantidad,
          licenciasOtro: cantidadOtro,
          cantidadTotalPC: arregloSoloEquipos.length,
        });

        const contratosagrupados = datosAgregados.reduce((acumulador, itemActual) => {
          if (itemActual._contrato_ref) {
            const contratoYaExiste = acumulador.find(
              (elemento) => elemento.contrato_codigo === itemActual._contrato_ref.codigo,
            );
            if (contratoYaExiste) {
              return acumulador.map((elemento) => {
                if (elemento.contrato_codigo === itemActual._contrato_ref.codigo) {
                  return {
                    ...elemento,
                    cantidadTotal: elemento.cantidadTotal + 1,
                    cantidad_Autodesk:
                      itemActual.nombre_marca === 'AutoDesk'
                        ? elemento.cantidad_Autodesk + 1
                        : elemento.cantidad_Autodesk,
                    cantidad_Microsoft:
                      itemActual.nombre_marca === 'Microsoft'
                        ? elemento.cantidad_Microsoft + 1
                        : elemento.cantidad_Microsoft,
                    cantidad_Adobe:
                      itemActual.nombre_marca === 'Adobe' ? elemento.cantidad_Adobe + 1 : elemento.cantidad_Adobe,
                    cantidad_Otro:
                      itemActual.nombre_marca !== 'AutoDesk' &&
                      itemActual.nombre_marca !== 'Microsoft' &&
                      itemActual.nombre_marca !== 'Adobe'
                        ? elemento.cantidad_Otro + 1
                        : elemento.cantidad_Otro,
                  };
                }
                return elemento;
              });
            }
            return [
              ...acumulador,
              {
                contrato_codigo: itemActual._contrato_ref.codigo,
                contrato_nombre: itemActual._contrato_ref.nombre,
                contrato_fecha_termino: itemActual._contrato_ref.fecha_termino_estimada,
                gerencia_sigla: itemActual._gerencia_ref.sigla,
                gerencia_nombre: itemActual._gerencia_ref.nombre,
                cantidadTotal: 1,
                cantidad_Autodesk: itemActual.nombre_marca === 'AutoDesk' ? 1 : 0,
                cantidad_Microsoft: itemActual.nombre_marca === 'Microsoft' ? 1 : 0,
                cantidad_Adobe: itemActual.nombre_marca === 'Adobe' ? 1 : 0,
                cantidad_Otro:
                  itemActual.nombre_marca !== 'AutoDesk' &&
                  itemActual.nombre_marca !== 'Microsoft' &&
                  itemActual.nombre_marca !== 'Adobe'
                    ? 1
                    : 0,
              },
            ];
          }

          return [
            ...acumulador,
            {
              contrato_codigo: itemActual._contrato_ref
                ? itemActual._contrato_ref.codigo
                : itemActual._gerencia_ref.sigla,
              contrato_nombre: itemActual._contrato_ref
                ? itemActual._contrato_ref.nombre
                : itemActual._gerencia_ref.nombre,
              contrato_fecha_termino: itemActual._contrato_ref ? itemActual._contrato_ref.fecha_termino_estimada : '',
              gerencia_sigla: itemActual._gerencia_ref.sigla,
              gerencia_nombre: itemActual._gerencia_ref.nombre,
              cantidadTotal: 1,
              cantidad_Autodesk: itemActual.nombre_marca === 'AutoDesk' ? 1 : 0,
              cantidad_Microsoft: itemActual.nombre_marca === 'Microsoft' ? 1 : 0,
              cantidad_Adobe: itemActual.nombre_marca === 'Adobe' ? 1 : 0,
              cantidad_Otro:
                itemActual.nombre_marca !== 'AutoDesk' &&
                itemActual.nombre_marca !== 'Microsoft' &&
                itemActual.nombre_marca !== 'Adobe'
                  ? 1
                  : 0,
            },
          ];
        }, []);

        console.log('contratos agrupados: ', contratosagrupados);

        const gerenciasAgrupadas = contratosagrupados.reduce((acumulador, itemActual) => {
          const gerenciaYaExiste = acumulador.find((elemento) => elemento.gerencia_sigla === itemActual.gerencia_sigla);
          if (gerenciaYaExiste) {
            return acumulador.map((elemento) => {
              if (elemento.gerencia_sigla === itemActual.gerencia_sigla) {
                return {
                  ...elemento,
                  cantidadTotal: elemento.cantidadTotal + itemActual.cantidadTotal,
                  cantidad_Autodesk: elemento.cantidad_Autodesk + itemActual.cantidad_Autodesk,
                  cantidad_Microsoft: elemento.cantidad_Microsoft + itemActual.cantidad_Microsoft,
                  cantidad_Adobe: elemento.cantidad_Adobe + itemActual.cantidad_Adobe,
                  cantidad_Otro: elemento.cantidad_Otro + itemActual.cantidad_Otro,
                };
              }
              return {
                ...elemento,
                detalleAgrupado: contratosagrupados.filter(
                  (gerencia) => gerencia.gerencia_sigla === elemento.gerencia_sigla,
                ),
              };
            });
          }
          return [
            ...acumulador,
            {
              gerencia_sigla: itemActual.gerencia_sigla,
              gerencia_nombre: itemActual.gerencia_nombre,
              cantidadTotal: itemActual.cantidadTotal,
              cantidad_Autodesk: itemActual.cantidad_Autodesk,
              cantidad_Microsoft: itemActual.cantidad_Microsoft,
              cantidad_Adobe: itemActual.cantidad_Adobe,
              cantidad_Otro: itemActual.cantidad_Otro,
            },
          ];
        }, []);

        setDatosGerencia(gerenciasAgrupadas);

        console.log('gerencias agrupados: ', gerenciasAgrupadas);
      }
    }
  }, [response, marcas]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const columns = [
    {
      title: 'Gerencia',
      field: 'gerencia_sigla',
    },
    {
      title: 'Nombre',
      field: 'gerencia_nombre',
    },
    {
      title: 'Total Software',
      field: 'cantidadTotal',
    },
    {
      title: 'Total Microsoft',
      field: 'cantidad_Microsoft',
    },
    {
      title: 'Total AutoDesk',
      field: 'cantidad_Autodesk',
    },
    {
      title: 'Total Adobe',
      field: 'cantidad_Adobe',
    },
    {
      title: 'Total Otro Software',
      field: 'cantidad_Otro',
    },
  ];

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
          <CustomMiniCards title="Cantidad Total de Licencias de Software Asignadas">
            <Typography variant="h5" component="h2" align="center">
              {indicadores.cantidadTotalSoftware}
            </Typography>
          </CustomMiniCards>
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
          <CustomMiniCards title="Cantidad Total de Computadores con Licencias de Software">
            <Typography variant="h5" component="h2" align="center">
              {indicadores.cantidadTotalPC}
            </Typography>
          </CustomMiniCards>
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
          <CustomMiniCards title="Licencias de AutoDesk Asignadas">
            <Typography variant="h5" component="h2" align="center">
              {indicadores.licenciasAutoDesk}
            </Typography>
          </CustomMiniCards>
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
          <CustomMiniCards title="Licencias de Microsoft Asignadas">
            <Typography variant="h5" component="h2" align="center">
              {indicadores.licenciasMicrosoft}
            </Typography>
          </CustomMiniCards>
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
          <CustomMiniCards title="Licencias de Adobe Asignadas">
            <Typography variant="h5" component="h2" align="center">
              {indicadores.licenciasAdobe}
            </Typography>
          </CustomMiniCards>
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
          <CustomMiniCards title="Licencias Asignadas de Otro Software">
            <Typography variant="h5" component="h2" align="center">
              {indicadores.licenciasOtro}
            </Typography>
          </CustomMiniCards>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MaterialTable
            title=""
            is_loading={isLoading}
            lockheaders={true}
            columns={columns}
            data={Array.isArray(datosGerencia) ? datosGerencia : []}
            searching={false}
            columnsButton={false}
            grouping={false}
            filtering={false}
            showTitle={false}
            exportButton={false}
            options={{
              padding: 'default',
              showTitle: false,
              headerStyle: {
                fontWeight: 'bold',
                color: '#731f1f',
                textAlign: 'center',
                fontSize: '15px',
              },
            }}
            detailPanel={(rowData) => {
              return (
                <MaterialTable
                  title={'Detalle Contratos ' + rowData.gerencia_nombre}
                  grouping={false}
                  options={{
                    padding: 'dense',
                    paging: false,
                    search: false,
                    headerStyle: {
                      fontWeight: 'bold',
                    },
                    rowStyle: {
                      height: '15px',
                    },
                  }}
                  columns={[
                    {
                      title: 'Código Contrato',
                      field: 'contrato_codigo',
                    },
                    {
                      title: 'Nombre Contrato',
                      field: 'contrato_nombre',
                    },
                    {
                      title: 'Fecha de Término',
                      field: 'contrato_fecha_termino',
                      render: (row) =>
                        row.contrato_fecha_termino ? Moment(row.contrato_fecha_termino).format('DD/MM/YYYY') : '--',
                    },
                    {
                      title: 'Total Software',
                      field: 'cantidadTotal',
                    },
                    {
                      title: 'Total Microsoft',
                      field: 'cantidad_Microsoft',
                    },
                    {
                      title: 'Total AutoDesk',
                      field: 'cantidad_Autodesk',
                    },
                    {
                      title: 'Total Adobe',
                      field: 'cantidad_Adobe',
                    },
                    {
                      title: 'Total Otro Software',
                      field: 'cantidad_Otro',
                    },
                  ]}
                  data={rowData.detalleAgrupado}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
