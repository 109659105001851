import React, { Fragment, useContext, useState, useEffect, useCallback } from 'react';
import useSWR from 'swr';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import Moment from 'moment';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Checkbox,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Close } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import MaterialReactSelect from '../../../components/materialReactSelect';
import FileInput from '../../../components/fileInput';
import { VerBienContext } from '.';
import { MainContext } from '../../../App';
import { getUsuarios } from '../../../services/request/requestUsuarios';
import { getContratos, getGerencias } from '../../../services/request/requestPortafolio';
import { ObtenerCondicionesActuales } from '../../../services/request/requestCondicionesActuales';
import { ActualizarAsignado } from '../../../services/request/requestBienes';
import { putBien } from '../../../services/request/requestBienes';
import { GenerarLog } from '../../../services/activoLog';
import {
  deleteAsignacionLicenciaSoftware,
  getAsignacionesLicenciasSoftware,
  postLogLicenciaSoftware,
} from '../../../services/request/requestLicenciasSoftware';
import { ObtenerContrato, ObtenerContratoPorCodigo } from '../../../services/request/requestPortafolio';
import { uploadFileToCloudStorage } from '../../../services/fileUpload';
import { AceptarAsignacion, GenerarSolicitudAsignacion } from '../../../services/request/requestSolicitudesAsignacion';
import {
  VER_OPCION_REGRESAR_A_GERENCIA_ACTIVO,
  VER_SELECCION_GERENCIAS,
  VER_CONTRATOS_GERENCIA,
  REASIGNACION_LICENCIAS_TI,
} from '../../../constants/permissions';
import {
  EMAILS_LIBERACION_LICENCIAS,
  TITULO_EMAIL_LIBERACION_LICENCIAS,
  CONTENIDO_EMAIL_LIBERACION_LICENCIAS,
  EMAILS_REASIGNACION_LICENCIAS_ACTIVO,
  TITULO_EMAIL_REASIGNACION_LICENCIAS_ACTIVO,
  CONTENIDO_EMAIL_REASIGNACION_LICENCIAS_ACTIVO,
  CONTENIDO_EMAIL_REASIGNACION_LICENCIAS_USUARIO_FINAL,
} from '../../../constants/correos_destinatarios';
import { SendHTML } from '../../../services/email';
import Tabla from '@material-table/core';
import { CONTRATOS_LIBERA_LICENCIAS } from '../../../constants/constantes';

function DialogoRegresarActivo(props) {
  const { bien, openDialogAsignar, setOpenDialogAsignar, loadData } = useContext(VerBienContext);

  const { usuarioSesion, permisos } = useContext(MainContext);

  //Funjcion que permite obtener las gerencias dependiendo del permiso que posea el usuario
  function getDataGerencias() {
    if (permisos[VER_SELECCION_GERENCIAS]) {
      return getGerencias();
    } else {
      if (usuarioSesion && usuarioSesion.gerencia) {
        return getGerencias({ _id: usuarioSesion.gerencia });
      }
    }
  }

  //Permite obtener los contratos que puede visualizar el usuario, segun sus permisos
  function getDataContratos() {
    if (permisos[VER_SELECCION_GERENCIAS]) {
      return getContratos();
    } else {
      if (permisos[VER_CONTRATOS_GERENCIA]) {
        return getContratos({ gerencia_ref: usuarioSesion.gerencia });
      } else {
        return getContratos({ _id: usuarioSesion.contrato });
      }
    }
  }

  //Permite obtener las licencias del activo
  const getDataLicencias = (codigo_bien) => {
    if (codigo_bien && codigo_bien.length > 0) {
      return getAsignacionesLicenciasSoftware(
        { _bien_ref_eq: codigo_bien },
        [],
        [
          '_licencia_software_ref.nombre',
          '_licencia_software_ref.version',
          '_licencia_software_ref.codigo_compra',
          '_licencia_software_ref.tipo',
          '_licencia_software_ref._marca_ref',
          '_licencia_software_ref._marca_ref.marca',
          '_licencia_software_ref._version_ref.version',
        ],
      );
    } else return [];
  };

  const { data: usuarios, isValidating: isValidatingUsuarios } = useSWR(
    'personas',
    (key) => getUsuarios({ is_gsuite_talana: true }),
    { revalidateOnFocus: false },
  );
  const { data: contratos, isValidating: isValidatingContratos } = useSWR('contratos', (key) => getDataContratos(), {
    revalidateOnFocus: false,
  });
  const { data: gerencias, isValidating: isValidatingGerencias } = useSWR('gerencias', (key) => getDataGerencias(), {
    revalidateOnFocus: false,
  });
  const { data: condiciones } = useSWR('condiciones_actuales', (key) => ObtenerCondicionesActuales(), {
    revalidateOnFocus: false,
  });
  const [licenciasActivo, setLicenciasActivo] = useState([]);
  const [columns, setColumns] = useState([]);
  const [esGaf8, setEsGaf8] = useState(false);

  const notistack = useSnackbar();
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      paso: 0,
      asignado: null,
      asignado_nombre: '',
      _usuario_asignante_ref: usuarioSesion.ref,
      _bien_ref: bien._id,
      fecha_revision: '',
      _asignado_ref: '',
      tipo: '',
      estado: 'pendiente',
      formulario_estado: {
        estado: bien.condicion_actual,
        descripcion: '',
        foto_antes: null,
      },
    },
    validationSchema: object().shape({
      tipo: string().required('El tipo de asignación es requerido.'),
      asignado: object().nullable().required('El asignado es requerido.'),
      asignado_nombre: string().required('El nombre de asignado es requerido.'),
      _usuario_asignante_ref: string().required('El usuario asignante es requerido.'),
      _bien_ref: string().required('El activo es requerido.'),
      fecha_revision: '',
      _asignado_ref: string().required('El asignado es requerido.'),
      estado: string().required('El estado es requerido.'),
      formulario_estado: object().shape({
        estado: string().required('La condición actual es requerida.'),
        descripcion: string().optional(),
        // foto_antes: null,
      }),
    }),
    onSubmit: (values, helper) => handleAsignar(values),
    enableReinitialize: true,
  });

  const revisarPermisos = useCallback(() => {
    const columnas = [
      {
        title: 'Selección',
        sorting: false,
        grouping: false,
        render: (row) => (
          <Checkbox
            checked={row.checked}
            onChange={(e) => {
              row.checked = e.target.checked;
              revisarPermisos();
            }}
          />
        ),
      },
      {
        title: 'Nombre',
        field: '_licencia_software_ref.nombre',
      },
      {
        title: 'Versión',
        field: '_licencia_software_ref.version',
      },
      {
        title: 'Código de Compra',
        field: '_licencia_software_ref.codigo_compra',
      },
      {
        title: 'Tipo de Licencia',
        field: '_licencia_software_ref.tipo',
      },
    ];
    setColumns(columnas);
  }, []);

  const fetchData = useCallback(async () => {
    const licencias = await getDataLicencias(bien._id);

    //console.log("Licencias encontradas: ", licencias);

    if (licencias.data && licencias.data.length > 0) {
      const datosAgregados = licencias.data.map((dato) => {
        return {
          ...dato,
          checked: false,
        };
      });
      setLicenciasActivo(datosAgregados);
    } else {
      setLicenciasActivo([]);
    }
  }, [bien]);

  useEffect(() => {
    revisarPermisos();
  }, [revisarPermisos]);

  useEffect(() => {
    fetchData().catch(console.error);
  }, [fetchData]);

  /**
   * Handler para cambiar de paso.
   * @param {*} value Valor que suma o resta.
   */
  const handleChangePaso = (value) => {
    formik.setFieldValue('paso', formik.values.paso + value);
  };

  /**
   * Handler para cerrar el popup.
   */
  const handleCerrar = () => {
    setOpenDialogAsignar(false);
    formik.resetForm();
    loadData();
  };

  /**
   * Método encargado de verificar si es posible avanzar al siguiente paso.
   */
  const checkIsDisabled = () => {
    //En paso 1 debe seleccionar un tipo de asignación.
    if (formik.values.paso === 0 && !formik.values.tipo) {
      return true;
    }
    //En paso 2 debe seleccionar un asignado (persona, contrato o gerencia).
    if (formik.values.paso === 1 && !formik.values.asignado) {
      return true;
    }
    if (formik.values.paso === 2) {
      if (licenciasActivo.length > 0) {
        return false;
      } else {
        formik.setFieldValue('paso', 3);
        return true;
      }
    }
    if (formik.values.paso === 3) {
      return true;
    }
    //console.log(usuarioSesion);
    return false;
  };

  const fileChange = (name, e) => {
    formik.setFieldValue(name, e.target.files[0]);
    formik.setFieldTouched(name, true, false);
  };

  async function handleEliminar(licencia, bien) {
    console.log('Licencia a Eliminar: ', licencia);
    if (licencia) await deleteAsignacionLicenciaSoftware(licencia._id);
    await postLogLicenciaSoftware({
      _usuario_ref: usuarioSesion.ref,
      _licencia_software_ref: licencia._licencia_software_ref,
      descripcion: `Desasignación de Licencia de ${bien.codigo_activo}`,
    });
  }

  const handleAsignar = async (values) => {
    //crea variable para verificar si se eliminan las licencias de software asignados al activo
    let quita_licencias_software = false;

    try {
      values.formulario_estado.foto_antes = null;

      //Asignación del archivo subido.
      let uploadedFile = null;
      if (values.file) {
        uploadedFile = await uploadFileToCloudStorage(
          values.file,
          `activos/${bien.codigo_activo}/fotos_actuales`,
          `foto_actual_${Moment().format('DD_MM_YYYY_HH_mm')}`,
        );
        values.formulario_estado.foto_antes = uploadedFile;
      }

      let contratoPersona = '';

      //Asignaciones por tipo.
      switch (values.tipo.toLowerCase()) {
        case 'persona':
          //Una asignación de tipo PERSONA, ya tiene el ID del usuario. Se busca su gerencia y su contrato.
          const codigoContrato =
            values.asignado.contratos_ref && values.asignado.contratos_ref.length > 0
              ? values.asignado.contratos_ref[0].codigo
              : null;
          // let correlativo = String(codigoContrato).split("-")[2];
          contratoPersona = await ObtenerContratoPorCodigo(codigoContrato);
          values['_gerencia_ref'] = values.asignado.gerencia_ref._id;
          values['_contrato_ref'] = contratoPersona ? contratoPersona._id : null;
          console.log('Values: ', values);
          break;
        case 'gerencia':
          //Una asignación de tipo GERENCIA, solo tiene el ID de la gerencia.
          values['_gerencia_ref'] = values._asignado_ref;
          values['_contrato_ref'] = null;
          values['_asignado_ref'] = null;
          break;
        case 'contrato':
          //Una asignación de tipo CONTRATO, tiene el ID de la gerencia y del contrato. No tiene ID de persona.
          const contrato = await ObtenerContrato(values._asignado_ref);
          const gerenciaID = contrato.gerencia_ref._id;
          contratoPersona = contrato;
          // Se optimiza la consulta del codigo de contrato habiendo agregado a GAF-3-03 al listado de comprobación
          quita_licencias_software = CONTRATOS_LIBERA_LICENCIAS.includes(contrato.codigo);

          values['_gerencia_ref'] = gerenciaID;
          values['_contrato_ref'] = values._asignado_ref;
          values['_asignado_ref'] = null;
          break;
        default:
          throw 'Tipo de solicitud de asignación no válida.';
      }

      if (bien._contrato_ref) {
        //Si el bien tiene un contrato de origen, se asigna.
        values['_origen_contrato_ref'] = bien._contrato_ref._id;
      }
      if (bien._gerencia_ref) {
        //Si el bien tiene una gerencia de origen, se asigna.
        values['_origen_gerencia_ref'] = bien._gerencia_ref;
      }
      //Registro LOG de la acción.
      const registroLog = {
        _bien_ref: values._bien_ref,
        titulo: 'Asignación de Activo',
        cuerpo: `Activo asignado a ${values.asignado_nombre}, en contrato: ${
          contratoPersona ? contratoPersona.codigo + ' ' + contratoPersona.nombre : '---'
        }, por ${usuarioSesion.nombre}.`,
        tipo: 'asignacion_activo',
        icon: 'person_add',
        link: uploadedFile ? uploadedFile.url : undefined,
      };

      const responses = await Promise.all([
        GenerarSolicitudAsignacion(values),
        ActualizarAsignado(values._bien_ref, values.formulario_estado.estado),
        putBien(values._bien_ref, {
          foto_actual: values.formulario_estado.foto_antes ? values.formulario_estado.foto_antes.url : '',
          asignado_por: usuarioSesion.nombre,
        }),
        GenerarLog(registroLog),
      ]);
      notistack.enqueueSnackbar('Solicitud de asignación realizada exitosamente.', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar()}>
            <Close />
          </IconButton>
        ),
      });

      /**
       * ACEPTACIÓN DE SOLICITUD AUTOMÁTICA
       */
      const solicitudAsignacion = responses[0].data;
      const registroLogAceptar = {
        _bien_ref: bien._id,
        titulo: 'Solicitud de Asignación',
        cuerpo: `La solicitud de asignación del activo ${bien.codigo_activo} fue aceptada por sistema.`,
        tipo: 'solicitud_asignacion',
        icon: 'thumb_up',
      };
      const actualizacionBien = {
        _asignado_ref:
          solicitudAsignacion.tipo.toUpperCase() === 'persona'.toUpperCase() ? solicitudAsignacion._asignado_ref : null,
        _gerencia_ref: solicitudAsignacion._gerencia_ref,
        _contrato_ref: solicitudAsignacion._contrato_ref,
        condicion_actual: solicitudAsignacion.formulario_estado.estado,
        foto_actual: solicitudAsignacion.formulario_estado.foto_despues
          ? solicitudAsignacion.formulario_estado.foto_despues.url
          : undefined,
      };
      await Promise.all([
        AceptarAsignacion(solicitudAsignacion._id, solicitudAsignacion.formulario_estado.foto_despues),
        putBien(bien._id, actualizacionBien),
        GenerarLog(registroLogAceptar),
      ]);

      if (quita_licencias_software) {
        //El activo se mueve a GAF-8 o GAF-3-03
        const response = await getAsignacionesLicenciasSoftware(
          { _bien_ref_eq: values._bien_ref },
          [],
          [
            '_licencia_software_ref.nombre',
            '_licencia_software_ref.version',
            '_licencia_software_ref.codigo_compra',
            '_licencia_software_ref.tipo',
            '_licencia_software_ref._marca_ref',
            '_licencia_software_ref._marca_ref.marca',
            '_licencia_software_ref._version_ref',
          ],
        );
        if (response.status) {
          if (response.data.length > 0) {
            const licencia = [];
            response.data.forEach((element) => {
              licencia.push(`
							<p></p>
							<p>Nombre: ${element._licencia_software_ref.nombre || '---'}</p>
							<p>Versión: ${element._licencia_software_ref.version || '---'}</p>
							<p>Código de Compra: ${element._licencia_software_ref.codigo_compra || '---'}</p>
							<p>Tipo Licencia: ${element._licencia_software_ref.tipo || '---'}</p>
							<p></p>
							`);
              handleEliminar(element, bien);
            });

            //Contenido HTML con informacion para el email.
            const htmlContent = CONTENIDO_EMAIL_LIBERACION_LICENCIAS(bien.codigo_activo, licencia.join(''));
            console.log('enviando email, con texto: ', htmlContent);

            //Se envia email con la informacion de las licencias liberadas
            SendHTML(EMAILS_LIBERACION_LICENCIAS, TITULO_EMAIL_LIBERACION_LICENCIAS(bien.codigo_activo), htmlContent)
              .then(() =>
                console.log(
                  `Email de asignación de activo ${bien.codigo_activo} enviado a ${EMAILS_LIBERACION_LICENCIAS}`,
                ),
              )
              .catch((error) =>
                console.error(
                  `Error al intentar enviar email de liberación de licencia ${bien.codigo_activo} a preyes@cydingenieria.cl.`,
                  error,
                ),
              );
          }
        }
      } else {
        //El Activo se reasigna a otro contrato, gerencia o persona
        if (!permisos[REASIGNACION_LICENCIAS_TI]) {
          if (licenciasActivo.length > 0) {
            const licencia_eliminada = [];
            //encabezado de la tabla detalle
            licencia_eliminada.push(`
							<table border="1">
							<tr>
								<th>Nombre</th>
								<th>Versión</th>
								<th>Código de Compra</th>
								<th>Tipo Licencia</th>
							</tr>
							`);
            //información de la tabla detalle
            licenciasActivo.forEach((element) => {
              if (!element.checked) {
                licencia_eliminada.push(`
								<tr>
									<td>${element._licencia_software_ref.nombre || '---'}</td>
									<td>${element._licencia_software_ref.version || '---'}</td>
									<td>${element._licencia_software_ref.codigo_compra || '---'}</td>
									<td>${element._licencia_software_ref.tipo || '---'}</td>
								</tr>
								`);
                handleEliminar(element, bien);
              }
            });
            //fin de la tabla detalle
            licencia_eliminada.push(`
							</table>								
							`);

            const licencia_mantenida = [];
            //encabezado de la tabla detalle
            licencia_mantenida.push(`
							<table border="1">
							<tr>
								<th>Nombre</th>
								<th>Versión</th>
								<th>Código de Compra</th>
								<th>Tipo Licencia</th>
							</tr>
							`);
            //información de la tabla detalle
            licenciasActivo.forEach((element) => {
              if (element.checked) {
                licencia_mantenida.push(`
								<tr>
									<td>${element._licencia_software_ref.nombre || '---'}</td>
									<td>${element._licencia_software_ref.version || '---'}</td>
									<td>${element._licencia_software_ref.codigo_compra || '---'}</td>
									<td>${element._licencia_software_ref.tipo || '---'}</td>
								</tr>
								`);
              }
            });
            //fin de la tabla detalle
            licencia_mantenida.push(`
							</table>								
							`);

            //Contenido HTML con informacion para el email al usuario final.
            const htmlContent2 = CONTENIDO_EMAIL_REASIGNACION_LICENCIAS_USUARIO_FINAL(
              bien.codigo_activo,
              contratoPersona ? contratoPersona.codigo + ' ' + contratoPersona.nombre : '---',
              licencia_mantenida.join(''),
              usuarioSesion.nombre,
              values.asignado_nombre,
            );

            //Se envia email con la informacion de las licencias al usuario final
            if (values.asignado.contacto.email && values.asignado.contacto.email.length > 0) {
              SendHTML(
                values.asignado.contacto.email,
                TITULO_EMAIL_REASIGNACION_LICENCIAS_ACTIVO(bien.codigo_activo),
                htmlContent2,
              )
                .then(() =>
                  console.log(
                    `Email de asignación de activo ${bien.codigo_activo} enviado a ${EMAILS_REASIGNACION_LICENCIAS_ACTIVO}`,
                  ),
                )
                .catch((error) =>
                  console.error(
                    `Error al intentar enviar email de liberación de licencia ${bien.codigo_activo} a preyes@cydingenieria.cl.`,
                    error,
                  ),
                );
            }

            //Contenido HTML con informacion para el email.
            const htmlContent = CONTENIDO_EMAIL_REASIGNACION_LICENCIAS_ACTIVO(
              bien.codigo_activo,
              contratoPersona ? contratoPersona.codigo + ' ' + contratoPersona.nombre : '---',
              licencia_eliminada.join(''),
              licencia_mantenida.join(''),
              usuarioSesion.nombre,
              values.asignado_nombre,
            );
            console.log('enviando email, con texto: ', htmlContent);

            EMAILS_REASIGNACION_LICENCIAS_ACTIVO.push(usuarioSesion.email);
            //Se envia email con la informacion de las licencias liberadas
            SendHTML(
              EMAILS_REASIGNACION_LICENCIAS_ACTIVO,
              TITULO_EMAIL_REASIGNACION_LICENCIAS_ACTIVO(bien.codigo_activo),
              htmlContent,
            )
              .then(() =>
                console.log(
                  `Email de asignación de activo ${bien.codigo_activo} enviado a ${EMAILS_REASIGNACION_LICENCIAS_ACTIVO}`,
                ),
              )
              .catch((error) =>
                console.error(
                  `Error al intentar enviar email de liberacion de licencia ${bien.codigo_activo} a preyes@cydingenieria.cl.`,
                  error,
                ),
              );
          }
        }
      }

      notistack.enqueueSnackbar('Solicitud de asignación aceptada exitosamente.', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar()}>
            <Close />
          </IconButton>
        ),
      });
      /**
       * ACEPTACIÓN DE SOLICITUD AUTOMÁTICA
       */
    } catch (error) {
      console.error(error);
      notistack.enqueueSnackbar('Error al intentar realizar la asignación.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar()}>
            <Close />
          </IconButton>
        ),
      });
    } finally {
      handleCerrar();
    }
  };

  return (
    <Fragment>
      <Dialog open={openDialogAsignar} onClose={handleCerrar} fullWidth maxWidth="md">
        <DialogTitle>Asignar Activo</DialogTitle>
        <DialogContent dividers>
          <Typography>{`Formulario para asignar el activo ${bien.codigo_activo} ${bien.marca ? bien.marca : ''} ${
            bien.modelo ? bien.modelo : ''
          }.`}</Typography>

          {/* PASO 1 */}
          {formik.values.paso === 0 && (
            <Fragment>
              <RadioGroup name="tipo" value={formik.values.tipo} onChange={formik.handleChange}>
                <FormControlLabel value="persona" control={<Radio />} label="Asignar a Persona" />
                <FormControlLabel value="contrato" control={<Radio />} label="Asignar a Contrato" />
                {permisos && permisos[VER_OPCION_REGRESAR_A_GERENCIA_ACTIVO] && (
                  <FormControlLabel value="gerencia" control={<Radio />} label="Asignar a Gerencia" />
                )}
              </RadioGroup>
              <Typography variant="caption" color="error">
                {formik.touched.tipo && formik.errors.tipo ? formik.errors.tipo : ''}
              </Typography>
            </Fragment>
          )}

          {/* PASO 2 */}
          {formik.values.paso === 1 && (
            <div style={{ marginTop: 10 }}>
              {/* SELECCIONADOR DE USUARIO */}
              {formik.values.tipo === 'persona' && (
                <Autocomplete
                  options={usuarios || []}
                  // value={tipo_recurso}
                  loading={isValidatingUsuarios}
                  getOptionLabel={(usuario) => `${usuario.nombre_completo} (${usuario.contacto.email})`}
                  onChange={(event, value) => {
                    formik.setFieldValue('asignado', value);
                    formik.setFieldValue('asignado_nombre', value.nombre_completo);
                    formik.setFieldValue('_asignado_ref', value._id);
                  }}
                  size="small"
                  autoComplete
                  loadingText="Cargando..."
                  noOptionsText="Sin Datos"
                  renderInput={(params) => <TextField label="Usuario" variant="outlined" {...params} />}
                />
              )}
              {/* SELECCIONADOR DE CONTRATO */}
              {formik.values.tipo === 'contrato' && (
                <Autocomplete
                  options={contratos ? contratos.data : []}
                  // value={contrato}
                  loading={isValidatingContratos}
                  getOptionLabel={(contrato) => `[${contrato.codigo}] ${contrato.nombre}`}
                  onChange={(event, value) => {
                    formik.setFieldValue('asignado', value);
                    formik.setFieldValue('asignado_nombre', value.nombre);
                    formik.setFieldValue('_asignado_ref', value._id);
                  }}
                  size="small"
                  autoComplete
                  loadingText="Cargando..."
                  noOptionsText="Sin Datos"
                  renderInput={(params) => <TextField label="Contrato" variant="outlined" {...params} />}
                />
              )}
              {/* SELECCIONADOR DE GERENCIA */}
              {formik.values.tipo === 'gerencia' && (
                <Autocomplete
                  options={gerencias || []}
                  // value={gerencia}
                  loading={isValidatingGerencias}
                  getOptionLabel={(gerencia) => `[${gerencia.sigla}] ${gerencia.nombre}`}
                  onChange={(event, value) => {
                    formik.setFieldValue('asignado', value);
                    formik.setFieldValue('asignado_nombre', value.nombre);
                    formik.setFieldValue('_asignado_ref', value._id);
                  }}
                  size="small"
                  autoComplete
                  loadingText="Cargando..."
                  noOptionsText="Sin Datos"
                  renderInput={(params) => <TextField label="Gerencia" variant="outlined" {...params} />}
                />
              )}
            </div>
          )}

          {/* PASO 3 */}
          {formik.values.paso === 2 && licenciasActivo.length > 0 && (
            <div style={{ marginTop: 10 }}>
              <Tabla
                title={'Seleccione las licencias que mantendrá en el equipo:'}
                options={{
                  padding: 'dense',
                  paging: false,
                  search: false,
                  headerStyle: {
                    fontWeight: 'bold',
                  },
                  rowStyle: {
                    height: '10px',
                  },
                }}
                columns={columns}
                data={Array.isArray(licenciasActivo) ? licenciasActivo : []}
              />
            </div>
          )}

          {/* PASO 3 */}
          {formik.values.paso === 3 && (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  name="usuario_asignante_ref"
                  label="Usuario Asignante"
                  value={usuarioSesion.nombre}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  error={
                    formik.touched.usuario_asignante_ref &&
                    formik.errors.usuario_asignante_ref &&
                    Boolean(formik.errors.usuario_asignante_ref)
                  }
                  helperText={
                    formik.touched.usuario_asignante_ref && formik.errors.usuario_asignante_ref
                      ? formik.errors.usuario_asignante_ref
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="_bien_ref"
                  label="Bien"
                  value={bien.codigo_activo}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  error={formik.touched._bien_ref && formik.errors._bien_ref && Boolean(formik.errors._bien_ref)}
                  helperText={formik.touched._bien_ref && formik.errors._bien_ref ? formik.errors._bien_ref : ''}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="_bien_ref"
                  label="Marca"
                  value={bien.marca}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  error={formik.touched._bien_ref && formik.errors._bien_ref && Boolean(formik.errors._bien_ref)}
                  helperText={formik.touched._bien_ref && formik.errors._bien_ref ? formik.errors._bien_ref : ''}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="_bien_ref"
                  label="Modelo"
                  value={bien.modelo}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  error={formik.touched._bien_ref && formik.errors._bien_ref && Boolean(formik.errors._bien_ref)}
                  helperText={formik.touched._bien_ref && formik.errors._bien_ref ? formik.errors._bien_ref : ''}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="asignado_ref"
                  label="Asignado"
                  value={formik.values.asignado_nombre}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  error={
                    formik.touched.asignado_ref && formik.errors.asignado_ref && Boolean(formik.errors.asignado_ref)
                  }
                  helperText={
                    formik.touched.asignado_ref && formik.errors.asignado_ref ? formik.errors.asignado_ref : ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <MaterialReactSelect
                  value={
                    condiciones && condiciones.data.find((d) => d.label === formik.values.formulario_estado.estado)
                  }
                  label="Condición Actual"
                  isClearable
                  onChange={(newValue) =>
                    formik.setFieldValue('formulario_estado.estado', newValue ? newValue.label : '')
                  }
                  placeholder="Condición Actual"
                  options={condiciones.data || []}
                  noOptionsMessage={() => 'No ha entrado la Condición Actual'}
                  error={
                    formik.touched.formulario_estado &&
                    formik.touched.formulario_estado.estado &&
                    formik.errors.formulario_estado &&
                    formik.errors.formulario_estado.estado &&
                    Boolean(formik.errors.formulario_estado.estado)
                  }
                  helperText={
                    formik.touched.formulario_estado &&
                    formik.touched.formulario_estado.estado &&
                    formik.errors.formulario_estado &&
                    formik.errors.formulario_estado.estado
                      ? formik.errors.formulario_estado.estado
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="formulario_estado.descripcion"
                  label="Descripción (Opcional)"
                  value={formik.values.formulario_estado.descripcion}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  onChange={formik.handleChange}
                  error={
                    formik.touched.formulario_estado &&
                    formik.touched.formulario_estado.descripcion &&
                    formik.errors.formulario_estado &&
                    formik.errors.formulario_estado.descripcion &&
                    Boolean(formik.errors.formulario_estado.descripcion)
                  }
                  helperText={
                    formik.touched.formulario_estado &&
                    formik.touched.formulario_estado.descripcion &&
                    formik.errors.formulario_estado &&
                    formik.errors.formulario_estado.descripcion
                      ? formik.errors.formulario_estado.descripcion
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={
                    formik.touched.formulario_estado &&
                    formik.touched.formulario_estado.foto_antes &&
                    formik.errors.formulario_estado &&
                    formik.errors.formulario_estado.foto_antes &&
                    Boolean(formik.errors.formulario_estado.foto_antes)
                  }
                >
                  <FileInput
                    inputName="file"
                    meta={{ touched: formik.touched.file, errors: formik.errors.file }}
                    buttonColor="primary"
                    buttonVariant="outlined"
                    label="Foto de Antes (Opcional)"
                    value={formik.values.file}
                    handleOnChange={fileChange.bind(null, 'file')}
                    textVariant="outlined"
                    accept="image/x-png,image/gif,image/jpeg"
                  />
                  <FormHelperText>{formik.touched.file && formik.errors.file ? formik.errors.file : ''}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrar} color="primary" variant="outlined">
            Cancelar
          </Button>
          <Button
            onClick={() => handleChangePaso(-1)}
            disabled={formik.values.paso === 0}
            color="primary"
            variant="outlined"
          >
            Anterior
          </Button>
          <Button onClick={() => handleChangePaso(+1)} disabled={checkIsDisabled()} color="primary" variant="outlined">
            Siguiente
          </Button>
          <Button
            onClick={() => formik.handleSubmit()}
            disabled={formik.values.paso !== 3}
            color="primary"
            variant="contained"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      {/* PANTALLA DE CARGA */}
      <Backdrop className={classes.backdrop} open={formik.isSubmitting}>
        <Typography variant="h6">Procesando la solicitud de asignación...</Typography>
        <CircularProgress style={{ marginLeft: 10 }} />
      </Backdrop>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff',
  },
}));

export default DialogoRegresarActivo;
