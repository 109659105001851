import { Button, Grid, makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import FileInput from '../../../../components/fileInput';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { uploadFileToCloudStorage } from '../../../../services/fileUpload';
import { postLogUpdateValorLibro } from './../../../../services/request/requestLogUpdateValorLibro';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    maxWidth: '100%',
  },
  padding: {
    padding: theme.spacing(1),
  },
  center: {
    textAlign: 'center',
  },
  paddingCenter: {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  fileInput: {
    top: 'unset',
    left: 'unset',
    position: 'inherit',
    marginTop: '1px',
  },
}));

const validationSchema = yup.object({
  archivo: yup.mixed().required('Debes seleccionar la planilla excel con los valores libros'),
});

export default function SubirArchivoValorLibro() {
  const classes = useStyles();
  const notistack = useSnackbar();

  const comenzarCargayActualizacion = async (values) => {
    const key = notistack.enqueueSnackbar('Iniciando Procesamiento de Archivo...', {
      persist: true,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    });
    try {
      if (values.archivo) {
        let uploadedFile = null;

        if (values.archivo) {
          uploadedFile = await uploadFileToCloudStorage(values.archivo, `activos/valores_libros`, values.archivo.name);
        }

        await postLogUpdateValorLibro({
          archivo: uploadedFile,
          descripcion: values.descripcion,
        });

        notistack.closeSnackbar(key);
        const successkey = notistack.enqueueSnackbar(
          'Carga de planilla completadada, se le avisara por email el fin del procesamiento',
          {
            variant: 'success',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            action: (
              <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
                <Close />
              </IconButton>
            ),
          },
        );
      } else {
        notistack.closeSnackbar(key);
        const successkey = notistack.enqueueSnackbar('Debe seleccionar un archivo para la carga.', {
          variant: 'warning',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
              <Close />
            </IconButton>
          ),
        });
      }
    } catch (e) {
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar(
        'Error en carga de activos. Intente de nuevo o contacte con el equipo de soporte.',
        {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
              <Close />
            </IconButton>
          ),
        },
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      archivo: null,
      descripcion: '',
    },
    validationSchema: validationSchema,
    onSubmit: comenzarCargayActualizacion,
  });

  const { errors, touched, values, handleSubmit, setFieldValue, setFieldTouched, handleChange } = formik;

  const fileChange = (name, e) => {
    setFieldValue(name, e.target.files[0]);
    setFieldTouched(name, true, false);
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FileInput
            inputName="archivo"
            buttonColor="primary"
            buttonVariant="outlined"
            label="Planilla con valores Libro"
            value={values.archivo}
            handleOnChange={fileChange.bind(null, 'archivo')}
            textVariant="outlined"
            meta={{ touched: touched.archivo, error: errors.archivo }}
            accept=".xls,.xlsx"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Descripción"
            name="descripcion"
            value={values.descripcion}
            onChange={handleChange}
            error={touched.descripcion && errors.descripcion && Boolean(errors.descripcion)}
            helperText={touched.descripcion && errors.descripcion ? errors.descripcion : ''}
            variant="outlined"
            fullWidth
            multiline
            minRows={2}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.padding} alignContent="center" justifyContent="center">
        <Grid item lg className={classes.center}>
          <Button variant="contained" color="primary" type="submit">
            Comenzar Carga
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
