import axios from 'axios';
import { BACKEND } from '../../constants/urls'
/**
 * Obtener tarifas
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getTarifas(filter = {}, cancelToken = null) {
  return axios.get(`${BACKEND}/tarifas/`,
    {
      params: filter,
      cancelToken: cancelToken
    }).then(res => res.data.data);
}