import { Button, Checkbox, Dialog, DialogActions, DialogContent, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import MaterialTable from "@material-table/core";
import { getGerencias } from '../../../services/request/requestPortafolio';
import { InformeMovimientosActivosContext } from '.';
const localization = require("../../../data/material-table.location.json");


export default function SelectorGerencia() {

  const { openSelectGerencia: open, setOpenSelectGerencia: setOpen, setGerenciasSeleccionados } = useContext(InformeMovimientosActivosContext);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [totalChecked, setTotalChecked] = useState(0);
  const [allChecked, setAllChecked] = useState(false);

  async function handleEnter() {
  }


  function changeTitleCheck(e, checked) {
    setAllChecked(checked);
    setData(data.map(a => ({ ...a, checked: checked })));
    if (!checked) {
      setTotalChecked(0);
    } else {
      setTotalChecked(data.length);
    }
  }

  function checkCell(row) {
    if (row.checked) {
      row.checked = false;
      let newtotalChecked = totalChecked - 1;
      setAllChecked(false);
      setTotalChecked(newtotalChecked);
    } else {
      row.checked = true;
      let newtotalChecked = totalChecked + 1;
      setAllChecked(false);
      setTotalChecked(newtotalChecked);
    }
  }

  const columns = [
    {
      title: <Checkbox checked={allChecked} indeterminate={(!allChecked && totalChecked > 0)} onChange={changeTitleCheck} />,
      render: (row) => <Checkbox checked={row.checked} onChange={() => checkCell(row)} />
    },
    {
      title: "Sigla",
      field: "sigla"
    },
    {
      title: "Nombre",
      field: "nombre"
    }
  ];

  const theme = useTheme();
  const smBreak = useMediaQuery(theme.breakpoints.up('xs'));

  function handleSubmit() {
    const selected = data.filter(a => a.checked).map(a => a._id);
    setGerenciasSeleccionados(selected);
    setOpen(false);
  }

  const [pageSize, setPageSize] = useState(20);

  const loadData = useCallback(async () => {
    setIsLoading(true);
    setTotalChecked(0);
    setData([]);
    const response = await getGerencias();
    let totalChecked = 0;
    if (response) {
      for (let i in response) {
        response[i]["checked"] = false;
      }
      setAllChecked(totalChecked === response.length);
      setTotalChecked(totalChecked);
      setData(response);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onEntered={handleEnter}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent>
        <MaterialTable
          title={"Gerencias"}
          isLoading={isLoading}
          columns={columns}
          localization={localization}
          data={data ? data : []}
          style={{
            maxWidth: smBreak ? "92vw" : "72vw"
          }}
          options={{
            pageSize: pageSize,
            padding: "dense",
            headerStyle: {
              fontWeight: 'bold',
              color: "#731f1f",
            },
            emptyRowsWhenPaging: false
          }}
          onChangeRowsPerPage={(size) => setPageSize(size)}
        />
      </DialogContent>
      <DialogActions>
        <Grid container direction='row' alignItems="center" justify="center">
          <Button onClick={() => setOpen(false)} variant="outlined" color="primary" style={{ marginRight: '8px' }}>
            Cerrar
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Aceptar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}