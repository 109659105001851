import axios from 'axios';
import { BACKEND } from '../../constants/urls';
/**
 * Obtener tipos de recursos
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getMarcas(filter = {}, cancelToken = null) {
  return axios
    .get(`${BACKEND}/marcas/`, {
      params: filter,
      cancelToken,
    })
    .then((res) => res.data);
}
/**
 * Guardar Marca
 * @param {*} data datos de Marca
 * @param {*} cancelToken token para cancelacion
 */
export function postMarca(data, cancelToken = null) {
  return axios
    .post(`${BACKEND}/marcas/`, data, {
      cancelToken,
    })
    .then((res) => res.data);
}
/**
 * Actualizar marca
 * @param {String}  id
 * @param {Object} data datos del registro
 * @param {*} cancelToken token para cancelacion
 */
export function putMarca(id, data, cancelToken = null) {
  return axios
    .put(`${BACKEND}/marcas/${id}`, data, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}

/**
 * Eliminar marca
 * @param {String}  id
 * @param {*} cancelToken token para cancelacion
 */
export function deleteMarca(id, cancelToken = null) {
  return axios
    .delete(`${BACKEND}/marcas/${id}`, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}
