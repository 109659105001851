import axios from 'axios';
import { BACKEND } from '../../constants/urls';
/**
 * Obtener modelos
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getModelos(filter = {}, cancelToken = null) {
  return axios
    .get(`${BACKEND}/modelos/`, {
      params: filter,
      cancelToken,
    })
    .then((res) => res.data);
}
/**
 * Guardar Modelo
 * @param {*} data datos de Modelo
 * @param {*} cancelToken token para cancelacion
 */
export function postModelo(data, cancelToken = null) {
  return axios
    .post(`${BACKEND}/modelos/`, data, {
      cancelToken,
    })
    .then((res) => res.data);
}
/**
 * Actualizar modelo
 * @param {String}  id
 * @param {Object} data datos del registro
 * @param {*} cancelToken token para cancelacion
 */
export function putModelo(id, data, cancelToken = null) {
  return axios
    .put(`${BACKEND}/modelos/${id}`, data, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}

/**
 * Eliminar modelo
 * @param {String}  id
 * @param {*} cancelToken token para cancelacion
 */
export function deleteModelo(id, cancelToken = null) {
  return axios
    .delete(`${BACKEND}/modelos/${id}`, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}
