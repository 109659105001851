import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import Page from './page';
import { getSociedad } from '../../../services/request/requestPortafolio';
import { uploadFileToDrive, viewDriveFile, uploadFileToCloudStorage, viewFileGCS } from '../../../services/fileUpload';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useParams } from 'react-router';
import { getBien, putBien } from '../../../services/request/requestBienes';
import { getSolicitudesBaja } from '../../../services/request/requestSolicitudesBaja';
import { getPropietario } from '../../../services/request/requestPropietarios';
import { MainContext } from '../../../App';
import moment from 'moment';
import { getSolicitudesActivacion } from '../../../services/request/requestSolicitudesActivacion';
import { ObtenerCondicionesActuales } from '../../../services/request/requestCondicionesActuales';
import { GenerarLog } from '../../../services/activoLog';
import { GAF_CONTABILIDAD_ID, GAF_RRFF_ID, GAF_TI_ID, GERENCIA_GAF_ID } from '../../../constants/gerencias_contratos';
import { GenerarSolicitudAsignacion } from '../../../services/request/requestSolicitudesAsignacion';
import { getProveedoresSDA } from '../../../services/request/sda';

export const VerBienContext = createContext({
  bien: {},
  setBien: () => {},
  id: '',
  openActualizarKilometraje: false,
  setOpenActualizarKilometraje: () => {},
  openActualizarInformacion: false,
  setOpenActualizarInformacion: () => {},
  openActualizarContabilidad: false,
  setOpenActualizarContabilidad: () => {},
  openAgregarArchivo: false,
  setOpenAgregarArchivo: () => {},
  ArchivoRenovacion: null,
  SetArchivoRenovacion: () => {},
  openDialogoCondicionActual: false,
  setOpenDialogoCondicionActual: () => {},
  openDialogoActualizarClase: false,
  setOpenDialogoActualizarClase: () => {},
  openDialogVerQR: false,
  setOpenDialogVerQR: () => {},
  openDialogBaja: false,
  setOpenDialogBaja: () => {},
  openDialogRegresarActivo: false,
  setOpenDialogRegresarActivo: () => {},
  openDialogAsignar: false,
  setOpenDialogAsignar: () => {},
  permirtirBaja: false,
  setPermitirBaja: () => {},
  motivoBaja: '',
  setMotivoBaja: () => {},
  respaldoBaja: null,
  setRespaldoBaja: () => {},
  openDialogActivacion: false,
  setOpenDialogActivacion: () => {},
  permirtirActivacion: false,
  setPermitirActivacion: () => {},
  handleSolicitarActivacion: () => {},
  handleAnularActivar: () => {},
  handleSolicitarBaja: () => {},
  handleAnularBaja: () => {},
  handleVerArchivoFactura: () => {},
});

export default function VerBien() {
  const { id } = useParams();
  const { usuarioSesion } = useContext(MainContext);
  const [title, setTitle] = useState('Activo');
  const [permirtirActivacion, setPermitirActivacion] = useState(false);
  const [permirtirBaja, setPermitirBaja] = useState(false);
  const [CondicionesActuales, SetCondicionesActuales] = useState();
  const [openDialogoCondicionActual, setOpenDialogoCondicionActual] = useState(false);
  const [openDialogoActualizarClase, setOpenDialogoActualizarClase] = useState(false);
  const [openDialogVerQR, setOpenDialogVerQR] = useState(false);
  const [openDialogBaja, setOpenDialogBaja] = useState(false);
  const [openDialogRegresarActivo, setOpenDialogRegresarActivo] = useState(false);
  const [openDialogAsignar, setOpenDialogAsignar] = useState(false);
  const [openActualizarKilometraje, setOpenActualizarKilometraje] = useState(false);
  const [openActualizarInformacion, setOpenActualizarInformacion] = useState(false);
  const [openActualizarContabilidad, setOpenActualizarContabilidad] = useState(false);
  const [openAgregarArchivo, setOpenAgregarArchivo] = useState(false);
  const [motivoBaja, setMotivoBaja] = useState('');
  const [respaldoBaja, setRespaldoBaja] = useState(null);
  const [openDialogActivacion, setOpenDialogActivacion] = useState(false);
  const [IsLoading, SetIsLoading] = useState(false);
  const [ArchivoRenovacion, SetArchivoRenovacion] = useState();
  const [proveedores, setProveedores] = useState([]);

  const [bien, setBien] = useState({
    sociedad: '',
    contrato: '',
    clase: '',
    marca: '',
    serial_number: '',
    modelo: '',
    condicion_actual: '',
    ubicacion: '',
    asignadoA: '',
    _clase_ref: {
      is_vehiculo: false,
      is_computacional: false,
    },
  });
  const [propietario, setPropietario] = useState({
    nombre: '',
  });
  const [factura, setFactura] = useState({
    archivo: {
      nombre: '--',
    },
    proveedor: {
      nombre: '--',
    },
    numero: '',
    valor: '',
    fecha: '',
  });
  const notistack = useSnackbar();

  const [tipo, setTipo] = useState('');

  const loadData = useCallback(async () => {
    if (id) {
      const bien = await getBien(id, [
        '_clase_ref',
        '_usuario_ref',
        '_contrato_ref',
        '_equipo_ti_ref',
        '_vehiculo_ref',
        '_gerencia_ref',
        '_sociedad_tercero_ref',
      ]);

      if (bien) {
        console.log('Bien recuperado en Index: ', bien);
        const sociedad = bien._sociedad_ref ? await getSociedad(bien._sociedad_ref) : null;
        const propietario =
          bien.activo && bien.activo._propietario_ref ? await getPropietario(bien.activo._propietario_ref) : null;
        setTitle(`Activo: ${bien.codigo_activo}`);
        const dataBien = {
          ...bien,
          codigo_sociedad: sociedad ? sociedad.codigo : 'Tercero',
          sociedad: sociedad ? sociedad.nombre : 'Tercero',
          contrato: bien._contrato_ref ? bien._contrato_ref.codigo : '--',
          asignadoA: bien._usuario_ref ? bien._usuario_ref.nombre_completo : '--',
          clase: bien._clase_ref ? bien._clase_ref.nombre : 'Sin clase',
          contrato_leasing: bien?.contrato_leasing ? bien?.contrato_leasing : ' ',

          //clase: vehiculo._clase_ref.nombre ?  vehiculo._clase_ref.nombre : '--',
          //contrato: bien._contrato_ref?.nombre ? bien._contrato_ref.nombre : '--',
          codigo_contrato: bien._contrato_ref?.codigo ? bien._contrato_ref.codigo : '--',
          fecha_termino_contrato: bien._contrato_ref?.fecha_termino_estimada
            ? bien._contrato_ref.fecha_termino_estimada
            : '--',
          color: bien._vehiculo_ref?.color ? bien._vehiculo_ref?.color : '--',
          patente: bien._vehiculo_ref?.patente ? bien._vehiculo_ref?.patente : '--',
          kilometraje: bien._vehiculo_ref?.kilometraje ? bien._vehiculo_ref?.kilometraje : '0',
          tipo_combustible: bien._vehiculo_ref?.tipo_combustible ? bien._vehiculo_ref?.tipo_combustible : '--',
          transmision: bien._vehiculo_ref?.transmision ? bien._vehiculo_ref?.transmision : '--',
          sigla_gerencia: bien._gerencia_ref?.sigla ? bien._gerencia_ref?.sigla : '--',
          gerencia: bien._gerencia_ref?.nombre ? bien._gerencia_ref?.nombre : '--',
          //sociedad: bien._sociedad_ref ? bien._sociedad_ref.nombre : (bien._sociedad_tercero_ref ? bien._sociedad_tercero_ref.sociedad : '--'),
        };
        const dataPropietario = propietario;
        setBien(dataBien);

        if (bien._clase_ref && bien._clase_ref.is_computacional) {
          setTipo('Equipo TI');
        } else if (bien._clase_ref && bien._clase_ref.is_vehiculo) {
          setTipo('Vehículo');
        } else if (bien._clase_ref && bien._clase_ref.is_topografico) {
          setTipo('Equipo Topográfico');
        } else if (bien._clase_ref && bien._clase_ref.is_laboratorio) {
          setTipo('Equipo Laboratorio');
        } else if (bien._clase_ref && bien._clase_ref.is_otro_instrumento) {
          setTipo('Otros instrumentos');
        } else {
          setTipo('');
        }

        if (!bien.is_activo_contable) {
          const solicitudesActivacion = await getSolicitudesActivacion({ _bien_ref_eq: id, estado_eq: 'Pendiente' });
          if (!solicitudesActivacion || solicitudesActivacion.length === 0) {
            setPermitirActivacion(true);
          } else {
            setPermitirActivacion(false);
          }
        } else {
          setPermitirActivacion(false);
        }
        if (!bien.is_eliminado) {
          const solicitudesBaja = await getSolicitudesBaja({ _bien_ref_eq: id, estado_eq: 'Pendiente' });
          if (!solicitudesBaja || solicitudesBaja.length === 0) {
            setPermitirBaja(true);
          } else {
            setPermitirBaja(false);
          }
        } else {
          setPermitirBaja(false);
        }
        if (bien.activo && bien.activo.factura) {
          const dataFactura = {
            ...bien.activo.factura,
            fecha: bien.activo.factura ? moment(bien.activo.factura.fecha).format('DD/MM/YYYY') : '--/--/--',
            valor: bien.activo.valor,
          };
          setFactura(dataFactura);
        } else {
          setFactura({
            archivo: {
              nombre: '--',
            },
            proveedor: {
              nombre: '--',
            },
            numero: '',
            valor: '',
            fecha: '',
          });
        }
        if (dataPropietario) setPropietario(dataPropietario);
        else {
          setPropietario({
            nombre: '',
          });
        }
      }
      console.log('Bien seleccionado y complementado: ', bien);
    }
    // ProveedoresSDA()
    // 	.then(proveedores => setProveedores(proveedores))
    // 	.catch(error => setProveedores([]));
  }, [id]);

  useEffect(() => {
    ObtenerCondicionesActuales()
      .then((response) => SetCondicionesActuales(response.data))
      .catch((error) => {
        console.error(error);
        SetCondicionesActuales([]);
      });

    ProveedoresSDA()
      .then((proveedores) => setProveedores(proveedores))
      .catch((error) => setProveedores([]));
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData, openDialogoCondicionActual, openDialogoActualizarClase]);

  // const loadDataProveedores = () => {
  // 	ProveedoresSDA()
  // 	  .then(proveedores => setProveedores(proveedores))
  // 	  .catch(error => setProveedores([]));
  // }

  // useEffect(() => {
  // 	loadDataProveedores();
  // }, []);

  async function ProveedoresSDA() {
    try {
      const proveedores = await getProveedoresSDA();
      const optionsProveedor = proveedores
        .filter((value) => value.tipo_recurso === 'Vehículos')
        .map((value) => ({
          nombre: value.nombre,
          rut: value.rut,
          tipo_recurso: value.tipo_recurso,
          correo_transferencia: value.banco_email,
          numero_cuenta: value.banco_numero_cuenta,
          tipo_cuenta: value.banco_tipo_cuenta,
          banco: value.banco_nombre,
          cuenta_banco: {
            correo: value.banco_email,
            numero_cuenta: value.banco_numero_cuenta,
            tipo_cuenta: value.banco_tipo_cuenta,
            banco: value.banco_nombre,
            titular_cuenta: value.banco_titular_cuenta,
            rut_cuenta: value.banco_rut_cuenta,
          },
        }));
      console.log('Proveedores capturados: ', optionsProveedor);
      return optionsProveedor ? optionsProveedor : [];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const handleVerArchivoFactura = async (archivo) => {
    const key = notistack.enqueueSnackbar('Descargando Archivo...', {
      persist: true,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    });
    try {
      const downloadUrl = archivo.url.replace(/ /g, '%20');
      if (archivo.repositorio === 'Cloud Storage') viewFileGCS(downloadUrl, archivo.nombre);
      else await viewDriveFile(downloadUrl, archivo.nombre);
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar('Descarga completada', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
            <Close />
          </IconButton>
        ),
      });
    } catch (e) {
      console.log(e);
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar(
        'Error en descarga. Intente de nuevo o contacte con el equipo de soporte.',
        {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
              <Close />
            </IconButton>
          ),
        },
      );
    }
  };

  const handleSolicitarBaja = async (motivo, respaldo) => {
    if (!motivo) {
      const successkey = notistack.enqueueSnackbar('Debe ingresar un motivo para solitar la baja', {
        variant: 'warning',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
            <Close />
          </IconButton>
        ),
      });
      return;
    }
    if (!respaldo && bien.is_activo_contable) {
      const successkey = notistack.enqueueSnackbar('Debe seleccionar un archivo con repaldo de la baja', {
        variant: 'warning',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
            <Close />
          </IconButton>
        ),
      });
      return;
    }
    const key = notistack.enqueueSnackbar('Generando solicitud...', {
      persist: true,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    });
    try {
      let uploadedFile = null;
      if (respaldo) {
        const nombreRespaldoSinEspacios = respaldo.name.replace(/ /g, '_');
        uploadedFile = await uploadFileToCloudStorage(
          respaldo,
          `activos/${bien.codigo_activo}/${nombreRespaldoSinEspacios}`,
        );
      }
      if (!bien._contrato_ref) {
        notistack.closeSnackbar(key);
        const successkey = notistack.enqueueSnackbar(
          '¡Atención! Para dar de baja al bien debe estar asignado a un contrato',
          {
            variant: 'warning',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            action: (
              <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
                <Close />
              </IconButton>
            ),
          },
        );
        return;
      }

      let datosActualizacion = {
        is_eliminado: true,
        fecha_baja: new Date(),
        motivo_baja: motivo,
        adjunto_baja: uploadedFile,
      };
      let registroLog = {
        _bien_ref: id,
        titulo: 'Solicitud de Baja',
        cuerpo: `El usuario ${usuarioSesion.nombre} realizó la baja del activo ${bien.codigo_activo}.`,
        tipo: 'solicitud_baja',
        icon: 'check_circle_outline',
      };
      Promise.all([putBien(id, datosActualizacion), GenerarLog(registroLog)]);
      loadData();
      setOpenDialogBaja(false);
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar('Solicitud generada', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
            <Close />
          </IconButton>
        ),
      });
    } catch (e) {
      console.log(e);
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar(
        'Error al generar solicitud. Intente de nuevo o contacte con el equipo de soporte.',
        {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
              <Close />
            </IconButton>
          ),
        },
      );
    }
  };

  /**
   * Método encargado de anular una dada de baja de un activo.
   */
  const handleAnularBaja = async () => {
    try {
      notistack.enqueueSnackbar('Anulando la baja del activo...', {
        variant: 'info',
        persist: true,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
      });
      let datosActualizacion = {
        is_eliminado: true,
        fecha_baja: null,
        adjunto_baja: null,
        motivo_baja: '',
      };
      let registroLog = {
        _bien_ref: bien._id,
        titulo: 'Anulación de Baja',
        cuerpo: `El usuario ${usuarioSesion.nombre} anuló la baja del activo ${bien.codigo_activo}.`,
        tipo: 'solicitud_baja',
        icon: 'check_circle_outline',
      };
      await Promise.all([putBien(bien._id, datosActualizacion), GenerarLog(registroLog)]);
      loadData();
      setOpenDialogBaja(false);
      notistack.closeSnackbar();
      notistack.enqueueSnackbar('Operanción exitosa: Baja contable anulada', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (key) => (
          <IconButton onClick={() => notistack.closeSnackbar(key)}>
            <Close />
          </IconButton>
        ),
      });
    } catch (e) {
      console.error(e);
      notistack.closeSnackbar();
      notistack.enqueueSnackbar(
        'Error al intentar anular la baja contable. Intente de nuevo o contacte con el equipo de soporte.',
        {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (key) => (
            <IconButton onClick={() => notistack.closeSnackbar(key)}>
              <Close />
            </IconButton>
          ),
        },
      );
    }
  };

  const handleSolicitarActivacion = async (motivo) => {
    const key = notistack.enqueueSnackbar('Generando solicitud...', {
      persist: true,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    });
    try {
      let datosActualizacion = {
        motivo_activacion: motivo,
        is_activo_contable: true,
        is_no_activable: false,
        fecha_activacion: new Date(),
        estado_activacion: 'Aprobado Registro Contable',
      };
      let registroLog = {
        _bien_ref: id,
        titulo: 'Solicitud de Activación',
        cuerpo: `El usuario ${usuarioSesion.nombre} realizó la activación del activo ${bien.codigo_activo}.`,
        tipo: 'solicitud_activacion',
        icon: 'check_circle_outline',
      };
      await Promise.all([putBien(bien._id, datosActualizacion), GenerarLog(registroLog)]);
      loadData();
      setOpenDialogActivacion(false);
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar('Solicitud generada', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
            <Close />
          </IconButton>
        ),
      });
    } catch (e) {
      console.log(e);
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar(
        'Error al generar solicitud. Intente de nuevo o contacte con el equipo de soporte.',
        {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
              <Close />
            </IconButton>
          ),
        },
      );
    }
  };

  /**
   * Método encargado de anular una activación de un bien.
   */
  const handleAnularActivar = async () => {
    try {
      notistack.enqueueSnackbar('Anulando la activación...', {
        variant: 'info',
        persist: true,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
      });
      let datosActualizacion = {
        is_activo_contable: false,
        is_no_activable: true,
        estado_activacion: '',
        fecha_activacion: null,
        estado_solicitud_activacion: 'Sin solicitud',
      };
      let registroLog = {
        _bien_ref: bien._id,
        titulo: 'Anulación de Activación',
        cuerpo: `El usuario ${usuarioSesion.nombre} anuló la activación del activo ${bien.codigo_activo}.`,
        tipo: 'solicitud_activacion',
        icon: 'check_circle_outline',
      };
      await Promise.all([putBien(bien._id, datosActualizacion), GenerarLog(registroLog)]);
      loadData();
      setOpenDialogActivacion(false);
      notistack.closeSnackbar();
      notistack.enqueueSnackbar('Operanción exitosa: Activación anulada', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (key) => (
          <IconButton onClick={() => notistack.closeSnackbar(key)}>
            <Close />
          </IconButton>
        ),
      });
    } catch (e) {
      console.error(e);
      notistack.closeSnackbar();
      notistack.enqueueSnackbar(
        'Error al intentar anular la activación. Intente de nuevo o contacte con el equipo de soporte.',
        {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (key) => (
            <IconButton onClick={() => notistack.closeSnackbar(key)}>
              <Close />
            </IconButton>
          ),
        },
      );
    }
  };

  const handleRegresarActivo = async (ubicacion) => {
    try {
      //Si regresa a su gerencia o contrato.
      if (ubicacion === 'contrato' || ubicacion === 'gerencia') {
        let actualizacionBien = {
          _asignado_ref: null,
          _contrato_ref: null,
          _gerencia_ref: null,
          is_asignado: false,
          asignado_por: null,
        };

        let registroLog = {
          _bien_ref: id,
          titulo: 'Retorno de Activo',
          cuerpo: `El usuario ${usuarioSesion.nombre} regresó el activo ${bien.codigo_activo}.`,
          tipo: 'retorno_activo',
          icon: 'keyboard_return',
        };

        switch (ubicacion) {
          case 'contrato':
            //Si se regresa a contrato, se quita el usuario asignado.
            actualizacionBien._asignado_ref = null;
            actualizacionBien._contrato_ref = undefined;
            actualizacionBien._gerencia_ref = undefined;
            break;
          case 'gerencia':
            //Si regresa a gerencia, se quita el contrato y usuario asignado.
            actualizacionBien._asignado_ref = null;
            actualizacionBien._contrato_ref = null;
            actualizacionBien._gerencia_ref = undefined;
            break;
          default:
            break;
        }

        await Promise.all([putBien(id, actualizacionBien), GenerarLog(registroLog)]);

        notistack.enqueueSnackbar('Activo regresado exitosamente.', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar()}>
              <Close />
            </IconButton>
          ),
        });
      }

      //Si retorna a GAF RRFF, GAF TI o GAF Contabilidad.
      if (ubicacion === 'rrff_ti' || ubicacion === 'contabilidad') {
        let solicitudRetorno = {
          _asignado_ref: null,
          _bien_ref: bien._id,

          _contrato_ref: null,
          _gerencia_ref: null,

          _origen_contrato_ref: null,
          _origen_gerencia_ref: null,

          _usuario_asignante_ref: usuarioSesion.ref,
          estado: 'pendiente',
          tipo: 'contrato',
          formulario_estado: {
            estado: bien.condicion_actual,
            descripcion: '',
            foto_antes: null,
          },
        };

        let registroLog = {
          _bien_ref: id,
          titulo: 'Retorno de Activo',
          cuerpo: `El usuario ${usuarioSesion.nombre} solicitó retornar el activo ${bien.codigo_activo}.`,
          tipo: 'retorno_activo',
          icon: 'keyboard_return',
        };

        switch (ubicacion) {
          case 'rrff_ti':
            //Si regresa a RRFF o TI, se quita el asignado, se actualiza el contrato y gerencia de GAF.
            solicitudRetorno._contrato_ref = bien._clase_ref.is_computacional ? GAF_TI_ID : GAF_RRFF_ID;
            solicitudRetorno._gerencia_ref = GERENCIA_GAF_ID;
            break;
          case 'contabilidad':
            //Si se regresa a contabilidad, se quita el asignado y se actualiza el contrato y gerencia de GAF.
            solicitudRetorno._contrato_ref = GAF_CONTABILIDAD_ID;
            solicitudRetorno._gerencia_ref = GERENCIA_GAF_ID;
            break;
          default:
            break;
        }

        if (bien._contrato_ref) {
          //Si el bien tiene un contrato de origen, se asigna.
          solicitudRetorno._origen_contrato_ref = bien._contrato_ref._id;
        } else {
          //Si el bien no tiene un contrato de origen, se asigna.
          solicitudRetorno._origen_contrato_ref = undefined;
        }

        if (bien._gerencia_ref) {
          //Si el bien tiene una gerencia de origen, se asigna.
          solicitudRetorno._origen_gerencia_ref = bien._gerencia_ref;
        } else {
          //Si el bien no tiene una gerencia de origen, se asigna.
          solicitudRetorno._origen_gerencia_ref = undefined;
        }

        await Promise.all([GenerarSolicitudAsignacion(solicitudRetorno), GenerarLog(registroLog)]);

        notistack.enqueueSnackbar('Solicitud de retorno de activo generada exitosamente.', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar()}>
              <Close />
            </IconButton>
          ),
        });
      }
      loadData();
      setOpenDialogRegresarActivo(false);
    } catch (error) {
      console.error(error);
      notistack.enqueueSnackbar(
        'Error al intentar regresar el activo. Intente de nuevo o contacte con el equipo de soporte.',
        {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar()}>
              <Close />
            </IconButton>
          ),
        },
      );
    }
  };

  /**
   * Método encargado de subir una imagen actual para un activo.
   * https://medium.com/web-dev-survey-from-kyoto/how-to-customize-the-file-upload-button-in-react-b3866a5973d8
   * @param {*} file imagen del activo.
   */
  const handleSubirFotoActual = async (file) => {
    try {
      if (file) {
        //Si se seleccionó un archivo.
        if (file.type.includes('image/')) {
          // Si el archivo es de tipo imagen.
          let uploaded = null;
          uploaded = await uploadFileToCloudStorage(
            file,
            `activos/${bien.codigo_activo}/fotos_actuales`,
            `foto_actual_${moment().format('DD_MM_YYYY_HH_mm')}`,
          );

          notistack.enqueueSnackbar('Subiendo imagen actual del activo.', {
            variant: 'info',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            action: (key) => (
              <IconButton onClick={() => notistack.closeSnackbar(key)}>
                <Close />
              </IconButton>
            ),
          });

          if (uploaded) {
            //Si el archivo fue subido exitosamente.
            let registroLog = {
              _bien_ref: id,
              titulo: 'Actualización de Imagen',
              cuerpo: `El usuario ${usuarioSesion.nombre} actualizó la imagen del activo ${bien.codigo_activo}.`,
              tipo: 'actualizacion',
              icon: 'image',
              link: uploaded ? uploaded.url : undefined,
            };

            await Promise.all([putBien(id, { foto_actual: uploaded ? uploaded.url : '' }), GenerarLog(registroLog)]);
            loadData();
            notistack.enqueueSnackbar('Imagen subida exitosamente.', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'center',
                vertical: 'bottom',
              },
              action: (key) => (
                <IconButton onClick={() => notistack.closeSnackbar(key)}>
                  <Close />
                </IconButton>
              ),
            });
          } else {
            //Si se generó un error al intentar subir el archivo.
            throw 'Error al intentar subir la imagen.';
          }
        } else {
          //Si el archivo no es de tipo imagen.
          notistack.enqueueSnackbar('Error, formato de imagen incorrecto.', {
            variant: 'warning',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            action: (key) => (
              <IconButton onClick={() => notistack.closeSnackbar(key)}>
                <Close />
              </IconButton>
            ),
          });
        }
      } else {
        //Si no se seleccionó un archivo.
        notistack.enqueueSnackbar('No se seleccionó ningún archivo.', {
          variant: 'info',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (key) => (
            <IconButton onClick={() => notistack.closeSnackbar(key)}>
              <Close />
            </IconButton>
          ),
        });
      }
    } catch (error) {
      console.error('Se produjo un error: ', error);
      notistack.enqueueSnackbar('Error al intentar subir la imagen actual del activo.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (key) => (
          <IconButton onClick={() => notistack.closeSnackbar(key)}>
            <Close />
          </IconButton>
        ),
      });
    }
  };

  const handleOpenDialogoCondicionActual = () => {
    setOpenDialogoCondicionActual(true);
  };

  const handleCloseDialogoCondicionActual = () => {
    setOpenDialogoCondicionActual(false);
  };

  const handleOpenDialogoActualizarClase = () => {
    setOpenDialogoActualizarClase(true);
  };

  const handleCloseDialogoActualizarClase = () => {
    setOpenDialogoActualizarClase(false);
  };

  const handleDialogVerQR = () => {
    window.scrollTo(0, 0);
    setOpenDialogVerQR(true);
  };

  const handleDialogBaja = () => {
    setOpenDialogBaja(true);
  };

  const handleDialogRegresarActivo = () => {
    setOpenDialogRegresarActivo(true);
  };

  const handleDialogAsignar = () => {
    setOpenDialogAsignar(true);
  };

  const handleDialogActualizarContabilidad = () => {
    setOpenActualizarContabilidad(true);
  };

  const handleDialogAgregarArchivo = (archivo = null) => {
    setOpenAgregarArchivo(true);
    SetArchivoRenovacion(archivo);
  };

  return (
    <VerBienContext.Provider
      value={{
        bien,
        setBien,
        tipo,
        id,
        openActualizarKilometraje,
        setOpenActualizarKilometraje,
        openActualizarInformacion,
        setOpenActualizarInformacion,
        openActualizarContabilidad,
        setOpenActualizarContabilidad,
        openAgregarArchivo,
        setOpenAgregarArchivo,
        condiciones_actuales: CondicionesActuales,
        openDialogoCondicionActual,
        setOpenDialogoCondicionActual,
        openDialogoActualizarClase,
        setOpenDialogoActualizarClase,
        openDialogVerQR,
        setOpenDialogVerQR,
        openDialogBaja,
        setOpenDialogBaja,
        openDialogActivacion,
        setOpenDialogActivacion,
        openDialogRegresarActivo,
        setOpenDialogRegresarActivo,
        openDialogAsignar,
        setOpenDialogAsignar,
        handleCloseDialogoCondicionActual,
        handleOpenDialogoCondicionActual,
        handleOpenDialogoActualizarClase,
        handleCloseDialogoActualizarClase,
        handleDialogActualizarContabilidad,
        handleDialogAgregarArchivo,
        ArchivoRenovacion,
        SetArchivoRenovacion,
        handleDialogVerQR,
        handleDialogBaja,
        handleDialogRegresarActivo,
        handleDialogAsignar,
        handleSolicitarBaja,
        handleAnularBaja,
        is_loading: IsLoading,
        SetIsLoading,
        permirtirBaja,
        setPermitirBaja,
        permirtirActivacion,
        setPermitirActivacion,
        motivoBaja,
        setMotivoBaja,
        respaldoBaja,
        setRespaldoBaja,
        handleSolicitarActivacion,
        handleAnularActivar,
        handleRegresarActivo,
        loadData,
        handleVerArchivoFactura,
      }}
    >
      <Page
        id={id}
        title={title}
        bien={bien}
        proveedores={proveedores}
        factura={factura}
        propietario={propietario}
        condiciones_actuales={CondicionesActuales}
        handleVerArchivoFactura={handleVerArchivoFactura}
        handleSubirFotoActual={handleSubirFotoActual}
      />
    </VerBienContext.Provider>
  );
}
