import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import React, { useContext, useState, useCallback, useEffect } from 'react';
import { VerBienContext } from '.';
import { MainContext } from '../../../App';
import {
  postEquipoComputacional,
  postVehiculo,
  putBien,
  putEquipoComputacional,
  putVehiculo,
} from '../../../services/request/requestBienes';
import { getUbicaciones, ObtenerDetalleUbicacionesPorIDListado } from '../../../services/request/requestUbicaciones';
import { GenerarLog } from '../../../services/activoLog';
import { getMarcas } from '../../../services/request/requestMarcas';
import { getModelos } from '../../../services/request/requestModelos';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import useSWR from 'swr';
import moment from 'moment';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { DatePicker } from '@material-ui/pickers';
import MaterialReactSelect from '../../../components/materialReactSelect';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    margin: theme.spacing(2, 0, 2, 0),
  },
}));

async function getData(listado) {
  if (!listado?.length) return [];

  let detalles = await ObtenerDetalleUbicacionesPorIDListado('', listado);

  return detalles ? detalles : [];
}

export default function DialogoActualizarInformacionActivo() {
  const { data: marcas, isValidating: isValidatingMarcas } = useSWR('get:marcas', (key) => getMarcas(), {
    revalidateOnFocus: false,
  });
  const { data: modelos, isValidating: isValidatingModelos } = useSWR('get:modelos', (key) => getModelos(), {
    revalidateOnFocus: false,
  });
  const { data: ubicaciones, isValidating: isValidatingUbicaciones } = useSWR(
    'get:ubicaciones',
    (key) => getUbicaciones({}, ['ubicacion', '_detalle_ubicacion_ref'], ['_detalle_ubicacion_ref.resumen_detalle']),
    { revalidateOnFocus: false },
  );
  // const { data: detalleubicaciones, isValidating: isValidatingDetalleUbicaciones } = useSWR("get:detalle-ubicaciones", (key) => getData(values.ubicaciones?.detalle_ubicacion), { revalidateOnFocus: false });
  const { bien, openActualizarInformacion, setOpenActualizarInformacion, loadData } = useContext(VerBienContext);
  const { usuarioSesion } = useContext(MainContext);
  const [tipo, setTipo] = useState('');
  const [detalleubicaciones, setDetalleUbicaciones] = useState([]);
  const [tieneDetalleUbicacion, setTieneDetalleUbicacion] = useState(false);
  const notistack = useSnackbar();

  const formik = useFormik({
    initialValues: {
      marca_ref: null,
      marca: '',
      modelo_ref: null,
      modelo: '',
      serial_number: '',
      descripcion: '',
      ubicacion: '',
      ubicaciones: null,
      detalle_ubicacion: '',
    },
    validationSchema: object().shape({
      serial_number: string().optional(),
      descripcion: string().optional(),
    }),
    onSubmit: async (values) => {
      const key = notistack.enqueueSnackbar('Guardando cambios...', {
        persist: true,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
      });
      try {
        if (bien) {
          let registroLog = {
            _bien_ref: bien._id,
            titulo: 'Actualización de información',
            cuerpo: `El usuario ${usuarioSesion.nombre} actualizó la información del activo ${bien.codigo_activo}.`,
            tipo: 'actualizacion',
            icon: 'edit',
          };

          if (bien._clase_ref && bien._clase_ref.is_computacional) {
            if (!bien._equipo_ti_ref) {
              const response = await postEquipoComputacional({
                disco_duro: values.disco_duro,
                procesador: values.procesador,
                memoria_ram: values.memoria_ram,
                pulgadas_pantalla: values.pulgadas_pantalla,
              });
              await putBien(bien._id, {
                marca: values.marca,
                modelo: values.modelo,
                serial_number: values.serial_number,
                descripcion: values.descripcion,
                ubicacion: values.ubicacion,
                detalle_ubicacion: values.detalle_ubicacion,
                _equipo_ti_ref: response._id,
              });
            } else {
              await putBien(bien._id, {
                marca: values.marca,
                modelo: values.modelo,
                serial_number: values.serial_number,
                descripcion: values.descripcion,
                ubicacion: values.ubicacion,
                detalle_ubicacion: values.detalle_ubicacion,
              });
              await putEquipoComputacional(bien._equipo_ti_ref._id, {
                disco_duro: values.disco_duro,
                procesador: values.procesador,
                memoria_ram: values.memoria_ram,
                pulgadas_pantalla: values.pulgadas_pantalla,
              });
            }
          } else if (bien._clase_ref && bien._clase_ref.is_vehiculo) {
            if (!bien._vehiculo_ref) {
              const response = await postVehiculo({
                kilometraje: values.kilometraje,
                numero_motor: values.numero_motor,
                numero_chasis: values.numero_chasis,
                color: values.color,
                ano: values.ano,
                tipo_combustible: values.tipo_combustible,
                transmision: values.transmision,
                cilindrada: values.cilindrada,
                patente: values.patente,
                tag: values.tag,
              });
              await putBien(bien._id, {
                marca: values.marca,
                modelo: values.modelo,
                serial_number: values.serial_number,
                descripcion: values.descripcion,
                ubicacion: values.ubicacion,
                detalle_ubicacion: values.detalle_ubicacion,
                _vehiculo_ref: response._id,
              });
            } else {
              await putBien(bien._id, {
                marca: values.marca,
                modelo: values.modelo,
                serial_number: values.serial_number,
                descripcion: values.descripcion,
                ubicacion: values.ubicacion,
                detalle_ubicacion: values.detalle_ubicacion,
              });
              await putVehiculo(bien._vehiculo_ref._id, {
                kilometraje: values.kilometraje,
                numero_motor: values.numero_motor,
                numero_chasis: values.numero_chasis,
                color: values.color,
                ano: values.ano,
                tipo_combustible: values.tipo_combustible,
                transmision: values.transmision,
                cilindrada: values.cilindrada,
                patente: values.patente,
                tag: values.tag,
              });
            }
          } else if (
            bien._clase_ref &&
            (bien._clase_ref.is_topografico || bien._clase_ref.is_otro_instrumento || bien._clase_ref.is_laboratorio)
          ) {
            await putBien(bien._id, {
              marca: values.marca,
              modelo: values.modelo,
              serial_number: values.serial_number,
              descripcion: values.descripcion,
              ubicacion: values.ubicacion,
              detalle_ubicacion: values.detalle_ubicacion,
              usos: values.usos,
              rango_medicion: values.rango_medicion,
              grado_precision: values.grado_precision,
              periodo_recalibracion: values.periodo_recalibracion,
              tolerancias: values.tolerancias,
              accesorios: values.accesorios,
            });
          } else {
            await putBien(bien._id, {
              marca: values.marca,
              modelo: values.modelo,
              serial_number: values.serial_number,
              descripcion: values.descripcion,
              ubicacion: values.ubicacion,
            });
          }
          await GenerarLog(registroLog);
          loadData();
          setOpenActualizarInformacion(false);
        }
        notistack.closeSnackbar(key);
        const successkey = notistack.enqueueSnackbar('Operanción exitosa: Información actualizada', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
              <Close />
            </IconButton>
          ),
        });
      } catch (e) {
        console.log(e);
        notistack.closeSnackbar(key);
        const errorkey = notistack.enqueueSnackbar(
          'Error: No ha sido posible actualizar la información. Intente de nuevo o contacte con Soporte.',
          {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            action: (
              <IconButton onClick={() => notistack.closeSnackbar(errorkey)}>
                <Close />
              </IconButton>
            ),
          },
        );
      }
    },
  });

  const classes = useStyles();

  const { handleSubmit, values, touched, errors, setFieldValue, resetForm, handleChange } = formik;

  const resetFormulario = useCallback(async () => {
    resetForm();
  }, [resetForm]);

  const findDetallesUbicacionesPorID = useCallback(async (ListadosIDs) => {
    let detalles_encontrados = await getData(ListadosIDs);

    console.log(detalles_encontrados);

    setDetalleUbicaciones(detalles_encontrados ? detalles_encontrados : []);
  }, []);

  useEffect(() => {
    resetFormulario();
  }, [resetFormulario]);

  useEffect(() => {
    if (!values.ubicaciones?._detalle_ubicacion_ref?.length) return setTieneDetalleUbicacion(false);

    findDetallesUbicacionesPorID(values.ubicaciones?._detalle_ubicacion_ref);

    return setTieneDetalleUbicacion(true);
  }, [values.ubicaciones, tieneDetalleUbicacion]);

  function handleEntered() {
    let tipo = '';

    let values = {
      marca_ref: bien && marcas && marcas.data ? Array.from(marcas.data).find((m) => m.nombre === bien.marca) : null,
      marca: bien ? bien.marca : '',
      modelo_ref:
        bien && marcas && marcas.data && modelos && modelos.data
          ? Array.from(modelos.data).find((m) => m.nombre === bien.modelo)
          : null,
      modelo: bien ? bien.modelo : '',
      serial_number: bien ? bien.serial_number : '',
      descripcion: bien ? bien.descripcion : '',
      ubicacion: bien ? bien.ubicacion : '',
      ubicaciones:
        bien && ubicaciones && ubicaciones.data
          ? Array.from(ubicaciones.data).find((m) => m.ubicacion === bien.ubicacion)
          : null,
      detalle_ubicacion: bien ? bien?.detalle_ubicacion : '',
    };

    if (bien._clase_ref && bien._clase_ref.is_computacional) {
      tipo = 'Equipo TI';
      values = {
        ...values,
        disco_duro: bien._equipo_ti_ref && bien._equipo_ti_ref.disco_duro ? bien._equipo_ti_ref.disco_duro : '',
        procesador: bien._equipo_ti_ref && bien._equipo_ti_ref.procesador ? bien._equipo_ti_ref.procesador : '',
        memoria_ram: bien._equipo_ti_ref && bien._equipo_ti_ref.memoria_ram ? bien._equipo_ti_ref.memoria_ram : '',
        pulgadas_pantalla:
          bien._equipo_ti_ref && bien._equipo_ti_ref.pulgadas_pantalla ? bien._equipo_ti_ref.pulgadas_pantalla : '',
      };
    } else if (bien._clase_ref && bien._clase_ref.is_vehiculo) {
      tipo = 'Vehículo';
      values = {
        ...values,
        kilometraje: bien._vehiculo_ref && bien._vehiculo_ref.kilometraje ? bien._vehiculo_ref.kilometraje : '',
        numero_motor: bien._vehiculo_ref && bien._vehiculo_ref.numero_motor ? bien._vehiculo_ref.numero_motor : '',
        numero_chasis: bien._vehiculo_ref && bien._vehiculo_ref.numero_chasis ? bien._vehiculo_ref.numero_chasis : '',
        color: bien._vehiculo_ref && bien._vehiculo_ref.color ? bien._vehiculo_ref.color : '',
        ano: bien._vehiculo_ref && bien._vehiculo_ref.ano ? bien._vehiculo_ref.ano : '',
        tipo_combustible:
          bien._vehiculo_ref && bien._vehiculo_ref.tipo_combustible ? bien._vehiculo_ref.tipo_combustible : '',
        transmision: bien._vehiculo_ref && bien._vehiculo_ref.transmision ? bien._vehiculo_ref.transmision : '',
        cilindrada: bien._vehiculo_ref && bien._vehiculo_ref.cilindrada ? bien._vehiculo_ref.cilindrada : '',
        patente: bien._vehiculo_ref && bien._vehiculo_ref.patente ? bien._vehiculo_ref.patente : '',
        tag: bien._vehiculo_ref && bien._vehiculo_ref.tag ? bien._vehiculo_ref.tag : '',
      };
    } else if (
      bien._clase_ref &&
      (bien._clase_ref.is_topografico || bien._clase_ref.is_otro_instrumento || bien._clase_ref.is_laboratorio)
    ) {
      tipo = 'Equipo Laboratorio/Topografía';
      values = {
        ...values,
        usos: bien.usos,
        rango_medicion: bien.rango_medicion,
        grado_precision: bien.grado_precision,
        periodo_recalibracion: bien.periodo_recalibracion,
        tolerancias: bien.tolerancias,
        accesorios: bien.accesorios,
      };
    }
    resetForm({ values: values });
    setTipo(tipo);
    console.log('estas son las ubicaciones: ', ubicaciones);
  }

  const dateChange = (name, e) => {
    formik.setFieldValue(name, e);
    formik.setFieldTouched(name, true, false);
  };

  return (
    <Dialog
      open={openActualizarInformacion}
      onClose={() => setOpenActualizarInformacion(false)}
      TransitionProps={{
        onEntered: handleEntered,
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>Actualizar Información</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              {/* MARCA */}
              <Autocomplete
                value={values.marca_ref}
                options={
                  marcas && marcas.data ? Array.from(marcas.data).sort((a, b) => SortBy(a.nombre, b.nombre)) : []
                }
                getOptionLabel={(marca) => marca.nombre}
                disabled={!marcas}
                noOptionsText="Sin Datos"
                loading={isValidatingMarcas}
                onChange={(event, value) => {
                  setFieldValue('marca_ref', value);
                  setFieldValue('marca', value ? value.nombre : '');
                  setFieldValue('modelo', '');
                }}
                renderInput={(params) => (
                  <TextField
                    label="Marca"
                    variant="outlined"
                    error={touched.marca && errors.marca && Boolean(errors.marca)}
                    helperText={touched.marca && errors.marca ? errors.marca : ''}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              {/* MODELO */}
              <Autocomplete
                value={values.modelo_ref}
                options={
                  marcas && marcas.data && modelos && modelos.data
                    ? Array.from(modelos.data)
                        .filter((m) => values.marca_ref && m._marca_ref === values.marca_ref._id)
                        .sort((a, b) => SortBy(a.nombre, b.nombre))
                    : []
                }
                getOptionLabel={(modelo) => modelo.nombre}
                disabled={!values.marca || !modelos}
                noOptionsText="Sin Datos"
                loading={isValidatingModelos}
                onChange={(event, value) => {
                  setFieldValue('modelo_ref', value);
                  setFieldValue('modelo', value ? value.nombre : '');
                }}
                renderInput={(params) => (
                  <TextField
                    label="Modelo"
                    variant="outlined"
                    error={touched.modelo && errors.modelo && Boolean(errors.modelo)}
                    helperText={touched.modelo && errors.modelo ? errors.modelo : ''}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              {/* SERIAL NUMBER */}
              <TextField
                name="serial_number"
                label="N° de Serie"
                value={values.serial_number}
                onChange={handleChange}
                error={touched.serial_number && errors.serial_number && Boolean(errors.serial_number)}
                helperText={touched.serial_number && errors.serial_number ? errors.serial_number : ''}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                value={
                  ubicaciones && ubicaciones.data
                    ? Array.from(ubicaciones.data).find((m) => m.ubicacion === bien.ubicacion)
                    : ''
                }
                //value={values.ubicacion}
                options={
                  ubicaciones && ubicaciones.data
                    ? Array.from(ubicaciones.data).sort((a, b) => SortBy(a.ubicacion, b.ubicacion))
                    : []
                }
                getOptionLabel={(ubicacion) => ubicacion.ubicacion}
                disabled={!ubicaciones}
                noOptionsText="Sin Datos"
                loading={isValidatingUbicaciones}
                onChange={(event, value) => {
                  console.log('Ubicacion seleccionada: ', value);
                  setFieldValue('ubicacion', value ? value.ubicacion : '');
                  setFieldValue('ubicaciones', value ? value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    label="Ubicación"
                    variant="outlined"
                    error={touched.ubicacion && errors.ubicacion && Boolean(errors.ubicacion)}
                    helperText={touched.ubicacion && errors.ubicacion ? errors.ubicacion : ''}
                    {...params}
                  />
                )}
              />
            </Grid>
            {values.ubicaciones?._detalle_ubicacion_ref?.length && (
              <Grid item xs={6}>
                <MaterialReactSelect
                  value={
                    detalleubicaciones
                      ? Array.from(detalleubicaciones).find((m) => m.resumen_detalle === bien?.detalle_ubicacion)
                      : ''
                  }
                  label="Posición"
                  isClearable={false}
                  onChange={(event, value) => {
                    setFieldValue('detalle_ubicacion', value ? value.resumen_detalle : '');
                  }}
                  placeholder="Seleccionar Posición"
                  options={
                    values.ubicaciones?._detalle_ubicacion_ref &&
                    values.ubicaciones?._detalle_ubicacion_ref.map((value, index) => ({
                      value,
                      label: value?.resumen_detalle ? value?.resumen_detalle : '',
                    }))
                  }
                  noOptionsMessage={() => 'No ha entrado la Posición'}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              {/* DESCRIPCIÓN */}
              <TextField
                name="descripcion"
                label="Descripción"
                value={values.descripcion}
                onChange={handleChange}
                error={touched.descripcion && errors.descripcion && Boolean(errors.descripcion)}
                helperText={touched.descripcion && errors.descripcion ? errors.descripcion : ''}
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
          {/* DATOS VEHICULO */}
          {tipo !== '' && (
            <Typography variant="h6" className={classes.subtitle}>
              Información de {tipo}
            </Typography>
          )}

          {tipo === 'Vehículo' && (
            <Grid container spacing={2}>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <CurrencyTextField
                  label="Kilometraje"
                  name="kilmetraje"
                  value={values.kilometraje}
                  onChange={handleChange}
                  error={touched.kilometraje && errors.kilometraje && Boolean(errors.kilometraje)}
                  helperText={touched.kilometraje && errors.kilometraje ? errors.kilometraje : ''}
                  fullWidth
                  variant="outlined"
                  currencySymbol="KM"
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  leading="deny"
                  decimalPlaces={0}
                  decimalPlacesShownOnBlur={0}
                  decimalPlacesShownOnFocus={0}
                  disabled
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  label="N° Motor"
                  fullWidth
                  variant={'outlined'}
                  value={values.numero_motor}
                  name="numero_motor"
                  onChange={handleChange}
                  error={touched.numero_motor && errors.numero_motor && Boolean(errors.numero_motor)}
                  helperText={touched.numero_motor && errors.numero_motor ? errors.numero_motor : ''}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  label="N° Chasis"
                  fullWidth
                  variant={'outlined'}
                  value={values.numero_chasis}
                  name="numero_chasis"
                  onChange={handleChange}
                  error={touched.numero_chasis && errors.numero_chasis && Boolean(errors.numero_chasis)}
                  helperText={touched.numero_chasis && errors.numero_chasis ? errors.numero_chasis : ''}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  label="Color"
                  fullWidth
                  variant={'outlined'}
                  value={values.color}
                  name="color"
                  onChange={handleChange}
                  error={touched.color && errors.color && Boolean(errors.color)}
                  helperText={touched.color && errors.color ? errors.color : ''}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <DatePicker
                  name="ano"
                  autoOk
                  fullWidth
                  InputLabelProps={{
                    classes: {
                      shrink: 'shrink',
                    },
                  }}
                  views={['year']}
                  inputVariant="outlined"
                  label="Año"
                  format="yyyy"
                  helperText={touched.ano ? errors.ano : ''}
                  error={touched.ano && Boolean(errors.ano)}
                  onChange={dateChange.bind(null, 'ano')}
                  value={moment(values.ano)
                    .utc()
                    .add(moment().utcOffset() * -1, 'minutes')}
                  maxDate={moment().startOf('year').add(1, 'years')}
                  minDate={moment().startOf('year').subtract(20, 'years')}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  label="Tipo Combustible"
                  fullWidth
                  variant={'outlined'}
                  value={values.tipo_combustible}
                  name="tipo_combustible"
                  onChange={handleChange}
                  error={touched.tipo_combustible && errors.tipo_combustible && Boolean(errors.tipo_combustible)}
                  helperText={touched.tipo_combustible && errors.tipo_combustible ? errors.tipo_combustible : ''}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  label="Transmisión"
                  fullWidth
                  variant={'outlined'}
                  value={values.transmision}
                  name="transmision"
                  onChange={handleChange}
                  error={touched.transmision && errors.transmision && Boolean(errors.transmision)}
                  helperText={touched.transmision && errors.transmision ? errors.transmision : ''}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  label="Cilindrada"
                  fullWidth
                  variant={'outlined'}
                  value={values.cilindrada}
                  name="cilindrada"
                  onChange={handleChange}
                  error={touched.cilindrada && errors.cilindrada && Boolean(errors.cilindrada)}
                  helperText={touched.cilindrada && errors.cilindrada ? errors.cilindrada : ''}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  label="Patente"
                  fullWidth
                  variant={'outlined'}
                  value={values.patente}
                  name="patente"
                  onChange={handleChange}
                  error={touched.patente && errors.patente && Boolean(errors.patente)}
                  helperText={touched.patente && errors.patente ? errors.patente : ''}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  label="Tag"
                  fullWidth
                  variant={'outlined'}
                  value={values.tag}
                  name="tag"
                  onChange={handleChange}
                  error={touched.tag && errors.tag && Boolean(errors.tag)}
                  helperText={touched.tag && errors.tag ? errors.tag : ''}
                />
              </Grid>
            </Grid>
          )}

          {/* DATOS EQUIPO TI */}
          {tipo === 'Equipo TI' && (
            <Grid container spacing={2}>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  label="Disco Duro"
                  fullWidth
                  variant={'outlined'}
                  value={values.disco_duro}
                  name="disco_duro"
                  onChange={handleChange}
                  error={touched.disco_duro && errors.disco_duro && Boolean(errors.disco_duro)}
                  helperText={touched.disco_duro && errors.disco_duro ? errors.disco_duro : ''}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  label="Procesador"
                  fullWidth
                  variant={'outlined'}
                  value={values.procesador}
                  name="procesador"
                  onChange={handleChange}
                  error={touched.procesador && errors.procesador && Boolean(errors.procesador)}
                  helperText={touched.procesador && errors.procesador ? errors.procesador : ''}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  label="Memoria Ram"
                  fullWidth
                  variant={'outlined'}
                  value={values.memoria_ram}
                  name="memoria_ram"
                  onChange={handleChange}
                  error={touched.memoria_ram && errors.memoria_ram && Boolean(errors.memoria_ram)}
                  helperText={touched.memoria_ram && errors.memoria_ram ? errors.memoria_ram : ''}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  label="Pulgadas Pantalla"
                  fullWidth
                  variant={'outlined'}
                  value={values.pulgadas_pantalla}
                  name="pulgadas_pantalla"
                  onChange={handleChange}
                  error={touched.pulgadas_pantalla && errors.pulgadas_pantalla && Boolean(errors.pulgadas_pantalla)}
                  helperText={touched.pulgadas_pantalla && errors.pulgadas_pantalla ? errors.pulgadas_pantalla : ''}
                />
              </Grid>
            </Grid>
          )}

          {/* DATOS EQUIPO LABORATORIO/TOPOGRAFICO */}
          {tipo === 'Equipo Laboratorio/Topografía' && (
            <Grid container spacing={2}>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  label="Usos"
                  fullWidth
                  variant={'outlined'}
                  value={values.usos}
                  name="usos"
                  onChange={handleChange}
                  error={touched.usos && errors.usos && Boolean(errors.usos)}
                  helperText={touched.usos && errors.usos ? errors.usos : ''}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  label="Rango de medición"
                  fullWidth
                  variant={'outlined'}
                  value={values.rango_medicion}
                  name="rango_medicion"
                  onChange={handleChange}
                  error={touched.rango_medicion && errors.rango_medicion && Boolean(errors.rango_medicion)}
                  helperText={touched.rango_medicion && errors.rango_medicion ? errors.rango_medicion : ''}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  label="Grado de precision"
                  fullWidth
                  variant={'outlined'}
                  value={values.grado_precision}
                  name="grado_precision"
                  onChange={handleChange}
                  error={touched.grado_precision && errors.grado_precision && Boolean(errors.grado_precision)}
                  helperText={touched.grado_precision && errors.grado_precision ? errors.grado_precision : ''}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  label="Período de recalibración"
                  fullWidth
                  variant={'outlined'}
                  value={values.periodo_recalibracion}
                  name="periodo_recalibracion"
                  onChange={handleChange}
                  error={
                    touched.periodo_recalibracion &&
                    errors.periodo_recalibracion &&
                    Boolean(errors.periodo_recalibracion)
                  }
                  helperText={
                    touched.periodo_recalibracion && errors.periodo_recalibracion ? errors.periodo_recalibracion : ''
                  }
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  label="Tolerancias"
                  fullWidth
                  variant={'outlined'}
                  value={values.tolerancias}
                  name="tolerancias"
                  onChange={handleChange}
                  error={touched.tolerancias && errors.tolerancias && Boolean(errors.tolerancias)}
                  helperText={touched.tolerancias && errors.tolerancias ? errors.tolerancias : ''}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  label="Accesorios"
                  fullWidth
                  variant={'outlined'}
                  value={values.accesorios}
                  name="accesorios"
                  onChange={handleChange}
                  error={touched.accesorios && errors.accesorios && Boolean(errors.accesorios)}
                  helperText={touched.accesorios && errors.accesorios ? errors.accesorios : ''}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => setOpenActualizarInformacion(false)}>
            Cerrar
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Actualizar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

/**
 * Método usado para ordenar elementos de una colección.
 * @param {*} text1 Texto para comparar.
 * @param {*} text2 Texto para comparar.
 * @returns Resultado de la comparación.
 */
function SortBy(text1, text2) {
  if (text1 < text2) {
    return -1;
  } else if (text1 > text2) {
    return 1;
  } else {
    return 0;
  }
}
