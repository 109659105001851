import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(1, 1, 3, 1),
  },
  center: {
    textAlign: 'center',
  },
  main: {
    overflow: 'auto',
    maxWidth: '95vw',
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  tableHeader: {
    textAlign: 'center',
    border: '1px solid #E0E0E0',
    fontWeight: 'bold',
    color: '#731f1f',
    position: 'sticky',
  },
  tableCell: {
    border: '1px solid #E0E0E0',
  },
  btnMargin: {
    margin: theme.spacing(1),
  },
}));

export default function PageFormularioBasicoBienes(props) {
  const { formik, handleVolver } = props;
  const { errors, handleChange, touched, values, handleSubmit } = formik;
  const classes = useStyles();

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Typography variant="h5">Formulario de Equipos Computacionales</Typography>
      <Grid container spacing={1}>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <TextField
            label="Disco duro"
            fullWidth
            variant={'outlined'}
            margin={'normal'}
            name="disco_duro"
            value={values.disco_duro}
            onChange={handleChange}
            error={touched.disco_duro && errors.disco_duro && Boolean(errors.disco_duro)}
            helperText={touched.disco_duro && errors.disco_duro ? errors.disco_duro : ''}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <TextField
            label="Procesador"
            fullWidth
            variant={'outlined'}
            margin={'normal'}
            name="procesador"
            value={values.procesador}
            onChange={handleChange}
            error={touched.procesador && errors.procesador && Boolean(errors.procesador)}
            helperText={touched.procesador && errors.procesador ? errors.procesador : ''}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <TextField
            label="Memoria Ram"
            fullWidth
            variant={'outlined'}
            margin={'normal'}
            name="memoria_ram"
            value={values.memoria_ram}
            onChange={handleChange}
            error={touched.memoria_ram && errors.memoria_ram && Boolean(errors.memoria_ram)}
            helperText={touched.memoria_ram && errors.memoria_ram ? errors.memoria_ram : ''}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <TextField
            label="Pulgadas Pantalla"
            fullWidth
            variant={'outlined'}
            margin={'normal'}
            name="pulgadas_pantalla"
            value={values.pulgadas_pantalla}
            onChange={handleChange}
            error={touched.pulgadas_pantalla && errors.pulgadas_pantalla && Boolean(errors.pulgadas_pantalla)}
            helperText={touched.pulgadas_pantalla && errors.pulgadas_pantalla ? errors.pulgadas_pantalla : ''}
          />
        </Grid>
      </Grid>
      <Grid container alignContent="center" justify="center">
        <Grid item lg md sm xs className={classes.center}>
          <Button variant="outlined" className={classes.btnMargin} onClick={handleVolver}>
            Volver
          </Button>
          <Button type="submit" color="primary" variant="contained" className={classes.btnMargin}>
            Siguiente
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
