import React from 'react';
import { Card, CardContent, CardHeader, CardActions, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  miniCards: {
    margin: theme.spacing(0, 1, 0, 1),
    maxWidth: '100%',
  },
  miniCardsTitle: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1, 0, 1, 0),
    minHeight: '52px',
  },
  link: {
    textAlign: 'center',
  },
}));

export default function CustomMiniCards({ title, children }) {
  const classes = useStyles();

  return (
    <Card className={classes.miniCards}>
      <CardHeader
        titleTypographyProps={{ variant: 'body2', component: 'h6', align: 'center' }}
        className={classes.miniCardsTitle}
        title={`${title}`}
      />
      <CardContent>
        <Typography variant="body2" align="center">
          {children}
        </Typography>
      </CardContent>
    </Card>
  );
}

export function CustomMiniCardsWithDownload({ title, children, downloadLink }) {
  const classes = useStyles();
  return (
    <CustomMiniCards title={title}>
      {children}
      <CardActions className={classes.link}>
        {downloadLink && (
          <a href={downloadLink} download target="_blank">
            Descargar contrato
          </a>
        )}
      </CardActions>
    </CustomMiniCards>
  );
}
