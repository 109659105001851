export const BACKEND = 'https://api-activos-dev.cydocs.cl';
export const PORTAFOLIO = 'https://api-portafolio.cydocs.cl';
export const ADMINISTRACION_USUARIOS = 'https://api-usuarios.cydocs.cl';
//export const BACKEND = 'http://localhost:8000';
//export const PORTAFOLIO = 'http://localhost:5001';
//export const ADMINISTRACION_USUARIOS = 'http://localhost:5002';
//export const BACKEND_GP = "http://localhost:5000";
export const BACKEND_SDA = 'https://api-sda.cydocs.cl/v1';
export const BACKEND_GP = "https://api-rendiciones.cydocs.cl";
export const FRONTEND = 'https://activos.cydocs.cl';
export const LOGIN = 'https://account.cydocs.cl';
export const REDIRECT_BASE = 'https://api-usuarios.cydocs.cl/login';
export const URL_MESA_AYUDA_CYD = 'https://soportecyd.sd.cloud.invgate.net/';

