import React, { createContext, useState } from 'react';
import {
  AppBar,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { Fragment } from 'react';
import TablaAsignacioneLicenciasSoft from './tablaAsignacionesLicenciasSoft';
import PanelDetalleLicenciaSoftware from './panelDetalleLicenciaSoftware';
import useSWR from 'swr';
import { getLicenciaSoftwarePorID } from '../../services/request/requestLicenciasSoftware';
import { useParams } from 'react-router';
import TablaLogLicenciasSoftware from './tablaLogLicenciasSoftware';


export const DetalleLicenciaSoftwareContext = createContext({
  dataLicencia: {},
  refreshDataLicencia: () => { }
});



export default function DetalleLicenciaSoftware() {
  const { id } = useParams();
  const { data: licenciaSoftware, mutate } = useSWR("get:licencia", () => getLicenciaSoftwarePorID(id), {});
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();
  const smBreak = useMediaQuery(theme.breakpoints.down('sm'));


  return (
    <Fragment>
      <DetalleLicenciaSoftwareContext.Provider
        value={{
          dataLicencia: licenciaSoftware,
          refreshDataLicencia: () => mutate()
        }}
      >
        <PanelDetalleLicenciaSoftware />
        <br />
        <AppBar position="static">
          <Tabs value={selectedTab} onChange={(event, tabIndex) => setSelectedTab(tabIndex)}
            centered={!smBreak}
            variant={smBreak ? "scrollable" : "standard"}
            scrollButtons={smBreak ? "on" : "off"}
          >
            <Tab label="Asignaciones" />
            <Tab label="Log" />
          </Tabs>
        </AppBar>
        <br />
        {selectedTab === 0 &&
          <TablaAsignacioneLicenciasSoft />
        }
        {selectedTab === 1 &&
          <TablaLogLicenciasSoftware />
        }
      </DetalleLicenciaSoftwareContext.Provider>
    </Fragment>
  );

}