import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { Box, Dialog, Tab, Tabs } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import { deleteDocumentoAdjunto, getDocumentoAdjunto } from '../../../services/request/requestBienes';
import Moment from 'moment';
import { MainContext } from '../../../App';
import { ELIMINAR_ADJUNTOS } from '../../../constants/permissions';
const localization = require('../../../data/material-table.location.json');

export default function TablaDocumentosAdjuntos(props) {
  const { id, handleVerArchivo, handleDialogAgregarArchivo } = props;
  const { permisos } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [isOpen, setIsOpen] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);

  const loadData = useCallback(async () => {
    if (id) {
      const adjuntos = await getDocumentoAdjunto({ _bien_ref_eq: id });
      if (adjuntos.status) {
        setData(adjuntos.data.reverse());
      } else {
        setData([]);
      }
      setIsLoading(false);
    }
  }, [id, handleDialogAgregarArchivo]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  async function handleEliminarArchivoRequerido(row) {
    await deleteDocumentoAdjunto(row._id);
    loadData();
  }

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered={true}
        value={selectedTab}
        onChange={(event, value) => setSelectedTab(value)}
        TabIndicatorProps={{ style: { height: 3 } }}
      >
        <Tab label="Documentos Actuales" />
        <Tab label="Documentos Históricos" />
      </Tabs>

      <TabPanel value={selectedTab} index={0}>
        <MaterialTable
          title="Documentos Actuales"
          options={{
            filtering: false,
            pageSizeOptions: [5, 10, 20, 50],
            pageSize,
            grouping: false,
            columnsButton: true,
            maxBodyHeight: '70vh',
            headerStyle: {
              backgroundColor: '#731f1f',
              color: '#ffffff',
              textAlign: 'center',
              position: 'sticky',
            },
            rowStyle: {
              border: '1px solid #E0E0E0',
            },
            padding: 'dense',
            actionsCellStyle: {
              margin: '0 auto',
              display: 'flex',
              flexGrow: '1',
              justifyContent: 'center',
              border: '1px solid #ffffff',
              padding: '6px 24px 6px 21px',
            },
            cellStyle: {
              border: '1px solid #E0E0E0',
            },
          }}
          onChangeRowsPerPage={(size) => {
            setPageSize(size);
          }}
          isLoading={isLoading}
          localization={localization}
          data={data.filter((d) => !d.is_renovado)}
          actions={[
            (rowData) => ({
              icon: 'visibility',
              tooltip: 'Ver Archivo',
              hidden: !rowData.archivo,
              onClick: (event, row) =>
                row?.archivo?.url.includes('https://storage.googleapis.com/')
                  ? setIsOpen(row?.archivo)
                  : handleVerArchivo(row?.archivo),
            }),
            {
              icon: 'update',
              tooltip: 'Renovar',
              onClick: (event, row) => handleDialogAgregarArchivo(row),
            },
            {
              icon: 'add',
              tooltip: 'Agregar Archivo',
              isFreeAction: true,
              onClick: () => handleDialogAgregarArchivo(),
            },
            {
              icon: 'delete',
              tooltip: 'Eliminar Archivo Requerido',
              hidden: !(permisos && permisos[ELIMINAR_ADJUNTOS]),
              onClick: (event, row) => handleEliminarArchivoRequerido(row),
            },
          ]}
          columns={[
            {
              title: 'Tipo',
              field: 'tipo',
            },
            {
              title: 'Nombre',
              field: 'archivo.nombre',
            },
            {
              title: 'Fecha del Documento',
              render: (row) => Moment(row.fecha).format('DD/MM/YYYY'),
            },
            {
              title: 'Fecha de Renovación',
              render: (row) => (row.fecha_proxima_carga ? Moment(row.fecha_proxima_carga).format('DD/MM/YYYY') : '---'),
            },
            {
              title: 'Valor(Permiso Circulación)',
              field: 'valor_permiso_circulacion',
            },
            {
              title: 'Municipalidad',
              field: 'municipalidad',
            },
            {
              title: 'Aseguradora',
              field: 'aseguradora',
            },
            {
              title: 'Prima Seguro',
              field: 'valor_prima_seguro',
            },
            {
              title: 'Nº Poliza',
              field: 'poliza_seguro',
            },
            {
              title: 'Laboratorio Proveedor',
              field: 'laboratorio_proveedor',
            },
          ]}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <MaterialTable
          title="Documentos Históricos"
          options={{
            filtering: false,
            pageSizeOptions: [5, 10, 20, 50],
            pageSize,
            grouping: false,
            columnsButton: true,
            maxBodyHeight: '70vh',
            headerStyle: {
              backgroundColor: '#731f1f',
              color: '#ffffff',
              textAlign: 'center',
              position: 'sticky',
            },
            rowStyle: {
              border: '1px solid #E0E0E0',
            },
            padding: 'dense',
            actionsCellStyle: {
              margin: '0 auto',
              display: 'flex',
              flexGrow: '1',
              justifyContent: 'center',
              border: '1px solid #ffffff',
              padding: '6px 24px 6px 21px',
            },
            cellStyle: {
              border: '1px solid #E0E0E0',
            },
          }}
          onChangeRowsPerPage={(size) => {
            setPageSize(size);
          }}
          isLoading={isLoading}
          localization={localization}
          data={data.filter((d) => Boolean(d.is_renovado))}
          actions={[
            (rowData) => ({
              icon: 'visibility',
              tooltip: 'Ver Archivo',
              hidden: !rowData?.archivo,
              onClick: (event, row) =>
                row?.archivo?.url.includes('https://storage.googleapis.com/') //row?.archivo?.repositorio === 'Cloud Storage'
                  ? setIsOpen(row?.archivo)
                  : handleVerArchivo(row?.archivo),
            }),
          ]}
          columns={[
            {
              title: 'Tipo',
              field: 'tipo',
            },
            {
              title: 'Nombre',
              field: 'archivo.nombre',
            },
            {
              title: 'Fecha del Documento',
              render: (row) => Moment(row.fecha).format('DD/MM/YYYY'),
            },
            {
              title: 'Fecha de Renovación',
              render: (row) => Moment(row.fecha_proxima_carga).format('DD/MM/YYYY'),
            },
          ]}
        />
      </TabPanel>

      <Dialog maxWidth="lg" open={Boolean(isOpen)} onClose={() => setIsOpen('')}>
        {isOpen && isOpen.mime_type.includes('image/') && <img src={isOpen.url} alt="Imagen" style={{ margin: 20 }} />}
        {isOpen.mime_type === 'application/pdf' && (
          <embed src={isOpen.url} style={{ width: '90vw', height: '100vh' }} />
        )}
      </Dialog>
    </Fragment>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}
