import { Box, Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { MovimientoMensualContext } from '.';

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: "15px",
    margin: theme.spacing(1, 0, 2, 0)
  },
  container:{
    maxWidth: "100%"
  }
}));


export default function IndicadoresMovimientoMensual() {

  const { indicadores} = useContext(MovimientoMensualContext);

  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Container className={classes.container}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={3} xl={3}>
            <Paper elevation={4} style={{ borderRadius: 15 }}>
              <Box display="flex" justifyContent="center">
                <Typography variant="h6">Activos Registrados</Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Typography variant="h4">{indicadores.totalBienes}</Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={3} xl={3}>
            <Paper elevation={4} style={{ borderRadius: 15 }}>
              <Box display="flex" justifyContent="center">
                <Typography variant="h6">Activos Asignados</Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Typography variant="h4">{indicadores.totalAsignaciones}</Typography>
              </Box>
            </Paper>
          </Grid>
          {/* <Grid item xs={3} xl={3}>
            <Paper elevation={4} style={{ borderRadius: 15 }}>
              <Box display="flex" justifyContent="center">
                <Typography variant="h6">Solicitudes Aceptadas</Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Typography variant="h4">{indicadores.totalAsignacionesAceptadas}</Typography>
              </Box>
            </Paper>
          </Grid> */}
          {/* <Grid item xs={3} xl={3}>
            <Paper elevation={4} style={{ borderRadius: 15 }}>
              <Box display="flex" justifyContent="center">
                <Typography variant="h6">Solicitudes Pendientes</Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Typography variant="h4">{indicadores.totalAsignacionesPendientes}</Typography>
              </Box>
            </Paper>
          </Grid> */}
        </Grid>
      </Container>
    </Paper>
  );

}