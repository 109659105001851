import React from 'react';
import MathJax from 'react-mathjax2';
import PropTypes from 'prop-types';

function CalcularTarifa({ tipoTarifa, valorInicial = 0, anios = 0 }) {
  let tipoTarifaEncontrado = false;
  // Función para generar la fórmula según el tipo de tarifa
  const generarFormula = (tipoTarifa) => {
    let formulasTipo = '';
    switch (tipoTarifa) {
      case 'Vehículos':
        if (anios <= 5) {
          formulasTipo = 'V.T = (V.I - (V.P.V - V.L)) / 60 + ((C.P) / 12) + C.S + ((C.I) / 60)';
        } else {
          formulasTipo = 'V.T = V.F + ((C.P) / 12) + C.S';
        }
        tipoTarifaEncontrado = true;
        break;
      case 'Computadores':
        if (valorInicial > 200000) {
          formulasTipo = anios <= 2 ? 'V.T = ((V.I) / 24)' : 'V.T = ((V.I) * 0.1) / 12';
        } else {
          formulasTipo = 'V.T = 0';
        }
        tipoTarifaEncontrado = true;
        break;
      case 'Equipos Topográficos':
      case 'Equipos Laboratorio':
      case 'Impresoras':
        if (valorInicial >= 200000) {
          if (valorInicial >= 1000000) {
            formulasTipo = anios <= 4 ? 'V.T = ((V.I) / 48)' : 'V.T = ((V.I) * 0.1) / 12';
          } else {
            if (valorInicial < 1000000) {
              formulasTipo = anios <= 2 ? 'V.T = ((V.I) / 24)' : 'V.T = ((V.I) * 0.1) / 12';
            }
          }
        } else {
          formulasTipo = 'V.T = 0';
        }
        tipoTarifaEncontrado = true;
        break;
      default:
        formulasTipo = 'Fórmula no encontrada';
        tipoTarifaEncontrado = false;
    }
    return formulasTipo;
  };

  const formula = generarFormula(tipoTarifa);

  return (
    <>
      {`Fórmula para calcular el valor de tarifa de gestión (V.T) de ${tipoTarifa}:`}
      <div style={{ marginTop: '4px', marginBottom: '4px' }}>
        {tipoTarifaEncontrado ? (
          <MathJax.Context>
            <div style={{ textAlign: 'center' }}>
              <MathJax.Node>{`${formula}`}</MathJax.Node>
            </div>
          </MathJax.Context>
        ) : (
          <div style={{ textAlign: 'center' }}>Formula no encontrada</div>
        )}
      </div>
    </>
  );
}

CalcularTarifa.propTypes = {
  tipoTarifa: PropTypes.string.isRequired,
  valorInicial: PropTypes.number,
  anios: PropTypes.number,
};

export default CalcularTarifa;
