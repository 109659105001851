import { useEffect, useState } from 'react';
import { getProveedoresSDA } from '../services/request/sda';

export function useProveedores() {
  const [proveedores, setProveedores] = useState([]);

  async function ProveedoresSDA() {
    try {
      const proveedores = await getProveedoresSDA();
      const optionsProveedor = proveedores
        .filter((value) => value.tipo_recurso === 'Vehículos')
        .map((value) => ({
          nombre: value.nombre,
          rut: value.rut,
          tipo_recurso: value.tipo_recurso,
          correo_transferencia: value.banco_email,
          numero_cuenta: value.banco_numero_cuenta,
          tipo_cuenta: value.banco_tipo_cuenta,
          banco: value.banco_nombre,
          cuenta_banco: {
            correo: value.banco_email,
            numero_cuenta: value.banco_numero_cuenta,
            tipo_cuenta: value.banco_tipo_cuenta,
            banco: value.banco_nombre,
            titular_cuenta: value.banco_titular_cuenta,
            rut_cuenta: value.banco_rut_cuenta,
          },
        }));
      return optionsProveedor ? optionsProveedor : [];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  useEffect(() => {
    ProveedoresSDA()
      .then((proveedores) => setProveedores(proveedores))
      .catch((error) => setProveedores([]));
  }, []);

  return { proveedores };
}
