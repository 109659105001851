import Axios from 'axios';
import { ADMINISTRACION_USUARIOS } from '../../constants/urls';

/**
 * Método encargado de obtener todas las personas.
 */
export async function ObtenerPersonas() {
	try {
		let url = `${ADMINISTRACION_USUARIOS}/personas`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener un usuario, por su ID.
 */
export async function ObtenerUsuario(usuarioID) {
	try {
		let url = `${ADMINISTRACION_USUARIOS}/personas/${usuarioID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}