import React, { useContext, useState } from 'react';
import MaterialTable from '../../../components/materialTable';
import { ReporteCostosActivosContext } from '.';
import { MainContext } from '../../../App';
import { EDITAR_VALORES_TARIFAS } from '../../../constants/permissions';
import DialogoVerTarifa from './dialogos/DialogoVerTarifa';

export default function TablaResultadoActivos() {
  const { permisos } = useContext(MainContext);
  const [openDialogo, setOpenDialogo] = useState(false);
  const [activoSeleccionado, setActivoSeleccionado] = useState(null);

  const { data, handleVerActivo, filters, isLoading } = useContext(ReporteCostosActivosContext);

  const actions = [
    {
      icon: 'chevron_right',
      tooltip: 'Ver Detalles',
      onClick: (evt, rowData) => handleVerActivo(rowData),
    },
    {
      icon: 'visibility',
      tooltip: 'Ver Detalle Tarifa de Gestión',
      onClick: (evt, rowData) => handleOpenDialogo(rowData),
      hidden: !(permisos && permisos[EDITAR_VALORES_TARIFAS]),
    },
  ];

  const columns = [
    {
      title: 'Código Activo',
      field: 'codigo_activo',
      defaultSort: 'desc',
    },
    {
      title: 'Código Contable',
      field: 'codigo_contable',
      hidden: true,
      export: true,
    },
    {
      title: 'Clase',
      field: 'clase.nombre',
    },
    {
      title: 'Condición',
      field: 'condicion_actual',
    },
    {
      title: 'Descripción',
      field: 'descripcion',
      cellStyle: {
        minWidth: '200px',
      },
    },
    {
      title: 'Número de Serie',
      field: 'serial_number',
    },
    {
      title: 'Marca',
      field: 'marca',
    },
    {
      title: 'Modelo',
      field: 'modelo.nombre',
      cellStyle: {
        minWidth: '200px',
      },
    },
    {
      title: 'Asignado a',
      field: '_asignado_ref',
      cellStyle: {
        minWidth: '150px',
      },
    },
    {
      title: 'Fecha Asignación',
      field: 'asignacion_info.fecha_creacion',
      type: 'date',
    },
    {
      title: 'Gerencia',
      field: '_gerencia_ref.nombre',
    },
    {
      title: 'Contrato',
      field: 'contrato',
      cellStyle: {
        minWidth: '200px',
      },
    },
    {
      title: 'Cod. Contrato',
      field: 'codigo_contrato',
    },
    {
      title: 'Propietario',
      field: 'sociedad',
      cellStyle: {
        minWidth: '200px',
      },
    },
    {
      title: 'Contrato Anterior',
      field: 'asignacion_info._contrato_ref.nombre',
      cellStyle: {
        minWidth: '200px',
      },
    },
    {
      title: 'Cod. Contrato',
      field: 'asignacion_info._contrato_ref.codigo',
    },
    {
      title: 'Precio de compra',
      field: 'valor',
      render: (row) =>
        new Intl.NumberFormat('es-CL', {
          style: 'currency',
          currency: 'CLP',
          minimumFractionDigits: 0,
        }).format(row.valor),
      cellStyle: {
        minWidth: '120px',
      },
    },
    {
      title: 'Tarifa de gestión',
      field: 'tarifa_gestion',
      render: (row) =>
        new Intl.NumberFormat('es-CL', {
          style: 'currency',
          currency: 'CLP',
          minimumFractionDigits: 0,
        }).format(row.tarifa_gestion),
      cellStyle: {
        minWidth: '120px',
      },
    },
    {
      title: 'Años del Activo',
      field: 'anio',
      render: (row) => {
        if (row.anio !== null && row.anio !== undefined) {
          return row.anio.toFixed(2);
        }
        // Si 'anio' es null o undefined, se devuelve 0 como valor por defecto
        return 0;
      },
    },
    {
      title: 'Valor Fijo',
      field: 'valor_fijo',
      export: true,
      render: (row) =>
        new Intl.NumberFormat('es-CL', {
          style: 'currency',
          currency: 'CLP',
          minimumFractionDigits: 0,
        }).format(row.valor_fijo),
    },
    {
      title: 'Valor Seguro',
      field: 'valor_seguro',
      hidden: true,
      export: true,
    },
    {
      title: 'Valor Promedio Venta',
      field: 'valor_promedio_venta',
      hidden: true,
      export: true,
    },
    {
      title: 'Valor Libro',
      field: 'valor_libro',
      hidden: true,
      export: true,
    },
    {
      title: 'Valor Permiso Circulación',
      field: 'valor_permiso_circulacion',
      hidden: true,
      export: true,
    },
    {
      title: 'Valor Inscripción',
      field: 'valor_inscripcion',
      hidden: true,
      export: true,
    },
    {
      title: 'Fecha de Registro',
      field: 'fecha_creacion',
      type: 'date',
    },
    {
      title: 'Fecha de Factura',
      field: 'fecha_factura',
      type: 'date',
    },
    {
      title: 'Tipo de Seguro (Vehículos)',
      field: 'tipo_seguro_vehiculo',
    },
  ];

  const handleOpenDialogo = (seleccionado) => {
    setActivoSeleccionado(seleccionado);
    setOpenDialogo(true);
  };

  return (
    <>
      <MaterialTable
        title="Costos por Activos"
        is_loading={isLoading}
        columns={columns}
        data={data}
        actions={actions}
        filters={filters}
        exportButtonPDF={false}
        lockheaders={true}
      />
      <DialogoVerTarifa open={openDialogo} setOpen={setOpenDialogo} activoSeleccionado={activoSeleccionado} />
    </>
  );
}
