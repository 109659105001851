import Axios from 'axios';
import { BACKEND } from '../../constants/urls';

/**
 * método encargado de obtener los vehículos.
 * @param {*} contrato ID del contrato.
 * @param {*} clases Arreglo con las clases de tipo vehículo.
 * @returns Arreglo con los vehículos de CyD en el contrato correspondiente.
 */
export async function ObtenerVehiculos(contrato, clases) {
  try {
    const clasesIDs = clases.map((c) => `_clase_ref_in=${c._id}`);
    const claseQuery = clasesIDs.join('&');
    const url = `${BACKEND}/bienes/codigo_activo/marca/modelo/_clase_ref.nombre/_asignado_ref.nombre/_asignado_ref.apellido_paterno/_asignado_ref.contacto/_asignado_ref.run/_vehiculo_ref.patente/_vehiculo_ref.kilometraje/_vehiculo_ref.color/_vehiculo_ref.transmision/_vehiculo_ref.ano/_vehiculo_ref.tipo_combustible/_contrato_ref.nombre/_contrato_ref.codigo/_contrato_ref.fecha_termino_estimada/_gerencia_ref.nombre/_gerencia_ref.sigla/_sociedad_ref.nombre/_sociedad_tercero_ref.sociedad/is_asignado/is_eliminado?is_eliminado_eq=false&${claseQuery}&_contrato_ref_eq=${contrato}`;
    const response = await Axios.get(url);
    return response.data ? response.data.data : [];
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * método encargado de obtener los vehículos.
 * @param {*} contrato ID del contrato.
 * @param {*} clases Arreglo con las clases de tipo vehículo.
 * @returns Arreglo con los vehículos de CyD en el contrato correspondiente.
 */
export async function ObtenerVehiculosTodos(clases) {
  try {
    const clasesIDs = clases.map((c) => `_clase_ref_in=${c._id}`);
    const claseQuery = clasesIDs.join('&');
    const url = `${BACKEND}/bienes/codigo_activo/marca/modelo/_clase_ref.nombre/_asignado_ref.nombre/_asignado_ref.apellido_paterno/_asignado_ref.contacto/_asignado_ref.run/_vehiculo_ref.patente/_vehiculo_ref.kilometraje/_vehiculo_ref.ano/_vehiculo_ref.color/_vehiculo_ref.transmision/_vehiculo_ref.tipo_combustible/_contrato_ref.nombre/_contrato_ref.codigo/_contrato_ref.fecha_termino_estimada/_gerencia_ref.nombre/_gerencia_ref.sigla/_sociedad_ref.nombre/_sociedad_tercero_ref.sociedad/is_asignado/is_eliminado?is_eliminado_eq=false&${claseQuery}`;
    const response = await Axios.get(url);
    return response.data ? response.data.data : [];
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function ObtenerVehiculosGerenciaContratos(filtro = '', clases) {
  try {
    const clasesIDs = clases.map((c) => `_clase_ref_in=${c._id}`);
    const claseQuery = clasesIDs.join('&');
    const url =
      `${BACKEND}/bienes/codigo_activo/marca/modelo/_clase_ref.nombre/_asignado_ref.nombre/_asignado_ref.apellido_paterno/_asignado_ref.contacto/_asignado_ref.run/_vehiculo_ref.patente/_vehiculo_ref.kilometraje/_vehiculo_ref.ano/_vehiculo_ref.color/_vehiculo_ref.transmision/_vehiculo_ref.tipo_combustible/_vehiculo_ref.numero_motor/_vehiculo_ref.numero_chasis/_vehiculo_ref.traccion/_contrato_ref.nombre/_contrato_ref.codigo/_contrato_ref.fecha_termino_estimada/_gerencia_ref.nombre/_gerencia_ref.sigla/_sociedad_ref.nombre/_sociedad_tercero_ref.sociedad/is_asignado/is_eliminado?is_eliminado_eq=false&${claseQuery}` +
      (filtro ? `&${filtro}` : '');

    const response = await Axios.get(url);
    return response.data ? response.data.data : [];
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export function postAddGPSVehiculo(id, data, cancelToken = null) {
  return Axios.post(`${BACKEND}/vehiculos/agregar-gps/${id}`, data, {
    cancelToken,
  }).then((res) => (res.data ? res.data.data : null));
}
export function putAddGPSVehiculoUpdate(id, data, cancelToken = null) {
  return Axios.put(`${BACKEND}/vehiculos/${id}/agregarEquipamientoGPS`, data, {
    cancelToken,
  }).then((res) => (res.data ? res.data.data : null));
}

export async function getBienesVehiculosGPSPopulado() {
  try {
    const url = `${BACKEND}/bienes/bienes-con-vehiculos-y-gps`;
    const response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getVehiculosGPSPopulado(id) {
  try {
    const url = `${BACKEND}/vehiculos/obtener-gps-populado/${id}`;
    const response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
