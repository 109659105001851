import axios from 'axios';
import { BACKEND } from '../../constants/urls'
/**
 * Obtener propietarios
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getPropietarios(cancelToken = null) {
  return axios.get(`${BACKEND}/usuarios-plataforma/`,
    {
      params: {
        "is_propietario_eq": true
      },
      cancelToken: cancelToken
    }).then(res => res.data ? res.data.data : null);
}
/**
 * Obtener datos de un propietario
 * @param {*} id 
 * @param {*} cancelToken token para cancelacion
 */
export function getPropietario(id, cancelToken = null) {
  return axios.get(`${BACKEND}/usuarios-plataforma/${id}`,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}