import { Button, Grid, makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';
import { ReporteCostosActivosContext } from '.';
import { MainContext } from '../../../App';
import MaterialReactSelect from '../../../components/materialReactSelect';
import { getContratos, getGerencias, getSociedades } from '../../../services/request/requestPortafolio';
import { getClases } from '../../../services/request/requestClase';
import { getTiposRecursos } from '../../../services/request/requestTipoRecurso';
import UpdateValorLibro from './updateValorLibro';

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: 'center',
  },
  btnMargin: {
    margin: theme.spacing(1),
  },
}));

export default function Filtros(props) {
  const classes = useStyles();
  const { setFilters, initialValues } = useContext(ReporteCostosActivosContext);
  const { data: sociedades } = useSWR('get:sociedades', (key) => getSociedades({}), {
    initialData: [],
  });
  const { data: tiposRecursos } = useSWR('get:tipos-recursos', (key) => getTiposRecursos({}), {
    initialData: [],
  });
  const { data: gerencias } = useSWR('get:gerencias', (key) => getGerencias({}), {
    initialData: [],
  });
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      const filter = {};
      if (values.gerencia) filter['_gerencia_ref'] = values.gerencia.value;
      if (values.contrato) filter['_contrato_ref'] = values.contrato.value;
      if (values.sociedad) filter['_sociedad_ref'] = values.sociedad.value;
      if (values.tipo) filter['_tipo_recurso_ref'] = values.tipo.value;
      if (values.clase) filter['_clase_ref'] = values.clase.value;
      setFilters(filter);
    },
  });
  const { handleSubmit, setFieldValue, values, errors, touched, resetForm } = formik;
  const [clases, setClases] = useState([]);
  const [contratos, setContratos] = useState([]);
  const { usuarioSesion } = useContext(MainContext);
  const [openDialogo, setOpenDialogo] = useState(false);

  const getContratosGerencia = async (gerencia) => {
    getContratos({ gerencia_ref: gerencia }).then((response) => {
      if (response.status === 200) {
        setContratos(response.data);
      } else if (response.status === 204) {
        setContratos([]);
      } else {
        setContratos([]);
      }
    });
  };

  const getClasesTipo = async (tipo) => {
    getClases({ _tipo_recurso_ref_eq: tipo }).then((response) => {
      if (response.status) {
        setClases(response.data);
      } else {
        setClases([]);
      }
    });
  };

  const resetFormikValues = useCallback(() => {
    resetForm(initialValues);
  }, [resetForm, initialValues]);

  useEffect(() => {
    resetFormikValues();
  }, [resetFormikValues]);

  useEffect(() => {
    if (usuarioSesion && usuarioSesion.gerencia && gerencias && gerencias.length > 0) {
      const gerencia = gerencias.find((a) => a._id === usuarioSesion.gerencia);
      if (gerencia) {
        setFieldValue('gerencia', {
          value: gerencia._id,
          label: gerencia.sigla + ' ' + gerencia.nombre,
          gerencia,
        });
        getContratos({ gerencia_ref: usuarioSesion.gerencia }).then((response) => {
          if (response.status === 200) {
            if (usuarioSesion.contrato) {
              const contrato = response.data.find((a) => a._id === usuarioSesion.contrato);
              if (contrato) {
                setFieldValue('contrato', {
                  value: contrato._id,
                  label: contrato.codigo + ' ' + contrato.nombre,
                  contrato,
                });
              }
            }
            setContratos(response.data);
          } else if (response.status === 204) {
            setContratos([]);
          } else {
            setContratos([]);
          }
        });
      }
      const filter = {};
      if (usuarioSesion.gerencia) filter['_gerencia_ref'] = usuarioSesion.gerencia;
      if (usuarioSesion.contrato) filter['_contrato_ref'] = usuarioSesion.contrato;

      setFilters(filter);
    }
  }, [usuarioSesion, gerencias, setFieldValue]);

  const handleOpenDialog = () => {
    setOpenDialogo(true);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <MaterialReactSelect
              value={values.gerencia}
              label="Gerencia"
              isClearable
              onChange={(newValue, actionMeta) => {
                if (newValue) {
                  setFieldValue('gerencia', newValue);
                  getContratosGerencia(newValue.value);
                } else setFieldValue('gerencia', '');
                setFieldValue('contrato', '');
              }}
              placeholder=""
              options={
                gerencias
                  ? gerencias.map((value, index) => ({
                      value: value._id,
                      label: value.sigla + ' ' + value.nombre,
                      gerencia: value,
                    }))
                  : []
              }
              noOptionsMessage={() => 'No sea ha entrado la gerencia'}
              error={touched.gerencia && errors.gerencia && Boolean(errors.gerencia)}
              helperText={touched.gerencia && errors.gerencia ? errors.gerencia : ''}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <MaterialReactSelect
              value={values.contrato}
              label="Contrato"
              isClearable
              onChange={(newValue, actionMeta) =>
                newValue ? setFieldValue('contrato', newValue) : setFieldValue('contrato', '')
              }
              placeholder=""
              options={contratos.map((value, index) => ({
                value: value._id,
                label: value.codigo + ' ' + value.nombre,
                contrato: value,
              }))}
              noOptionsMessage={() => 'No se ha entrado el contrato'}
              error={touched.contrato && errors.contrato && Boolean(errors.contrato)}
              helperText={touched.contrato && errors.contrato ? errors.contrato : ''}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <MaterialReactSelect
              value={values.sociedad}
              label="Sociedad"
              isClearable
              onChange={(newValue, actionMeta) =>
                newValue ? formik.setFieldValue('sociedad', newValue) : formik.setFieldValue('sociedad', '')
              }
              placeholder=""
              options={
                sociedades
                  ? sociedades.map((value, index) => ({
                      value: value._id,
                      label: value.nombre,
                      clase: value,
                    }))
                  : []
              }
              noOptionsMessage={() => 'No se ha entrado la sociedad'}
              error={touched.sociedad && errors.sociedad && Boolean(errors.sociedad)}
              helperText={touched.sociedad && errors.sociedad ? errors.sociedad : ''}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <MaterialReactSelect
              value={values.tipo}
              label="Tipo"
              isClearable
              onChange={(newValue, actionMeta) => {
                if (newValue) {
                  setFieldValue('tipo', newValue);
                  getClasesTipo(newValue.value);
                } else setFieldValue('tipo', '');
                setFieldValue('clase', '');
              }}
              placeholder=""
              options={
                tiposRecursos
                  ? tiposRecursos.map((value, index) => ({
                      value: value._id,
                      label: value.nombre,
                      tipo: value,
                    }))
                  : []
              }
              noOptionsMessage={() => 'No se ha entrado el tipo'}
              error={touched.tipo && errors.tipo && Boolean(errors.tipo)}
              helperText={touched.tipo && errors.tipo ? errors.tipo : ''}
            />
          </Grid>

          <Grid item lg={4} md={6} sm={6} xs={12}>
            <MaterialReactSelect
              value={values.clase}
              label="Clase"
              isClearable
              onChange={(newValue, actionMeta) =>
                newValue ? formik.setFieldValue('clase', newValue) : formik.setFieldValue('clase', '')
              }
              placeholder=""
              options={clases.map((value, index) => ({
                value: value._id,
                label: value.nombre,
                clase: value,
              }))}
              noOptionsMessage={() => 'No se ha entrado la clase'}
              error={touched.clase && errors.clase && Boolean(errors.clase)}
              helperText={touched.clase && errors.clase ? errors.clase : ''}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg md sm xs className={classes.center}>
            {/* <Button color="primary" variant="outlined" className={classes.btnMargin} onClick={() => formik.resetForm()}>Limpiar</Button> */}
            <Button type="submit" variant="contained" color="primary" className={classes.btnMargin}>
              Filtrar
            </Button>
            <Button color="primary" variant="contained" size="large" onClick={handleOpenDialog}>
              Subir planilla de Actualización Valor Libro
            </Button>
          </Grid>
        </Grid>
      </form>
      <UpdateValorLibro open={openDialogo} close={() => setOpenDialogo(false)} />
    </>
  );
}
