import React, {
  createContext,
  useCallback,
  useState
} from "react";
import { IconButton } from '@material-ui/core';
import {
  Close as CloseIcon,
  Check as CheckIcon
} from '@material-ui/icons';
import { useSnackbar } from "notistack";
import useSWR from "swr";
import { ActualizarUsuarioPlataforma, AgregarUsuarioPlataforma, EliminarUsuarioPlataforma, ObtenerUsuariosPlataforma } from "../../../services/request/requestUsuariosPlataforma";
import MaterialTable from "../../../components/materialTable";
import DialogoAgregarUsuario from './dialogoAgregarUsuario';

export const UsuariosPlataformaContext = createContext({
  usuario: {}, setUsuario: () => { },
  id: "", setId: () => { },
  isOpen: false, setIsOpen: () => { },
  tipoDialogo: "", setTipoDialogo: () => { },
  handleAceptar: () => { }
});

export default function TabUsuariosPlataforma() {
  const { data, mutate } = useSWR("get:usuariosPlataforma", (key) => ObtenerUsuariosPlataforma());
  const [usuario, setUsuario] = useState({
    nombre: "",
    rut: "",
    is_propietario: false,
    uid: "",
    is_ti: false,
    is_rff: false,
    is_contabilidad: false,
    is_gerente: false,
    is_responsable_gerencia: false,
    _perfiles_ref: [],
  });
  const [id, setId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [tipoDialogo, setTipoDialogo] = useState("");
  const notistack = useSnackbar();
  const handleAgregar = useCallback(() => {
    setIsOpen(true);
    setTipoDialogo("agregar");
  }, []);

  const handleActualizar = useCallback((usuario) => {
    setUsuario({
      nombre: usuario.nombre,
      rut: usuario.rut,
      is_propietario: usuario.is_propietario,
      uid: usuario.uid,
      is_ti: usuario.is_ti,
      is_rff: usuario.is_rff,
      is_contabilidad: usuario.is_contabilidad,
      is_gerente: usuario.is_gerente,
      is_responsable_gerencia: usuario.is_responsable_gerencia,
      _perfiles_ref: usuario._perfiles_ref,
    });
    setId(usuario._id);
    setIsOpen(true);
    setTipoDialogo("actualizar");
  }, []);

  const handleEliminar = useCallback((usuario) => {
    setUsuario({
      nombre: usuario.nombre,
      rut: usuario.rut,
      is_propietario: usuario.is_propietario,
      uid: usuario.uid,
      is_ti: usuario.is_ti,
      is_rff: usuario.is_rff,
      is_contabilidad: usuario.is_contabilidad,
      is_gerente: usuario.is_gerente,
      is_responsable_gerencia: usuario.is_responsable_gerencia,
      _perfiles_ref: usuario._perfiles_ref,
    });
    setIsOpen(true);
    setId(usuario._id);
    setTipoDialogo("eliminar");
  }, []);

  const handleAceptar = async (value, id) => {
    try {
      if (tipoDialogo === "agregar" && data.data.find(up => up.uid === value.uid)) {
        notistack.enqueueSnackbar("El usuario ya está agregado en el listado.", {
          variant: "info",
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: <IconButton onClick={() => notistack.closeSnackbar()}><CloseIcon /></IconButton>
        });
      } else {
        value._perfiles_ref = value._perfiles_ref.map(p => p._id);
        switch (tipoDialogo) {
          case "agregar":
            value._id = undefined;
            await AgregarUsuarioPlataforma(value);
            break;
          case "actualizar":
            await ActualizarUsuarioPlataforma(value, id);
            break;
          case "eliminar":
            await EliminarUsuarioPlataforma(id);
            break;
          default:
            throw new Error("Acción no especificada.")
        }
        notistack.enqueueSnackbar("Acción realizada exitosamente.", {
          variant: "success",
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: <IconButton onClick={() => notistack.closeSnackbar()}><CloseIcon /></IconButton>
        });
      }
    } catch (error) {
      console.error(error);
      notistack.enqueueSnackbar("Error al intentar realizar la acción.", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar()}><CloseIcon /></IconButton>
      });
    } finally {
      mutate();
      setUsuario({
        _id: "",
        nombre: "",
        rut: "",
        is_propietario: false,
        uid: "",
        is_ti: false,
        is_rff: false,
        is_contabilidad: false,
        is_gerente: false,
        is_responsable_gerencia: false,
        _perfiles_ref: [],
      });
      setIsOpen(false);
      setTipoDialogo("");
    }
  }

  let columns = [
    {
      title: "Nombre",
      field: "nombre",
      defaultSort: 'asc'
    }, {
      title: "RUT",
      field: "rut",
    }, {
      title: "Propietario",
      field: "is_propietario",
      hidden: true,
      render: (rowData) => rowData.is_propietario ? <CheckIcon /> : <CloseIcon />
    }, {
      title: "TI",
      field: "is_ti",
      hidden: true,
      render: (rowData) => rowData.is_ti ? <CheckIcon /> : <CloseIcon />
    }, {
      title: "RFF",
      field: "is_rff",
      hidden: true,
      render: (rowData) => rowData.is_rff ? <CheckIcon /> : <CloseIcon />
    }, {
      title: "Contabilidad",
      field: "is_contabilidad",
      hidden: true,
      render: (rowData) => rowData.is_contabilidad ? <CheckIcon /> : <CloseIcon />
    }, {
      title: "Gerente",
      field: "is_gerente",
      hidden: true,
      render: (rowData) => rowData.is_gerente ? <CheckIcon /> : <CloseIcon />
    }, {
      title: "Responsable Gerencia",
      field: "is_responsable_gerencia",
      hidden: true,
      render: (rowData) => rowData.is_responsable_gerencia ? <CheckIcon /> : <CloseIcon />
    }, {
      title: "Perfiles",
      field: "perfiles",
    }, {
      title: "UID",
      field: "uid",
    }
  ];

  const actions = [{
    tooltip: "Agregar",
    icon: "add",
    onClick: () => handleAgregar(),
    isFreeAction: true,
  },
  {
    tooltip: "Eliminar",
    icon: "delete",
    onClick: (event, row) => handleEliminar(row),
  },
  {
    tooltip: "Editar",
    icon: "edit",
    onClick: (event, row) => handleActualizar(row),
  }];

  return (
    <UsuariosPlataformaContext.Provider value={{
      id, setId,
      usuario, setUsuario,
      isOpen, setIsOpen,
      tipoDialogo, setTipoDialogo,
      handleAceptar
    }}>
      <MaterialTable
        title="Usuarios Plataforma"
        is_loading={!data}
        data={data && data.data}
        columns={columns}
        actions={actions}
        options={{          
          headerStyle: {
            fontWeight: 'bold',
            color: "#731f1f",
            textAlign: "left",
          },
        }}
      />
      <DialogoAgregarUsuario />
    </UsuariosPlataformaContext.Provider>
  );
}