import React, { Fragment } from 'react';
import MaterialTable from '../../../../components/materialTable';
import AgregarBien from '../agregar';
import FormularioAsignacion from '../formularioAsignacion';
import { Grid, Paper, TextField, Typography, Dialog, DialogContent, IconButton, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Close as CloseIcon } from '@material-ui/icons';
import { useHistory } from 'react-router';

function Page(props) {
  const {
    usuario_asignante,
    contrato_asignado,
    bienes,
    bien,
    is_open_1,
    is_open_2,
    handle_paso_1,
    handle_paso_2,
    handle_close,
  } = props;

  const history = useHistory();

  const columns = [
    {
      title: 'Código',
      field: 'codigo_activo',
    },
    {
      title: 'Marca',
      field: 'marca',
    },
    {
      title: 'Modelo',
      field: 'modelo',
    },
    {
      title: 'Condición Actual',
      field: 'condicion_actual',
    },
    {
      title: 'Clase',
      field: '_clase_ref.nombre',
    },
    {
      title: 'Asignado',
      field: '_asignado_ref',
      render: (rowData) =>
        rowData._asignado_ref
          ? rowData._asignado_ref.nombre +
            ' ' +
            rowData._asignado_ref.apellido_paterno +
            ' ' +
            rowData._asignado_ref.apellido_materno
          : '--',
    },
  ];

  const actions = [
    {
      icon: 'chevron_right',
      tooltip: 'Ver Activo',
      onClick: (event, row) => history.push(`/activos/${row._id}`),
    },
    {
      icon: 'add',
      tooltip: 'Agregar Activo',
      onClick: (event, row) => handle_paso_1(),
      isFreeAction: true,
    },
  ];

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Paper style={{ padding: 10, borderRadius: '15px' }}>
            <Typography variant="h5" style={{ paddingBottom: 10 }}>
              Información del Contrato
            </Typography>
            {!contrato_asignado && (
              <Grid container spacing={1}>
                {/* CARGANDO INFORMACIÓN DE USUARIO */}
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
              </Grid>
            )}
            {contrato_asignado && (
              <Grid container spacing={1}>
                {/* INFORMACIÓN DE USUARIO CARGADA */}
                <Grid item xs={6}>
                  <TextField
                    label="Código"
                    value={contrato_asignado.codigo}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Nombre"
                    value={contrato_asignado.nombre}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Nombre Responsable"
                    value={contrato_asignado.responsable_ref.persona.nombre_completo}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="RUN Responsable"
                    value={contrato_asignado.responsable_ref.persona.run_formateado}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Email Responsable"
                    value={contrato_asignado.responsable_ref.persona.contacto.email}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
        <Grid item xs={8}>
          {/* LISTADO DE BIENES ASIGNADOS */}
          <MaterialTable
            title="Activos Asignados"
            is_loading={!bienes}
            data={bienes}
            columns={columns}
            actions={actions}
          />
        </Grid>
      </Grid>
      {(is_open_1 || is_open_2) && (
        <Dialog open={is_open_1 || is_open_2} maxWidth="md" fullWidth>
          <Box display="flex">
            <Box p={1} pl={3} flexGrow={1}>
              <Typography variant="h6" style={{ margin: 0, padding: 0 }}>
                {is_open_1 && 'Busqueda de Activos'}
                {is_open_2 && 'Formulario de Asignación'}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handle_close}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <DialogContent dividers style={{ margin: 10 }}>
            {is_open_1 && (
              //POPUP CON VISTA PARA BUSCAR BIENES.
              <AgregarBien handle_paso_2={handle_paso_2} handle_close={handle_close} />
            )}
            {is_open_2 && (
              //POPUP CON VISTA PARA LLENAR DATOS DE FORMULARIO.
              <FormularioAsignacion
                usuario_asignante={usuario_asignante}
                bien={bien}
                asignado={contrato_asignado}
                nombre_asignado={contrato_asignado.nombre}
                tipo="contrato"
                handle_paso_1={handle_paso_1}
                handle_close={handle_close}
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </Fragment>
  );
}

export default Page;
