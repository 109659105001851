import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip
} from "@material-ui/core";
import MaterialTable from "@material-table/core";
import React, { useCallback, useEffect, useState } from "react";
import { ActualizarRol, ObtenerRol } from "../../../services/request/requestRoles";
import { ObtenerPerfiles } from "../../../services/request/requestPerfiles";
import { useHistory, useParams } from "react-router";
import { ArrowBack } from "@material-ui/icons";
import { AJUSTES_ADMINISTRACION } from '../../../constants/routes';
const columns = [
  {
    title: 'Rol',
    field: 'nombre',
    cellStyle:{
      textAlign: 'center'
    }
  },
  {
    title: 'Descripción',
    field: 'Descripcion'
  }
];

const localization = require('../../../data/material-table.location.json');


export default function ListadoPerfilesDeRol() {
  // const { rolSeleccionado, openDialogoPerfilesDeRol, setOpenDialogoPerfilesDeRol } = useContext(ListadoRolesContext);
  const history = useHistory();
  const { id } = useParams();
  const [rol, setRol] = useState(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [perfiles, setPerfiles] = useState([]);
  const [nuevoPerfil, setNuevoPerfil] = useState("");
  const cargarData = useCallback(async () => {
    setIsLoading(true);
    if (id) {
      const dataRol = await ObtenerRol(id, ["_perfiles_ref"], ["_perfiles_ref"]);
      const dataPerfiles = await ObtenerPerfiles();
      const listaPerfiles = [];
      if (dataPerfiles.data) {
        for (let i = 0; i < dataPerfiles.data.length; i++) {
          const dataPerfil = dataPerfiles.data[i];
          let agregar = true;
          for (let j = 0; j < dataRol._perfiles_ref.length; j++) {
            if (dataPerfil._id === dataRol._perfiles_ref[j]._id) {
              agregar = false;
              break;
            }
          }
          if (agregar) listaPerfiles.push(dataPerfil);
        }
      }
      setPerfiles(listaPerfiles);
      setData(dataRol._perfiles_ref);
      setRol(dataRol);
      setIsLoading(false);
    } else {
      setPerfiles([]);
      setData([]);
      setIsLoading(false);
      setRol(null);
    }
  }, [id]);

  const recargarData = async (id) => {
    setIsLoading(true);
    if (id) {
      const dataRol = await ObtenerRol(id, ["_perfiles_ref"], ["_perfiles_ref"]);
      const dataPerfiles = await ObtenerPerfiles();
      const listaPerfiles = [];
      if (dataPerfiles.data) {
        for (let i = 0; i < dataPerfiles.data.length; i++) {
          const dataPerfil = dataPerfiles.data[i];
          let agregar = true;
          for (let j = 0; j < dataRol._perfiles_ref.length; j++) {
            if (dataPerfil._id === dataRol._perfiles_ref[j]._id) {
              agregar = false;
              break;
            }
          }
          if (agregar) listaPerfiles.push(dataPerfil);
        }
      }
      setPerfiles(listaPerfiles);
      setData(dataRol._perfiles_ref);
      setRol(dataRol);
      setIsLoading(false);
    } else {
      setPerfiles([]);
      setData([]);
      setIsLoading(false);
      setRol(null);
    }
  };

  const handleAgregarPerfil = async (nuevo) => {
    const rol = await ObtenerRol(id);
    rol._perfiles_ref.push(nuevo);
    await ActualizarRol({ _id: rol._id, _perfiles_ref: rol._perfiles_ref });
    setNuevoPerfil("");
    recargarData(id);
  }
  const handleQuitarPerfil = async (rowData) => {
    const rol = await ObtenerRol(id);
    const _perfiles_ref = rol._perfiles_ref.filter((a) => a !== rowData._id);
    await ActualizarRol({ _id: rol._id, _perfiles_ref: _perfiles_ref });
    setNuevoPerfil("");
    recargarData(id);
  }
  const handleVolver = () => history.push(AJUSTES_ADMINISTRACION);


  const actions = [{
    tooltip: "Eliminar",
    icon: "delete",
    onClick: (event, row) => handleQuitarPerfil(row),
  }];



  useEffect(() => { cargarData() }, [cargarData]);

  return (
    <Container>
      <Card>
        <CardHeader
          title={`Rol de usuario: ${rol ? rol.nombre : ""}`}
          action={
            <Tooltip title="Volver">
              <IconButton onClick={handleVolver}>
                <ArrowBack></ArrowBack>
              </IconButton>
            </Tooltip>
          }
        />
        <CardContent>
          <form>

            <Grid container spacing={2}>
              <Grid item lg>
                <FormControl fullWidth>
                  <InputLabel>Lista de perfiles de usuario</InputLabel>
                  <Select
                    value={nuevoPerfil}
                    onChange={event => setNuevoPerfil(event.target.value)}>
                    <MenuItem value=""></MenuItem>
                    {perfiles.map((value, index) =>
                      <MenuItem value={value._id} key={index}>{value.nombre}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg>
                <Button style={{ marginTop: "16px" }} color="primary" variant="contained" onClick={() => handleAgregarPerfil(nuevoPerfil)}>Agregar Perfil</Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
      <br />
      <MaterialTable
        title={`Perfiles del rol de usuario`}
        isLoading={isLoading}
        data={data}
        columns={columns}
        actions={actions}
        options={{
          padding: "dense",
          exportButton: false,
          exportAllData: false,
          search: false,
          grouping: false,
          filtering: false,
          actionsColumnIndex: 0,
          headerStyle: {
            fontWeight: 'bold',
            color: "#731f1f",
            textAlign: "center",
          },
        }}
        localization={localization}
      />
    </Container>
  );
}