import React, { useContext } from 'react';
import { ReporteCostosActivosContext } from '.';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import formatNumber from 'format-number';
import CustomMiniCards from '../../../components/customMiniCards';
const numberFormater = formatNumber({ integerSeparator: '.', decimal: ',' });

export default function IndicadoresCostosActivos(props) {
  const { indicadores, isLoading } = useContext(ReporteCostosActivosContext);
  return (
    <>
      {isLoading && (
        <Grid container>
          <Grid item lg style={{ textAlign: 'center' }}>
            <CircularProgress size={60} />
          </Grid>
        </Grid>
      )}
      {!isLoading && (
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={4}>
            <CustomMiniCards title="Indicadores - Resumen de Costos por Activo">
              <Typography variant="h6">{`Cantidad: ${numberFormater(indicadores.cantidad)}`}</Typography>
              <br />
              <Typography variant="h6">{`Costos: $${numberFormater(indicadores.costoTotal)}`}</Typography>
            </CustomMiniCards>
          </Grid>
        </Grid>
      )}
    </>
  );
}
