import React, { useCallback, useContext, useEffect, useState } from "react";
import MaterialTable from "../../../components/materialTable";
import { useHistory } from "react-router";
import moment from "moment";
import { InformeMovimientosActivosContext } from ".";
import XLSX from 'xlsx';
import { getBienes } from "../../../services/request/requestBienes";

export default function TablaBienesInforme() {

  const { desde, hasta, contratosSeleccionados, gerenciasSeleccionados, clasesSeleccionados, tiposRecursosSeleccionados } = useContext(InformeMovimientosActivosContext);

  const history = useHistory();

  let columns = [
    {
      title: "Gerencia",
      field: "gerencia",
    },
    {
      title: "Cód. Contrato",
      field: "codigo_contrato",
    },
    {
      title: "Contrato",
      field: "contrato",
    },
    {
      title: "Tipo de Recurso",
      field: "tipo_recurso",
    },
    {
      title: "Código Activo",
      field: "codigo_activo",
    },
    {
      title: "Descripción",
      field: "descripcion"
    },
    {
      title: "Asignado A",
      field: "asignado",
    },
    {
      title: "Fecha de Registro",
      field: "fecha_creacion",
      type: 'date'
    }
  ];

  let actions = [
    {
      icon: "chevron_right",
      tooltip: "Ver Activo",
      onClick: (event, row) => history.push(`/activos/${row._bien_ref._id}`),
    },
  ];

  const handleExport = () => {
    const wb = XLSX.utils.book_new();
    const wbdata = data.map(value => ({
      "Gerencia": value.gerencia ? value.gerencia : "--",
      "Cód Contrato": value.codigo_contrato ? value.codigo_contrato : "--",
      "Contrato": value.contrato ? value.contrato : "--",
      "Tipo de Recurso": value.tipo_recurso ? value.tipo_recurso : "--",
      "Código Activo": value.codigo_activo,
      "Descripción": value.descripcion,
      "Asignado": value.asignado ? value.asignado : "--",
      "Fecha de Registro": moment(value.fecha_creacion).format("YYYY-MM-DD")
    }));
    let ws = XLSX.utils.json_to_sheet(wbdata);
    XLSX.utils.book_append_sheet(wb, ws, "Listado de Bienes");
    XLSX.writeFile(wb, "Listado de Bienes.xlsx");
  }

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  /**
   * Método encargado de obtener las solicitudes de asignación, por rango de fecha.
   * @param {*} desde Fecha desde para el filtrado.
   * @param {*} hasta Fecha hasta para el filtrado.
   * @returns Colección de solicitudes de asignación.
   */

  const loadData = useCallback(async () => {
    try {
      setData([]);
      setIsLoading(true);
      const filtro = {
        fecha_creacion_lt: hasta,
        fecha_creacion_gt: desde
      };
      if (contratosSeleccionados && contratosSeleccionados.length > 0) filtro["_contrato_ref_in"] = contratosSeleccionados;
      if (gerenciasSeleccionados && gerenciasSeleccionados.length > 0) filtro["_gerencia_ref_in"] = gerenciasSeleccionados;
      if (tiposRecursosSeleccionados && tiposRecursosSeleccionados.length > 0) filtro["_tipo_recurso_ref_in"] = tiposRecursosSeleccionados;
      if (clasesSeleccionados && clasesSeleccionados.length > 0) filtro["_clase_ref_in"] = clasesSeleccionados;

        const response = await getBienes(filtro, ["codigo_activo", "fecha_creacion", "descripcion"],
          [
            "_contrato_ref.nombre",
            "_gerencia_ref.sigla",
            "_contrato_ref.codigo",
            "_tipo_recurso_ref.nombre",
            "_asignado_ref.nombre", "_asignado_ref.apellido_paterno"
          ]
        );

      let bienes = [];
      if (response.status) {
        for (let i = 0; i < response.data.length; i++) {
          const value = response.data[i];
          bienes.push({
            codigo_activo: value.codigo_activo,
            fecha_creacion: value.fecha_creacion,
            descripcion: value.descripcion,
            contrato: value._contrato_ref ? value._contrato_ref.nombre : "--",
            codigo_contrato: value._contrato_ref ? value._contrato_ref.codigo : "--",
            gerencia: value._gerencia_ref ? value._gerencia_ref.sigla : "--",
            asignado: value._asignado_ref ? value._asignado_ref.nombre + " " + value._asignado_ref.apellido_paterno : "--",
            tipo_recurso: value._tipo_recurso_ref ? value._tipo_recurso_ref.nombre : "--"
          });
        }
      }

      setData(bienes);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      throw error;
    }
  }, [desde, hasta, gerenciasSeleccionados, contratosSeleccionados, clasesSeleccionados, tiposRecursosSeleccionados]);



  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <MaterialTable
      title="Listado de Activos"
      is_loading={isLoading}
      data={data}
      columns={columns}
      actions={actions}
      export_function={handleExport}
      exportButtonPDF={false}
    />
  );
}