import React, { createContext, useState } from 'react';
import MaterialTable from '../../../components/materialTable';
import useSWR from 'swr';
import { Fragment } from 'react';
import { useHistory } from 'react-router';
import { AJUSTES_CONFIGURACION } from '../../../constants/routes';
import {
  getUbicaciones,
  postUbicacion,
  putUbicacion,
  deleteUbicacion,
} from '../../../services/request/requestUbicaciones';
import DialogoCRUDUbicaciones from './dialogoCRUDUbicaciones';

export const TablaUbicacionesContext = createContext({
  openDialogoCRUD: false,
  setOpenDialogoCRUD: () => {},
  tituloDialogo: '',
  setTituloDialogo: () => {},
  initialValues: '',
  setInitialValues: () => {},
  handleAceptar: () => {},
  disableEditing: false,
  setDisableEditing: () => {},
});

function getData() {
  return getUbicaciones({}, ['ubicacion', '_id', 'descripcion', '_detalle_ubicacion_ref'], []).then((ubicacion) => {
    return ubicacion;
  });
}

export default function TablaUbicaciones() {
  const { data: ubicaciones, mutate } = useSWR('get:ubicaciones', (key) => getData(), { refreshInterval: 0 });
  const [openDialogoCRUD, setOpenDialogoCRUD] = useState(false);
  const [tituloDialogo, setTituloDialogo] = useState('');
  const [initialValues, setInitialValues] = useState({
    ubicacion: '',
    descripcion: '',
    _detalle_ubicacion_ref: [],
  });
  const [disableEditing, setDisableEditing] = useState(false);
  const history = useHistory();

  const handleOpenAgregar = (titulo) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      ubicacion: '',
      descripcion: '',
      _detalle_ubicacion_ref: [],
    });
    setDisableEditing(false);
  };

  const handleOpenModificar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      ubicacion: row.ubicacion,
      descripcion: row.descripcion,
      id: row._id,
      _detalle_ubicacion_ref: row._detalle_ubicacion_ref,
    });
    setDisableEditing(false);
  };

  const handleOpenQuitar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      ubicacion: row.ubicacion,
      descripcion: row.descripcion,
      id: row._id,
    });
    setDisableEditing(true);
  };

  const handleAceptar = async (values) => {
    if (tituloDialogo) {
      if (tituloDialogo.includes('Nuev')) {
        await postUbicacion({ ...values });
      } else if (tituloDialogo.includes('Modificar'))
        await putUbicacion(values.id, {
          ubicacion: values.ubicacion,
          descripcion: values.descripcion,
        });
      else if (tituloDialogo.includes('Eliminar')) await deleteUbicacion(values.id);
    }
    mutate();
    setOpenDialogoCRUD(false);
  };

  const handleVolver = () => {
    history.push(AJUSTES_CONFIGURACION);
  };

  const columnsMarcas = [
    {
      title: 'ID',
      field: '_id',
      align: 'center',
    },
    {
      title: 'Ubicación',
      field: 'ubicacion',
      align: 'center',
      defaultSort: 'asc',
    },
    {
      title: 'Descripción',
      field: 'descripcion',
      align: 'center',
    },
  ];

  return (
    <Fragment>
      <TablaUbicacionesContext.Provider
        value={{
          openDialogoCRUD,
          setOpenDialogoCRUD,
          tituloDialogo,
          setTituloDialogo,
          initialValues,
          setInitialValues,
          handleAceptar,
          disableEditing,
          setDisableEditing,
        }}
      >
        <MaterialTable
          actions={[
            {
              tooltip: 'Agregar Ubicación',
              icon: 'add',
              onClick: (event, row) => handleOpenAgregar('Nueva Ubicación'),
              isFreeAction: true,
            },
            {
              tooltip: 'Editar',
              icon: 'edit',
              onClick: (event, row) => handleOpenModificar('Modificar Ubicación', row),
            },
            {
              tooltip: 'Eliminar',
              icon: 'delete',
              onClick: (event, row) => handleOpenQuitar('Eliminar Ubicación', row),
            },
            {
              tooltip: 'Volver',
              icon: 'arrow_back',
              onClick: (event, row) => handleVolver(),
              isFreeAction: true,
            },
          ]}
          title="Ubicaciones Físicas"
          is_loading={!ubicaciones}
          data={ubicaciones && ubicaciones.data}
          columns={columnsMarcas}
          filtering={false}
          grouping={false}
          exportButtonPDF={true}
          exportButtonExcel={true}
          empty_rows={true}
          page_size={20}
        />
        <DialogoCRUDUbicaciones />
      </TablaUbicacionesContext.Provider>
    </Fragment>
  );
}
