import React, { createContext, useState } from 'react';
import MaterialTable from '../../../components/materialTable';
import useSWR from 'swr';
import {
  getMunicipalidades,
  postMunicipalidad,
  putMunicipalidad,
  deleteMunicipalidad,
} from '../../../services/request/requestMunicipalidades';
import { Fragment } from 'react';
import DialogoCRUDMunicipalidad from './dialogoCRUDMunicipalidad';
import { useHistory } from 'react-router';
import { AJUSTES_CONFIGURACION } from '../../../constants/routes';

export const TablaMunicipalidadesContext = createContext({
  openDialogoCRUD: false,
  setOpenDialogoCRUD: () => {},
  tituloDialogo: '',
  setTituloDialogo: () => {},
  initialValues: '',
  setInitialValues: () => {},
  handleAceptar: () => {},
  disableEditing: false,
  setDisableEditing: () => {},
});

export default function TablaMunicipalidades() {
  const { data: municipalidades, mutate } = useSWR('get:municipalidades', (key) => getMunicipalidades({}));
  const [openDialogoCRUD, setOpenDialogoCRUD] = useState(false);
  const [tituloDialogo, setTituloDialogo] = useState('');
  const [initialValues, setInitialValues] = useState({
    nombre: '',
    valor_patente: 0,
    valor_seguro: 0,
    valor_inscripcion: 0,
  });
  const [disableEditing, setDisableEditing] = useState(false);
  const history = useHistory();

  const handleOpenAgregar = (titulo) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      nombre: '',
      valor_promedio_venta: 0,
      valor_libro: 0,
      valor_patente: 0,
      valor_seguro: 0,
      valor_inscripcion: 0,
    });
    setDisableEditing(false);
  };

  const handleOpenModificar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      nombre: row.nombre,
      id: row._id,
      valor_promedio_venta: row.valor_promedio_venta,
      valor_libro: row.valor_libro,
      valor_patente: row.valor_patente,
      valor_seguro: row.valor_seguro,
      valor_permiso_circulacion: row.valor_permiso_circulacion,
      valor_inscripcion: row.valor_inscripcion,
    });
    setDisableEditing(false);
  };

  const handleOpenQuitar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      nombre: row.nombre,
      id: row._id,
      valor_promedio_venta: row.valor_promedio_venta,
      valor_libro: row.valor_libro,
      valor_patente: row.valor_patente,
      valor_seguro: row.valor_seguro,
      valor_permiso_circulacion: row.valor_permiso_circulacion,
      valor_inscripcion: row.valor_inscripcion,
    });
    setDisableEditing(true);
  };

  const handleAceptar = async (values) => {
    if (tituloDialogo) {
      if (tituloDialogo.includes('Nuev')) await postMunicipalidad({ ...values });
      else if (tituloDialogo.includes('Modificar'))
        await putMunicipalidad(values.id, {
          nombre: values.nombre,
        });
      else if (tituloDialogo.includes('Eliminar')) await deleteMunicipalidad(values.id);
    }
    mutate(getMunicipalidades());
    setOpenDialogoCRUD(false);
  };

  const handleVolver = () => {
    history.push(AJUSTES_CONFIGURACION);
  };

  const columnsMunicipalidades = [
    {
      title: 'Nombre',
      field: 'nombre',
      align: 'center',
      defaultSort: 'asc',
    },
  ];

  return (
    <Fragment>
      <TablaMunicipalidadesContext.Provider
        value={{
          openDialogoCRUD,
          setOpenDialogoCRUD,
          tituloDialogo,
          setTituloDialogo,
          initialValues,
          setInitialValues,
          handleAceptar,
          disableEditing,
          setDisableEditing,
        }}
      >
        <MaterialTable
          actions={[
            {
              tooltip: 'Agregar',
              icon: 'add',
              onClick: (event, row) => handleOpenAgregar('Nuevo Municipalidad'),
              isFreeAction: true,
            },
            {
              tooltip: 'Editar',
              icon: 'edit',
              onClick: (event, row) => handleOpenModificar('Modificar Municipalidad', row),
            },
            {
              tooltip: 'Eliminar',
              icon: 'delete',
              onClick: (event, row) => handleOpenQuitar('Eliminar Municipalidad', row),
            },
            {
              tooltip: 'Volver',
              icon: 'arrow_back',
              onClick: (event, row) => handleVolver(),
              isFreeAction: true,
            },
          ]}
          title="Municipalidades"
          is_loading={!municipalidades}
          data={municipalidades && municipalidades.data}
          columns={columnsMunicipalidades}
          filtering={false}
          grouping={false}
          searching={false}
          empty_rows={true}
          page_size={20}
        />
        <DialogoCRUDMunicipalidad />
      </TablaMunicipalidadesContext.Provider>
    </Fragment>
  );
}
