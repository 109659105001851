import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import TablaResultadoActivos from './tablaResultados';
import IndicadoresCostosActivos from './indicadoresCostosActivos';
import Filtros from './filtros';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1, 2, 0, 1),
    borderRadius: '15px',
  },
}));

export default function Page() {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h4" align="center">
          Listado de Costos por Activo
        </Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <Filtros />
          </Grid>
          <Grid item lg={6}>
            <IndicadoresCostosActivos />
          </Grid>
        </Grid>
      </Paper>
      <br />
      <TablaResultadoActivos />
    </>
  );
}
