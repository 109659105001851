import React, { createContext, useContext, useCallback, useState, useEffect } from 'react';
import Page from './page';
import { getBienes } from '../../../services/request/requestBienes';
import { useHistory } from 'react-router';
import * as ROUTES from '../../../constants/routes';
import { MainContext } from '../../../App';
import XLSX from 'xlsx';
import formatNumber from 'format-number';
import moment from 'moment';
import useSWR from 'swr';
import { getTiposRecursos } from '../../../services/request/requestTipoRecurso';
import { getAsignaciones } from '../../../services/request/requestSolicitudesAsignacion';
import useGerenciasUser from '../../../hooks/useGerenciasUser';

const numberFormater = formatNumber({ integerSeparator: '.', decimal: ',' });

export const ListadoActivosContext = createContext({
  data: [],
  setData: () => {},
  mostrarResultado: false,
  setMostrarResultado: () => {},
  triggerSearch: () => {},
  setTriggerSearch: () => {},
  handleExport: () => {},
  handleVerActivo: () => {},
  filter: {},
  setFilter: () => {},
  isLoading: true,
  tiposRecursos: [],
  gerencias: [],
});

export default function ListadoBienes(props) {
  const [mostrarResultado, setMostrarResultado] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [data, setData] = useState([]);
  const [OpenSeleccionados, SetOpenSeleccionados] = useState(false);
  const [OpenSeleccionadosBaja, SetOpenSeleccionadosBaja] = useState(false);
  const { gerencias } = useGerenciasUser(true);

  const { data: tiposRecursos } = useSWR('get:tipos-recursos', (key) => getTiposRecursos({}), { refreshInterval: 0 });
  const { data: asignaciones } = useSWR(
    'get:solicitudes-asignacion',
    (key) =>
      getAsignaciones(
        {},
        ['estado', 'fecha_creacion', 'tipo', '_bien_ref', '_origen_contrato_ref', '_origen_gerencia_ref'],
        ['_origen_contrato_ref.nombre', '_origen_contrato_ref.codigo'],
      ),
    { refreshInterval: 0 },
  );

  const triggerSearch = useCallback(
    async (filter) => {
      if (tiposRecursos && asignaciones) {
        setIsLoading(true);
        setMostrarResultado(true);
        setData([]);
        const response = await getBienes(
          filter,
          [
            'codigo_activo',
            'codigo_contable',
            'estado_activacion',
            'condicion_actual',
            'ubicacion',
            'detalle_ubicacion',
            'serial_number',
            'marca',
            'modelo',
            'is_activo_contable',
            'activo',
            'descripcion',
            'fecha_activacion',
            'fecha_baja',
            'is_no_activable',
            'is_eliminado',
            'contrato_leasing',
          ],
          [
            '_gerencia_ref.nombre',
            '_contrato_ref.nombre',
            '_contrato_ref.codigo',
            '_contrato_ref.fecha_termino_estimada',
            '_asignado_ref.nombre',
            '_asignado_ref.apellido_paterno',
            '_asignado_ref.contacto',
            '_asignado_ref.run',
            '_sociedad_tercero_ref.sociedad',
            '_clase_ref.nombre',
            '_clase_ref._tipo_recurso_ref',
            '_vehiculo_ref.patente',
            '_vehiculo_ref.ano',
            '_vehiculo_ref.kilometraje',
            '_vehiculo_ref.fecha_kilometraje',
            '_vehiculo_ref.transmision',
            '_vehiculo_ref.color',
            '_vehiculo_ref.numero_chasis',
            '_vehiculo_ref.numero_motor',
            '_sociedad_ref.nombre',
          ],
        );
        const bienes = response.data;
        if (bienes) {
          const dataBienes = [];
          bienes.forEach((a) => {
            let tipoRecurso = null;
            if (tiposRecursos && a._clase_ref && a._clase_ref._tipo_recurso_ref) {
              tipoRecurso = tiposRecursos.find((b) => b._id === a._clase_ref._tipo_recurso_ref);
            }

            let fechaTerminoContrato =
              a._contrato_ref && a._contrato_ref.fecha_termino_estimada
                ? moment(a._contrato_ref.fecha_termino_estimada).format('DD/MM/YYYY')
                : '--';
            if (fechaTerminoContrato === '01/01/2100') fechaTerminoContrato = 'Indefinido';

            let fechaAsignacion = '--';
            let contratoOrigen = null;
            if (asignaciones) {
              let asigBien = asignaciones.filter((asig) => asig._bien_ref === a._id);
              asigBien.sort((a, b) => {
                if (moment(a.fecha_creacion).isAfter(moment(b.fecha_creacion))) return -1;
                if (moment(b.fecha_creacion).isAfter(moment(a.fecha_creacion))) return 1;
                return 0;
              });
              fechaAsignacion =
                asigBien[0] && asigBien[0].fecha_creacion
                  ? moment(asigBien[0].fecha_creacion).format('DD/MM/YYYY')
                  : '--';
              contratoOrigen =
                asigBien[0] && asigBien[0]?._origen_contrato_ref
                  ? {
                      nombre: asigBien[0]?._origen_contrato_ref.nombre,
                      codigo: asigBien[0]?._origen_contrato_ref.codigo,
                    }
                  : null;
            }
            const dataBien = {
              checked: false,
              _id: a._id,
              is_no_activable: a.is_no_activable,
              is_activo_contable: a.is_activo_contable,
              codigo_activo: a.codigo_activo,
              codigo_contable: a.codigo_contable,
              estado_activacion: a.estado_activacion,
              _clase_ref: a._clase_ref && a._clase_ref.nombre ? a._clase_ref.nombre : '--',
              condicion_actual: a?.condicion_actual ? a?.condicion_actual : '--',
              ubicacion: a?.ubicacion ? a?.ubicacion : '--',
              detalle_ubicacion: a?.detalle_ubicacion ? a?.detalle_ubicacion : '',
              contrato_leasing: a?.contrato_leasing ? a?.contrato_leasing : '',
              serial_number: a.serial_number,
              marca: a?.marca ? a?.marca : '--',
              modelo: a?.modelo ? a.modelo : '--',
              descripcion: a.descripcion,
              _asignado_ref:
                a._asignado_ref && a._asignado_ref.nombre
                  ? a._asignado_ref.nombre + ' ' + a._asignado_ref.apellido_paterno
                  : '--',
              mail_usuario: a._asignado_ref && a._asignado_ref.contacto ? a._asignado_ref.contacto.email : '--',
              rut_usuario: a._asignado_ref ? a._asignado_ref.run : '--',
              fecha_asignacion: fechaAsignacion,
              _gerencia_ref: a._gerencia_ref && a._gerencia_ref.nombre ? a._gerencia_ref.nombre : '--',
              gerencia_id: a._gerencia_ref && a._gerencia_ref.nombre ? a._gerencia_ref._id : '--',
              _contrato_ref: a._contrato_ref && a._contrato_ref.nombre ? a._contrato_ref.nombre : '--',
              codigo_contrato: a._contrato_ref && a._contrato_ref.codigo ? a._contrato_ref.codigo : '--',
              fecha_termino_contrato: fechaTerminoContrato,
              contrato_id: a._contrato_ref && a._contrato_ref.nombre ? a._contrato_ref._id : '--',
              contrato_anterior: contratoOrigen ? contratoOrigen?.nombre : '--',
              contrato_anterior_codigo: contratoOrigen ? contratoOrigen?.codigo : '--',
              valor: numberFormater(a.activo ? a.activo.valor : 0),
              patente: a._vehiculo_ref && a._vehiculo_ref.patente ? a._vehiculo_ref.patente : '--',
              color: a?._vehiculo_ref ? a._vehiculo_ref?.color : ' ',
              numero_chasis: a._vehiculo_ref ? a._vehiculo_ref.numero_chasis : '',
              numero_motor: a._vehiculo_ref ? a._vehiculo_ref.numero_motor : '',
              ano: a._vehiculo_ref && a._vehiculo_ref.ano ? moment(a._vehiculo_ref.ano).utc().format('YYYY') : '--',
              kilometraje: a._vehiculo_ref && a._vehiculo_ref.kilometraje ? a._vehiculo_ref.kilometraje : '--',
              fecha_kilometraje:
                a._vehiculo_ref && a._vehiculo_ref.fecha_kilometraje
                  ? moment(a._vehiculo_ref.fecha_kilometraje).format('DD/MM/YYYY')
                  : '--',
              fecha_activacion: a.fecha_activacion,
              fecha_baja: a.fecha_baja,
              transmision: a._vehiculo_ref && a._vehiculo_ref.transmision ? a._vehiculo_ref.transmision : '--',
              proveedor:
                a.activo && a.activo.factura && a.activo.factura.proveedor ? a.activo.factura.proveedor.nombre : '--',
              numero_factura: a.activo && a.activo.factura ? a.activo.factura.numero : '--',
              fecha_factura: a.activo && a.activo.factura ? moment(a.activo.factura.fecha).format('DD/MM/YYYY') : '--',
              sociedad: a._sociedad_ref
                ? a._sociedad_ref.nombre
                : a._sociedad_tercero_ref
                  ? a._sociedad_tercero_ref.sociedad
                  : '--',
              tipo_recurso: tipoRecurso ? tipoRecurso.nombre : ' ',
              is_eliminado: a.is_eliminado,
            };
            if (filter.asignado) {
              if (filter.asignado === 'Asignado a Persona' && a._asignado_ref && a._contrato_ref)
                dataBienes.push(dataBien);
              if (filter.asignado === 'Asignado a Contrato' && a._contrato_ref && !a._asignado_ref)
                dataBienes.push(dataBien);
              if (filter.asignado === 'Sin asignar' && !a._contrato_ref) dataBienes.push(dataBien);
            } else {
              dataBienes.push(dataBien);
            }
          });
          setData(dataBienes);
          setIsLoading(false);
        }
      }
    },
    [tiposRecursos, asignaciones],
  );

  const handleExport = (columns) => {
    const wb = XLSX.utils.book_new();
    const wbdata = data.map((a) => {
      const row = {};
      for (let c in columns) {
        const col = columns[c];
        if (col.title === 'Selección') continue;
        if (col.hidden) continue;
        row[col.title] = col.field && a[col.field] ? a[col.field] : '';
        if (col.title === 'Activado') row[col.title] = a.is_activo_contable;
      }
      return row;
    });
    let ws = XLSX.utils.json_to_sheet(wbdata);
    XLSX.utils.book_append_sheet(wb, ws, 'Activos');
    XLSX.writeFile(wb, 'activos.xlsx');
  };

  const handleVerActivo = (rowData) => {
    history.push(ROUTES.VER_ACTIVO.replace(':id', rowData._id));
  };

  return (
    <ListadoActivosContext.Provider
      value={{
        data,
        setData,
        handleExport,
        handleVerActivo,
        triggerSearch,
        setMostrarResultado,
        mostrarResultado,
        isLoading,
        OpenSeleccionados,
        SetOpenSeleccionados,
        OpenSeleccionadosBaja,
        SetOpenSeleccionadosBaja,
        tiposRecursos,
        gerencias,
      }}
    >
      <Page />
    </ListadoActivosContext.Provider>
  );
}
