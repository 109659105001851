import Axios from "axios";
import { BACKEND } from "../constants/urls";

/**
 * Método encargado de generar un nuevo registro LOG para un activo.
 * @param {*} data Objeto con los datos del LOG (título, cuerpo, tipo e icono opcional).
 */
export async function GenerarLog(data) {
	try {
		let url = `${BACKEND}/activo-logs`;
		let response = await Axios.post(url, data);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}