import React, { useState } from 'react';
// import { makeStyles } from '@material-ui/styles';
import MaterialTable from '@material-table/core';
import Moment from 'moment';
import useSWR from 'swr';
import { getAsignaciones } from '../../../services/request/requestSolicitudesAsignacion';
const localization = require('../../../data/material-table.location.json');

export default function TablaAsignaciones(props) {
  const { id } = props;
  const { data } = useSWR(
    'get:asignaciones',
    (key) =>
      getAsignaciones(
        { _bien_ref_eq: id },
        ['fecha_creacion', 'estado'],
        [
          '_usuario_asignante_ref.nombre',
          '_usuario_asignante_ref.apellido_paterno',
          '_usuario_asignante_ref.nombre_completo',
          '_asignado_ref.nombre',
          '_asignado_ref.apellido_paterno',
          '_asignado_ref.nombre_completo',
          '_contrato_ref.codigo',
          '_contrato_ref.nombre',
        ],
      ),
    { refreshInterval: 0 },
  );
  const [pageSize, setPageSize] = useState(10);
  return (
    <MaterialTable
      title="Asignaciones"
      options={{
        filtering: false,
        pageSizeOptions: [5, 10, 20, 50],
        pageSize,
        grouping: false,
        columnsButton: true,
        maxBodyHeight: '70vh',
        headerStyle: {
          backgroundColor: '#731f1f',
          color: '#ffffff',
          textAlign: 'center',
          position: 'sticky',
        },
        rowStyle: {
          border: '1px solid #E0E0E0',
        },
        padding: 'dense',
        actionsCellStyle: {
          margin: '0 auto',
          display: 'flex',
          flexGrow: '1',
          justifyContent: 'center',
          border: '1px solid #ffffff',
          padding: '6px 24px 6px 21px',
        },
        cellStyle: {
          border: '1px solid #E0E0E0',
        },
      }}
      onChangeRowsPerPage={(size) => {
        setPageSize(size);
      }}
      localization={localization}
      data={data?.reverse()}
      columns={[
        {
          title: 'Fecha Asignación',
          field: 'fecha_creacion',
          render: (rowData) => {
            return rowData.fecha_creacion ? Moment(rowData.fecha_creacion).format('DD/MM/YYYY') : '--';
          },
        },
        {
          title: 'Asignado por',
          field: '_usuario_asignante',
          render: (rowData) =>
            rowData._usuario_asignante_ref
              ? rowData._usuario_asignante_ref.nombre + ' ' + rowData._usuario_asignante_ref.apellido_paterno
              : '--',
        },
        {
          title: 'Asignado a',
          field: '_asignado_ref',
          render: (rowData) =>
            rowData._asignado_ref ? rowData._asignado_ref.nombre + ' ' + rowData._asignado_ref.apellido_paterno : '--',
        },
        {
          title: 'Código Contrato',
          field: '_contrato_ref',
          render: (rowData) => (rowData._contrato_ref ? rowData._contrato_ref.codigo : '--'),
        },
        {
          title: 'Contrato',
          field: '_contrato_ref',
          render: (rowData) => (rowData._contrato_ref ? rowData._contrato_ref.nombre : '--'),
        },
        {
          title: 'Estado',
          field: 'estado',
        },
      ]}
    />
  );
}
