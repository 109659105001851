import React, { Fragment, useEffect, useState } from 'react';
import Moment from 'moment';
import {
  Backdrop,
  Grid,
  TextField,
  DialogActions,
  Button,
  Typography,
  GridListTile,
  GridListTileBar,
  CircularProgress,
  Dialog,
  GridList,
  DialogTitle,
  DialogContent,
  FormControl,
  FormHelperText,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { useFormik } from 'formik';
import { object, mixed } from 'yup';
import { useSnackbar } from 'notistack';
import TitleCase from 'titlecase';
import XLSX from 'xlsx';
import useSWR from 'swr';
import { AceptarAsignacion, RechazarAsignacion } from '../../../services/request/requestSolicitudesAsignacion';
import { putBien } from '../../../services/request/requestBienes';
import MaterialTable from '../../../components/materialTable';
import FileInput from '../../../components/fileInput';
import { uploadFileToCloudStorage } from '../../../services/fileUpload';
import { GenerarLog } from '../../../services/activoLog';
import { getClases } from '../../../services/request/requestClase';
import { getContratos, getGerencias } from '../../../services/request/requestPortafolio';

function MisAsignaciones(props) {
  const { asignaciones, usuario_id, load_data } = props;

  const [IsOpen, SetIsOpen] = useState(false);
  const [Seleccionado, SetSeleccionado] = useState();

  const { data: dataClases, error: errorClases } = useSWR('clases', () => getClases(), { revalidateOnFocus: false });
  const { data: dataContratos, error: errorContratos } = useSWR('contratos', () => getContratos(), {
    revalidateOnFocus: false,
  });
  const { data: dataGerencias, error: errorGerencias } = useSWR('gerencias', () => getGerencias(), {
    revalidateOnFocus: false,
  });

  const notistack = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    if (Seleccionado) {
      SetIsOpen(true);
    }
  }, [Seleccionado]);

  useEffect(() => {
    if (errorClases || errorContratos || errorGerencias) {
      notistack.enqueueSnackbar('Error al intentar cargar algunos datos.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar()}>
            <Close />
          </IconButton>
        ),
      });
    }
  }, [errorClases, errorContratos, errorGerencias]);

  let initials = Seleccionado ? Seleccionado : null;
  let validations = object().shape({
    solicitud: object().nullable(),
    file: mixed().optional(),
  });
  const formik = useFormik({
    initialValues: initials,
    validationSchema: validations,
    onSubmit: (values, helper) => handleSubmit(values),
    enableReinitialize: true,
  });

  const { resetForm } = formik;

  /**
   * Handler para cancelar rechazar o aceptar la asignación.
   */
  const handleCancel = () => {
    formik.values.file = null;
    SetSeleccionado();
    SetIsOpen(false);
    resetForm();
  };

  /**
   * Handler para aceptar o rechazar la asignación.
   * @param {*} values
   */
  const handleSubmit = async (values) => {
    try {
      // if (values.action === "accept" && !values.file) {
      //   notistack.enqueueSnackbar("Debe incluir una Foto de Después al aceptar un activo.", {
      //     variant: "info",
      //     anchorOrigin: {
      //       horizontal: "center",
      //       vertical: "bottom"
      //     },
      //     action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
      //   });
      //   return;
      // }

      switch (values.action) {
        case 'accept':
          values.formulario_estado.foto_despues = null;
          //Asignación del archivo subido.
          if (values.file) {
            const uploadedFile = await uploadFileToCloudStorage(
              values.file,
              `solicitudes/${values._id}`,
              values.file.name,
            );
            values.formulario_estado.foto_despues = uploadedFile;
          }
          let registroLogAceptar = {
            _bien_ref: values._bien_ref._id,
            titulo: 'Solicitud de Asignación',
            cuerpo: `La solicitud de asignación del activo ${values._bien_ref.codigo_activo} fue aceptada.`,
            tipo: 'solicitud_asignacion',
            icon: 'thumb_up',
          };
          let actualizacionBien = {
            _asignado_ref: values.tipo === 'persona' ? usuario_id : null,
            _gerencia_ref: values._gerencia_ref._id,
            _contrato_ref: values._contrato_ref ? values._contrato_ref._id : null,
            condicion_actual: values.formulario_estado.estado,
            foto_actual: values.formulario_estado.foto_despues ? values.formulario_estado.foto_despues.url : undefined,
          };
          await Promise.all([
            AceptarAsignacion(values._id, values.formulario_estado.foto_despues),
            putBien(values._bien_ref._id, actualizacionBien),
            GenerarLog(registroLogAceptar),
          ]);
          notistack.enqueueSnackbar('Solicitud de asignación aceptada exitosamente.', {
            variant: 'success',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            action: (
              <IconButton onClick={() => notistack.closeSnackbar()}>
                <Close />
              </IconButton>
            ),
          });
          break;
        case 'reject':
          let registroLogRechazar = {
            _bien_ref: values._bien_ref._id,
            titulo: 'Solicitud de Asignación',
            cuerpo: `La solicitud de asignación del activo ${values._bien_ref.codigo_activo} fue rechazada.`,
            tipo: 'solicitud_asignacion',
            icon: 'thumb_down',
          };
          await Promise.all([
            RechazarAsignacion(values._id),
            putBien(values._bien_ref._id, { _asignado_ref: null, is_asignado: false }),
            GenerarLog(registroLogRechazar),
          ]);
          notistack.enqueueSnackbar('Solicitud de asignación rechazada exitosamente.', {
            variant: 'info',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            action: (
              <IconButton onClick={() => notistack.closeSnackbar()}>
                <Close />
              </IconButton>
            ),
          });
          break;
        default:
          throw new Error('Error, acción no especificada.');
      }
    } catch (error) {
      console.error(error);
      notistack.enqueueSnackbar('Error al intentar aceptar la solicitud.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar()}>
            <Close />
          </IconButton>
        ),
      });
    } finally {
      load_data();
      SetIsOpen(false);
      resetForm();
    }
  };

  /**
   * Handler para exportar las asignaciones pendientes.
   */
  const handleExport = () => {
    const wb = XLSX.utils.book_new();
    const wbdata = asignaciones.map((a) => {
      let gerencia = dataGerencias && dataGerencias.find((d) => d._id === a._bien_ref._gerencia_ref);
      let contrato = dataContratos && dataContratos.data.find((d) => d._id === a._bien_ref._contrato_ref);
      return {
        'Asignante por': a.asignado_por,
        'Código Activo': a._bien_ref.codigo_activo,
        Tipo: a.tipo,
        'Condición Actual': a._bien_ref.codigo_activo,
        Clase: dataClases ? dataClases.data.find((d) => d._id === a._bien_ref._clase_ref).nombre : '---',
        'Número Serie': a._bien_ref.numero_serie,
        Marca: a._bien_ref.marca,
        Modelo: a._bien_ref.modelo,
        'Gerencia Origen': gerencia ? gerencia.nombre : '---',
        'Contrato Origen': contrato ? contrato.nombre : '---',
        'Fecha Asignación': a.fecha_creacion,
      };
    });
    let ws = XLSX.utils.json_to_sheet(wbdata);
    XLSX.utils.book_append_sheet(wb, ws, 'Asignaciones');
    XLSX.writeFile(wb, 'asignaciones.xlsx');
  };

  const fileChange = (name, e) => {
    formik.setFieldValue(name, e.target.files[0]);
    formik.setFieldTouched(name, true, false);
  };

  const multiSubmitAction = (action) => {
    formik.setFieldValue('action', action);
    formik.submitForm();
  };

  let columns = [
    {
      title: 'Asignado por',
      field: 'asignado_por',
    },
    {
      title: 'Asignado a',
      field: 'asignado_a',
      hidden: true,
    },
    {
      title: 'Código Activo',
      field: '_bien_ref.codigo_activo',
    },
    {
      title: 'Tipo',
      field: 'tipo',
      hidden: true,
    },
    {
      title: 'Condición Actual',
      field: '_bien_ref.condicion_actual',
    },
    {
      title: 'Clase',
      field: '_bien_ref._clase_ref',
      render: (row) => (dataClases ? dataClases.data.find((d) => d._id === row._bien_ref._clase_ref).nombre : '---'),
    },
    {
      title: 'N° Serie',
      field: '_bien_ref.numero_serie',
      hidden: true,
      // hiddenByColumnsButton: true,
    },
    {
      title: 'Marca',
      field: '_bien_ref.marca',
    },
    {
      title: 'Modelo',
      field: '_bien_ref.modelo',
    },
    {
      title: 'Gerencia Origen',
      field: '_bien_ref._gerencia_ref',
      render: (row) =>
        dataGerencias ? dataGerencias.find((d) => d._id === row._bien_ref._gerencia_ref).nombre : '---',
      hidden: true,
      // hiddenByColumnsButton: true,
    },
    {
      title: 'Contrato Origen',
      field: '_bien_ref._contrato_ref',
      render: (row) => {
        if (dataContratos && dataContratos.data && row._bien_ref._contrato_ref) {
          let contrato = dataContratos.data.find((d) => d._id === row._bien_ref._contrato_ref);
          return contrato ? contrato.nombre : '---';
        }
      },
      hidden: true,
      // hiddenByColumnsButton: true,
    },
    {
      title: 'Fecha Asignación',
      field: 'fecha_creacion',
      render: (row) => Moment(row.fecha_creacion).format('DD/MM/YYYY HH:mm'),
    },
  ];

  let actions = [
    {
      icon: 'chevron_right',
      tooltip: 'Aceptar | Rechazar',
      onClick: (event, row) => SetSeleccionado(row),
    },
  ];

  return (
    <Fragment>
      {/* LISTADO DE ASIGNACIONES */}
      <MaterialTable
        title="Solicitudes de Asignación"
        is_loading={!asignaciones}
        data={asignaciones}
        columns={columns}
        actions={actions}
        export_function={handleExport}
      />
      {/* POPUP ACEPTAR RECHAZAR CANCELAR */}
      {IsOpen && (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Dialog open={IsOpen} maxWidth="md" fullWidth>
            <DialogTitle>Información del Activo</DialogTitle>
            <DialogContent dividers style={{ height: 230 }}>
              {formik.values && (
                <Grid container spacing={2}>
                  <Grid item xs={4} xl={4}>
                    {/* IMAGEN ANTES */}
                    <GridList>
                      <GridListTile cols={2}>
                        <img
                          src={
                            formik.values.formulario_estado.foto_antes
                              ? formik.values.formulario_estado.foto_antes.url
                              : ''
                          }
                          alt={
                            formik.values.formulario_estado.foto_antes
                              ? formik.values.formulario_estado.foto_antes.nombre
                              : 'No Disponible'
                          }
                        />
                        <GridListTileBar
                          title={
                            formik.values.formulario_estado.foto_antes
                              ? formik.values.formulario_estado.foto_antes.nombre
                              : 'No Disponible'
                          }
                          subtitle="Foto de Antes"
                        />
                      </GridListTile>
                    </GridList>
                  </Grid>
                  <Grid item container xs={8} xl={8} spacing={2}>
                    <Grid item xs={6} xl={6}>
                      {/* USUARIO ASIGNANTE */}
                      <TextField
                        label="Asignado por"
                        value={formik.values.asignado_por}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={6} xl={6}>
                      {/* CÓDIGO ACTIVO */}
                      <TextField
                        label="Código de Activo"
                        value={formik.values._bien_ref.codigo_activo}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={6} xl={6}>
                      {/* MARCA ACTIVO */}
                      <TextField
                        label="Marca"
                        value={formik.values._bien_ref.marca}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={6} xl={6}>
                      {/* MODELO ACTIVO */}
                      <TextField
                        label="Modelo"
                        value={formik.values._bien_ref.modelo}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={6} xl={6}>
                      {/* CONDICIÓN ACTUAL */}
                      <TextField
                        label="Condición Actual"
                        value={TitleCase(formik.values.formulario_estado.estado)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={6} xl={6}>
                      {/* CAMPO PARA SUBIR IMAGES DE DESPUÉS */}
                      <FormControl
                        fullWidth
                        error={
                          formik.touched.formulario_estado &&
                          formik.touched.formulario_estado.foto_despues &&
                          formik.errors.formulario_estado &&
                          formik.errors.formulario_estado.foto_despues &&
                          Boolean(formik.errors.formulario_estado.foto_despues)
                        }
                      >
                        <FileInput
                          inputName="file"
                          meta={{ touched: formik.touched.file, errors: formik.errors.file }}
                          buttonColor="primary"
                          buttonVariant="outlined"
                          label="Foto de Después"
                          value={formik.values.file}
                          handleOnChange={fileChange.bind(null, 'file')}
                          textVariant="outlined"
                          size="small"
                          accept="image/x-png,image/gif,image/jpeg"
                        />
                        <FormHelperText error>{formik.errors.file ? formik.errors.file : ''}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel} color="primary" autoFocus>
                Cancelar
              </Button>
              <Button onClick={() => multiSubmitAction('reject')} color="secondary" variant="contained">
                Rechazar
              </Button>
              <Button onClick={() => multiSubmitAction('accept')} color="primary" variant="contained">
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      )}
      {/* FONDO DE CARGA */}
      <Backdrop className={classes.backdrop} open={formik.isSubmitting}>
        <Typography variant="h6">Procesando la solicitud de asignación...</Typography>
        <CircularProgress style={{ marginLeft: 10 }} />
      </Backdrop>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff',
  },
}));

export default MisAsignaciones;
