import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Checkbox,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  FormLabel,
  InputAdornment,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import moment from 'moment';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(1, 1, 3, 1),
  },
  center: {
    textAlign: 'center',
  },
  main: {
    overflow: 'auto',
    maxWidth: '95vw',
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  tableHeader: {
    textAlign: 'center',
    border: '1px solid #E0E0E0',
    fontWeight: 'bold',
    color: '#731f1f',
    position: 'sticky',
  },
  tableCell: {
    border: '1px solid #E0E0E0',
  },
  btnMargin: {
    margin: theme.spacing(1),
  },
  fileInput: {
    top: 'unset',
    left: 'unset',
    position: 'inherit',
  },
}));

export default function PageFormularioBasicoBienes(props) {
  const { colores, formik, handleVolver } = props;
  const { errors, handleChange, touched, values, handleSubmit, setFieldValue } = formik;
  const classes = useStyles();

  const dateChange = (name, e) => {
    formik.setFieldValue(name, e);
    formik.setFieldTouched(name, true, false);
  };
  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Typography variant="h5">Formulario de Vehículos</Typography>
      <Grid container spacing={1}>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <CurrencyTextField
            label="Kilometraje"
            value={values.kilometraje}
            onChange={(event, value) => setFieldValue('kilometraje', value)}
            error={touched.kilometraje && errors.kilometraje && Boolean(errors.kilometraje)}
            helperText={touched.kilometraje && errors.kilometraje ? errors.kilometraje : ''}
            fullWidth
            variant="outlined"
            margin={'normal'}
            currencySymbol="Km"
            minimumValue="0"
            maximumValue="1000000000"
            outputFormat="string"
            decimalCharacter=","
            digitGroupSeparator="."
            leading="deny"
            decimalPlaces={0}
            decimalPlacesShownOnBlur={0}
            decimalPlacesShownOnFocus={0}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <TextField
            label="N° Motor"
            fullWidth
            variant={'outlined'}
            margin={'normal'}
            name="numero_motor"
            value={values.numero_motor}
            onChange={handleChange}
            error={touched.numero_motor && errors.numero_motor && Boolean(errors.numero_motor)}
            helperText={touched.numero_motor && errors.numero_motor ? errors.numero_motor : ''}
          />
        </Grid>

        <Grid item lg={3} md={6} sm={12} xs={12}>
          <TextField
            label="N° Chasis"
            fullWidth
            variant={'outlined'}
            margin={'normal'}
            name="numero_chasis"
            value={values.numero_chasis}
            onChange={handleChange}
            error={touched.numero_chasis && errors.numero_chasis && Boolean(errors.numero_chasis)}
            helperText={touched.numero_chasis && errors.numero_chasis ? errors.numero_chasis : ''}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <FormControl
            variant="outlined"
            margin="normal"
            fullWidth
            error={touched.color && errors.color && Boolean(errors.color)}
          >
            <InputLabel>Color</InputLabel>
            <Select name="color" value={values.color} onChange={handleChange}>
              <MenuItem value="">Seleccionar</MenuItem>
              {colores &&
                colores.map((value, index) => (
                  <MenuItem value={value.value} key={index}>
                    {value.label}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>{touched.color && errors.color ? errors.color : ''}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <DatePicker
              name="ano"
              autoOk
              fullWidth
              InputLabelProps={{
                classes: {
                  shrink: 'shrink',
                },
              }}
              views={['year']}
              margin="normal"
              inputVariant="outlined"
              label="Año"
              format="yyyy"
              helperText={touched.ano ? errors.ano : ''}
              error={touched.ano && Boolean(errors.ano)}
              onChange={dateChange.bind(null, 'ano')}
              value={values.ano}
              maxDate={moment().startOf('year').add(1, 'years').toDate()}
              minDate={moment().startOf('year').subtract(20, 'years').toDate()}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <FormControl
            variant="outlined"
            margin="normal"
            fullWidth
            error={touched.tipo_combustible && errors.tipo_combustible && Boolean(errors.tipo_combustible)}
          >
            <InputLabel>Tipo Combustible</InputLabel>
            <Select name="tipo_combustible" value={values.tipo_combustible} onChange={handleChange}>
              <MenuItem value="Diesel">Diesel</MenuItem>
              <MenuItem value="Gasolina">Gasolina</MenuItem>
              <MenuItem value="Dual (Eléctrico/Gasolina)">Dual (Eléctrico/Gasolina)</MenuItem>
            </Select>
            <FormHelperText>
              {touched.tipo_combustible && errors.tipo_combustible ? errors.tipo_combustible : ''}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <FormControl
            variant="outlined"
            margin="normal"
            fullWidth
            error={touched.transmision && errors.transmision && Boolean(errors.transmision)}
          >
            <InputLabel>Tracción</InputLabel>
            <Select name="transmision" value={values.transmision} onChange={handleChange}>
              <MenuItem value="4x2">4x2</MenuItem>
              <MenuItem value="4x4">4x4</MenuItem>
            </Select>
            <FormHelperText>{touched.transmision && errors.transmision ? errors.transmision : ''}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item lg={3} md={12} sm={12} xs={12}>
          <TextField
            label="Cilindrada"
            fullWidth
            variant={'outlined'}
            margin={'normal'}
            name="cilindrada"
            value={values.cilindrada}
            onChange={handleChange}
            error={touched.cilindrada && errors.cilindrada && Boolean(errors.cilindrada)}
            helperText={touched.cilindrada && errors.cilindrada ? errors.cilindrada : ''}
            InputProps={{
              endAdornment: <InputAdornment position="start">cc</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item lg={3} md={12} sm={12} xs={12}>
          <TextField
            label="Patente"
            fullWidth
            variant={'outlined'}
            margin={'normal'}
            name="patente"
            value={values.patente}
            onChange={handleChange}
            error={touched.patente && errors.patente && Boolean(errors.patente)}
            helperText={touched.patente && errors.patente ? errors.patente : ''}
          />
        </Grid>
        {/* <Grid item lg={3} md={12} sm={12} xs={12}>
          <TextField
            label="Tag"
            fullWidth
            variant={'outlined'}
            margin={'normal'}
            name="tag"
            value={values.tag}
            onChange={handleChange}
            error={touched.tag && errors.tag && Boolean(errors.tag)}
            helperText={touched.tag && errors.tag ? errors.tag : ''}
          />
        </Grid> */}
      </Grid>
      <Grid container spacing={1}>
        {/* <Grid item lg={6} md={12} sm={12} xs={12}>
          <FormControl>
            <FormLabel>El vehículo incluye</FormLabel>
            <FormControlLabel
              color={'primary'}
              control={
                <Checkbox
                  checked={values.gps}
                  color="primary"
                  onChange={(e) => {
                    formik.setFieldValue('gps', e.target.checked);
                  }}
                />
              }
              label="GPS"
            />
            <FormControlLabel
              color={'primary'}
              control={
                <Checkbox
                  checked={values.mobileye}
                  color="primary"
                  onChange={(e) => {
                    formik.setFieldValue('mobileye', e.target.checked);
                  }}
                />
              }
              label="MobilEye"
            />
            <FormControlLabel
              color={'primary'}
              control={
                <Checkbox
                  checked={values.barra_antivuelco}
                  color="primary"
                  onChange={(e) => {
                    formik.setFieldValue('barra_antivuelco', e.target.checked);
                  }}
                />
              }
              label="Barra Antivuelco"
            />
          </FormControl>
        </Grid> */}
      </Grid>

      <Grid container alignContent="center" justify="center">
        <Grid item lg md sm xs className={classes.center}>
          <Button variant="outlined" className={classes.btnMargin} onClick={handleVolver}>
            Volver
          </Button>
          <Button type="submit" color="primary" variant="contained" className={classes.btnMargin}>
            Siguiente
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
