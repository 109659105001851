export const EMAILS_LIBERACION_LICENCIAS = [
  'soporte@cydingenieria.cl',
  'nperezv@cydingenieria.cl',
  'btobar@cydingenieria.cl',
];
export const EMAILS_REASIGNACION_LICENCIAS_ACTIVO = [
  'soporte@cydingenieria.cl',
  'compras@cydingenieria.cl',
  'logistica@cydingenieria.cl',
];

export function TITULO_EMAIL_LIBERACION_LICENCIAS(dato) {
  return `Liberación de Licencia en Activo: ${dato}`;
}

export function TITULO_EMAIL_REASIGNACION_LICENCIAS_ACTIVO(dato) {
  return `Gestión de Licencias en Activo: ${dato}`;
}

export function CONTENIDO_EMAIL_LIBERACION_LICENCIAS(activo, detalle_licencias) {
  const htmlContent = `
        <div>
            <h2>Liberación de Licencias de Software</h2>
            <p></p>
            <p>El Activo, <b>Código: ${
              activo || '---'
            }</b>, ha sido movilizado a <b>GAF-8</b>, por lo que las siguientes licencias de software que poseía asociadas, fueron liberadas automáticamente por el sistema.</p>
            <p></p>						  
            <p></p>
            <h3>Detalle de Licencias liberadas:</h3>
            ${detalle_licencias}						  						  
            <br/>
            <p>        Atentamente,</p>						  
            <p></p>
            <p>Sistema Gestor de Activos</p>
            <p></p>						  
            <p></p>
            <p>Nota: no responda a este correo, es generado automáticamente.</p>						  						  						  
        </div>
        `;
  return htmlContent;
}

export function CONTENIDO_EMAIL_REASIGNACION_LICENCIAS_ACTIVO(
  activo,
  contrato = '',
  detalle_licencias_quitadas = '',
  detalle_licencias_mantenidas = '',
  usuario_asignante = '',
  usuario_final = '',
) {
  const mensaje_inicial = `
        <div>
            <h2>Gestión de Licencias de Software por reasignación de Activo:</h2>
            <p></p>
            <p> Estimados, </p>
            <p>El Activo, <b>Código: ${activo || '---'}</b>, ha sido movilizado al contrato <b>${
              contrato || '---'
            }</b>, por el usuario: ${usuario_asignante || '---'}, asignándolo al colaborador: ${
              usuario_final || '---'
            }. Donde se realizaron las siguientes operaciones en las licencias que el activo poseía asignadas: </p>
            <p></p>            
        `;

  const mensaje_licencias_eliminadas = `                    
            <h3>Detalle de Licencias liberadas:</h3>
            ${detalle_licencias_quitadas}						  						  
            <br/>            						  						  						          
        `;
  const mensaje_licencias_mantenidas = `                    
        <h3>Detalle de Licencias Mantenidas:</h3>
        ${detalle_licencias_mantenidas}						  						  
        <br/>            						  						  						          
    `;

  const mensaje_despedida = `                    
            <p>        Atentamente,</p>						  
            <p></p>
            <p>Sistema Gestor de Activos</p>
            <p></p>						  
            <p></p>
            <p>Nota: no responda a este correo, es generado automáticamente.</p>						  						  						  
        </div>           						  						  						          
    `;

  const htmlContent =
    mensaje_inicial +
    (detalle_licencias_quitadas.length > 0 ? mensaje_licencias_eliminadas : ' ') +
    (detalle_licencias_mantenidas.length > 0 ? mensaje_licencias_mantenidas : ' ') +
    mensaje_despedida;

  return htmlContent;
}

export function CONTENIDO_EMAIL_REASIGNACION_LICENCIAS_USUARIO_FINAL(
  activo,
  contrato = '',
  detalle_licencias_mantenidas = '',
  usuario_asignante = '',
  usuario_final = '',
) {
  const mensaje_inicial = `
        <div>
            <h2>Gestión de Licencias de Software por reasignación de Activo:</h2>
            <p></p>
            <p> Estimado, </p>
            <p>El Activo, <b>Código: ${activo || '---'}</b>, le ha sido reasignado a usted en el contrato <b>${
              contrato || '---'
            }</b>, por el usuario: ${usuario_asignante || '---'}. Y que posee las siguientes licencias asignadas: </p>
            <p></p>            
        `;

  const mensaje_licencias_mantenidas = `                    
        <h3>Detalle de Licencias de Software:</h3>
        ${detalle_licencias_mantenidas}						  						  
        <br/>            						  						  						          
    `;

  const mensaje_despedida = ` 
            <p>Si desea la instalación de un software adicional, por favor realizar solicitud de recursos en Sistema de Adquisiciones, indicando: nombre de software, version y código del Activo. </p>                   
            <p></p>
            <p>        Atentamente,</p>						  
            <p></p>
            <p>Sistema Gestor de Activos</p>
            <p></p>						  
            <p></p>
            <p>Nota: no responda a este correo, es generado automáticamente.</p>						  						  						  
        </div>           						  						  						          
    `;

  const htmlContent =
    mensaje_inicial +
    (detalle_licencias_mantenidas.length > 0 ? mensaje_licencias_mantenidas : ' ') +
    mensaje_despedida;

  return htmlContent;
}
