import {
  Dialog,
  Grid,
  TextField,
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { VerBienContext } from '.';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import MaterialReactSelect from '../../../components/materialReactSelect';
import { putBien } from '../../../services/request/requestBienes';
import { GenerarLog } from '../../../services/activoLog';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { MainContext } from '../../../App';
import { VER_CONDICION_VENTA } from '../../../constants/permissions';

export default function DialogoCondicionActual() {
  const {
    id,
    bien,
    condiciones_actuales,
    openDialogoCondicionActual,
    setOpenDialogoCondicionActual,
    handleCloseDialogoCondicionActual,
    SetIsLoading,
  } = useContext(VerBienContext);

  const { usuarioSesion, permisos } = useContext(MainContext);
  const notistack = useSnackbar();
  const [CondicionActual, SetCondicionActual] = useState();
  const [Comentario, SetComentario] = useState();
  const [optionCondiciones, setOptionCondiciones] = useState([]);

  const handleActualizarCondición = async (condicion, comentario) => {
    try {
      SetIsLoading(true);
      let registroLog = {
        _bien_ref: id,
        titulo: 'Actualización de Condición Actual',
        cuerpo: `Se actualizó la condición actual del activo ${bien.codigo_activo} a ${condicion} por el usuario: ${
          usuarioSesion.nombre
        }.${comentario ? ` Se incluye comentario "${comentario}".` : ''}`,
        tipo: 'actualizacion',
        icon: 'edit',
      };
      await Promise.all([
        putBien(id, { condicion_actual: condicion, comentario_condicion_actual: comentario }),
        GenerarLog(registroLog),
      ]);
      const successkey = notistack.enqueueSnackbar('Condición del activo actualizada exitosamente.', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
            <Close />
          </IconButton>
        ),
      });
    } catch (error) {
      console.error(error);
      const errorkey = notistack.enqueueSnackbar('Error al actualizar la condición del activo.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar(errorkey)}>
            <Close />
          </IconButton>
        ),
      });
    } finally {
      SetIsLoading(false);
      setOpenDialogoCondicionActual(false);
    }
  };

  const handleOptionConditions = useCallback(() => {
    let options = [];

    if (condiciones_actuales && condiciones_actuales.length > 0) {
      options = condiciones_actuales.filter((condicion) => condicion.value !== 'Para venta');

      if (permisos[VER_CONDICION_VENTA])
        options.push(condiciones_actuales.find((condicion) => condicion.value === 'Para venta'));
    }
    setOptionCondiciones(options);
  }, [condiciones_actuales]);

  useEffect(() => {
    handleOptionConditions();
  }, [handleOptionConditions]);

  useEffect(() => {
    SetCondicionActual(bien.condicion_actual);
    SetComentario(bien.comentario_condicion_actual);
  }, [bien]);

  return (
    <Dialog open={openDialogoCondicionActual} fullWidth>
      <DialogTitle>Actualización de la Condición Actual</DialogTitle>
      <DialogContent dividers>
        <Typography>Ingrese una condición y comentario</Typography>
        <Grid container spacing={2} style={{ marginTop: 5, marginBottom: 5 }}>
          <Grid item xs>
            <MaterialReactSelect
              value={condiciones_actuales && condiciones_actuales.find((d) => d.label === CondicionActual)}
              label="Condición Actual"
              isClearable
              onChange={(value) => SetCondicionActual(value.label)}
              placeholder="Condición Actual"
              options={optionCondiciones}
              noOptionsMessage={() => 'No ha entrado la Condición Actual'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Comentario Condición Actual"
              value={Comentario}
              onChange={(event) => SetComentario(event.target.value)}
              fullWidth
              variant={'outlined'}
              rows={2}
              multiline={true}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCloseDialogoCondicionActual} color="primary" variant="outlined">
          Cancelar
        </Button>
        <Button
          autoFocus
          onClick={() => handleActualizarCondición(CondicionActual, Comentario)}
          disabled={CheckButtonDisabled(Comentario)}
          color="primary"
          variant="contained"
        >
          Actualizar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * Método encargado de verificar si el botón está habilitado o deshabilitado.
 * @param {*} motivo Motivo para actualizar la Condición Actual del bien.
 * @returns FLAG que habilita o deshabilita el botón.
 */
function CheckButtonDisabled(motivo) {
  if (!motivo) {
    return true;
  }
  if (motivo.length < 4) {
    return true;
  }
  return false;
}
