import React from 'react';
import MaterialTable from '../../../components/materialTable';
import useSWR from 'swr';
import { Fragment } from 'react';
import { useHistory } from 'react-router';
import { AJUSTES_CONFIGURACION } from '../../../constants/routes';
import { getVersionesSoftware } from '../../../services/request/requestVersionesSoftware';

function getData() {
  return getVersionesSoftware(
    {},
    ['version', '_id'],
    ['_software_ref.software', '_software_ref._id', '_marca_ref._id', '_marca_ref.marca'],
  ).then((software) => {
    return software;
  });
}

export default function TablaGruposContables() {
  const { data: grupos } = useSWR('get:versiones-software', (key) => getData(), { refreshInterval: 0 });
  const history = useHistory();

  const handleVolver = () => {
    history.push(AJUSTES_CONFIGURACION);
  };

  const columnsModelos = [
    {
      title: 'Marca',
      field: '_marca_ref.marca',
      align: 'center',
      defaultSort: 'asc',
    },
    {
      title: 'Version',
      field: 'version',
      align: 'center',
    },
    {
      title: 'Agrupación',
      field: '_software_ref.software',
      align: 'center',
      defaultSort: 'asc',
    },
    {
      title: 'Descripción',
      field: '_marca_ref.descripcion',
      align: 'center',
    },
  ];

  return (
    <Fragment>
      <MaterialTable
        actions={[
          {
            tooltip: 'Volver',
            icon: 'arrow_back',
            onClick: (event, row) => handleVolver(),
            isFreeAction: true,
          },
        ]}
        title="Agrupación de Softwares"
        is_loading={!grupos}
        data={grupos && grupos.data}
        columns={columnsModelos}
        filtering={false}
        grouping={false}
        exportButtonPDF={true}
        exportButtonExcel={true}
        empty_rows={true}
        page_size={20}
      />
    </Fragment>
  );
}
