import React, { Fragment } from 'react';
import { Grid, Paper, TextField, Typography } from '@material-ui/core';
import MaterialTable from '../../../components/materialTable';
import { Skeleton } from '@material-ui/lab';

function Page(props) {
  const { personas, bien } = props;

  const columns = [
    {
      title: 'Nombre',
      field: 'nombre_completo',
    },
    {
      title: 'Gerencia',
      render: (rowData) => (rowData._gerencia_ref ? rowData._gerencia_ref.sigla : '--'),
    },
    {
      title: 'Contrato',
      field: 'contrato',
    },
  ];

  const actions = [
    {
      icon: 'add',
      tooltip: 'Asignar',
    },
  ];

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Paper style={{ padding: 10, borderRadius: '15px' }}>
            <Typography variant="h5" style={{ paddingBottom: 10 }}>
              Información del Activo
            </Typography>
            {!bien && (
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
              </Grid>
            )}
            {bien && (
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    label="Codigo Contable"
                    value={bien.codigo_contable}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Codigo Activo"
                    value={bien.codigo_activo}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Marca"
                    value={bien.marca}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Modelo"
                    value={bien.modelo}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Estado"
                    value={bien.estado}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Condición Actual"
                    value={bien.condicion_actual}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <MaterialTable
            title="Listado de Personas"
            is_loading={!personas}
            columns={columns}
            data={personas}
            actions={actions}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default Page;
