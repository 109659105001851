import { Box, Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { InformeMovimientosActivosContext } from '.';

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: "15px",
    margin: theme.spacing(1, 0, 1, 0)
  },
  container:{
    maxWidth: "100%"
  }
}));


export default function IndicadoresMovimientoActivos() {

  const { indicadores} = useContext(InformeMovimientosActivosContext);

  const classes = useStyles();

  // totalActivos: 0,
  // registroContableAprobados: 0,
  // registroContablePendiente: 0,
  // noActivable: 0

  return (
    <Paper className={classes.paper}>
      <Container className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={3} xl={3}>
            <Paper elevation={4} style={{ borderRadius: 15 }}>
              <Box display="flex" justifyContent="center">
                <Typography variant="h6">Total Activos</Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Typography variant="h4">{indicadores.totalActivos}</Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={3} xl={3}>
            <Paper elevation={4} style={{ borderRadius: 15 }}>
              <Box display="flex" justifyContent="center">
                <Typography variant="h6">Registro Contable Aprobado</Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Typography variant="h4">{indicadores.registroContableAprobados}</Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={3} xl={3}>
            <Paper elevation={4} style={{ borderRadius: 15 }}>
              <Box display="flex" justifyContent="center">
                <Typography variant="h6">Registro Contable Pendiente</Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Typography variant="h4">{indicadores.registroContablePendiente}</Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={3} xl={3}>
            <Paper elevation={4} style={{ borderRadius: 15 }}>
              <Box display="flex" justifyContent="center">
                <Typography variant="h6">No Activable</Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Typography variant="h4">{indicadores.noActivable}</Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );

}