import React, { useCallback, useContext, useEffect, useState } from "react";
import MaterialTable from "../../../components/materialTable";
import { useHistory } from "react-router";
import Moment from "moment";
import { ObtenerSolicitudesAsignacionRango } from "../../../services/request/requestSolicitudesAsignacion";
import { getSociedades } from "../../../services/request/requestPortafolio";
import { MovimientoMensualContext } from ".";
import XLSX from 'xlsx';
import toTitleCase from "titlecase";

export default function TablaAsigMovimientoMensual() {

  const { desde, hasta } = useContext(MovimientoMensualContext);

  const history = useHistory();

  let columns = [
    {
      title: "Asignado Por",
      field: "asignado_por",
    }, {
      title: "Asignado A",
      field: "asignado_a",
    }, {
      title: "Código Activo",
      field: "_bien_ref.codigo_activo",
    }, {
      title: "Proveedor",
      field: "_bien_ref.activo.factura.proveedor.nombre",
    }, {
      title: "N° de Factura",
      field: "_bien_ref.activo.factura.numero",
    }, {
      title: "Fecha Factura",
      field: "fecha_factura",
      type: 'date'
    }, {
      title: "Sociedad",
      field: "sociedad",
    }, {
      title: "Nombre Gerencia Origen",
      field: "gerencia_origen_nombre",
    }, {
      title: "Nombre Contrato Origen",
      field: "contrato_origen_nombre",
    }, {
      title: "Código Contrato Origen",
      field: "contrato_origen_codigo",
    }, {
      title: "Nombre Gerencia Destino",
      field: "gerencia_destino_nombre",
    }, {
      title: "Nombre Contrato Destino",
      field: "contrato_destino_nombre",
    }, {
      title: "Código Contrato Destino",
      field: "contrato_destino_codigo",
    }, {
      title: "Tipo",
      field: "tipo",
    }, {
      title: "Estado",
      field: "estado",
    }, {
      title: "Fecha Asignación",
      field: "fecha_creacion",
      type: 'date'
    },
  ];

  let actions = [
    {
      icon: "chevron_right",
      tooltip: "Ver Activo",
      onClick: (event, row) => history.push(`/activos/${row._bien_ref._id}`),
    },
  ];

  const handleExport = () => {
    const wb = XLSX.utils.book_new();
    const wbdata = solicitudes.map(s => ({
      "Asignado Por": s.asignado_por,
      "Asignado A": s.asignado_a,
      "Código Activo": s._bien_ref.codigo_activo,
      "Proveedor": s._bien_ref.activo.factura && s._bien_ref.activo.factura.proveedor ? s._bien_ref.activo.factura.proveedor.nombre : "",
      "N° de Factura": s._bien_ref.activo.factura ? s._bien_ref.activo.factura.numero : "",
      "Fecha Factura": s.fecha_factura,
      "Sociedad": s.sociedad,
      "Nombre Gerencia Origen": s.gerencia_origen_nombre,
      "Nombre Contrato Origen": s.contrato_origen_nombre,
      "Código Contrato Origen": s.contrato_origen_codigo,
      "Nombre Gerencia Destino": s.gerencia_destino_nombre,
      "Nombre Contrato Destino": s.contrato_destino_nombre,
      "Código Contrato Destino": s.contrato_destino_codigo,
      "Tipo": s.tipo,
      "Estado": s.estado,
      "Fecha Asignación": s.fecha_creacion,
    }));
    let ws = XLSX.utils.json_to_sheet(wbdata);
    XLSX.utils.book_append_sheet(wb, ws, "Movimiento Mensual");
    XLSX.writeFile(wb, "movimiento_mensual.xlsx");
  }

  const [solicitudes, setSolicitudes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

/**
 * Método encargado de obtener las solicitudes de asignación, por rango de fecha.
 * @param {*} desde Fecha desde para el filtrado.
 * @param {*} hasta Fecha hasta para el filtrado.
 * @returns Colección de solicitudes de asignación.
 */

  const AsignacionesPorRango = useCallback(async () => {
    try {
      setSolicitudes([]);
      setIsLoading(true);
      let responses = await Promise.all([
        ObtenerSolicitudesAsignacionRango(desde, hasta),
        getSociedades(),
      ]);
      let asignaciones = responses[0].data;
      let sociedades = responses[1];

      asignaciones.forEach((solicitud, index_1) => {
        let asignante = solicitud._usuario_asignante_ref;
        asignaciones[index_1]["asignado_por"] = asignante ? `${asignante.nombre} ${asignante.apellido_paterno} ${asignante.apellido_materno}` : "Sin Datos";
        switch (solicitud.tipo) {
          case "persona":
            //Se asigna el nombre de la persona.
            let persona = solicitud._asignado_ref;
            asignaciones[index_1]["asignado_a"] = persona ? `${persona.nombre} ${persona.apellido_paterno} ${persona.apellido_materno}` : "Sin Datos";
            break;
          case "gerencia":
            //Se asigna el nombre de la gerencia.
            let gerencia = solicitud._gerencia_ref;
            asignaciones[index_1]["asignado_a"] = gerencia ? `${gerencia.sigla} ${gerencia.nombre}` : "Sin Datos";
            break;
          case "contrato":
            //Se asigna el nombre del contrato.
            let contrato = solicitud._contrato_ref;
            asignaciones[index_1]["asignado_a"] = contrato ? `${contrato.codigo} ${contrato.nombre}` : "Sin Datos";
            break;
          default:
            throw new Error("Tipo de asignación no válida.");
        }
        asignaciones[index_1]["tipo"] = toTitleCase(solicitud.tipo);
        asignaciones[index_1]["estado"] = toTitleCase(solicitud.estado);
        asignaciones[index_1]["fecha_creacion"] = solicitud.fecha_creacion ? solicitud.fecha_creacion : null;

        asignaciones[index_1]["gerencia_origen_nombre"] = solicitud._origen_gerencia_ref ? solicitud._origen_gerencia_ref.nombre : "--";
        asignaciones[index_1]["contrato_origen_nombre"] = solicitud._origen_contrato_ref ? solicitud._origen_contrato_ref.nombre : "--";
        asignaciones[index_1]["contrato_origen_codigo"] = solicitud._origen_contrato_ref ? solicitud._origen_contrato_ref.codigo : "--";

        asignaciones[index_1]["gerencia_destino_nombre"] = solicitud._gerencia_ref ? solicitud._gerencia_ref.nombre : "--";
        asignaciones[index_1]["contrato_destino_nombre"] = solicitud._contrato_ref ? solicitud._contrato_ref.nombre : "--";
        asignaciones[index_1]["contrato_destino_codigo"] = solicitud._contrato_ref ? solicitud._contrato_ref.codigo : "--";

        asignaciones[index_1]["fecha_factura"] = solicitud._bien_ref && solicitud._bien_ref.activo && solicitud._bien_ref.activo.factura && solicitud._bien_ref.activo.factura.fecha ? solicitud._bien_ref.activo.factura.fecha : null;

        const sociedad = Array.from(sociedades).find(s => s._id === solicitud._bien_ref._sociedad_ref);
        asignaciones[index_1]["sociedad"] = sociedad ? sociedad.nombre : "--";
      });
      if (asignaciones) {
        //Se ordenan las asignaciones de la más actual a la más antigua.
        asignaciones.sort((a, b) => b.fecha_creacion && a.fecha_creacion && Moment(b.fecha_creacion).diff(Moment(a.fecha_creacion)));
      }
      // return asignaciones;
      setSolicitudes(asignaciones);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      throw error;
    }
  }, [desde, hasta]);



  useEffect(() => {
    AsignacionesPorRango();
  }, [AsignacionesPorRango]);

  return (
    <MaterialTable
      title="Movimientos de tipo asignación"
      is_loading={isLoading}
      data={solicitudes}
      columns={columns}
      actions={actions}
      export_function={handleExport}
      exportButtonPDF={false}
    />
  );
}