import React, { useContext, useState, useEffect } from 'react';
import useSWR from 'swr';
import { useFormik } from 'formik';
import moment from 'moment';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import MaterialReactSelect from '../../../components/materialReactSelect';
import { Autocomplete } from '@material-ui/lab';
import { getUsuarios } from '../../../services/request/requestUsuarios';
import { ObtenerContrato, getContratos, getGerencias } from '../../../services/request/requestPortafolio';
import { ObtenerCondicionesActuales } from '../../../services/request/requestCondicionesActuales';
import {
  deleteAsignacionLicenciaSoftware,
  getAsignacionesLicenciasSoftware,
  postLogLicenciaSoftware,
} from '../../../services/request/requestLicenciasSoftware';
import { GenerarSolicitudAsignacion } from '../../../services/request/requestSolicitudesAsignacion';
import { getUbicaciones } from '../../../services/request/requestUbicaciones';
import { GenerarLog } from '../../../services/activoLog';
import { putBien } from '../../../services/request/requestBienes';
import { MainContext } from '../../../App';
import { ListadoActivosContext } from '.';
import { useSnackbar } from 'notistack';
import { Close as CloseIcon } from '@material-ui/icons';
import { VER_SELECCION_GERENCIAS, VER_CONTRATOS_GERENCIA, VER_CONDICION_VENTA } from '../../../constants/permissions';
import {
  EMAILS_LIBERACION_LICENCIAS,
  TITULO_EMAIL_LIBERACION_LICENCIAS,
  CONTENIDO_EMAIL_LIBERACION_LICENCIAS,
} from '../../../constants/correos_destinatarios';
import { SendHTML } from '../../../services/email';
import { CONTRATOS_LIBERA_LICENCIAS } from '../../../constants/constantes';

function TablaSeleccionados(props) {
  const { handle_close } = props;

  const { usuarioSesion, permisos } = useContext(MainContext);
  const { data, setData, OpenSeleccionados, SetOpenSeleccionados } = useContext(ListadoActivosContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedData, setSelectedData] = useState([]);
  const [CondicionesActuales, SetCondicionesActuales] = useState([]);
  const [CondicionActual, SetCondicionActual] = useState([]);
  const classes = useStyles();
  const notistack = useSnackbar();

  //Funcion que permite obtener las gerencias dependiendo del permiso que posea el usuario
  function getDataGerencias() {
    if (permisos[VER_SELECCION_GERENCIAS]) {
      return getGerencias();
    } else {
      if (usuarioSesion && usuarioSesion.gerencia) {
        return getGerencias({ _id: usuarioSesion.gerencia });
      }
    }
  }

  //Permite obtener los contratos que puede visualizar el usuario, segun sus permisos
  function getDataContratos() {
    if (permisos[VER_SELECCION_GERENCIAS]) {
      return getContratos();
    } else {
      if (permisos[VER_CONTRATOS_GERENCIA]) {
        return getContratos({ gerencia_ref: usuarioSesion.gerencia });
      } else {
        return getContratos({ _id: usuarioSesion.contrato });
      }
    }
  }

  const { data: usuarios, isValidating: isValidatingUsuarios } = useSWR(
    'personas',
    (key) => getUsuarios({ is_gsuite_talana: true }),
    { revalidateOnFocus: false },
  );
  const { data: contratos, isValidating: isValidatingContratos } = useSWR('contratos', (key) => getDataContratos(), {
    revalidateOnFocus: false,
  });
  const { data: gerencias, isValidating: isValidatingGerencias } = useSWR('gerencias', (key) => getDataGerencias(), {
    revalidateOnFocus: false,
  });
  const { data: ubicaciones, error: errorUbicaciones } = useSWR(
    'get:ubicaciones',
    (key) =>
      getUbicaciones(
        {},
        ['_id', 'is_eliminado', 'ubicacion', 'descripcion', 'fecha_creacion', '_detalle_ubicacion_ref'],
        ['_detalle_ubicacion_ref.resumen_detalle'],
      ),
    { refreshInterval: 0 },
  );

  const formik = useFormik({
    initialValues: {
      _usuario_asignante_ref: usuarioSesion.ref,
      _asignado_ref: null,
      _contrato_ref: null,
      _gerencia_ref: null,
      nombre_asignado: '',
      tipo: '',
      estado: 'aceptado',
      contrato: null,
      persona: null,
      gerencia: null,
      multiplesActivos: [
        {
          condicion_actual: '',
          condicion_anterior: '',
          ubicacion: '',
          ubicacion_ref: null,
          detalle_ubicacion: '',
          listado_detalle: [],
          formulario_estado: {
            estado: '',
            descripcion: '',
            foto_antes: null,
          },
        },
      ],
    },
    onSubmit: (values, helper) => handleAsignar(values),
    enableReinitialize: true,
  });

  const { setFieldValue, values, resetForm } = formik;

  useEffect(() => {
    ObtenerCondicionesActuales()
      .then((response) => {
        let options = [];
        if (response && response.data.length > 0) {
          options = response.data.filter((condicion) => condicion.value !== 'Para venta');

          if (permisos[VER_CONDICION_VENTA])
            options.push(response.data.find((condicion) => condicion.value === 'Para venta'));
        }

        SetCondicionesActuales(options);
      })
      .catch((error) => {
        console.error(error);
        SetCondicionesActuales([]);
      });
  }, []);

  useEffect(() => {
    if (selectedData.length > 0) {
      const condicionTemporal = selectedData.map((row) => {
        return {
          id: row._id,
          value: row.condicion_actual,
          label: row.condicion_actual,
        };
      });

      SetCondicionActual(condicionTemporal);
    }
  }, [selectedData, CondicionesActuales]);

  useEffect(() => {
    if (selectedData.length > 0) {
      const ubicacionTemporal = selectedData.map((row) => {
        return {
          condicion_actual: row.condicion_actual,
          condicion_anterior: row.condicion_actual,
          ubicacion: row.ubicacion,
          ubicacion_ref: null,
          detalle_ubicacion: row.detalle_ubicacion,
          listado_detalle: [],
          formulario_estado: {
            estado: row.condicion_actual,
            descripcion: '',
            foto_antes: null,
          },
        };
      });
      setFieldValue('multiplesActivos', ubicacionTemporal);
    }
  }, [selectedData, ubicaciones]);

  const handleChangePaso = (value) => {
    if (selectedTab === 0) {
      formik.setFieldValue('tipo', '');
    }
    if (selectedTab === 1) {
      formik.setFieldValue('asignado', '');
      formik.setFieldValue('_asignado_ref', null);
      formik.setFieldValue('_contrato_ref', null);
      formik.setFieldValue('_gerencia_ref', null);
    }
    setSelectedTab(selectedTab + value);
  };

  const handleChangeSeleccion = (selectedOption, index) => {
    const update = {
      id: index,
      value: selectedOption.value,
      label: selectedOption.label,
    };

    const updateCondicionTemporal = CondicionActual.map((condicion) =>
      condicion.id === String(index) ? update : condicion,
    );
    SetCondicionActual(updateCondicionTemporal);
  };

  const HandleChangeUbicaciones = (selectedOption, index) => {
    let modActivo = [...values.multiplesActivos];

    modActivo[index].ubicacion = selectedOption ? selectedOption.label : '';
    modActivo[index].ubicacion_ref = selectedOption ? selectedOption.value : '';
    modActivo[index].listado_detalle =
      selectedOption?.value?._detalle_ubicacion_ref && selectedOption?.value?._detalle_ubicacion_ref.length > 0
        ? selectedOption?.value?._detalle_ubicacion_ref
        : [];

    formik.setFieldValue('multiplesActivos', modActivo);
  };

  const HandleChangePosiciones = (selectedOption, index) => {
    let modActivo = [...values.multiplesActivos];

    modActivo[index].detalle_ubicacion = selectedOption ? selectedOption.label : '';

    formik.setFieldValue('multiplesActivos', modActivo);
  };

  const checkIsDisabled = () => {
    //En paso 1 debe seleccionar un tipo de asignación.
    if (selectedTab === 0 && selectedData.length === 0) {
      return true;
    }
    //En paso 2 debe seleccionar un asignado (persona, contrato o gerencia).
    if (selectedTab === 1 && !formik.values.tipo) {
      return true;
    }
    if (selectedTab === 2) {
      return true;
    }
    return false;
  };

  async function handleEliminarLicencia(licencia, bien) {
    if (licencia) await deleteAsignacionLicenciaSoftware(licencia._id);
    await postLogLicenciaSoftware({
      _usuario_ref: usuarioSesion.ref,
      _licencia_software_ref: licencia._licencia_software_ref,
      descripcion: `Desasignación de Licencia de ${bien.codigo_activo}`,
    });
  }

  async function handleAsignar(values) {
    try {
      //crea variable para verificar si se eliminan las licencias de software asignados al activo
      let quita_licencias_software = false;
      const bienes = [...data];

      //Obtenemos el contrato asignado para chequear si corresponde quitar las licencias de activos que las posean
      const contrato = await ObtenerContrato(values._contrato_ref);
      quita_licencias_software = CONTRATOS_LIBERA_LICENCIAS.includes(contrato.codigo);

      for (let i = 0; i < selectedData.length; i++) {
        const seleccionado = selectedData[i];

        /* Verificacion de cambio de la condicion actual original*/
        CondicionActual.map(async (condicion) => {
          if (seleccionado._id === condicion.id && !(seleccionado.condicion_actual === condicion.value)) {
            const registroLog = {
              _bien_ref: seleccionado._id,
              titulo: 'Actualización de Condición Actual',
              cuerpo: `Se actualizó la condición actual del activo ${seleccionado.codigo_activo} a ${condicion.label}. Actualizado por ${usuarioSesion.nombre} `,
              tipo: 'actualizacion',
              icon: 'edit',
            };
            seleccionado.condicion_actual = condicion && condicion.label ? condicion.label : ' ';

            await Promise.all([
              GenerarLog(registroLog),
              putBien(seleccionado._id, {
                condicion_actual: condicion.label,
                comentario_condicion_actual: 'Actualizado por ' + usuarioSesion.nombre,
              }),
            ]);
          }
        });

        await GenerarSolicitudAsignacion({
          _usuario_asignante_ref: usuarioSesion.ref,
          _bien_ref: seleccionado._id,
          fecha_revision: null,
          _asignado_ref: values._asignado_ref,
          tipo: values.tipo,
          estado: 'aceptado',
          formulario_estado: {
            estado: seleccionado.condicion_actual ? seleccionado.condicion_actual : ' ',
            descripcion: '',
            foto_antes: {
              nombre: seleccionado.codigo_activo,
              repositorio: 'sistema-gestor-activos',
              url: seleccionado.foto_actual,
              size: 0,
              mime_type: '',
              path: '',
            },
          },
          _origen_contrato_ref: seleccionado.contrato_id,
          _origen_gerencia_ref: seleccionado.gerencia_id,
        });
        const contratoPersona = contratos.data.find((x) => x._id === values._contrato_ref);

        const log = {
          _bien_ref: seleccionado._id,
          titulo: 'Asignación de Activo',
          cuerpo: `El Activo ha sido asignado a ${values.nombre_asignado} ${
            values._asignado_ref ? ', en contrato: ' + contratoPersona.codigo + ' ' + contratoPersona.nombre : ' '
          } por ${usuarioSesion.nombre}.`,
          tipo: 'asignacion',
          icon: 'check',
        };

        await GenerarLog(log);
        await putBien(seleccionado._id, {
          _asignado_ref: values._asignado_ref,
          _gerencia_ref: values._gerencia_ref,
          _contrato_ref: values._contrato_ref,
          asignado_por: usuarioSesion.nombre,
          ubicacion: values.multiplesActivos[i].ubicacion,
          detalle_ubicacion: values.multiplesActivos[i].detalle_ubicacion,
        });

        //Se quitan las licencias de software asignadas al activo
        if (quita_licencias_software) {
          const response = await getAsignacionesLicenciasSoftware(
            { _bien_ref_eq: seleccionado._id },
            [],
            [
              '_licencia_software_ref.nombre',
              '_licencia_software_ref.version',
              '_licencia_software_ref.codigo_compra',
              '_licencia_software_ref.tipo',
              '_licencia_software_ref._marca_ref',
              '_licencia_software_ref._marca_ref.marca',
              '_licencia_software_ref._version_ref',
            ],
          );
          if (response.status) {
            if (response.data.length > 0) {
              const licencia = [];

              response.data.forEach((element) => {
                licencia.push(`
									<p></p>
									<p>Nombre: ${element._licencia_software_ref.nombre || '---'}</p>
									<p>Versión: ${element._licencia_software_ref.version || '---'}</p>
									<p>Código de Compra: ${element._licencia_software_ref.codigo_compra || '---'}</p>
									<p>Tipo Licencia: ${element._licencia_software_ref.tipo || '---'}</p>
									<p></p>
								`);
                handleEliminarLicencia(element, seleccionado.codigo_activo);
              });

              //Contenido HTML con informacion para el email.
              const htmlContent = CONTENIDO_EMAIL_LIBERACION_LICENCIAS(seleccionado.codigo_activo, licencia.join(''));

              //Se envia el email indicando las licencias liberadas
              SendHTML(
                EMAILS_LIBERACION_LICENCIAS,
                TITULO_EMAIL_LIBERACION_LICENCIAS(seleccionado.codigo_activo),
                htmlContent,
              )
                .then(() =>
                  console.log(
                    `Email de liberación de licencia de Software ${seleccionado.codigo_activo} enviado a preyes@cydingenieria.cl.`,
                  ),
                )
                .catch((error) =>
                  console.error(
                    `Error al intentar enviar email de asignación del activo ${seleccionado.codigo_activo} a preyes@cydingenieria.cl.`,
                    error,
                  ),
                );
            }
          }
        }
        //Se actualizan los datos de la tabla origen para que se muestre su informacion actualizada
        const bien = bienes.find((bien) => bien._id === seleccionado._id);
        bien.condicion_actual = seleccionado.condicion_actual;
        bien.ubicacion = values.multiplesActivos[i].ubicacion;
        bien.detalle_ubicacion = values.multiplesActivos[i].detalle_ubicacion;

        bien._asignado_ref =
          values._asignado_ref && usuarios.find((x) => x._id === values._asignado_ref)
            ? usuarios.find((x) => x._id === values._asignado_ref).nombre +
              ' ' +
              usuarios.find((x) => x._id === values._asignado_ref).apellido_paterno
            : '--';
        bien.rut_usuario =
          values._asignado_ref && usuarios.find((x) => x._id === values._asignado_ref)
            ? usuarios.find((x) => x._id === values._asignado_ref).run
            : '--';
        bien.mail_usuario =
          values._asignado_ref && usuarios.find((x) => x._id === values._asignado_ref)?.contacto
            ? usuarios.find((x) => x._id === values._asignado_ref).contacto.email
            : '--';

        bien._gerencia_ref =
          values._gerencia_ref && gerencias.find((x) => x._id === values._gerencia_ref)
            ? gerencias.find((x) => x._id === values._gerencia_ref).nombre
            : '--';
        bien.gerencia_id = values._gerencia_ref;

        bien._contrato_ref =
          values._contrato_ref && contratos.data.find((x) => x._id === values._contrato_ref)
            ? contratos.data.find((x) => x._id === values._contrato_ref).nombre
            : '--';
        bien.codigo_contrato =
          values._contrato_ref && contratos.data.find((x) => x._id === values._contrato_ref)
            ? contratos.data.find((x) => x._id === values._contrato_ref).codigo
            : '--';
        bien.contrato_id = values._contrato_ref;

        bien.asignado_por = usuarioSesion.nombre;
        bien.fecha_asignacion = moment().format('DD/MM/YYYY');

        SetOpenSeleccionados(false);
      }
      setData(bienes);

      notistack.enqueueSnackbar('Solicitudes de asignación aceptadas exitosamente.', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (key) => (
          <IconButton onClick={() => notistack.closeSnackbar(key)}>
            <CloseIcon />
          </IconButton>
        ),
      });
      /**
       * ACEPTACIÓN DE SOLICITUD AUTOMÁTICA
       */
    } catch (error) {
      console.error(error);
      notistack.enqueueSnackbar('Error al intentar realizar la asignación.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (key) => (
          <IconButton onClick={() => notistack.closeSnackbar(key)}>
            <CloseIcon />
          </IconButton>
        ),
      });
    }
  }

  const handleClose = () => {
    resetForm({ values: null });
    SetOpenSeleccionados(false);
  };

  async function onEnter() {
    let datosSeleccionados = data.filter((a) => a.checked);

    let datosMultiples = datosSeleccionados.map((activo) => ({
      condicion_actual: activo.condicion_actual,
      condicion_anterior: activo.condicion_actual,
      ubicacion: activo.ubicacion,
      ubicacion_ref: null,
      listado_detalle: [],
      detalle_ubicacion: activo.detalle_ubicacion,
      formulario_estado: {
        estado: activo.condicion_actual,
        descripcion: '',
        foto_antes: null,
      },
    }));

    let values = {
      _usuario_asignante_ref: usuarioSesion.ref,
      _asignado_ref: null,
      _contrato_ref: null,
      _gerencia_ref: null,
      nombre_asignado: '',
      tipo: '',
      estado: 'aceptado',
      contrato: null,
      persona: null,
      gerencia: null,
      multiplesActivos: datosMultiples,
    };

    resetForm({ values: values });

    setSelectedTab(0);
    setSelectedData(data.filter((a) => a.checked));
  }

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={OpenSeleccionados}
      onClose={handle_close}
      TransitionProps={{
        onEnter: onEnter,
      }}
    >
      <DialogContent style={{ maxHeight: '600px' }}>
        <Box m={1}>
          <Typography variant="h5" align="center" style={{ margin: 20 }}>
            Activos Seleccionados Para Asignar
          </Typography>
          {/* Visualizacion de informacion y cambio de Condicion Actual */}
          {selectedTab === 0 && (
            <div className={classes.div}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead style={{ backgroundColor: '#731f1f' }}>
                    <TableRow>
                      <TableCell className={classes.table_header}>Código Activo</TableCell>
                      <TableCell align="center" className={classes.table_header}>
                        Clase
                      </TableCell>
                      <TableCell align="center" className={classes.header_condicion}>
                        Condición
                      </TableCell>
                      <TableCell align="center" className={classes.header_condicion}>
                        Ubicación
                      </TableCell>
                      <TableCell align="center" className={classes.table_header}>
                        Marca
                      </TableCell>
                      <TableCell align="center" className={classes.header_descripcion}>
                        Descripción
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* SI NO HAY DATOS */}
                    {selectedData.length === 0 && (
                      <TableRow>
                        <TableCell align="center" colSpan={5}>
                          Sin Datos
                        </TableCell>
                      </TableRow>
                    )}
                    {/* SI HAY DATOS */}
                    {selectedData.map((row, index) => (
                      <TableRow key={`table_row_${index}`}>
                        <TableCell component="th" scope="row">
                          {row ? row.codigo_activo : '---'}
                        </TableCell>
                        <TableCell align="center">{row ? row._clase_ref : '---'}</TableCell>
                        <TableCell align="center">
                          <MaterialReactSelect
                            value={
                              CondicionActual.length > 0
                                ? CondicionesActuales.find((d) => d.label === CondicionActual[index]?.label)
                                : row.condicion_actual
                            }
                            label="Condición Actual"
                            isClearable={false}
                            onChange={(selectedOption) => handleChangeSeleccion(selectedOption, row._id)}
                            placeholder="Seleccionar Condición"
                            options={CondicionesActuales}
                            noOptionsMessage={() => 'No ha entrado la Condición Actual'}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <MaterialReactSelect
                            value={
                              ubicaciones
                                ? ubicaciones.data.find((d) => d.label === values.multiplesActivos[index]?.ubicacion)
                                : row.ubicacion
                            }
                            label="Ubicación Fisica"
                            isClearable={false}
                            onChange={(selectedOption) => HandleChangeUbicaciones(selectedOption, index)}
                            placeholder="Seleccionar Ubicación"
                            options={
                              ubicaciones &&
                              ubicaciones.data.map((value, index) => ({
                                value: value,
                                label: value?.ubicacion ? value?.ubicacion : '',
                              }))
                            }
                            noOptionsMessage={() => 'No ha entrado la Ubicación'}
                          />
                          {values.multiplesActivos[index]?.listado_detalle &&
                            values.multiplesActivos[index].listado_detalle?.length > 0 && (
                              <MaterialReactSelect
                                value={
                                  values.multiplesActivos[index].listado_detalle
                                    ? values.multiplesActivos[index]?.listado_detalle.find(
                                        (d) => d.label === values.multiplesActivos[index]?.detalle_ubicacion,
                                      )
                                    : row.detalle_ubicacion
                                }
                                label="Posición"
                                isClearable={false}
                                onChange={(selectedOption) => HandleChangePosiciones(selectedOption, index)}
                                placeholder="Seleccionar Posición"
                                options={
                                  values.multiplesActivos[index].listado_detalle &&
                                  values.multiplesActivos[index].listado_detalle
                                    .sort((a, b) => SortBy(a.resumen_detalle, b.resumen_detalle))
                                    .map((value, index) => ({
                                      value: value,
                                      label: value?.resumen_detalle ? value?.resumen_detalle : '',
                                    }))
                                }
                                noOptionsMessage={() => 'No ha entrado la Posición'}
                              />
                            )}
                        </TableCell>
                        <TableCell align="center">{row ? row.marca : '---'}</TableCell>
                        <TableCell align="center">{row ? row.descripcion : '---'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
          {selectedTab === 1 && (
            <div className={classes.div}>
              <RadioGroup name="tipo" value={formik.values.tipo} onChange={formik.handleChange}>
                <FormControlLabel value="persona" control={<Radio />} label="Asignar a Persona" />
                <FormControlLabel value="contrato" control={<Radio />} label="Asignar a Contrato" />
                <FormControlLabel value="gerencia" control={<Radio />} label="Asignar a Gerencia" />
              </RadioGroup>
              <Typography variant="caption" color="error">
                {formik.touched.tipo && formik.errors.tipo ? formik.errors.tipo : ''}
              </Typography>
            </div>
          )}
          {selectedTab === 2 && (
            <div className={classes.div} style={{ marginTop: 10 }}>
              {/* SELECCIONADOR DE USUARIO */}
              {formik.values.tipo === 'persona' && (
                <Autocomplete
                  options={usuarios || []}
                  value={values.persona}
                  loading={isValidatingUsuarios}
                  getOptionLabel={(usuario) => `${usuario.nombre_completo} (${usuario.contacto.email})`}
                  onChange={(event, value) => {
                    formik.setFieldValue('persona', value);
                    if (value) {
                      formik.setFieldValue('_gerencia_ref', value.gerencia_ref._id);
                      formik.setFieldValue('_contrato_ref', value.contratos_ref[0].contrato_id);
                      formik.setFieldValue('_asignado_ref', value._id);
                      formik.setFieldValue('nombre_asignado', value.nombre_completo);
                    }
                  }}
                  size="small"
                  autoComplete
                  loadingText="Cargando..."
                  noOptionsText="Sin Datos"
                  renderInput={(params) => <TextField label="Usuario" variant="outlined" {...params} />}
                />
              )}
              {/* SELECCIONADOR DE CONTRATO */}
              {formik.values.tipo === 'contrato' && (
                <Autocomplete
                  options={contratos ? contratos.data : []}
                  value={values.contrato}
                  loading={isValidatingContratos}
                  getOptionLabel={(contrato) => `[${contrato.codigo}] ${contrato.nombre}`}
                  onChange={(event, value) => {
                    formik.setFieldValue('contrato', value);
                    if (value) {
                      formik.setFieldValue('_asignado_ref', null);
                      formik.setFieldValue('_contrato_ref', value._id);
                      formik.setFieldValue('_gerencia_ref', value.gerencia_ref._id);
                      formik.setFieldValue('nombre_asignado', value.nombre);
                    }
                  }}
                  size="small"
                  autoComplete
                  loadingText="Cargando..."
                  noOptionsText="Sin Datos"
                  renderInput={(params) => <TextField label="Contrato" variant="outlined" {...params} />}
                />
              )}
              {/* SELECCIONADOR DE GERENCIA */}
              {formik.values.tipo === 'gerencia' && (
                <Autocomplete
                  options={gerencias || []}
                  value={values.gerencia}
                  loading={isValidatingGerencias}
                  getOptionLabel={(gerencia) => `[${gerencia.sigla}] ${gerencia.nombre}`}
                  onChange={(event, value) => {
                    formik.setFieldValue('gerencia', value);
                    if (value) {
                      formik.setFieldValue('_asignado_ref', null);
                      formik.setFieldValue('_contrato_ref', null);
                      formik.setFieldValue('_gerencia_ref', value._id);
                      formik.setFieldValue('nombre_asignado', value.nombre);
                    }
                  }}
                  size="small"
                  autoComplete
                  loadingText="Cargando..."
                  noOptionsText="Sin Datos"
                  renderInput={(params) => <TextField label="Gerencia" variant="outlined" {...params} />}
                />
              )}
            </div>
          )}
          {/* {JSON.stringify(formik.errors)} */}
          {selectedData.length > 0 && (
            <Box display="flex" justifyContent="center" p={3}>
              <Button onClick={() => handleClose()} className={classes.boton} variant="outlined" color="primary">
                {'Cerrar'}
              </Button>
              <Button
                onClick={() => handleChangePaso(-1)}
                disabled={selectedTab === 0}
                className={classes.boton}
                variant="contained"
                color="primary"
              >
                {'Anterior'}
              </Button>
              <Button
                onClick={() => handleChangePaso(+1)}
                disabled={checkIsDisabled()}
                className={classes.boton}
                variant="contained"
                color="primary"
              >
                {'Siguiente'}
              </Button>
              <Button
                onClick={() => formik.handleSubmit()}
                disabled={selectedTab !== 2}
                className={classes.boton}
                variant="contained"
                color="primary"
              >
                {'Aceptar'}
              </Button>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  popover: {
    maxHeight: '1200px',
  },
  div: {
    minWidth: '100%',
  },
  table_header: {
    color: 'white',
  },
  boton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header_condicion: {
    color: 'white',
    width: '200px',
  },
  header_descripcion: {
    color: 'white',
    width: '250px',
  },
}));

function SortBy(text1, text2) {
  if (text1 < text2) {
    return -1;
  } else if (text1 > text2) {
    return 1;
  } else {
    return 0;
  }
}

export default TablaSeleccionados;
