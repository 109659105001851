/**
 * GAF-4 Tecnología de la Información
 */
export const GAF_TI_ID = "5f7e0fb7952c2d0019e8721f";

/**
 * GAF-3 Administración
 */
export const GAF_RRFF_ID = "5f7e0fb7952c2d0019e8721e";

/**
 * GAF-1 Contabilidad
 */
export const GAF_CONTABILIDAD_ID = "60171d906f22b1001843a75b";

/**
 * Gerencia GAF
 */
export const GERENCIA_GAF_ID = "5e0f28b070edaf00137fcf44";

/**
 * GAF-8 Activos en Transito
 */
 export const GAF_EN_TRANSITO_ID = "610408a65920df0019145b2b";

 /**
 * GAF-7 Bodega
 */
  export const GAF_BODEGA_ID = "603d41e2a7c51600185b4cdc";