import axios from 'axios';
import { BACKEND } from '../../constants/urls'

/**
 * Obtener Mantenciones Vehiculos
 * @param {*} filtro
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export function getmantencionesVehiculos(filter = {}, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/mantenciones-vehiculos`;
  if (selects) selects.forEach(select => {
    url = url + "/" + select;
  });
  if (populates) populates.forEach(populate => {
    url = url + "/" + populate;
  });
  return axios.get(url,
    {
      params: filter,
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Obtener mantencion de vehiculo por id
 * @param {*} id
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export async function getMantencionVehiculoPorID(id, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/mantenciones-vehiculos/${id}`;
  if (selects) selects.forEach(select => {
    url = url + "/" + select;
  });
  if (populates) populates.forEach(populate => {
    url = url + "/" + populate;
  });
  return axios.get(url,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Agregar mantencion de vehiculo
 * @param {Object} data
 * @param {*} cancelToken token para cancelacion
 */
export async function postMantencionVehiculo(data, cancelToken = null) {
  let url = `${BACKEND}/mantenciones-vehiculos`;
  return axios.post(url, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Modificar mantencion de vehiculo
 * @param {*} id
 * @param {Object} data
 * @param {*} cancelToken token para cancelacion
 */
export async function putMantencionVehiculo(id, data, cancelToken = null) {
  let url = `${BACKEND}/mantenciones-vehiculos/${id}`;
  return axios.put(url, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Eliminar documento requerido de clase
 * @param {*} id
 * @param {Object} data
 * @param {*} cancelToken token para cancelacion
 */
export async function deleteMantencionVehiculo(id, data,  cancelToken = null) {
  let url = `${BACKEND}/mantenciones-vehiculos/${id}`;
  return axios.put(url, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}