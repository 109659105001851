import {
  AppBar,
  Container,
  Paper,
  Tab,
  Tabs
} from "@material-ui/core";
import React, { useState } from "react";
import TablaMisSolicitudesBaja from './tablaMisSolicitudesBaja';
import TablaSolicitudesBajaPendientes from "./tablaSolicitudesBajaPendientes";


export default function Page(props) {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <Container>
      <AppBar position="static" color="default">
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="content-tabs"
          indicatorColor="primary"
          variant="fullWidth">
          <Tab label="Mis Solicitudes" id="tabMisSolicitudes" aria-controls="panelMisSolicitudes" />
          <Tab label="Pendientes / Aprobadas" id="tabPendientes" aria-controls="panelPendientes" />
        </Tabs>
      </AppBar>
      <br />
      <br />
      <Paper>

        <div hidden={selectedTab !== 0}>
          {selectedTab === 0 &&
            <TablaMisSolicitudesBaja />
          }
        </div>
        <div hidden={selectedTab !== 1}>
          {selectedTab === 1 &&
            <TablaSolicitudesBajaPendientes />
          }
        </div>
      </Paper>
    </Container>
  );
}