import axios from 'axios';
import { BACKEND } from '../../constants/urls'

/**
 * Obtener licencias de software
 * @param {*} filtro
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export function getLicenciasSoftware(filter = {}, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/licencias-software`;
  if (selects) selects.forEach(select => {
    url = url + "/" + select;
  });
  if (populates) populates.forEach(populate => {
    url = url + "/" + populate;
  });
  return axios.get(url,
    {
      params: filter,
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Obtener licencia de software por id
 * @param {*} id
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export async function getLicenciaSoftwarePorID(id, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/licencias-software/${id}`;
  if (selects) selects.forEach(select => {
    url = url + "/" + select;
  });
  if (populates) populates.forEach(populate => {
    url = url + "/" + populate;
  });
  return axios.get(url,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Agregar licencia de software
 * @param {Object} data
 * @param {*} cancelToken token para cancelacion
 */
export async function postLicenciaSoftware(data, cancelToken = null) {
  let url = `${BACKEND}/licencias-software`;
  return axios.post(url, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Modificar licencia de software
 * @param {*} id
 * @param {Object} data
 * @param {*} cancelToken token para cancelacion
 */
export async function putLicenciaSoftware(id, data, cancelToken = null) {
  let url = `${BACKEND}/licencias-software/${id}`;
  return axios.put(url, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Eliminar licencia de software
 * @param {*} id
 * @param {Object} data
 * @param {*} cancelToken token para cancelacion
 */
export async function deleteLicenciaSoftware(id, data, cancelToken = null) {
  let url = `${BACKEND}/licencias-software/${id}`;
  return axios.delete(url,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}


/**
 * Obtener asignaciones licencias de software
 * @param {*} filtro
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
 export function getAsignacionesLicenciasSoftware(filter = {}, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/asignacion-licencias-software`;
  if (selects) selects.forEach(select => {
    url = url + "/" + select;
  });
  if (populates) populates.forEach(populate => {
    url = url + "/" + populate;
  });
  return axios.get(url,
    {
      params: filter,
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Obtener asignacion licencia de software por id
 * @param {*} id
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export async function getAsignacionesLicenciaSoftwarePorID(id, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/asignacion-licencias-software/${id}`;
  if (selects) selects.forEach(select => {
    url = url + "/" + select;
  });
  if (populates) populates.forEach(populate => {
    url = url + "/" + populate;
  });
  return axios.get(url,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Agregar asignacion licencia de software
 * @param {Object} data
 * @param {*} cancelToken token para cancelacion
 */
export async function postAsignacionLicenciaSoftware(data, cancelToken = null) {
  let url = `${BACKEND}/asignacion-licencias-software`;
  return axios.post(url, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Modificar asignacion licencia de software
 * @param {*} id
 * @param {Object} data
 * @param {*} cancelToken token para cancelacion
 */
export async function putAsignacionLicenciaSoftware(id, data, cancelToken = null) {
  let url = `${BACKEND}/asignacion-licencias-software/${id}`;
  return axios.put(url, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Eliminar asignacion licencia de software
 * @param {*} id
 * @param {Object} data
 * @param {*} cancelToken token para cancelacion
 */
export async function deleteAsignacionLicenciaSoftware(id, data, cancelToken = null) {
  let url = `${BACKEND}/asignacion-licencias-software/${id}`;
  return axios.delete(url,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}



/**
 * Obtener logs licencias de software
 * @param {*} filtro
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
 export function getLogsLicenciasSoftware(filter = {}, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/logs-licencias-software`;
  if (selects) selects.forEach(select => {
    url = url + "/" + select;
  });
  if (populates) populates.forEach(populate => {
    url = url + "/" + populate;
  });
  return axios.get(url,
    {
      params: filter,
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Obtener log licencia de software por id
 * @param {*} id
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export async function getLogLicenciaSoftwarePorID(id, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/logs-licencias-software/${id}`;
  if (selects) selects.forEach(select => {
    url = url + "/" + select;
  });
  if (populates) populates.forEach(populate => {
    url = url + "/" + populate;
  });
  return axios.get(url,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Agregar log licencia de software
 * @param {Object} data
 * @param {*} cancelToken token para cancelacion
 */
export async function postLogLicenciaSoftware(data, cancelToken = null) {
  let url = `${BACKEND}/logs-licencias-software`;
  return axios.post(url, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Modificar log licencia de software
 * @param {*} id
 * @param {Object} data
 * @param {*} cancelToken token para cancelacion
 */
export async function putLogLicenciaSoftware(id, data, cancelToken = null) {
  let url = `${BACKEND}/logs-licencias-software/${id}`;
  return axios.put(url, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Eliminar log licencia de software
 * @param {*} id
 * @param {Object} data
 * @param {*} cancelToken token para cancelacion
 */
export async function deleteLogLicenciaSoftware(id, data, cancelToken = null) {
  let url = `${BACKEND}/logs-licencias-software/${id}`;
  return axios.delete(url,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}