import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles
} from "@material-ui/core";
import React, { useState } from "react";
import FileInput from "../../components/fileInput";
import xlsx from 'xlsx';
import {
  getBienes,
  postBienSimple,
  postEquipoComputacional,
  putBien,
  putEquipoComputacional,
  putVehiculo,
  postVehiculo
} from "../../services/request/requestBienes";
import { getMarcas, postMarca } from "../../services/request/requestMarcas";
import { getModelos, postModelo } from "../../services/request/requestModelos";
import { getClases } from "../../services/request/requestClase";
import { getContratos, getGerencias } from "../../services/request/requestPortafolio";
import { useFormik } from "formik";
import * as yup from 'yup';
import useSWR from "swr";
import { getTiposRecursos } from "../../services/request/requestTipoRecurso";
import MaterialReactSelect from "../../components/materialReactSelect";
import { useSnackbar } from "notistack";
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { downloadDriveFile } from "../../services/fileUpload";

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(3),
    maxWidth: "100%"
  },
  padding: {
    padding: theme.spacing(1)
  },
  center: {
    textAlign: "center",
  },
  paddingCenter: {
    padding: theme.spacing(1),
    textAlign: "center",
  },
  fileInput: {
    top: 'unset',
    left: 'unset',
    position: 'inherit',
    marginTop: "1px",
  },
}));

const validationSchema = yup.object({
  // archivo: yup.object().required("Debe seleccionar un Archivo").nullable()
});


export default function FormularioGeneralCargaActivosAntiguos() {

  const classes = useStyles();
  const notistack = useSnackbar();
  const comenzarCarga = (values) => {
    const key = notistack.enqueueSnackbar("Iniciando carga de activos...", {
      persist: true,
      anchorOrigin: {
        horizontal: "center",
        vertical: "bottom"
      }
    });
    try {
      if (values.archivo) {
        let fileReader = new FileReader();
        fileReader.onload = async (e) => {
          const binary = new Uint8Array(e.target.result);
          const workbook = xlsx.read(binary, { type: 'array' });
          let worksheet = workbook.Sheets["Hoja 1"];
          let desired_row = 2;
          let desired_cell = worksheet["A" + desired_row];
          let desired_value = (desired_cell ? desired_cell.v : undefined);
          const creados = [];
          const actualizados = [];
          while (desired_value) {
            let rowData = {
              codigo_activo: desired_value,
              codigo_contable: desired_value,
              descripcion: (worksheet["B" + desired_row]) ? worksheet["B" + desired_row].w : "",
              is_activo_contable: true,
              marca: (worksheet["C" + desired_row]) ? worksheet["C" + desired_row].w : "",
              modelo: (worksheet["D" + desired_row]) ? worksheet["D" + desired_row].w : "",
              serial_number: (worksheet["E" + desired_row]) ? worksheet["E" + desired_row].w : "",
              activo: {
                valor: (worksheet["F" + desired_row]) ? worksheet["F" + desired_row].w : 0
              },
            };
            //BUSCAR CLASE
            let clase = (worksheet["G" + desired_row]) ? worksheet["G" + desired_row].w : "";
            let grupo_contable = null;
            let is_vehiculo = false;
            let is_computacional = false;
            if (values.clase) {
              clase = values.clase.value;
              grupo_contable = values.clase.grupo_contable;
              is_vehiculo = values.clase.is_vehiculo;
              is_computacional = values.clase.is_computacional;
            } else {
              if (clase) {
                let response = await getClases({ nombre_eq: clase });
                response = response.data[0];
                if (response) {
                  clase = response._id;
                  is_vehiculo = response.is_vehiculo;
                  is_computacional = response.is_computacional;
                  grupo_contable = response._grupo_contable_ref ? response._grupo_contable_ref : null;
                }
              }
            }
            //BUSCAR CONTRATO
            let contrato = (worksheet["H" + desired_row]) ? worksheet["H" + desired_row].w : null;
            let gerencia = "";
            let sociedad = "";
            if (values.contrato) {
              contrato = values.contrato.value;
              gerencia = values.gerencia.value;
              sociedad = values.contrato.sociedad ? values.contrato.sociedad._id : null;
            } else {
              if (contrato) {
                let response = await getContratos({ codigo: contrato });
                response = response.data[0];
                if (response) {
                  contrato = response._id;
                  gerencia = response.gerencia_ref._id;
                  sociedad = response.sociedad_ref ? response.sociedad_ref._id : null;
                }
              }
            }



            if (clase && contrato) {
              rowData["_clase_ref"] = clase;
              rowData["_grupo_contable_ref"] = grupo_contable;
              rowData["_contrato_ref"] = contrato;
              rowData["_gerencia_ref"] = gerencia;
              rowData["_sociedad_ref"] = sociedad;

              const marca = await getMarcas({ nombre_eq: rowData.marca });
              if (marca && marca.length > 0) {
                const modelo = await getModelos({ nombre: rowData.modelo, _marca_ref_eq: marca[0]._id });
                if (!modelo) {
                  await postModelo({
                    nombre: rowData.modelo,
                    _marca_ref_eq: marca[0]._id
                  });
                }
              } else {
                const newMarca = await postMarca({
                  nombre: rowData.marca,
                  descripcion: rowData.marca
                });
                await postModelo({
                  nombre: rowData.modelo,
                  _marca_ref_eq: newMarca._id
                });
              }

              const response = await getBienes({ codigo_activo_eq: rowData.codigo_activo });
              const activoYaIngresado = response.data;
              if (!activoYaIngresado || activoYaIngresado.length === 0) {
                if (is_computacional) {
                  const refComputacional = await postEquipoComputacional(
                    {
                      disco_duro: (worksheet["I" + desired_row]) ? worksheet["I" + desired_row].v : 0,
                      procesador: (worksheet["J" + desired_row]) ? worksheet["J" + desired_row].v : "",
                      memoria_ram: (worksheet["K" + desired_row]) ? worksheet["K" + desired_row].v : 0,
                      pulgadas_pantalla: (worksheet["L" + desired_row]) ? worksheet["L" + desired_row].v : 0
                    }
                  );
                  rowData["_equipo_ti_ref"] = refComputacional._id;
                }
                if (is_vehiculo) {
                  const refVehiculo = await postVehiculo(
                    {
                      patente: (worksheet["U" + desired_row]) ? worksheet["U" + desired_row].v : "",
                      kilometraje: (worksheet["M" + desired_row]) ? worksheet["M" + desired_row].v : 0,
                      cilindrada: (worksheet["T" + desired_row]) ? worksheet["T" + desired_row].v : 0,
                      numero_motor: (worksheet["N" + desired_row]) ? worksheet["N" + desired_row].v : "",
                      numero_chasis: (worksheet["O" + desired_row]) ? worksheet["O" + desired_row].v : "",
                      color: (worksheet["P" + desired_row]) ? worksheet["P" + desired_row].v : "",
                      ano: (worksheet["Q" + desired_row]) ? (worksheet["Q" + desired_row].v) + "-01-01" : null,
                      tipo_combustible: (worksheet["R" + desired_row]) ? worksheet["R" + desired_row].v : "",
                      transmision: (worksheet["S" + desired_row]) ? worksheet["S" + desired_row].v : "",
                      barra_antivuelco: false,
                      gps: false,
                      mobil_eye: false,
                      tipo: "",
                      tag: (worksheet["V" + desired_row]) ? worksheet["V" + desired_row].v : ""
                    }
                  );
                  rowData["_vehiculo_ref"] = refVehiculo._id;
                }
                await postBienSimple(rowData);
                creados.push(rowData);
              } else {
                if (is_computacional) {
                  if (activoYaIngresado[0]._equipo_ti_ref) {
                    await putEquipoComputacional(activoYaIngresado[0]._equipo_ti_ref, {
                      disco_duro: (worksheet["I" + desired_row]) ? worksheet["I" + desired_row].v : 0,
                      procesador: (worksheet["J" + desired_row]) ? worksheet["J" + desired_row].v : "",
                      memoria_ram: (worksheet["K" + desired_row]) ? worksheet["K" + desired_row].v : 0,
                      pulgadas_pantalla: (worksheet["L" + desired_row]) ? worksheet["L" + desired_row].v : 0
                    });
                  } else {
                    const refComputacional = await postEquipoComputacional(
                      {
                        disco_duro: (worksheet["I" + desired_row]) ? worksheet["I" + desired_row].v : 0,
                        procesador: (worksheet["J" + desired_row]) ? worksheet["J" + desired_row].v : "",
                        memoria_ram: (worksheet["K" + desired_row]) ? worksheet["K" + desired_row].v : 0,
                        pulgadas_pantalla: (worksheet["L" + desired_row]) ? worksheet["L" + desired_row].v : 0
                      }
                    );
                    rowData["_equipo_ti_ref"] = refComputacional._id;
                  }
                }
                if (is_vehiculo) {
                  if(activoYaIngresado[0]._vehiculo_ref){
                    await putVehiculo(activoYaIngresado[0]._vehiculo_ref, {
                      patente: (worksheet["U" + desired_row]) ? worksheet["U" + desired_row].v : "",
                      kilometraje: (worksheet["M" + desired_row]) ? worksheet["M" + desired_row].v : 0,
                      cilindrada: (worksheet["T" + desired_row]) ? worksheet["T" + desired_row].v : 0,
                      numero_motor: (worksheet["N" + desired_row]) ? worksheet["N" + desired_row].v : "",
                      numero_chasis: (worksheet["O" + desired_row]) ? worksheet["O" + desired_row].v : "",
                      color: (worksheet["P" + desired_row]) ? worksheet["P" + desired_row].v : "",
                      ano: (worksheet["Q" + desired_row]) ? (worksheet["Q" + desired_row].v) + "-01-01" : null,
                      tipo_combustible: (worksheet["R" + desired_row]) ? worksheet["R" + desired_row].v : "",
                      transmision: (worksheet["S" + desired_row]) ? worksheet["S" + desired_row].v : "",
                      barra_antivuelco: false,
                      gps: false,
                      mobil_eye: false,
                      tipo: "",
                      tag: (worksheet["V" + desired_row]) ? worksheet["V" + desired_row].v : ""
                    });
                  }else{
                    const refVehiculo = await postVehiculo(
                      {
                        patente: (worksheet["U" + desired_row]) ? worksheet["U" + desired_row].v : "",
                        kilometraje: (worksheet["M" + desired_row]) ? worksheet["M" + desired_row].v : 0,
                        cilindrada: (worksheet["T" + desired_row]) ? worksheet["T" + desired_row].v : 0,
                        numero_motor: (worksheet["N" + desired_row]) ? worksheet["N" + desired_row].v : "",
                        numero_chasis: (worksheet["O" + desired_row]) ? worksheet["O" + desired_row].v : "",
                        color: (worksheet["P" + desired_row]) ? worksheet["P" + desired_row].v : "",
                        ano: (worksheet["Q" + desired_row]) ? (worksheet["Q" + desired_row].v) + "-01-01" : null,
                        tipo_combustible: (worksheet["R" + desired_row]) ? worksheet["R" + desired_row].v : "",
                        transmision: (worksheet["S" + desired_row]) ? worksheet["S" + desired_row].v : "",
                        barra_antivuelco: false,
                        gps: false,
                        mobil_eye: false,
                        tipo: "",
                        tag: (worksheet["V" + desired_row]) ? worksheet["V" + desired_row].v : ""
                      }
                    );
                    rowData["_vehiculo_ref"] = refVehiculo._id;
                  }
                }
                await putBien(activoYaIngresado[0]._id, rowData);
                actualizados.push(rowData);
              }
            }
            desired_row++;
            desired_cell = worksheet["A" + desired_row];
            desired_value = (desired_cell ? desired_cell.v : undefined);
          }

          console.log("Creados", creados);
          console.log("Actualizados", actualizados);
          notistack.closeSnackbar(key);
          const successkey = notistack.enqueueSnackbar("Carga de activos completadada", {
            variant: 'success',
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom"
            },
            action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
          });
        };
        fileReader.readAsArrayBuffer(values.archivo);
      } else {
        notistack.closeSnackbar(key);
        const successkey = notistack.enqueueSnackbar("Debe seleccionar un archivo para la carga.", {
          variant: 'warning',
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
        });
      }
    }
    catch (e) {
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar("Error en carga de activos. Intente de nuevo o contacte con el equipo de soporte.", {
        variant: 'error',
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
      });
    }
  }
  const formik = useFormik({
    initialValues: {
      archivo: null,
      clase: "",
      contrato: "",
      sociedad: ""
    },
    onSubmit: comenzarCarga,
    validationSchema: validationSchema
  });

  const { errors, touched, values, handleSubmit, setFieldValue, setFieldTouched } = formik;

  const fileChange = (name, e) => {
    setFieldValue(name, e.target.files[0]);
    setFieldTouched(name, true, false);
  };

  const { data: gerencias } = useSWR("get:gerencias", (key) => getGerencias({}), { refreshInterval: 0 });
  const { data: tiposRecursos } = useSWR("get:tipos-recursos", (key) => getTiposRecursos({}), { refreshInterval: 0 });

  const [contratos, setContratos] = useState([]);
  const [clases, setClases] = useState([]);


  const handleChangeTipoRecurso = (newValue, actionMeta) => {
    if (newValue) {
      setFieldValue("tipo_recurso", newValue);
      getClases({ _tipo_recurso_ref_eq: newValue.value })
        .then(response => {
          if (response.status) {
            setClases(response.data);
          } else {
            setClases([]);
          }
        })
        .catch(e => {
          console.log(e);
          setClases([]);
        });

    } else {
      setClases([]);
      setFieldValue("tipo_recurso", "");
    }
    setFieldValue("clase", "", false);
  }

  const handleChangeGerencia = (newValue, actionMeta) => {
    if (newValue) {
      setFieldValue("gerencia", newValue);
      getContratos({ gerencia_ref: newValue.value }).then(response => {
        if (response.status === 200) {
          setContratos(response.data);
        } else if (response.status === 204) {
          setContratos([]);
        } else {
          setContratos([]);
        }
      })
        .catch(e => {
          console.log(e);
          setContratos([]);
        });

    } else {
      setContratos([]);
      setFieldValue("gerencia", "");
    }
    setFieldValue("contrato", "", false);
  }

  const descargarFormato = async () => {
    const key = notistack.enqueueSnackbar("Descargando Archivo...", {
      persist: true,
      anchorOrigin: {
        horizontal: "center",
        vertical: "bottom"
      }
    });
    try {
      await downloadDriveFile("https://www.googleapis.com/drive/v3/files/1UHH6fSVeutjhhzDabrr_t7BqOvs_KFkk?alt=media", "Carga de activos.xlsx");
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar("Descarga completada", {
        variant: 'success',
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
      });
    }
    catch (e) {
      console.log(e);
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar("Error en descarga. Intente de nuevo o contacte con el equipo de soporte.", {
        variant: 'error',
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
      });
    }
  }

  console.log(errors);
  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item lg={6} md={6} sm={12}>
          <MaterialReactSelect
            value={values.gerencia}
            label="Gerencia"
            isClearable
            onChange={handleChangeGerencia}
            placeholder="Seleccionar una Gerencia"
            options={gerencias ? gerencias.map((value, index) => ({
              value: value._id,
              label: value.nombre
            })) : []}
            noOptionsMessage={() => "No ha ingresado la Gerencia"}
            error={touched.gerencia && errors.gerencia && Boolean(errors.gerencia)}
            helperText={touched.gerencia && errors.gerencia ? errors.gerencia : ""}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12}>
          <MaterialReactSelect
            value={values.contrato}
            label="Contrato"
            isClearable
            onChange={(newValue, actionMeta) => {
              if (newValue) {
                formik.setFieldValue("contrato", newValue);
              }
              else {
                formik.setFieldValue("contrato", "");
              }
            }}
            placeholder="Seleccionar un Contrato"
            options={contratos.map((value, index) => ({
              value: value._id,
              label: value.codigo + " " + value.nombre,
              sociedad: value.sociedad_ref
            }))}
            noOptionsMessage={() => "No ha ingresado el Contrato"}
            error={touched.contrato && errors.contrato && Boolean(errors.contrato)}
            helperText={touched.contrato && errors.contrato ? errors.contrato : ""}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12}>
          <MaterialReactSelect
            value={values.tipo_recurso}
            label="Tipo Recurso"
            isClearable
            onChange={handleChangeTipoRecurso}
            placeholder="Seleccionar un Tipo de Recurso"
            options={tiposRecursos ? tiposRecursos.map((value, index) => ({
              value: value._id,
              label: value.nombre
            })) : []}
            noOptionsMessage={() => "No ha ingresado el Tipo de Recurso"}
            error={touched.tipo_recurso && errors.tipo_recurso && Boolean(errors.tipo_recurso)}
            helperText={touched.tipo_recurso && errors.tipo_recurso ? errors.tipo_recurso : ""}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12}>
          <MaterialReactSelect
            value={values.clase}
            label="Clase"
            isClearable
            onChange={(newValue, actionMeta) => {
              if (newValue) {
                formik.setFieldValue("clase", newValue);
              }
              else {
                formik.setFieldValue("clase", "");
              }
            }}
            placeholder="Seleccionar una Clase"
            options={clases.map((value, index) => ({
              value: value._id,
              label: value.nombre,
              grupo_contable: value._grupo_contable_ref ? value._grupo_contable_ref : null,
              is_vehiculo: value.is_vehiculo,
              is_computacional: value.is_computacional
            }))}
            noOptionsMessage={() => "No ha ingresado la Clase"}
            error={touched.clase && errors.clase && Boolean(errors.clase)}
            helperText={touched.clase && errors.clase ? errors.clase : ""}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg>
          <FormControl
            fullWidth
          // error={!values.archivo}
          >
            <InputLabel className={classes.fileInput} shrink >Archivo</InputLabel>
            <FileInput
              inputName="archivo"
              buttonColor="primary"
              buttonVariant="outlined"
              label="Archivo de Factura"
              value={values.archivo}
              handleOnChange={fileChange.bind(null, "archivo")}
              textVariant="outlined"
            />
            {/* <FormHelperText>
              {!values.archivo ? "Debe seleccionar un Archivo" : ""}
            </FormHelperText> */}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container className={classes.padding} alignContent="center" justify="center">
        <Grid item lg className={classes.center}>
          <Button variant="outlined" color="primary" onClick={descargarFormato} style={{ marginRight: "8px" }}>Descargar Formato</Button>
          <Button variant="contained" color="primary" type="submit">Comenzar Carga</Button>
        </Grid>
      </Grid>
    </form>
  );
}