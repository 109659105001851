import React, { useState, createContext, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';

import { CssBaseline } from '@material-ui/core';
import AppBar from './components/appBar';
import LoadPage from './containers/loadPage';
import * as ROUTES from './constants/routes';
import Home from './containers/home';
import RegistroBienes from './containers/bienes/registroBienes';
import ListadoPersonas from './containers/bienes/asignarBien/listadoPersonas';
import BienesPersona from './containers/bienes/asignarBien/bienesPersona';
import ListadoGerencias from './containers/bienes/asignarBien/listadoGerencias';
import BienesGerencia from './containers/bienes/asignarBien/bienesGerencia';
import ListadoContratos from './containers/bienes/asignarBien/listadoContratos';
import BienesContrato from './containers/bienes/asignarBien/bienesContrato';
import SolicitudesAsignacion from './containers/solicitudesAsignacion';
import './App.css';
import axios from 'axios';
import { auth, onAuthStateChange } from './services/firebase';
import * as URLS from './constants/urls';
import { obtenerDatosSesionUsuario, getUsuario } from './services/request/requestUsuarios';
import {
  getContrato,
  getContratoPorCodigo,
  getContratosACargo,
  getResponsable,
} from './services/request/requestPortafolio';
import ListadoBienes from './containers/bienes/listadoBienes';
import DetalleBienes from './containers/bienes/detalleBienes';
import Activacion from './containers/activacion';
import VerSolicitudActivacion from './containers/activacion/verSolicitudActivacion';
import VerBien from './containers/bienes/verBien';
import ListadoSolicitudesBaja from './containers/bienes/listadoSolicitudesBaja';
import VerSolicitudBaja from './containers/bienes/verSolicitudBaja';
import AjustesAdministracion from './containers/ajustes/administracion';
import AjustesConfiguracion from './containers/ajustes/configuracion';
import MovimientoMensual from './containers/reportabilidad/movimientoMensual';
import ListadoPerfilesDeRol from './containers/ajustes/administracion/listadoPerfilesDeRol';
import ListadoPermisosPerfil from './containers/ajustes/administracion/listadoPermisosPerfil';
import TablaModelos from './containers/ajustes/configuracion/tablaModelos';
import * as PERMISOS from './constants/permissions';
import CargaMasivaActivos from './containers/cargaMasiva/cargaMasivaActivos';
import ListaActivosTarifas from './containers/reportabilidad/listaActivosTarifas';
import LicenciasSoftware from './containers/licenciasSoftware';
import DetalleLicenciaSoftware from './containers/detalleLicenciaSoftware';
import ReporteActivosRegistrados from './containers/reportabilidad/reporteActivosRegistrados.js';
import InformeSoftware from './containers/reportabilidad/informeSoftware';
import DocumentosPorVencer from './containers/reportabilidad/documentosPorVencer';
import TablaDocumentosRequeridosClase from './containers/ajustes/configuracion/tablaDocumentosRequeridosClase';
import InformeMovimientosActivos from './containers/reportabilidad/informeMovimientosActivos';
import ResumenLicenciasSoftwareGerencias from './containers/reportabilidad/informeResumenLicenciaSoftware';
import GestionFlota from './containers/gestionFlota';

const drawerWidth = 300;
const appbarHeight = 18;

const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none',
  },
  main: {
    display: 'flex',
    // width: "100%"
  },
  root: {
    display: 'flex',
  },
  content: {
    // display: 'flex',
    padding: theme.spacing(11, 1, 3, 1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#dddddd',
    minHeight: `calc(100vh - ${appbarHeight}px)`,
    width: '100vw',
  },
  contentUnShift: {
    width: '100vw',
  },
  contentShift: {
    width: `calc(100vw - ${drawerWidth}px)`,
  },
}));

const getQueryVariable = function (variable) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
};

const login = async () => {
  let currenToken = getQueryVariable('csrfToken');
  if (!currenToken) {
    currenToken = localStorage.getItem('csrfToken');
  }
  localStorage.setItem('csrfToken', currenToken);
  if (currenToken && currenToken !== 'null' && currenToken !== 'undefined') {
    localStorage.setItem('contrato', '');
    localStorage.setItem('proyecto', '');
    await axios
      .post(
        `${URLS.REDIRECT_BASE}/verifySessionCookie`,
        {},
        {
          withCredentials: true,
          crossDomain: true,
          params: {
            _csrf: currenToken,
          },
        },
      )
      .then((response) => {
        if (response.data.token) {
          auth.signInWithCustomToken(response.data.token);
        }
      })
      .catch((error) => {
        console.log(error);
        window.location.href = `${URLS.LOGIN}/?origin=${window.location.href.split('?')[0]}`;
      });
  } else {
    window.location.href = `${URLS.LOGIN}/?origin=${window.location.href.split('?')[0]}`;
  }
};

export const MainContext = createContext({
  usuarioSesion: null,
  listadoContratosACargo: [],
  accessError: false,
  setAccessError: () => {},
  permisos: {},
  setPermisos: () => {},
  filtrosListaActivos: {},
  setFiltrosListaActivos: () => {},
  initialValuesFiltroActivos: {
    gerencia: null,
    contrato: null,
    tipo: null,
    clase: null,
    activado: '',
    baja: 'Vigentes',
    asignado: '',
    codigo_activo: '',
  },
  setInitialValuesFiltroActivos: () => {},
});

export const ListadoVehiculosContext = createContext({
  vehiculos: [],
  setVehiculos: () => {},
});

export default function App(props) {
  const classes = useStyles();
  const [usuarioSesion, setUsuarioSesion] = useState(null);
  const [responsableContrato, setResponsableContrato] = useState(null);
  const [listadoContratosACargo, setListadoContratosACargo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [accessError, setAccessError] = useState(true);
  const [permisos, setPermisos] = useState({});
  const [filtrosListaActivos, setFiltrosListaActivos] = useState({});
  const [initialValuesFiltroActivos, setInitialValuesFiltroActivos] = useState({
    codigo_activo: '',
    gerencia: null,
    contrato: null,
    tipo: null,
    clase: null,
    activado: '',
    baja: 'Vigentes',
    asignado: '',
  });
  const theme = createMuiTheme({
    typography: {
      fontSize: 14,
      htmlFontSize: 17,
      fontFamily: ['Open Sans', 'sans-serif'],
    },
    palette: {
      primary: {
        light: '#a64c46',
        main: '#731f1f',
        dark: '#430000',
        contrastText: '#fff',
      },
      secondary: {
        light: '#4f5b62',
        main: '#263238',
        dark: '#000a12',
        contrastText: '#fff',
      },
      blanco: {
        light: '#fff',
        main: '#fff',
        dark: '#fff',
        contrastText: '#fff',
      },
    },
  });

  const checkSession = (isLogged) => {
    try {
      if (isLogged && auth.currentUser) {
        const user = auth.currentUser;
        console.log(user);
        obtenerDatosSesionUsuario()
          .then((response) => {
            getResponsable(user.uid)
              .then((responsable) => {
                responsable && responsable.length > 0
                  ? setResponsableContrato(responsable[0]._id)
                  : setResponsableContrato(null);
              })
              .catch((error) => {
                console.error(error);
              });
            getUsuario(response.ref)
              .then((usuario) => {
                console.log(usuario);
                let contratoCodigo =
                  usuario.contratos_ref && usuario.contratos_ref.length > 0 ? usuario.contratos_ref[0].codigo : '';
                getContratoPorCodigo(contratoCodigo)
                  .then((contrato) => {
                    setUsuarioSesion({
                      _id: usuario._id,
                      uid: user.uid,
                      photoUrl: user.photoURL,
                      nombre: user.displayName,
                      rut: usuario.run,
                      email: user.email,
                      ref: response.ref,
                      gerencia: usuario.gerencia_ref,
                      contrato: contrato ? contrato._id : null,
                      is_responsable_gerencia: Boolean(response.is_responsable_gerencia),
                      is_ti: Boolean(response.is_ti),
                      is_rff: Boolean(response.is_rff),
                      is_contabilidad: Boolean(response.is_contabilidad),
                    });

                    if (response.permisos) setPermisos(response.permisos);
                    else setPermisos({});
                    setIsLoading(false);
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              })
              .catch((error) => {
                console.error(error);
              });
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        login();
      }
    } catch (error) {
      console.log(error);
      // setAccessError(true);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChange(checkSession);
    return () => {
      unsubscribe();
    };
  }, []);

  //Busca los contratos en los que el usuario sea residente o gerente de proyectos
  useEffect(() => {
    if (responsableContrato && responsableContrato.length > 0) {
      // Se obtienen los contratos en los que pueda ser Gerente de Area (Subgerente)     */
      getContratosACargo(responsableContrato, true)
        .then((contratos) => {
          if (contratos && contratos.length > 0) {
            setListadoContratosACargo(contratos);
          } else {
            //Si no es subgerente, entonces se obtienen los contratos en los que es residente
            getContratosACargo(responsableContrato, false)
              .then((contratos) => {
                if (contratos && contratos.length > 0) {
                  setListadoContratosACargo(contratos);
                } else {
                  setListadoContratosACargo([]);
                }
              })
              .catch((error) => {
                console.error(error);
                setListadoContratosACargo([]);
              });
          }
        })
        .catch((error) => {
          console.error(error);
          setListadoContratosACargo([]);
        });
    } else {
      if (usuarioSesion && usuarioSesion.contrato.length > 0) {
        getContrato(usuarioSesion.contrato)
          .then((contrato) => {
            if (contrato && contrato.length > 0) {
              setListadoContratosACargo(contrato);
            } else {
              setListadoContratosACargo([]);
            }
          })
          .catch((error) => {
            console.error(error);
            setListadoContratosACargo([]);
          });
      }
    }
  }, [responsableContrato]);

  const privateRoute = ({ component: Component, permiso, path, ...rest }, permisos) => {
    return (
      <Route
        path={path}
        {...rest}
        render={(props) => {
          if (permiso && usuarioSesion && (!permisos || !permisos[permiso])) {
            return <Redirect to="/" />;
          }
          return <Component {...props} />;
        }}
      />
    );
  };

  function VehiculosProvider({ children }) {
    const [vehiculos, setVehiculos] = useState([]);

    return (
      <ListadoVehiculosContext.Provider
        value={React.useMemo(() => ({ vehiculos, setVehiculos }), [vehiculos, setVehiculos])}
      >
        {children}
      </ListadoVehiculosContext.Provider>
    );
  }

  const mainApp = (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <CssBaseline />
        <main className={classes.main}>
          <MainContext.Provider
            value={{
              usuarioSesion,
              listadoContratosACargo,
              accessError,
              setAccessError,
              permisos,
              setPermisos,
              filtrosListaActivos,
              setFiltrosListaActivos,
              initialValuesFiltroActivos,
              setInitialValuesFiltroActivos,
            }}
          >
            <VehiculosProvider>
              <AppBar nombreModulo="Gestor de Activos - Dev" />

              <div
                name="Content"
                className={clsx({
                  [classes.content]: true,
                  [classes.contentUnShift]: true,
                })}
              >
                <Switch>
                  <Route exact={true} path={ROUTES.LANDING} component={() => <Home />} />
                  {privateRoute(
                    {
                      component: ListadoBienes,
                      path: ROUTES.LISTADO_BIENES,
                      exact: true,
                      permiso: PERMISOS.VER_LISTADO_ACTIVOS,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: DetalleBienes,
                      path: ROUTES.DETALLE_BIENES,
                      exact: true,
                      permiso: PERMISOS.VER_DETALLE_ACTIVO,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: DetalleBienes,
                      path: ROUTES.DETALLE_MI_ACTIVO,
                      exact: true,
                      permiso: PERMISOS.VER_DETALLE_BIEN_ASIGNADO,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: RegistroBienes,
                      path: ROUTES.REGISTRO_AOB,
                      exact: true,
                      permiso: PERMISOS.AGREGAR_ACTIVO,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: SolicitudesAsignacion,
                      path: ROUTES.SOLICITUDES_ASIGNACION,
                      exact: true,
                      permiso: PERMISOS.VER_LISTADO_ASIGNACIONES,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: Activacion,
                      path: ROUTES.LISTADO_SOLICITUDES_ACTIVACION,
                      exact: true,
                      permiso: PERMISOS.VER_LISTADO_SOLICITUDES_ACTIVACION,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: ListadoPersonas,
                      path: ROUTES.LISTADO_PERSONAS,
                      exact: true,
                      permiso: PERMISOS.VER_LISTADO_PERSONAS,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: BienesPersona,
                      path: ROUTES.BIENES_PERSONA,
                      exact: true,
                      permiso: PERMISOS.VER_LISTADO_PERSONAS,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: ListadoGerencias,
                      path: ROUTES.LISTADO_GERENCIAS,
                      exact: true,
                      permiso: PERMISOS.VER_LISTADO_GERENCIAS,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: BienesGerencia,
                      path: ROUTES.BIENES_GERENCIA,
                      exact: true,
                      permiso: PERMISOS.VER_LISTADO_GERENCIAS,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: ListadoContratos,
                      path: [ROUTES.LISTADO_CONTRATOS, ROUTES.LISTADO_CONTRATOS_ID],
                      exact: true,
                      permiso: PERMISOS.VER_LISTADO_CONTRATOS,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: BienesContrato,
                      path: ROUTES.BIENES_CONTRATO,
                      exact: true,
                      permiso: PERMISOS.VER_LISTADO_CONTRATOS,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: VerSolicitudActivacion,
                      path: ROUTES.VER_SOLICITUD_ACTIVACION,
                      exact: true,
                      permiso: PERMISOS.VER_DETALLE_SOLICITUD_ACTIVACION,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: VerBien,
                      path: ROUTES.VER_ACTIVO,
                      exact: true,
                      permiso: PERMISOS.VER_DETALLE_BIEN_ASIGNADO,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: ListadoSolicitudesBaja,
                      path: ROUTES.SOLICITUDES_BAJA,
                      exact: true,
                      permiso: PERMISOS.VER_LISTADO_SOLICITUDES_BAJA,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: VerSolicitudBaja,
                      path: ROUTES.VER_SOLICITUD_BAJA,
                      exact: true,
                      permiso: PERMISOS.VER_DETALLE_SOLICITUD_BAJA,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: AjustesAdministracion,
                      path: ROUTES.AJUSTES_ADMINISTRACION,
                      exact: true,
                      permiso: PERMISOS.VER_MODULO_AJUSTES,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: AjustesConfiguracion,
                      path: ROUTES.AJUSTES_CONFIGURACION,
                      exact: true,
                      permiso: PERMISOS.VER_MODULO_AJUSTES,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: MovimientoMensual,
                      path: ROUTES.MOVIMIENTO_MENSUAL,
                      exact: true,
                      permiso: PERMISOS.VER_MOVIMIENTO_MENSUAL,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: ListadoPerfilesDeRol,
                      path: ROUTES.LISTADO_PERFILES_ROL,
                      exact: true,
                      permiso: PERMISOS.VER_ROLES,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: ListadoPermisosPerfil,
                      path: ROUTES.LISTADO_PERMISOS_PERFIL,
                      exact: true,
                      permiso: PERMISOS.VER_PERFILES,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: TablaModelos,
                      path: ROUTES.LISTADO_MODELOS_MARCA,
                      exact: true,
                      permiso: PERMISOS.VER_MARCAS,
                    },
                    permisos,
                  )}

                  {privateRoute(
                    {
                      component: CargaMasivaActivos,
                      path: ROUTES.CARGA_MASIVA_ACTIVOS,
                      exact: true,
                      permiso: PERMISOS.CARGAR_ACTIVOS,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: ListaActivosTarifas,
                      path: ROUTES.COSTOS_ACTIVOS,
                      exact: true,
                      permiso: PERMISOS.VER_COSTOS_ACTIVOS,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: LicenciasSoftware,
                      path: ROUTES.LICENCIAS_SOFTWARE,
                      exact: true,
                      permiso: PERMISOS.VER_LICENCIAS_SOFTWARE,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: DetalleLicenciaSoftware,
                      path: ROUTES.DETALLE_LICENCIA_SOFTWARE,
                      exact: true,
                      permiso: PERMISOS.VER_LICENCIAS_SOFTWARE,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: ReporteActivosRegistrados,
                      path: ROUTES.REPORTE_ACTIVOS_REGISTRADOS,
                      exact: true,
                      permiso: PERMISOS.VER_REPORTE_ACTIVOS_REGISTRADOS,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: InformeSoftware,
                      path: ROUTES.INFORME_SOFTWARE_ROUTE,
                      exact: true,
                      permiso: PERMISOS.VER_LICENCIAS_SOFTWARE,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: DocumentosPorVencer,
                      path: ROUTES.DOCUMENTOS_POR_VENCER_ROUTE,
                      exact: true,
                      permiso: PERMISOS.VER_LICENCIAS_SOFTWARE,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: TablaDocumentosRequeridosClase,
                      path: ROUTES.LISTADO_DOCUMENTOS_CLASE,
                      exact: true,
                      permiso: PERMISOS.VER_MODULO_AJUSTES,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: InformeMovimientosActivos,
                      path: ROUTES.INFORME_MOVIMIENTOS_ACTIVOS,
                      exact: true,
                      permiso: PERMISOS.VER_INFORME_MOVIMIENTOS_ACTIVOS,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: ResumenLicenciasSoftwareGerencias,
                      path: ROUTES.REPORTE_RESUMEN_LICENCIAS_SOFTWARE_GERENCIAS_ROUTE,
                      exact: true,
                      permiso: PERMISOS.VER_REPORTE_RESUMEN_LICENCIAS_SOFTWARE_GERENCIAS,
                    },
                    permisos,
                  )}
                  {privateRoute(
                    {
                      component: GestionFlota,
                      path: ROUTES.GESTION_FLOTA,
                      exact: true,
                      //permiso: PERMISOS.VER_LICENCIAS_SOFTWARE
                    },
                    permisos,
                  )}
                </Switch>
              </div>
            </VehiculosProvider>
          </MainContext.Provider>
        </main>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
  return isLoading ? <LoadPage></LoadPage> : mainApp;
}
