import axios from 'axios';
import { BACKEND } from '../../constants/urls'

/**
 * Obtener Actas de Entrega de Vehiculos
 * @param {*} filtro
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export function getActaEntregaVehiculos(filter = {}, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/acta-entrega`;
  if (selects) selects.forEach(select => {
    url = url + "/" + select;
  });
  if (populates) populates.forEach(populate => {
    url = url + "/" + populate;
  });
  return axios.get(url,
    {
      params: filter,
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Obtener acta entrega de vehiculo por id
 * @param {*} id
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export async function getActaEntregaVehiculoPorID(id, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/acta-entrega/${id}`;
  if (selects) selects.forEach(select => {
    url = url + "/" + select;
  });
  if (populates) populates.forEach(populate => {
    url = url + "/" + populate;
  });
  return axios.get(url,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Agregar acta entrega de vehiculo
 * @param {Object} data
 * @param {*} cancelToken token para cancelacion
 */
export async function postActaEntregaVehiculo(data, cancelToken = null) {
  let url = `${BACKEND}/acta-entrega`;
  return axios.post(url, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Modificar acta entrega de vehiculo
 * @param {*} id
 * @param {Object} data
 * @param {*} cancelToken token para cancelacion
 */
export async function putActaEntregaVehiculo(id, data, cancelToken = null) {
  let url = `${BACKEND}/acta-entrega/${id}`;
  return axios.put(url, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}

/**
 * Eliminar acta de entrega de vehiculo registrada por su ID
 * @param {*} id
 * @param {Object} data
 * @param {*} cancelToken token para cancelacion
 */
export async function deleteActaEntregaVehiculo(id, data, cancelToken = null) {
  let url = `${BACKEND}/acta-entrega/${id}`;
  return axios.delete(url,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}