import Axios from "axios";
import { BACKEND } from "../../constants/urls";

/**
 * Método encargado de obtener los registros LOGs asociados a un activo.
 * @param {*} activoID ID del activo.
 */
export async function ObtenerActivoLogs(activoID) {
	try {
		let url = `${BACKEND}/activo-logs?_bien_ref_eq=${activoID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Obtener los registros asociados a un activo
 * @param {*} filtro
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
 export function getActivosLogs(filter = {}, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/licencias-software`;
  if (selects) selects.forEach(select => {
    url = url + "/" + select;
  });
  if (populates) populates.forEach(populate => {
    url = url + "/" + populate;
  });
  return Axios.get(url,
    {
      params: filter,
      cancelToken: cancelToken
    }).then(res => res.data ? res.data : null);
}