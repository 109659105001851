import React, { createContext, useCallback, useEffect, useState } from 'react';
import Page from './page';
import { obtenerCostosActivos } from '../../../services/request/requestBienes';
import { useHistory } from 'react-router';
import * as ROUTES from '../../../constants/routes';
import useSWR, { useSWRConfig } from 'swr';

export const ReporteCostosActivosContext = createContext({
  data: [],
  setTriggerSearch: () => {},
  handleVerActivo: () => {},
  filter: {},
  setFilter: () => {},
  isLoading: true,
  indicadores: {
    costoTotal: 0,
    cantidad: 0,
  },
  setIndicadores: () => {},
  filters: {},
  setFilters: () => {},
  initialValues: {},
  setInitialValues: () => {},
});

const getIndicadoresCostosGestion = (dataCostosGestion) => {
  if (!dataCostosGestion)
    return {
      costoTotal: 0,
      cantidad: 0,
    };
  return {
    costoTotal: dataCostosGestion.reduce((acc, item) => acc + item.tarifa_gestion, 0),
    cantidad: dataCostosGestion.length,
  };
};

export default function ListaActivosTarifas(props) {
  const [filters, setFilters] = useState(null);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [indicadores, setIndicadores] = useState({
    costoTotal: 0,
    cantidad: 0,
  });
  const { data } = useSWR('get:costosGestionActivos', (key) => obtenerCostosActivos({ ...filters }));

  const [initialValues, setInitialValues] = useState({
    gerencia: null,
    contrato: null,
    tipo: null,
    clase: null,
    activado: '',
  });

  const { mutate } = useSWRConfig();

  // const triggerSearch = useCallback(() => {
  //   setIsLoading(true);
  //   mutate('get:costosGestionActivos'); // Se llama al método mutate de SWR para limpiar la caché y recargar los datos
  //   setIsLoading(false);
  // }, [mutate]);

  const triggerSearch = useCallback(async () => {
    setIsLoading(true); // Establecemos isLoading en true al inicio de la búsqueda

    // Llamar a la función para obtener los datos
    try {
      await obtenerCostosActivos({ ...filters }); // No es necesario almacenar los datos en newData
      mutate('get:costosGestionActivos'); // Actualiza la caché con los nuevos datos
    } catch (error) {
      // Manejar errores si es necesario
    } finally {
      setIsLoading(false); // Establecemos isLoading en false al finalizar la búsqueda
    }
  }, [filters, mutate]);

  useEffect(() => {
    triggerSearch();
  }, [filters, triggerSearch]);

  const getIndicadores = () => {
    setIndicadores({
      costoTotal: 0,
      cantidad: 0,
    });

    const indicadores = getIndicadoresCostosGestion(data);
    setIndicadores(indicadores);
  };

  const handleVerActivo = (rowData) => {
    history.push(ROUTES.VER_ACTIVO.replace(':id', rowData._id));
  };

  useEffect(() => {
    getIndicadores();
  }, [data]);

  return (
    <ReporteCostosActivosContext.Provider
      value={{
        data,
        handleVerActivo,
        triggerSearch,
        isLoading,
        indicadores,
        filters,
        setFilters,
        initialValues,
        setInitialValues,
      }}
    >
      <Page />
    </ReporteCostosActivosContext.Provider>
  );
}
