import React, { useContext } from 'react';
import { InformeMovimientosActivosContext } from '.';
import MaterialTable from "@material-table/core";


export default function TablaActivosContratoGerencia() {

  const { activosContratoGerencia, isLoading } = useContext(InformeMovimientosActivosContext);

  const columns = [
    { title: "Gerencia", field: "gerencia" },
    { title: "Cód de Proyecto", field: "codigo_contrato" },
    { title: "Proyecto", field: "nombre_contrato", cellStyle:{ minWidth: "400px"} },
    { title: "Computacionales", field: "total_computacionales" },
    { title: "Misceláneos", field: "total_miscelaneos" },
    { title: "Topografía y Laboratorios", field: "total_topografia_laboratorios" },
    { title: "Vehículos", field: "total_vehiculos" }
  ]

  return (
    <MaterialTable
      title="Total de activos por Gerencia/Proyecto según tipo de recurso"
      is_loading={isLoading}
      data={activosContratoGerencia}
      columns={columns}
      style={{ borderRadius: "15px" }}
      options={{
        padding: "dense",
        rowStyle:{
          fontSize: "0.7rem"
        },
        headerStyle:{
          fontSize: "0.7rem"
        }
        
      }}
    />
  );

}