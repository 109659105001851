import React, { Fragment } from "react";
import MaterialTable from "../../../../components/materialTable";
import AgregarBien from "../agregar";
import FormularioAsignacion from "../formularioAsignacion";
import { Avatar, Grid, Paper, TextField, Typography, Dialog, DialogContent, IconButton, Box, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Close as CloseIcon } from "@material-ui/icons";
import { useHistory } from "react-router";

function Page(props) {
  const {
    usuario_asignante,
    usuario_asignado,
    bienes,
    bien,
    is_open_1,
    is_open_2,
    handle_paso_1,
    handle_paso_2,
    handle_close,
  } = props;

  const history = useHistory();
  const classes = useStyles();

  let columns = [
    {
      title: "Código",
      field: "codigo_activo",
    }, {
      title: "Marca",
      field: "marca",
    }, {
      title: "Modelo",
      field: "modelo",
    }, {
      title: "Condición Actual",
      field: "condicion_actual",
    }, {
      title: "Clase",
      field: "_clase_ref.nombre",
    },
  ];

  let actions = [
    {
      icon: "chevron_right",
      tooltip: "Ver Activo",
      onClick: (event, row) => history.push(`/activos/${row._id}`),
    }, {
      icon: "add",
      tooltip: "Agregar Activo",
      onClick: (event, row) => handle_paso_1(),
      isFreeAction: true,
    }
  ];

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Paper style={{ padding: 10, borderRadius: "15px" }}>
            <Typography variant="h5" style={{ paddingBottom: 10 }}>Información del Usuario</Typography>
            {!usuario_asignado && (
              <Grid container spacing={1}>
                {/* CARGANDO INFORMACIÓN DE USUARIO */}
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <Skeleton variant="circle" className={classes.large} />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={40} />
                </Grid>
              </Grid>
            )}
            {usuario_asignado && (
              <Grid container spacing={1}>
                {/* INFORMACIÓN DE USUARIO CARGADA */}
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <Avatar alt="Remy Sharp" src={usuario_asignado.photo} className={classes.large} />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="RUN"
                    value={usuario_asignado.run_formateado}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Nombre"
                    value={usuario_asignado.nombre}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Apellido Paterno"
                    value={usuario_asignado.apellido_paterno}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Apellido Materno"
                    value={usuario_asignado.apellido_materno}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Cargo"
                    value={usuario_asignado.cargo}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Gerencia"
                    value={usuario_asignado.gerencia}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Contrato"
                    value={usuario_asignado.contrato}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Email"
                    value={usuario_asignado.contacto.email}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Teléfono"
                    value={usuario_asignado.contacto.telefono}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
        <Grid item xs={8}>
          {/* LISTADO DE BIENES ASIGNADOS */}
          <MaterialTable
            title="Activos Asignados"
            is_loading={!bienes}
            data={bienes}
            columns={columns}
            actions={actions}
          />
        </Grid>
      </Grid>
      {(is_open_1 || is_open_2) && (
        <Dialog
          open={is_open_1 || is_open_2}
          maxWidth="lg"
          fullWidth
        >
          <Box display="flex">
            <Box p={1} pl={3} flexGrow={1}>
              <Typography variant="h6" style={{ margin: 0, padding: 0 }}>
                {is_open_1 && ("Busqueda de Activos")}
                {is_open_2 && ("Formulario de Asignación")}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handle_close}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <DialogContent dividers>
            {is_open_1 && (
              //POPUP CON VISTA PARA BUSCAR BIENES.
              <AgregarBien
                handle_paso_2={handle_paso_2}
                handle_close={handle_close}
              />
            )}
            {is_open_2 && (
              //POPUP CON VISTA PARA LLENAR DATOS DE FORMULARIO.
              <FormularioAsignacion
                usuario_asignante={usuario_asignante}
                bien={bien}
                asignado={usuario_asignado}
                nombre_asignado={usuario_asignado.nombre_completo}
                tipo="persona"
                handle_paso_1={handle_paso_1}
                handle_close={handle_close}
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

export default Page;