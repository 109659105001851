import React, { useCallback, useEffect, useState } from 'react';
import MaterialTable from "../../components/materialTable";
import { getLogsLicenciasSoftware } from '../../services/request/requestLicenciasSoftware';
import { useParams } from 'react-router';
export default function TablaLogLicenciasSoftware() {

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const getData = useCallback(async () => {
    setData([]);
    setIsLoading(true);
    const response = await getLogsLicenciasSoftware({ _licencia_software_ref_eq: id },
      [
        "descripcion", "comentario", "fecha_creacion"
      ], [
      "_usuario_ref.nombre",
      "_usuario_ref.apellido_paterno",
      "_usuario_ref.apellido_materno"
    ]);
    if (response.status) {
      const dataResponse = response.data.map(a => ({
        _id: a._id,
        descripcion: a.descripcion,
        comentario: a.comentario,
        usuario: a._usuario_ref ? a._usuario_ref.nombre + " " + a._usuario_ref.apellido_paterno + " " + a._usuario_ref.apellido_materno : "--",
        fecha_view: a.fecha_creacion
      }));
      setData(dataResponse);
      setIsLoading(false);
    }
  }
    , [id]);

  const actions = [

  ];

  const columns = [
    {
      title: "fecha",
      field: "fecha_view",
      defaultSort: "desc",
      type: 'datetime'
    },
    {
      title: "Usuario",
      field: "usuario",
    },
    {
      title: "Descripción",
      field: "descripcion",
      cellStyle:{
        whiteSpace: "pre-line"
      }
    },
    {
      title: "Comentario",
      field: "comentario",
    }
  ];

  useEffect(() => { getData() }, [getData]);

  return (
    <MaterialTable
      title="Log Histórico"
      is_loading={isLoading}
      columns={columns}
      data={data}
      actions={actions}
      lockheaders={true}
    />
  );
}