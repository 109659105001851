import { Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState, Fragment, useContext } from 'react';
import MaterialTable from '../../components/materialTable';
import { ListadoVehiculosContext } from '../../App';
import Moment from 'moment';
import CustomMiniCards from '../../components/customMiniCards';

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: 'center',
  },
  gridTabla: {
    margin: theme.spacing(0, 0, 1, 0),
    padding: theme.spacing(0, 1, 0, 1),
  },
  gridContainer: {
    marginTop: 5,
  },
}));

const objetoIndicadores = {
  cantidadTotalVehiculos: 0,
  cantidadTotalCyD: 0,
  cantidadTotalLeasing: 0,
};

export default function InformeResumenLicenciaSoftware(props) {
  const { vehiculos } = useContext(ListadoVehiculosContext);
  const [indicadores, setIndicadores] = useState(objetoIndicadores);
  const [datosGerencia, setDatosGerencia] = useState([]);
  const classes = useStyles();

  const gestionDatos = () => {
    if (vehiculos && vehiculos.length > 0) {
      const cantidadCyD = vehiculos.filter((elemento) => elemento._sociedad_ref);
      const cantidadLeasing = vehiculos.filter((elemento) => elemento._sociedad_tercero_ref);

      setIndicadores({
        ...indicadores,
        cantidadTotalVehiculos: vehiculos.length,
        cantidadTotalCyD: cantidadCyD.length,
        cantidadTotalLeasing: cantidadLeasing.length,
      });

      const contratosagrupados = vehiculos.reduce((acumulador, itemActual) => {
        if (itemActual._contrato_ref) {
          const contratoYaExiste = acumulador.find(
            (elemento) => elemento.contrato_codigo === itemActual._contrato_ref.codigo,
          );
          if (contratoYaExiste) {
            return acumulador.map((elemento) => {
              if (elemento.contrato_codigo === itemActual._contrato_ref.codigo) {
                return {
                  ...elemento,
                  cantidadTotal: elemento.cantidadTotal + 1,
                  cantidad_CyD: itemActual._sociedad_ref ? elemento.cantidad_CyD + 1 : elemento.cantidad_CyD,
                  cantidad_Leasing: itemActual._sociedad_tercero_ref
                    ? elemento.cantidad_Leasing + 1
                    : elemento.cantidad_Leasing,
                  cantidad_Camionetas:
                    itemActual._clase_ref.nombre === 'Camionetas'
                      ? elemento.cantidad_Camionetas + 1
                      : elemento.cantidad_Camionetas,
                  cantidad_Furgones:
                    itemActual._clase_ref.nombre === 'Furgones'
                      ? elemento.cantidad_Furgones + 1
                      : elemento.cantidad_Furgones,
                  cantidad_Otro:
                    itemActual._clase_ref.nombre !== 'Camionetas' && itemActual._clase_ref.nombre !== 'Furgones'
                      ? elemento.cantidad_Otro + 1
                      : elemento.cantidad_Otro,
                };
              }
              return elemento;
            });
          }
          return [
            ...acumulador,
            {
              contrato_codigo: itemActual._contrato_ref.codigo,
              contrato_nombre: itemActual._contrato_ref.nombre,
              contrato_fecha_termino: itemActual._contrato_ref ? itemActual._contrato_ref.fecha_termino_estimada : '',
              gerencia_sigla: itemActual._gerencia_ref.sigla,
              gerencia_nombre: itemActual._gerencia_ref.nombre,
              cantidadTotal: 1,
              cantidad_CyD: itemActual._sociedad_ref ? 1 : 0,
              cantidad_Leasing: itemActual._sociedad_tercero_ref ? 1 : 0,
              cantidad_Camionetas: itemActual._clase_ref.nombre === 'Camionetas' ? 1 : 0,
              cantidad_Furgones: itemActual._clase_ref.nombre === 'Furgones' ? 1 : 0,
              cantidad_Otro:
                itemActual._clase_ref.nombre !== 'Camionetas' && itemActual._clase_ref.nombre !== 'Furgones' ? 1 : 0,
            },
          ];
        }
        return [
          ...acumulador,
          {
            contrato_codigo: itemActual._contrato_ref
              ? itemActual._contrato_ref.codigo
              : itemActual._gerencia_ref.sigla,
            contrato_nombre: itemActual._contrato_ref
              ? itemActual._contrato_ref.nombre
              : itemActual._gerencia_ref.nombre,
            contrato_fecha_termino: itemActual._contrato_ref ? itemActual._contrato_ref.fecha_termino_estimada : '',
            gerencia_sigla: itemActual._gerencia_ref.sigla,
            gerencia_nombre: itemActual._gerencia_ref.nombre,
            cantidadTotal: 1,
            cantidad_CyD: itemActual._sociedad_ref ? 1 : 0,
            cantidad_Leasing: itemActual._sociedad_tercero_ref ? 1 : 0,
            cantidad_Camionetas: itemActual._clase_ref.nombre === 'Camionetas' ? 1 : 0,
            cantidad_Furgones: itemActual._clase_ref.nombre === 'Furgones' ? 1 : 0,
            cantidad_Otro:
              itemActual._clase_ref.nombre !== 'Camionetas' && itemActual._clase_ref.nombre !== 'Furgones' ? 1 : 0,
          },
        ];
      }, []);

      const gerenciasAgrupadas = contratosagrupados.reduce((acumulador, itemActual) => {
        const gerenciaYaExiste = acumulador.find((elemento) => elemento.gerencia_sigla === itemActual.gerencia_sigla);
        if (gerenciaYaExiste) {
          return acumulador.map((elemento) => {
            if (elemento.gerencia_sigla === itemActual.gerencia_sigla) {
              return {
                ...elemento,
                cantidadTotal: elemento.cantidadTotal + itemActual.cantidadTotal,
                cantidad_CyD: elemento.cantidad_CyD + itemActual.cantidad_CyD,
                cantidad_Leasing: elemento.cantidad_Leasing + itemActual.cantidad_Leasing,
                cantidad_Camionetas: elemento.cantidad_Camionetas + itemActual.cantidad_Camionetas,
                cantidad_Furgones: elemento.cantidad_Furgones + itemActual.cantidad_Furgones,
                cantidad_Otro: elemento.cantidad_Otro + itemActual.cantidad_Otro,
              };
            }
            return {
              ...elemento,
              detalleAgrupado: contratosagrupados.filter(
                (gerencia) => gerencia.gerencia_sigla === elemento.gerencia_sigla,
              ),
            };
          });
        }
        return [
          ...acumulador,
          {
            gerencia_sigla: itemActual.gerencia_sigla,
            gerencia_nombre: itemActual.gerencia_nombre,
            cantidadTotal: itemActual.cantidadTotal,
            cantidad_CyD: itemActual.cantidad_CyD,
            cantidad_Leasing: itemActual.cantidad_Leasing,
            cantidad_Camionetas: itemActual.cantidad_Camionetas,
            cantidad_Furgones: itemActual.cantidad_Furgones,
            cantidad_Otro: itemActual.cantidad_Otro,
            detalleAgrupado: contratosagrupados.filter(
              (gerencia) => gerencia.gerencia_sigla === itemActual.gerencia_sigla,
            ),
          },
        ];
      }, []);

      setDatosGerencia(gerenciasAgrupadas);
    }
  };

  useEffect(() => {
    gestionDatos();
  }, [vehiculos]);

  const columns = [
    {
      title: 'Gerencia',
      field: 'gerencia_sigla',
      align: 'center',
    },
    {
      title: 'Nombre',
      field: 'gerencia_nombre',
      align: 'center',
    },
    {
      title: 'Total Vehículos',
      field: 'cantidadTotal',
      align: 'center',
    },
    {
      title: 'Vehículos CyD',
      field: 'cantidad_CyD',
      align: 'center',
    },
    {
      title: 'Vehículos en Leasing',
      field: 'cantidad_Leasing',
      align: 'center',
    },
    {
      title: 'Total Camionetas',
      field: 'cantidad_Camionetas',
      align: 'center',
    },
    {
      title: 'Total Furgones',
      field: 'cantidad_Furgones',
      align: 'center',
    },
    {
      title: 'Total Otros',
      field: 'cantidad_Otro',
      align: 'center',
    },
  ];

  return (
    <Fragment>
      <Grid container spacing={1} className={classes.gridContainer} alignContent="center" justify="center">
        <Grid item xs={2}>
          <CustomMiniCards title="Cantidad Total de Vehículos">
            <Typography variant="h5" component="h2" align="center">
              {indicadores.cantidadTotalVehiculos}
            </Typography>
          </CustomMiniCards>
        </Grid>
        <Grid item xs={2}>
          <CustomMiniCards title="Cantidad Vehículos TAL">
            <Typography variant="h5" component="h2" align="center">
              {indicadores.cantidadTotalCyD}
            </Typography>
          </CustomMiniCards>
        </Grid>
        <Grid item xs={2}>
          <CustomMiniCards title="Cantidad Vehículos en Leasing">
            <Typography variant="h5" component="h2" align="center">
              {indicadores.cantidadTotalLeasing}
            </Typography>
          </CustomMiniCards>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MaterialTable
            title="Flota de Vehículos por Gerencias"
            is_loading={!datosGerencia}
            lockheaders={true}
            columns={columns}
            data={datosGerencia}
            searching={false}
            columnsButton={false}
            grouping={false}
            filtering={false}
            showTitle={true}
            exportButton={false}
            options={{
              padding: 'default',
              showTitle: false,
              headerStyle: {
                fontWeight: 'bold',
                color: '#731f1f',
                textAlign: 'center',
                fontSize: '15px',
              },
            }}
            detailPanel={(rowData) => {
              return (
                <MaterialTable
                  title={'Detalle Contratos ' + rowData.gerencia_nombre}
                  grouping={false}
                  options={{
                    padding: 'dense',
                    paging: false,
                    search: false,
                    headerStyle: {
                      fontWeight: 'bold',
                    },
                    rowStyle: {
                      height: '15px',
                    },
                  }}
                  columns={[
                    {
                      title: 'Código Contrato',
                      field: 'contrato_codigo',
                      align: 'center',
                    },
                    {
                      title: 'Nombre Contrato',
                      field: 'contrato_nombre',
                      align: 'center',
                    },
                    {
                      title: 'Fecha de Término',
                      field: 'contrato_fecha_termino',
                      align: 'center',
                      render: (row) =>
                        row.contrato_fecha_termino ? Moment(row.contrato_fecha_termino).format('DD/MM/YYYY') : '--',
                    },
                    {
                      title: 'Total Vehículos',
                      field: 'cantidadTotal',
                      align: 'center',
                    },
                    {
                      title: 'Vehículos CyD',
                      field: 'cantidad_CyD',
                      align: 'center',
                    },
                    {
                      title: 'Vehiculos en Leasing',
                      field: 'cantidad_Leasing',
                      align: 'center',
                    },
                    {
                      title: 'Total Camionetas',
                      field: 'cantidad_Camionetas',
                      align: 'center',
                    },
                    {
                      title: 'Total Furgones',
                      field: 'cantidad_Furgones',
                      align: 'center',
                    },
                    {
                      title: 'Total Otros',
                      field: 'cantidad_Otro',
                      align: 'center',
                    },
                  ]}
                  data={rowData.detalleAgrupado}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
