import React, { useState, Fragment, useCallback, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import MaterialTable from '../../components/materialTable';
import { MTableAction } from '@material-table/core';
import { Button } from '@material-ui/core';
import { getActaEntregaVehiculos } from '../../services/request/requestActaEntregaVehiculo';
import DialogoActaEntrega from './dialogoActaEntrega';
import useSWR from 'swr';
import Moment from 'moment';
import { MainContext } from '../../App';
import * as PERMISSIONS from '../../constants/permissions';

function getData() {
  return getActaEntregaVehiculos(
    {},
    [
      '_vehiculo_ref',
      '_bien_ref',
      '_bien_ref',
      '_entregado_ref',
      '_recibido_ref',
      '_usuario_final_ref',
      '_contrato_origen_ref',
      '_contrato_destino_ref',
      'fecha_creacion',
      'kilometraje',
      'motivo',
      'descripcion',
    ],
    [
      '_vehiculo_ref.patente',
      '_vehiculo_ref.tipo_combustible',
      '_bien_ref.codigo_activo',
      '_bien_ref.marca',
      '_bien_ref.modelo',
      '_vehiculo_ref.transmision',
      '_vehiculo_ref.color',
      '_bien_ref._id',
      '_entregado_ref.nombre',
      '_recibido_ref.nombre',
      '_usuario_final_ref.nombre',
      '_entregado_ref.apellido_paterno',
      '_recibido_ref.apellido_paterno',
      '_usuario_final_ref.apellido_paterno',
      '_contrato_origen_ref.codigo',
      '_contrato_origen_ref.nombre',
      '_contrato_destino_ref.codigo',
      '_contrato_destino_ref.nombre',
    ],
  );
}

function TablaActasEntrega() {
  const { permisos } = useContext(MainContext);
  const [activoSeleccionado, setActivoSeleccionado] = useState(null);
  const [OpenDialogoActaEntrega, setOpenDialogoActaEntrega] = useState(false);
  const [datosActasEntrega, setDatosActasEntrega] = useState([]);
  const { data: response } = useSWR('acta-entrega', (key) => getData());
  const history = useHistory();

  const loadData = useCallback(async () => {
    setDatosActasEntrega([]);

    if (response) {
      if (response.status) {
        setDatosActasEntrega(response.data);
      }
    }
  }, [response]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  function handleOpenDialogoActaEntrega(seleccionado, open) {
    setActivoSeleccionado(seleccionado);
    setOpenDialogoActaEntrega(open);
  }

  const columns = [
    {
      title: 'Patente',
      field: '_vehiculo_ref.patente',
      align: 'center',
    },
    {
      title: 'Código Activo',
      field: '_bien_ref.codigo_activo',
      align: 'center',
    },
    {
      title: 'Marca',
      field: '_bien_ref.marca',
      align: 'center',
    },
    {
      title: 'Modelo',
      field: '_bien_ref.modelo',
      align: 'center',
    },
    {
      title: 'Transmisión',
      field: '_vehiculo_ref.transmision',
      align: 'center',
      hidden: true,
    },
    {
      title: 'Kilometraje',
      field: 'kilometraje',
      align: 'center',
      render: (row) => (row ? `${Intl.NumberFormat('es-ES').format(row.kilometraje)} Kms.` : ''),
    },
    {
      title: 'Fecha Acta',
      field: 'fecha_creacion',
      align: 'center',
      render: (row) => (row.fecha_creacion ? Moment(row.fecha_creacion).format('DD/MM/YYYY') : '--'),
    },
    {
      title: 'Entregado Por',
      field: '_entregado_ref.nombre',
      align: 'center',
    },
    {
      title: 'Recibido Por',
      field: '_recibido_ref.nombre',
      align: 'center',
    },
    {
      title: 'Usuario Asignado',
      field: '_usuario_final_ref.nombre',
      align: 'center',
    },
    {
      title: 'Contrato Origen',
      field: '_contrato_origen_ref.nombre',
      align: 'center',
    },
    {
      title: 'Contrato Destino',
      field: '_contrato_destino_ref.nombre',
      align: 'center',
    },
    {
      title: 'Motivo',
      field: 'motivo',
      align: 'center',
    },
    {
      title: 'Descripción',
      field: 'descripcion',
      align: 'center',
      hidden: true,
    },
  ];

  const actions = [
    {
      icon: 'chevron_right',
      tooltip: 'Ver Detalles',
      onClick: (event, rowData) => history.push(`activos/${rowData._bien_ref._id}`),
    },
    {
      icon: 'visibility',
      tooltip: 'Ver registro',
      hidden: !permisos[PERMISSIONS.VER_BOTON_ACTA_ENTREGA_VEHICULO],
      onClick: (event, rowData) => handleOpenDialogoActaEntrega(rowData, true),
    },
    {
      icon: 'acta_entrega',
      tooltip: 'Registro Acta/Entrega',
      position: 'row',
      hidden: !permisos[PERMISSIONS.VER_BOTON_ACTA_ENTREGA_VEHICULO],
      onClick: (event, row) => handleOpenDialogoActaEntrega(null, true),
      isFreeAction: true,
    },
  ];

  return (
    <Fragment>
      <MaterialTable
        title="Registros de Actas de Entrega"
        is_loading={!datosActasEntrega}
        fullWidth
        data={datosActasEntrega}
        columns={columns}
        grouping={false}
        actions={actions}
        components={{
          Action: (propData) => {
            const rowData = propData.data;
            if (propData.action.icon === 'acta_entrega') {
              return (
                !propData.action.hidden && (
                  <Button
                    onClick={(event) => propData.action.onClick(event, rowData)}
                    color="primary"
                    style={{
                      color: 'white',
                      backgroundColor: '#731f1f',
                      marginLeft: 5,
                      textTransform: 'none',
                    }}
                    variant="contained"
                    size="small"
                  >
                    {'Registro Acta/Entrega'}
                  </Button>
                )
              );
            }
            return <MTableAction {...propData} />;
          },
        }}
      />

      {/* POPUP PARA MOSTRAR DIALOGO DE REGISTRO ENTREGA */}
      <DialogoActaEntrega
        activo_seleccionado={activoSeleccionado}
        is_open={OpenDialogoActaEntrega}
        handle_open={handleOpenDialogoActaEntrega}
      />
    </Fragment>
  );
}

export default TablaActasEntrega;
