import React, { useContext, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import FileInput from '../../../components/fileInput';
import { MainContext } from '../../../App';
import { ListadoActivosContext } from '.';
import { putBien } from '../../../services/request/requestBienes';
import { GenerarLog } from '../../../services/activoLog';
import { uploadFileToCloudStorage } from '../../../services/fileUpload';
import { GAF_BODEGA_ID, GERENCIA_GAF_ID } from '../../../constants/gerencias_contratos';

export default function DialogBaja(props) {
  const { activo_seleccionado, is_open, handle_open } = props;

  const [File, SetFile] = useState(null);
  const [Motivo, SetMotivo] = useState('');
  const { usuarioSesion } = useContext(MainContext);
  const { data, setData } = useContext(ListadoActivosContext);
  const notistack = useSnackbar();

  /**
   * Método encargado de dar de baja un activo.
   */
  async function Baja() {
    try {
      notistack.enqueueSnackbar('Dando de baja el activo...', {
        variant: 'info',
        persist: true,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
      });
      let uploadedFile = null;
      if (File) {
        uploadedFile = await uploadFileToCloudStorage(File, `activos/${activo_seleccionado.codigo_activo}`, File.name);
      }
      let datosActualizacion = {
        is_eliminado: true,
        fecha_baja: new Date(),
        motivo_baja: Motivo,
        adjunto_baja: uploadedFile,
        _asignado_ref: null,
        _gerencia_ref: GERENCIA_GAF_ID,
        _contrato_ref: GAF_BODEGA_ID,
        asignado_por: usuarioSesion.nombre,
      };
      let registroLogBaja = {
        _bien_ref: activo_seleccionado._id,
        titulo: 'Baja Contable',
        cuerpo: `El usuario ${usuarioSesion.nombre} realizó la baja del activo ${activo_seleccionado.codigo_activo}.`,
        tipo: 'solicitud_baja',
        icon: 'check_circle_outline',
      };
      let registroLogAsignacion = {
        _bien_ref: activo_seleccionado._id,
        titulo: 'Asignación de Activo',
        cuerpo: `El activo ${activo_seleccionado.codigo_activo} ha sido asignado a GAF-7 Bodega automáticamente por el sistema debido a la Baja Contable.`,
        tipo: 'asignacion',
        icon: 'check',
      };
      await Promise.all([
        putBien(activo_seleccionado._id, datosActualizacion),
        GenerarLog(registroLogBaja),
        GenerarLog(registroLogAsignacion),
      ]);
      notistack.closeSnackbar();

      let bienes = [...data];
      let bien = bienes.find((bien) => bien._id === activo_seleccionado._id);
      bien.is_eliminado = true;
      bien.fecha_baja = new Date();
      bien.motivo_baja = Motivo;
      bien.adjunto_baja = uploadedFile;
      bien._asignado_ref = '--';
      bien.rut_usuario = '--';
      bien.mail_usuario = '--';
      bien._gerencia_ref = 'Gerencia de Administración y Finanzas';
      bien.gerencia_id = GERENCIA_GAF_ID;
      bien._contrato_ref = 'Bodega GAF';
      bien.codigo_contrato = 'GAF-7';
      bien.contrato_id = GAF_BODEGA_ID;
      setData(bienes);

      notistack.enqueueSnackbar('Operanción exitosa: Activo dado de baja y asignado a GAF-7 Bodega', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (key) => (
          <IconButton onClick={() => notistack.closeSnackbar(key)}>
            <Close />
          </IconButton>
        ),
      });
    } catch (e) {
      console.error(e);
      notistack.closeSnackbar();
      notistack.enqueueSnackbar(
        'Error al intentar dar de baja el activo. Intente de nuevo o contacte con el equipo de soporte.',
        {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (key) => (
            <IconButton onClick={() => notistack.closeSnackbar(key)}>
              <Close />
            </IconButton>
          ),
        },
      );
    } finally {
      handleClose();
    }
  }

  /**
   * Método encargado de cerrar el popup y limpiar los datos.
   */
  const handleClose = () => {
    handle_open(null, false);
    SetMotivo('');
    SetFile(null);
  };

  return (
    <Dialog open={is_open} onClose={handleClose}>
      <DialogTitle>Baja del Activo</DialogTitle>
      <DialogContent dividers>
        <Typography>Formulario para dar de baja un activo.</Typography>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <FormControl fullWidth>
              <FileInput
                inputName="file"
                buttonColor="primary"
                buttonVariant="outlined"
                label="Respaldo de baja"
                value={File}
                handleOnChange={(e) => SetFile(e.target.files[0])}
                textVariant="outlined"
              />
              {activo_seleccionado?.is_activo_contable && (
                <FormHelperText style={{ paddingLeft: 15 }}>Requerido si el recurso esta activado</FormHelperText>
              )}
              {!activo_seleccionado?.is_activo_contable && (
                <FormHelperText style={{ paddingLeft: 15 }}>Opcional</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              name="motivo"
              label="Razón de baja"
              fullWidth
              variant={'outlined'}
              margin={'normal'}
              value={Motivo}
              rows={2}
              multiline={true}
              required
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => SetMotivo(e.target.value)}
              helperText="Requerido"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClose}>
          No
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => Baja(activo_seleccionado)}
          disabled={CheckButtonDisabled(Motivo, activo_seleccionado, File)}
        >
          Sí
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * Método encargado de verificar si el botón está habilitado o deshabilitado.
 * @param {*} motivo Motivo para activar el bien.
 * @param {*} activo Datos del activo seleccionado.
 * @param {*} file Archivo de respaldo.
 * @returns FLAG que habilita o deshabilita el botón.
 */
function CheckButtonDisabled(motivo, activo, file) {
  if (activo && activo.is_activo_contable && !file) {
    return true;
  }
  if (!motivo) {
    return true;
  }
  if (motivo.length < 3) {
    return true;
  }
  return false;
}
