import React, { createContext, useState } from 'react';
import MaterialTable from '../../../components/materialTable';
import useSWR from 'swr';
import { Fragment } from 'react';
import { useHistory } from 'react-router';
import { AJUSTES_CONFIGURACION } from '../../../constants/routes';
import {
  getAseguradoras,
  postAseguradora,
  putAseguradora,
  deleteAseguradora,
} from '../../../services/request/requestAseguradoras';
import DialogoCRUDAseguradoras from './dialogoCRUDAseguradoras';

export const TablaAseguradorasContext = createContext({
  openDialogoCRUD: false,
  setOpenDialogoCRUD: () => {},
  tituloDialogo: '',
  setTituloDialogo: () => {},
  initialValues: '',
  setInitialValues: () => {},
  handleAceptar: () => {},
  disableEditing: false,
  setDisableEditing: () => {},
});

function getData() {
  return getAseguradoras({}, ['aseguradora', '_id', 'descripcion'], []).then((aseguradora) => {
    return aseguradora;
  });
}

export default function TablaAseguradoras() {
  const { data: aseguradoras, mutate } = useSWR('get:aseguradoras', (key) => getData(), { refreshInterval: 0 });
  const [openDialogoCRUD, setOpenDialogoCRUD] = useState(false);
  const [tituloDialogo, setTituloDialogo] = useState('');
  const [initialValues, setInitialValues] = useState({
    aseguradora: '',
    descripcion: '',
  });
  const [disableEditing, setDisableEditing] = useState(false);
  const history = useHistory();

  const handleOpenAgregar = (titulo) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      aseguradora: '',
      descripcion: '',
    });
    setDisableEditing(false);
  };

  const handleOpenModificar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      aseguradora: row.aseguradora,
      descripcion: row.descripcion,
      id: row._id,
    });
    setDisableEditing(false);
  };

  const handleOpenQuitar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      aseguradora: row.aseguradora,
      descripcion: row.descripcion,
      id: row._id,
    });
    setDisableEditing(true);
  };

  const handleAceptar = async (values) => {
    if (tituloDialogo) {
      if (tituloDialogo.includes('Nuev')) {
        await postAseguradora({ ...values });
      } else if (tituloDialogo.includes('Modificar'))
        await putAseguradora(values.id, {
          aseguradora: values.aseguradora,
          descripcion: values.descripcion,
        });
      else if (tituloDialogo.includes('Eliminar')) await deleteAseguradora(values.id);
    }
    mutate();
    setOpenDialogoCRUD(false);
  };

  const handleVolver = () => {
    history.push(AJUSTES_CONFIGURACION);
  };

  const columnsSociedades = [
    {
      title: 'ID',
      field: '_id',
      align: 'center',
    },
    {
      title: 'Empresa Aseguradora',
      field: 'aseguradora',
      align: 'center',
      defaultSort: 'asc',
    },
    {
      title: 'Descripción',
      field: 'descripcion',
      align: 'center',
    },
  ];

  const actions = [
    {
      tooltip: 'Agregar Aseguradora',
      icon: 'add',
      onClick: (event, row) => handleOpenAgregar('Nueva Sociedad'),
      isFreeAction: true,
    },
    {
      tooltip: 'Editar',
      icon: 'edit',
      onClick: (event, row) => handleOpenModificar('Modificar Sociedad', row),
    },
    {
      tooltip: 'Eliminar',
      icon: 'delete',
      onClick: (event, row) => handleOpenQuitar('Eliminar Sociedad', row),
    },
    {
      tooltip: 'Volver',
      icon: 'arrow_back',
      onClick: (event, row) => handleVolver(),
      isFreeAction: true,
    },
  ];

  return (
    <Fragment>
      <TablaAseguradorasContext.Provider
        value={{
          openDialogoCRUD,
          setOpenDialogoCRUD,
          tituloDialogo,
          setTituloDialogo,
          initialValues,
          setInitialValues,
          handleAceptar,
          disableEditing,
          setDisableEditing,
        }}
      >
        <MaterialTable
          title="Listado de Empresas Aseguradoras"
          is_loading={!aseguradoras}
          data={aseguradoras && aseguradoras.data}
          columns={columnsSociedades}
          actions={actions}
          filtering={false}
          grouping={false}
          exportButtonPDF={true}
          exportButtonExcel={true}
          empty_rows={true}
          page_size={20}
        />
        <DialogoCRUDAseguradoras />
      </TablaAseguradorasContext.Provider>
    </Fragment>
  );
}
