import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useCallback, useContext, useEffect } from 'react';
import { TablaTiposSegurosContext } from './tablaTiposSeguros';
import * as yup from 'yup';

const validationSchema = yup.object({
  nombre: yup.string().required(),
});

export default function DialogoCRUDTipoSeguro() {
  const { openDialogoCRUD, setOpenDialogoCRUD, tituloDialogo, handleAceptar, disableEditing, initialValues } =
    useContext(TablaTiposSegurosContext);

  const handleClose = () => setOpenDialogoCRUD(false);

  const formik = useFormik({
    initialValues,
    onSubmit: handleAceptar,
    validationSchema,
  });

  const { values, errors, touched, handleSubmit, handleChange, resetForm } = formik;

  const resetFormik = useCallback(() => {
    resetForm({ values: initialValues });
  }, [initialValues, resetForm]);

  useEffect(() => {
    resetFormik();
  }, [resetFormik]);

  return (
    <Dialog open={openDialogoCRUD} maxWidth="md" fullWidth>
      <DialogTitle>{tituloDialogo}</DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                name="nombre"
                label="Nombre"
                value={values.nombre}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                disabled={disableEditing}
                error={errors.nombre && touched.nombre ? true : false}
                helperText={errors.nombre && touched.nombre ? errors.nombre : ''}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
