import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { postProveedorSDA, getComunasSDA, getProveedoresSDA } from '../../../services/request/sda';
import MaterialReactSelect from "../../../components/materialReactSelect";
import formatNumber from 'format-number';
import { useSnackbar } from "notistack";
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { postProveedor } from '../../../services/request/requestTipoProveedores';

const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });

const validationSchema = yup.object({
  direccion: yup.string().required('Debe ingresar una dirección'),
  nombre: yup.string().required('Debe ingresar un nombre'),
  rut: yup.string().required('Debe ingresar un RUT'),
  telefono: yup.string().required('Debe ingresar un número de teléfono'),
  email_envio_oc: yup.string().email("Email no válido").required('Debe ingresar un email de contacto'),
  // ciudad_id: yup.string().required('Debe ingresar una ciudad'),
  // comuna_id: yup.string().required('Debe ingresar una comuna ')
  // comuna: yup.string().required('Debe ingresar una comuna ')
});

export default function DialogoAgregarProveedor(props) {

  const { closeDialog, open, onSubmit } = props;

  const [comunas, setComunas] = useState([]);
  const notistack = useSnackbar();
  const formik = useFormik({
    initialValues: {
      direccion: "",
      nombre: "",
      rut: "",
      telefono: "",
      email_envio_oc: "",
      comuna: ""
      // ciudad_id: "",
      // comuna_id: ""
    },
    validationSchema: validationSchema,
    onSubmit: async function (values) {
      console.log(values);
      const key = notistack.enqueueSnackbar("Guardando proveedor...", {
        persist: true,
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        }
      });
      try {
        const data = {
          direccion: values.direccion,
          nombre: values.nombre,
          rut: values.rut,
          telefono: values.telefono,
          email_envio_oc: values.email_envio_oc
        }
        if (values.comuna) {
          data["comuna_id"] = values.comuna.value.id;
          data["ciudad_id"] = values.comuna.value.ciudad_id;
        }
        const proveedores = await getProveedoresSDA();
        const proveedorIngresado = proveedores.find(a => (a.nombre === data.nombre && a.rut === data.rut));
        if (proveedorIngresado) {
          notistack.closeSnackbar(key);
          const errorkey = notistack.enqueueSnackbar("Error: El proveedor ya se encuentra ingresado", {
            variant: 'warning',
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom"
            },
            action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
          });
        }
        else {
          await postProveedorSDA(data);
          await postProveedor({ rut: data.rut, nombre: data.nombre, direccion: data.direccion });
          if (onSubmit) onSubmit();
          notistack.closeSnackbar(key);
          const successkey = notistack.enqueueSnackbar("Operanción exitosa: Proveedor creado", {
            variant: 'success',
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom"
            },
            action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
          });
          closeDialog();
        }
      }
      catch (e) {
        console.log(e);
        notistack.closeSnackbar(key);
        const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible guardar el proveedor", {
          variant: 'error',
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
        });
      }

    }
  });

  const { values, handleChange, touched, errors, handleSubmit, resetForm } = formik;

  async function onEnter() {
    const response = await getComunasSDA();
    setComunas(response);
    resetForm();
  }



  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      maxWidth="lg"
      fullWidth
      onEntered={onEnter}
    >
      <DialogTitle>Agregar nuevo proveedor</DialogTitle>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <TextField
                name="nombre"
                label="Nombre"
                fullWidth
                variant={"outlined"}
                margin={"normal"}
                value={values.nombre}
                onChange={handleChange}
                error={touched.nombre && errors.nombre && Boolean(errors.nombre)}
                helperText={touched.nombre && errors.nombre ? errors.nombre : ""}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <TextField
                name="rut"
                label="RUT"
                fullWidth
                variant={"outlined"}
                margin={"normal"}
                value={values.rut}
                onChange={(e) => {
                  if (e.target.value) {
                    let raw = e.target.value;
                    raw = raw.replace(/[^0-9kK]/g, "");
                    if (raw.length > 1) {
                      let verificador = raw[raw.length - 1];
                      let cuerpo = raw.substring(0, raw.length - 1);
                      let rut = numberFormater(cuerpo) + "-" + verificador;
                      formik.setFieldValue("rut", rut);
                    } else {
                      formik.setFieldValue("rut", raw);
                    }
                  } else {
                    handleChange(e);
                  }
                }}
                error={touched.rut && errors.rut && Boolean(errors.rut)}
                helperText={touched.rut && errors.rut ? errors.rut : ""}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <TextField
                name="direccion"
                label="Dirección"
                fullWidth
                variant={"outlined"}
                margin={"normal"}
                value={values.direccion}
                onChange={handleChange}
                error={touched.direccion && errors.direccion && Boolean(errors.direccion)}
                helperText={touched.direccion && errors.direccion ? errors.direccion : ""}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <MaterialReactSelect
                label="Comuna"
                isClearable
                onChange={(newValue, actionMeta) => newValue ? formik.setFieldValue("comuna", newValue) : formik.setFieldValue("comuna", "")}
                placeholder="Seleccionar un Comuna"
                options={comunas && comunas.map((value, index) => ({
                  value: value,
                  label: value.nombre
                }))}
                noOptionsMessage={() => "No ha encontrado la comuna"}
                error={touched.comuna && errors.comuna && Boolean(errors.comuna)}
                helperText={touched.comuna && errors.comuna ? errors.comuna : ""}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <TextField
                name="telefono"
                label="Teléfono"
                fullWidth
                variant={"outlined"}
                margin={"normal"}
                value={values.telefono}
                onChange={handleChange}
                error={touched.telefono && errors.telefono && Boolean(errors.telefono)}
                helperText={touched.telefono && errors.telefono ? errors.telefono : ""}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <TextField
                name="email_envio_oc"
                label="Email"
                fullWidth
                variant={"outlined"}
                margin={"normal"}
                value={values.email_envio_oc}
                onChange={handleChange}
                error={touched.email_envio_oc && errors.email_envio_oc && Boolean(errors.email_envio_oc)}
                helperText={touched.email_envio_oc && errors.email_envio_oc ? errors.email_envio_oc : ""}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary" variant="contained"> Aceptar</Button>
          <Button variant="outlined" color="primary" onClick={closeDialog}>Cancelar</Button>
        </DialogActions>
      </form>
    </Dialog>
  );

}