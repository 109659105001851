import React from 'react';
import Creatable from 'react-select/creatable';
import { components } from 'react-select';
import { InputLabel, makeStyles, FormControl, FormHelperText } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

const customStyles = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  control: base => ({
    ...base,
    height: "54.88px",
    minHeight: "54.88px"
  })
};

const useStyles = makeStyles(theme => ({
  reactSelect: {
    padding: "9px"
  },
  reactSelectLabel: {
    zIndex: "1",
    top: "-8px",
    backgroundColor: "white",
    left: "10px",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }
}));

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDown />
    </components.DropdownIndicator>
  );
};


export default function MaterialCreatable(props) {

  const classes = useStyles();
  const { label = null, error = false, helperText = "", margin="normal" } = props;

  return (

    <FormControl
      margin={margin}
      fullWidth
      error={error}
    >
      {label && <InputLabel className={classes.reactSelectLabel} shrink>{label}</InputLabel>}
      <Creatable
        {...props}
        components={{ DropdownIndicator }}
        styles={customStyles}
        menuPortalTarget={document.body}
        formatCreateLabel={() => "Crear nuevo"}
      />
      <FormHelperText>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
}