import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { ListadoActivosContext } from '.';
import { MainContext } from '../../../App';
import MaterialReactSelect from '../../../components/materialReactSelect';
import { getContratos } from '../../../services/request/requestPortafolio';
import { getClases } from '../../../services/request/requestClase';
import TablaResultadoActivos from './tablaResultados';
import TablaSeleccionados from './tablaSeleccionados';
import TablaSeleccionadosBajaMasiva from './tablaSeleccionadosBajaMasiva';
import {
  VER_SELECCION_GERENCIAS,
  VER_SELECCION_CONTRATOS,
  VER_CONTRATOS_GERENCIA,
  VER_BAJA_LISTADO,
} from '../../../constants/permissions';

function isEmpty(object) {
  for (const key in object) {
    return false;
  }
  return true;
}

const useStyles = makeStyles((theme) => ({
  main: {
    // overflow: "auto",
    maxWidth: '95vw',
    // maxWidth: "100%",
    // margin: theme.spacing(1, 0, 1, 0),
    padding: theme.spacing(0),
  },
  paper: {
    padding: theme.spacing(1, 2, 0, 1),
    borderRadius: '15px',
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'end',
  },
  btnMargin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function Page(props) {
  const classes = useStyles();
  const {
    setFiltrosListaActivos,
    initialValuesFiltroActivos,
    setInitialValuesFiltroActivos,
    filtrosListaActivos,
    usuarioSesion,
    listadoContratosACargo,
    permisos,
  } = useContext(MainContext);

  const [clases, setClases] = useState([]);
  const [contratos, setContratos] = useState([]);
  const {
    triggerSearch,
    data,
    OpenSeleccionados,
    OpenSeleccionadosBaja,
    SetOpenSeleccionados,
    SetOpenSeleccionadosBaja,
    setData,
    setMostrarResultado,
    tiposRecursos,
    gerencias,
  } = useContext(ListadoActivosContext);

  const handleCloseList = () => {
    SetOpenSeleccionados(false);
    SetOpenSeleccionadosBaja(false);
  };

  const formik = useFormik({
    initialValues: initialValuesFiltroActivos,
    onSubmit: (values) => {
      const filter = {};

      if (values.gerencia) {
        filter['_gerencia_ref_eq'] = values.gerencia.value;
      } else {
        if (!permisos[VER_SELECCION_GERENCIAS]) {
          filter['_gerencia_ref_in'] = gerencias.map((a) => a._id);
        }
      }
      if (values.contrato) filter['_contrato_ref_eq'] = values.contrato.value;
      if (values.codigo_activo) filter['codigo_activo_contains'] = values.codigo_activo;
      if (values.numero_factura) filter['activo.factura.numero_contains'] = values.numero_factura;
      if (values.numero_serie) filter['serial_number_contains'] = values.numero_serie;
      if (values.tipo) {
        filter['_clase_ref_in'] = clases.map((a) => a._id);
        if (values.clase) filter['_clase_ref_eq'] = values.clase.value;
      }
      if (values.activado === 'Sin activar') filter['is_activo_contable_eq'] = false;
      if (values.activado === 'Activado') filter['is_activo_contable_eq'] = true;
      if (values.baja === 'Dado de baja') filter['is_eliminado_eq'] = true;
      if (values.baja === 'Vigentes') filter['is_eliminado_eq'] = false;
      // if (values.asignado === "Asignado") filter["_asignado_ref_ne"] = "null";
      // if (values.asignado === "Sin asignar") filter["_asignado_ref_eq"] = "null";

      if (values.asignado) filter['asignado'] = values.asignado;
      if (isEmpty(filter)) triggerSearch(filter);
      else setFiltrosListaActivos(filter);
      setInitialValuesFiltroActivos(values);
      // triggerSearch(filter, values.asignado);
    },
  });

  const { handleSubmit, setFieldValue, values, errors, touched, handleChange, resetForm } = formik;

  const getContratosGerencia = async (gerencia) => {
    getContratos({ gerencia_ref: gerencia }).then((response) => {
      if (response.status === 200) {
        setContratos(response.data);
      } else if (response.status === 204) {
        setContratos([]);
      } else {
        setContratos([]);
      }
    });
  };

  const getClasesTipo = async (tipo) => {
    getClases({ _tipo_recurso_ref_eq: tipo }).then((response) => {
      if (response.status) {
        setClases(response.data);
      } else {
        setClases([]);
      }
    });
  };

  const resetFormik = useCallback(() => {
    resetForm({ values: initialValuesFiltroActivos });
  }, [resetForm, initialValuesFiltroActivos]);

  useEffect(() => {
    resetFormik();
  }, [resetFormik]);

  useEffect(() => {
    if (!isEmpty(filtrosListaActivos)) triggerSearch(filtrosListaActivos);
  }, [filtrosListaActivos]);

  useEffect(() => {
    if (usuarioSesion && usuarioSesion.gerencia && gerencias && gerencias.length > 0) {
      const gerencia = gerencias.find((a) => a?._id === usuarioSesion.gerencia);
      //Se setea automáticamente la gerencia del usuario en el desplegable de Gerencias
      if (gerencia) {
        //se comprueba si posee el permiso para desbloquear el desplegable de gerencias
        if (!permisos[VER_SELECCION_GERENCIAS]) {
          //Si no posee el permiso, se setea de forma predeterminada la lista de gerencias
          setFieldValue('gerencia', {
            value: gerencia._id,
            label: gerencia.sigla + ' ' + gerencia.nombre,
            gerencia,
          });

          if (!permisos[VER_CONTRATOS_GERENCIA]) {
            //Si posee 1 o mas contratos a cargo, se setea el primero en el desplegable de los contratos
            if (listadoContratosACargo && listadoContratosACargo.length > 0) {
              setFieldValue('contrato', {
                value: listadoContratosACargo[0]._id,
                label: listadoContratosACargo[0].codigo + ' ' + listadoContratosACargo[0].nombre,
                contrato: listadoContratosACargo[0],
              });
              setContratos(listadoContratosACargo);
            } else {
              if (!permisos[VER_SELECCION_CONTRATOS]) {
                if (usuarioSesion.contrato) {
                  getContratos({ _id: usuarioSesion.contrato }).then((response) => {
                    if (response.status === 200) {
                      const contrato = response.data[0];

                      if (contrato) {
                        setFieldValue('contrato', {
                          value: contrato._id,
                          label: contrato.codigo + ' ' + contrato.nombre,
                          contrato,
                        });
                      }
                      setContratos(response.data);
                    } else if (response.status === 204) {
                      setContratos([]);
                    } else {
                      setContratos([]);
                    }
                  });
                }
              } else {
                getContratos({ gerencia_ref: usuarioSesion.gerencia }).then((response) => {
                  if (response.status === 200) {
                    if (usuarioSesion.contrato) {
                      const contrato = response.data.find((a) => a._id === usuarioSesion.contrato);
                      if (contrato) {
                        setFieldValue('contrato', {
                          value: contrato._id,
                          label: contrato.codigo + ' ' + contrato.nombre,
                          contrato,
                        });
                      }
                    }
                    setContratos(response.data);
                  } else if (response.status === 204) {
                    setContratos([]);
                  } else {
                    setContratos([]);
                  }
                });
              }
            }
          } else {
            getContratosGerencia(usuarioSesion.gerencia);
          }
        } else {
          getContratos({ gerencia_ref: usuarioSesion.gerencia }).then((response) => {
            if (response.status === 200) {
              if (usuarioSesion.contrato) {
                const contrato = response.data.find((a) => a._id === usuarioSesion.contrato);
                if (contrato) {
                  // setFieldValue("contrato", {
                  //   value: contrato._id,
                  //   label: contrato.codigo + " " + contrato.nombre,
                  //   contrato: contrato
                  // });
                }
              }
              setContratos(response.data);
            } else if (response.status === 204) {
              setContratos([]);
            } else {
              setContratos([]);
            }
          });
        }
      }
      const filter = {};
      if (usuarioSesion.gerencia) filter['_gerencia_ref_eq'] = usuarioSesion.gerencia;
      if (usuarioSesion.contrato) filter['_contrato_ref_eq'] = usuarioSesion.contrato;
      //setFilters(filter);
    }
  }, [usuarioSesion, setFieldValue, gerencias]);

  function limpiarFormulario() {
    setInitialValuesFiltroActivos({
      gerencia: null,
      contrato: null,
      tipo: null,
      clase: null,
      activado: '',
      baja: 'Vigentes',
      asignado: '',
      codigo_activo: '',
      numero_factura: '',
      numero_serie: '',
    });
    setFiltrosListaActivos({});
    resetFormik();
    setData([]);
    setMostrarResultado();
  }

  return (
    <Fragment>
      <Grid container spacing={2} style={{ width: '96vw', margin: 5 }}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h4" align="center">
              Listado de Activos
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <TextField
                    margin="none"
                    variant="outlined"
                    name="codigo_activo"
                    label="Código Activo"
                    value={values.codigo_activo}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <MaterialReactSelect
                    isDisabled={
                      (permisos && permisos[VER_SELECCION_GERENCIAS]) || (gerencias && gerencias.length > 1)
                        ? false
                        : true
                    }
                    value={values.gerencia}
                    label="Gerencia"
                    isClearable
                    onChange={(newValue, actionMeta) => {
                      if (newValue) {
                        setFieldValue('gerencia', newValue);
                        getContratosGerencia(newValue.value);
                      } else setFieldValue('gerencia', '');
                      setFieldValue('contrato', '');
                    }}
                    placeholder=""
                    options={
                      gerencias && gerencias.length > 0
                        ? gerencias.map((value, index) => ({
                            value: value?._id,
                            label: value?.sigla + ' ' + value?.nombre,
                            gerencia: value,
                          }))
                        : []
                    }
                    margin="none"
                    noOptionsMessage={() => 'No sea ha entrado la gerencia'}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <MaterialReactSelect
                    isDisabled={
                      (permisos && permisos[VER_SELECCION_CONTRATOS]) ||
                      (listadoContratosACargo && listadoContratosACargo.length > 1)
                        ? false
                        : true
                    }
                    value={values.contrato}
                    label="Contrato"
                    isClearable
                    // onInputChange={(inputValue, actionMeta)=>formik.setFieldValue("contrato", inputValue.label)}
                    onChange={(newValue, actionMeta) =>
                      newValue ? setFieldValue('contrato', newValue) : setFieldValue('contrato', '')
                    }
                    placeholder=""
                    options={contratos.map((value, index) => ({
                      value: value._id,
                      label: value.codigo + ' ' + value.nombre,
                      contrato: value,
                    }))}
                    margin="none"
                    noOptionsMessage={() => 'No ha entrado el contrato'}
                    error={touched.contrato && errors.contrato && Boolean(errors.contrato)}
                    helperText={touched.contrato && errors.contrato ? errors.contrato : ''}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <MaterialReactSelect
                    value={values.tipo}
                    label="Tipo"
                    isClearable
                    onChange={(newValue, actionMeta) => {
                      if (newValue) {
                        setFieldValue('tipo', newValue);
                        getClasesTipo(newValue.value);
                      } else setFieldValue('tipo', '');
                      setFieldValue('clase', '');
                    }}
                    margin="none"
                    placeholder=""
                    options={
                      tiposRecursos
                        ? tiposRecursos.map((value, index) => ({
                            value: value._id,
                            label: value.nombre,
                            tipo: value,
                          }))
                        : []
                    }
                    noOptionsMessage={() => 'No ha entrado el tipo'}
                    error={touched.tipo && errors.tipo && Boolean(errors.tipo)}
                    helperText={touched.tipo && errors.tipo ? errors.tipo : ''}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
                  <MaterialReactSelect
                    value={values.clase}
                    label="Clase"
                    isClearable
                    onChange={(newValue, actionMeta) =>
                      newValue ? formik.setFieldValue('clase', newValue) : formik.setFieldValue('clase', '')
                    }
                    placeholder=""
                    options={clases.map((value, index) => ({
                      value: value._id,
                      label: value.nombre,
                      clase: value,
                    }))}
                    margin="none"
                    noOptionsMessage={() => 'No ha entrado la clase'}
                    error={touched.clase && errors.clase && Boolean(errors.clase)}
                    helperText={touched.clase && errors.clase ? errors.clase : ''}
                  />
                </Grid>

                <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                  <FormControl
                    variant="outlined"
                    margin="none"
                    fullWidth
                    error={touched.activado && errors.activado && Boolean(errors.activado)}
                  >
                    <InputLabel shrink={true}>Activación</InputLabel>
                    <Select native name="activado" label="Activación" onChange={handleChange} value={values.activado}>
                      <option aria-label="None" value=""></option>
                      <option aria-label="None" value="Sin activar">
                        Sin activar
                      </option>
                      <option aria-label="None" value="Activado">
                        Activado
                      </option>
                    </Select>
                    <FormHelperText>{touched.activado && errors.activado ? errors.activado : ''}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                  <FormControl
                    variant="outlined"
                    margin="none"
                    fullWidth
                    error={touched.baja && errors.baja && Boolean(errors.baja)}
                  >
                    <InputLabel shrink={true}>Estatus de Registros</InputLabel>
                    <Select native name="baja" label="Baja contable" onChange={handleChange} value={values.baja}>
                      <option aria-label="None" value="Vigentes">
                        Vigentes
                      </option>
                      {permisos && permisos[VER_BAJA_LISTADO] && (
                        <>
                          <option aria-label="None" value="Dado de baja">
                            Dado de baja
                          </option>
                          <option aria-label="None" value="">
                            Todos
                          </option>
                        </>
                      )}
                    </Select>
                    <FormHelperText>{touched.baja && errors.baja ? errors.baja : ''}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                  <FormControl
                    variant="outlined"
                    margin="none"
                    fullWidth
                    error={touched.asignado && errors.asignado && Boolean(errors.asignado)}
                  >
                    <InputLabel shrink={true}>Asignado</InputLabel>
                    <Select native name="asignado" label="Asignado" onChange={handleChange} value={values.asignado}>
                      <option value=""></option>
                      <option value="Asignado a Persona">Asignado a Persona</option>
                      <option value="Asignado a Contrato">Asignado a Contrato</option>
                      <option value="Sin asignar">Sin asignar</option>
                    </Select>
                    <FormHelperText>{touched.asignado && errors.asignado ? errors.asignado : ''}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                  <TextField
                    margin="none"
                    variant="outlined"
                    name="numero_factura"
                    label="Número Factura"
                    value={values.numero_factura}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                  <TextField
                    margin="none"
                    variant="outlined"
                    name="numero_serie"
                    label="Número Serie"
                    value={values.numero_serie}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: '10px' }}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box
                    display="flex"
                    justifyContent="right"
                    alignItems="center"
                    height="100%"
                    style={{ marginRight: '10px' }}
                  >
                    <Typography variant="h5">
                      <b>{`Cantidad: ${data ? data.length : 0}`}</b>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg md sm xs className={classes.center}>
                  <Button color="primary" variant="outlined" className={classes.btnMargin} onClick={limpiarFormulario}>
                    Limpiar
                  </Button>
                  <Button type="submit" variant="contained" color="primary" className={classes.btnMargin}>
                    Buscar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <TablaResultadoActivos {...props} />
        </Grid>
      </Grid>

      {/* LISTADO DE ACTIVOS SELECCIONADOS */}
      <TablaSeleccionados id={OpenSeleccionados ? 'simple-popover' : undefined} handle_close={handleCloseList} />
      <TablaSeleccionadosBajaMasiva
        id={OpenSeleccionadosBaja ? 'simple-popover' : undefined}
        handle_close={handleCloseList}
      />
    </Fragment>
  );
}
