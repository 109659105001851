import React, { useEffect, useState } from "react";
import Page from "./page";
import { useSnackbar } from "notistack";
import TitleCase from "titlecase";
import Moment from "moment";
import XLSX from "xlsx";
import { ObtenerSolicitdesAsignacion, AnularAsignacion, AceptarAsignacion } from "../../services/request/requestSolicitudesAsignacion";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { putBien } from "../../services/request/requestBienes";
import { GenerarLog } from "../../services/activoLog";

function Index(props) {
	const [SolicitudesAsignacion, SetSolicitudesAsignacion] = useState();

	const notistack = useSnackbar();

	useEffect(() => {
		LoadData();
	}, []);

	const LoadData = () => {
		ObtenerSolicitdesAsignacion()
			.then(response => {
				let solicitudes = Array.from(response.data);
				solicitudes.forEach((solicitud, index) => {
					let asignante = solicitud._usuario_asignante_ref;
					if (asignante) {
						solicitud._usuario_asignante_ref["nombre_completo"] = `${asignante.nombre} ${asignante.apellido_paterno} ${asignante.apellido_materno}`;
					}
					switch (solicitud.tipo) {
						case "persona":
							let asignado = solicitud._asignado_ref;
							if (asignado) {
								solicitud["asignado_a"] = `${asignado.nombre} ${asignado.apellido_paterno} ${asignado.apellido_materno}`;
							}
							break;
						case "gerencia":
							let gerencia = solicitud._gerencia_ref;
							if (gerencia) {
								solicitud["asignado_a"] = `${gerencia.sigla} ${gerencia.nombre}`;
							}
							break;
						case "contrato":
							let contrato = solicitud._contrato_ref;
							if (contrato) {
								solicitud["asignado_a"] = `${contrato.codigo} ${contrato.nombre}`;
							}
							break;
						default:
							break;
					}
					solicitudes[index].tipo = TitleCase(solicitud.tipo);
					solicitudes[index].estado = TitleCase(solicitud.estado);
					solicitudes[index].formulario_estado.estado = TitleCase(solicitud.formulario_estado.estado);
					solicitudes[index].formulario_estado.descripcion = TitleCase(solicitud.formulario_estado.descripcion);
					solicitudes[index].fecha_creacion_format = Moment(solicitud.fecha_creacion).format("DD/MM/YYYY HH:mm");
				});
				solicitudes.sort((a, b) => Moment(b.fecha_creacion).diff(a.fecha_creacion));
				SetSolicitudesAsignacion(solicitudes);
			})
			.catch(error => {
				console.error(error);
				SetSolicitudesAsignacion([]);
			});
	}

	/**
	 * ACEPTACIÓN DE SOLICITUD
	 */
	const aceptarSolicitud = async (solictudAsignacion) => {
		try {
			let registroLogAceptar = {
				_bien_ref: solictudAsignacion._bien_ref._id,
				titulo: "Solicitud de Asignación",
				cuerpo: `La solicitud de asignación del activo ${solictudAsignacion._bien_ref.codigo_activo} fue aceptada por sistema.`,
				tipo: "solicitud_asignacion",
				icon: "thumb_up",
			}
			let actualizacionBien = {
				_asignado_ref: solictudAsignacion.tipo.toUpperCase() === "persona".toUpperCase() && solictudAsignacion._asignado_ref ? solictudAsignacion._asignado_ref._id : null,
				_gerencia_ref: solictudAsignacion._gerencia_ref ? solictudAsignacion._gerencia_ref._id : null,
				_contrato_ref: solictudAsignacion._contrato_ref ? solictudAsignacion._contrato_ref._id : null,
				condicion_actual: solictudAsignacion.formulario_estado.estado,
				foto_actual: solictudAsignacion.formulario_estado.foto_despues ? solictudAsignacion.formulario_estado.foto_despues.url : undefined,
				is_asignado: false,
			}
			//Si la solicitud de asignación es para persona, se mantiene como "is_asignado" TRUE.
			if (String(solictudAsignacion.tipo).toLowerCase() === "persona") {
				actualizacionBien.is_asignado = true;
			}
			await Promise.all([
				AceptarAsignacion(solictudAsignacion._id, solictudAsignacion.formulario_estado.foto_despues),
				putBien(solictudAsignacion._bien_ref._id, actualizacionBien),
				GenerarLog(registroLogAceptar),
			]);

			//Se recargan los datos.
			LoadData();

			notistack.enqueueSnackbar("Solicitud de asignación aceptada exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar aceptar la solicitud de asignación.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		}
	}

	/**
	 * Handler para anular una solicitud de asignación.
	 * @param {*} solicitudID ID de la solicitud de asignación.
	 */
	const anularSolicitud = async (solicitudID) => {
		try {
			await AnularAsignacion(solicitudID);

			//Se recargan los datos.
			LoadData();

			notistack.enqueueSnackbar("Solicitud de asignación anulada exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar anular la solicitud de asignación.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		}
	}

	const handleExport = () => {
		const wb = XLSX.utils.book_new();
		const wbdata = SolicitudesAsignacion.map(a => {
			return {
				"Asignado por": a._usuario_asignante_ref.nombre_completo,
				"Activo": a._bien_ref.codigo_activo,
				"Marca": a._bien_ref.marca,
				"Modelo": a._bien_ref.modelo,
				"Asignado a": a.asignado_a,
				"Gerencia": a._gerencia_ref.nombre,
				"Contrato": a._contrato_ref?.nombre,
				"Tipo": a.tipo,
				"Estado": a.estado,
				"Condición Actual": a.formulario_estado.estado,
				"Descripción": a.formulario_estado.descripcion,
				"Fecha Asignación": a.fecha_creacion_format,
			};
		});
		let ws = XLSX.utils.json_to_sheet(wbdata);
		XLSX.utils.book_append_sheet(wb, ws, "Solicitudes");
		XLSX.writeFile(wb, "solicitudes_asignacion.xlsx");
	}

	return (
		<Page
			solicitudes_asignacion={SolicitudesAsignacion}
			handle_aceptar_solicitud={aceptarSolicitud}
			handle_anular_solicitud={anularSolicitud}
			handle_export={handleExport}
		/>
	);
}

export default Index;