import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import React, { useContext } from 'react';
import { TablaSeleccionablesContext } from './tablaSeleccionables';

export default function DialogoCRUDRegistroSeleccionable() {
  const { openDialogoCRUD, setOpenDialogoCRUD, tituloDialogo, nombre, setNombre, handleAceptar, disableEditing, id } =
    useContext(TablaSeleccionablesContext);

  const handleClose = () => setOpenDialogoCRUD(false);

  return (
    <Dialog open={openDialogoCRUD} maxWidth="md" fullWidth>
      <DialogTitle>{tituloDialogo}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Nombre"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              variant="outlined"
              fullWidth
              disabled={disableEditing}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="primary">
          Cancelar
        </Button>
        <Button onClick={() => handleAceptar(tituloDialogo, nombre, id)} variant="contained" color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
