import { createContext } from "react"

const InformeSoftwareContext = createContext({
  filtros: {
    gerencias: "",
    activos: "",
    softwares: "",
    contratos: "",
    usuarios: ""
  }, setFiltros: () => { },
  aplicarFiltros: () => { },
  openSelectGerencia: false, setOpenSelectGerencia: () => { },
  openSelectActivo: false, setOpenSelectActivo: () => { },
  openSelectSoftware: false, setOpenSelectSoftware: () => { },
  openSelectUsuario: false, setOpenSelectUsuario: () => { },
  openSelectContrato: false, setOpenSelectContrato: () => { }
});

export default InformeSoftwareContext;