import React, { useEffect, useState } from "react";
import Page from "./page";
import { ActualizarAsignado } from "../../../../services/request/requestBienes";
import { putBien } from "../../../../services/request/requestBienes";
import { GenerarSolicitudAsignacion } from "../../../../services/request/requestSolicitudesAsignacion";
import { ObtenerCondicionesActuales } from "../../../../services/request/requestCondicionesActuales";
import { ObtenerContrato, ObtenerContratoPorCodigo, ObtenerContratoPorCorrelativo } from "../../../../services/request/requestPortafolio";
import { useFormik } from "formik";
import { object, string, mixed } from "yup";
import { uploadFileToCloudStorage } from "../../../../services/fileUpload";
import { GenerarLog } from "../../../../services/activoLog";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import Moment from "moment";
import { AceptarAsignacion } from "../../../../services/request/requestSolicitudesAsignacion";

function Index(props) {
	const {
		usuario_asignante,
		bien,
		asignado,
		nombre_asignado,
		tipo,
		handle_paso_1,
		handle_close,
	} = props;

	const [CondicionesActuales, SetCondicionesActuales] = useState();
	const notistack = useSnackbar();

	useEffect(() => {
		ObtenerCondicionesActuales()
			.then(response => SetCondicionesActuales(response.data))
			.catch(error => {
				console.error(error);
				SetCondicionesActuales([]);
			});
	}, []);

	let initials = {
		_usuario_asignante_ref: usuario_asignante.ref,
		_bien_ref: bien._id,
		fecha_revision: "",
		_asignado_ref: asignado._id,
		tipo: tipo,
		estado: "pendiente",
		formulario_estado: {
			estado: bien.condicion_actual,
			descripcion: "",
			foto_antes: null,
		},
	}
	let validations = object().shape({
		_usuario_asignante_ref: string()
			.required("El usuario asignante es requerido."),
		_bien_ref: string()
			.required("El bien es requerido."),
		fecha_revision: string()
			.optional(),
		_asignado_ref: string()
			.required("El usuario asignado es requerido."),
		tipo: string()
			.required("El tipo es requerido."),
		estado: string()
			.required("El estado es requerido."),
		formulario_estado: object().shape({
			estado: string()
				.required("El estado es requerido."),
			descripcion: string()
				.optional(),
			foto_antes: object({
				file: mixed().nullable().required("Campo requerido"),
			})
				.nullable(),
		}),
	});
	const formik = useFormik({
		initialValues: initials,
		validationSchema: validations,
		onSubmit: (values, helper) => handleAsignar(values),
	});

	const { resetForm } = formik;

	const handleAsignar = async (values) => {
		try {
			values.formulario_estado.foto_antes = null;

			//Asignación del archivo subido.
			let uploadedFile = null;
			if (values.file) {
				uploadedFile = await uploadFileToCloudStorage(values.file, `activos/${bien.codigo_activo}/fotos_actuales`, `foto_actual_${Moment().format("DD_MM_YYYY_HH_mm")}`);
				values.formulario_estado.foto_antes = uploadedFile;
			}
			//Registro LOG de la acción.
			let registroLog = {
				_bien_ref: values._bien_ref,
				titulo: "Asignación de Activo",
				cuerpo: `Activo asignado a ${nombre_asignado} por ${usuario_asignante.nombre}.`,
				tipo: "asignacion_activo",
				icon: "person_add",
				link: uploadedFile ? uploadedFile.url : undefined,
			}

			//Asignaciones por tipo.
			switch (values.tipo.toLowerCase()) {
				case "persona":
					//Una asignación de tipo PERSONA, ya tiene el ID del usuario. Se busca su gerencia y su contrato.
					let codigoContrato = asignado.contratos_ref && asignado.contratos_ref.length > 0 ? asignado.contratos_ref[0].codigo : null;
					let correlativo = String(codigoContrato).split("-")[2];
					let contratoPersona = correlativo ? await ObtenerContratoPorCorrelativo(correlativo) : await ObtenerContratoPorCodigo(codigoContrato);
					values["_gerencia_ref"] = asignado.gerencia_ref;
					values["_contrato_ref"] = contratoPersona ? contratoPersona._id : null;
					break;
				case "gerencia":
					//Una asignación de tipo GERENCIA, solo tiene el ID de la gerencia.
					values["_gerencia_ref"] = values._asignado_ref;
					values["_contrato_ref"] = null;
					values["_asignado_ref"] = null;
					break;
				case "contrato":
					//Una asignación de tipo CONTRATO, tiene el ID de la gerencia y del contrato. No tiene ID de persona.
					let contrato = await ObtenerContrato(values._asignado_ref);
					let gerenciaID = contrato.gerencia_ref._id;
					values["_gerencia_ref"] = gerenciaID;
					values["_contrato_ref"] = values._asignado_ref;
					values["_asignado_ref"] = null;
					break;
				default:
					throw "Tipo de solicitud de asignación no válida.";
			}

			if (bien._contrato_ref) {
				//Si el bien tiene un contrato de origen, se asigna.
				values["_origen_contrato_ref"] = bien._contrato_ref._id;
			}
			if (bien._gerencia_ref) {
				//Si el bien tiene una gerencia de origen, se asigna.
				values["_origen_gerencia_ref"] = bien._gerencia_ref;
			}

			let responses = await Promise.all([
				GenerarSolicitudAsignacion(values),
				ActualizarAsignado(values._bien_ref, values.formulario_estado.estado),
				putBien(values._bien_ref, { foto_actual: values.formulario_estado.foto_antes ? values.formulario_estado.foto_antes.url : "", asignado_por: usuario_asignante.nombre }),
				GenerarLog(registroLog),
			]);

			notistack.enqueueSnackbar("Solicitud de asignación realizada exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});

			/**
			 * ACEPTACIÓN DE SOLICITUD AUTOMÁTICA
			 */
			let solicitudAsignacion = responses[0].data;
			let registroLogAceptar = {
				_bien_ref: bien._id,
				titulo: "Solicitud de Asignación",
				cuerpo: `La solicitud de asignación del activo ${bien.codigo_activo} fue aceptada por sistema.`,
				tipo: "solicitud_asignacion",
				icon: "thumb_up",
			}
			let actualizacionBien = {
				_asignado_ref: solicitudAsignacion.tipo.toUpperCase() === "persona".toUpperCase() ? asignado._id : null,
				_gerencia_ref: solicitudAsignacion._gerencia_ref,
				_contrato_ref: solicitudAsignacion._contrato_ref,
				condicion_actual: solicitudAsignacion.formulario_estado.estado,
				foto_actual: solicitudAsignacion.formulario_estado.foto_despues ? solicitudAsignacion.formulario_estado.foto_despues.url : undefined,
			}
			await Promise.all([
				AceptarAsignacion(solicitudAsignacion._id, solicitudAsignacion.formulario_estado.foto_despues),
				putBien(bien._id, actualizacionBien),
				GenerarLog(registroLogAceptar),
			]);
			notistack.enqueueSnackbar("Solicitud de asignación aceptada exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
			/**
			 * ACEPTACIÓN DE SOLICITUD AUTOMÁTICA
			 */

		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar realizar la asignación.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} finally {
			handle_close();
		}
	}

	const handleCancelar = () => {
		resetForm();
		handle_paso_1();
	}

	// const reset = useCallback(() => {
	//   resetForm();
	// }, [resetForm]);

	// useEffect(() => {
	//   reset();
	// }, [reset]);

	return (
		<Page
			usuario_asignante={usuario_asignante}
			asignado={nombre_asignado}
			bien={bien}
			condiciones_actuales={CondicionesActuales}
			formik={formik}
			handleCancelar={handleCancelar}
		/>
	);
}

export default Index;