import React, { Fragment, useContext, useState, useCallback, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Tooltip,
  FormControl,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import { object, string, number, date } from 'yup';
import { VerBienContext } from '.';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { IconButton } from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { KeyboardDatePicker } from '@material-ui/pickers';
import useSWR from 'swr';
import formatNumber from 'format-number';
import { MainContext } from '../../../App';
import FileInput from '../../../components/fileInput';
import { putBien, getBienes } from '../../../services/request/requestBienes';
import { GenerarLog } from '../../../services/activoLog';
import { getProveedores, postProveedor } from '../../../services/request/requestTipoProveedores';
import { postProveedorSDA, getComunasSDA, getProveedoresSDA } from '../../../services/request/sda';
import { getSociedadTerceros } from '../../../services/request/requestSociedades';
import { getSociedades } from '../../../services/request/requestPortafolio';
import { uploadFileToCloudStorage } from '../../../services/fileUpload';
import MaterialReactSelect from '../../../components/materialReactSelect';
import * as PERMISOS from '../../../constants/permissions';

const numberFormater = formatNumber({ integerSeparator: '.', decimal: ',' });

export default function DialogoActualizarContabilidad() {
  const {
    data: proveedores,
    isValidating: isValidatingProveedores,
    mutate: mutateProveedores,
  } = useSWR('proveedores', getProveedores, { revalidateOnFocus: false });
  const { data: comunasSDA, isValidating: isValidatingComunasSDA } = useSWR('comunas_sda', getComunasSDA, {
    revalidateOnFocus: false,
  });
  const { data: terceros, error: errorTerceros } = useSWR('get:sociedades-terceros', (key) => getSociedadTerceros(), {
    refreshInterval: 0,
  });
  const { bien, openActualizarContabilidad, setOpenActualizarContabilidad, loadData } = useContext(VerBienContext);
  const { usuarioSesion, permisos } = useContext(MainContext);
  const [IsOpenProveedores, SetIsOpenProveedores] = useState(false);
  const [sociedadesOptions, setSociedadesOptions] = useState([]);
  const [propietarioCyD, setPropietarioCyD] = useState(true);
  const [leasings, setLeasings] = useState([]);
  const notistack = useSnackbar();

  const formikContabilidad = useFormik({
    initialValues: {
      codigo_contable: '',
      proveedor: null,
      numero_factura: '',
      valor: 0,
      fecha_factura: null,
      factura: null,
      is_propietario_cyd: false,
      sociedad: '',
      _sociedad_ref: null,
      tercero: null,
      _sociedad_tercero_ref: null,
      contrato_leasing: '',
    },
    validationSchema: object().shape({
      codigo_contable: string().min(3, 'El código contable debe tener al menos ${min} caracteres.').optional(),
      // .required("El código contable es requerido."),
      proveedor: object().nullable().optional(),
      // .required("El proveedor es requerido."),
      numero_factura: string().min(3, 'El número de factura debe tener al menos ${min} caracteres.').optional(),
      // .required("El número de factura es requerido."),
      valor: number()
        .min(0, 'El valor debe ser mayor a ${min}.')
        .max(999999999, 'El valor debe ser menor a $999.999.999 pesos.')
        .positive('El valor debe ser una cantidad positiva.')
        .optional(),
      // .required("El valor es requerido."),
      fecha_factura: date().nullable().optional(),
      // .required("La fecha de la factura es requerida."),
      factura: object().nullable().optional(),
      // .required("El archivo de la factura es requerido."),
    }),
    onSubmit: async (values) => {
      try {
        notistack.enqueueSnackbar('Guardando cambios...', {
          persist: true,
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
        });

        if (values.sociedad) {
          if (values.sociedad && values.sociedad.label === 'Tercero') {
            values.is_propietario_cyd = false;
            values._sociedad_ref = null;
            values._sociedad_tercero_ref = values.tercero?.value?._id
              ? values.tercero?.value?._id
              : values?._sociedad_tercero_ref
              ? values?._sociedad_tercero_ref
              : null;
          } else {
            values.is_propietario_cyd = true;
            values._sociedad_ref = values.sociedad.value._id;
            values._sociedad_tercero_ref = null;
            values.contrato_leasing = '';
          }
        }

        let uploaded =
          bien && bien.activo && bien.activo.factura && bien.activo.factura.archivo
            ? bien.activo.factura.archivo
            : null;
        if (values.file) {
          //Si se seleccionó un archivo de factura, se sube.
          uploaded = await uploadFileToCloudStorage(values.file, `activos/${bien.codigo_activo}`, values.file.name);
        }

        const updateValues = {
          is_propietario_cyd: values.is_propietario_cyd,
          sociedad: values.sociedad.label,
          _sociedad_ref: values._sociedad_ref,
          _sociedad_tercero_ref: values._sociedad_tercero_ref,
          contrato_leasing: values.contrato_leasing,
          activo: {
            factura: {
              numero: values.numero_factura,
              archivo: uploaded,
              proveedor: values.proveedor,
              fecha: values.fecha_factura,
            },
            valor: values.valor,
          },
        };
        let registroLog1 = {
          _bien_ref: bien._id,
          titulo: 'Actualización de Información de Contabilidad',
          cuerpo: `La información de contabilidad del activo ${bien.codigo_activo} fue actualizada por el usuario ${usuarioSesion.nombre}.`,
          tipo: 'actualizacion',
          icon: 'edit',
        };
        await Promise.all([putBien(bien._id, updateValues), GenerarLog(registroLog1)]);

        //Si el nuevo valor es mayor o igual a $200.000, se genera la activación.
        if (values.valor >= 200000 && !bien.is_activo_contable) {
          let datosActualizacion = {
            is_activo_contable: true,
            is_no_activable: false,
            fecha_activacion: new Date(),
            estado_activacion: 'Aprobado Registro Contable',
          };
          let registroLog2 = {
            _bien_ref: bien._id,
            titulo: 'Activación de Contable',
            cuerpo: `El usuario ${usuarioSesion.nombre} realizó la activación del activo ${bien.codigo_activo} mediante actualización de información contable.`,
            tipo: 'solicitud_activacion',
            icon: 'check_circle_outline',
          };
          await Promise.all([putBien(bien._id, datosActualizacion), GenerarLog(registroLog2)]);
        }

        loadData();
        setOpenActualizarContabilidad(false);
        notistack.closeSnackbar();
        notistack.enqueueSnackbar('Operanción exitosa: Información de Contabilidad actualizada.', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (key) => (
            <IconButton onClick={() => notistack.closeSnackbar(key)}>
              <Close />
            </IconButton>
          ),
        });
      } catch (error) {
        console.error(error);
        notistack.closeSnackbar();
        notistack.enqueueSnackbar(
          'Error: No ha sido posible actualizar la información de contabilidad. Intente de nuevo o contacte con Soporte.',
          {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            action: (key) => (
              <IconButton onClick={() => notistack.closeSnackbar(key)}>
                <Close />
              </IconButton>
            ),
          },
        );
      }
    },
  });

  const formikProveedor = useFormik({
    initialValues: {
      direccion: '',
      nombre: '',
      rut: '',
      telefono: '',
      email_envio_oc: '',
      comuna: '',
    },
    validationSchema: object().shape({
      direccion: string()
        .min(3, 'La dirección debe tener al menos ${min} caracteres.')
        .required('La dirección es requerida.'),
      nombre: string().min(3, 'El nombre debe tener al menos ${min} caracteres.').required('El nombre es requerido.'),
      rut: string()
        .min(10, 'El RUT debe tener al menos ${min} caracteres.')
        .max(12, 'El RUT debe tener al lo más ${max} caracteres.')
        .required('El RUT es requerido.'),
      telefono: string()
        .min(6, 'El teléfono debe tener al menos ${min} caracteres.')
        .required('El teléfono es requerido.'),
      email_envio_oc: string()
        .min(3, 'El email debe tener al menos ${min} caracteres.')
        .email('Email no válido')
        .required('El email de contacto es requerido.'),
    }),
    onSubmit: async function (values, helper) {
      try {
        notistack.enqueueSnackbar('Guardando proveedor...', {
          persist: true,
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
        });
        const data = {
          direccion: values.direccion,
          nombre: values.nombre,
          rut: values.rut,
          telefono: values.telefono,
          email_envio_oc: values.email_envio_oc,
        };
        if (values.comuna) {
          data['comuna_id'] = values.comuna.value.id;
          data['ciudad_id'] = values.comuna.value.ciudad_id;
        }
        const proveedores = await getProveedoresSDA();
        const proveedorIngresado = proveedores.find((a) => a.nombre === data.nombre && a.rut === data.rut);
        if (proveedorIngresado) {
          notistack.closeSnackbar();
          notistack.enqueueSnackbar('Error: El proveedor ya se encuentra ingresado', {
            variant: 'warning',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            action: (key) => (
              <IconButton onClick={() => notistack.closeSnackbar(key)}>
                <Close />
              </IconButton>
            ),
          });
        } else {
          await postProveedorSDA(data);
          await postProveedor({ rut: data.rut, nombre: data.nombre, direccion: data.direccion });
          notistack.closeSnackbar();
          notistack.enqueueSnackbar('Operanción exitosa: Proveedor creado', {
            variant: 'success',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            action: (key) => (
              <IconButton onClick={() => notistack.closeSnackbar(key)}>
                <Close />
              </IconButton>
            ),
          });
        }
      } catch (error) {
        console.error(error);
        notistack.closeSnackbar();
        notistack.enqueueSnackbar('Error: No ha sido posible guardar el proveedor', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (key) => (
            <IconButton onClick={() => notistack.closeSnackbar(key)}>
              <Close />
            </IconButton>
          ),
        });
      } finally {
        SetIsOpenProveedores(false);
        helper.resetForm();
        mutateProveedores();
      }
    },
  });

  const fileChange = (name, e) => {
    formikContabilidad.setFieldValue(name, e.target.files[0]);
    formikContabilidad.setFieldTouched(name, true, false);
  };

  const dateChange = (name, e) => {
    formikContabilidad.setFieldValue(name, e);
    formikContabilidad.setFieldTouched(name, true, false);
  };

  const handleExitFormProveedor = () => {
    SetIsOpenProveedores(false);
    formikProveedor.resetForm();
  };

  const resetFormulario = useCallback(async () => {
    const sociedades = (await getSociedades()).map((value, index) => ({
      value: value,
      label: value.nombre,
    }));

    //Se obtienen los contratos de leasing registrados en los bienes y se eliminan los repetidos y genera un nuevo array con los datos
    const leasing_registrados = await getBienes({}, ['contrato_leasing'], []);
    const leasing = leasing_registrados.data.map((value) => (value.contrato_leasing ? value.contrato_leasing : ' '));
    const leasingArray = [...new Set(leasing)];
    setLeasings(leasingArray);

    sociedades.push({ label: 'Tercero', value: 'Tercero' });
    setSociedadesOptions(sociedades);

    formikContabilidad.resetForm();
  }, [formikContabilidad.resetForm]);

  useEffect(() => {
    resetFormulario();
  }, [resetFormulario]);

  useEffect(() => {
    if (errorTerceros) console.log(errorTerceros);
  }, [errorTerceros]);

  function handleEntered() {
    let values = {
      codigo_contable: bien.codigo_contable,
      proveedor: bien.activo.factura && bien.activo.factura.proveedor ? bien.activo.factura.proveedor : null,
      numero_factura: bien.activo.factura && bien.activo.factura.numero ? bien.activo.factura.numero : '',
      valor: bien.activo.valor,
      fecha_factura: bien.activo.factura && bien.activo.factura.fecha ? bien.activo.factura.fecha : null,
      factura: null,
      sociedad: bien && bien?.sociedad ? Array.from(sociedadesOptions).find((m) => m.label === bien.sociedad) : null,
      _sociedad_ref:
        bien && bien?._sociedad_ref
          ? Array.from(sociedadesOptions).find((m) => m.value._id === bien._sociedad_ref)
          : null,
      tercero:
        bien && bien?._sociedad_tercero_ref ? terceros.data.find((d) => d._id === bien?._sociedad_tercero_ref) : null,
      _sociedad_tercero_ref: bien && bien?._sociedad_tercero_ref ? bien?._sociedad_tercero_ref : null,
      contrato_leasing: bien && bien?.contrato_leasing ? bien?.contrato_leasing : '',
    };

    if (bien.sociedad === 'Tercero') setPropietarioCyD(false);
    else setPropietarioCyD(true);

    formikContabilidad.resetForm({ values: values });
  }

  return (
    <Dialog
      open={openActualizarContabilidad}
      maxWidth="md"
      onClose={() => setOpenActualizarContabilidad(false)}
      TransitionProps={{
        onEntered: handleEntered,
      }}
    >
      {/* FORMULARIO INFORMACIÓN CONTABLE */}
      {!IsOpenProveedores && (
        <Fragment>
          <DialogTitle>Actualizar Contabilidad</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* CÓDIGO CONTABLE */}
                <TextField
                  name="codigo_contable"
                  label="Código Contable"
                  onChange={formikContabilidad.handleChange}
                  value={formikContabilidad.values.codigo_contable}
                  error={
                    formikContabilidad.touched.codigo_contable &&
                    formikContabilidad.errors.codigo_contable &&
                    Boolean(formikContabilidad.errors.codigo_contable)
                  }
                  helperText={
                    formikContabilidad.touched.codigo_contable && formikContabilidad.errors.codigo_contable
                      ? formikContabilidad.errors.codigo_contable
                      : ''
                  }
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} style={{ display: 'flex' }}>
                {/* PROVEEDOR */}
                <Autocomplete
                  name="proveedor"
                  options={proveedores ? proveedores.data : []}
                  value={
                    proveedores && bien.activo && bien.activo.factura && bien.activo.factura.proveedor
                      ? proveedores.data.find((p) => p._id === bien.activo.factura.proveedor._id)
                      : undefined
                  }
                  loading={isValidatingProveedores}
                  getOptionLabel={(proveedor) => `[${proveedor.rut}] ${proveedor.nombre}`}
                  onChange={(event, value) => formikContabilidad.setFieldValue('proveedor', value)}
                  noOptionsText="Sin Resultados"
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      label="Proveedor"
                      variant="outlined"
                      error={
                        formikContabilidad.touched.proveedor &&
                        formikContabilidad.errors.proveedor &&
                        Boolean(formikContabilidad.errors.proveedor)
                      }
                      helperText={
                        formikContabilidad.touched.proveedor && formikContabilidad.errors.proveedor
                          ? formikContabilidad.errors.proveedor
                          : ''
                      }
                      {...params}
                    />
                  )}
                />
                <Tooltip title="Agregar Proveedor">
                  <Button
                    variant="contained"
                    style={{ margin: 5, boxShadow: 'none' }}
                    color="primary"
                    onClick={() => SetIsOpenProveedores(true)}
                  >
                    <Add />
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                {/* NÚMERO DE FACTURA */}
                <TextField
                  name="numero_factura"
                  label="N° de Factura"
                  value={formikContabilidad.values.numero_factura}
                  onChange={formikContabilidad.handleChange}
                  error={
                    formikContabilidad.touched.numero_factura &&
                    formikContabilidad.errors.numero_factura &&
                    Boolean(formikContabilidad.errors.numero_factura)
                  }
                  helperText={
                    formikContabilidad.touched.numero_factura && formikContabilidad.errors.numero_factura
                      ? formikContabilidad.errors.numero_factura
                      : ''
                  }
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                {/* VALOR */}
                <CurrencyTextField
                  name="valor"
                  label="Valor"
                  variant="outlined"
                  value={formikContabilidad.values.valor}
                  onChange={(event, value) => formikContabilidad.setFieldValue('valor', value)}
                  currencySymbol="$"
                  minimumValue="0"
                  outputFormat="string"
                  decimalCharacter=","
                  decimalPlaces={0}
                  digitGroupSeparator="."
                  fullWidth
                  error={
                    formikContabilidad.touched.valor &&
                    formikContabilidad.errors.valor &&
                    Boolean(formikContabilidad.errors.valor)
                  }
                  helperText={
                    formikContabilidad.touched.valor && formikContabilidad.errors.valor
                      ? formikContabilidad.errors.valor
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={6}>
                {/* FECHA DE LA FACTURA */}
                <KeyboardDatePicker
                  name="fecha_factura"
                  label="Fecha de Factura"
                  value={formikContabilidad.values.fecha_factura}
                  onChange={dateChange.bind(null, 'fecha_factura')}
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  cancelLabel="Cancelar"
                  okLabel="Aceptar"
                  helperText={formikContabilidad.touched.fecha_factura ? formikContabilidad.errors.fecha_factura : ''}
                  error={formikContabilidad.touched.fecha_factura && Boolean(formikContabilidad.errors.fecha_factura)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                {/* ARCHIVO DE FACTURA */}
                <FileInput
                  inputName="file"
                  meta={{ touched: formikContabilidad.touched.factura, error: formikContabilidad.errors.factura }}
                  buttonColor="primary"
                  buttonVariant="outlined"
                  label="Archivo de Factura"
                  value={formikContabilidad.values.file}
                  handleOnChange={fileChange.bind(null, 'file')}
                  textVariant="outlined"
                  accept="image/x-png,image/gif,image/jpeg,application/pdf"
                />
              </Grid>

              {permisos[PERMISOS.EDITAR_SOCIEDAD_ACTIVO] && (
                <Grid item xs={6}>
                  <MaterialReactSelect
                    value={formikContabilidad.values.sociedad}
                    label="Sociedad"
                    isClearable
                    onChange={(newValue, actionMeta) => {
                      if (newValue) {
                        if (newValue.label === 'Tercero') setPropietarioCyD(false);
                        else setPropietarioCyD(true);
                        formikContabilidad.setFieldValue('sociedad', newValue);
                      } else {
                        setPropietarioCyD(false);
                        formikContabilidad.setFieldValue('sociedad', '');
                      }
                    }}
                    placeholder="Seleccionar Sociedad"
                    options={sociedadesOptions}
                    noOptionsMessage={() => 'No ha entrado la Sociedad'}
                    error={
                      formikContabilidad.touched.sociedad &&
                      formikContabilidad.errors.sociedad &&
                      Boolean(formikContabilidad.errors.sociedad)
                    }
                    helperText={
                      formikContabilidad.touched.sociedad && formikContabilidad.errors.sociedad
                        ? formikContabilidad.errors.sociedad
                        : ''
                    }
                  />
                </Grid>
              )}
              {permisos[PERMISOS.EDITAR_SOCIEDAD_ACTIVO] && !propietarioCyD && (
                <Grid item xs={4}>
                  <MaterialReactSelect
                    value={
                      formikContabilidad.values.tercero ||
                      terceros.data.find((d) => d._id === formikContabilidad.values._sociedad_tercero_ref)
                    } //{values._sociedad_tercero_ref}
                    label="Tercero"
                    isClearable
                    onChange={(newValue) => formikContabilidad.setFieldValue('tercero', newValue ? newValue : null)}
                    placeholder="Seleccionar Tercero"
                    options={
                      terceros &&
                      terceros.data.map((value, index) => ({
                        value: value,
                        label: value.sociedad,
                      }))
                    }
                    noOptionsMessage={() => 'No ha entrado el Tercero'}
                    error={
                      formikContabilidad.touched.tercero &&
                      formikContabilidad.errors.tercero &&
                      Boolean(formikContabilidad.errors.tercero)
                    }
                    helperText={
                      formikContabilidad.touched.tercero && formikContabilidad.errors.tercero
                        ? formikContabilidad.errors.tercero
                        : ''
                    }
                  />
                </Grid>
              )}
              {permisos[PERMISOS.EDITAR_SOCIEDAD_ACTIVO] && !propietarioCyD && (
                <Grid item xs={2}>
                  <FormControl variant="outlined" margin="normal" fullWidth>
                    <Autocomplete
                      freeSolo
                      autoSelect
                      options={leasings || []}
                      value={formikContabilidad.values.contrato_leasing}
                      loading={!leasings}
                      getOptionLabel={(leasing) => leasing || ' '}
                      autoComplete
                      onChange={(event, newValue) => {
                        newValue
                          ? formikContabilidad.setFieldValue('contrato_leasing', newValue)
                          : formikContabilidad.setFieldValue('contrato_leasing', ' ');
                        formikContabilidad.handleChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField label="Nº Contrato Leasing" variant="outlined" fullWidth {...params} />
                      )}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenActualizarContabilidad(false)} variant="outlined" color="primary">
              Cerrar
            </Button>
            <Button onClick={formikContabilidad.submitForm} variant="contained" color="primary">
              Actualizar
            </Button>
          </DialogActions>
        </Fragment>
      )}

      {/* FORMULARIO NUEVO PROVEEDOR */}
      {IsOpenProveedores && (
        <Fragment>
          <DialogTitle>Agregar nuevo proveedor</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                {/* NOMBRE DEL PROVEEDOR */}
                <TextField
                  name="nombre"
                  label="Nombre"
                  fullWidth
                  variant={'outlined'}
                  value={formikProveedor.values.nombre}
                  onChange={formikProveedor.handleChange}
                  error={
                    formikProveedor.touched.nombre &&
                    formikProveedor.errors.nombre &&
                    Boolean(formikProveedor.errors.nombre)
                  }
                  helperText={
                    formikProveedor.touched.nombre && formikProveedor.errors.nombre ? formikProveedor.errors.nombre : ''
                  }
                />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                {/* RUT DEL PROVEEDOR */}
                <TextField
                  name="rut"
                  label="RUT"
                  fullWidth
                  variant={'outlined'}
                  value={formikProveedor.values.rut}
                  onChange={(e) => {
                    if (e.target.value) {
                      let raw = e.target.value;
                      raw = raw.replace(/[^0-9kK]/g, '');
                      if (raw.length > 1) {
                        const verificador = raw[raw.length - 1];
                        const cuerpo = raw.substring(0, raw.length - 1);
                        const rut = numberFormater(cuerpo) + '-' + verificador;
                        formikProveedor.setFieldValue('rut', rut);
                      } else {
                        formikProveedor.setFieldValue('rut', raw);
                      }
                    } else {
                      formikProveedor.handleChange(e);
                    }
                  }}
                  error={
                    formikProveedor.touched.rut && formikProveedor.errors.rut && Boolean(formikProveedor.errors.rut)
                  }
                  helperText={
                    formikProveedor.touched.rut && formikProveedor.errors.rut ? formikProveedor.errors.rut : ''
                  }
                />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                {/* DIRECCIÓN DEL PROVEEDOR */}
                <TextField
                  name="direccion"
                  label="Dirección"
                  fullWidth
                  variant={'outlined'}
                  value={formikProveedor.values.direccion}
                  onChange={formikProveedor.handleChange}
                  error={
                    formikProveedor.touched.direccion &&
                    formikProveedor.errors.direccion &&
                    Boolean(formikProveedor.errors.direccion)
                  }
                  helperText={
                    formikProveedor.touched.direccion && formikProveedor.errors.direccion
                      ? formikProveedor.errors.direccion
                      : ''
                  }
                />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                {/* COMUNA DEL PROVEEDOR */}
                <Autocomplete
                  name="comuna"
                  options={comunasSDA ? comunasSDA : []}
                  loading={isValidatingComunasSDA}
                  getOptionLabel={(comuna) => comuna.nombre}
                  onChange={(event, value) => formikProveedor.setFieldValue('comuna', value)}
                  noOptionsText="Sin Resultados"
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      label="Comuna"
                      variant="outlined"
                      error={
                        formikProveedor.touched.comuna &&
                        formikProveedor.errors.comuna &&
                        Boolean(formikProveedor.errors.comuna)
                      }
                      helperText={
                        formikProveedor.touched.comuna && formikProveedor.errors.comuna
                          ? formikProveedor.errors.comuna
                          : ''
                      }
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                {/* TELÉFONO DEL PROVEEDOR */}
                <TextField
                  name="telefono"
                  label="Teléfono"
                  fullWidth
                  variant={'outlined'}
                  value={formikProveedor.values.telefono}
                  onChange={formikProveedor.handleChange}
                  error={
                    formikProveedor.touched.telefono &&
                    formikProveedor.errors.telefono &&
                    Boolean(formikProveedor.errors.telefono)
                  }
                  helperText={
                    formikProveedor.touched.telefono && formikProveedor.errors.telefono
                      ? formikProveedor.errors.telefono
                      : ''
                  }
                />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                {/* EMAIL DEL PROVEEDOR */}
                <TextField
                  name="email_envio_oc"
                  label="Email"
                  fullWidth
                  variant={'outlined'}
                  value={formikProveedor.values.email_envio_oc}
                  onChange={formikProveedor.handleChange}
                  error={
                    formikProveedor.touched.email_envio_oc &&
                    formikProveedor.errors.email_envio_oc &&
                    Boolean(formikProveedor.errors.email_envio_oc)
                  }
                  helperText={
                    formikProveedor.touched.email_envio_oc && formikProveedor.errors.email_envio_oc
                      ? formikProveedor.errors.email_envio_oc
                      : ''
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleExitFormProveedor} variant="outlined" color="primary">
              Cancelar
            </Button>
            <Button onClick={formikProveedor.submitForm} variant="contained" color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </Fragment>
      )}
    </Dialog>
  );
}
