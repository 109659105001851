import React, { useContext } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import FileInput from '../../../components/fileInput';
import { VerBienContext } from '.';

function DialogoBaja(props) {
  const {
    bien,
    permirtirBaja,
    handleVerArchivoFactura,
    respaldoBaja,
    setRespaldoBaja,
    motivoBaja,
    setMotivoBaja,
    openDialogBaja,
    handleSolicitarBaja,
    handleAnularBaja,
    setOpenDialogBaja,
  } = useContext(VerBienContext);

  const classes = useStyles();

  const handleBaja = () => {
    handleSolicitarBaja(motivoBaja, respaldoBaja);
  };

  return (
    <Dialog open={openDialogBaja} onClose={() => setOpenDialogBaja(false)} fullWidth>
      <DialogTitle>Baja Contable</DialogTitle>
      <DialogContent dividers>
        <Typography>Formulario para realizar la baja del activo.</Typography>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.center}>
            {!permirtirBaja && (
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Ver Archivo"
                        onClick={() => handleVerArchivoFactura(bien.adjunto_baja)}
                        edge="end"
                        disabled={!bien.adjunto_baja || !bien.adjunto_baja.url ? true : false}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
                label="Respaldo de baja"
                fullWidth
                variant={'outlined'}
                margin={'normal'}
                value={bien.adjunto_baja ? bien.adjunto_baja.nombre : ''}
                disabled
              />
            )}
            {permirtirBaja && (
              <FormControl fullWidth>
                <InputLabel shrink className={classes.fileInput}>
                  Respaldo de Baja
                </InputLabel>
                <FileInput
                  inputName="file"
                  buttonColor="primary"
                  buttonVariant="outlined"
                  label="Respaldo "
                  value={respaldoBaja}
                  handleOnChange={(e) => setRespaldoBaja(e.target.files[0])}
                  textVariant="outlined"
                />
                <FormHelperText>Requerido si el recurso esta activado</FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.center}>
            {permirtirBaja && (
              <TextField
                name="motivo"
                label="Razón de baja"
                fullWidth
                variant={'outlined'}
                margin={'normal'}
                value={motivoBaja}
                rows={2}
                multiline={true}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setMotivoBaja(e.target.value)}
                helperText="Requerido para realizar la baja"
              />
            )}
            {!permirtirBaja && (
              <TextField
                name="motivo"
                label="Razón de baja"
                fullWidth
                variant={'outlined'}
                margin={'normal'}
                value={bien.motivo_baja}
                rows={2}
                multiline={true}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => setOpenDialogBaja(false)} color="primary" variant="outlined">
          Cancelar
        </Button>
        <Button
          autoFocus
          onClick={bien.is_eliminado ? handleAnularBaja : handleBaja}
          disabled={CheckButtonDisabled(
            permirtirBaja ? motivoBaja : bien.motivo_baja,
            bien,
            permirtirBaja ? respaldoBaja : bien.adjunto_baja,
          )}
          color="primary"
          variant="contained"
        >
          {bien.is_eliminado ? 'Anular Baja' : 'Dar de Baja'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * Método encargado de verificar si el botón está habilitado o deshabilitado.
 * @param {*} motivo Motivo para activar el bien.
 * @param {*} activo Datos del activo seleccionado.
 * @param {*} file Archivo de respaldo.
 * @returns FLAG que habilita o deshabilita el botón.
 */
function CheckButtonDisabled(motivo, activo, file) {
  if (activo && activo.is_activo_contable && !file) {
    return true;
  }
  if (!motivo) {
    return true;
  }
  if (motivo.length < 3) {
    return true;
  }
  return false;
}

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: 'center',
  },
  fileInput: {
    top: 'unset',
    left: 'unset',
    position: 'inherit',
    textAlign: 'left',
  },
}));

export default DialogoBaja;
