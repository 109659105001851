import {
  Dialog,
  Grid,
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { VerBienContext } from '.';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import MaterialReactSelect from '../../../components/materialReactSelect';
import { putBien } from '../../../services/request/requestBienes';
import { GenerarLog } from '../../../services/activoLog';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { MainContext } from '../../../App';
import { getClases } from '../../../services/request/requestClase';
import useSWR from 'swr';

function getData() {
  return getClases(
    {},
    ['nombre', '_grupo_contable_ref', '_tipo_recurso_ref'],
    ['_tipo_recurso_ref.nombre', '_grupo_contable_ref.nombre', '_grupo_contable_ref.sigla'],
  ).then((clases) => {
    const dataClases = [];
    clases.data.forEach((a) => {
      const data = {
        ...a,
        tipo_recurso: a._tipo_recurso_ref.nombre,
        grupo_contable: a._grupo_contable_ref
          ? a._grupo_contable_ref.sigla + ' - ' + a._grupo_contable_ref.nombre
          : '--',
      };
      dataClases.push(data);
    });
    return dataClases;
  });
}
export default function DialogoActualizarClase() {
  const {
    id,
    bien,
    openDialogoActualizarClase,
    setOpenDialogoActualizarClase,
    handleCloseDialogoActualizarClase,
    SetIsLoading,
  } = useContext(VerBienContext);

  const { usuarioSesion } = useContext(MainContext);
  const notistack = useSnackbar();
  const [nuevaClase, setNuevaClase] = useState(null);
  const [nuevoCodigoActivo, setNuevoCodigoActivo] = useState('');
  const [optionsClases, setOptionsClases] = useState([]);
  const { data: clases } = useSWR('get:clases-activos', (key) => getData());
  const [cambiarCodigoActivo, setCambiarCodigoActivo] = useState(false);

  const handleActualizarClase = async () => {
    try {
      SetIsLoading(true);

      const registroLog = {
        _bien_ref: id,
        titulo: 'Actualización de Clase del activo',
        cuerpo: `Se actualizó la clase del activo ${bien.codigo_activo} a ${nuevaClase.label} por el usuario: ${usuarioSesion.nombre}`,
        tipo: 'actualizacion',
        icon: 'edit',
      };
      const dataToUpdate = {
        clase: nuevaClase.label,
        _clase_ref: nuevaClase.value._id,
        _grupo_contable_ref: nuevaClase.value._grupo_contable_ref._id,
      };

      if (cambiarCodigoActivo) {
        dataToUpdate.codigo_activo = nuevoCodigoActivo;
      }

      await Promise.all([putBien(id, dataToUpdate), GenerarLog(registroLog)]);
      const successkey = notistack.enqueueSnackbar('Clase del activo actualizada exitosamente.', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
            <Close />
          </IconButton>
        ),
      });
    } catch (error) {
      console.error(error);
      const errorkey = notistack.enqueueSnackbar('Error al actualizar la Clase del activo.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        action: (
          <IconButton onClick={() => notistack.closeSnackbar(errorkey)}>
            <Close />
          </IconButton>
        ),
      });
    } finally {
      SetIsLoading(false);
      setCambiarCodigoActivo(false);
      setNuevaClase(null);
      setNuevoCodigoActivo('');
      setOpenDialogoActualizarClase(false);
    }
  };

  const handleOptionsClases = useCallback(() => {
    let options = [];

    if (clases && clases.length > 0) {
      options = clases.map((clase) => ({ label: clase.nombre, value: clase }));
    }
    setOptionsClases(options);
  }, [clases]);

  useEffect(() => {
    handleOptionsClases();
  }, [handleOptionsClases]);

  useEffect(() => {
    if (clases && bien) {
      const claseSeleccionada = clases.find((d) => d.nombre === bien.clase);
      setNuevaClase(claseSeleccionada ? { label: claseSeleccionada.nombre, value: claseSeleccionada } : null);
    }
    setNuevoCodigoActivo(bien?.codigo_activo);
  }, [clases, bien]);

  useEffect(() => {
    if (cambiarCodigoActivo && nuevaClase) {
      const siglaNueva = nuevaClase.value._grupo_contable_ref.sigla;
      const codigoActivoActual = bien.codigo_activo;
      const nuevoCodigo = siglaNueva + codigoActivoActual.substring(codigoActivoActual.indexOf('-'));
      setNuevoCodigoActivo(nuevoCodigo);
    } else if (!cambiarCodigoActivo) {
      setNuevoCodigoActivo(bien?.codigo_activo);
    }
  }, [cambiarCodigoActivo, nuevaClase, bien]);

  return (
    <Dialog open={openDialogoActualizarClase} fullWidth>
      <DialogTitle>Actualización de Clase del Activo</DialogTitle>
      <DialogContent dividers>
        <Typography>Seleccione la nueva Clase para el Activo. </Typography>
        <Grid container spacing={2} style={{ marginTop: 5, marginBottom: 5 }}>
          <Grid item xs>
            <MaterialReactSelect
              value={nuevaClase}
              label="Clase del Activo"
              isClearable
              onChange={(value) => setNuevaClase(value)}
              placeholder="Clase"
              options={optionsClases}
              noOptionsMessage={() => 'No ha seleccionado la Clase del activo'}
            />
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 5, marginBottom: 5 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography>Actualización de Código de Activo:</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography>Código Antiguo:</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{bien?.codigo_activo}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography>Código Nuevo:</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{nuevoCodigoActivo}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Grid item xs={12}>
              <Checkbox
                checked={cambiarCodigoActivo}
                onChange={(e) => setCambiarCodigoActivo(e.target.checked)}
                color="primary"
              />
              <Typography>Habilitar cambio de código de activo</Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCloseDialogoActualizarClase} color="primary" variant="outlined">
          Cancelar
        </Button>
        <Button
          autoFocus
          onClick={() => handleActualizarClase(nuevaClase, nuevoCodigoActivo)}
          color="primary"
          variant="contained"
        >
          Actualizar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
