import React, { useState, useEffect } from 'react';
import Page from './page';
import { obtenerPersonas, obtenerDetalleBien } from '../../../services/request/requestBienes';
import { useParams } from 'react-router';

function DetalleBienes() {
  const [Personas, SetPersona] = useState();
  const [Bien, SetBien] = useState();
  const { id: bienID } = useParams();

  useEffect(() => {
    obtenerPersonas()
      .then((response) => {
        const personas = Array.from(response);
        personas.forEach((persona, index_1) => {
          const checkContrato = persona.contratos_ref.length > 0;
          personas[index_1]['contrato'] = checkContrato ? persona.contratos_ref[0].codigo : '---';
        });
        SetPersona(personas);
      })
      .catch((error) => {
        console.error(error);
        SetPersona([]);
      });
  }, []);

  useEffect(() => {
    if (bienID) {
      Promise.all([obtenerDetalleBien(bienID)])
        .then((responses) => {
          const bien = responses[0];
          SetBien(bien);
        })
        .catch((error) => {
          console.error(error);
          SetBien([]);
        });
    }
  }, [bienID]);

  return <Page personas={Personas} bien={Bien} />;
}

export default DetalleBienes;
