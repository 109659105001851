import React, { useState, useContext } from 'react';
import MaterialTable from '../../../components/materialTable';
import DetalleNotificacion from './dialogos/detalleNotificacion';
import { Button } from '@material-ui/core';
import { red, yellow, green } from '@material-ui/core/colors/';
import { ListadoNotificacionesContext } from '.';

function MisNotificaciones() {
  const { notificaciones } = useContext(ListadoNotificacionesContext);

  const [notificacionSeleccionada, setNotificacionSeleccionada] = useState(null);
  const [OpenDialogoDetalle, setOpenDialogoDetalle] = useState(false);
  const COLORES_NIVEL = { Crítica: red[500], Atención: yellow[500], Normal: green[500] };
  const COLORES_ESTADOS = { Cancelada: red[500], Pausada: yellow[500], Activa: green[500] };

  function handleOpenDialogoDetalle(notificacion, open) {
    setNotificacionSeleccionada(notificacion);
    setOpenDialogoDetalle(open);
  }

  let columns = [
    {
      title: 'Código Activo',
      field: '_bien_ref.codigo_activo',
      align: 'center',
    },
    {
      title: 'Fecha de Creación',
      field: 'fecha_creacion',
      align: 'center',
      type: 'date',
      dateSetting: {
        format: 'dd/MM/yyyy',
      },
    },
    {
      title: 'Mensaje',
      field: 'mensaje',
      align: 'center',
    },
    {
      title: 'Estado',
      field: 'estado',
      align: 'center',
      render: (row) => {
        let style = {
          backgroundColor: COLORES_ESTADOS[row.estado],
          color: 'black',
        };
        return (
          <Button variant="contained" disabled onClick={() => {}} style={style}>
            {row.estado}
          </Button>
        );
      },
    },
    {
      title: 'Fecha de Evento',
      field: 'fecha_evento',
      align: 'center',
      type: 'date',
      dateSetting: {
        format: 'dd/MM/yyyy',
      },
    },
    {
      title: 'Tipo de Documento',
      field: 'tipo_documento',
      align: 'center',
    },
    {
      title: 'Nivel',
      field: 'nivel',
      align: 'center',
    },
  ];

  let actions = [
    {
      icon: 'chevron_right',
      tooltip: 'Ver Detalles',
      onClick: (event, row) => handleOpenDialogoDetalle(row, true),
    },
  ];

  return (
    <>
      <MaterialTable
        title="Listado de Notificaciones"
        data={notificaciones ? notificaciones : []}
        columns={columns}
        actions={actions}
        options={{
          padding: 'dense',
          rowStyle: (rowData) => ({
            backgroundColor: COLORES_NIVEL[rowData.nivel],
          }),
        }}
      />

      <DetalleNotificacion
        notificacionSeleccionada={notificacionSeleccionada}
        isOpen={OpenDialogoDetalle}
        handleOpen={handleOpenDialogoDetalle}
      />
    </>
  );
}

export default MisNotificaciones;
