import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { MainContext } from '../../App';
import { postRegistroKilometraje } from '../../services/request/requestBienes';
import { postMantencionVehiculo } from '../../services/request/requestMantencionVehiculo';
import { getMany, postOne } from '../../services/request/requestGestorPagos';
import { getManySGU } from '../../services/request/requestUsuarios';
import { getResponsableByID, getGerencia } from '../../services/request/requestPortafolio';
import { uploadFileToDrive } from '../../services/fileUpload';
import { GenerarLog } from '../../services/activoLog';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import MaterialReactSelect from '../../components/materialReactSelect';
import { Autocomplete } from '@material-ui/lab';
import { InfoVehiculo } from './components/InfoVehiculo';
import { DatosFactura } from './components/DatosFactura';
import DialogoVistaPreviaFactura from './dialogos/dialogoVistaPreviaFactura';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  contenedorPrincipal: {
    padding: 5,
  },
  items: {
    marginBottom: theme.spacing(0.7),
  },
  itemsMantenimiento: {
    marginRight: theme.spacing(0.3),
    padding: theme.spacing(1),
    border: '1px solid grey',
  },
  formselect: {
    marginTop: -16,
  },
  text_disabled: {
    '& input.Mui-disabled': {
      background: 'rgb(242, 246, 246)',
      color: 'grey',
    },
  },
  input_required: {
    background: 'rgb(247, 249, 216)',
    color: 'black',
  },
  select_color: {
    background: 'rgb(247, 249, 216)',
    color: 'black',
  },
}));

const Mantenciones_Programadas = [
  { label: 'Reparación', value: 'Reparación' },
  { label: 'No Programada', value: 'No Programada' },
];

export const IngresarFacturaContext = createContext({
  formik: {
    values: {},
    errors: {},
    handleSubmit: () => {},
    handleChange: () => {},
    touched: {},
    setFieldValue: () => {},
    siguienteAprobador: '',
    setSiguienteAprobador: () => {},
  },
});

export default function DialogoRegistroMantenimiento(props) {
  const { activo_seleccionado, proveedores, is_open, handle_open } = props;

  const { usuarioSesion } = useContext(MainContext);
  const [proveedoresOptions, setProveedoresOptions] = useState([]);
  const [rutOptions, setRutOptions] = useState([]);
  const [gerenciaUsuario, setGerenciaUsuario] = useState('');
  const [habilitaPatente, setHabilitaPatente] = useState(false);
  const [ultimoKM, setUltimoKM] = useState(0);
  const [siguienteAprobador, setSiguienteAprobador] = useState('');
  const [openDialogError, setOpenDialogError] = useState(false);
  const [openDialogFinCarga, setOpenDialogFinCarga] = useState(false);
  const [openVistaPrevia, setOpenVistaPrevia] = useState(false);
  const [dialogMessageError, setDialogMessageError] = useState('');
  const [isSubmiting, setIsSubminting] = useState(false);
  const [openDialogArchivoProtegido, setOpenDialogArchivoProtegido] = useState(false);
  const notistack = useSnackbar();
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      _vehiculo_ref: '',
      _bien_ref: null,
      _ingresado_por_ref: null,
      _contrato_ref: null,
      codigo_contrato: '',
      estado: '',
      archivo: null,
      archivo_orden_trabajo: null,
      patente: '',
      codigo_activo: '',
      color: '',
      marca: '',
      modelo: '',
      ano: moment().startOf('year').set('month', 4).toDate(),
      kilometraje: 0,
      fecha_ingreso: new Date(),
      fecha_entrega: new Date(),
      proveedor: null,
      proveedor_nombre: '',
      rut_proveedor: '',
      otro_proveedor: false,
      mantencion_programada: '',
      tipo_rendicion: '',
      tipo_documento: '',
      numero_documento: '',
      plazo_pago: '',
      fecha_documento: new Date(),
      es_exenta: true,
      monto_documento: 0,
      monto_neto: 0,
      iva_documento: 0,
      descripcion_gasto: '',
      orden_trabajo: '',
      descripcion: '',
      sistema_luces: false,
      espejos: false,
      llantas: false,
      carroceria: false,
      plumillas: false,
      parabrisas: false,
      neumaticos: false,
      tablero: false,
      calefaccion: false,
      bocinas: false,
      espejo_retrovisor: false,
      cinturones: false,
      radio: false,
      air_bags: false,
      gata: false,
      kit_herramientas: false,
      llantas_accesorios: false,
      extintor: false,
      triangulo_seguridad: false,
      chaleco_reflectante: false,
      radiador: false,
      bateria: false,
      filtros: false,
      sistema_frenos: false,
      correo_accesorios: false,
      limpieza_dpf: false,
      kit_embriague: false,
      aceite_motor: false,
      fluido_frenos: false,
      refrigerante: false,
      aceite_hidraulico: false,
      otro: false,
      otro_servicio: '',
      //SGP
      clasificacion_factura: '',
      clasificacion_factura_obj: '',
      monto_total_dolares: 0,
      tipo_cambio: 0,
      sociedad: '',
      es_borrador: false,
      es_reembolsable_mandate: false,
      caja_chica: '',
      fondo_rendir: '',
      cuenta_banco: '',
      _caja_chica_ref: null,
      _cuenta_banco_usuario_ref: null,
      _fondo_rendir_ref: null,
      monto_caja_chica: 0,
      monto_fondo_rendir: 0,
      tiene_divisiones: false,
      contrato: '',
      sociedad_obj: '',
      _usuario_emisor_ref: null,
      _usuario_jefe_proyecto_ref: null,
      _usuario_gerente_ref: null,
      codigo_orden_compra: '',
      id_orden_compra: '',
      aprobador_sda: null,
      codigo_solicitud_recurso: null,
      id_usuario_aprobador_sda: null,
      dias_vencimiento: 0,
      fecha_vencimiento: '',
      comentario: '',
      es_flujo_gaf: false,
      tiene_monto_por_distribuir: false,
      archivo_distribucion_monto: null,
      file_distribucion: null,
      _usuario_administrador_contrato_ref: null,
      _usuario_sub_gerente_ref: null,
      es_flujo_gpo: false,
      cuenta_banco_usuario: null,
    },
    validationSchema: yup.object().shape({
      kilometraje: yup
        .number()
        .typeError('Debe ingresar un valor numérico')
        .min(ultimoKM + 1, 'Debe ingresar un kilometraje mayor al actual')
        .required('Debe ingresar el Kilometraje'),
      tipo_documento: yup.string().required('Debe seleccionar el tipo de documento a registrar'),
      numero_documento: yup
        .string()
        .min(1, 'El número de documento es demasiado corto')
        .required('Debe ingresar un número de documento'),
      proveedor_nombre: yup.mixed().required('Debe seleccionar un Proveedor'),
      proveedor: yup.mixed().required('Debe seleccionar un Proveedor'),
      mantencion_programada: yup.mixed().required('Debe seleccionar un tipo de Mantención'),
      monto_documento: yup
        .number()
        .typeError('Debe ingresar un valor numérico')
        .min(1, 'Debe ingresar un valor superior a cero')
        .required('Campo requerido'),
      tipo_rendicion: yup.string().when('tipo_documento', {
        is: (tipo_documento) => tipo_documento === 'Factura',
        then: yup.string().required('Debe seleccionar el tipo de rendición'),
      }),
      sociedad_obj: yup.mixed().when('tipo_documento', {
        is: (tipo_documento) => tipo_documento === 'Factura',
        then: yup.mixed().required('Debe seleccionar una sociedad'),
      }),
      fecha_documento: yup.mixed().when('tipo_documento', {
        is: (tipo_documento) => tipo_documento === 'Factura',
        then: yup.mixed().required('Debe ingresar la fecha de la factura'),
      }),
      contrato: yup.mixed().when('tipo_documento', {
        is: (tipo_documento) => tipo_documento === 'Factura',
        then: yup.mixed().required('Debe seleccionar un contrato'),
      }),
      archivo: yup.mixed().required('Debes ingresar la factura/boleta'),
    }),
    onSubmit: async (values) => {
      setOpenVistaPrevia(false);
      setIsSubminting(true);

      const key = notistack.enqueueSnackbar('Guardando cambios...', {
        persist: true,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
      });
      try {
        let uploadedFile = null;
        let uploadFileOrdenTrabajo = null;
        let estadoRegistro = '';

        if (activo_seleccionado) {
          const registroLog = {
            _bien_ref: activo_seleccionado._id,
            titulo: 'Registro Mantención Vehicular',
            cuerpo: `El usuario ${usuarioSesion.nombre} registró una nueva mantención para el vehículo: ${activo_seleccionado.codigo_activo}.`,
            tipo: 'actualizacion',
            icon: 'edit',
          };
          if (activo_seleccionado._vehiculo_ref) {
            //Si se registra una Factura se envia al Gestor de Pagos, sino, solo se guarda el mantenimiento
            if (values.tipo_documento === 'Factura') {
              let gerencia = null;
              let estado = 'V°B° Revisor 1';
              if (values.contrato.value) {
                //Si el contrato tiene los aprobadores de factura definidos
                if (
                  values.contrato.value.aprobadores_factura &&
                  values.contrato.value.aprobadores_factura.aprobadores
                ) {
                  //Se obtiene y define el primer aprobador
                  const personaContrato = await getManySGU('/personas', {
                    usuario_id: values.contrato.value.aprobadores_factura.aprobadores[0].uid,
                  });
                  if (personaContrato && personaContrato.length > 0) {
                    values._usuario_jefe_proyecto_ref = personaContrato[0]._id;
                  }
                  //Si el aprobador es uno solo se asigna como final
                  if (values.contrato.value.aprobadores_factura.aprobadores.length === 1) {
                    estado = 'V°B° Revisor Final';
                    values._usuario_gerente_ref = personaContrato[0]._id;
                  }
                  //Si son dos aprobadores se asignan el siguiente aprobador 2
                  else if (values.contrato.value.aprobadores_factura.aprobadores.length === 2) {
                    const personaGerencia = await getManySGU('/personas', {
                      usuario_id: values.contrato.value.aprobadores_factura.aprobadores[1].uid,
                    });
                    if (personaGerencia && personaGerencia.length > 0) {
                      values._usuario_gerente_ref = personaGerencia[0]._id;
                    }
                  }
                  //Si son dos aprobadores se asignan el siguiente aprobador 2 y 3
                  else if (values.contrato.value.aprobadores_factura.aprobadores.length === 3) {
                    const personaAdministrador = await getManySGU('/personas', {
                      usuario_id: values.contrato.value.aprobadores_factura.aprobadores[1].uid,
                    });
                    if (personaAdministrador && personaAdministrador.length > 0) {
                      values._usuario_administrador_contrato_ref = personaAdministrador[0]._id;
                    }
                    const personaGerencia = await getManySGU('/personas', {
                      usuario_id: values.contrato.value.aprobadores_factura.aprobadores[2].uid,
                    });
                    if (personaGerencia && personaGerencia.length > 0) {
                      values._usuario_gerente_ref = personaGerencia[0]._id;
                    }
                  }
                  //Si son dos aprobadores se asignan el siguiente aprobador 2,3 y 4
                  else if (values.contrato.value.aprobadores_factura.aprobadores.length === 4) {
                    const personaAdministrador = await getManySGU('/personas', {
                      usuario_id: values.contrato.value.aprobadores_factura.aprobadores[1].uid,
                    });
                    if (personaAdministrador && personaAdministrador.length > 0) {
                      values._usuario_administrador_contrato_ref = personaAdministrador[0]._id;
                    }
                    const personaSubGerente = await getManySGU('/personas', {
                      usuario_id: values.contrato.value.aprobadores_factura.aprobadores[2].uid,
                    });
                    if (personaSubGerente && personaSubGerente.length > 0) {
                      values._usuario_sub_gerente_ref = personaSubGerente[0]._id;
                    }
                    const personaGerencia = await getManySGU('/personas', {
                      usuario_id: values.contrato.value.aprobadores_factura.aprobadores[3].uid,
                    });
                    if (personaGerencia && personaGerencia.length > 0) {
                      values._usuario_gerente_ref = personaGerencia[0]._id;
                    }
                  }
                }
                //Si los aprobadores de facturas no están definidos se asigna el jefe de proyecto como primer aprobador, el flujo son solo dos aprobadores
                else {
                  let responsableContrato = values.contrato ? values.contrato.value.responsable_ref : null;
                  if (responsableContrato) {
                    const responseResponsableContrato = await getResponsableByID(responsableContrato);
                    if (responseResponsableContrato) {
                      const personaContrato = await getManySGU('/personas', {
                        usuario_id: responseResponsableContrato.persona.usuario_id,
                      });
                      if (personaContrato && personaContrato.length > 0) {
                        values._usuario_jefe_proyecto_ref = personaContrato[0]._id;
                      }
                    }
                  }
                }
                //Obtener datos de la gerencia
                gerencia = await getGerencia(values.contrato ? values.contrato.value.gerencia_ref : null);
                if (gerencia) {
                  let uidGerencia = gerencia.responsable_ref.persona.usuario_id;
                  //Si no habían aprobadores de facturas se asigna al gerente como aprobador final
                  if (!values._usuario_gerente_ref) {
                    const personaGerencia = await getManySGU('/personas', { usuario_id: uidGerencia });
                    if (personaGerencia && personaGerencia.length > 0) {
                      values._usuario_gerente_ref = personaGerencia[0]._id;
                    }
                  }
                }
              }
              //Si la factura no tiene contrato, la revisa GAF
              else {
                estado = 'V°B° Jefatura GAF';
                values._usuario_jefe_proyecto_ref = '5e0f291a70edaf00137fd297';
                values._usuario_gerente_ref = '5e0f291a70edaf00137fd2f5';
              }

              //Enviar factura a gaf si el usuario lo indica
              if (values.es_flujo_gaf) {
                estado = 'V°B° Jefatura GAF';
                values._usuario_jefe_proyecto_ref = '5e0f291a70edaf00137fd297';
                values._usuario_gerente_ref = '5e0f291a70edaf00137fd2f5';
              }
              //Enviar factura a gpo si el usuario lo indica
              if (values.es_flujo_gpo) {
                estado = 'V°B° Gerencia GPO';
                values._usuario_jefe_proyecto_ref = '5efe01e94ca4030011ba70f8';
                values._usuario_gerente_ref = '5efe01e94ca4030011ba70f8';
              }
              //Si la factura no tiene fecha de vencimiento, se toma al contado(Fecha vencimiento = fecha factura)
              if (!values.fecha_vencimiento) values.fecha_vencimiento = values.fecha_documento;

              if (values.archivo) {
                let ext = '';
                ext =
                  values.archivo.name.substring(values.archivo.name.lastIndexOf('.') + 1, values.archivo.name.length) ||
                  values.archivo.name;
                let filename = values.numero_documento + '.' + ext;
                let ruta = 'facturas/' + values.proveedor_nombre + '/' + values.numero_documento;
                uploadedFile = await uploadFileToDrive(values.archivo, ruta, filename, 'informa-pago-facturas');
              }

              if (values.proveedor_nombre && values.rut_proveedor) {
                values.proveedor = proveedores.find((prov) => prov.rut === values.rut_proveedor);
              }

              //Si tiene asociada una OC del SDA tiene estado Procesando Pago
              if (values.tipo_rendicion === 'Pago directo a proveedor (Orden giro)' && values.aprobador_sda)
                estado = 'Procesando Pago';

              estadoRegistro = estado;

              const datosPago = {
                estado,
                tipo_rendicion: values.tipo_rendicion,
                numero_factura: values.numero_documento,
                monto_total: values.monto_documento ? values.monto_documento : 0, // ? parseInt(values.monto_documento.toString().replace(/\./g, '')) : 0,
                monto_iva: values.iva_documento ? parseInt(values.iva_documento.toString().replace(/\./g, '')) : 0,
                monto_neto: values.monto_neto,
                monto_total_dolares: 0,
                clasificacion_factura: 'Mantención Vehículos',
                es_exenta: values.iva_documento === '0',
                descripcion: `Pago de Mantenimiento para el Vehículo: ${activo_seleccionado.codigo_activo}, patente: ${values.patente} gestionado a través del Gestor de Activos.`,
                comentario: ' ',
                dias_vencimiento: values.dias_vencimiento,
                fecha_factura: values.fecha_documento,
                fecha_vencimiento: values.fecha_vencimiento,
                _contrato_revision_ref: values.contrato.value ? values.contrato.value._id : null,
                _gerencia_revision_ref: values.contrato.value ? values.contrato.value.gerencia_ref : null,
                _sociedad_ref: values.sociedad_obj?.value._id,
                contrato: values.contrato.value ? values.contrato.value.nombre : '',
                gerencia: gerencia ? gerencia.nombre : '',
                sociedad: values.sociedad_obj.label,
                _usuario_emisor_ref: usuarioSesion._id,
                nombre_usuario_emisor: usuarioSesion.nombre,
                tiene_monto_por_distribuir: false,
                archivo_distribucion_monto: null,
                proveedor: {
                  nombre: values.proveedor.nombre,
                  rut: values.proveedor.rut,
                  correo_transferencia: values.proveedor.banco_email,
                  numero_cuenta: values.proveedor.banco_numero_cuenta,
                  tipo_cuenta: values.proveedor.banco_tipo_cuenta,
                  banco: values.proveedor.banco_nombre,
                },
                cuenta_banco_usuario: values.cuenta_banco_usuario,
                _cuenta_banco_usuario_ref: values._cuenta_banco_usuario_ref,
                codigo_orden_compra: values.codigo_orden_compra,
                id_orden_compra: values.id_orden_compra,
                aprobador_sda: values.aprobador_sda,
                id_usuario_aprobador_sda: values.id_usuario_aprobador_sda,
                codigo_solicitud_recurso: values.codigo_solicitud_recurso,
                archivo_factura: uploadedFile,
                _usuario_jefe_proyecto_ref: values._usuario_jefe_proyecto_ref,
                _usuario_gerente_ref: values._usuario_gerente_ref,
                es_flujo_gaf: false,
                _usuario_administrador_contrato_ref: values._usuario_administrador_contrato_ref,
                _usuario_sub_gerente_ref: values._usuario_sub_gerente_ref,
              };
              const responseFactura = await postOne('/facturas', datosPago);

              if (!responseFactura.status) {
                setOpenDialogError(true);
                setDialogMessageError('Problemas al guardar la Factura en el Gestor de Pago.');
                setIsSubminting(false);
                notistack.closeSnackbar(key);
                return;
              }
              notistack.closeSnackbar(key);
              const Facturakey = notistack.enqueueSnackbar(
                'Factura ingresada correctamente, ahora guardando datos del mantenimiento...',
                {
                  variant: 'success',
                  anchorOrigin: {
                    horizontal: 'center',
                    vertical: 'bottom',
                  },
                  action: (
                    <IconButton onClick={() => notistack.closeSnackbar(Facturakey)}>
                      <Close />
                    </IconButton>
                  ),
                },
              );
            } else {
              estadoRegistro = 'Ingresado';
              if (values.archivo) {
                uploadedFile = await uploadFileToDrive(
                  values.archivo,
                  `activos/${values.codigo_activo}/${values.archivo.name}`,
                );
              }
            }

            const usuario_gerencia = await getGerencia(usuarioSesion.gerencia);

            if (values.archivo_orden_trabajo) {
              uploadFileOrdenTrabajo = await uploadFileToDrive(
                values.archivo_orden_trabajo,
                `activos/${activo_seleccionado.codigo_activo}/${values.archivo.name}`,
              );
            }
            await postMantencionVehiculo({
              _vehiculo_ref: activo_seleccionado._vehiculo_ref._id,
              _bien_ref: values._bien_ref,
              _ingresado_por_ref: usuarioSesion.ref,
              estado: estadoRegistro,
              archivo: uploadedFile,
              orden_trabajo_file: uploadFileOrdenTrabajo,
              kilometraje: values.kilometraje,
              fecha_ingreso: values.fecha_ingreso,
              folio: usuario_gerencia ? usuario_gerencia?.sigla : '',
              fecha_entrega: values.fecha_entrega,
              proveedor: values.proveedor_nombre,
              rut_proveedor: values.rut_proveedor,
              otro_proveedor: values.otro_proveedor,
              mantencion_programada: values.mantencion_programada,
              tipo_documento: values.tipo_documento,
              numero_documento: values.numero_documento,
              plazo_pago: values.dias_vencimiento,
              fecha_documento: values.fecha_documento,
              monto_documento: values.monto_documento, //? parseInt(values.monto_documento.toString().replace(/\./g, '')) : 0,
              monto_neto: values.monto_neto,
              iva_documento: values.iva_documento ? parseInt(values.iva_documento.toString().replace(/\./g, '')) : 0,
              tipo_rendicion: values.tipo_rendicion,
              descripcion_gasto: values.descripcion_gasto,
              orden_trabajo: values.orden_trabajo,
              descripcion: values.descripcion,
              sistema_luces: values.sistema_luces,
              espejos: values.espejos,
              llantas: values.llantas,
              carroceria: values.carroceria,
              plumillas: values.plumillas,
              parabrisas: values.parabrisas,
              neumaticos: values.neumaticos,
              tablero: values.tablero,
              calefaccion: values.calefaccion,
              bocinas: values.bocinas,
              espejo_retrovisor: values.espejo_retrovisor,
              cinturones: values.cinturones,
              radio: values.radio,
              air_bags: values.air_bags,
              gata: values.gata,
              kit_herramientas: values.kit_herramientas,
              llantas_accesorios: values.llantas_accesorios,
              extintor: values.extintor,
              triangulo_seguridad: values.triangulo_seguridad,
              chaleco_reflectante: values.chaleco_reflectante,
              radiador: values.radiador,
              bateria: values.bateria,
              filtros: values.filtros,
              sistema_frenos: values.sistema_frenos,
              correo_accesorios: values.correo_accesorios,
              limpieza_dpf: values.limpieza_dpf,
              kit_embriague: values.kit_embriague,
              aceite_motor: values.aceite_motor,
              fluido_frenos: values.fluido_frenos,
              refrigerante: values.refrigerante,
              aceite_hidraulico: values.aceite_hidraulico,
              otro: values.otro,
              otro_servicio: values.otro_servicio,
            });

            await postRegistroKilometraje({
              _vehiculo_ref: activo_seleccionado._vehiculo_ref._id,
              _bien_ref: values._bien_ref, // activo_seleccionado._id,
              kilometraje: values.kilometraje,
              fecha_kilometraje: new Date(),
              descripcion: '',
              foto_tacometro: null,
            });
          }
          const registroLogKM = {
            _bien_ref: activo_seleccionado._id,
            titulo: 'Actualización de Kilometraje',
            cuerpo: `Se actualizó automáticamente el kilometraje del activo ${activo_seleccionado.codigo_activo} a ${values.kilometraje} Kms. al registrar un nuevo mantenimiento`,
            tipo: 'actualizacion',
            icon: 'edit',
          };
          await GenerarLog(registroLog);
          await GenerarLog(registroLogKM);
          handleClose();
        }

        notistack.closeSnackbar(key);
        const successkey = notistack.enqueueSnackbar('Operación exitosa: Mantenimiento registrado', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
              <Close />
            </IconButton>
          ),
        });
      } catch (e) {
        console.log(e);
        notistack.closeSnackbar(key);
        const errorkey = notistack.enqueueSnackbar(
          'Error: No ha sido posible registrar el mantenimiento. Intente de nuevo o contacte con Soporte.',
          {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            action: (
              <IconButton onClick={() => notistack.closeSnackbar(errorkey)}>
                <Close />
              </IconButton>
            ),
          },
        );
      }
    },
  });

  const {
    handleSubmit,
    values,
    touched,
    errors,
    setFieldValue,
    resetForm,
    handleChange,
    validateForm,
    setErrors,
    setTouched,
  } = formik;

  const resetFormulario = useCallback(async () => {
    let optionsProveedor = [];
    let optionsRut = [];
    if (proveedores) {
      optionsProveedor = proveedores
        .filter((value) => value.tipo_recurso === 'Vehículos')
        .map((value) => (value.nombre ? value?.nombre : ''));
      optionsRut = proveedores
        .filter((value) => value.tipo_recurso === 'Vehículos')
        .map((value) => (value.rut ? value?.rut : ''));
    }
    const usuario_gerencia = await getGerencia(usuarioSesion.gerencia);

    setProveedoresOptions(optionsProveedor);
    setRutOptions(optionsRut);

    const mes = moment().format('MM');
    const año = moment().format('YY');

    setGerenciaUsuario(`${usuario_gerencia.sigla}-MAN-${mes}${año}-0XXXX`);
  }, [proveedores]);

  useEffect(() => {
    resetFormulario();
  }, [resetFormulario]);

  const addMantenimientosArray = () => {
    if (Mantenciones_Programadas.length > 2) Mantenciones_Programadas.length = 2;

    Mantenciones_Programadas.push({ label: '5.000 Km', value: '5.000 Km' });

    for (let i = 1; i < 20; i++) Mantenciones_Programadas.push({ label: `${i}0.000 Km`, value: `${i}0.000 Km` });
  };

  function handleEntered() {
    activo_seleccionado ? setHabilitaPatente(true) : setHabilitaPatente(false);

    const values = {
      _vehiculo_ref: activo_seleccionado && activo_seleccionado?._vehiculo_ref ? activo_seleccionado._vehiculo_ref : '',
      _bien_ref: activo_seleccionado
        ? activo_seleccionado?._bien_ref
          ? activo_seleccionado?._bien_ref
          : activo_seleccionado?._id
        : '',
      _contrato_ref: activo_seleccionado
        ? activo_seleccionado?._contrato_ref
          ? activo_seleccionado?._contrato_ref
          : null
        : null,
      codigo_contrato: activo_seleccionado
        ? activo_seleccionado?.codigo_contrato
          ? activo_seleccionado?.codigo_contrato
          : ''
        : '',
      marca: activo_seleccionado
        ? activo_seleccionado?._bien_ref
          ? activo_seleccionado?._bien_ref.marca
          : activo_seleccionado?.marca
        : '',
      modelo: activo_seleccionado
        ? activo_seleccionado?._bien_ref
          ? activo_seleccionado?._bien_ref.modelo
          : activo_seleccionado?.modelo
        : '',
      patente: activo_seleccionado ? activo_seleccionado?._vehiculo_ref?.patente : '',
      codigo_activo: activo_seleccionado
        ? activo_seleccionado?._bien_ref
          ? activo_seleccionado?._bien_ref.codigo_activo
          : activo_seleccionado?.codigo_activo
        : '',
      color: activo_seleccionado ? activo_seleccionado?._vehiculo_ref?.color : '',
      ano: activo_seleccionado
        ? activo_seleccionado?.ano
          ? activo_seleccionado?.ano
          : moment().startOf('year').set('month', 4).toDate()
        : moment().startOf('year').set('month', 4).toDate(),
      archivo: null,
      kilometraje: activo_seleccionado ? (activo_seleccionado?.kilometraje ? activo_seleccionado?.kilometraje : 0) : 0,
      fecha_ingreso: new Date(),
      fecha_entrega: new Date(),
      proveedor_nombre: activo_seleccionado
        ? activo_seleccionado?.proveedor
          ? activo_seleccionado?.proveedor
          : ''
        : '',
      rut_proveedor: activo_seleccionado
        ? activo_seleccionado?.rut_proveedor
          ? activo_seleccionado?.rut_proveedor
          : ''
        : '',
      proveedor: null,
      otro_proveedor: activo_seleccionado
        ? activo_seleccionado?.otro_proveedor
          ? activo_seleccionado?.otro_proveedor
          : false
        : false,
      mantencion_programada: activo_seleccionado
        ? activo_seleccionado?.mantencion_programada
          ? activo_seleccionado?.mantencion_programada
          : ''
        : '',
      tipo_documento: activo_seleccionado
        ? activo_seleccionado?.tipo_documento
          ? activo_seleccionado?.tipo_documento
          : ''
        : '',
      numero_documento: activo_seleccionado
        ? activo_seleccionado?.numero_documento
          ? activo_seleccionado?.numero_documento
          : ''
        : '',
      plazo_pago: activo_seleccionado ? (activo_seleccionado?.plazo_pago ? activo_seleccionado?.plazo_pago : '') : '',
      fecha_documento: activo_seleccionado
        ? activo_seleccionado?.fecha_documento
          ? activo_seleccionado?.fecha_documento
          : new Date()
        : new Date(), //new Date(),
      monto_documento: activo_seleccionado
        ? activo_seleccionado?.monto_documento
          ? activo_seleccionado?.monto_documento
          : 0
        : 0,
      //monto_neto: activo_seleccionado ? (activo_seleccionado?.monto_neto ? activo_seleccionado?.monto_neto : 0) : 0,
      //iva_documento:activo_seleccionado ? (activo_seleccionado?.iva_documento ? activo_seleccionado?.iva_documento : 0) : 0,
      descripcion_gasto: activo_seleccionado
        ? activo_seleccionado?.descripcion_gasto
          ? activo_seleccionado?.descripcion_gasto
          : ''
        : '',
      orden_trabajo: activo_seleccionado
        ? activo_seleccionado?.orden_trabajo
          ? activo_seleccionado?.orden_trabajo
          : ''
        : '',
      descripcion: activo_seleccionado
        ? activo_seleccionado?.descripcion
          ? activo_seleccionado?.descripcion
          : ''
        : '',
      sistema_luces: activo_seleccionado
        ? activo_seleccionado?.sistema_luces
          ? activo_seleccionado?.sistema_luces
          : false
        : false,
      espejos: activo_seleccionado ? (activo_seleccionado?.espejos ? activo_seleccionado?.espejos : false) : false,
      llantas: activo_seleccionado ? (activo_seleccionado?.llantas ? activo_seleccionado?.llantas : false) : false,
      carroceria: activo_seleccionado
        ? activo_seleccionado?.carroceria
          ? activo_seleccionado?.carroceria
          : false
        : false,
      plumillas: activo_seleccionado
        ? activo_seleccionado?.plumillas
          ? activo_seleccionado?.plumillas
          : false
        : false,
      parabrisas: activo_seleccionado
        ? activo_seleccionado?.parabrisas
          ? activo_seleccionado?.parabrisas
          : false
        : false,
      neumaticos: activo_seleccionado
        ? activo_seleccionado?.neumaticos
          ? activo_seleccionado?.neumaticos
          : false
        : false,
      tablero: activo_seleccionado ? (activo_seleccionado?.tablero ? activo_seleccionado?.tablero : false) : false,
      calefaccion: activo_seleccionado
        ? activo_seleccionado?.calefaccion
          ? activo_seleccionado?.calefaccion
          : false
        : false,
      bocinas: activo_seleccionado ? (activo_seleccionado?.bocinas ? activo_seleccionado?.bocinas : false) : false,
      cinturones: activo_seleccionado
        ? activo_seleccionado?.cinturones
          ? activo_seleccionado?.cinturones
          : false
        : false,
      radio: activo_seleccionado ? (activo_seleccionado?.radio ? activo_seleccionado?.radio : false) : false,
      gata: activo_seleccionado ? (activo_seleccionado?.gata ? activo_seleccionado?.gata : false) : false,
      espejo_retrovisor: activo_seleccionado
        ? activo_seleccionado?.espejo_retrovisor
          ? activo_seleccionado?.espejo_retrovisor
          : false
        : false,
      air_bags: activo_seleccionado ? (activo_seleccionado?.air_bags ? activo_seleccionado?.air_bags : false) : false,
      kit_herramientas: activo_seleccionado
        ? activo_seleccionado?.kit_herramientas
          ? activo_seleccionado?.kit_herramientas
          : false
        : false,
      extintor: activo_seleccionado ? (activo_seleccionado?.extintor ? activo_seleccionado?.extintor : false) : false,
      radiador: activo_seleccionado ? (activo_seleccionado?.radiador ? activo_seleccionado?.radiador : false) : false,
      bateria: activo_seleccionado ? (activo_seleccionado?.bateria ? activo_seleccionado?.bateria : false) : false,
      filtros: activo_seleccionado ? (activo_seleccionado?.filtros ? activo_seleccionado?.filtros : false) : false,
      refrigerante: activo_seleccionado
        ? activo_seleccionado?.refrigerante
          ? activo_seleccionado?.refrigerante
          : false
        : false,
      llantas_accesorios: activo_seleccionado
        ? activo_seleccionado?.llantas_accesorios
          ? activo_seleccionado?.llantas_accesorios
          : false
        : false,
      triangulo_seguridad: activo_seleccionado
        ? activo_seleccionado?.triangulo_seguridad
          ? activo_seleccionado?.triangulo_seguridad
          : false
        : false,
      chaleco_reflectante: activo_seleccionado
        ? activo_seleccionado?.chaleco_reflectante
          ? activo_seleccionado?.chaleco_reflectante
          : false
        : false,
      sistema_frenos: activo_seleccionado
        ? activo_seleccionado?.sistema_frenos
          ? activo_seleccionado?.sistema_frenos
          : false
        : false,
      correo_accesorios: activo_seleccionado
        ? activo_seleccionado?.correo_accesorios
          ? activo_seleccionado?.correo_accesorios
          : false
        : false,
      limpieza_dpf: activo_seleccionado
        ? activo_seleccionado?.limpieza_dpf
          ? activo_seleccionado?.limpieza_dpf
          : false
        : false,
      kit_embriague: activo_seleccionado
        ? activo_seleccionado?.kit_embriague
          ? activo_seleccionado?.kit_embriague
          : false
        : false,
      aceite_motor: activo_seleccionado
        ? activo_seleccionado?.aceite_motor
          ? activo_seleccionado?.aceite_motor
          : false
        : false,
      fluido_frenos: activo_seleccionado
        ? activo_seleccionado?.fluido_frenos
          ? activo_seleccionado?.fluido_frenos
          : false
        : false,
      aceite_hidraulico: activo_seleccionado
        ? activo_seleccionado?.aceite_hidraulico
          ? activo_seleccionado?.aceite_hidraulico
          : false
        : false,
      otro: activo_seleccionado ? (activo_seleccionado?.otro ? activo_seleccionado?.otro : false) : false,
      otro_servicio: activo_seleccionado
        ? activo_seleccionado?.otro_servicio
          ? activo_seleccionado?.otro_servicio
          : ''
        : '',
    };

    resetForm({ values });

    setUltimoKM(values.kilometraje);
    setIsSubminting(false);
    addMantenimientosArray();
  }

  const handleChangeProv = (Valor, tipo) => {
    let proveedor_buscado = null;

    if (Valor) {
      switch (tipo) {
        case 'Nombre':
          setFieldValue('proveedor_nombre', Valor);
          proveedor_buscado = proveedores.find((proveedor) => proveedor?.nombre === Valor);
          break;
        case 'Rut':
          setFieldValue('rut_proveedor', Valor);
          proveedor_buscado = proveedores.find((proveedor) => proveedor?.rut === Valor);
          break;
        default:
      }

      if (proveedor_buscado) {
        setFieldValue('proveedor_nombre', proveedor_buscado.nombre);
        setFieldValue('rut_proveedor', proveedor_buscado.rut);
        setFieldValue('proveedor', proveedor_buscado);
      } else {
        setFieldValue('proveedor_nombre', '');
        setFieldValue('rut_proveedor', '');
        setFieldValue('proveedor', null);
      }
    } else {
      setFieldValue('proveedor_nombre', '');
      setFieldValue('rut_proveedor', '');
      setFieldValue('proveedor', null);
    }
  };

  const dateChange = (name, e) => {
    formik.setFieldValue(name, e);
    formik.setFieldTouched(name, true, false);
  };

  const handleClose = () => {
    resetForm({ values: formik.initialValues });
    setUltimoKM(0);
    setIsSubminting(false);
    setOpenVistaPrevia(false);
    handle_open(null, false);
  };

  async function checkFactura() {
    const key = notistack.enqueueSnackbar('Revisando factura...', {
      persist: true,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    });
    //console.log("Checkeando la Factura...");
    const result = await validateForm();
    function isEmpty(obj) {
      if (obj) {
        for (let o in obj) {
          return false;
        }
      }

      return true;
    }
    function mapObj(obj, mapFuncion) {
      const newObj = {};
      if (obj) {
        for (let o in obj) {
          newObj[o] = mapFuncion(o);
        }
      }

      return newObj;
    }
    if (!isEmpty(result)) {
      setErrors(result);
      setTouched(mapObj(result, (value) => true));
      notistack.closeSnackbar(key);
    } else {
      const responseCheckFactura = await getMany('/facturas', {
        'proveedor.rut_eq': values.rut_proveedor,
        numero_factura_eq: values.numero_documento,
        eliminado_eq: false,
      });
      if (responseCheckFactura && responseCheckFactura.status && responseCheckFactura.data.length > 0) {
        setOpenDialogError(true);
        setDialogMessageError('Ya se ha ingresado una factura con ese número y proveedor');
        setIsSubminting(false);
        notistack.closeSnackbar(key);
        return;
      }
      if (
        values.tipo_rendicion === 'Pago directo a proveedor (Orden giro)' &&
        (!values.cuenta_banco_usuario ||
          !values.cuenta_banco_usuario.numero_cuenta ||
          !values.cuenta_banco_usuario.tipo_cuenta ||
          !values.cuenta_banco_usuario.correo)
      ) {
        setOpenDialogError(true);
        setDialogMessageError('Problemas con el proveedor escogido, verifique por favor los datos de pago de este.');
        setIsSubminting(false);
        notistack.closeSnackbar(key);
        return;
      }

      if (
        values.archivo &&
        values.archivo.type !== 'application/pdf' &&
        values.archivo.type !== 'image/jpeg' &&
        values.archivo.type !== 'image/png'
      ) {
        setOpenDialogError(true);
        setDialogMessageError(
          'El Archivo de Factura ingresado es de tipo incorrecto. Debe ser de tipo PDF, JPEG o PNG.',
        );
        setIsSubminting(false);
        notistack.closeSnackbar(key);
        return;
      }

      if (values.contrato?.value?.aprobadores_factura?.aprobadores?.length > 0) {
        const aprob = values.contrato.value.aprobadores_factura.aprobadores;
        for (let i = 0; i < aprob.length; i++) {
          if (!aprob[i].uid) {
            setOpenDialogError(true);
            setDialogMessageError(
              'Hay un problema con los aprobadores del contrato seleccionado. Contacte a Erick Jerez (ejerez@cydingenieria.cl) indicando el contrato con el problema.',
            );
            setIsSubminting(false);
            notistack.closeSnackbar(key);
            return;
          }
        }
      }
      if (values.archivo && values.archivo.type === 'application/pdf') {
        const reader = new FileReader();
        reader.readAsArrayBuffer(values.archivo);
        reader.onload = function () {
          var files = new Blob([reader.result], { type: 'application/pdf' });
          files.text().then((x) => {
            console.log('isEncrypted', x.includes('Encrypt'));
            console.log('isEncrypted', x.substring(x.lastIndexOf('<<'), x.lastIndexOf('>>')).includes('/Encrypt'));
            console.log(values.archivo.name);

            if (x.includes('Encrypt')) {
              //Guía para desencriptar¿
              setOpenDialogArchivoProtegido(true);
            } else {
              setOpenVistaPrevia(true);
            }
            notistack.closeSnackbar(key);
          });
        };
      } else {
        setOpenVistaPrevia(true);
        notistack.closeSnackbar(key);
      }
    }
  }

  function CheckButtonDisabled() {
    if (
      !(
        values.sistema_frenos ||
        values.sistema_luces ||
        values.espejo_retrovisor ||
        values.filtros ||
        values.fluido_frenos ||
        values.aceite_motor ||
        values.aceite_hidraulico ||
        values.calefaccion ||
        values.air_bags ||
        values.bateria ||
        values.bocinas ||
        values.carroceria ||
        values.chaleco_reflectante ||
        values.cinturones ||
        values.neumaticos ||
        values.triangulo_seguridad ||
        values.llantas ||
        values.llantas_accesorios ||
        values.espejos ||
        values.plumillas ||
        values.parabrisas ||
        values.tablero ||
        values.radio ||
        values.radiador ||
        values.gata ||
        values.kit_embriague ||
        values.kit_herramientas ||
        values.extintor ||
        values.correo_accesorios ||
        values.limpieza_dpf ||
        values.refrigerante
      )
    ) {
      return true;
    }

    return false;
  }

  return (
    <>
      <IngresarFacturaContext.Provider
        value={{
          formik,
          siguienteAprobador,
          setSiguienteAprobador,
        }}
      >
        <Dialog
          open={is_open}
          onClose={handleClose}
          TransitionProps={{
            onEntered: handleEntered,
          }}
          fullWidth
          maxWidth="xl"
        >
          <DialogTitle>Registro de Mantenimiento Vehicular - Folio: {gerenciaUsuario}</DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              {/* DATOS DEL VEHICULO Y PROVEEDOR */}
              <Grid container spacing={0} className={classes.contenedorPrincipal}>
                {/* DATOS DEL VEHICULO */}
                <InfoVehiculo
                  values={values}
                  formik={formik}
                  FullDetalle={true}
                  TipoDeshabilitado={true}
                  habilitaPatente={habilitaPatente}
                />

                {/* DATOS PROVEEDOR */}
                <Grid container spacing={1}>
                  <Grid item xs={3} className={classes.items}>
                    <Autocomplete
                      freeSolo
                      autoSelect
                      options={proveedoresOptions}
                      value={values.proveedor_nombre}
                      loading={!proveedoresOptions}
                      getOptionLabel={(proveedor) => proveedor}
                      autoComplete
                      onChange={(event, newValue) => handleChangeProv(newValue, 'Nombre')} //handleChangeProveedor(newValue, event)}
                      renderInput={(params) => (
                        <TextField
                          label="Proveedor"
                          variant="outlined"
                          fullWidth
                          className={classes.input_required}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3} className={classes.items}>
                    <Autocomplete
                      freeSolo
                      autoSelect
                      options={rutOptions}
                      value={values.rut_proveedor}
                      loading={!rutOptions}
                      getOptionLabel={(rut) => rut}
                      autoComplete
                      onChange={(event, newValue) => handleChangeProv(newValue, 'Rut')} //handleChangeRutProveedor(newValue, event)}
                      renderInput={(params) => (
                        <TextField
                          label="Rut Proveedor"
                          variant="outlined"
                          fullWidth
                          className={classes.input_required}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.items}>
                    <CurrencyTextField
                      label="Kilometraje Actual"
                      value={values.kilometraje}
                      onChange={(event, value) => {
                        setFieldValue('kilometraje', value);
                      }}
                      error={touched.kilometraje && errors.kilometraje && Boolean(errors.kilometraje)}
                      helperText={touched.kilometraje && errors.kilometraje ? errors.kilometraje : ''}
                      fullWidth
                      variant="outlined"
                      currencySymbol="Km"
                      minimumValue="0"
                      maximumValue="1000000000"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      leading="deny"
                      decimalPlaces={0}
                      decimalPlacesShownOnBlur={0}
                      decimalPlacesShownOnFocus={0}
                      className={classes.input_required}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.items}>
                    <FormControl className={classes.formselect} fullWidth variant="outlined">
                      <MaterialReactSelect
                        value={Mantenciones_Programadas.find((d) => d.value === values.mantencion_programada)}
                        label="Tipo Mantenimiento"
                        isClearable
                        onChange={(newValue) => setFieldValue('mantencion_programada', newValue ? newValue.value : '')}
                        placeholder="Mantención Programada"
                        options={
                          Mantenciones_Programadas &&
                          Mantenciones_Programadas.map((value, index) => ({
                            value: value.value,
                            label: value.value,
                          }))
                        }
                        noOptionsMessage={() => 'No ha seleccionado el tipo de Mantención'}
                        error={
                          touched.mantencion_programada &&
                          errors.mantencion_programada &&
                          Boolean(errors.mantencion_programada)
                        }
                        helperText={
                          touched.mantencion_programada && errors.mantencion_programada
                            ? errors.mantencion_programada
                            : ''
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <DatosFactura proveedores={proveedores} />

              {/* DATOS DE MANTENIMIENTO */}
              <Grid container spacing={1} className={classes.contenedorPrincipal}>
                <Grid item xs={6} container>
                  {/* datos de mantenimiento Exteriores */}
                  <Grid item xs className={classes.itemsMantenimiento}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">EXTERIORES</FormLabel>
                      <FormGroup aria-label="position">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.sistema_luces}
                              onChange={(e) => setFieldValue('sistema_luces', e.target.checked)}
                              name="sistema_luces"
                              color="primary"
                            />
                          }
                          label="Sistema de Luces"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.espejos}
                              onChange={(e) => setFieldValue('espejos', e.target.checked)}
                              name="espejos"
                              color="primary"
                            />
                          }
                          label="Espejos"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.llantas}
                              onChange={(e) => setFieldValue('llantas', e.target.checked)}
                              name="llantas"
                              color="primary"
                            />
                          }
                          label="Llantas"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.carroceria}
                              onChange={(e) => setFieldValue('carroceria', e.target.checked)}
                              name="carroceria"
                              color="primary"
                            />
                          }
                          label="Carrocería"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.plumillas}
                              onChange={(e) => setFieldValue('plumillas', e.target.checked)}
                              name="plumillas"
                              color="primary"
                            />
                          }
                          label="Plumillas"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.parabrisas}
                              onChange={(e) => setFieldValue('parabrisas', e.target.checked)}
                              name="parabrisas"
                              color="primary"
                            />
                          }
                          label="Parabrisas"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.neumaticos}
                              onChange={(e) => setFieldValue('neumaticos', e.target.checked)}
                              name="neumaticos"
                              color="primary"
                            />
                          }
                          label="Neumáticos"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  {/* datos de mantenimiento Interiores */}
                  <Grid item xs className={classes.itemsMantenimiento}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">INTERIORES</FormLabel>
                      <FormGroup aria-label="position">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.tablero}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="tablero"
                              color="primary"
                            />
                          }
                          label="Tablero"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.calefaccion}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="calefaccion"
                              color="primary"
                            />
                          }
                          label="Calefacción"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.bocinas}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="bocinas"
                              color="primary"
                            />
                          }
                          label="Bocinas"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.espejo_retrovisor}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="espejo_retrovisor"
                              color="primary"
                            />
                          }
                          label="Espejo Retrovisor"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.cinturones}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="cinturones"
                              color="primary"
                              //disabled={disableEditing}
                            />
                          }
                          label="Cinturones"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.radio}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="radio"
                              color="primary"
                              //disabled={disableEditing}
                            />
                          }
                          label="Radio"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.air_bags}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="air_bags"
                              color="primary"
                              //disabled={disableEditing}
                            />
                          }
                          label="Air Bags"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  {/* datos de mantenimiento Accesorios */}
                  <Grid item xs className={classes.itemsMantenimiento}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">ACCESORIOS</FormLabel>
                      <FormGroup aria-label="position">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.gata}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="gata"
                              color="primary"
                            />
                          }
                          label="Gata"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.kit_herramientas}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="kit_herramientas"
                              color="primary"
                            />
                          }
                          label="Kit de Herramientas"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.llantas_accesorios}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="llantas_accesorios"
                              color="primary"
                            />
                          }
                          label="Llantas"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.extintor}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="extintor"
                              color="primary"
                            />
                          }
                          label="Extintor"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.triangulo_seguridad}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="triangulo_seguridad"
                              color="primary"
                            />
                          }
                          label="Triángulo de Seguridad"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.chaleco_reflectante}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="chaleco_reflectante"
                              color="primary"
                            />
                          }
                          label="Chaleco Reflectante"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item xs={6} container>
                  {/* datos de mantenimiento Componentes Mecanicos */}
                  <Grid item xs className={classes.itemsMantenimiento}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">COMPONENTES MECÁNICOS</FormLabel>
                      <FormGroup aria-label="position">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.radiador}
                              onChange={(e) => formik.setFieldValue('radiador', e.target.checked)}
                              name="radiador"
                              color="primary"
                              ////disabled={disableEditing}
                            />
                          }
                          label="Radiador"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.bateria}
                              onChange={(e) => formik.setFieldValue('bateria', e.target.checked)}
                              name="bateria"
                              color="primary"
                              //disabled={disableEditing}
                            />
                          }
                          label="Batería"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.filtros}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="filtros"
                              color="primary"
                              //disabled={disableEditing}
                            />
                          }
                          label="Filtros"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.sistema_frenos}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="sistema_frenos"
                              color="primary"
                              //disabled={disableEditing}
                            />
                          }
                          label="Sistema de Frenos"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.correo_accesorios}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="correo_accesorios"
                              color="primary"
                              //disabled={disableEditing}
                            />
                          }
                          label="Correa Accesorios"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.limpieza_dpf}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="limpieza_dpf"
                              color="primary"
                              //disabled={disableEditing}
                            />
                          }
                          label="Limpieza DPF"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.kit_embriague}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="kit_embriague"
                              color="primary"
                              //disabled={disableEditing}
                            />
                          }
                          label="Kit de Embrague"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  {/* datos de mantenimiento Fluidos */}
                  <Grid item xs className={classes.itemsMantenimiento}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">FLUIDOS</FormLabel>
                      <FormGroup aria-label="position">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.aceite_motor}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="aceite_motor"
                              color="primary"
                            />
                          }
                          label="Aceite Motor"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.fluido_frenos}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="fluido_frenos"
                              color="primary"
                            />
                          }
                          label="Frenos"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.refrigerante}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="refrigerante"
                              color="primary"
                            />
                          }
                          label="Refrigerante"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.aceite_hidraulico}
                              onChange={(e) => formik.setFieldValue(e.target.name, e.target.checked)}
                              name="aceite_hidraulico"
                              color="primary"
                            />
                          }
                          label="Aceite Hidráulico"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  {/* datos de mantenimiento Descripcion del servicio */}
                  <Grid item xs className={classes.itemsMantenimiento}>
                    <TextField
                      label="Otro"
                      name="otro_servicio"
                      value={values.otro_servicio}
                      onChange={handleChange}
                      error={touched.otro_servicio && errors.otro_servicio && Boolean(errors.otro_servicio)}
                      helperText={touched.otro_servicio && errors.otro_servicio ? errors.otro_servicio : ''}
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={2}
                    />
                    {/* </FormGroup> */}
                    <br></br>
                    <p></p>
                    <TextField
                      label="Descripción del Servicio"
                      name="descripcion"
                      value={values.descripcion}
                      onChange={handleChange}
                      error={touched.descripcion && errors.descripcion && Boolean(errors.descripcion)}
                      helperText={touched.descripcion && errors.descripcion ? errors.descripcion : ''}
                      variant="outlined"
                      multiline
                      minRows={4}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Cerrar
              </Button>
              {values.tipo_documento === 'Factura' ? (
                <Button
                  disabled={isSubmiting && CheckButtonDisabled()}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={checkFactura}
                >
                  Enviar factura a revisión
                </Button>
              ) : (
                <Button
                  disabled={CheckButtonDisabled()}
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Guardar Mantenimiento
                </Button>
              )}
            </DialogActions>
          </form>
        </Dialog>

        <Dialog open={openDialogError}>
          <DialogContent>
            <Typography align="center">{dialogMessageError}</Typography>
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="outlined" size="large" onClick={() => setOpenDialogError(false)}>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDialogFinCarga}>
          <DialogContent>
            <Typography align="center">Operanción exitosa: Factura guardada</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              onClick={() => {
                setOpenDialogFinCarga(false);
                //history.push(HOME_ROUTE);
              }}
            >
              Volver al Inicio
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={() => {
                setOpenDialogFinCarga(false);
                resetForm({
                  values: {
                    ...values,
                    numero_factura: '',
                    fecha_factura: null,
                    monto_total: 0,
                    monto_neto: 0,
                    monto_iva: 0,
                    monto_total_dolares: 0,
                    descripcion: '',
                    archivo_factura: null,
                    file: null,
                  },
                });
              }}
            >
              Cargar otra factura
            </Button>
          </DialogActions>
        </Dialog>
      </IngresarFacturaContext.Provider>

      <DialogoVistaPreviaFactura
        open={openVistaPrevia}
        values={values}
        close={() => setOpenVistaPrevia(false)}
        submit={handleSubmit}
        validateForm={validateForm}
        siguienteAprobador={siguienteAprobador}
        isSubmiting={isSubmiting}
      />

      <Dialog open={openDialogArchivoProtegido}>
        <DialogContent>
          <Typography>
            El archivo pdf seleccionado se encuentra encriptado/protegido. Para desencriptar el archivo, puede imprimir
            el documento como archivo pdf para generar una copia sin proteger.
            <br />
          </Typography>
          <Typography align="center">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://drive.google.com/file/d/13z3vh4GiAfpnvW7MyaY86azueqevBjaU/view?usp=sharing"
            >
              Como quitar bloqueo a un archivo pdf
            </a>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" size="large" onClick={() => setOpenDialogArchivoProtegido(false)}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
