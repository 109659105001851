import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Tooltip, Box, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import QRCode from 'qrcode.react';
import { FRONTEND } from '../../../constants/urls';

export default function DialogoCargaRegistro(props) {
  const componentRef = useRef();
  const {
    handleClose,
    handleVolverActivos,
    handleIngresarNuevo,
    handleIngresarNuevoMismosDatos,
    handleDescargarExcel,
    message,
    open,
    // setOpen,
    title,
    modoMultiplesActivos,
    bien,
  } = props;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{title ? title : 'Operación Exitosa'}</DialogTitle>
      {modoMultiplesActivos ? (
        <DialogContent>
          {/* {message ? message: 'Activo guardado.s'} */}
          <Box display="flex" justifyContent="center">
            <Typography>Activos guardados:</Typography>
          </Box>
          <br />
          <Box display="flex" justifyContent="center">
            {message}
          </Box>
          <br />
          <Box display="flex" justifyContent="center">
            <Button onClick={handleDescargarExcel} color="primary" variant="contained">
              Descargar Excel
            </Button>
          </Box>
        </DialogContent>
      ) : (
        <DialogContent>
          {/* {message ? message: 'Activo guardado.s'} */}
          Activo guardado
          <Box display="flex" justifyContent="center">
            <div ref={componentRef}>
              <QRCode value={`${FRONTEND}/activos/${bien._id}`} size={256} />
              <Box display="flex" justifyContent="center">
                <Typography>{bien.codigo_activo}</Typography>
              </Box>
            </div>
          </Box>
          <Box display="flex" justifyContent="center">
            <Typography>URL: {`${FRONTEND}/activos/${bien._id}`}</Typography>
          </Box>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleVolverActivos} color="primary" variant="outlined">
          Ir a Lista de Activos
        </Button>
        <Button onClick={handleIngresarNuevo} color="primary" variant="contained">
          Limpiar Formulario
        </Button>
        <Tooltip title="Con los mismos datos">
          <Button onClick={handleIngresarNuevoMismosDatos} color="primary" variant="contained">
            Ingresar otro Activo
          </Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
}
