import React from "react";
import FileInput from "../../../../components/fileInput";
import { Grid, TextField, DialogActions, Button, FormControl, FormHelperText, Backdrop, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MaterialReactSelect from "../../../../components/materialReactSelect";

function Page(props) {
  const {
    usuario_asignante,
    asignado,
    bien,
    condiciones_actuales,
    formik,
    handleCancelar,
  } = props;

  const {
    values,
    handleChange,
    setFieldValue,
    submitForm,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
  } = formik;

  const fileChange = (name, e) => {
    formik.setFieldValue(name, e.target.files[0]);
    formik.setFieldTouched(name, true, false);
  };

  const classes = useStyles();

  return (
    <div>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={3} xl={3}>
            <TextField
              name="usuario_asignante_ref"
              label="Usuario Asignante"
              value={usuario_asignante.nombre}
              variant="outlined"
              margin="normal"
              fullWidth
              disabled
              error={touched.usuario_asignante_ref && errors.usuario_asignante_ref && Boolean(errors.usuario_asignante_ref)}
              helperText={touched.usuario_asignante_ref && errors.usuario_asignante_ref ? errors.usuario_asignante_ref : ""}
            />
          </Grid>
          <Grid item xs={3} xl={3}>
            <TextField
              name="codigo_activo"
              label="Bien"
              value={bien.codigo_activo}
              variant="outlined"
              margin="normal"
              fullWidth
              disabled
              error={touched._bien_ref && errors._bien_ref && Boolean(errors._bien_ref)}
              helperText={touched._bien_ref && errors._bien_ref ? errors._bien_ref : ""}
            />
          </Grid>
          <Grid item xs={3} xl={3}>
            <TextField
              name="marca"
              label="Marca"
              value={bien.marca}
              variant="outlined"
              margin="normal"
              fullWidth
              disabled
              error={touched._bien_ref && errors._bien_ref && Boolean(errors._bien_ref)}
              helperText={touched._bien_ref && errors._bien_ref ? errors._bien_ref : ""}
            />
          </Grid>
          <Grid item xs={3} xl={3}>
            <TextField
              name="modelo"
              label="Modelo"
              value={bien.modelo}
              variant="outlined"
              margin="normal"
              fullWidth
              disabled
              error={touched._bien_ref && errors._bien_ref && Boolean(errors._bien_ref)}
              helperText={touched._bien_ref && errors._bien_ref ? errors._bien_ref : ""}
            />
          </Grid>
          <Grid item xs={3} xl={3}>
            <TextField
              name="asignado_ref"
              label="Asignado"
              value={asignado}
              variant="outlined"
              margin="normal"
              fullWidth
              disabled
              error={touched.asignado_ref && errors.asignado_ref && Boolean(errors.asignado_ref)}
              helperText={touched.asignado_ref && errors.asignado_ref ? errors.asignado_ref : ""}
            />
          </Grid>
          <Grid item xs={3} xl={3}>
            <MaterialReactSelect
              value={condiciones_actuales && condiciones_actuales.find(d => d.label === values.formulario_estado.estado)}
              label="Condición Actual"
              isClearable
              onChange={(newValue) => setFieldValue("formulario_estado.estado", newValue ? newValue.label : "")}
              placeholder="Condición Actual"
              options={condiciones_actuales && condiciones_actuales}
              noOptionsMessage={() => "No ha entrado la Condición Actual"}
              error={touched.formulario_estado && touched.formulario_estado.estado && errors.formulario_estado && errors.formulario_estado.estado && Boolean(errors.formulario_estado.estado)}
              helperText={touched.formulario_estado && touched.formulario_estado.estado && errors.formulario_estado && errors.formulario_estado.estado ? errors.formulario_estado.estado : ""}
            />
          </Grid>
          <Grid item xs={3} xl={3}>
            <TextField
              name="formulario_estado.descripcion"
              label="Descripción (Opcional)"
              value={values.formulario_estado.descripcion}
              variant="outlined"
              margin="normal"
              fullWidth
              onChange={handleChange}
              error={touched.formulario_estado && touched.formulario_estado.descripcion && errors.formulario_estado && errors.formulario_estado.descripcion && Boolean(errors.formulario_estado.descripcion)}
              helperText={touched.formulario_estado && touched.formulario_estado.descripcion && errors.formulario_estado && errors.formulario_estado.descripcion ? errors.formulario_estado.descripcion : ""}
            />
          </Grid>
          <Grid item xs={3} xl={3}>
            <FormControl
              fullWidth
              margin="normal"
              error={touched.formulario_estado && touched.formulario_estado.foto_antes && errors.formulario_estado && errors.formulario_estado.foto_antes && Boolean(errors.formulario_estado.foto_antes)}
            >
              <FileInput
                inputName="file"
                meta={{ touched: touched.file, errors: errors.file }}
                buttonColor="primary"
                buttonVariant="outlined"
                label="Foto de Antes (Opcional)"
                value={values.file}
                handleOnChange={fileChange.bind(null, "file")}
                textVariant="outlined"
                accept="image/x-png,image/gif,image/jpeg"
              />
              <FormHelperText>
                {touched.file && errors.file ? errors.file : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <DialogActions>
          <Button autoFocus onClick={handleCancelar} color="primary">
            {"Volver"}
          </Button>
          <Button onClick={submitForm} variant="contained" color="primary">
            {"Agregar"}
          </Button>
        </DialogActions>
      </form>
      <Backdrop className={classes.backdrop} open={isSubmitting}>
        <Typography variant="h6">Procesando la solicitud de asignación...</Typography>
        <CircularProgress style={{ marginLeft: 10 }} />
      </Backdrop>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default Page;