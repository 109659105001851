import React, { createContext, useState } from 'react';
import MaterialTable from '../../../components/materialTable';
import useSWR from 'swr';
import { Fragment } from 'react';
import { useHistory } from 'react-router';
import { AJUSTES_CONFIGURACION } from '../../../constants/routes';
import {
  getSociedadTerceros,
  postSociedad,
  putSociedad,
  deleteSociedad,
} from '../../../services/request/requestSociedades';
import DialogoCRUDSociedades from './dialogoCRUDSociedades';

export const TablaSociedadesContext = createContext({
  openDialogoCRUD: false,
  setOpenDialogoCRUD: () => {},
  tituloDialogo: '',
  setTituloDialogo: () => {},
  initialValues: '',
  setInitialValues: () => {},
  handleAceptar: () => {},
  disableEditing: false,
  setDisableEditing: () => {},
});

function getData() {
  return getSociedadTerceros({}, ['sociedad', '_id', 'rut', 'giro', 'descripcion'], []).then((sociedad) => {
    return sociedad;
  });
}

export default function TablaSociedades() {
  const { data: sociedades, mutate } = useSWR('get:sociedades', (key) => getData(), { refreshInterval: 0 });
  const [openDialogoCRUD, setOpenDialogoCRUD] = useState(false);
  const [tituloDialogo, setTituloDialogo] = useState('');
  const [initialValues, setInitialValues] = useState({
    sociedad: '',
    rut: '',
    giro: '',
    descripcion: '',
  });
  const [disableEditing, setDisableEditing] = useState(false);
  const history = useHistory();

  const handleOpenAgregar = (titulo) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      sociedad: '',
      rut: '',
      giro: '',
      descripcion: '',
      //id: ""
    });
    setDisableEditing(false);
  };

  const handleOpenModificar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      sociedad: row.sociedad,
      descripcion: row.descripcion,
      rut: row.rut,
      giro: row.giro,
      id: row._id,
    });
    setDisableEditing(false);
  };

  const handleOpenQuitar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      sociedad: row.sociedad,
      rut: row.rut,
      giro: row.giro,
      descripcion: row.descripcion,
      id: row._id,
    });
    setDisableEditing(true);
  };

  const handleAceptar = async (values) => {
    if (tituloDialogo) {
      if (tituloDialogo.includes('Nuev')) {
        await postSociedad({ ...values });
      } else if (tituloDialogo.includes('Modificar'))
        await putSociedad(values.id, {
          sociedad: values.sociedad,
          rut: values.rut,
          giro: values.giro,
          descripcion: values.descripcion,
        });
      else if (tituloDialogo.includes('Eliminar')) await deleteSociedad(values.id);
    }
    mutate();
    setOpenDialogoCRUD(false);
  };

  const handleVolver = () => {
    history.push(AJUSTES_CONFIGURACION);
  };

  const columnsSociedades = [
    {
      title: 'ID',
      field: '_id',
      align: 'center',
    },
    {
      title: 'Banco',
      field: 'sociedad',
      align: 'center',
      defaultSort: 'asc',
    },
    {
      title: 'Rut',
      field: 'rut',
      align: 'center',
    },
    {
      title: 'Descripción',
      field: 'descripcion',
      align: 'center',
    },
  ];

  const actions = [
    {
      tooltip: 'Agregar Sociedad',
      icon: 'add',
      onClick: (event, row) => handleOpenAgregar('Nueva Sociedad'),
      isFreeAction: true,
    },
    {
      tooltip: 'Editar',
      icon: 'edit',
      onClick: (event, row) => handleOpenModificar('Modificar Sociedad', row),
    },
    {
      tooltip: 'Eliminar',
      icon: 'delete',
      onClick: (event, row) => handleOpenQuitar('Eliminar Sociedad', row),
    },
    {
      tooltip: 'Volver',
      icon: 'arrow_back',
      onClick: (event, row) => handleVolver(),
      isFreeAction: true,
    },
  ];

  return (
    <Fragment>
      <TablaSociedadesContext.Provider
        value={{
          openDialogoCRUD,
          setOpenDialogoCRUD,
          tituloDialogo,
          setTituloDialogo,
          initialValues,
          setInitialValues,
          handleAceptar,
          disableEditing,
          setDisableEditing,
        }}
      >
        <MaterialTable
          actions={actions}
          title="Sociedades de Terceros"
          is_loading={!sociedades}
          data={sociedades && sociedades.data}
          columns={columnsSociedades}
          filtering={false}
          grouping={false}
          exportButtonPDF={true}
          exportButtonExcel={true}
          empty_rows={true}
          page_size={20}
        />
        <DialogoCRUDSociedades />
      </TablaSociedadesContext.Provider>
    </Fragment>
  );
}
