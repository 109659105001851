import React, { useContext, useCallback, useEffect } from 'react';
import Page from './pageFormularioBienesComputacionales';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { RegistroBienesContext } from '.';

const validationSchema = yup.object({});

export default function FomularioBienesComputacionales() {
  const { setFormularioComputacionales, setSelectedTab, formularioComputacionales } = useContext(RegistroBienesContext);

  const formik = useFormik({
    initialValues: {
      disco_duro: '',
      procesador: '',
      memoria_ram: '',
      pulgadas_pantalla: '',
    },
    onSubmit: (values) => {
      setFormularioComputacionales(values);
      setSelectedTab(4);
    },
    validationSchema,
  });

  const { resetForm } = formik;

  const resetFormulario = useCallback(() => {
    if (formularioComputacionales) {
      resetForm({ values: formularioComputacionales });
    } else resetForm();
  }, [resetForm, formularioComputacionales]);

  useEffect(() => {
    resetFormulario();
  }, [resetFormulario]);

  const handleVolver = () => {
    setSelectedTab(0);
  };

  return <Page formik={formik} handleVolver={handleVolver} />;
}
