import React, { createContext, useState } from 'react';
import MaterialTable from '../../../components/materialTable';
import useSWR from 'swr';
import { Fragment } from 'react';
import { useHistory } from 'react-router';
import { AJUSTES_CONFIGURACION } from '../../../constants/routes';
import {
  getVersionesSoftware,
  postVersionSoftware,
  putVersionSoftware,
  deleteVersionSoftware,
} from '../../../services/request/requestVersionesSoftware';
import DialogoCRUDVersionSoftware from './dialogoCRUDVersionSoftware';

export const TablaVersionesSoftwareContext = createContext({
  openDialogoCRUD: false,
  setOpenDialogoCRUD: () => {},
  tituloDialogo: '',
  setTituloDialogo: () => {},
  initialValues: '',
  setInitialValues: () => {},
  handleAceptar: () => {},
  disableEditing: false,
  setDisableEditing: () => {},
});

function getData() {
  return getVersionesSoftware(
    {},
    ['version', '_id', 'descripcion'],
    ['_marca_ref.marca', '_software_ref.software'],
  ).then((software) => {
    const datos = [];
    software.data.forEach((a) => {
      const data = {
        ...a,
        marca: a._marca_ref ? a._marca_ref.marca : ' ',
        software: a._software_ref ? a._software_ref.software : ' ',
      };
      datos.push(data);
    });
    console.log('Datos: ', datos);
    return datos;
  });
}

export default function TablaVersionesSoftware() {
  const { data: versiones, mutate } = useSWR('get:versiones-software', (key) => getData(), { refreshInterval: 0 });
  const [openDialogoCRUD, setOpenDialogoCRUD] = useState(false);
  const [tituloDialogo, setTituloDialogo] = useState('');
  const [initialValues, setInitialValues] = useState({
    version: '',
    descripcion: '',
    id: null,
    marca: '',
    software: '',
  });
  const [disableEditing, setDisableEditing] = useState(false);
  const history = useHistory();

  const handleOpenAgregar = (titulo) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      version: '',
      descripcion: '',
      marca: '',
      software: '',
    });
    setDisableEditing(false);
  };

  const handleOpenModificar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      version: row.version,
      descripcion: row.descripcion,
      marca: row._marca_ref ? { value: row._marca_ref._id, label: row._marca_ref.marca } : '',
      software: row._software_ref ? { value: row._software_ref._id, label: row._software_ref.software } : '',
      id: row._id,
    });
    setDisableEditing(false);
  };

  const handleOpenQuitar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      version: row.version,
      descripcion: row.descripcion,
      marca: row._marca_ref ? { value: row._marca_ref._id, label: row._marca_ref.marca } : '',
      software: row._software_ref ? { value: row._software_ref._id, label: row._software_ref.software } : '',
      id: row._id,
    });
    setDisableEditing(true);
  };

  const handleAceptar = async (values) => {
    if (tituloDialogo) {
      if (tituloDialogo.includes('Nuev'))
        await postVersionSoftware({ ...values, _marca_ref: values.marca.value, _software_ref: values.software.value });
      else if (tituloDialogo.includes('Modificar'))
        await putVersionSoftware(values.id, {
          version: values.version,
          descripcion: values.descripcion,
          _marca_ref: values.marca.value ? values.marca.value : null,
          _software_ref: values.software.value ? values.software.value : null,
        });
      else if (tituloDialogo.includes('Eliminar')) await deleteVersionSoftware(values.id);
    }
    mutate();
    setOpenDialogoCRUD(false);
  };

  const handleVolver = () => {
    history.push(AJUSTES_CONFIGURACION);
  };

  const columnsVersiones = [
    {
      title: 'ID',
      field: '_id',
      align: 'center',
    },
    {
      title: 'Versión',
      field: 'version',
      align: 'center',
      defaultSort: 'asc',
    },
    {
      title: 'Marca Asociada',
      field: 'marca',
      align: 'center',
    },
    {
      title: 'Agrupación',
      field: '_software_ref.software',
      align: 'center',
      hidden: true,
    },
    {
      title: 'Descripción',
      field: 'descripcion',
      align: 'center',
    },
  ];

  return (
    <Fragment>
      <TablaVersionesSoftwareContext.Provider
        value={{
          openDialogoCRUD,
          setOpenDialogoCRUD,
          tituloDialogo,
          setTituloDialogo,
          initialValues,
          setInitialValues,
          handleAceptar,
          disableEditing,
          setDisableEditing,
        }}
      >
        <MaterialTable
          actions={[
            {
              tooltip: 'Agregar Version',
              icon: 'add',
              onClick: (event, row) => handleOpenAgregar('Nueva Versión de Software'),
              isFreeAction: true,
            },
            {
              tooltip: 'Editar',
              icon: 'edit',
              onClick: (event, row) => handleOpenModificar('Modificar Versión de Software', row),
            },
            {
              tooltip: 'Eliminar',
              icon: 'delete',
              onClick: (event, row) => handleOpenQuitar('Eliminar Versión de Software', row),
            },
            {
              tooltip: 'Volver',
              icon: 'arrow_back',
              onClick: (event, row) => handleVolver(),
              isFreeAction: true,
            },
          ]}
          title="Versiones de Softwares Registradas"
          is_loading={!versiones && !Array.isArray(versiones)}
          data={Array.isArray(versiones) ? versiones : []}
          columns={columnsVersiones}
          filtering={false}
          grouping={false}
          exportButtonPDF={true}
          exportButtonExcel={true}
          empty_rows={true}
          page_size={20}
        />
        <DialogoCRUDVersionSoftware />
      </TablaVersionesSoftwareContext.Provider>
    </Fragment>
  );
}
