import React, { Fragment, useContext } from "react";
import MaterialTable from "../../components/materialTable";
import { LicenciasSoftwareContext } from ".";
import { MainContext } from "../../App";
import { AGREGAR_LICENCIA_SOFTWARE, EDITAR_LICENCIA_SOFTWARE, ELIMINAR_LICENCIA_SOFTWARE } from "../../constants/permissions";
import { useHistory } from "react-router";
import { DETALLE_LICENCIA_SOFTWARE } from "../../constants/routes";


export default function TablaResultadoLicenciasSoftware() {

  const history = useHistory();

  const {
    data,
    isLoading,
    handleOpenAgregar,
    handleOpenModificar,
    handleOpenQuitar
  } = useContext(LicenciasSoftwareContext);

  const {
    permisos
  } = useContext(MainContext);

  const actions = [
    {
      tooltip: "Agregar",
      icon: "add",
      hidden: permisos && permisos[AGREGAR_LICENCIA_SOFTWARE] ? false: true,
      onClick: (event, row) => handleOpenAgregar(row),
      isFreeAction: true
    },
    {
      tooltip: "Editar",
      icon: "edit",
      hidden: permisos && permisos[EDITAR_LICENCIA_SOFTWARE] ? false: true,
      onClick: (event, row) => handleOpenModificar(row),
    },
    {
      tooltip: "Eliminar",
      icon: "delete",
      hidden: permisos && permisos[ELIMINAR_LICENCIA_SOFTWARE] ? false: true,
      onClick: (event, row) => handleOpenQuitar(row),
    },
    {
      icon: "chevron_right",
      tooltip: "Ver Detalles",
      hidden: permisos && permisos[EDITAR_LICENCIA_SOFTWARE] ? false: true,
      onClick: (evt, rowData) => history.push(DETALLE_LICENCIA_SOFTWARE.replace(':id', rowData._id))
    }
  ];

  const columns = [
    {
      title: "ID",
      field: "_id",
      hidden: true,
    },
    {
      title: "Marca",
      field: "marca",
      defaultSort: 'desc',
    },
    {
      title: "Version Software",
      field: "version_software"
    },    
    {
      title: "Código de Compra",
      field: "codigo_compra"
    },
    {
      title: "Tipo de Licencia",
      field: "tipo",
    },
    {
      title: "Fecha de Vencimiento",
      field: "fecha_vencimiento_view",
      type: "date"
    },
    {
      title: "Cantidad total",
      field: "cantidad",
    },
    {
      title: "Cantidad disponible",
      field: "cantidad_disponible",
    },
    {
      title: "Descripción",
      field: "descripcion"
    },    
    {
      title: "Agrupación",
      field: "agrupacion",
      hidden: true,
    }
  ];

  return (
    <Fragment>
      <MaterialTable
        title="Licencias de Software"
        is_loading={isLoading}
        columns={columns}
        data={Array.isArray(data) ? data : []}
        actions={actions}
        lockheaders={true}
      />
    </Fragment>
  );
}