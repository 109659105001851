import React, { useState } from "react";
import MaterialTable from "../../components/materialTable";
import { useHistory } from "react-router";
import { Fragment } from "react";
import { Menu, MenuItem } from "@material-ui/core";

function Page(props) {
  const {
    solicitudes_asignacion,
    handle_aceptar_solicitud,
    handle_anular_solicitud,
    handle_export,
  } = props;

  const [Registro, SetRegistro] = useState(null);
  const history = useHistory();

  const handleClick = (event, row) => {
    SetRegistro({
      anchor: event.currentTarget,
      row: row
    });
  };

  /**
   * Handler para ver el detalle de una solicitud de asignación.
   */
  const handleVerDetalle = () => {
    history.push(`/activos/${Registro.row._bien_ref._id}`);
  }

  /**
   * Handler para aceptar una solicitud de asignación.
   */
  const handleAceptarAsignacion = () => {
    SetRegistro();
    handle_aceptar_solicitud(Registro.row);
  }

  /**
   * Handler para anular una solicitud de asignación.
   */
  const handleAnularAsignacion = () => {
    SetRegistro();
    handle_anular_solicitud(Registro.row._id);
  }

  let columns = [
    {
      title: "Asignado por",
      field: "_usuario_asignante_ref.nombre_completo",
    }, {
      title: "Activo",
      field: "_bien_ref.codigo_activo",
    }, {
      title: "N° de Serie",
      field: "_bien_ref.serial_number",
      hidden: true,
      hiddenByColumnsButton: true,
    }, {
      title: "Marca",
      field: "_bien_ref.marca",
    }, {
      title: "Modelo",
      field: "_bien_ref.modelo",
    }, {
      title: "Asignado a",
      field: "asignado_a",
    }, {
      title: "Gerencia",
      field: "_gerencia_ref.nombre",
    }, {
      title: "Contrato",
      field: "_contrato_ref.nombre",
    }, {
      title: "Tipo",
      field: "tipo",
    }, {
      title: "Estado",
      field: "estado",
    }, {
      title: "Condición Actual",
      field: "formulario_estado.estado",
    }, {
      title: "Descripción",
      field: "formulario_estado.descripcion",
    }, {
      title: "Fecha Asignación",
      field: "fecha_creacion_format",
    },
  ];

  let actions = [
    {
      icon: "more_vert",
      tooltip: "Opciones",
      onClick: handleClick,
    }
  ];

  return (
    <Fragment>
      <MaterialTable
        title="Solicitudes de Asignación"
        is_loading={!solicitudes_asignacion}
        data={solicitudes_asignacion}
        columns={columns}
        actions={actions}
        filtering={false}
        export_function={handle_export}
        exportButton={true}
        exportButtonPDF={false}
      />
      <Menu
        anchorEl={Registro && Registro.anchor}
        keepMounted
        open={Boolean(Registro && Registro.anchor)}
        onClose={() => SetRegistro()}
      >
        {/* SOLO SOLICITUDES PENDIENTES PUEDEN SER ACEPTADAS */}
        {Registro && Registro.row.estado === "Pendiente" && (
          <MenuItem onClick={handleAceptarAsignacion}>Aceptar Asignación</MenuItem>
        )}
        <MenuItem onClick={handleVerDetalle}>Ver Activo</MenuItem>
        {/* SOLO SOLICITUDES PENDIENTES PUEDEN SER ANULADAS */}
        {Registro && Registro.row.estado === "Pendiente" && (
          <MenuItem onClick={handleAnularAsignacion}>Anular Asignación</MenuItem>
        )}
      </Menu>
    </Fragment>
  );
}

export default Page;