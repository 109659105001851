import React, { useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Typography
} from '@material-ui/core';

export default function FileInput(props) {
  const { input, label, accept, meta, custom, style, textVariant, buttonVariant, buttonColor, inputName, size } = props;
  const [fileName, setFileName] = useState("");
  const onChange = (e) => {
    const { handleOnChange } = props;
    if (handleOnChange) handleOnChange(e);
    const name = e.target.files[0] ? e.target.files[0].name : null;
    setFileName(name);
  }
  return (
    <Grid container>
      <Grid item xs={12} lg={4}
        style={{
          // paddingRight: "5px"
        }}
      >
        <Button
          variant={buttonVariant ? buttonVariant : "outlined"}
          component="label"
          color={buttonColor ? buttonColor : "default"}
          style={{
            width: "100%",
            height: "100%",
            borderTopRightRadius: textVariant === "outlined" ? "0px" : "4px",
            borderBottomRightRadius: textVariant === "outlined" ? "0px" : "4px"
          }}
        >
          Seleccionar
          <input
            onChange={(e) => onChange(e)}
            style={{ display: 'none' }}
            type='file'
            accept={accept}
            name={inputName}
          />
        </Button>
      </Grid>
      <Grid item xs={12} lg={8}>
        <TextField
          {...input}
          {...custom}
          style={{
            ...style,
            width: "100%",
          }}
          placeholder={label ? label : "No se ha seleccionado archivo"}
          value={fileName ? fileName : ""}
          InputProps={{
            readOnly: true,
            style: {
              borderTopLeftRadius: textVariant === "outlined" ? "0px" : "4px",
              borderBottomLeftRadius: textVariant === "outlined" ? "0px" : "4px"
            }
          }}
          error={meta && (meta.touched && (meta.error ? true : false))}
          // helperText={meta && meta.touched && meta.error}
          fullWidth={false}
          variant={textVariant ? textVariant : "standard"}
          size={size}
        />
      </Grid>
      {meta && meta.touched && meta.error && (
        <Typography variant="caption" color="error" style={{ marginLeft: 15 }}>
          {meta && meta.touched && meta.error}
        </Typography>
      )}
    </Grid>
  );
}