import axios from 'axios';
import { BACKEND } from '../../constants/urls'
/**
 * Obtener Tipos de Seguros
 * @param {*} filter filtro para resultados
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export function getTiposSeguros(filter = {}, selects = [], populates = [], cancelToken = null) {
	//_sub_tipos-seguros_ref/is_eliminado/nombre/descripcion/fecha_creacion/is_computacional/is_vehiculo/_grupo_contable_ref/numero_grupo/_tipo_recurso_ref.nombre
	let url = `${BACKEND}/tipos-seguros`;
	if (selects) selects.forEach(select => {
		url = url + "/" + select;
	});
	if (populates) populates.forEach(populate => {
		url = url + "/" + populate;
	});
	return axios.get(url,
		{
			params: filter,
			cancelToken: cancelToken
		}).then(res => res.data);
}

/**
 * Guardar Tipos de Seguros
 * @param {*} data datos de Tipos de Seguros
 * @param {*} cancelToken token para cancelacion
 */
 export function postTiposSeguros(data, cancelToken = null) {
  return axios.post(`${BACKEND}/tipos-seguros/`, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data);
}
/**
 * Actualizar clase
 * @param {String}  id
 * @param {Object} data datos del registro
 * @param {*} cancelToken token para cancelacion
 */
export function putTiposSeguros(id, data, cancelToken = null) {
  return axios.put(`${BACKEND}/tipos-seguros/${id}`, data,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data.data : null);
}

/**
 * Eliminar clase
 * @param {String}  id
 * @param {*} cancelToken token para cancelacion
 */
export function deleteTiposSeguros(id, cancelToken = null) {
  return axios.delete(`${BACKEND}/tipos-seguros/${id}`,
    {
      cancelToken: cancelToken
    }).then(res => res.data ? res.data.data : null);
}

/**
 * Obtener clase
 * @param {String} id 
 * @param {*} cancelToken token para cancelacion
 */
 export function getTipoSeguro(id , cancelToken = null) {
	let url = `${BACKEND}/tipos-seguros/${id}`;
	return axios.get(url,
		{
			cancelToken: cancelToken
		}).then(res => res.data);
}