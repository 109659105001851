import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { deleteOne, getMany, postOne, updateOne } from '../../../services/request/requestGestorPagos';
import useSWR from 'swr';
import { MainContext } from '../../../App';
import formatNumber from 'format-number';

const numberFormater = formatNumber({ integerSeparator: '.', decimal: ',' });

const validationSchema = yup.object({
  banco: yup.string().required('Debe seleccionar el banco'),
  correo: yup.string().required('Debe ingresar el correo al que desea enviar su comprobante'),
  numero_cuenta: yup.string().required('Debe ingresar su número de cuenta'),
  tipo_cuenta: yup.string().required('Debe seleccionar el tipo de cuenta'),
});

export default function DialogoCRUDCuentasBancosUsuarios(props) {
  const { refreshTable, open, titulo, initialValues, disableEditing, close } = props;

  const { usuarioSesion } = useContext(MainContext);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { data: responseBancos } = useSWR('get:bancos', () => getMany('/bancos'));

  const notistack = useSnackbar();
  const formik = useFormik({
    initialValues: initialValues
      ? initialValues
      : {
          banco: '',
          correo: '',
          descripcion: '',
          numero_cuenta: '',
          rut_cuenta: '',
          tipo_cuenta: '',
          titular_cuenta: '',
        },
    onSubmit: async (values) => {
      setIsSubmiting(true);
      if (titulo) {
        let mensaje = 'Cuenta de banco';
        if (titulo.includes('Nuev')) {
          await postOne('/cuentas-bancos-usuarios', {
            ...values,
            _usuario_ref: usuarioSesion._id,
          });
          mensaje = mensaje + ' agregada';
        } else if (titulo.includes('Modificar')) {
          await updateOne('/cuentas-bancos-usuarios', values.id, {
            banco: values.banco,
            correo: values.correo,
            descripcion: values.descripcion,
            numero_cuenta: values.numero_cuenta,
            rut_cuenta: values.rut_cuenta,
            tipo_cuenta: values.tipo_cuenta,
            titular_cuenta: values.titular_cuenta,
          });
          mensaje = mensaje + ' actualizada';
        } else if (titulo.includes('Eliminar')) {
          await deleteOne('/cuentas-bancos-usuarios', values.id);
          mensaje = mensaje + ' eliminada';
        }

        const successkey = notistack.enqueueSnackbar(mensaje, {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
              <Close />
            </IconButton>
          ),
        });
      }
      setIsSubmiting(false);
      refreshTable();
      close();
    },
    validationSchema: validationSchema,
  });

  const { handleChange, errors, touched, values, handleSubmit, resetForm, setFieldValue } = formik;

  const resetFormik = useCallback(() => {
    resetForm({ values: initialValues });
  }, [initialValues, resetForm]);

  useEffect(() => {
    resetFormik();
  }, [resetFormik]);

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="xl">
      <DialogTitle>{titulo}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel>Banco</InputLabel>
              <Select
                value={values.banco}
                name="banco"
                error={touched.banco && errors.banco && Boolean(errors.banco)}
                onChange={handleChange}
              >
                {responseBancos &&
                  responseBancos.status &&
                  responseBancos.data.map((value, index) => (
                    <MenuItem value={value.nombre} key={index}>
                      {value.nombre}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>{touched.banco && errors.banco ? errors.banco : ''}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel>Tipo de Cuenta</InputLabel>
              <Select
                value={values.tipo_cuenta}
                name="tipo_cuenta"
                error={touched.tipo_cuenta && errors.tipo_cuenta && Boolean(errors.tipo_cuenta)}
                onChange={handleChange}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="Corriente">Corriente</MenuItem>
                <MenuItem value="Vista">Vista</MenuItem>
              </Select>
              <FormHelperText>{touched.tipo_cuenta && errors.tipo_cuenta ? errors.tipo_cuenta : ''}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <TextField
              label="Titular de Cuenta"
              name="titular_cuenta"
              value={values.titular_cuenta}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              margin="normal"
              disabled={disableEditing}
              error={errors.titular_cuenta && touched.titular_cuenta ? true : false}
              helperText={errors.titular_cuenta && touched.titular_cuenta ? errors.titular_cuenta : ''}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <TextField
              label="RUT de Cuenta"
              name="rut_cuenta"
              value={values.rut_cuenta}
              onChange={(e) => {
                let rut = e.target.value;
                rut = rut.replace(/[^0-9kK]/g, '');
                rut = rut.replace(/k/g, 'K');
                if (rut && rut.length > 1) {
                  let dv = rut[rut.length - 1];
                  let cuerpo = rut.substring(0, rut.length - 1);
                  cuerpo = numberFormater(cuerpo);
                  rut = cuerpo + '-' + dv;
                }
                setFieldValue('rut_cuenta', rut);
              }}
              variant="outlined"
              fullWidth
              margin="normal"
              disabled={disableEditing}
              error={errors.rut_cuenta && touched.rut_cuenta ? true : false}
              helperText={errors.rut_cuenta && touched.rut_cuenta ? errors.rut_cuenta : ''}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <TextField
              label="N° de Cuenta"
              name="numero_cuenta"
              value={values.numero_cuenta}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              margin="normal"
              disabled={disableEditing}
              error={errors.numero_cuenta && touched.numero_cuenta ? true : false}
              helperText={errors.numero_cuenta && touched.numero_cuenta ? errors.numero_cuenta : ''}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <TextField
              label="Correo"
              name="correo"
              value={values.correo}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              margin="normal"
              disabled={disableEditing}
              error={errors.correo && touched.correo ? true : false}
              helperText={errors.correo && touched.correo ? errors.correo : ''}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <TextField
              label="Descripción"
              name="descripcion"
              value={values.descripcion}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              margin="normal"
              disabled={disableEditing}
              error={errors.descripcion && touched.descripcion ? true : false}
              helperText={errors.descripcion && touched.descripcion ? errors.descripcion : ''}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" alignItems="center" justify="center">
          <Button onClick={close} variant="outlined" color="primary" style={{ marginRight: '8px' }}>
            Cerrar
          </Button>
          <Button disabled={isSubmiting} onClick={handleSubmit} variant="contained" color="primary">
            Aceptar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
