import React, { createContext, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import IndicadoresMovimientoActivos from './indicadoresMovimientosActivos';
import { Button, Container, Grid, makeStyles, Paper } from '@material-ui/core';
import TablaActivosContratoGerencia from './tablaActivosContratoGerencia';
import { getBienes } from '../../../services/request/requestBienes';
import GraficoTipoAsignaciones from './graficoTipoAsignaciones';
import { getAsignaciones } from '../../../services/request/requestSolicitudesAsignacion';
import GraficoAsignacionesTipoRecurso from './graficoAsignacionesTipoRecursos';
import GraficoLineaActivaciones from './graficoLineaActivaciones';
import GraficoLineaBajas from './graficoLineaBajas';
import GraficoLineaAsignaciones from './graficoLineaAsignaciones';
import TablaBienesInforme from './tablaBieneslnforme';
import { DatePicker } from "@material-ui/pickers";
import SelectorContratos from './selectorContratos';
import SelectorGerencia from './selectorGerencias';
import SelectorTipoRecurso from './selectorTipoRecurso';
import SelectorClase from './selectorClase';

const useStyles = makeStyles((theme) => ({
  containerTabla: {
    margin: theme.spacing(2, 0, 1, 0)
  },
  paperPickers: {
    // margin: theme.spacing(1, 0, 2, 0),
    borderRadius: "15px",
    // color: "white",
    // backgroundColor: theme.palette.primary.main
  },
  buttonsFiltros: {
    margin: theme.spacing(1, 1, 1, 1)
  }
}));

export const InformeMovimientosActivosContext = createContext({
  indicadores: {
    totalActivos: 0,
    registroContableAprobados: 0,
    registroContablePendiente: 0,
    noActivable: 0
  },
  desde: moment().startOf('month').toDate(), hasta: moment().endOf('month').toDate(),
  isLoading: true, setIsloading: () => { },
  activosContratoGerencia: [], setActivosContratoGerencia: () => { },
  dataGraficoTipoAsignaciones: [], setDataGraficoTipoAsignaciones: () => { },
  openSelectContrato: false, setOpenSelectContrato: () => { },
  openSelectGerencia: false, setOpenSelectGerencia: () => { },
  openSelectTipoRecurso: false, setOpenSelectTipoRecurso: () => { },
  openSelectClase: false, setOpenSelectClase: () => { },
  contratosSeleccionados: [], setContratosSeleccionados: () => { },
  gerenciasSeleccionados: [], setGerenciasSeleccionados: () => { },
  tiposRecursosSeleccionados: [], setTiposRecursosSeleccionados: () => { },
  clasesSeleccionados: [], setClasesSeleccionados: () => { }
});

export default function InformeMovimientosActivos() {
  const classes = useStyles();
  const [indicadores, setIndicadores] = useState({
    totalActivos: 0,
    registroContableAprobados: 0,
    registroContablePendiente: 0,
    noActivable: 0
  });

  const [dataGraficoTipoAsignaciones, setDataGraficoTipoAsignaciones] = useState([]);
  const [dataGraficoAsignacionesTipoRecurso, setDataGraficoAsignacionesTipoRecurso] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [desde, setDesde] = useState(moment().startOf('month').toDate());
  const [hasta, setHasta] = useState(moment().endOf('day').toDate());
  const [activosContratoGerencia, setActivosContratoGerencia] = useState([]);
  const [openSelectContrato, setOpenSelectContrato] = useState(false);
  const [openSelectGerencia, setOpenSelectGerencia] = useState(false);
  const [openSelectTipoRecurso, setOpenSelectTipoRecurso] = useState(false);
  const [openSelectClase, setOpenSelectClase] = useState(false);
  const [contratosSeleccionados, setContratosSeleccionados] = useState([]);
  const [gerenciasSeleccionados, setGerenciasSeleccionados] = useState([]);
  const [tiposRecursosSeleccionados, setTiposRecursosSeleccionados] = useState([]);
  const [clasesSeleccionados, setClasesSeleccionados] = useState([]);




  const loadData = useCallback(async () => {
    setIsLoading(true);
    setDataGraficoTipoAsignaciones([]);
    setDataGraficoAsignacionesTipoRecurso([]);
    const dataGraficoTipoAsignaciones = [{ name: "Contrato", y: 0 },
    { name: "Persona", y: 0 }];
    const dataGraficoAsignacionesTipoRecurso = [
      { name: "Computacionales", y: 0 },
      { name: "Misceláneos", y: 0 },
      { name: "Topográfia y Laboratorio", y: 0 },
      { name: "Vehículos", y: 0 }
    ];
    const indicadores = {
      totalActivos: 0,
      registroContableAprobados: 0,
      registroContablePendiente: 0,
      noActivable: 0
    };

    const filtrosBienes = {
      fecha_creacion_lt: hasta,
      fecha_creacion_gt: desde,
      is_eliminado_eq: false
    };
    if (contratosSeleccionados && contratosSeleccionados.length > 0) filtrosBienes["_contrato_ref_in"] = contratosSeleccionados;
    if (gerenciasSeleccionados && gerenciasSeleccionados.length > 0) filtrosBienes["_gerencia_ref_in"] = gerenciasSeleccionados;
    if (tiposRecursosSeleccionados && tiposRecursosSeleccionados.length > 0) filtrosBienes["_tipo_recurso_ref_in"] = tiposRecursosSeleccionados;
    if (clasesSeleccionados && clasesSeleccionados.length > 0) filtrosBienes["_clase_ref_in"] = clasesSeleccionados;
    const responseBienes = await getBienes(
      filtrosBienes,
      ["codigo_activo", "_tipo_recurso_ref", "is_no_activable"],
      [
        "_gerencia_ref.sigla",
        "_contrato_ref.nombre", "_contrato_ref.codigo",
        "_clase_ref.nombre",
      ]
    );
    const responseAsignaciones = await getAsignaciones({
      fecha_creacion_lt: hasta,
      fecha_creacion_gt: desde
    }, ["tipo", "estado"], [
      "_bien_ref._tipo_recurso_ref", "_bien_ref._clase_ref", "_bien_ref._contrato_ref", "_bien_ref._gerencia_ref"
    ]);
    const activosContratoGerencia = [];
    if (responseBienes.status && responseAsignaciones) {
      indicadores.totalActivos = responseBienes.data.length;
      for (let i = 0; i < responseBienes.data.length; i++) {
        const value = responseBienes.data[i];

        if (value._contrato_ref) {
          const contrato = activosContratoGerencia.find(a => a._contrato_ref === value._contrato_ref._id);
          if (!contrato) {
            activosContratoGerencia.push({
              _contrato_ref: value._contrato_ref._id,
              gerencia: value._gerencia_ref.sigla,
              codigo_contrato: value._contrato_ref.codigo,
              nombre_contrato: value._contrato_ref.nombre,
              total_computacionales: value._tipo_recurso_ref === "5f91ec3f446036001810eeec" ? 1 : 0,
              total_miscelaneos: value._tipo_recurso_ref === "5f91eb0f446036001810eeea" ? 1 : 0,
              total_topografia_laboratorios: value._tipo_recurso_ref === "5f91edb4446036001810eeee" ? 1 : 0,
              total_vehiculos: value._tipo_recurso_ref === "5f91ed2845309100118c8286" ? 1 : 0
            });
          } else {
            switch (value._tipo_recurso_ref) {
              case "5f91ec3f446036001810eeec": {
                contrato.total_computacionales++;
                break;
              }
              case "5f91eb0f446036001810eeea": {
                contrato.total_miscelaneos++;
                break;
              }
              case "5f91edb4446036001810eeee": {
                contrato.total_topografia_laboratorios++;
                break;
              }
              case "5f91ed2845309100118c8286": {
                contrato.total_vehiculos++;
                break;
              }
              default: break;
            }
          }
        }
        if (value.is_no_activable) indicadores.noActivable++;
      }
      for (let i = 0; i < responseAsignaciones.length; i++) {
        const value = responseAsignaciones[i];
        if (gerenciasSeleccionados && gerenciasSeleccionados.length > 0) {
          if (!gerenciasSeleccionados.find(a => a === value._bien_ref._gerencia_ref)) continue;
        }
        if (contratosSeleccionados && contratosSeleccionados.length > 0) {
          if (!contratosSeleccionados.find(a => a === value._bien_ref._contrato_ref)) continue;
        }
        if (tiposRecursosSeleccionados && tiposRecursosSeleccionados.length > 0) {
          if (!tiposRecursosSeleccionados.find(a => a === value._bien_ref._tipo_recurso_ref)) continue;
        }
        if (clasesSeleccionados && clasesSeleccionados.length > 0) {
          if (!clasesSeleccionados.find(a => a === value._bien_ref._clase_ref)) continue;
        }
        if (value.tipo === "contrato") {
          dataGraficoTipoAsignaciones[0].y++;
        }
        if (value.tipo === "persona") {
          dataGraficoTipoAsignaciones[1].y++;
        }
        if (value._bien_ref) {
          switch (value._bien_ref._tipo_recurso_ref) {
            case "5f91ec3f446036001810eeec": {
              dataGraficoAsignacionesTipoRecurso[0].y++;
              break;
            }
            case "5f91eb0f446036001810eeea": {
              dataGraficoAsignacionesTipoRecurso[1].y++;
              break;
            }
            case "5f91edb4446036001810eeee": {
              dataGraficoAsignacionesTipoRecurso[2].y++;
              break;
            }
            case "5f91ed2845309100118c8286": {
              dataGraficoAsignacionesTipoRecurso[3].y++;
              break;
            }
            default: break;
          }
        }
        if (value.estado === "pendiente") indicadores.registroContablePendiente++;
        if (value.estado === "aceptado") indicadores.registroContableAprobados++;


      }
    }
    setIndicadores(indicadores);
    setDataGraficoTipoAsignaciones(dataGraficoTipoAsignaciones);
    setDataGraficoAsignacionesTipoRecurso(dataGraficoAsignacionesTipoRecurso);
    setActivosContratoGerencia(activosContratoGerencia);
    setIsLoading(false);
  }, [desde, hasta, contratosSeleccionados, gerenciasSeleccionados, tiposRecursosSeleccionados, clasesSeleccionados]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <React.Fragment>
      <InformeMovimientosActivosContext.Provider value={{
        desde, hasta,
        indicadores,
        isLoading,
        activosContratoGerencia,
        dataGraficoTipoAsignaciones,
        dataGraficoAsignacionesTipoRecurso,
        openSelectContrato, setOpenSelectContrato,
        openSelectGerencia, setOpenSelectGerencia,
        openSelectTipoRecurso, setOpenSelectTipoRecurso,
        openSelectClase, setOpenSelectClase,
        contratosSeleccionados, setContratosSeleccionados,
        gerenciasSeleccionados, setGerenciasSeleccionados,
        tiposRecursosSeleccionados, setTiposRecursosSeleccionados,
        clasesSeleccionados, setClasesSeleccionados
      }}>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Paper className={classes.paperPickers}>
              <Container>
                <Grid container alignItems="center" justify="center">
                  <Button variant="contained" className={classes.buttonsFiltros} color="primary" onClick={() => setOpenSelectGerencia(true)}>
                    Gerencia
              </Button>
                  <Button variant="contained" className={classes.buttonsFiltros} color="primary" onClick={() => setOpenSelectContrato(true)}>
                    Contrato
              </Button>
                  <Button variant="contained" className={classes.buttonsFiltros} color="primary" onClick={() => setOpenSelectTipoRecurso(true)}>
                    Tipo Recurso
              </Button>
                  <Button variant="contained" className={classes.buttonsFiltros} color="primary" onClick={() => setOpenSelectClase(true)}>
                    Clase
              </Button>
                </Grid>
              </Container>
            </Paper>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Paper className={classes.paperPickers}>
              <Container>
                <Grid container alignItems="center" justify="center">
                  <DatePicker
                    label="Fecha Inicio"
                    value={desde}
                    // minDate={Moment("2010-01-01 00:00:00")}
                    maxDate={hasta}
                    format="dd/MM/yyyy"
                    onChange={setDesde}
                    inputVariant="outlined"
                    size="small"
                    style={{ margin: 5 }}
                    okLabel="Aceptar"
                    cancelLabel="Cancelar"
                  />
                  <DatePicker
                    label="Fecha Término"
                    value={hasta}
                    minDate={desde}
                    maxDate={moment().endOf("day").toDate()}
                    format="dd/MM/yyyy"
                    onChange={setHasta}
                    inputVariant="outlined"
                    size="small"
                    style={{ margin: 5 }}
                    okLabel="Aceptar"
                    cancelLabel="Cancelar"
                  />
                </Grid>
              </Container>
            </Paper>
          </Grid>
        </Grid>
        <IndicadoresMovimientoActivos />
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TablaActivosContratoGerencia />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={2} xs={12}></Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <GraficoTipoAsignaciones />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <GraficoAsignacionesTipoRecurso />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <GraficoLineaActivaciones />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <GraficoLineaAsignaciones />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <GraficoLineaBajas />
          </Grid>
        </Grid>
        <div className={classes.containerTabla}>
          <TablaBienesInforme />
        </div>
        <SelectorContratos />
        <SelectorGerencia />
        <SelectorTipoRecurso />
        <SelectorClase />
      </InformeMovimientosActivosContext.Provider>
    </React.Fragment >
  );
}