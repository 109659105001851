import React, { createContext, useState } from 'react';
import MaterialTable from '../../../components/materialTable';
import useSWR from 'swr';
import {
  getTiposSeguros,
  postTiposSeguros,
  putTiposSeguros,
  deleteTiposSeguros,
} from '../../../services/request/requestTiposSeguros';
import { Fragment } from 'react';
import DialogoCRUDTipoSeguro from './dialogoCRUDTipoSeguro';

export const TablaTiposSegurosContext = createContext({
  openDialogoCRUD: false,
  setOpenDialogoCRUD: () => {},
  tituloDialogo: '',
  setTituloDialogo: () => {},
  initialValues: '',
  setInitialValues: () => {},
  handleAceptar: () => {},
  disableEditing: false,
  setDisableEditing: () => {},
});

async function getData(filtros = {}, setIsLoading = () => {}) {
  const result = await getTiposSeguros(filtros);
  if (result) {
    setIsLoading(false);
    return result.data;
  } else {
    setIsLoading(false);
    return [];
  }
}

export default function TablaTiposSeguros() {
  const [isLoading, setIsLoading] = useState(true);
  const { data, mutate } = useSWR('get:tipos-seguros', (key) => getData({}, setIsLoading));
  const [openDialogoCRUD, setOpenDialogoCRUD] = useState(false);
  const [tituloDialogo, setTituloDialogo] = useState('');
  const [initialValues, setInitialValues] = useState({ nombre: '' });
  const [disableEditing, setDisableEditing] = useState(false);

  const handleOpenAgregar = (titulo) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      nombre: '',
    });
    setDisableEditing(false);
  };

  const handleOpenModificar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      nombre: row.nombre,
      _id: row._id,
    });
    setDisableEditing(false);
  };

  const handleOpenQuitar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      nombre: row.nombre,
      _id: row._id,
    });
    setDisableEditing(true);
  };

  const handleAceptar = async (values) => {
    if (tituloDialogo) {
      if (tituloDialogo.includes('Nuev')) await postTiposSeguros({ ...values });
      else if (tituloDialogo.includes('Modificar'))
        await putTiposSeguros(values._id, {
          nombre: values.nombre,
        });
      else if (tituloDialogo.includes('Eliminar')) await deleteTiposSeguros(values._id);
    }
    mutate();
    setOpenDialogoCRUD(false);
  };

  const columns = [
    {
      title: 'Nombre',
      field: 'nombre',
      align: 'center',
      defaultSort: 'asc',
    },
  ];

  return (
    <Fragment>
      <TablaTiposSegurosContext.Provider
        value={{
          openDialogoCRUD,
          setOpenDialogoCRUD,
          tituloDialogo,
          setTituloDialogo,
          initialValues,
          setInitialValues,
          handleAceptar,
          disableEditing,
          setDisableEditing,
        }}
      >
        <MaterialTable
          actions={[
            {
              tooltip: 'Agregar',
              icon: 'add',
              onClick: (event, row) => handleOpenAgregar('Nuevo Tipo de Seguro'),
              isFreeAction: true,
            },
            {
              tooltip: 'Editar',
              icon: 'edit',
              onClick: (event, row) => handleOpenModificar('Modificar Tipo de Seguro', row),
            },
            {
              tooltip: 'Eliminar',
              icon: 'delete',
              onClick: (event, row) => handleOpenQuitar('Eliminar Tipo de Seguro', row),
            },
          ]}
          title="TiposSeguros"
          data={data}
          columns={columns}
          filtering={false}
          grouping={false}
          searching={false}
          empty_rows={true}
          page_size={20}
          is_loading={isLoading}
        />
        <DialogoCRUDTipoSeguro />
      </TablaTiposSegurosContext.Provider>
    </Fragment>
  );
}
