import React, { useContext, useEffect, useState } from "react";
import Page from "./page";
import { ObtenerGerencias, ObtenerContratos } from "../../../../services/request/requestPortafolio";
import { ObtenerClases } from "../../../../services/request/requestClase";
import { ObtenerTiposRecursos } from "../../../../services/request/requestTipoRecurso";
import { ObtenerBienesFiltros } from "../../../../services/request/requestBienes";
import { MainContext } from "../../../../App";

function Index(props) {
  const {
    is_open,
    handle_paso_2,
    handle_close,
  } = props;

  const [Gerencia, SetGerencia] = useState();
  const [Contrato, SetContrato] = useState();
  const [Clase, SetClase] = useState();
  const [TipoRecurso, SetTipoRecurso] = useState();
  const [Gerencias, SetGerencias] = useState([]);
  const [Contratos, SetContratos] = useState([]);
  const [Clases, SetClases] = useState([]);
  const [TiposRecursos, SetTiposRecursos] = useState([]);
  const [Bienes, SetBienes] = useState([]);
  const { usuarioSesion } = useContext(MainContext);

  /**
   * UseEffect inicial, para obtener las gerencias.
   */
  useEffect(() => {
    Promise.all([ObtenerGerencias(), ObtenerTiposRecursos()])
      .then(responses => {
        let gerencias = responses[0];
        SetGerencias(gerencias);
        let tiposRecursos = responses[1].data;
        SetTiposRecursos(tiposRecursos);
      })
      .catch(error => {
        console.error(error);
        SetGerencias([]);
        SetTiposRecursos([]);
      });
  }, []);

  /**
   * UseEffect para obtener los contratos, por gerencia seleccionada.
   */
  useEffect(() => {
    SetContrato(null);
    SetContratos([]);
    if (Gerencia) {
      ObtenerContratos(Gerencia._id)
        .then(contratos => SetContratos(contratos))
        .catch(error => {
          console.error(error);
          SetContratos([]);
        });
    }
  }, [Gerencia]);

  /**
   * UseEffect para obtener las clases, por tipo de recurso seleccionado.
   */
  useEffect(() => {
    SetClase(null);
    SetClases([]);
    if (TipoRecurso) {
      ObtenerClases(TipoRecurso._id)
        .then(response => SetClases(response.data))
        .catch(error => {
          console.error(error);
          SetClases([]);
        });
    }
  }, [TipoRecurso]);

  /**
   * UseEffect para obtener los bienes por gerencia y contrato seleccionados.
   */
  useEffect(() => {
    SetBienes([]);
    if (Gerencia || Contrato || TipoRecurso || Clase) {
      SetBienes();
      ObtenerBienesFiltros(Gerencia, Contrato, TipoRecurso, Clase)
        .then(response => {
          let bienes = Array.from(response.data);
          if (TipoRecurso) {
            //Se filtra por tipo de recurso.
            bienes = bienes.filter(d => d._clase_ref && d._clase_ref._tipo_recurso_ref === TipoRecurso._id);
          }
          if (Clase) {
            //Se filtra por clase de activo.
            bienes = bienes.filter(d => d._clase_ref && d._clase_ref._id === Clase._id);
          }
          if (Gerencia) {
            //Se filtra por gerencia.
            bienes = bienes.filter(d => d._gerencia_ref && d._gerencia_ref._id === Gerencia._id);
          }
          if (Contrato) {
            //Se filtra por contrato.
            bienes = bienes.filter(d => d._contrato_ref && d._contrato_ref._id === Contrato._id);
          }
          for (let i = 0; i < bienes.length; i++) {
            const bien = bienes[i];
            bien["gerencia"] = bien._gerencia_ref ? bien._gerencia_ref.sigla : "---";
            bien["contrato"] = bien._contrato_ref ? bien._contrato_ref.codigo : "---";
          }
          SetBienes(bienes);
        })
        .catch(error => {
          console.error(error);
          SetBienes([]);
        });
    }
  }, [Gerencia, Contrato, TipoRecurso, Clase]);

  return (
    <Page
      gerencia={Gerencia}
      set_gerencia={SetGerencia}
      contrato={Contrato}
      set_contrato={SetContrato}
      tipo_recurso={TipoRecurso}
      set_tipo_recurso={SetTipoRecurso}
      clase={Clase}
      set_clase={SetClase}
      gerencias={Gerencias}
      contratos={Contratos}
      tipos_recursos={TiposRecursos}
      clases={Clases}
      bienes={Bienes}
      is_open={is_open}
      handle_paso_2={handle_paso_2}
      handle_close={handle_close}
      usuario_sesion={usuarioSesion}
    />
  );
}

export default Index;