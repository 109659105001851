import React, { useState, useEffect } from 'react';
import Page from './page';
import { ObtenerBienes } from '../../../../services/request/requestBienes';
import useGerenciasUser from '../../../../hooks/useGerenciasUser';

function Index() {
  const { gerencias } = useGerenciasUser(false);
  const [dataGerencias, setDataGerencias] = useState();

  useEffect(() => {
    ObtenerBienes(false)
      .then((response) => {
        const bienes = response.data;
        gerencias.forEach((gerencia, index_1) => {
          gerencias[index_1]['cant_bienes'] = bienes.filter((b) => b._gerencia_ref === gerencia._id).length;
        });

        setDataGerencias(gerencias);
      })
      .catch((error) => {
        console.error(error);
        setDataGerencias([]);
      });
  }, [gerencias]);

  return <Page gerencias={dataGerencias} />;
}

export default Index;
