import React, { createContext, useState } from 'react';
import MaterialTable from '../../../components/materialTable';
import useSWR from 'swr';
import { Fragment } from 'react';
import { useHistory } from 'react-router';
import { AJUSTES_CONFIGURACION } from '../../../constants/routes';
import {
  getMarcasSoftware,
  postMarcaSoftware,
  putMarcaSoftware,
  deleteMarcaSoftware,
} from '../../../services/request/requestMarcasSoftware';
import DialogoCRUDMarcaSoftware from './dialogoCRUDMarcaSoftware';

export const TablaMarcasSoftwareContext = createContext({
  openDialogoCRUD: false,
  setOpenDialogoCRUD: () => {},
  tituloDialogo: '',
  setTituloDialogo: () => {},
  initialValues: '',
  setInitialValues: () => {},
  handleAceptar: () => {},
  disableEditing: false,
  setDisableEditing: () => {},
});

function getData() {
  return getMarcasSoftware({}, ['marca', '_id', 'descripcion'], []).then((software) => {
    return software;
  });
}

export default function TablaMarcasSoftware() {
  const { data: marcas, mutate } = useSWR('get:marcas-software', (key) => getData(), { refreshInterval: 0 });
  const [openDialogoCRUD, setOpenDialogoCRUD] = useState(false);
  const [tituloDialogo, setTituloDialogo] = useState('');
  const [initialValues, setInitialValues] = useState({
    marca: '',
    descripcion: '',
  });
  const [disableEditing, setDisableEditing] = useState(false);
  const history = useHistory();

  const handleOpenAgregar = (titulo) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      marca: '',
      descripcion: '',
    });
    setDisableEditing(false);
  };

  const handleOpenModificar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      marca: row.marca,
      descripcion: row.descripcion,
      id: row._id,
    });
    setDisableEditing(false);
  };

  const handleOpenQuitar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      marca: row.marca,
      descripcion: row.descripcion,
      id: row._id,
    });
    setDisableEditing(true);
  };

  const handleAceptar = async (values) => {
    if (tituloDialogo) {
      if (tituloDialogo.includes('Nuev')) await postMarcaSoftware({ ...values });
      else if (tituloDialogo.includes('Modificar'))
        await putMarcaSoftware(values.id, {
          marca: values.marca,
          descripcion: values.descripcion,
        });
      else if (tituloDialogo.includes('Eliminar')) await deleteMarcaSoftware(values.id);
    }
    mutate();
    setOpenDialogoCRUD(false);
  };

  const handleVolver = () => {
    history.push(AJUSTES_CONFIGURACION);
  };

  const columnsMarcas = [
    {
      title: 'ID',
      field: '_id',
      align: 'center',
    },
    {
      title: 'Marca',
      field: 'marca',
      align: 'center',
      defaultSort: 'asc',
    },
    {
      title: 'Descripción',
      field: 'descripcion',
      align: 'center',
    },
  ];

  return (
    <Fragment>
      <TablaMarcasSoftwareContext.Provider
        value={{
          openDialogoCRUD,
          setOpenDialogoCRUD,
          tituloDialogo,
          setTituloDialogo,
          initialValues,
          setInitialValues,
          handleAceptar,
          disableEditing,
          setDisableEditing,
        }}
      >
        <MaterialTable
          actions={[
            {
              tooltip: 'Agregar Marca',
              icon: 'add',
              onClick: (event, row) => handleOpenAgregar('Nueva Marca de Software'),
              isFreeAction: true,
            },
            {
              tooltip: 'Editar',
              icon: 'edit',
              onClick: (event, row) => handleOpenModificar('Modificar Marca de Software', row),
            },
            {
              tooltip: 'Eliminar',
              icon: 'delete',
              onClick: (event, row) => handleOpenQuitar('Eliminar Marca de Software', row),
            },
            {
              tooltip: 'Volver',
              icon: 'arrow_back',
              onClick: (event, row) => handleVolver(),
              isFreeAction: true,
            },
          ]}
          title="Marcas de Softwares"
          is_loading={!marcas}
          data={marcas && marcas.data}
          columns={columnsMarcas}
          filtering={false}
          grouping={false}
          exportButtonPDF={true}
          exportButtonExcel={true}
          empty_rows={true}
          page_size={20}
        />
        <DialogoCRUDMarcaSoftware />
      </TablaMarcasSoftwareContext.Provider>
    </Fragment>
  );
}
