import React, { useContext, useRef } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import QRCode from 'qrcode.react';
import { useReactToPrint } from 'react-to-print';
import { exportComponentAsJPEG } from 'react-component-export-image';
import { VerBienContext } from '.';
import { FRONTEND } from '../../../constants/urls';

function DialogVerQR(props) {
  const { id, bien, openDialogVerQR, setOpenDialogVerQR } = useContext(VerBienContext);

  const componentRef = useRef();

  const handlePrint = () => {
    reactToPrint();
    setOpenDialogVerQR(false);
  };

  const handleDownload = () => {
    exportComponentAsJPEG(componentRef, { fileName: `qr-activo-${bien.codigo_activo}` });
  };

  const reactToPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Dialog open={openDialogVerQR} fullWidth>
      <DialogTitle>Código QR del Activo</DialogTitle>
      <DialogContent dividers>
        <Typography>Código QR el que permite tener acceso a la información del activo.</Typography>
        <Box display="flex" justifyContent="center" justifyItems="center">
          <div ref={componentRef}>
            {/* <Box display="flex" justifyContent="center"> */}
            <QRCode value={`${FRONTEND}/activos/${id}`} size={256} />
            {/* </Box> */}
            <Box display="flex" justifyContent="center" width={256}>
              <Typography>{bien.codigo_activo}</Typography>
            </Box>
          </div>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography>URL: {`${FRONTEND}/activos/${id}`}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => setOpenDialogVerQR(false)} color="primary" variant="outlined">
          Salir
        </Button>
        <Button autoFocus onClick={handlePrint} color="primary" variant="contained">
          Imprimir
        </Button>
        <Button autoFocus onClick={handleDownload} color="primary" variant="contained">
          Descargar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogVerQR;
