import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import { TablaMantencionesContext } from '../tablaMantenimientosVehiculares';
import { useCallback, useContext, useEffect } from 'react';
import { useFormik } from 'formik';

export default function DialogoEliminarMantencion(props) {
  const {
    activo_seleccionado,    
  } = props; 

  const {
    openDialogoEliminar, 
    setOpenDialogoEliminar,	
    motivoEliminacion,
    setMotivoEliminacion,							
    handleEliminar,
  } = useContext(TablaMantencionesContext);

  const formik = useFormik({ initialValues: activo_seleccionado, onSubmit: handleEliminar });

  const { values, handleSubmit, resetForm, setFieldValue, handleChange, touched, errors } = formik;

  const handleClose = () => setOpenDialogoEliminar(false);

  const resetFormik = useCallback(() => {
    resetForm({ values: activo_seleccionado });
  }, [activo_seleccionado, resetForm]);

  useEffect(() => {
    resetFormik();
  }, [resetFormik]);

  return (
    <Dialog 
      open={openDialogoEliminar}       
      maxWidth="md" 
      fullWidth
    >
      <DialogTitle>
        ¿Está seguro de eliminar el registro de Mantenimiento Vehicular seleccionado?
      </DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                name="folio"
                label="Folio Mantenimiento"
                value={values?.mantenimiento?.folio}                
                variant="outlined"
                fullWidth
                disabled={true}                
              />
            </Grid>                               
            <Grid item xs>
              <TextField
                name="patente"
                label="Patente"
                value={values?.patente}                
                variant="outlined"
                fullWidth
                disabled={true}                
              />
            </Grid>
            <Grid item xs>
              <TextField
                name="tipo_mantenimiento"
                label="Tipo Mantenimiento"
                value={values?.mantenimiento?.mantencion_programada}                
                variant="outlined"
                fullWidth
                disabled={true}                
              />
            </Grid>            
          </Grid>
          <Grid container spacing={2}>
          <Grid item xs>
              <TextField
                name="motivo"
                label="Motivo Eliminación"
                value={motivoEliminacion}                                               
                onChange={(event) => setMotivoEliminacion(event.target.value)}
                variant="outlined"
                fullWidth         
                multiline
                minRows={3} 
                helperText="Requerido para eliminar el mantenimiento"                      
              />
            </Grid>
          </Grid>
          <br></br>
          <Grid container >
            <Grid item xs>
            <Typography >Nota: Recuerde que antes de eliminar el mantenimiento, debe eliminar la Factura registrada en el Gestor de Pagos, para evitar incongruencias o problemas entre estos sistemas. Cualquier duda contacte al equipo de soporte.</Typography>
            </Grid>                               
            
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancelar
          </Button>
          <Button disabled={CheckButtonDisabled(motivoEliminacion)} type="submit" variant="contained" color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function CheckButtonDisabled(motivo) {  
  if (!motivo) {
    return true;
  }
  if (motivo.length < 3) {
    return true;
  }
  return false;
}