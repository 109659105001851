import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
  Box,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { TablaUbicacionesContext } from './tablaUbicaciones';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Ubicaciones } from './componentes/ubicaciones';
import ListadoDetalleUbicaciones from './componentes/listadoDetalleUbicaciones';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const validationSchema = yup.object({
  ubicacion: yup.string().required(),
});

export default function DialogoCRUDUbicaciones() {
  const { openDialogoCRUD, setOpenDialogoCRUD, tituloDialogo, handleAceptar, initialValues } =
    useContext(TablaUbicacionesContext);

  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();
  const smBreak = useMediaQuery(theme.breakpoints.down('sm'));
  const formik = useFormik({
    initialValues,
    onSubmit: handleAceptar,
    validationSchema,
  });

  const { values, handleSubmit, resetForm } = formik;

  const resetFormik = useCallback(() => {
    resetForm({ values: initialValues });
    console.log('Valores dados: ', initialValues);
  }, [initialValues, resetForm]);

  useEffect(() => {
    resetFormik();
  }, [resetFormik]);

  const handleClose = () => setOpenDialogoCRUD(false);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Dialog open={openDialogoCRUD} maxWidth="md" fullWidth>
      <DialogTitle>{tituloDialogo}</DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={selectedTab}
                onChange={handleChangeTab}
                aria-label="basic tabs"
                centered={!smBreak}
                variant={smBreak ? 'scrollable' : 'standard'}
                scrollButtons={smBreak ? 'on' : 'off'}
              >
                <Tab label="Ubicaciones" {...a11yProps(0)} />
                <Tab label="Posiciones" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <br></br>
            <TabPanel value={selectedTab} index={0}>
              <Ubicaciones formik={formik} />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <ListadoDetalleUbicaciones
                id_ubicacion={values.id}
                listado_detalles={values?._detalle_ubicacion_ref ? values?._detalle_ubicacion_ref : []}
              />
            </TabPanel>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
