import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { DetalleLicenciaSoftwareContext } from './index';
import { postAsignacionLicenciaSoftware, postLogLicenciaSoftware } from '../../services/request/requestLicenciasSoftware';
import MaterialTable from "@material-table/core";
import { getBienes } from '../../services/request/requestBienes';
import { useParams } from 'react-router';
import { MainContext } from '../../App';
import { getClases } from '../../services/request/requestClase';
import { TablaAsignacioneLicenciasSoftContext } from './tablaAsignacionesLicenciasSoft';
import { useSnackbar } from "notistack";
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export default function DialogoCRUDAsignacionSoftware() {

  const { id } = useParams();
  const notistack = useSnackbar();
  const { refreshTable, openDialogo, closeDialogo, data: asignaciones } = useContext(TablaAsignacioneLicenciasSoftContext);

  const { refreshDataLicencia } = useContext(DetalleLicenciaSoftwareContext);

  const { usuarioSesion } = useContext(MainContext);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [totalChecked, setTotalChecked] = useState(0);
  const [allChecked, setAllChecked] = useState(false);

  function changeTitleCheck(e, checked) {
    setAllChecked(checked);
    setData(data.map(a => ({ ...a, checked: checked })));
    if (!checked) {
      setTotalChecked(0);
    } else {
      setTotalChecked(data.length);
    }
  }

  function checkCell(row) {
    if (row.checked) {
      row.checked = false;
      let newtotalChecked = totalChecked - 1;
      setAllChecked(false);
      setTotalChecked(newtotalChecked);
    } else {
      row.checked = true;
      let newtotalChecked = totalChecked + 1;
      setAllChecked(false);
      setTotalChecked(newtotalChecked);
    }
  }

  const handleOpen = async () => {
    setIsLoading(true);
    setData([]);
    // resetForm();
    const responseClases = await getClases({ is_computacional_eq: true });
    let clases = [];
    if (responseClases.status) clases = responseClases.data.map(a => a._id);
    const response = await getBienes(
      {
        _clase_ref_in: clases,
      },
      ["codigo_activo", "codigo_contable", "serial_number", "marca", "modelo", "descripcion"],
      [
        "_gerencia_ref.nombre",
        "_contrato_ref.nombre", "_contrato_ref.codigo",
        "_clase_ref.nombre",
        "_vehiculo_ref.patente",
        "_asignado_ref.nombre", "_asignado_ref.apellido_paterno", "_asignado_ref.apellido_materno"
      ]);
    if (response.status) {
      const bienes = response.data.filter(a => !asignaciones.find(b => b._bien_ref === a._id)).map(a => ({
        _id: a._id,
        checked: false,
        _asignado_ref: a._asignado_ref ? a._asignado_ref._id : null,
        _contrato_ref: (a._contrato_ref ? a._contrato_ref._id : null),
        _gerencia_ref: (a._gerencia_ref ? a._gerencia_ref._id : null),
        codigo_activo: a.codigo_activo,
        codigo_contable: a.codigo_contable,
        serial_number: a.serial_number,
        marca: a.marca,
        modelo: a.modelo,
        descripcion: a.descripcion,
        gerencia: (a._gerencia_ref && a._gerencia_ref.nombre ? a._gerencia_ref.nombre : "--"),
        contrato: (a._contrato_ref && a._contrato_ref.nombre ? a._contrato_ref.nombre : "--"),
        codigo_contrato: (a._contrato_ref && a._contrato_ref.codigo ? a._contrato_ref.codigo : "--"),
        patente: (a._vehiculo_ref && a._vehiculo_ref.patente ? a._vehiculo_ref.patente : "--"),
        clase: a._clase_ref && a._clase_ref.nombre ? a._clase_ref.nombre : "--",
        asignado: (a._asignado_ref ? (a._asignado_ref.nombre + " " + a._asignado_ref.apellido_paterno + " " + a._asignado_ref.apellido_materno) : "--")
      }));

      setData(bienes);
    }
    setIsLoading(false);
  }

  const columns = [
    {
      title: <Checkbox checked={allChecked} indeterminate={(!allChecked && totalChecked > 0)} onChange={changeTitleCheck} />,
      render: (row) => <Checkbox checked={row.checked} onChange={() => checkCell(row)} />
    },
    {
      title: "Cód. activo",
      field: "codigo_activo",
    },
    {
      title: "Cód. contable",
      field: "codigo_contable",
    },
    {
      title: "N° serie",
      field: "serial_number",

    },
    {
      title: "Marca",
      field: "marca",

    },
    {
      title: "Modelo",
      field: "modelo",

    },
    {
      title: "Descripción",
      field: "descripcion",
      cellStyle: {
        minWidth: "300px"
      }
    },
    {
      title: "Gerencia",
      field: "gerencia",
    },
    {
      title: "Contrato",
      field: "contrato",
      cellStyle: {
        minWidth: "200px"
      }
    },
    {
      title: "Cód. contrato",
      field: "codigo_contrato",
    },
    {
      title: "Asignado a",
      field: "asignado",
    },
    {
      title: "Clase",
      field: "clase",
    },
    {
      title: "Patente",
      field: "patente",
    }
  ];

  const theme = useTheme();

  const smBreak = useMediaQuery(theme.breakpoints.up('xs'));

  async function handleSubmit() {
    const selected = data.filter(a => a.checked);
    if (!selected || selected.length === 0) {
      closeDialogo();
    }

    const key = notistack.enqueueSnackbar("Asignando licencias...", {
      persist: true,
      anchorOrigin: {
        horizontal: "center",
        vertical: "bottom"
      }
    });
    try {

      for (let i in selected) {
        const rowData = selected[i];
        await postAsignacionLicenciaSoftware({
          _bien_ref: rowData._id,
          _contrato_ref: rowData._contrato_ref,
          _gerencia_ref: rowData._gerencia_ref,
          _usuario_ref: rowData._asignado_ref,
          _licencia_software_ref: id
        });
        await postLogLicenciaSoftware({
          _usuario_ref: usuarioSesion.ref,
          _licencia_software_ref: id,
          descripcion: `Asignación de Licencia a ${rowData.codigo_activo}`
        });

      }
      closeDialogo();
      refreshTable();
      refreshDataLicencia();
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar("Operanción exitosa: Licencias asignadas", {
        variant: 'success',
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
      });
    }
    catch (e) {
      console.log(e);
      notistack.closeSnackbar(key);
      const successkey = notistack.enqueueSnackbar("Error al intentar asignar las licencias. Intente de nuevo o contacte con el equipo de soporte.", {
        variant: 'error',
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
      });
    }

  }

  return (

    <Dialog open={openDialogo} onEnter={handleOpen} onClose={closeDialogo} fullWidth maxWidth="xl">
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <MaterialTable
            title={"Seleccionar un Activo para la asignación"}
            isLoading={isLoading}
            columns={columns}
            data={data ? data : []}
            options={{
              filtering: true
            }}
            style={{
              maxWidth: smBreak ? "92vw" : "72vw"
            }}
          />
        </DialogContent>
        <DialogActions>
          <Grid container direction='row' alignItems="center" justify="center">
            <Button onClick={closeDialogo} variant="outlined" color="primary" style={{ marginRight: '8px' }}>
              Cerrar
          </Button>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              Aceptar
          </Button>
          </Grid>
        </DialogActions>
      </form>
    </Dialog >
  );
}

