import React from 'react';
import MaterialReactSelect from '../../../components/materialReactSelect';

const SelectProveedores = ({ proveedores, onChange, value }) => {
  const opciones = proveedores.map((proveedor) => ({
    value: proveedor._id,
    label: proveedor.nombre,
  }));

  if (opciones.length === 0) {
    return <p>SIN Proveedores</p>;
  }

  return (
    <MaterialReactSelect
      options={opciones}
      onChange={onChange}
      value={opciones.find((opcion) => opcion.value === value)}
      label="Proveedor GPS"
      isClearable
      margin="none"
      placeholder="Proveedor GPS"
      noOptionsMessage={() => 'No ha seleccionado el proveedor'}
    />
  );
};

export default SelectProveedores;
