import React, { useCallback, useContext, useState } from 'react';
import MaterialTable from '../../../components/materialTable';
import { useHistory } from 'react-router';
import useSWR from 'swr';
import { MTableAction, MTableToolbar } from '@material-table/core';
import { Button, Checkbox } from '@material-ui/core';
import Moment from 'moment';
import DialogoGPS from '../dialogos/dialogoGPS';
import { getBienesVehiculosGPSPopulado, getVehiculosGPSPopulado } from '../../../services/request/requestVehiculos';
import { CRUD_ACTIONS } from '../../../constants/constantes';
import {
  AGREGAR_DISPOSITIVO_GPS,
  EDITAR_DISPOSITIVO_GPS,
  ELIMINAR_DISPOSITIVO_GPS,
  VER_CONTRATO_DISPOSITIVO_GPS,
} from '../../../constants/permissions';
import { MainContext } from '../../../App';

export default function TablaEquipamiento(props) {
  const { bien } = props;
  const [activoSeleccionado, setActivoSeleccionado] = useState(null);
  const [openRegistroEquipamiento, setOpenRegistroEquipamiento] = useState(false);
  const [accionARealizar, setAccionARealizar] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const { permisos } = useContext(MainContext);
  const history = useHistory();

  const getData = () =>
    bien && bien._vehiculo_ref._id ? getVehiculosGPSPopulado(bien._vehiculo_ref._id) : getBienesVehiculosGPSPopulado();

  const { data: datosPopuladosBienesVehiculosGPS } = useSWR(bien ? 'equipamientoGPSTodos' : 'GPSVehiculo', getData);

  const handleOpenRegistroEquipamiento = useCallback((seleccionado, open, action) => {
    setActivoSeleccionado(seleccionado);
    setOpenRegistroEquipamiento(open);
    setAccionARealizar(action);
  }, []);

  const handleRowSelection = (e, rowData) => {
    const newSelectedRows = [...selectedRows];
    const isSelected = newSelectedRows.some((row) => row._id === rowData._id);

    if (isSelected) {
      const indexToRemove = newSelectedRows.findIndex((row) => row._id === rowData._id);
      newSelectedRows.splice(indexToRemove, 1);
    } else {
      newSelectedRows.push(rowData);
    }

    setSelectedRows(newSelectedRows);
  };

  const handleRegistrarSeleccionados = () => {
    console.log('Dispositivos seleccionados:', selectedRows);
  };

  const columnsSinID = [
    {
      title: 'Selección',
      sorting: false,
      grouping: false,
      headerStyle: { textAlign: 'center' },
      render: (rowData) => (
        <Checkbox
          checked={selectedRows.some((row) => row._id === rowData._id)}
          onChange={(e) => {
            rowData.checked = e.target.checked;
            handleRowSelection(e, rowData);
          }}
        />
      ),
    },
    {
      title: 'Patente',
      field: 'vehiculo.patente',
      align: 'center',
    },
    {
      title: 'Código Activo',
      field: 'codigo_activo',
      align: 'center',
    },
    {
      title: 'Marca',
      field: 'marca',
      align: 'center',
    },
    {
      title: 'Modelo',
      field: 'modelo',
      align: 'center',
    },
    {
      title: 'Fecha Creación Registro',
      field: 'equipamientoGPS.fecha_creacion',
      align: 'center',
      render: (row) =>
        row?.equipamientoGPS?.fecha_creacion ? Moment(row?.equipamientoGPS?.fecha_creacion).format('DD/MM/YYYY') : '--',
      hidden: true,
    },
    {
      title: 'Ingresado por',
      field: 'equipamientoGPS._ingresado_por_ref',
      align: 'center',
      hidden: true,
    },
    {
      title: 'Estado de GPS',
      field: 'equipamientoGPS.estadoGPS',
      align: 'center',
    },
    {
      title: 'Fecha de Instalación Dispositivo',
      field: 'equipamientoGPS.fechaInstalacion',
      align: 'center',
      render: (row) =>
        row?.equipamientoGPS?.fechaInstalacion
          ? Moment(row?.equipamientoGPS?.fechaInstalacion).format('DD/MM/YYYY')
          : ' ',
    },
    {
      title: 'Proveedor GPS',
      field: 'proveedorGPS.nombre',
      align: 'center',
    },
    {
      title: 'ID dispositivo GPS',
      field: 'equipamientoGPS.idGPS',
      align: 'center',
    },
    {
      title: 'N° Contrato GPS',
      field: 'equipamientoGPS.numeroContratoGPS',
      align: 'center',
    },
    {
      title: 'Vencimiento Contrato GPS',
      field: 'equipamientoGPS.fechaTerminoContratoGPS',
      align: 'center',
      render: (row) =>
        row?.equipamientoGPS?.fechaTerminoContratoGPS
          ? Moment(row?.equipamientoGPS?.fechaTerminoContratoGPS).format('DD/MM/YYYY')
          : ' ',
    },
    {
      title: 'Clase',
      field: '_clase_ref.nombre',
      align: 'center',
    },
    {
      title: 'Asignado',
      field: '_asignado_ref.nombre_abreviado',
      align: 'center',
      render: (row) => (row?._asignado_ref?.nombre_abreviado ? row?._asignado_ref?.nombre_abreviado : '--'),
    },
    {
      title: 'Cod. Contrato',
      field: '_contrato_ref.codigo',
      align: 'center',
    },
    {
      title: 'Contrato',
      field: '_contrato_ref.nombre',
      align: 'center',
    },
    {
      title: 'Gerencia',
      field: '_gerencia_ref.nombre',
      align: 'center',
    },
    {
      title: 'Sociedad',
      field: '_sociedad_ref.nombre',
      align: 'center',
    },
  ];

  const columnsConID = [
    {
      title: 'Fecha Creación Registro',
      field: 'equipamientoGPS.fecha_creacion',
      align: 'center',
      render: (row) =>
        row?.equipamientoGPS?.fecha_creacion ? Moment(row?.equipamientoGPS?.fecha_creacion).format('DD/MM/YYYY') : '--',
      hidden: true,
    },
    {
      title: 'Ingresado por',
      field: 'equipamientoGPS._ingresado_por_ref',
      align: 'center',
      hidden: true,
    },
    {
      title: 'Estado de GPS',
      field: 'equipamientoGPS.estadoGPS',
      align: 'center',
    },
    {
      title: 'Fecha de Instalación Dispositivo',
      field: 'equipamientoGPS.fechaInstalacion',
      align: 'center',
      render: (row) =>
        row?.equipamientoGPS?.fechaInstalacion
          ? Moment(row?.equipamientoGPS?.fechaInstalacion).format('DD/MM/YYYY')
          : ' ',
    },
    {
      title: 'Proveedor GPS',
      field: 'equipamientoGPS._proveedorGPS_ref',
      align: 'center',
      render: (row) =>
        row?.equipamientoGPS?._proveedorGPS_ref ? row?.equipamientoGPS?._proveedorGPS_ref?.nombre : ' ',
    },
    {
      title: 'ID dispositivo GPS',
      field: 'equipamientoGPS.idGPS',
      align: 'center',
    },
    {
      title: 'N° Contrato GPS',
      field: 'equipamientoGPS.numeroContratoGPS',
      align: 'center',
    },
    {
      title: 'Vencimiento Contrato GPS',
      field: 'equipamientoGPS.fechaTerminoContratoGPS',
      align: 'center',
      render: (row) =>
        row?.equipamientoGPS?.fechaTerminoContratoGPS
          ? Moment(row?.equipamientoGPS?.fechaTerminoContratoGPS).format('DD/MM/YYYY')
          : ' ',
    },
  ];

  // Función para determinar si una acción debe estar oculta
  const isActionHidden = (action, rowData) => {
    switch (action) {
      case 'delete':
        return !rowData?.equipamientoGPS?._id || !permisos[ELIMINAR_DISPOSITIVO_GPS];
      case 'edit':
        return !rowData?.equipamientoGPS?._id || !permisos[EDITAR_DISPOSITIVO_GPS];
      case 'description':
        return !rowData.equipamientoGPS?.contratoGPS || !permisos[VER_CONTRATO_DISPOSITIVO_GPS];
      case 'preview':
        return !rowData?.equipamientoGPS?.certificadoCoberturaGPS;
      case 'registrarGps':
        return !bien || !permisos[AGREGAR_DISPOSITIVO_GPS];
      case 'chevron_right':
        return bien;
      default:
        return false;
    }
  };

  const actions = [
    {
      icon: 'chevron_right',
      tooltip: 'Ver Detalles',
      onClick: (event, rowData) => history.push(`activos/${rowData._id}`),
    },
    {
      icon: 'delete',
      tooltip: 'Borrar',
      onClick: (event, rowData) =>
        handleOpenRegistroEquipamiento(
          bien
            ? {
                ...rowData,
                codigo_activo: bien.codigo_activo,
                marca: bien.marca,
                modelo: bien.modelo,
                patente: bien.patente,
              }
            : rowData.patente
            ? rowData
            : { ...rowData, patente: rowData.vehiculo.patente },
          true,
          CRUD_ACTIONS.DELETE,
        ),
    },
    {
      icon: 'edit',
      tooltip: 'Editar',
      onClick: (event, rowData) =>
        handleOpenRegistroEquipamiento(
          bien
            ? {
                ...rowData,
                codigo_activo: bien.codigo_activo,
                marca: bien.marca,
                modelo: bien.modelo,
                patente: bien.patente,
              }
            : rowData.patente
            ? rowData
            : { ...rowData, patente: rowData.vehiculo.patente },
          true,
          CRUD_ACTIONS.EDIT,
        ),
    },
    {
      icon: 'description',
      tooltip: 'Ver Contrato',
      onClick: (event, rowData) => window.open(rowData?.equipamientoGPS?.contratoGPS.url, '_blank'),
    },
    {
      icon: 'preview',
      tooltip: 'Ver Certificado Cobertura',
      onClick: (event, rowData) => window.open(rowData?.equipamientoGPS?.certificadoCoberturaGPS.url, '_blank'),
    },
    {
      icon: 'registrarGps',
      tooltip: 'Ingresar GPS',
      position: 'row',
      onClick: (event, row) => handleOpenRegistroEquipamiento(bien, true, CRUD_ACTIONS.ADD),
      isFreeAction: true,
    },
  ];

  // Función para renderizar la acción personalizada 'Registrar GPS'
  const renderRegistrarGPSAction = (propData) => {
    const rowData = propData.data;

    const valorDeHidden = isActionHidden(propData.action.icon, rowData);

    propData.action.hidden = valorDeHidden;

    if (propData.action.icon === 'registrarGps') {
      return (
        !propData.action.hidden && (
          <Button
            onClick={(event) => propData.action.onClick(event, rowData)}
            color="primary"
            style={{
              color: 'white',
              backgroundColor: '#731f1f',
              marginLeft: 5,
              textTransform: 'none',
            }}
            variant="contained"
            size="small"
          >
            {'Registrar GPS'}
          </Button>
        )
      );
    }
    return <MTableAction {...propData} />;
  };

  return (
    <>
      <MaterialTable
        title={bien ? 'Dispositivos GPS Instalados' : 'Equipamiento GPS en Flota de CyD'}
        data={
          datosPopuladosBienesVehiculosGPS
            ? datosPopuladosBienesVehiculosGPS.filter((bien) => {
                if (!bien.equipamientoGPS) {
                  return true;
                }

                return !bien.equipamientoGPS.is_eliminado;
              })
            : []
        }
        columns={bien && bien._vehiculo_ref._id ? columnsConID : columnsSinID}
        grouping={false}
        actions={actions}
        components={{
          Action: renderRegistrarGPSAction,
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div style={{ padding: '0 16px' }}>
                {selectedRows.length > 0 && (
                  <Button variant="contained" color="primary" onClick={handleRegistrarSeleccionados}>
                    Registrar GPS a Selección
                  </Button>
                )}
              </div>
            </div>
          ),
        }}
      />

      <DialogoGPS
        activo_seleccionado={activoSeleccionado}
        is_open={openRegistroEquipamiento}
        handle_open={handleOpenRegistroEquipamiento}
        action={accionARealizar}
      />
    </>
  );
}
