import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
import useSWR, { mutate } from 'swr';
import * as yup from 'yup';
import { Close } from '@material-ui/icons';
import { MainContext } from '../../../App';
import FileInput from '../../../components/fileInput';
import { GenerarLog } from '../../../services/activoLog';
import { uploadFileToCloudStorage } from '../../../services/fileUpload';
import MaterialReactSelect from '../../../components/materialReactSelect';
import { InfoVehiculo } from '../../gestionFlota/components/InfoVehiculo';
import { getProveedoresGPS } from '../../../services/request/requestProveedorGPS';
import { postAddGPSVehiculo } from '../../../services/request/requestVehiculos';
import SelectProveedores from '../components/SelectProveedores';
import { CRUD_ACTIONS } from '../../../constants/constantes';
import {
  deleteEquipamientoVehicular,
  getEquipamientos,
  putEquipamientoVehicular,
} from '../../../services/request/requestEquipamientoVehicular';

const useStyles = makeStyles(() => ({
  fileInput: {
    top: 'unset',
    left: 'unset',
    position: 'inherit',
    textAlign: 'left',
  },
}));

const estadoGPS = [
  { label: 'Activo', value: 'Activo' },
  { label: 'Desactivado', value: 'Desactivado' },
  { label: 'Integración', value: 'Integración' },
];

export default function DialogoGPS(props) {
  const { activo_seleccionado, is_open, handle_open, action } = props;
  const { usuarioSesion } = useContext(MainContext);
  const [proveedorSeleccionado, setProveedorSeleccionado] = useState('');
  const { data: proveedoresGPS } = useSWR('get:proveedoresGPS', (key) => getProveedoresGPS());
  const notistack = useSnackbar();
  const formik = useFormik({
    initialValues: {
      _vehiculo_ref: '',
      _bien_ref: null,
      patente: '',
      codigo_activo: '',
      color: '',
      marca: '',
      modelo: '',
      descripcionGPS: '',
      _proveedorGPS_ref: '',
      fechaTerminoContratoGPS: new Date(),
      fechaInstalacion: new Date(),
      idGPS: '',
      estadoGPS: '',
      contratoGPS: null,
      certificadoCoberturaGPS: null,
      numeroContratoGPS: '',
    },
    validationSchema: yup.object().shape({
      _proveedorGPS_ref: yup.mixed().required('Debe seleccionar un proveedor'),
    }),
    onSubmit: async (values) => {
      if (CRUD_ACTIONS[action] !== CRUD_ACTIONS.DELETE && values.idGPS.length > 2) {
        const consultaIDVerificar = await getEquipamientos({ idGPS_eq: values.idGPS, is_eliminado_eq: false });

        if (consultaIDVerificar && consultaIDVerificar.data.length > 0) {
          const result = window.confirm(
            '¿Ya se encuentra registrado en Sistema el ID del GPS indicado, Lo quieres volver a registrar?',
          );
          if (!result) {
            // handleClose();
            return;
          }
        }
      }
      const key = notistack.enqueueSnackbar('Guardando datos del GPS...', {
        persist: true,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
      });
      try {
        // if (activo_seleccionado._vehiculo_ref) {
        console.log('valores dados: ', values);

        let uploadedContrato = null;

        if (values.contratoGPS) {
          uploadedContrato = await uploadFileToCloudStorage(
            values.contratoGPS,
            `activos/${activo_seleccionado.codigo_activo}`,
            values.contratoGPS.name,
          );
        }
        let uploadedCertificado = null;

        if (values.certificadoCoberturaGPS) {
          uploadedCertificado = await uploadFileToCloudStorage(
            values.certificadoCoberturaGPS,
            `activos/${activo_seleccionado.codigo_activo}`,
            values.certificadoCoberturaGPS.name,
          );
        }
        const registroLog = {
          _bien_ref: activo_seleccionado._id,
          titulo: 'Registro de Equipamiento GPS',
          cuerpo: `El usuario ${usuarioSesion.nombre} ${
            CRUD_ACTIONS[action] === CRUD_ACTIONS.ADD
              ? 'registró'
              : CRUD_ACTIONS[action] === CRUD_ACTIONS.EDIT
                ? 'actualizó'
                : 'eliminó'
          } el GPS con ID: ${values.idGPS}, al activo ${activo_seleccionado.codigo_activo}.`,
          tipo: 'actualizacion',
          icon: 'edit',
        };
        const datosAGuardar = {
          _ingresado_por_ref: usuarioSesion.ref,
          descripcionGPS: values.descripcionGPS,
          _proveedorGPS_ref: values._proveedorGPS_ref,
          fechaTerminoContratoGPS: values.fechaTerminoContratoGPS,
          fechaInstalacion: values.fechaInstalacion,
          idGPS: values.idGPS,
          estadoGPS: values.estadoGPS,
          numeroContratoGPS: values.numeroContratoGPS,
          contratoGPS: uploadedContrato,
          certificadoCoberturaGPS: uploadedCertificado,
        };

        switch (action) {
          case CRUD_ACTIONS.ADD:
            await postAddGPSVehiculo(activo_seleccionado._vehiculo_ref._id, datosAGuardar);
            break;

          case CRUD_ACTIONS.EDIT:
            await putEquipamientoVehicular(activo_seleccionado.equipamientoGPS._id, datosAGuardar);
            break;
          case CRUD_ACTIONS.DELETE:
            await deleteEquipamientoVehicular(activo_seleccionado.equipamientoGPS._id);
            break;

          default:
            break;
        }

        await GenerarLog(registroLog);

        mutate('equipamientoGPSTodos');
        mutate('GPSVehiculo');
        mutate('CantidadGPSProveedor');
        handle_open(null, false);
        // }
        notistack.closeSnackbar(key);
        const successkey = notistack.enqueueSnackbar('Operación exitosa...', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar(successkey)}>
              <Close />
            </IconButton>
          ),
        });
      } catch (e) {
        console.log(e);
        notistack.closeSnackbar(key);
        const errorkey = notistack.enqueueSnackbar(
          'Error: No ha sido posible realizar la operación seleccionada al dispositivo de GPS. Intente de nuevo o contacte con Soporte.',
          {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            action: (
              <IconButton onClick={() => notistack.closeSnackbar(errorkey)}>
                <Close />
              </IconButton>
            ),
          },
        );
      }
    },
  });
  const classes = useStyles();
  const { handleSubmit, values, touched, errors, setFieldValue, resetForm, handleChange } = formik;

  const handleClose = () => {
    resetForm({ values: formik.initialValues });
    setProveedorSeleccionado('');
    handle_open(null, false);
  };

  const handleOnEntered = () => {
    const valores = {
      _vehiculo_ref: activo_seleccionado && activo_seleccionado?._vehiculo_ref ? activo_seleccionado._vehiculo_ref : '',
      _bien_ref: activo_seleccionado
        ? activo_seleccionado?._bien_ref
          ? activo_seleccionado?._bien_ref
          : activo_seleccionado?._id
        : '',
      marca: activo_seleccionado
        ? activo_seleccionado?._bien_ref
          ? activo_seleccionado?._bien_ref.marca
          : activo_seleccionado?.marca
        : '',
      modelo: activo_seleccionado
        ? activo_seleccionado?._bien_ref
          ? activo_seleccionado?._bien_ref.modelo
          : activo_seleccionado?.modelo
        : '',
      patente: activo_seleccionado && activo_seleccionado.patente ? activo_seleccionado?.patente : '',
      codigo_activo: activo_seleccionado
        ? activo_seleccionado?._bien_ref
          ? activo_seleccionado?._bien_ref.codigo_activo
          : activo_seleccionado?.codigo_activo
        : '',
      descripcionGPS:
        activo_seleccionado && activo_seleccionado.equipamientoGPS
          ? activo_seleccionado?.equipamientoGPS.descripcionGPS
            ? activo_seleccionado?.equipamientoGPS?.descripcionGPS
            : ''
          : '',
      _proveedorGPS_ref:
        activo_seleccionado && activo_seleccionado.equipamientoGPS
          ? typeof activo_seleccionado.equipamientoGPS._proveedorGPS_ref === 'object'
            ? activo_seleccionado?.equipamientoGPS?._proveedorGPS_ref._id
            : activo_seleccionado.equipamientoGPS._proveedorGPS_ref
          : '',
      fechaTerminoContratoGPS:
        activo_seleccionado && activo_seleccionado.equipamientoGPS
          ? activo_seleccionado?.equipamientoGPS?.fechaTerminoContratoGPS
            ? activo_seleccionado?.equipamientoGPS?.fechaTerminoContratoGPS
            : new Date()
          : new Date(),
      fechaInstalacion:
        activo_seleccionado && activo_seleccionado.equipamientoGPS
          ? activo_seleccionado?.equipamientoGPS?.fechaInstalacion
            ? activo_seleccionado?.equipamientoGPS?.fechaInstalacion
            : new Date()
          : new Date(),
      idGPS:
        activo_seleccionado && activo_seleccionado.equipamientoGPS
          ? activo_seleccionado?.equipamientoGPS?.idGPS
            ? activo_seleccionado?.equipamientoGPS?.idGPS
            : ''
          : '',
      estadoGPS:
        activo_seleccionado && activo_seleccionado.equipamientoGPS
          ? activo_seleccionado?.equipamientoGPS.estadoGPS
            ? activo_seleccionado?.equipamientoGPS?.estadoGPS
            : ''
          : '',
      contratoGPS: null,
      certificadoCoberturaGPS: null,
      numeroContratoGPS:
        activo_seleccionado && activo_seleccionado.equipamientoGPS
          ? activo_seleccionado?.equipamientoGPS?.numeroContratoGPS
            ? activo_seleccionado?.equipamientoGPS?.numeroContratoGPS
            : ''
          : '',
    };

    setProveedorSeleccionado(valores._proveedorGPS_ref);

    resetForm({ values: valores });
  };

  const dateChange = (name, e) => {
    formik.setFieldValue(name, e);
    formik.setFieldTouched(name, true, false);
  };

  const handleProveedorChange = (selectedOption) => {
    console.log('Proveedor seleccionado:', selectedOption);
    setFieldValue('_proveedorGPS_ref', selectedOption ? selectedOption.value : '');
    setProveedorSeleccionado(selectedOption.value); // Actualizar el proveedor seleccionado
  };
  const getButtonText = (action) => {
    let textoBoton = '';

    switch (action) {
      case CRUD_ACTIONS.ADD:
        textoBoton = 'Guardar Dispositivo GPS';

        break;
      case CRUD_ACTIONS.EDIT:
        textoBoton = 'Actualizar Dispositivo';

        break;
      case CRUD_ACTIONS.DELETE:
        textoBoton = 'Eliminar Dispositivo';

        break;

      default:
        break;
    }
    return textoBoton;
  };

  return (
    <Dialog
      open={is_open}
      onClose={handleClose}
      TransitionProps={{
        onEntered: handleOnEntered,
      }}
    >
      <DialogTitle>Registrar Dispositivo GPS</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={1}>
            <InfoVehiculo values={values} formik={formik} FullDetalle={false} TipoDeshabilitado habilitaPatente />

            <Grid item xs={6}>
              <SelectProveedores
                proveedores={proveedoresGPS ? proveedoresGPS.data : []}
                onChange={handleProveedorChange}
                value={proveedorSeleccionado}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="idGPS"
                label="ID dispositivo GPS"
                onChange={handleChange}
                value={values.idGPS}
                error={touched.idGPS && errors.idGPS && Boolean(errors.idGPS)}
                helperText={touched.idGPS && errors.idGPS ? errors.idGPS : ''}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MaterialReactSelect
                value={estadoGPS.find((d) => d.value === values.estadoGPS)}
                label="Estado GPS"
                isClearable
                margin="none"
                onChange={(newValue) => {
                  setFieldValue('estadoGPS', newValue ? newValue.value : '');
                }}
                placeholder="Seleccionar Estado"
                options={estadoGPS.map((value) => ({
                  value: value.value,
                  label: value.label,
                }))}
                noOptionsMessage={() => 'No ha seleccionado el estado'}
                error={touched.estadoGPS && errors.estadoGPS && Boolean(errors.estadoGPS)}
                helperText={touched.estadoGPS && errors.estadoGPS ? errors.estadoGPS : ''}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                name="fechaInstalacion"
                label="Fecha Instalación Dispositivo"
                value={values.fechaInstalacion}
                onChange={dateChange.bind(null, 'fechaInstalacion')}
                inputVariant="outlined"
                format="dd/MM/yyyy"
                cancelLabel="Cancelar"
                okLabel="Aceptar"
                autoOk
                fullWidth
                className={classes.input_required}
                error={touched.fechaInstalacion && errors.fechaInstalacion && Boolean(errors.fechaInstalacion)}
                helperText={touched.fechaInstalacion && errors.fechaInstalacion ? errors.fechaInstalacion : ''}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                name="fechaTerminoContratoGPS"
                label="Fecha Termino Contrato"
                value={values.fechaTerminoContratoGPS}
                onChange={dateChange.bind(null, 'fechaTerminoContratoGPS')}
                inputVariant="outlined"
                format="dd/MM/yyyy"
                cancelLabel="Cancelar"
                okLabel="Aceptar"
                autoOk
                fullWidth
                className={classes.input_required}
                error={
                  touched.fechaTerminoContratoGPS &&
                  errors.fechaTerminoContratoGPS &&
                  Boolean(errors.fechaTerminoContratoGPS)
                }
                helperText={
                  touched.fechaTerminoContratoGPS && errors.fechaTerminoContratoGPS
                    ? errors.fechaTerminoContratoGPS
                    : ''
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="numeroContratoGPS"
                label="Número de Contrato"
                onChange={handleChange}
                value={values.numeroContratoGPS}
                error={touched.numeroContratoGPS && errors.numeroContratoGPS && Boolean(errors.numeroContratoGPS)}
                helperText={touched.numeroContratoGPS && errors.numeroContratoGPS ? errors.numeroContratoGPS : ''}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" margin="none" fullWidth>
                <FileInput
                  inputName="file"
                  buttonColor="primary"
                  buttonVariant="outlined"
                  label="Archivo Contrato"
                  value={values.contratoGPS}
                  handleOnChange={(e) => setFieldValue('contratoGPS', e.target.files[0])}
                  textVariant="outlined"
                  accept="image/x-png,image/gif,image/jpeg,application/pdf"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" margin="none" fullWidth>
                <FileInput
                  inputName="file"
                  buttonColor="primary"
                  buttonVariant="outlined"
                  label="Certificado Cobertura"
                  value={values.certificadoCoberturaGPS}
                  handleOnChange={(e) => setFieldValue('certificadoCoberturaGPS', e.target.files[0])}
                  textVariant="outlined"
                  accept="image/x-png,image/gif,image/jpeg,application/pdf"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Descripción"
                name="descripcionGPS"
                value={values.descripcionGPS}
                onChange={handleChange}
                error={touched.descripcionGPS && errors.descripcionGPS && Boolean(errors.descripcionGPS)}
                helperText={touched.descripcionGPS && errors.descripcionGPS ? errors.descripcionGPS : ''}
                variant="outlined"
                multiline
                minRows={4}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button type="submit" variant="contained" color="primary">
            {getButtonText(action)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
