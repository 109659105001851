import axios from 'axios';
import { BACKEND } from '../../constants/urls';
/**
 * Obtener Todas las aseguradoras registradas
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getEquipamientos(filter = {}, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/equipamientoGPS`;
  if (selects)
    selects.forEach((select) => {
      url = url + '/' + select;
    });
  if (populates)
    populates.forEach((populate) => {
      url = url + '/' + populate;
    });
  return axios
    .get(url, {
      params: filter,
      cancelToken,
    })
    .then((res) => res.data);
}

/**
 * Obtener empresa Aseguradora dado su ID
 * @param {String}  id
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelación
 */
export function getEquipamientoVehicular(id, selects, populates, cancelToken = null) {
  let url = `${BACKEND}/equipamientoVehicular/${id}`;
  if (selects)
    selects.forEach((select) => {
      url = url + '/' + select;
    });
  if (populates)
    populates.forEach((populate) => {
      url = url + '/' + populate;
    });
  return axios
    .get(url, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data : null));
}

export function getEquipamientoPopulado(id, cancelToken = null) {
  const url = `${BACKEND}/equipamientoVehicular/obtener-populado/${id}`;

  return axios
    .get(url, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data : null));
}
export async function getCantidadGPSPorProveedor() {
  try {
    const url = `${BACKEND}/equipamientoGPS/cantidadGPSProveedor`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Guardar Nueva Sociedad
 * @param {*} data datos de Marca
 * @param {*} cancelToken token para cancelacion
 */
export function postEquipamientoVehicular(data, cancelToken = null) {
  return axios
    .post(`${BACKEND}/equipamientoGPS/`, data, {
      cancelToken,
    })
    .then((res) => res.data);
}
/**
 * Actualizar Sociedad
 * @param {String}  id
 * @param {Object} data datos del registro
 * @param {*} cancelToken token para cancelacion
 */
export function putEquipamientoVehicular(id, data, cancelToken = null) {
  return axios
    .put(`${BACKEND}/equipamientoGPS/${id}`, data, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}

/**
 * Eliminar Sociedad dado el ID
 * @param {String}  id
 * @param {*} cancelToken token para cancelacion
 */
export function deleteEquipamientoVehicular(id, cancelToken = null) {
  return axios
    .delete(`${BACKEND}/equipamientoGPS/${id}`, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}
