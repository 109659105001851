import React, { useContext, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { MainContext } from "../../../App";
import { ListadoActivosContext } from ".";
import { putBien } from "../../../services/request/requestBienes";
import { GenerarLog } from "../../../services/activoLog";

export default function DialogActivar(props) {
  const {
    activo_seleccionado,
    is_open,
    handle_open,
  } = props;

  const [Motivo, SetMotivo] = useState("");

  const { usuarioSesion } = useContext(MainContext);
  const { data, setData } = useContext(ListadoActivosContext);
  const notistack = useSnackbar();

  /**
   * Método encargado de activar un bien.
   */
  async function Activar() {
    try {
      notistack.enqueueSnackbar("Activando el bien...", {
        variant: "info",
        persist: true,
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        }
      });
      let datosActualizacion = {
        motivo_activacion: Motivo,
        is_activo_contable: true,
        is_no_activable: false,
        fecha_activacion: new Date(),
        estado_activacion: "Aprobado Registro Contable",
      }
      let registroLog = {
        _bien_ref: activo_seleccionado._id,
        titulo: "Activación de Contable",
        cuerpo: `El usuario ${usuarioSesion.nombre} realizó la activación del activo ${activo_seleccionado.codigo_activo}.`,
        tipo: "solicitud_activacion",
        icon: "check_circle_outline",
      }
      await Promise.all([
        putBien(activo_seleccionado._id, datosActualizacion),
        GenerarLog(registroLog),
      ]);
      notistack.closeSnackbar();

      let bienes = [...data];
      let bien = bienes.find(bien => bien._id === activo_seleccionado._id);
      bien.motivo_activacion = Motivo;
      bien.is_activo_contable = true;
      bien.fecha_activacion = new Date();
      bien.estado_activacion = "Aprobado Registro Contable";
      setData(bienes);

      notistack.enqueueSnackbar("Operanción exitosa: Bien activado", {
        variant: "success",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><Close /></IconButton>
      });
    }
    catch (e) {
      console.error(e);
      notistack.closeSnackbar();
      notistack.enqueueSnackbar("Error al intentar activar el bien. Intente de nuevo o contacte con el equipo de soporte.", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><Close /></IconButton>
      });
    } finally {
      handleClose();
    }
  }

  /**
   * Método encargado de cerrar el popup y limpiar los datos.
   */
  const handleClose = () => {
    handle_open(null, false);
    SetMotivo("");
  }

  return (
    <Dialog
      open={is_open}
      onClose={handleClose}
    >
      <DialogTitle>Activación de un Bien</DialogTitle>
      <DialogContent dividers>
        <Typography>Formulario para la activación del bien.</Typography>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              name="motivo"
              label="Comentario"
              fullWidth
              variant={"outlined"}
              margin={"normal"}
              value={Motivo}
              rows={2}
              multiline={true}
              InputLabelProps={{
                shrink: true
              }}
              onChange={(e) => SetMotivo(e.target.value)}
              helperText="Requerido para solicitar la activación"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClose}>No</Button>
        <Button variant="contained" color="primary" onClick={() => Activar(activo_seleccionado)} disabled={CheckButtonDisabled(Motivo)}>Sí</Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * Método encargado de verificar si el botón está habilitado o deshabilitado.
 * @param {*} motivo Motivo para activar el bien.
 * @returns FLAG que habilita o deshabilita el botón.
 */
function CheckButtonDisabled(motivo) {
  if (!motivo) {
    return true;
  }
  if (motivo.length < 3) {
    return true;
  }
  return false;
}