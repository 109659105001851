import React from 'react';
import { Grid, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import useSWR from 'swr';
import { getCantidadGPSPorProveedor } from '../../../services/request/requestEquipamientoVehicular';
import CustomMiniCards, { CustomMiniCardsWithDownload } from '../../../components/customMiniCards';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: 5,
  },
  listItemTextStyle: {
    margin: 0,
    fontWeight: 'bold',
  },
  listItemStyle: {
    padding: '6px 12px',
  },
}));

export default function ResumenGPSProveedor() {
  const classes = useStyles();
  function getData() {
    return getCantidadGPSPorProveedor();
  }
  const { data: datosResumenGPS } = useSWR('CantidadGPSProveedor', getData);

  console.log('Datos recuperados: ', datosResumenGPS);

  return (
    <>
      <Grid container spacing={1} alignContent="center" justifyContent="center">
        <Grid item xs={2}>
          <CustomMiniCards title="Resumen Total GPS">
            {datosResumenGPS !== undefined ? (
              <List>
                <ListItem className={classes.listItemStyle}>
                  <ListItemText
                    primary={`Total Flota de Vehiculos: ${datosResumenGPS.vehiculosTotales}`}
                    className={classes.listItemTextStyle}
                  />
                </ListItem>
                <ListItem className={classes.listItemStyle}>
                  <ListItemText
                    primary={`Total Vehiculos con GPS: ${datosResumenGPS.vehiculosConGPS}`}
                    className={classes.listItemTextStyle}
                  />
                </ListItem>
              </List>
            ) : (
              <p>No hay datos disponibles.</p>
            )}
          </CustomMiniCards>
        </Grid>
        {datosResumenGPS && datosResumenGPS.proveedores !== undefined ? (
          datosResumenGPS.proveedores.map((proveedor, index) => (
            <Grid item xs={2}>
              <CustomMiniCardsWithDownload
                title={proveedor.nombre}
                downloadLink={proveedor.contratoProximoAVencer && proveedor.contratoProximoAVencer.url}
              >
                {`Cant. GPS Instalados: ${proveedor.cantidadDispositivos} \n`}
                <br />
                {proveedor.fechaTerminoContratoProximoAVencer &&
                  `Contrato prox. a Vencer: ${new Date(proveedor.fechaTerminoContratoProximoAVencer)
                    .toLocaleDateString('es-ES')
                    .replace(/\//g, '/')}`}
              </CustomMiniCardsWithDownload>
            </Grid>
          ))
        ) : (
          <p>No hay datos disponibles.</p>
        )}
      </Grid>
    </>
  );
}
