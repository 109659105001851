import React, { Fragment, useCallback, useEffect, useState } from 'react';
import MaterialTable from "../../components/materialTable"; 
import SubTabla from "@material-table/core";
import { Container, makeStyles, Paper, Typography } from '@material-ui/core';
import useSWR from 'swr';
import { useHistory } from "react-router";
import { getLicenciasSoftware } from '../../services/request/requestLicenciasSoftware';
import { DETALLE_LICENCIA_SOFTWARE } from "../../constants/routes";

//Obtenemos los datos para hacer el reporte
function getData() {
    return getLicenciasSoftware({}, 
      ["_id", "cantidad", "cantidad_utilizada", "descripcion", "nombre", "version", "codigo_compra", "tipo", "fecha_vencimiento"],
      ["_marca_ref.marca", "_version_ref.version", "_software_ref.software"]);    
    }
  
//Creacion de estilos para la tabla 
const useStyles = makeStyles(theme => ({
    paperTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
    },
    paperFiltros: {
    padding: theme.spacing(2, 0, 2, 0),
    margin: theme.spacing(2, 0, 2, 0)
    },    
    title: {
    padding: theme.spacing(3, 0, 2, 0),
    textAlign: "center"
    },
    center: {
    textAlign: "center"
    }
}));

export default function TablaResumenDisponibilidadLicencias() {

    const { data: response, mutate } = useSWR("get:licencias", () => getData(), { initialData: null });
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const history = useHistory();

    const verifyResponse = useCallback(() => {
        setIsLoading(true);
        setData([]);
        if (response) {
            if (response.status) {
            setIsLoading(false);
            
            //Se agregan campos nuevos como la fecha de vencimiento y la cantidad disponible por Licencia
            const datosAgregados = response.data.map(dato => {
                return {
                ...dato,                
                cantidad_disponible: dato.cantidad_utilizada ? (dato.cantidad - dato.cantidad_utilizada) : dato.cantidad,
                marca: dato._marca_ref ? dato._marca_ref.marca : "",
                agrupacion: dato._software_ref ? dato._software_ref.software : "",
                version_software: dato._version_ref ? dato._version_ref.version : ""  
                }
            });
            //Se obtiene el resumen agrupado por software
            const versionesagrupadas = datosAgregados.reduce((acumulador, itemActual) => {
                const versionYaExiste = acumulador.find(elemento => elemento.version_software === itemActual.version_software);
                if (versionYaExiste) {
                    return acumulador.map((elemento) => {
                    if (elemento.version_software === itemActual.version_software) {
                        return {
                        ...elemento,
                        fecha_vencimiento_view: elemento.fecha_vencimiento,
                        cantidad: elemento.cantidad + itemActual.cantidad,
                        cantidad_utilizada: elemento.cantidad_utilizada + itemActual.cantidad_utilizada,                        
                        cantidad_disponible: elemento.cantidad_disponible + itemActual.cantidad_disponible
                        }
                    }                
                    return elemento;
                    });
                }                
                return [...acumulador, itemActual];
                }, []);            

            //Se agrupan las licencias por Marca sumandose los valores de cantidades respectivos
            const marcasagrupadas = datosAgregados.reduce((acumulador, itemActual) => {
                const marcaYaExiste = acumulador.find(elemento => elemento.marca === itemActual.marca);
                if (marcaYaExiste) {
                    return acumulador.map((elemento) => {
                    if (elemento.marca === itemActual.marca) {
                        return {
                        ...elemento,
                        cantidad: elemento.cantidad + itemActual.cantidad,
                        cantidad_utilizada: elemento.cantidad_utilizada + itemActual.cantidad_utilizada,                        
                        cantidad_disponible: elemento.cantidad_disponible + itemActual.cantidad_disponible                        
                        }
                    }                
                    return {
                        ...elemento,
                        detalleAgrupado: versionesagrupadas.filter(version => version.marca === elemento.marca)
                        }
                    });
                }                
                return [...acumulador, itemActual];
                }, []);

            setData(marcasagrupadas);
            } else {
            setIsLoading(false);
            }
        }
    }, [response]);

    useEffect(() => verifyResponse(), [verifyResponse]);

    const classes = useStyles();

    const actions = [        
        {
          icon: "menu_open",
          tooltip: "Ver Detalles",
          //hidden: permisos && permisos[EDITAR_LICENCIA_SOFTWARE] ? false: true,
          onClick: (evt, rowData) => history.push(DETALLE_LICENCIA_SOFTWARE.replace(':id', rowData._id))
        }
      ];

    const columns = [
        {
          title: "Marca",
          field: "marca"
        },
        {
          title: "Cantidad total",
          field: "cantidad",
        },
        {
          title: "Cantidad disponible",
          field: "cantidad_disponible",
        }
      ];

  return (
    <Fragment>
        <Paper className={classes.paperTitle}>
            <Container>
                <Typography variant="h4" className={classes.title}>Resumen de Disponibilidad Licencias de Software</Typography>
            </Container>
        </Paper>
        <MaterialTable
        title="" 
        is_loading={isLoading}                            
        lockheaders={true}
        columns={columns}
        data={Array.isArray(data) ? data : []}
        searching= {false}
        columnsButton= {false}
        grouping= {false}
        filtering= {false}
        showTitle= {false} 
        exportButton={false}        
        options={{
          padding: "default",          
          showTitle: false,                   
          headerStyle: {
            fontWeight: 'bold',
            color: "#731f1f",
            textAlign: "left",
            fontSize: "15px",
          },
        }}   
        detailPanel={rowData => {
            return (            
            <SubTabla           
                title= {"Detalle Licencias disponibles de: " + rowData.marca}
                options={{ 
                    padding: "dense",                   
                    paging: false,
                    search: false,
                    headerStyle: {
                      fontWeight: 'bold',                                            
                    },
                    rowStyle: {
                      height: "15px"
                  }                   
                  }}
                columns={[
                    {
                        title: "Versión",
                        field: "version_software",
                    },                    
                    {
                        title: "Código de Compra",
                        field: "codigo_compra"
                    },
                    {
                        title: "Tipo de Licencia",
                        field: "tipo",
                    },
                    {
                        title: "Fecha de Vencimiento",
                        field: "fecha_vencimiento_view",
                        type: "date"
                    },
                    {
                        title: "Cantidad total",
                        field: "cantidad",                
                    },
                    {
                    title: "Cantidad disponible",
                    field: "cantidad_disponible",
                    }
                ]}
                data={rowData.detalleAgrupado}  
                actions={actions}              
            />
            );
        }}
        />
    </Fragment>    
  );
}
