import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import MaterialReactSelect from '../../../components/materialReactSelect';
import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { TablaVersionesSoftwareContext } from './tablaVersionesSoftware';
import { getMarcasSoftware } from '../../../services/request/requestMarcasSoftware';
import { getGruposSoftware, obtenerAgrupacionesporMarca } from '../../../services/request/requestGrupoSoftware';
import * as yup from 'yup';

const validationSchema = yup.object({
  version: yup.string().required(),
});

export default function DialogoCRUDVersionSoftware() {
  const { openDialogoCRUD, setOpenDialogoCRUD, tituloDialogo, handleAceptar, disableEditing, initialValues } =
    useContext(TablaVersionesSoftwareContext);

  const handleClose = () => setOpenDialogoCRUD(false);

  const formik = useFormik({
    initialValues,
    onSubmit: handleAceptar,
    validationSchema,
  });

  const { values, errors, touched, handleSubmit, handleChange, resetForm } = formik;
  const [marcas, setMarcas] = useState([]);
  const [softwares, setSoftwares] = useState([]);

  const getSoftwaresMarcas = async (marcaID) => {
    obtenerAgrupacionesporMarca(marcaID).then((response) => {
      if (response.status === true) {
        setSoftwares(response.data);
      } else {
        setSoftwares([]);
      }
    });
  };

  function handleOpenDialog(initialValues) {
    resetForm({ values: initialValues });
    getMarcasSoftware({}).then((response) => setMarcas(response.data));
    getGruposSoftware({}).then((response) => setSoftwares(response.data));
  }
  return (
    <Dialog open={openDialogoCRUD} onEntered={() => handleOpenDialog(initialValues)} maxWidth="md" fullWidth>
      <DialogTitle>{tituloDialogo}</DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs>
              <MaterialReactSelect
                value={values.marca}
                label="Marca Software"
                isClearable
                onChange={(newValue, actionMeta) => {
                  if (newValue) {
                    formik.setFieldValue('marca', newValue);
                    getSoftwaresMarcas(newValue.value);
                  } else formik.setFieldValue('marca', '');
                  formik.setFieldValue('contrato', '');
                }}
                placeholder="Seleccione la Marca"
                options={
                  marcas
                    ? marcas.map((value, index) => ({
                        value: value._id,
                        label: value.marca,
                      }))
                    : []
                }
                noOptionsMessage={() => 'No ha entrado la Marca del Software'}
                error={touched.marca && errors.marca && Boolean(errors.marca)}
                helperText={touched.marca && errors.marca ? errors.marca : ''}
              />
            </Grid>
            <Grid item xs>
              <MaterialReactSelect
                value={values.software}
                label="Agrupación Software"
                isClearable
                onChange={(newValue, actionMeta) =>
                  newValue ? formik.setFieldValue('software', newValue) : formik.setFieldValue('software', '')
                }
                placeholder="Seleccione la Marca"
                options={
                  softwares
                    ? softwares.map((value, index) => ({
                        value: value._id,
                        label: value.software,
                      }))
                    : []
                }
                noOptionsMessage={() => 'No ha entrado la Marca del Software'}
                error={touched.software && errors.software && Boolean(errors.software)}
                helperText={touched.software && errors.software ? errors.software : ''}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                name="version"
                label="Versión de Software"
                value={values.version}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                disabled={disableEditing}
                error={errors.version && touched.version ? true : false}
                helperText={errors.version && touched.version ? errors.version : ''}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                name="descripcion"
                label="Descripción"
                value={values.descripcion}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                disabled={disableEditing}
                error={errors.descripcion && touched.descripcion ? true : false}
                helperText={errors.descripcion && touched.descripcion ? errors.descripcion : ''}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
