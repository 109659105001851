import React, { useEffect, useState, useContext } from "react";
import Page from "./page";
import { ObtenerBienesPorUsuario } from "../../../../services/request/requestBienes";
import { ObtenerUsuario } from "../../../../services/request/requestsPersonas";
import { ObtenerGerencia } from "../../../../services/request/requestPortafolio";
import { useParams } from "react-router";
import { MainContext } from "../../../../App";

function Index(props) {
	const [UsuarioAsignado, SetUsuarioAsignado] = useState();
	const [Bienes, SetBienes] = useState();
	const [Bien, SetBien] = useState();
	const [IsOpen1, SetIsOpen1] = useState(false);
	const [IsOpen2, SetIsOpen2] = useState(false);
	const { id: usuarioID } = useParams();
	const { usuarioSesion } = useContext(MainContext);

	useEffect(() => {
		if (usuarioID) {
			let usuario = null;
			ObtenerUsuario(usuarioID)
				.then(response => usuario = response)
				.then(() => ObtenerGerencia(usuario.gerencia_ref))
				.then(gerencia => {
					usuario["gerencia"] = `[${gerencia.sigla}] ${gerencia.nombre}`;
					let contrato = usuario.contratos_ref.length > 0 ? `[${usuario.contratos_ref[0].codigo}] ${usuario.contratos_ref[0].nombre}` : "Sin Contrato"
					usuario["contrato"] = contrato;
					SetUsuarioAsignado(usuario);
				})
				.catch(error => {
					console.error(error);
					SetUsuarioAsignado(null);
				});

			ObtenerBienesPorUsuario(usuarioID)
				.then(response => SetBienes(response.data))
				.catch(error => {
					console.error(error);
					SetBienes([]);
				});
		}
	}, [usuarioID, IsOpen1, IsOpen2]);

	const handlePaso1 = () => {
		SetIsOpen1(true);
		SetIsOpen2(false);
	}

	const handlePaso2 = (bien) => {
		SetIsOpen1(false);
		SetIsOpen2(true);
		SetBien(bien);
	}

	const handleClose = () => {
		SetIsOpen1(false);
		SetIsOpen2(false);
	}

	return (
		<Page
			usuario_asignante={usuarioSesion}
			usuario_asignado={UsuarioAsignado}
			bienes={Bienes}
			bien={Bien}
			is_open_1={IsOpen1}
			is_open_2={IsOpen2}
			handle_paso_1={handlePaso1}
			handle_paso_2={handlePaso2}
			handle_close={handleClose}
		/>
	);
}

export default Index;