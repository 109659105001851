import axios from 'axios';
import { BACKEND } from '../../constants/urls';
/**
 * Obtener notificaciones Usuario
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getNotificaciones(filter = {}, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/notificaciones`;
  if (selects)
    selects.forEach((select) => {
      url = url + '/' + select;
    });
  if (populates)
    populates.forEach((populate) => {
      url = url + '/' + populate;
    });
  return axios
    .get(url, {
      params: filter,
      cancelToken,
    })
    .then((res) => res.data);
}
/**
 * Guardar Notificacion
 * @param {*} data datos de Notificacion
 * @param {*} cancelToken token para cancelacion
 */
export function postNotificacion(data, cancelToken = null) {
  return axios
    .post(`${BACKEND}/notificaciones/`, data, {
      cancelToken,
    })
    .then((res) => res.data);
}
/**
 * Actualizar notificacion
 * @param {String}  id
 * @param {Object} data datos del registro
 * @param {*} cancelToken token para cancelacion
 */
export function putNotificacion(id, data, cancelToken = null) {
  return axios
    .put(`${BACKEND}/notificaciones/${id}`, data, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}

/**
 * Eliminar notificacion
 * @param {String}  id
 * @param {*} cancelToken token para cancelacion
 */
export function deleteNotificacion(id, cancelToken = null) {
  return axios
    .delete(`${BACKEND}/notificaciones/${id}`, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}
