import { Button, Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import InformeSoftwareContext from './context';
import SelectorGerencias from './selectorGerencias';
import SelectorActivos from './selectorActivos';
import SelectorSoftwares from './selectorSoftwares';
import SelectorContratos from './selectorContratos';
import SelectorUsuarios from './selectorUsuarios';
import GraficosInformeSoftware from './graficosInformeSoftware';

const useStyles = makeStyles(theme => ({
  paperTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  paperFiltros: {
    padding: theme.spacing(2, 0, 2, 0),
    margin: theme.spacing(2, 0, 2, 0)
  },
  buttonsFiltros: {
    margin: theme.spacing(0, 1, 0, 1)
  },
  title: {
    padding: theme.spacing(3, 0, 2, 0),
    textAlign: "center"
  },
  center: {
    textAlign: "center"
  },
  selectGerencia: {
    // backgroundColor: theme.palette.primary.contrastText,
    margin: theme.spacing(1),
    border: "1px solid white"
  }
}));

export default function InformeSoftware() {

  const [filtros, setFiltros] = useState({
    gerencias: "",
    activos: "",
    softwares: "",
    contratos: "",
    usuarios: ""
  });

  function aplicarFiltros() {

  }

  const [openSelectGerencia, setOpenSelectGerencia] = useState(false);
  const [openSelectActivo, setOpenSelectActivo] = useState(false);
  const [openSelectSoftware, setOpenSelectSoftware] = useState(false);
  const [openSelectUsuario, setOpenSelectUsuario] = useState(false);
  const [openSelectContrato, setOpenSelectContrato] = useState(false);

  const classes = useStyles();
  return (
    <React.Fragment>
      <InformeSoftwareContext.Provider value={{
        filtros, setFiltros,
        aplicarFiltros,
        openSelectGerencia, setOpenSelectGerencia,
        openSelectActivo, setOpenSelectActivo,
        openSelectSoftware, setOpenSelectSoftware,
        openSelectUsuario, setOpenSelectUsuario,
        openSelectContrato, setOpenSelectContrato
      }}>
        <Paper className={classes.paperTitle}>
          <Container>
            <Typography variant="h4" className={classes.title}> Informe Software</Typography>
          </Container>
        </Paper>
        <Paper className={classes.paperFiltros}>
          <Grid container direction="row" alignContent="center" justify="center">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.center}>
              <Button variant="outlined" className={classes.buttonsFiltros} color="primary" onClick={() => setOpenSelectGerencia(true)}>
                Gerencia
              </Button>
              <Button className={classes.buttonsFiltros} variant="outlined" color="primary" onClick={() => setOpenSelectActivo(true)}>
                Activo
              </Button>
              <Button className={classes.buttonsFiltros} variant="outlined" color="primary" onClick={() => setOpenSelectSoftware(true)}>
                Software
              </Button>
              <Button className={classes.buttonsFiltros} variant="outlined" color="primary" onClick={() => setOpenSelectContrato(true)}>
                Contrato
              </Button>
              <Button className={classes.buttonsFiltros} variant="outlined" color="primary" onClick={() => setOpenSelectUsuario(true)}>
                Usuario
              </Button>
            </Grid>
          </Grid>
        </Paper>

        <GraficosInformeSoftware />

        <SelectorGerencias />
        <SelectorActivos />
        <SelectorSoftwares />
        <SelectorContratos />
        <SelectorUsuarios />
      </InformeSoftwareContext.Provider>
    </React.Fragment >
  );
}