import React, { useState, createContext } from "react";
import { useHistory } from "react-router";
import Page from './page';
import * as ROUTES from '../../../constants/routes';

export const RegistroBienesContext = createContext({
	clase: null, setClase: () => { },
	formularioBasico: null, setFormularioBasico: () => { },
	formularioVehiculos: null, setFormularioVehiculos: () => { },
	formularioComputacionales: null, setFormularioComputacionales: () => { },
	formularioActivacion: null, setFormularioActivacion: () => { },
	selectedTab: 0, setSelectedTab: () => { },
	showTabVehiculos: false, setShowTabVehiculos: () => { },
	showTabComputacionales: false, setShowTabComputacionales: () => { },
	tipoRecurso: null, setTipoRecurso: () => { },
	showTabLabotorioTopografia: false, setShowTabLabotorioTopografia: () => { },
	modoMultiplesActivos: false, setModoMultiplesActivos: () => { }
});

export function useRegistroBienes() {

	const [formularioBasico, setFormularioBasico] = useState(null);
	const [formularioActivacion, setFormularioActivacion] = useState(null);
	const [formularioComputacionales, setFormularioComputacionales] = useState(null);
	const [formularioVehiculos, setFormularioVehiculos] = useState(null);
	const [selectedTab, setSelectedTab] = useState(0);
	const [showTabVehiculos, setShowTabVehiculos] = useState(false);
	const [showTabComputacionales, setShowTabComputacionales] = useState(false);
	const [showTabLabotorioTopografia, setShowTabLabotorioTopografia] = useState(false);
	const [clase, setClase] = useState(null);
	const [modoMultiplesActivos, setModoMultiplesActivos] = useState(false);

	return {
		clase, setClase,
		formularioBasico, setFormularioBasico,
		formularioActivacion, setFormularioActivacion,
		formularioComputacionales, setFormularioComputacionales,
		formularioVehiculos, setFormularioVehiculos,
		selectedTab, setSelectedTab,
		showTabVehiculos, setShowTabVehiculos,
		showTabComputacionales, setShowTabComputacionales,
		showTabLabotorioTopografia, setShowTabLabotorioTopografia,
		modoMultiplesActivos, setModoMultiplesActivos
	};
}


export default function RegistroBienes() {

	const history = useHistory();

	const handleVolverActivos = () => {
		history.push(ROUTES.LISTADO_BIENES);
	}

	const registroBienesHook = useRegistroBienes();

	return (
		<RegistroBienesContext.Provider value={registroBienesHook}>
			<Page
				handleVolverActivos={handleVolverActivos}
			/>
		</RegistroBienesContext.Provider>
	);
}