import axios from "axios";
import { BACKEND } from "../../constants/urls";
/**
 * Obtener Todas las ubicaciones registrada
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getUbicaciones(
	filter = {},
	selects = [],
	populates = [],
	cancelToken = null
) {
	let url = `${BACKEND}/ubicaciones`;
	if (selects)
		selects.forEach((select) => {
			url = url + "/" + select;
		});
	if (populates)
		populates.forEach((populate) => {
			url = url + "/" + populate;
		});
	return axios
		.get(url, {
			params: filter,
			cancelToken: cancelToken,
		})
		.then((res) => res.data);
}

/**
 * Obtener Ubicación dado su ID
 * @param {String}  id
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelación
 */
export function getUbicacion(id, selects, populates, cancelToken = null) {
	let url = `${BACKEND}/ubicaciones/${id}`;
	if (selects)
		selects.forEach((select) => {
			url = url + "/" + select;
		});
	if (populates)
		populates.forEach((populate) => {
			url = url + "/" + populate;
		});
	return axios
		.get(url, {
			cancelToken: cancelToken,
		})
		.then((res) => (res.data ? res.data : null));
}

/**
 * Guardar Nueva Ubicación
 * @param {*} data datos de Marca
 * @param {*} cancelToken token para cancelacion
 */
export function postUbicacion(data, cancelToken = null) {
	return axios
		.post(`${BACKEND}/ubicaciones/`, data, {
			cancelToken: cancelToken,
		})
		.then((res) => res.data);
}
/**
 * Actualizar Ubicación
 * @param {String}  id
 * @param {Object} data datos del registro
 * @param {*} cancelToken token para cancelacion
 */
export function putUbicacion(id, data, cancelToken = null) {
	return axios
		.put(`${BACKEND}/ubicaciones/${id}`, data, {
			cancelToken: cancelToken,
		})
		.then((res) => (res.data ? res.data.data : null));
}

/**
 * Eliminar Ubicación dado el ID
 * @param {String}  id
 * @param {*} cancelToken token para cancelacion
 */
export function deleteUbicacion(id, cancelToken = null) {
	return axios
		.delete(`${BACKEND}/ubicaciones/${id}`, {
			cancelToken: cancelToken,
		})
		.then((res) => (res.data ? res.data.data : null));
}
/**********************************DETALLE UBICACIONES *************************************/
/**
 * Obtener Todas las ubicaciones registrada
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getDetalleUbicaciones(
	filter = {},
	selects = [],
	populates = [],
	cancelToken = null
) {
	let url = `${BACKEND}/detalles-ubicaciones`;
	if (selects)
		selects.forEach((select) => {
			url = url + "/" + select;
		});
	if (populates)
		populates.forEach((populate) => {
			url = url + "/" + populate;
		});
	return axios
		.get(url, {
			params: filter,
			cancelToken: cancelToken,
		})
		.then((res) => res.data);
}

/**
 * Obtener Ubicación dado su ID
 * @param {String}  id
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelación
 */
export function getDetalleUbicacion(id, selects, populates, cancelToken = null) {
	let url = `${BACKEND}/detalles-ubicaciones/${id}`;
	if (selects)
		selects.forEach((select) => {
			url = url + "/" + select;
		});
	if (populates)
		populates.forEach((populate) => {
			url = url + "/" + populate;
		});
	return axios
		.get(url, {
			cancelToken: cancelToken,
		})
		.then((res) => (res.data ? res.data : null));
}

/**
 * Guardar Nueva Ubicación
 * @param {*} data datos de Marca
 * @param {*} cancelToken token para cancelacion
 */
export function postDetalleUbicacion(data, cancelToken = null) {
	return axios
		.post(`${BACKEND}/detalles-ubicaciones/`, data, {
			cancelToken: cancelToken,
		})
		.then((res) => res.data);
}
/**
 * Actualizar Ubicación
 * @param {String}  id
 * @param {Object} data datos del registro
 * @param {*} cancelToken token para cancelacion
 */
export function putDetalleUbicacion(id, data, cancelToken = null) {
	return axios
		.put(`${BACKEND}/detalles-ubicaciones/${id}`, data, {
			cancelToken: cancelToken,
		})
		.then((res) => (res.data ? res.data.data : null));
}

/**
 * Eliminar Ubicación dado el ID
 * @param {String}  id
 * @param {*} cancelToken token para cancelacion
 */
export function deleteDetalleUbicacion(id, cancelToken = null) {
	return axios
		.delete(`${BACKEND}/detalles-ubicaciones/${id}`, {
			cancelToken: cancelToken,
		})
		.then((res) => (res.data ? res.data.data : null));
}

export async function ObtenerDetalleUbicacionesPorIDListado(filtro = "", listado) {
	try {
		let listadoIDs = listado.map((c) => `_id_in=${c}`);
		let claseQuery = listadoIDs.join("&");
		let url =
			`${BACKEND}/detalles-ubicaciones/?${claseQuery}` +
			(filtro ? `&${filtro}` : "");
		console.log(url);
		let response = await axios.get(url);
		return response.data ? response.data.data : [];
	} catch (error) {
		console.error(error);
		throw error;
	}
}
