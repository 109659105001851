import React, { createContext, useState } from 'react';
import MaterialTable from '../../../components/materialTable';
import useSWR from 'swr';
import { getModelos, postModelo, putModelo, deleteModelo } from '../../../services/request/requestModelos';
import { Fragment } from 'react';
import DialogoCRUDModelo from './dialogoCRUDModelo';
import { useHistory, useParams } from 'react-router';
import { AJUSTES_CONFIGURACION } from '../../../constants/routes';
import formatNumber from 'format-number';
const numberFormater = formatNumber({ integerSeparator: '.', decimal: ',' });
export const TablaModelosContext = createContext({
  openDialogoCRUD: false,
  setOpenDialogoCRUD: () => {},
  tituloDialogo: '',
  setTituloDialogo: () => {},
  initialValues: '',
  setInitialValues: () => {},
  handleAceptar: () => {},
  disableEditing: false,
  setDisableEditing: () => {},
  idMarca: '',
});

export default function TablaModelos() {
  const { idMarca } = useParams();
  const { data: modelos, mutate } = useSWR('get:modelos', (key) =>
    idMarca ? getModelos({ _marca_ref_eq: idMarca }) : { data: [] },
  );
  const [openDialogoCRUD, setOpenDialogoCRUD] = useState(false);
  const [tituloDialogo, setTituloDialogo] = useState('');
  const [initialValues, setInitialValues] = useState({
    nombre: '',
    valor_patente: 0,
    valor_seguro: 0,
    valor_inscripcion: 0,
  });
  const [disableEditing, setDisableEditing] = useState(false);
  const history = useHistory();

  const handleOpenAgregar = (titulo) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      nombre: '',
      valor_promedio_venta: 0,
      valor_libro: 0,
      valor_patente: 0,
      valor_seguro: 0,
      valor_inscripcion: 0,
    });
    setDisableEditing(false);
  };

  const handleOpenModificar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      nombre: row.nombre,
      id: row._id,
      valor_promedio_venta: row.valor_promedio_venta,
      valor_libro: row.valor_libro,
      valor_patente: row.valor_patente,
      valor_seguro: row.valor_seguro,
      valor_permiso_circulacion: row.valor_permiso_circulacion,
      valor_inscripcion: row.valor_inscripcion,
    });
    setDisableEditing(false);
  };

  const handleOpenQuitar = (titulo, row) => {
    setOpenDialogoCRUD(true);
    setTituloDialogo(titulo);
    setInitialValues({
      nombre: row.nombre,
      id: row._id,
      valor_promedio_venta: row.valor_promedio_venta,
      valor_libro: row.valor_libro,
      valor_patente: row.valor_patente,
      valor_seguro: row.valor_seguro,
      valor_permiso_circulacion: row.valor_permiso_circulacion,
      valor_inscripcion: row.valor_inscripcion,
    });
    setDisableEditing(true);
  };

  const handleAceptar = async (values) => {
    if (tituloDialogo) {
      if (tituloDialogo.includes('Nuev')) await postModelo({ ...values, _marca_ref: idMarca });
      else if (tituloDialogo.includes('Modificar'))
        await putModelo(values.id, {
          nombre: values.nombre,
          valor_promedio_venta: values.valor_promedio_venta,
          valor_libro: values.valor_libro,
          valor_patente: values.valor_patente,
          valor_permiso_circulacion: values.valor_permiso_circulacion,
          valor_seguro: values.valor_seguro,
          valor_inscripcion: values.valor_inscripcion,
        });
      else if (tituloDialogo.includes('Eliminar')) await deleteModelo(values.id);
    }
    mutate(getModelos({ _marca_ref_eq: idMarca }));
    setOpenDialogoCRUD(false);
  };

  const handleVolver = () => {
    history.push(AJUSTES_CONFIGURACION);
  };

  const columnsModelos = [
    {
      title: 'Nombre',
      field: 'nombre',
      align: 'center',
      defaultSort: 'asc',
    },
    {
      title: 'V. Promedio Venta',
      field: 'valor_promedio_venta',
      align: 'center',
      render: (a) => numberFormater(a.valor_promedio_venta ? a.valor_promedio_venta : 0),
    },
    {
      title: 'V. Libro',
      field: 'valor_libro',
      align: 'center',
      render: (a) => numberFormater(a.valor_libro ? a.valor_libro : 0),
    },
    {
      title: 'V. Seguro',
      field: 'valor_seguro',
      align: 'center',
      render: (a) => numberFormater(a.valor_seguro ? a.valor_seguro : 0),
    },
    {
      title: 'V. Permiso Circulación',
      field: 'valor_permiso_circulacion',
      align: 'center',
      render: (a) => numberFormater(a.valor_permiso_circulacion ? a.valor_permiso_circulacion : 0),
    },
    {
      title: 'V. Inscripción',
      field: 'valor_inscripcion',
      align: 'center',
      render: (a) => numberFormater(a.valor_inscripcion ? a.valor_inscripcion : 0),
    },
  ];

  return (
    <Fragment>
      <TablaModelosContext.Provider
        value={{
          openDialogoCRUD,
          setOpenDialogoCRUD,
          tituloDialogo,
          setTituloDialogo,
          initialValues,
          setInitialValues,
          handleAceptar,
          disableEditing,
          setDisableEditing,
          idMarca,
        }}
      >
        <MaterialTable
          actions={[
            {
              tooltip: 'Agregar',
              icon: 'add',
              onClick: (event, row) => handleOpenAgregar('Nuevo Modelo'),
              isFreeAction: true,
            },
            {
              tooltip: 'Editar',
              icon: 'edit',
              onClick: (event, row) => handleOpenModificar('Modificar Modelo', row),
            },
            {
              tooltip: 'Eliminar',
              icon: 'delete',
              onClick: (event, row) => handleOpenQuitar('Eliminar Modelo', row),
            },
            {
              tooltip: 'Volver',
              icon: 'arrow_back',
              onClick: (event, row) => handleVolver(),
              isFreeAction: true,
            },
          ]}
          title="Modelos"
          is_loading={!modelos}
          data={modelos && modelos.data}
          columns={columnsModelos}
          filtering={false}
          grouping={false}
          searching={false}
          empty_rows={true}
          page_size={20}
        />
        <DialogoCRUDModelo />
      </TablaModelosContext.Provider>
    </Fragment>
  );
}
