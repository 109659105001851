import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { ObtenerActivoLogs } from '../../../services/request/requestActivoLog';
import { Typography, Paper, makeStyles, Icon, Box, CircularProgress, Dialog, IconButton } from '@material-ui/core';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import { Image as ImageIcon } from '@material-ui/icons';

function LineaTiempo(props) {
  const { id } = props;

  const [Registros, SetRegistros] = useState();
  const [IsOpen, SetIsOpen] = useState();

  const classes = useStyles();

  useEffect(() => {
    if (id) {
      ObtenerActivoLogs(id)
        .then((registros) => {
          const timeline = Array.from(registros.data).sort(
            (a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion),
          );
          SetRegistros(timeline);
        })
        .catch((error) => {
          console.error(error);
          SetRegistros([]);
        });
    }
  }, [id]);

  return (
    <Fragment>
      {/* LÍNEA DE TIEMPO DEL ACTIVO */}
      <Timeline align="alternate">
        {!Registros && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress style={{ margin: 100 }} />
          </Box>
        )}
        {Registros &&
          Registros.map((registro, index) => (
            <TimelineItem key={`timeline_item_${index}`}>
              <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary" style={{ marginTop: 10 }}>
                  {moment(registro.fecha_creacion).format('DD/MM/YYYY HH:mm')}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Icon>{registro.icon}</Icon>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1">
                    {registro.titulo}
                  </Typography>
                  <Typography>
                    {registro.cuerpo}
                    {registro.link && (
                      <IconButton
                        onClick={() => {
                          SetIsOpen(registro.link);
                        }}
                        size="small"
                      >
                        <ImageIcon />
                      </IconButton>
                    )}
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>

      {/* POPUP PARA VER IMAGEN */}
      <Dialog onClose={() => SetIsOpen()} open={Boolean(IsOpen)}>
        <img src={IsOpen} alt="Imagen" style={{ margin: 20 }} />
      </Dialog>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
}));

export default LineaTiempo;
