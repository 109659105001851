import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useCallback, useContext, useEffect } from 'react';
import { TablaDocumentosRequeridosClaseContext } from './tablaDocumentosRequeridosClase';
import * as yup from 'yup';
import MaterialCreatable from '../../../components/materialCreatable';
import { getSeleccionables } from '../../../services/request/requestSeleccionables';
import useSWR from 'swr';

const validationSchema = yup.object({
  nombre: yup.string().required(),
});

export default function DialogoCRUDDocumentoRequerido() {
  const { openDialogoCRUD, setOpenDialogoCRUD, tituloDialogo, handleAceptar, disableEditing, initialValues } =
    useContext(TablaDocumentosRequeridosClaseContext);

  const handleClose = () => setOpenDialogoCRUD(false);

  const formik = useFormik({
    initialValues,
    onSubmit: handleAceptar,
    validationSchema,
  });

  const { values, errors, touched, handleSubmit, handleChange, resetForm, setFieldValue } = formik;

  const { data: tiposDocumentos } = useSWR(
    'tipos_documentos',
    (key) => getSeleccionables({ context_eq: 'tipo_documento_adjunto' }),
    { revalidateOnFocus: false },
  );

  const resetFormik = useCallback(() => {
    resetForm({ values: initialValues });
  }, [initialValues, resetForm]);

  useEffect(() => {
    resetFormik();
  }, [resetFormik]);

  return (
    <Dialog open={openDialogoCRUD} maxWidth="md" fullWidth>
      <DialogTitle>{tituloDialogo}</DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            {disableEditing && (
              <Grid item xs>
                <TextField
                  name="nombre"
                  label="Nombre"
                  value={values.nombre}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled={true}
                />
              </Grid>
            )}
            {!disableEditing && (
              <Grid item xs>
                <MaterialCreatable
                  value={values.tipo_archivo_obj}
                  label="Marca"
                  isClearable
                  onChange={(newValue, actionMeta) => {
                    console.log(newValue);
                    if (newValue) {
                      setFieldValue('tipo_archivo_obj', newValue);
                      setFieldValue('nombre', newValue.label);
                    } else {
                      setFieldValue('tipo_archivo_obj', '');
                      setFieldValue('nombre', '');
                    }
                  }}
                  placeholder="Seleccionar Tipo de Archivo"
                  options={
                    tiposDocumentos &&
                    tiposDocumentos.map((value, index) => ({
                      value,
                      label: value.label,
                    }))
                  }
                  noOptionsMessage={() => 'No se ha entrado el tipo de archivo'}
                  error={touched.nombre && errors.nombre && Boolean(errors.nombre)}
                  helperText={touched.nombre && errors.nombre ? errors.nombre : ''}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
